export const sanitizeString = (s: string | null | undefined) => {
  if (!s) return s;
  // eslint-disable-next-line no-control-regex
  return s.replace(/\\u[0-9A-Fa-f]{4}/g, '').replace(/\x00/g, '');
};

export const removeRanges = (inputString: string, ranges: { from: number; to: number }[]) => {
  let result = '';
  let prevEnd = 0;

  // Sort the ranges by their `from` value
  const sortedRanges = ranges.sort((a, b) => a.from - b.from);

  for (const range of sortedRanges) {
    if (prevEnd <= range.from) {
      result += inputString.slice(prevEnd, range.from);
    }
    prevEnd = Math.max(prevEnd, range.to);
  }

  // Add the remaining portion of the input string
  result += inputString.slice(prevEnd);

  return result;
};

export const removeRange = (string: string, from: number, to: number) => removeRanges(string, [{ from, to }]);

export const removeMultipleSpaces = (inputString: string): string => inputString.replace(/\s+/g, ' ');

export function capitalize(str: string) {
  return (
    str
      .split(' ')
      // First character upper case else lower case
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  );
}

export function parseItemType(itemType: string) {
  return capitalize(itemType).replace(/_/g, ' ');
}
