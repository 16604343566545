import cntl from 'cntl';
import { ChangeEventHandler, FocusEventHandler, forwardRef, HTMLInputTypeAttribute, MouseEventHandler } from 'react';
import { MdOutlineAlternateEmail, MdSearch } from 'react-icons/md';

interface FloatingInputProps {
  variant: 'primary' | 'secondary';
  rounded: boolean;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSearch?: boolean;
  isContact?: boolean;
  type?: HTMLInputTypeAttribute;
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  name?: string;
  className?: string;
  error?: string;
  required?: boolean;
  form?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  multiple?: boolean;
  readOnly?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  defaultValue?: string | number;
  autoFocus?: boolean;
  isLabelFixed?: boolean;
}

// eslint-disable-next-line react/display-name
export const FloatingInput = forwardRef<HTMLInputElement, FloatingInputProps>(
  (
    {
      variant,
      isDisabled,
      isSearch,
      isContact,
      rounded,
      type = 'text',
      label,
      placeholder = ' ',
      isLoading,
      value,
      onChange,
      onBlur,
      onClick,
      name,
      className,
      error,
      required,
      form,
      max,
      maxLength,
      min,
      minLength,
      multiple,
      readOnly,
      defaultValue,
      autoFocus,
      isLabelFixed = false,
    },
    ref
  ) => {
    const notEnabled = isDisabled || isLoading;
    const inputClass = (variant: 'primary' | 'secondary', isSearch?: boolean, isContact?: boolean) => cntl`
      peer
      leading-[21px]
      py-[10px]
      ${notEnabled ? 'bg-gray-200' : 'bg-white'}
      w-full
      min-h-[46px]
      ${isSearch || isContact ? 'px-6' : 'px-4'}
      text-[14px] 
      text-black 
      font-medium
      ${isLabelFixed ? '' : 'placeholder-transparent'}
      transition duration-200
      border
      ${
        variant === 'primary'
          ? 'border-[#e5e7eb] focus:border-primary-500'
          : 'border-gray-500 focus:placeholder-gray-500'
      }
      ${rounded ? 'rounded-[8px]' : 'rounded-none'}
      outline-none
    `;
    const labelTextColor = error
      ? 'text-red-500'
      : variant === 'primary'
        ? 'text-gray-border'
        : variant === 'secondary'
          ? 'text-gray-500'
          : 'text-gray-border';

    const labelClass = (notEnabled?: boolean, isLabelFixed?: boolean) => {
      if (!isLabelFixed) {
        return cntl`
      ${placeholder && value !== '' ? 'w-fit ml-2' : 'w-[150px]'}
      leading-[24px]
      absolute
      select-none
      left-1
      text-[8px]
      ${labelTextColor}
      transition-all 
      duration-200 
      ${notEnabled ? 'bg-gray-200' : 'bg-white'}
      ${value !== '' ? 'px-1' : 'px-2'}
      top-[-10px]
      input-text
      peer-placeholder-shown:top-[10px]
      ${isSearch || isContact ? 'peer-placeholder-shown:ml-9' : 'peer-placeholder-shown:ml-5'}
      peer-placeholder-shown:scale-125
      peer-placeholder-shown:pointer-events-none
      peer-focus:-top-[10px]
      peer-focus:ml-2
      peer-focus:px-1
      peer-focus:scale-100
      peer-focus:w-fit
    `;
      }
      return cntl`
        leading-[24px]
        absolute
        select-none
        text-[8px]
        ${labelTextColor}
        -top-[10px]
        ml-3
        bg-white
        px-1
      `;
    };
    const searchIconStyle = cntl`
      data-[variant=primary]:fill-gray-border
      data-[variant=secondary]:fill-gray-500
      absolute
      top-[11px]
      left-[2px]
      text-gray-border
    `;
    const atIconStyle = cntl`
      fill-gray-black
      absolute
      top-[13px]
      left-[16px]
      left-1
      text-gray-border
    `;

    const inputIcon = (isSearch?: boolean, isContact?: boolean) => {
      if (isSearch) {
        return <MdSearch size={20} className={searchIconStyle} />;
      }
      if (isContact) {
        return <MdOutlineAlternateEmail className={atIconStyle} />;
      }
    };

    return (
      <div className={`relative flex ${className}`}>
        {inputIcon(isSearch, isContact)}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          className={inputClass(variant, isSearch, isContact)}
          disabled={notEnabled}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          name={name}
          required={required}
          form={form}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          multiple={multiple}
          readOnly={readOnly}
          defaultValue={defaultValue}
          ref={ref}
          autoFocus={autoFocus}
        />
        <label className={labelClass(notEnabled, isLabelFixed)}>{label || placeholder || ''}</label>
      </div>
    );
  }
);
