import { EditionMode, IndicatorContext, ItemType } from '@/components/Calendar/types';
import { DateInterval } from '@/utils/date';
import { createContext } from 'react';

type CalendarContextType = {
  timeZone: string;
  setTimeZone: (timeZone: string) => void;
  timeZones: string[];
  hourHeight: number;
  step: number;
  items: ItemType[];
  editionMode: EditionMode;
  freeSlots?: DateInterval[];
  indicatorContext: IndicatorContext;
  forceSingleDay?: boolean;
};

const CalendarContext = createContext<CalendarContextType>({
  timeZone: '',
  setTimeZone: () => {},
  timeZones: [],
  hourHeight: 48,
  step: 15,
  items: [],
  editionMode: EditionMode.EVENTS,
  freeSlots: [],
  indicatorContext: {
    selectedDate: null,
    dates: null,
    action: null,
    selectedItem: null,
    activeColumn: null,
    duration: null,
  },
});

export default CalendarContext;
