import { useNotes } from '@/hooks/useNotes';
import NoteItem from '@/components/SmartyItem/NoteItem';

export const AllNotes = () => {
  const { notes } = useNotes();
  const noteList = notes?.results ?? [];

  if (!notes) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 min-h-0 gap-[20px] px-[16px] py-[24px]">
      <div className="px-3">
        {noteList.length ? (
          noteList?.map((note) => (
            <div
              key={note.id}
              className="group relative flex items-center px-5 gap-[5px] rounded-[5px] bg-white hover:bg-gray-100">
              <NoteItem note={note} />
            </div>
          ))
        ) : (
          <div className="p-4 text-center text-secondary-300">No notes found</div>
        )}
      </div>
    </div>
  );
};
