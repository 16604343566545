import { useMemo } from 'react';

import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useTasks } from '@/hooks/useTasks';
import { atom } from 'jotai';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';

export default function useEmailRefs(id?: string | null) {
  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { notes } = useNotes();
  const { schedulingLinks } = useSchedulingLinks({});

  return useMemo(() => {
    if (!id) return [];

    const filteredTasks = tasks?.results.filter((task) => task.emailIds?.includes(id)) || [];
    const filteredEvents = events?.filter((event) => event.emailIds?.includes(id)) || [];
    const filteredNotes = notes?.results.filter((note) => note.emailIds?.includes(id)) || [];
    const filteredSchedulingLinks =
      schedulingLinks?.results.filter((schedulingLink) => schedulingLink.emailIds?.includes(id)) || [];

    return [
      ...filteredTasks.map(({ id }) => id),
      ...filteredEvents.map(({ id }) => id),
      ...filteredNotes.map(({ id }) => id),
      ...filteredSchedulingLinks.map(({ id }) => id),
    ] as string[];
  }, [events, id, notes?.results, schedulingLinks?.results, tasks?.results]);
}

export const emailThreadIdAtom = atom<string | undefined>(undefined);
export const focusedIndexAtom = atom<number>(0)
