import { useQuery } from '@tanstack/react-query';
import { httpGet } from 'utils/smarty-api';
import moment from 'moment-timezone';

export type ContactEvent = {
  itemType: 'CONTACT_EVENT';
  id: string;
  status: string;
  htmlLink: string;
  created: string;
  updated: string;
  creator?: {
    email: string;
    self: boolean;
  };
  organizer?: {
    email: string;
    self: boolean;
  };
  start: Date;
  end: Date;
  allDay: boolean;
  email: string;
  eventType: string;
  backgroundColor: string;
  foregroundColor: string;
  summary: string;
  originalStartTime?: {
    date: string;
  };
};

type Params = {
  start: Date;
  end: Date;
  timeZone: string;
  contactEmails: string[];
};

const isAllDay = (start: Date, end: Date) => moment(end).diff(moment(start), 'hours', true) % 24 === 0;

export const useContactEvents = (params: Params) => {
  const { start, end, timeZone, contactEmails } = params;
  const { data, isLoading } = useQuery({
    queryKey: ['CONTACT_EVENTS', start, end, timeZone, contactEmails],
    queryFn: async () => {
      if (params.contactEmails.length === 0) return [];

      const data = await httpGet<ContactEvent[]>('/contact-events', {
        params: {
          ...params,
          contactEmails: params.contactEmails.join(',') || undefined,
        },
      });

      return data.map((event) => {
        event.itemType = 'CONTACT_EVENT';
        event.start = new Date(event.start);
        event.end = new Date(event.end);
        event.allDay = isAllDay(event.start, event.end);
        return event;
      });
    },
  });

  return {
    contactEvents: data,
    isLoading,
  };
};
