import { ReactNode } from 'react';
import * as HoverCardRadix from '@radix-ui/react-hover-card';

export const HoverCard = ({
  children,
  trigger,
  className,
}: {
  children: ReactNode;
  trigger: ReactNode;
  className?: string;
}) => (
  <HoverCardRadix.Root>
    <HoverCardRadix.Trigger asChild>
      <div>{trigger}</div>
    </HoverCardRadix.Trigger>
    <HoverCardRadix.Portal>
      <HoverCardRadix.Content className={`p-4 bg-white rounded-md shadow-popover w-80 ${className}`} sideOffset={5}>
        <div>{children}</div>
        <HoverCardRadix.Arrow className="fill-white" />
      </HoverCardRadix.Content>
    </HoverCardRadix.Portal>
  </HoverCardRadix.Root>
);
