import { useSettings } from '@/hooks/useSettings';
import MDatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { cn } from 'ui/cn';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import Switch from '@/components/Switch';
import { Divider } from '@/components/Divider';
import moment from 'moment-timezone';

type Props = {
  start: Date;
  end?: Date | null;
  allDay: boolean;
  disabled?: boolean;
  renderButton: (_: unknown, onClick: () => void) => ReactNode;
  onChangeDates: (start: Date, end: Date | null, allDay: boolean) => void;
};

export default function DatePicker({ onChangeDates, renderButton, ...item }: Props) {
  const { defaultTimeZone: timeZone } = useSettings();
  const datePickerRef = useRef<
    HTMLDivElement & {
      closeCalendar: () => void;
    }
  >();
  const valueRef = useRef<Date[]>([]);

  const [allDay, setAllDay] = useState<boolean>(item.allDay);
  const [start, setStart] = useState<Date>(item.start);
  const [end, setEnd] = useState<Date | null | undefined>(item.end);

  const handleOnClear = useCallback(() => {
    setStart(item.start);
    setEnd(item.end);
    setAllDay(item.allDay);

    valueRef.current = [...valueRef.current];
  }, [item.allDay, item.end, item.start]);

  const handleOnCancel = useCallback(() => {
    handleOnClear();
    datePickerRef.current?.closeCalendar();
  }, [handleOnClear]);

  const handleOnOk = useCallback(() => {
    let _start = start;
    let _end = end || null;

    if (allDay) {
      _start = moment.tz(start, timeZone).startOf('day').toDate();
      _end = moment.tz(start, timeZone).startOf('day').add(1, 'day').toDate();
    }

    onChangeDates(_start, _end, allDay);
    datePickerRef.current?.closeCalendar();
  }, [allDay, end, onChangeDates, start, timeZone]);

  const handleOnChange = useCallback(
    (value: DateObject | DateObject[] | null) => {
      if (!value) return;

      if (value instanceof DateObject) {
        const parsedValue = moment.tz(value.toDate(), timeZone).startOf('day');
        const _start = allDay ? parsedValue.toDate() : value.toDate();
        const _end = allDay ? parsedValue.add(1, 'day').toDate() : null;

        setStart(_start);
        setEnd(_end);

        if (allDay) {
          onChangeDates(_start, _end, allDay);
          datePickerRef.current?.closeCalendar();
        }

        return;
      }

      value.sort((a, b) => a.toDate().getTime() - b.toDate().getTime());

      setStart(value[0].toDate());
      setEnd(value[1]?.toDate() || null);
    },
    [allDay, onChangeDates, timeZone]
  );

  const handleOnOpen = useCallback(() => {
    setTimeout(() => {
      const firstDate = datePickerRef.current?.querySelector('.b-date');
      if (!(firstDate instanceof HTMLButtonElement)) return;
      firstDate.click();
    }, 1);
  }, []);

  const value = useMemo(() => {
    if (allDay) return start;

    if (!end) return start;

    valueRef.current[0] = start;
    valueRef.current[1] = end;
    return valueRef.current;
  }, [allDay, start, end]);

  const plugins = useMemo(() => {
    if (allDay) return [];
    return [
      <TimePicker key={1} position="bottom" disabled={item.disabled} />,
      <DatePanel
        key={2}
        position="top"
        sort="date"
        disabled={item.disabled}
        removeButton={false}
        formatFunction={({ date, index }) => (
          <div className="group/datepicker">
            <span className="mr-auto text-xs text-gray-500">{index ? 'End' : 'Start'}</span>
            <div className="!flex gap-4 text-lg text-black date-time">
              <span className="group-hover/datepicker:text-primary-300 text-[14px]">
                {date?.format('MMMM DD, YYYY')}
              </span>
              <span className="text-secondary-300 group-hover/datepicker:text-primary-300 text-[14px]">
                {date?.format('HH:mm')}
              </span>
            </div>
          </div>
        )}
        markFocused
        focusedClassName="[&_.date-time>*]:!text-primary-500"
      />,
    ];
  }, [allDay, item.disabled]);

  if (!start) return null;

  return (
    <MDatePicker
      value={value}
      range={!!end && !allDay}
      numberOfMonths={1}
      format="MMMM DD, YYYY hh:mm A"
      onChange={handleOnChange}
      ref={datePickerRef}
      render={renderButton}
      onOpen={handleOnOpen}
      disabled={item.disabled}
      headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
      className={cn(
        '[&.rmdp-wrapper]:p-1',
        '[&_.rmdp-calendar]:mx-auto',
        '[&_.rmdp-panel-header]:hidden',
        '[&_.rmdp-panel-body]:static',
        '[&_.rmdp-header-values]:mr-auto [&_.rmdp-header-values]:ml-0',
        '[&>.rmdp-panel.top>*:nth-child(2)]:!h-auto',
        '[&_.rmdp-panel-body>li]:bg-transparent [&_.rmdp-panel-body>li]:shadow-none',
        '[&_.b-date]:!py-0'
      )}
      plugins={plugins}>
      <Divider />
      <div className="flex flex-col gap-3 p-1">
        <Switch label="All day" value={allDay} onChange={setAllDay} />
        <Switch label="Recurring" disabled value={false} onChange={() => {}} />
        <div className="flex gap-2 text-sm leading-6">
          <button className="p-2 text-gray-500" onClick={handleOnClear}>
            CLEAR
          </button>
          <button className="p-2 ml-auto text-primary-500" onClick={handleOnCancel}>
            CANCEL
          </button>
          <button className="p-2 text-primary-500" onClick={handleOnOk}>
            OK
          </button>
        </div>
      </div>
    </MDatePicker>
  );
}
