import { useMemo } from 'react';
import { useContacts } from '@/hooks/useContacts';
import { ContactArg, convertContactToContactArg } from '@/models/ContactArg';
import { toast } from 'react-hot-toast';
import { ContactForm } from '@/components/ContactForm';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ToastGeneric } from '@/components/ToastGeneric';

type ContactEditorProps = {
  contactId?: string;
  name?: string;
  email?: string;
};

export const ContactEditor = ({ contactId, name, email }: ContactEditorProps) => {
  const { closeLeftSidebar, openLeftSidebar } = useLeftSidebar();
  const {
    contacts,
    createContact,
    isCreatingContact,
    resetCreateContact,
    updateContact,
    isUpdatingContact,
    resetUpdateContact,
  } = useContacts({
    onCreated: (contact) => {
      closeLeftSidebar();
      toast(<ToastGeneric title={`Contact '${contact.email}' created`} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateContact();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Contact not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateContact();
    },
    onUpdated: (contact) => {
      closeLeftSidebar();
      toast(<ToastGeneric title={`Contact '${contact.email}' updated`} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      openLeftSidebar({ type: 'CONTACT', context: { contactId } });
      resetUpdateContact();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Contact not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateContact();
      return;
    },
  });

  const contact = useMemo((): ContactArg => {
    const c = Array.isArray(contacts) ? contacts?.find((t) => t.id === contactId) : undefined;

    if (c) return convertContactToContactArg(c);
    // it's a new contact
    return {
      email: email || '',
      name: name || '',
      picture: '',
      description: '',
      firstName: '',
      lastName: '',
      birthday: null,
      location: '',
      organization: [],
      urls: [],
    };
  }, [contacts, contactId, name, email]);

  const handleSave = (contact: ContactArg) => {
    if (!contactId) {
      createContact(contact);
    } else {
      updateContact({ contactId, contact });
    }
  };

  if (!contact || !contacts) return <>Loading...</>;
  return <ContactForm contact={contact} onSave={handleSave} isSaving={isCreatingContact || isUpdatingContact} />;
};
