import { useAtomValue } from 'jotai';
import { SearchView } from './Search';
import { OverlayType } from './types';
import { searchStateAtom } from './utils';

export const editorFor = (itemType: any): OverlayType | undefined => {
  switch (itemType) {
    default:
      return 'SEARCH';
  }
};

export const Overlay = () => {
  const searchState = useAtomValue(searchStateAtom);
  const { type } = searchState;

  switch (type) {
    case 'SEARCH':
      return <SearchView />;
    default:
      return null;
  }
};
