import { Event } from '@/models/EventModel';
import { TaskScheduleType } from '@prisma/client';

export type RecurrenceEnd = 'NEVER' | 'DATE_RANGE' | 'COUNT';
export type TimeUnit = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
export type WhenType = 'THIS_WEEK' | 'NEXT_WEEK' | 'THIS_MONTH' | 'DATE_RANGE' | 'RECURRING';

export type EventArg = Omit<
  Event,
  'itemType' | 'id' | 'createdAt' | 'updatedAt' | 'externalId' | 'userId' | 'backgroundColor' | 'foregroundColor'
> & {
  notifyGuests?: boolean;
  when?: WhenType;
  recurringDaysOfWeek?: number[] | null;
  recurringEveryAmount?: number | null;
  recurringEveryUnit?: TimeUnit | null;
  recurringEnds?: RecurrenceEnd | null;
  backgroundColor?: string;
  foregroundColor?: string;
  scheduleId?: string;
  scheduleConstraints?: string | null;
  earliestStartDate?: Date;
  deadline?: Date;
  duration?: number;
  priority?: 'LOW' | 'NORMAL' | 'HIGH' | 'NO_PRIORITY';
  eventScheduleType?: TaskScheduleType;
};

export const convertEventToEventArg = (event: Event): EventArg => {
  const { itemType, id, ...rest } = event;
  return rest;
};

export const convertEventArgToEvent = (eventArg: EventArg, eventId?: string, externalId?: string): Event => ({
  id: eventId || 'temp',
  createdAt: new Date(),
  updatedAt: new Date(),
  externalId: externalId || 'temp',
  itemType: 'EVENT',
  ...eventArg,
  backgroundColor: eventArg.backgroundColor || '#000000',
  foregroundColor: eventArg.foregroundColor || '#ffffff',
});

export type ConferencingData = {
  conferenceSolution?: {
    iconUri?: string;
    name?: string;
    key: {
      type: 'addOn';
    };
  };
  entryPoints?: {
    uri?: string;
    label?: string;
    entryPointType: 'video' | 'phone';
  }[];

  createRequest?: {
    requestId: string;
  };

  notes?: string;
};
