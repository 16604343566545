import { ReactNode, useEffect, useRef, useState } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment-timezone';

const currentTimeAtom = atom<Date>(new Date());

export const WallClockProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const setCurrentTime = useSetAtom(currentTimeAtom);
  const reported = useRef(new Date());

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      const newNow = new Date();
      // if we are in a different minute than last one reported, report
      if (!moment(newNow).isSame(reported.current, 'minute')) {
        setCurrentTime(newNow);
        reported.current = newNow;
      }
    }, 500);
    return () => {
      clearInterval(intervalHandle);
    };
  }, [setCurrentTime]);

  return <>{children}</>;
};

export const useWallClock = () => {
  return useAtomValue(currentTimeAtom);
};

export const WallClock = () => {
  const now = useWallClock();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  if (!initialized) {
    return (
      <div className="w-[150px] animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className={'min-w-[150px] text-secondary-300'}>
      {initialized ? moment(now).format('MMMM Do h:mm a') : moment.tz(now, 'UTC').format('MMMM Do h:mm a')}
    </div>
  );
};
