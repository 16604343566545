import moment from 'moment-timezone';
import { Priority, TaskScheduleType } from '@prisma/client';
import { Task } from './TaskModel';
import { Event } from './EventModel';
import { SchedulingLink } from './SchedulingLinkModel';
import { Note } from './NoteModel';
import { ContactEvent } from '@/hooks/useContactEvents';

export type ItemType = 'TASK' | 'EVENT' | 'SCHEDULING_LINK' | 'NOTE' | 'EMAIL' | 'WORKFLOW' | 'CONTACT' | 'SEARCH';
export const ALL_ITEM_TYPES = [
  'TASK',
  'EVENT',
  'SCHEDULING_LINK',
  'NOTE',
  'EMAIL',
  'WORKFLOW',
  'CONTACT',
  'SEARCH',
] as const;

export const extractItemSummary = (item: Task | Event | SchedulingLink | Note | ContactEvent) => {
  switch (item.itemType) {
    case 'TASK':
    case 'EVENT':
    case 'CONTACT_EVENT':
      return (item as Event | Task).summary;
    case 'SCHEDULING_LINK':
      return (item as SchedulingLink).title;
    default:
      return undefined;
  }
};

export const isItemCompleted = (item: Task | Event | SchedulingLink | Note) => {
  switch (item.itemType) {
    case 'TASK':
      return item.isCompleted;
    case 'NOTE':
    case 'EVENT':
    case 'SCHEDULING_LINK':
      return moment(item.start).isBefore() && (item.end ? moment(item.end).isBefore() : false);
    default:
      return false;
  }
};

export const isItemHighPriority = (item: { priority: Priority }) => item.priority === 'HIGH';
export const isItemMediumPriority = (item: { priority: Priority }) => item.priority === 'NORMAL';
export const isItemLowPriority = (item: { priority: Priority }) => item.priority === 'LOW';
export const isItemNoPriority = (item: { priority: Priority }) => item.priority === 'NO_PRIORITY';

export const isItemAutoSchedule = (item: { taskScheduleType: TaskScheduleType }) =>
  item.taskScheduleType === 'AUTO_SCHEDULED';

export const isItemNotSchedule = (item: { taskScheduleType: TaskScheduleType }) =>
  item.taskScheduleType !== 'AUTO_SCHEDULED';
export const isItemEvent = (item: Task | Event | SchedulingLink | Note) => item.itemType === 'EVENT';
export const isScheduled = (item: { taskScheduleType: TaskScheduleType }) => item.taskScheduleType !== null;

export const ALL_ROLLING_INTERVALS = ['Days', 'Weeks', 'Months'];
export type RollingInterval = (typeof ALL_ROLLING_INTERVALS)[number];
