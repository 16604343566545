import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut } from 'utils/smarty-api';
import { Group, GroupArg } from '@/models/GroupModel';
import { useEasySession } from '@/hooks/useEasySession';

type useGroupsProps = {
  onCreated?: (group: Group) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (group: Group) => void;
  onUpdateError?: (error: void) => void;
};

export const useGroups = ({ onCreated, onCreateError, onUpdated, onUpdateError }: useGroupsProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: groups, isLoading: isLoadingGroup } = useQuery({
    queryKey: ['GROUPS'],
    queryFn: () => httpGet<Group[]>('/groups'),
    enabled: isAuthenticated,
  });

  const {
    mutate: createGroup,
    status: creatingGroupStatus,
    error: createGroupError,
    isSuccess: isCreateGroupSuccess,
    reset: resetCreateGroup,
  } = useMutation<Group, void, GroupArg>({
    mutationKey: ['CREATE_GROUP'],
    mutationFn: (data) => httpPost<void, Group, GroupArg>('/groups', data),
    onMutate: async ({ name }) => {
      await queryClient.cancelQueries({ queryKey: ['GROUPS'] });
      const previous = queryClient.getQueryData<Group[]>(['GROUPS']);
      queryClient.setQueryData<Group[]>(
        ['GROUPS'],
        [
          {
            //TODO: Fix this when groups contact is implemented
            contacts: [],
            name,
            id: '',
          },
        ]
      );
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<Group[]>(['GROUPS'], (previous) => previous);
      onCreateError?.(error);
    },
    onSuccess: (group) => {
      void queryClient.invalidateQueries({ queryKey: ['GROUPS'] });
      onCreated?.(group);
    },
  });

  const {
    mutate: updateGroup,
    status: updatingGroupStatus,
    error: updateGroupError,
    isSuccess: isUpdateGroupSuccess,
    reset: resetUpdateGroup,
  } = useMutation<
    Group,
    void,
    {
      groupId: string;
      group?: GroupArg;
    }
  >({
    mutationKey: ['UPDATE_GROUP'],
    mutationFn: ({ groupId, group }) => httpPut<void, Group, GroupArg>(`/groups/${groupId}`, group),
    onMutate: async ({ group, groupId }) => {
      await queryClient.cancelQueries({ queryKey: ['GROUPS'] });
      const previous = queryClient.getQueryData(['GROUPS']);
      queryClient.setQueryData<Group[]>(['GROUPS'], () => [
        {
          id: groupId,
          //TODO: Fix this when groups contact is implemented
          contacts: [],
          name: group?.name || '',
        },
      ]);
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<Group[]>(['GROUPS'], (previous) => previous);
      onUpdateError?.(error);
    },
    onSuccess: (group) => {
      void queryClient.invalidateQueries({ queryKey: ['GROUPS'] });
      onUpdated?.(group);
    },
  });

  return {
    groups,
    isLoadingGroup,
    isCreatingGroup: creatingGroupStatus === 'pending',
    isCreateGroupSuccess,
    createGroupError,
    isUpdatingGroup: updatingGroupStatus === 'pending',
    updateGroupError,
    isUpdateGroupSuccess,
    createGroup,
    resetCreateGroup,
    updateGroup,
    resetUpdateGroup,
  };
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, void, { groupId: string }>({
    mutationKey: ['DELETE_GROUP'],
    mutationFn: async ({ groupId }) => {
      await httpDelete(`/groups/${groupId}`);
    },
    onMutate: async ({ groupId }) => {
      await queryClient.cancelQueries({ queryKey: ['GROUPS'] });
      const previous = queryClient.getQueryData<Group[]>(['GROUPS']);
      const newResults = previous?.filter((p) => p.id !== groupId);
      return queryClient.setQueryData<Group[]>(['GROUPS'], () => newResults);
    },
    onError: () => {
      queryClient.setQueryData<Group[]>(['GROUPS'], (previous) => previous);
    },
    onSuccess: (_, variable) => {
      const cached = queryClient.getQueryData<Group[]>(['GROUPS']);
      const filteredCache = cached?.filter((p) => p.id !== variable.groupId);
      queryClient.setQueryData<Group[]>(['GROUPS'], () => filteredCache);
    },
  });

  return {
    deleteGroup: mutation.mutate,
    deleteGroupAsync: mutation.mutateAsync,
    isDeletingGroup: mutation.status === 'pending',
    deleteGroupError: mutation.error,
    isDeleteGroupSuccess: mutation.isSuccess,
    resetDeleteGroup: mutation.reset,
  };
};
