import { Avatar } from '@/components/Avatar';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { Cross2Icon } from '@radix-ui/react-icons';
import { BiPencil } from 'react-icons/bi';
import { MdArrowBack, MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';
import { useContacts } from '@/hooks/useContacts';
import { useMemo } from 'react';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import avatarPlaceholder from '@/icons/avatar-placeholder.png';

import { Urls } from '@/models/ContactArg';

export type ContactContext = {
  contactId?: string;
  contactName?: string;
  contactPicture?: string;
  editMode?: boolean;
};

interface PropsHeader {
  editMode?: boolean;
  contactId?: string;
  onClose?: () => void;
}

export const ContactHeader = ({ contactId, editMode, onClose }: PropsHeader) => {
  const { openLeftSidebar } = useLeftSidebar();
  const { contacts } = useContacts({});

  const handleOpenLeftSidebarAllContacts = () => {
    openLeftSidebar({
      type: 'ALL_CONTACTS',
    });
  };

  const handleOpenLeftSidebarEditContacts = () => {
    openLeftSidebar({
      type: 'CONTACT_EDITOR',
      context: { contactId, editMode: true },
    });
  };

  const handleCloseSidebar = () => {
    if (onClose) {
      onClose();
    }
  };

  const contact = useMemo(() => {
    if (!contacts) return null;
    const c = Array.isArray(contacts) ? contacts.find((t) => t.id === contactId) : undefined;
    return c;
  }, [contacts, contactId]);

  if (!contact) return null;

  const openLink = (url: string) => () => {
    window.open(`${url}`, '_blank');
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between border-b mt-2">
        <div className="flex gap-4 items-center">
          <ToolTip tooltip="Back">
            <button
              className={`rounded h-[32px] w-[32px] inline-flex items-center justify-center`}
              onClick={handleOpenLeftSidebarAllContacts}>
              {editMode ? (
                <Cross2Icon height={24} width={24} />
              ) : (
                <MdArrowBack size={24} className="text-[#5b5650] hover:text-[#000]" />
              )}
            </button>
          </ToolTip>
          {contactId && !editMode ? (
            <ToolTip tooltip="Edit Contact">
              <button
                className={`rounded h-[32px] w-[32px] inline-flex items-center justify-center`}
                onClick={handleOpenLeftSidebarEditContacts}>
                <BiPencil size={22} className="text-[#5b5650] hover:text-[#000]" />
              </button>
            </ToolTip>
          ) : null}
        </div>

        <ToolTip tooltip="Close sidebar">
          <button type="button" onClick={handleCloseSidebar}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="px-[16px] flex flex-col">
        <div className="flex items-center gap-2 mt-[24px]">
          <ContactsIcon />
          <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter truncate max-w-[350px]">
            {contact.name ?? contact.email}
          </h1>
        </div>
        {contact.name && !editMode ? (
          <div className="flex items-center gap-[10px] whitespace-nowrap mt-4">
            {contact.picture ? (
              <Avatar picture={contact.picture} name={contact.name} className="rounded-full" size={73} />
            ) : (
              <Avatar name="Placeholder" picture={avatarPlaceholder.src} className="rounded-full" size={73} />
            )}
            {contact.location ? (
              <p className="text-[#1A1A1A] text-[14px] leading-0 font-inter max-w-[350px] text-wrap">
                {contact.location}
              </p>
            ) : null}
          </div>
        ) : null}
        <div className="flex items-center mt-4 px-[16px] gap-[8px]">
          {(contact.urls as Urls[])?.map((url, index) => (
            <ToolTip tooltip={url.metadata} key={index}>
              <button
                type="button"
                onClick={openLink(url.value!)}
                className="text-[#1A1A1A] text-[14px] leading-0 font-inter max-w-[350px] text-wrap">
                <SchedulingLinkIcon />
              </button>
            </ToolTip>
          ))}
        </div>
      </div>
    </div>
  );
};
