import { httpGet } from 'utils/smarty-api';
import { useQuery } from '@tanstack/react-query';
import { Contact } from '@/models/ContactModel';
import { useEasySession } from '@/hooks/useEasySession';

export const searchContacts = (
  search?: string,
  excludedEmailAddresses?: string,
  sort?: string,
  term?: string,
  limit?: number
) =>
  httpGet<Contact[]>('/contacts', {
    params: {
      term: term || undefined,
      excludedEmailAddresses,
      sort,
      search,
      limit,
    },
  });

export const useSearchContacts = ({
  term,
  excludedEmailAddresses,
  sort = 'ASC',
  search = '',
  enabled = true,
}: {
  term?: string;
  excludedEmailAddresses?: string;
  limit?: number;
  sort?: string;
  search?: string;
  enabled?: boolean;
}) => {
  const { isAuthenticated } = useEasySession();
  const { data, isLoading } = useQuery({
    queryKey: ['CONTACTS', term, excludedEmailAddresses, sort, search],
    queryFn: () => searchContacts(search, excludedEmailAddresses, sort, term),
    enabled: enabled && isAuthenticated,
    refetchOnWindowFocus: false,
  });
  return {
    results: data || [],
    isLoadingContacts: isLoading,
  };
};
