import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSegment } from '@/components/useSegment';
import { SelectorCalendarIcon } from '@/icons/SelectorCalendarIcon';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';

export const ViewSelector = () => {
  const { trackEvent } = useSegment();
  const router = useRouter();
  const { asPath: currentPath } = router;

  return (
    <div className="flex gap-[16px] px-[16px] py-[9px] bg-[#F3F3F3] rounded-[14px] items-center">
      <Link
        href="/calendar"
        shallow
        draggable={false}
        onClick={() => trackEvent('View Updated', { selected_view: 'calendar', previous_view: currentPath.slice(1) })}>
        <SelectorCalendarIcon color={currentPath === '/calendar' ? 'black' : undefined} />
      </Link>
      <KeyboardShortcutIndicator shortcut={'Space'} />
    </div>
  );
};
