import { AddNewIcon } from '@/icons/AddNewIcon';
import useLeftSidebar from '../useLeftSidebar';
import ToolTip from '@/components/Tooltip';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { NoteIcon } from '@/icons/NoteIcon';
import { useNotes } from '@/hooks/useNotes';

export const NoteHeader = ({ onClose }: { onClose: () => void }) => {
  const { openLeftSidebar } = useLeftSidebar();
  const { notes } = useNotes();
  const noteList = notes?.results ?? [];

  if (!notes) {
    return null;
  }

  const handleOpenLeftSidebarNoteEditor = () => {
    openLeftSidebar({
      type: 'NOTE_EDITOR',
    });
  };

  return (
    <div className="flex flex-col gap-6 px-4 py-6 h-[140px] min-h-[100px]">
      <div className="flex items-center justify-between">
        <div
          onClick={handleOpenLeftSidebarNoteEditor}
          className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-[#EFEFEF] rounded-[14px]">
          <AddNewIcon />
        </div>
        <ToolTip tooltip="Close Sidebar">
          <button type="button" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center">
        <NoteIcon className="ml-[16px] mr-[8px]" size={13.85} />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">
          {noteList.length > 1 ? noteList.length : 'All'} Notes
        </h1>
      </div>
    </div>
  );
};
