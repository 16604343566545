import { CSSProperties, TransitionEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ItemType } from '../../types';
import DraggableIcon from '@/icons/Draggable';
import { SubTaskIcon } from '@/icons/SubTaskIcon';
import { EventIcon } from '@/icons/EventIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { RightSidebarIcon } from '@/icons/RightSidebarIcon';
import { cn } from 'ui/cn';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { createDraggableId, DraggableType, DroppableType, isDraggable, parseDroppableId } from '@/utils/dnd';
import { isNewItem } from '../../utils/item';
import { HoverCard } from '@/components/HoverCard';
import { ConferenceDataProps, ContactContextMenu } from '@/components/CommandBox/DragHandleMouseOver';
import { useEvents } from '@/hooks/useEvents';
import { useEasySession } from '@/hooks/useEasySession';
import { AttendeeResponseStatus } from '@/utils/contacts';
import { isItemCompleted } from '@/models/shared';
import { CommandBarIcon } from '@/icons/CommandBarIcon';

export default function EditorItem(props: NodeViewProps) {
  const { node } = props;

  if (node.attrs.index === -1) {
    return <Content {...props} />;
  }

  return (
    <Draggable
      index={node.attrs.index}
      draggableId={createDraggableId(DroppableType.LIST, node.attrs.type as DraggableType, node.attrs.id)}
      isDragDisabled={
        !isDraggable(node.attrs.type) || isNewItem(node.attrs!.id) || !!node.attrs.parentId || !node.attrs.editable
      }
      shouldRespectForcePress
      key={node.attrs.id}>
      {(draggableProvided, snapshot) => {
        return <Content {...props} draggableProvided={draggableProvided} snapshot={snapshot} />;
      }}
    </Draggable>
  );
}

type ContentProps = {
  draggableProvided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
} & NodeViewProps;

function Content({ node, editor, updateAttributes, draggableProvided, snapshot }: ContentProps) {
  const { droppableType } = useMemo(() => parseDroppableId(snapshot?.draggingOver || ''), [snapshot?.draggingOver]);
  const [clickableWhitespaceStyle, setClickableWhitespaceStyle] = useState<CSSProperties>({});
  const containerRef = useRef<HTMLDivElement>();
  const { events, updateEvent } = useEvents({ disableQuery: true });
  const { user } = useEasySession();

  const placeholder = useMemo(() => {
    if (!node.attrs.type) {
      return '';
    }
    if (node.attrs.type === ItemType.EVENT) {
      return 'Create an event or use the / command';
    }
    return `Create a ${node.attrs.type.toLowerCase().replace('_', ' ')} or use the / command`;
  }, [node.attrs.type]);

  const eventCompleted = useMemo(() => {
    if (node.attrs.type !== 'EVENT') return;
    const event = events?.find((event) => event.id === node.attrs.id);
    if (!event) return;
    return isItemCompleted(event);
  }, [events, node.attrs.id, node.attrs.type]);

  const renderedIcon = useMemo(() => {
    switch (node.attrs.type) {
      case ItemType.TASK: {
        return (
          <div className="flex items-center h-6 gap-2 shrink-0" contentEditable={false}>
            {!!node.attrs.parentId && <SubTaskIcon className="w-[14px] h-[14px] select-none" />}
            <input
              className="w-4 h-4"
              type="checkbox"
              checked={!!node.attrs.checked}
              contentEditable={false}
              disabled={isNewItem(node.attrs!.id)}
              onChange={(event) => {
                const { checked } = event.target as HTMLInputElement;
                updateAttributes({ checked });
                editor.commands.save();
              }}
            />
          </div>
        );
      }
      case ItemType.EVENT: {
        const event = events?.find((event) => event.id === node.attrs.id);

        const handleChangeStatus = (status: AttendeeResponseStatus) => {
          if (!event) return;
          event.attendees?.forEach((c) => {
            if (c.email === user?.email) {
              c.responseStatus = status;
            }
          });
          updateEvent({ eventId: event.id, event });
        };
        const isEventHoverCardDisabled =
          event?.conferenceData ?? event?.hangoutLink ?? event?.location ?? event?.attendees?.length;
        const status = event?.attendees?.find((c) => c.email === user?.email)?.responseStatus;

        return (
          <>
            {!isEventHoverCardDisabled ? (
              <EventIcon
                color={node.attrs.color}
                size={16}
                className={`w-4 h-6 ${eventCompleted ? 'opacity-50 [&>*]:brightness-50' : ''}`}
              />
            ) : (
              <HoverCard
                className="p-[8px]"
                trigger={
                  <EventIcon
                    color={node.attrs.color}
                    size={16}
                    className={`w-4 h-6 ${eventCompleted ? 'opacity-50 [&>*]:brightness-50' : ''}`}
                  />
                }>
                <ContactContextMenu
                  conferenceData={event?.conferenceData as ConferenceDataProps}
                  hangoutLink={event?.hangoutLink}
                  location={event?.location}
                  contacts={event?.attendees?.map((attendee) => ({
                    email: attendee.email || '',
                    name: attendee.name || '',
                    responseStatus: attendee.responseStatus || '',
                  }))}
                  creatorEmail={event?.creator as { email: string }}
                  takeMeetingNotes={false}
                  status={status}
                  onChangeStatus={handleChangeStatus}
                />
              </HoverCard>
            )}
          </>
        );
      }
      case ItemType.SCHEDULING_LINK: {
        return <SchedulingLinkIcon color="black" size={16} className="w-4 h-6" />;
      }
      case ItemType.NOTE: {
        return <NoteIcon color="black" size={16} className="w-4 h-6" />;
      }
      default: {
        return <CommandBarIcon color="black" className="w-4 h-6" />;
      }
    }
  }, [editor.commands, eventCompleted, events, node.attrs, updateAttributes, updateEvent, user?.email]);

  const openSidebar = useCallback(() => {
    if (node.attrs.parentId) return;
    editor.commands.openSidebar({ id: node.attrs.id, itemType: node.attrs.type });
  }, [editor.commands, node.attrs.id, node.attrs.type, node.attrs.parentId]);

  const style = useMemo(() => {
    if (!snapshot?.isDropAnimating || droppableType !== DroppableType.CALENDAR) {
      return draggableProvided?.draggableProps.style;
    }

    return { ...draggableProvided?.draggableProps.style, transitionDuration: '1ms', opacity: 0 };
  }, [draggableProvided?.draggableProps.style, snapshot, droppableType]);

  const getClickableWhitespaceStyle = useCallback((): CSSProperties => {
    if (!containerRef.current) return {};

    const eol = containerRef.current.getElementsByClassName('ProseMirror-separator')[0] as HTMLImageElement;
    if (!eol) {
      const content = containerRef.current.getElementsByTagName('p')[0];
      return { top: content.offsetTop, left: content.offsetLeft + content.offsetWidth + 50 };
    }

    const { offsetTop, offsetLeft } = eol;
    return { top: offsetTop, left: offsetLeft + 50, transform: 'translateY(-50%)' };
  }, []);

  useEffect(() => {
    setClickableWhitespaceStyle(getClickableWhitespaceStyle());
  }, [getClickableWhitespaceStyle]);

  return (
    <NodeViewWrapper
      as="div"
      className={cn(
        'flex w-full items-center gap-2 hover:bg-primary-100 focus:bg-primary-100 lg:p-2.5 xs:p-1 rounded transition-colors group data-[checked=true]:opacity-50 [&[data-checked=true]>*]:brightness-50 data-[checked=true]:line-through bg-white',
        snapshot?.isDragging && 'shadow-2xl',
        droppableType === DroppableType.CALENDAR && 'opacity-50',
        node.attrs.parentId && 'ml-7',
        eventCompleted ? 'opacity-50 [&>*]:brightness-50 [&>*]:line-through' : '',
        !draggableProvided && 'bg-transparent'
      )}
      data-id={node.attrs.id}
      data-type={node.attrs.type}
      data-checked={node.attrs.checked}
      data-parent-id={node.attrs.parentId}
      data-index={node.attrs.index}
      data-user-order={node.attrs.userOrder}
      data-schedule-type={node.attrs.scheduleType}
      ref={(ref: HTMLDivElement) => {
        draggableProvided?.innerRef(ref);
        containerRef.current = ref;
      }}
      {...draggableProvided?.draggableProps}
      style={style}
      onTransitionEnd={(event: TransitionEvent) => {
        draggableProvided?.draggableProps.onTransitionEnd?.(event);
        setClickableWhitespaceStyle(getClickableWhitespaceStyle());
      }}>
      <div
        className={cn(
          'draggable-icon',
          (isNewItem(node.attrs!.id) || !node.attrs.editable) && 'opacity-0 pointer-events-none'
        )}
        {...draggableProvided?.dragHandleProps}
        hidden={!draggableProvided || !!node.attrs.parentId}>
        <DraggableIcon className="w-4 h-6 transition-opacity opacity-0 select-none group-hover:opacity-100 cursor-grab" />
      </div>
      {renderedIcon && <div className="shrink-0 item-icon">{renderedIcon}</div>}
      <NodeViewContent
        as="p"
        className="leading-6 w-full before:text-[#adb5bd] before:float-left before:pointer-events-none before:h-0 [&[contentEditable=false]_span]:pointer-events-none !outline-none"
        data-placeholder={placeholder}
        contentEditable={node.attrs.editable}
      />
      <div className="flex ml-auto select-none item-buttons" contentEditable={false}>
        <div
          onClick={openSidebar}
          className={`flex-1 select-none !outline-none absolute h-6 mt-[-1px] right-0 ${
            node.attrs.parentId ? '' : 'cursor-pointer'
          }`}
          style={clickableWhitespaceStyle}
          hidden={isNewItem(node.attrs!.id)}
        />
        {!node.attrs.parentId ? (
          <>
            <RightSidebarIcon
              size={16}
              className="z-10 w-4 h-6 ml-auto transition-opacity opacity-0 cursor-pointer select-none group-hover:opacity-100 shrink-0"
              hidden={isNewItem(node.attrs!.id)}
              onClick={openSidebar}
            />
          </>
        ) : null}
        <DeleteIcon
          size={16}
          className="z-10 w-4 h-6 ml-4 transition-all opacity-0 cursor-pointer select-none group-hover:opacity-100 hover:fill-red-500 shrink-0"
          hidden={isNewItem(node.attrs!.id) || !node.attrs.editable}
          onClick={() => {
            editor.chain().blur().deleteItem({ id: node.attrs.id, itemType: node.attrs.type }).run();
          }}
        />
      </div>
    </NodeViewWrapper>
  );
}
