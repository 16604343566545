import { useCallback, useContext } from 'react';
import { hoursToPixels } from './utils';
import moment from 'moment-timezone';
import { ParagraphText } from '../Typography';
import CalendarContext from '@/components/Calendar/context';

export default function TimeZonesColumn() {
  const { hourHeight, timeZones, timeZone: selectedTimeZone } = useContext(CalendarContext);

  const formatTime = useCallback(
    (hour: number, timeZone: string) => {
      return moment.tz(selectedTimeZone).startOf('day').add(hour, 'hours').tz(timeZone).format('h:mma');
    },
    [selectedTimeZone]
  );

  return (
    <div
      style={{ height: hoursToPixels(hourHeight, 24.5) }}
      className="flex items-start gap-2 px-1 text-center shrink-0 text-gray-border">
      {timeZones.map((_timeZone, index) => (
        <div key={index}>
          {Array.from({ length: 24 + 1 }).map((_, hour) => (
            // 6 is the height of calendar__content__hour last item
            <div key={hour} style={{ height: hour < 24 ? hoursToPixels(hourHeight, 1) : 6 }} className="w-[75px]">
              <ParagraphText>{formatTime(hour, _timeZone)}</ParagraphText>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
