import { FormEvent, useState } from 'react';
import { Select } from './Select';
import { Tiptap } from './TipTap';
import Switch from './Switch';

type Props = {
  onSave: (question: {
    question: string;
    answerFormat: 'Single line' | 'Multi-line';
    isMandatory: boolean;
    answers: { id: string; answer: string }[];
  }) => void;
  onCancel: () => void; // Add this line
};

export const QuestionsForm = ({ onSave, onCancel }: Props) => {
  const [content, setContent] = useState<string>('');
  const [answerFormat, setAnswerFormat] = useState<'Single line' | 'Multi-line'>('Single line');
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const reset = () => {
    setContent('');
    setAnswerFormat('Single line');
    setIsRequired(false);
  };

  const handleClick = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSave({
      question: content,
      answerFormat,
      isMandatory: isRequired,
      answers: [],
    });
    reset();
    setShouldRender(!shouldRender);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="mt-2">
        <Tiptap
          key={shouldRender.toString()}
          classNameBox="border border-gray-200 rounded-lg"
          placeholder="Question..."
          content={content}
          setContent={setContent}
        />
      </div>
      <Select
        options={['Single line', 'Multi-line']}
        label="Answer format"
        value={answerFormat}
        onChange={setAnswerFormat}
      />
      <Switch className="ml-2 text-slate-400" label="Answer required" value={isRequired} onChange={setIsRequired} />
      <button className="items-center ml-4 p-2 border rounded text-black hover:text-blue-500" onClick={handleClick}>
        <span>Save question</span>
      </button>
      <button className="text-black hover:text-blue-500" onClick={onCancel}>Cancel</button>
    </div>
  );
};
