import { MdEmail } from 'react-icons/md';
import useLeftSidebar from '../../useLeftSidebar';
import { useEmailThread } from '@/hooks/useEmails';

type Props = {
  value?: string[] | null;
};

export default function ReferenceList({ value = [] }: Props) {
  if (!value?.length)
    return (
      <section className="flex items-center justify-center py-5 border-t border-gray-border">
        <p className="text-gray-400">No linked items.<br/><br/>You can link existing tasks, events, emails, or notes to this item.</p>
      </section>
    );

  return (
    <section className="relative flex flex-col gap-3 py-5 border-t border-gray-border">
      {value?.map((id, index) => {
        return <EmailReferenced key={index} threadId={id} />;
      })}
    </section>
  );
}

const EmailReferenced = ({ threadId }: { threadId: string }) => {
  const { openLeftSidebar } = useLeftSidebar();
  const { emailThread, isLoading } = useEmailThread({ threadId });

  const handleOpenEmailDetail = (threadId: string) => () => {
    openLeftSidebar({ type: 'EMAIL_DETAIL', context: { threadId } });
  };

  if (isLoading)
    return (
      <div className="animate-pulse flex w-full">
        <div className="flex-1 py-1">
          <div className="h-2 bg-slate-700 rounded mb-2"></div>
          <div className="h-2 bg-slate-700 rounded"></div>
        </div>
      </div>
    );

  if (!emailThread) return null;

  return (
    <div className="flex items-center gap-2 hover:scale-105 hover:translate-x-1 transition-all">
      <MdEmail />
      <button
        type="button"
        onClick={handleOpenEmailDetail(emailThread.id ?? '')}
        className="select-none underline text-primary-500 cursor-pointer">
        <p
          dangerouslySetInnerHTML={{ __html: emailThread.messages[0].snippet ?? '' }}
          className=" text-[14px] leading-[18px] font-inter truncate select-none max-w-[350px]"
        />
      </button>
    </div>
  );
};
