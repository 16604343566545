import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import moment from 'moment-timezone';

import { CopyIcon } from '@/icons/CopyIcon';
import { Select } from '@/components/Select';
import { buildLink, copyToClipboard } from '@/utils/copyToClipboard';
import { DateInterval, findTimeZone } from '@/utils/date';
import { ToastGeneric } from '@/components/ToastGeneric';
import { NameEmail } from '@/utils/contacts';
import { ParagraphText } from '@/components/Typography';
import { SchedulingClockIcon } from '@/icons/SchedulingClockIcon';
import { Bullet } from '@/icons/Bullet';
import Switch from '@/components/Switch';
import { cn } from 'ui/cn';

import { useSettings } from '@/hooks/useSettings';
import { useSchedulingLinkFreeSlots } from '@/hooks/useFreeSlots';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { useAtom } from 'jotai';
import { slotsAtom } from '@/components/calendarAtoms';
import ToolTip from '@/components/Tooltip';
import ToggleableSection from '../sections/ToggleableSection';
import { IoMdOptions } from "react-icons/io";
import { Divider } from '@/components/Divider';

const OPTIONS_LINK_STYLE = ['No Link' as const, 'Hyperlinked' as const, 'Plaintext URL' as const];
type OPTIONS_LINK_STYLE = (typeof OPTIONS_LINK_STYLE)[number];

type Props = {
  code: string;
  scheduleId?: string;
  duration?: number;
  start?: Date | null;
  end?: Date | null;
  contacts?: NameEmail[];
  slots?: DateInterval[];
  calendarIds?: string[];
  id?: string;
  openLink?: (url: string) => void;
};

export default function SlotsSection({
  start,
  end,
  id,
  scheduleId,
  duration,
  code,
  calendarIds,
  contacts,
  slots,
  openLink,
}: Props) {
  const { defaultTimeZone: timeZone, settings } = useSettings();
  const [slotAtom, setSlotAtom] = useAtom(slotsAtom);

  const [linkStyle, setLinkStyle] = useState<OPTIONS_LINK_STYLE>('Hyperlinked');
  const [showAllTimes, setShowAllTimes] = useState(false);
  const [combineAdjacent, setCombineAdjacent] = useState(true);

  const contactEmails = useMemo(() => contacts?.map((contact) => contact.email) || [], [contacts]);
  const anyOfTheseTimesText = useMemo(
    () => `Do any of these times (${findTimeZone(timeZone)?.abbr}) work for you?`,
    [timeZone]
  );

  const { freeSlots, isLoadingFreeSlots } = useSchedulingLinkFreeSlots({
    start,
    end,
    duration,
    contactEmails,
    timeZone,
    adjacent: combineAdjacent,
    checkCalendarIds: calendarIds,
    availabilityId: scheduleId,
    slots,
  });

  const freeSlotsEntries = useMemo(
    () => Object.entries(freeSlots).slice(0, showAllTimes ? undefined : 3),
    [freeSlots, showAllTimes]
  );

  const showMoreVisible = !showAllTimes && Object.values(freeSlots).length > 3;

  const link = useMemo(() => {
    const url = `${process.env.NEXT_PUBLIC_URL}/${settings?.username || ''}/${code}`;
    return { url, title: url.replace('https://', '') };
  }, [code, settings]);

  const handleCopyLinkToClipboard = useCallback(() => {
    const formattedLinkURL = buildLink(link.url, link.title);
    copyToClipboard(formattedLinkURL, link.url);
    toast(<ToastGeneric title="Copied to clipboard" />, { position: 'bottom-center', style: { background: '#000' } });
  }, [link]);

  const handleLinkStyleChange = useCallback((value: OPTIONS_LINK_STYLE) => {
    setLinkStyle(value);
    setShowAllTimes(value === 'No Link');
  }, []);

  const handleCopySlots = useCallback(() => {
    const slotsText = freeSlotsEntries
      .map(([, info]) => {
        const slotLabel = `${info.label}\n`;
        const slotTimes = info.slots
          .map((slotRange) => {
            const startTime = slotRange[0];
            const endTime = moment(slotRange[1] || startTime, 'h:mm A')
              .add(duration, 'minutes')
              .format('h:mm A');
            return `• ${startTime} - ${endTime}`;
          })
          .join('\n');
        return `${slotLabel}${slotTimes}`;
      })
      .join('\n\n');

    const moreTimesText = showMoreVisible ? `\n\nMore times are listed at this scheduling link instead` : '';
    const plainText = `${anyOfTheseTimesText}\n\n${slotsText}${moreTimesText}`;

    // Build the HTML version
    const slotsHtml = freeSlotsEntries
      .map(([, info]) => {
        const slotLabel = `<p>${info.label}</p>`;
        const slotTimes = info.slots
          .map((slotRange) => {
            const startTime = slotRange[0];
            const endTime = moment(slotRange[1] || startTime, 'h:mm A')
              .add(duration, 'minutes')
              .format('h:mm A');
            const slotTime = `${startTime} - ${endTime}`;

            return linkStyle === 'Hyperlinked'
              ? `<p><span class='text-black'>&#8226;</span> <a href='${link.url}'>${slotTime}</a></p>`
              : `<p><span class='text-black'>&#8226;</span> ${slotTime}</p>`;
          })
          .join('');
        return `${slotLabel}${slotTimes}`;
      })
      .join(' ');
    const moreTimesHtml = showMoreVisible
      ? `<p>If it’s easier, you can click on a slot below to book: <br /> <a href='${link.url}'>${link.title}</a></p>`
      : '';

    const htmlText = `<p>${anyOfTheseTimesText}</p>${slotsHtml}${moreTimesHtml}`;

    // Use the copyToClipboard function to copy both versions
    copyToClipboard(htmlText, plainText);
    toast(<ToastGeneric title="Copied to clipboard" />, { position: 'bottom-center', style: { background: '#000' } });
  }, [anyOfTheseTimesText, duration, freeSlotsEntries, link, linkStyle, showMoreVisible]);

  const handleOnDelete = (start: string, end: string, date: string) => () => {
    const newStart = moment(`${date} ${start}`, 'YYYY-MM-DD h:mm A').toDate();
    const newEnd = moment(`${date} ${end}`, 'YYYY-MM-DD h:mm A').toDate();
    const newSlots = slotAtom.filter(
      (slot) => slot.start.toString() !== newStart.toString() && slot.end.toString() !== newEnd.toString()
    );
    return setSlotAtom(newSlots);
  };

  const handleOpenLink = () => {
    openLink?.(link.url);
  };
  const selectedSectionValuesString = `${linkStyle}${
    combineAdjacent ? `; Combine adjacent time slots` : ''
    }${Object.values(freeSlots).length > 3 && showAllTimes ? `; Show all times selected` : ''}`;
  
  return (
    <section className="flex flex-col gap-3 py-3">
      <div className="flex items-center justify-between w-full p-3 text-sm bg-[#f1f3f4] border rounded-lg text-primary-500">
        <span className="w-4/5 truncate">{link.title}</span>
        <button className="cursor-pointer" onClick={handleCopyLinkToClipboard}>
          <CopyIcon color="#279AF1" size={18} />
        </button>
      </div>
      <div className="p-3 bg-[#f1f3f4] border rounded-lg">
        {isLoadingFreeSlots ? (
          <FreeSlotsSkeleton />
        ) : freeSlotsEntries.length === 0 ? (
          <div className="flex flex-col gap-[10px]">
            <ParagraphText className="flex items-center text-base font-medium">No slots selected yet</ParagraphText>
            <ParagraphText className="flex items-center text-[14px] font-medium text-slate-400">
              Drag on your calendar to create Slots
            </ParagraphText>
            <div style={{ height: '200px', overflow: 'hidden' }}>
              <video src="/drag.mov" className="rounded-xl" autoPlay loop muted style={{ objectFit: 'cover', width: '100%' }} />
            </div>
          </div>
        ) : (
          <>
            <div className="text-left overflow-auto text-sm w-full ml-0 pointer max-h-[400px] m-4">
              <p className="flex justify-between text-sm font-medium">
                {anyOfTheseTimesText}
                <button className="hover:cursor-pointer" onClick={handleCopySlots}>
                  <CopyIcon color="#279AF1" size={18} />
                </button>
              </p>
            </div>
            <div className="flex flex-col">
              {freeSlotsEntries.map(([date, info], index) => (
                <div key={date} className="flex flex-row">
                  <Bullet size={10} className="mt-5 mr-2.5" color={linkStyle === 'Hyperlinked' ? '#279AF1' : 'black'} />
                  <div key={index} className="flex flex-col w-full mt-1.5">
                    <ParagraphText
                      className={cn(
                        'flex items-center text-base font-medium',
                        linkStyle === 'Hyperlinked' ? 'text-blue-600 cursor-pointer' : 'text-black'
                      )}>
                      {info.label}
                    </ParagraphText>
                    {info.slots.map((slotRange, slotIndex) => {
                      const startTime = slotRange[0];
                      const endTime = moment(slotRange[1] || startTime, 'h:mm A')
                        .add(duration, 'minutes')
                        .format('h:mm A');
                      return (
                        <div key={slotIndex} className="flex justify-between items-center mb-1.5 w-full">
                          <div className="flex items-center">
                            <SchedulingClockIcon className="mr-2" />
                            <p className="text-sm">{`${startTime} - ${endTime}`}</p>
                          </div>
                          <button type="button" onClick={handleOnDelete(startTime, endTime, date)}>
                            <DeleteIcon className="cursor-pointer hover:fill-red-500 transition-all" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
            {linkStyle === 'Hyperlinked' && showMoreVisible && (
              <div className="pt-1 text-sm font-medium">
                <p className="flex items-center">
                  You can just let me know or see more times
                  {!id ? (
                    <ToolTip className="text-[14px] w-full font-inter" size="sm" tooltip="Enabled after save">
                      <div className="ml-[2px]">
                        <span className="underline cursor-pointer text-primary-500">here</span>.
                      </div>
                    </ToolTip>
                  ) : (
                    <button type="button" onClick={handleOpenLink}>
                      <span className="underline cursor-pointer text-primary-500 ml-[2px]">here</span>.
                    </button>
                  )}
                </p>
              </div>
            )}
            {linkStyle === 'Plaintext URL' && (
              <div className="pt-1 text-sm font-medium">
                {showMoreVisible ? (
                  <p className="flex items-center">
                    You can just let me know or see more times
                    {!id ? (
                      <ToolTip className="text-[14px] w-full font-inter" size="sm" tooltip="Enabled after save">
                        <div className="ml-[2px]">
                          <span className="underline cursor-pointer text-primary-500">here</span>.
                        </div>
                      </ToolTip>
                    ) : (
                      <button type="button" onClick={handleOpenLink}>
                        <span className="underline cursor-pointer text-primary-500 ml-[2px]">here</span>.
                      </button>
                    )}
                  </p>
                ) : (
                  <p className="flex items-center">
                    You can just let me know or confirm
                    {!id ? (
                      <ToolTip className="text-[14px] w-full font-inter" size="sm" tooltip="Enabled after save">
                        <div className="ml-[2px]">
                          <span className="underline cursor-pointer text-primary-500">here</span>.
                        </div>
                      </ToolTip>
                    ) : (
                      <button type="button" onClick={handleOpenLink}>
                        <span className="underline cursor-pointer text-primary-500 ml-[2px]">here</span>.
                      </button>
                    )}
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Divider />
      <ToggleableSection title="Link Style" summary={selectedSectionValuesString} icon={IoMdOptions}>
        <>
          <Select
            options={OPTIONS_LINK_STYLE}
            label="Link Style"
            value={linkStyle}
            extractLabel={(option) => option}
            extractValue={(option) => option}
            onChange={handleLinkStyleChange}
          />
          <Switch
            className="text-slate-400 mt-2"
            label="Combine adjacent time slots"
            value={combineAdjacent}
            onChange={setCombineAdjacent}
          />
          {Object.values(freeSlots).length > 3 && (
            <Switch
              className="text-slate-400 mt-2"
              label="Show all times selected"
              value={showAllTimes}
              onChange={setShowAllTimes}
            />
          )}
        </>
      </ToggleableSection>
    </section>
  );
}

function FreeSlotsSkeleton() {
  return (
    <div className="max-w-sm animate-pulse h-[400px]">
      <div className="h-3.5 bg-gray-400 rounded-full w-48 mb-6" />
      <div className="h-3.5 bg-gray-400 rounded-full w-32 mt-2 mb-3" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[360px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full mb-3.5" />
      <div className="h-3.5 bg-gray-400 rounded-full w-32 mt-2 mb-3" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[330px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[300px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[360px]" />
      <div className="h-3.5 bg-gray-400 rounded-full w-32 mt-2 mb-3" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[360px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full mb-3.5" />
      <div className="h-3.5 bg-gray-400 rounded-full w-32 mt-2 mb-3" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[330px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[300px] mb-3.5" />
      <div className="h-3 bg-blue-300 rounded-full max-w-[360px]" />
    </div>
  );
}
