import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AnalyticsBrowser, Options } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { UserTraits } from '@segment/analytics-node';
import { EventProperties } from '@segment/analytics-core';

const SegmentContext = createContext<{
  identifyUser: (userId: string, traits?: UserTraits) => void;
  trackEvent: (event: string, properties?: EventProperties, options?: Options) => void;
  resetIdentifyUser: () => void;
  identifyGroup: (groupId: string, traits?: UserTraits) => void;
}>({
  identifyUser: () => {},
  trackEvent: () => {},
  resetIdentifyUser: () => {},
  identifyGroup: () => {},
});

export const SegmentProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(undefined);

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_FRONTEND) return;

    const analytics = AnalyticsBrowser.load(
      { writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_FRONTEND },
      { obfuscate: true }
    );
    void analytics.ready(() => {
      void analytics.track('Session Started');
      setAnalytics(analytics);
    });
  }, []);

  useEffect(() => {
    const handleRouteChangeComplete = () => analytics?.page();

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [analytics, router.events]);

  const identifyUser = (userId: string, traits?: UserTraits) => {
    analytics?.identify(userId, traits).catch(console.error);
  };

  const trackEvent = (event: string, properties?: EventProperties, options?: Options) => {
    analytics?.track(event, properties, options).catch(console.error);
  };

  const resetIdentifyUser = () => {
    analytics?.reset().catch(console.error);
  };

  const identifyGroup = (groupId: string, traits?: UserTraits) => {
    analytics?.group(groupId, traits).catch(console.error);
  };

  return (
    <SegmentContext.Provider
      value={{
        identifyUser,
        trackEvent,
        resetIdentifyUser,
        identifyGroup,
      }}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegment = () => useContext(SegmentContext);
