import { Task } from '@/models/TaskModel';
import { NameEmail } from '@/utils/contacts';

export type TaskArg = Omit<
  Task,
  'itemType' | 'id' | 'createdAt' | 'updatedAt' | 'userOrder' | 'subtasks' | 'earliestStartDate' | 'scheduleId'
> & {
  oldProjectIds?: string[];
  subtasks?: { id?: string; summary: string; isCompleted: boolean; projectIds?: string[] }[];
  scheduleId?: string;
  scheduleName?: string;
  scheduleConstraints?: string | null;
  earliestStartDate?: Date;
  userOrder?: number;
};

export const convertTaskToTaskArg = (task: Task): TaskArg => {
  const { itemType, id, userOrder, subtasks, scheduleId, earliestStartDate, ...rest } = task;
  return {
    ...rest,
    subtasks: subtasks?.map(({ id, summary, isCompleted }) => ({ id, summary, isCompleted })),
    scheduleId: scheduleId,
    earliestStartDate: earliestStartDate,
    userOrder: userOrder,
  };
};

export const convertTaskArgToTask = ({
  taskArg,
  taskId = 'temp',
  userOrder = 100000,
  contacts = [],
  subtasks = [],
  scheduleId = '',
  earliestStartDate = new Date(),
}: {
  taskArg: TaskArg;
  taskId?: string;
  userOrder?: number;
  contacts?: NameEmail[];
  subtasks?: Task['subtasks'];
  scheduleId?: string;
  earliestStartDate?: Date;
}): Task => {
  return {
    ...taskArg,
    id: taskId,
    createdAt: new Date(),
    updatedAt: new Date(),
    itemType: 'TASK',
    userOrder: taskArg.userOrder || userOrder,
    contacts,
    scheduleId,
    earliestStartDate,
    subtasks:
      taskArg.subtasks?.map((subtask) => {
        const originalSubtask = subtasks.find((s) => s.id === subtask.id)!;

        return {
          ...originalSubtask,
          ...subtask,
          id: subtask.id ?? 'temp',
          itemType: 'TASK',
          start: taskArg.start,
          end: taskArg.end,
          userOrder: originalSubtask?.userOrder || userOrder,
        };
      }) || subtasks,
  };
};
