import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { useGlobalSelectedDate } from '@/hooks/useGlobalSelectedDate';
import { useSettings } from '@/hooks/useSettings';
import { RoundedCalendarIcon } from '@/icons/RoundedCalendarIcon';
import moment from 'moment-timezone';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft } from 'react-icons/md';

import MDatePicker, { DateObject } from 'react-multi-date-picker';
import { CalendarDockButton } from '@/screens/home/CalendarView/CalendarDockButton';
import { useRightSidebar } from '@/components/RightSidebar';

type DatePickerProps = {
  date: Date;
  containerClassName?: string;
  renderButton: (_: unknown, onClick: () => void) => ReactNode;
  onChangeDate: (date: Date) => () => void;
};

export default function DatePicker({ onChangeDate, renderButton, date: _date, containerClassName }: DatePickerProps) {
  const { defaultTimeZone: timeZone } = useSettings();
  const [date, setDate] = useState<Date>(_date);
  const datePickerRef = useRef<HTMLDivElement & { closeCalendar: () => void }>();

  const handleOnChange = useCallback(
    (value: DateObject | DateObject[] | null) => {
      if (!value) return;
      if (value instanceof Array) return;

      const date = moment.tz(value.toDate(), timeZone).startOf('day').toDate();

      onChangeDate(date)();
      setDate(date);

      datePickerRef.current?.closeCalendar();
    },
    [onChangeDate, timeZone]
  );

  const handleOnOpen = useCallback(() => {
    setTimeout(() => {
      const firstDate = datePickerRef.current?.querySelector('.b-date');
      if (!(firstDate instanceof HTMLButtonElement)) return;
      firstDate.click();
    }, 1);
  }, []);

  return (
    <MDatePicker
      value={date}
      weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
      numberOfMonths={1}
      format="MMMM DD, YYYY hh:mm A"
      onChange={handleOnChange}
      ref={datePickerRef}
      render={renderButton}
      onOpen={handleOnOpen}
      headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
      hideYear
      className="
        select-none
        [&_.rmdp-calendar]:p-6
        [&_.rmdp-day-picker]:p-0
        [&_.rmdp-header-values]:ml-0
        [&_.rmdp-arrow]:mt-[7px]
        [&_.rmdp-arrow]:ml-[2px]
        [&_.rmdp-arrow]:border-gray-border
        [&_.rmdp-week-day]:text-gray-border
        [&_.rmdp-week-day]:font-light
        [&_.rmdp-day]:font-light
      "
      containerClassName={`${containerClassName}`}
    />
  );
}

export const SingleDayCalendarHeader = ({
  className,
  isMobile,
  onClose,
}: {
  className?: string;
  isMobile?: boolean;
  onClose?: () => void;
}) => {
  const { defaultTimeZone: timeZone } = useSettings();
  const { openRightSidebar, rightSidebarState } = useRightSidebar();
  const { globalSelectedDate, setGlobalSelectedDate } = useGlobalSelectedDate();
  const { globalDateRange, setGlobalDateRange } = useGlobalDateRange();

  const handleTodayClick = useCallback(() => {
    setGlobalSelectedDate(moment.tz(timeZone).startOf('day').toDate());
  }, [setGlobalSelectedDate, timeZone]);

  const changeDate = useCallback(
    (value: 1 | -1 | Date) => () => {
      let newSelectedDate;
      if (value instanceof Date) {
        newSelectedDate = moment.tz(value, timeZone).startOf('day').toDate();
      } else {
        newSelectedDate = moment.tz(globalSelectedDate, timeZone).add(value, 'days').toDate();
      }

      if (newSelectedDate.getTime() < globalDateRange.start.getTime()) {
        setGlobalDateRange({
          start: moment.tz(newSelectedDate, timeZone).subtract(1, 'month').toDate(),
        });
      } else if (globalDateRange.end.getTime() < newSelectedDate.getTime()) {
        setGlobalDateRange({
          end: moment.tz(newSelectedDate, timeZone).add(1, 'month').toDate(),
        });
      }
      setGlobalSelectedDate(newSelectedDate);
    },
    [globalDateRange, globalSelectedDate, setGlobalDateRange, setGlobalSelectedDate, timeZone]
  );

  const handleOpenCalendarControlPanel = () => {
    openRightSidebar({
      type: 'CALENDAR_DOCK',
    });
  };

  return (
    <header
      className={`flex flex-col items-center text-[14px] px-4 h-[140px] border-b border-solid border-gray-border mb-6 ${className}`}>
      <div className="flex w-full justify-between gap-4 py-6">
        <div className="flex gap-2.5">
          <button
            type="button"
            className="px-5 py-3 rounded-lg border border-gray-border text-sm"
            onClick={handleTodayClick}>
            Today
          </button>
          <div className="flex gap-8">
            <button onClick={changeDate(-1)}>
              <MdKeyboardArrowLeft size={24} />
            </button>
            <button onClick={changeDate(1)}>
              <MdKeyboardArrowRight size={24} />
            </button>
          </div>
        </div>
        {isMobile ? (
          <>
            {!rightSidebarState.show ? (
              <CalendarDockButton onOpen={handleOpenCalendarControlPanel} />
            ) : rightSidebarState.type !== 'CALENDAR_DOCK' ? (
              <CalendarDockButton onOpen={handleOpenCalendarControlPanel} />
            ) : null}
          </>
        ) : (
          <ToolTip tooltip="Close Sidebar">
            <button type="button" onClick={onClose}>
              <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
              <KeyboardShortcutIndicator shortcut="Esc" />
            </button>
          </ToolTip>
        )}
      </div>
      <DatePicker
        renderButton={(_, onClick) => (
          <button onClick={onClick} className="flex items-center justify-center flex-1 gap-6 text-black/60 text-sm">
            {moment.tz(globalSelectedDate, timeZone).format('MMMM DD, YYYY')}
            <RoundedCalendarIcon />
          </button>
        )}
        date={globalSelectedDate || new Date()}
        onChangeDate={changeDate}
      />
    </header>
  );
};
