import moment from 'moment-timezone';
import { EditionMode } from './types';
import { DateInterval, dateIntervalToRecurringInterval, RecurringInterval } from '@/utils/date';
import deepEqual from '@/utils/objects';

const MINUTES_IN_HOUR = 60;

export function minutesToPixels(hourHeight: number, minutes: number) {
  return (hourHeight * minutes) / MINUTES_IN_HOUR;
}

export function pixelsToMinutes(hourHeight: number, pixels: number) {
  return (pixels / hourHeight) * MINUTES_IN_HOUR;
}

export function hoursToPixels(hourHeight: number, hours: number) {
  return minutesToPixels(hourHeight, hours * MINUTES_IN_HOUR);
}

export function calcTop(hourHeight: number, timeZone: string, date: Date) {
  const m = moment.tz(date, timeZone);
  if (m.hours() < 0) m.hours(0);
  const startOfDay = m.clone().startOf('day');
  const minutes = m.diff(startOfDay, 'minutes');
  return minutesToPixels(hourHeight, minutes);
}

export function calcHeight(hourHeight: number, timeZone: string, start: Date, end: Date) {
  const startM = moment.tz(start, timeZone);
  let endM = moment.tz(end, timeZone);
  if (!endM.isSame(startM, 'day')) endM = startM.clone().startOf('day').add(1, 'day');
  if (endM.hours() > 24) endM.hours(23);
  const minutes = endM.diff(startM, 'minutes');
  return minutesToPixels(hourHeight, minutes);
}

export function sidebarTypeByEditionMode(editionMode: EditionMode) {
  switch (editionMode) {
    case EditionMode.EVENTS:
      return 'EVENT_EDITOR' as const;
    case EditionMode.BOOKING:
      return 'BOOKING_EDITOR' as const;
    default:
      return null;
  }
}

export function updateRecurringSlot(
  oldRslots: RecurringInterval[],
  oldSlot: DateInterval,
  newSlot: DateInterval | null,
  timeZone: string
) {
  const oldRSlot = dateIntervalToRecurringInterval(oldSlot, timeZone);
  const index = oldRslots.findIndex((rslot) => deepEqual(rslot, oldRSlot));
  if (index === -1) return oldRslots;

  const newRslots = [...oldRslots];

  if (newSlot) {
    newRslots[index] = dateIntervalToRecurringInterval(newSlot, timeZone);
  } else {
    newRslots.splice(index, 1);
  }

  return newRslots;
}
