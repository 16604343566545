import { useCallback, useMemo } from 'react';
import { Editor } from 'ff-novel';

import type { Editor as EditorType } from '@tiptap/core';

type Props = {
  value?: string | null;
  onChange: (value: string) => void;
  alwaysActive?: boolean;
};

export default function Description({ value, onChange, alwaysActive }: Props) {
  const handleOnContentChange = useCallback(
    (editor?: EditorType) => {
      if (!editor) return;
      if (!editor.getText()) return onChange('');
      onChange(JSON.stringify(editor.getJSON()));
    },
    [onChange]
  );

  const parsedValue = useMemo(() => {
    if (!value) return {};
    // support plain text
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }, [value]);

  return (
    <section className="relative flex flex-col gap-3 py-5 border-t border-gray-border">
      <Editor
        className={`
        novel-relative [&_.tiptap]:text-sm min-h-[140px] novel-w-full \
        novel-max-w-screen-lg novel-border-stone-200 novel-bg-white \
        novel-rounded-lg novel-border [&_.tiptap]:!px-8 [&_.tiptap]:!py-5 \
        before:absolute before:bg-white before:-top-2.5 before:left-4 before:text-xss \
        before:text-gray-border before:content-['Description'] before:p-1 \
        ${alwaysActive && 'min-h-[400px] before:hidden'}
        `}
        disableLocalStorage
        defaultValue={parsedValue}
        onDebouncedUpdate={handleOnContentChange}
      />
    </section>
  );
}
