import { MdPerson } from 'react-icons/md';
import { NameEmail } from '@/utils/contacts';
import { useContacts } from '@/hooks/useContacts';
import { useCallback, useMemo, useState } from 'react';
import { cn } from 'ui/cn';
import { CheckboxIcon } from '@/icons/CheckboxIcon';
import Combobox from '@/components/Combobox';

type Props = {
  email: string;
  getSelectedEmails: () => (string | null)[];
  onChange: (contacts: NameEmail[]) => void;
  alwaysExpanded?: boolean;
};

export default function Contacts({ email, getSelectedEmails, onChange, alwaysExpanded }: Props) {
  const { contacts: allContacts } = useContacts({});
  const [open, setOpen] = useState(false);

  const contact = useMemo((): NameEmail => {
    if (!allContacts) return { email };

    const contact = Array.isArray(allContacts) ? allContacts.find((contact) => contact.email === email) : undefined;
    return contact || { email };
  }, [allContacts, email]);

  const getSelectedContacts = useCallback(() => {
    const selectedEmails = getSelectedEmails();
    const getContacts = Array.isArray(allContacts)
      ? allContacts.filter((contact) => selectedEmails.includes(contact.email))
      : [];
    return getContacts;
  }, [allContacts, getSelectedEmails]);

  const getItems = useCallback(() => {
    const selectedContacts = getSelectedContacts();

    return Array.isArray(allContacts)
      ? allContacts.map((contact) => {
          const checked = selectedContacts.some((i) => i.email === contact.email);

          return {
            content: (
              <div key={contact.id} className="flex items-center gap-2">
                <CheckboxIcon checked={checked} />
                {contact.name || contact.email}
              </div>
            ),
            onSelect: () => {
              if (checked) {
                onChange(selectedContacts.filter((i) => i.email !== contact.email));
                return;
              }
              onChange([...selectedContacts, contact]);
            },
          };
        })
      : [];
  }, [allContacts, getSelectedContacts, onChange]);

  return (
    <Combobox
      key={contact.email}
      button={
        <div
          className={cn(
            'inline-flex align-top items-center px-1 text-gray-500 rounded bg-select-light-gray whitespace-nowrap hover:cursor-pointer'
          )}>
          <MdPerson fill="#8C8C8C" className="shrink-0" />
          <span
            className={cn(
              'text-[14px]',
              !open && !alwaysExpanded
                ? 'ml-0 overflow-hidden transition-all max-w-0 group-hover:max-w-xs group-hover:ml-2'
                : 'ml-2'
            )}>
            {contact.name || contact.email}
          </span>
        </div>
      }
      items={getItems()}
      onOpenChange={setOpen}
      align="start"
    />
  );
}
