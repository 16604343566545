import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { DragStart, DropResult } from 'react-beautiful-dnd';

type DnDContext = { isDragging: boolean; itemId: string; sourceId: string; desinationId?: string };

const dndContextAtom = atom<DnDContext>({ isDragging: false, itemId: '', sourceId: '' });

export default function useDndContext() {
  const [dndContext, setDndContext] = useAtom(dndContextAtom);

  const onDragStart = useCallback(
    (initial: DragStart) => {
      setDndContext({ isDragging: true, itemId: initial.draggableId, sourceId: initial.source.droppableId });
    },
    [setDndContext]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setDndContext({
        isDragging: false,
        itemId: result.draggableId,
        sourceId: result.source.droppableId,
        desinationId: result.destination?.droppableId,
      });
    },
    [setDndContext]
  );

  return { dndContext, onDragStart, onDragEnd };
}
