import { useMemo, useRef } from 'react';
import cuid from 'cuid';
import { ReactNodeViewRenderer } from '@tiptap/react';
import type { Editor, JSONContent } from '@tiptap/core';
import { mergeAttributes, Node } from '@tiptap/core';
import type { Node as NodeType } from 'prosemirror-model';

import EditorItemReact from './EditorItem';
import { getItemTypeFromEditor } from '../../utils/editor';
import { NEW_ITEM_PREFIX } from '../../utils/item';
import { ItemType } from '../../types';


function onBackspace({ editor }: { editor: Editor }) {
  const positionFrom = editor.state.selection.from;
  const isSelecting = editor.state.selection.from !== editor.state.selection.to;

  const currentNode = editor.state.selection.$from.node();
  let prevNode: NodeType | undefined;

  try {
    editor.state.doc.nodesBetween(0, positionFrom, (node) => {
      if (node.type.name !== 'item') return;
      if (node.eq(currentNode)) throw new Error('found');
      prevNode = node;
    });
  } catch {
    /* empty */
  }

  const isAtStartOfNode = !isSelecting && editor.state.selection.$from.start() === positionFrom;

  if (currentNode.attrs.parentId && isAtStartOfNode) {
    editor.chain().lift('details').lift('collapsible').updateAttributes('item', { parentId: null }).run();

    return true;
  }

  if (!currentNode.textContent.trim()) {
    editor.commands.deleteNode('item');

    return true;
  }

  if (isAtStartOfNode && prevNode?.attrs.parentId) {
    editor.chain().joinBackward().joinBackward().joinBackward().run();
    return true;
  }

  return false;
}

function calculateUserOrder(prevValue?: number, nextValue?: number) {
  if (!prevValue && !nextValue) return undefined;
  if (!prevValue) return (2 * nextValue! - 1) / 2;
  return (prevValue + nextValue!) / 2;
}

export default function useEditorItem(defaultItemType: ItemType | null, onSubmit?: (editor: Editor) => void, onboarding?: boolean) {
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  return useMemo(
    () =>
      Node.create({
        name: 'item',
        group: 'block',
        content: 'inline*',
        parseHTML() {
          return [
            {
              tag: 'item',
            },
          ];
        },
        renderHTML({ HTMLAttributes }) {
          return ['item', mergeAttributes(HTMLAttributes), 0];
        },
        addKeyboardShortcuts() {
          return {
            Enter: ({ editor }) => {
              if (document.getElementById('mention-list')) {
                return false;
              }

              if (onSubmitRef.current) {
                onSubmitRef.current(editor);
              }

              if (editor.state.doc.attrs.disableNewLine) {
                return true;
              }
              if (editor.state.doc.attrs.onlyCreate) {
                if (!onboarding) {
                  editor.commands.blur();
                }
                return true;
              }

              const currentNode = editor.state.selection.$from.node();
              const from = editor.state.selection.from;
              const to = editor.state.selection.$from.end();

              let nextNode: NodeType | undefined;
              try {
                editor.state.doc.descendants((node, pos) => {
                  if (node.attrs?.type !== ItemType.TASK || pos <= from) return;
                  nextNode = node;
                  throw new Error('found');
                });
              } catch {
                /* empty */
              }

              if (!currentNode.attrs.editable) {
                editor.commands.insertContentAt(to, [{ type: 'item' }]);
                return true;
              }

              const suggestions: JSONContent[] = [];
              editor.state.doc.nodesBetween(from, to, (node) => {
                if (!node.type.name.includes('SUGGESTION')) return;
                suggestions.push({ type: 'text', text: ' ' }, node.toJSON());
              });

              if (suggestions.length) {
                editor.commands.insertContentAt(from, suggestions);
              }
              const userOrder = calculateUserOrder(currentNode?.attrs.userOrder, nextNode?.attrs.userOrder);

              return editor
                .chain()
                .splitBlock()
                .resetAttributes('item', ['id', 'editable', 'index', 'color', 'userOrder', 'scheduleType'])
                .updateAttributes('item', { userOrder, id: `${NEW_ITEM_PREFIX}_${cuid()}` })
                .run();
            },
            Tab: ({ editor }) => {
              if (document.getElementById('mention-list')) return true;

              if (getItemTypeFromEditor(editor) !== ItemType.TASK) {
                return true;
              }

              const currentNode = editor.state.selection.$head.node();
              if (!currentNode.attrs.editable) return true;

              if (currentNode.attrs.parentId) {
                editor
                  .chain()
                  .lift('details')
                  .lift('collapsible')
                  .updateAttributes(this.type, { parentId: null })
                  .run();
                return true;
              }

              const prevNodes: NodeType[] = [];
              try {
                editor.state.doc.nodesBetween(0, editor.state.selection.from, (node) => {
                  if (!['item', 'collapsible'].includes(node.type.name)) {
                    return;
                  }
                  if (node.eq(currentNode)) throw new Error('found');
                  prevNodes.unshift(node);
                });
              } catch {
                /* empty */
              }

              if (prevNodes[0]?.attrs.type !== 'TASK') {
                return false;
              }

              const prevNodeId = prevNodes[0].attrs.parentId || prevNodes[0].attrs.id;
              const hasCollapsible = prevNodes.some(
                (node) => node.type.name === 'collapsible' && node.attrs.id === prevNodeId
              );

              let chainCommands = editor.chain();

              if (hasCollapsible) {
                chainCommands = chainCommands.selectTextblockStart().joinBackward().joinBackward();
              }
              chainCommands.updateAttributes(this.type, { parentId: prevNodeId }).run();
              return true;
            },
            'Shift-Tab': ({ editor }) => {
              if (document.getElementById('mention-list')) return true;

              editor.chain().lift('details').lift('collapsible').updateAttributes(this.type, { parentId: null }).run();
              return true;
            },
            Backspace: onBackspace,
            'Mod-Backspace': onBackspace,
            'Alt-Backspace': onBackspace,
            'Shift-Backspace': onBackspace,
            'Mod-a': ({ editor }) => {
              const from = editor.state.selection.$from.start();
              const to = editor.state.selection.$from.end();
              editor.chain().setTextSelection({ from, to }).run();
              return true;
            },
            Escape: ({ editor }) => {
              editor.commands.blur();
              return true;
            },
          };
        },
        addAttributes() {
          return {
            type: {
              default: defaultItemType || '',
              parseHTML: (element) => element.getAttribute('data-type'),
              renderHTML: (attrs) => ({ 'data-type': attrs.type }),
            },
            id: {
              default: `${NEW_ITEM_PREFIX}_${cuid()}`,
              parseHTML: (element) => element.getAttribute('data-id'),
              renderHTML: (attrs) => ({ 'data-id': attrs.id }),
            },
            checked: {
              default: false,
              parseHTML: (element) => element.getAttribute('data-checked') === 'true',
              renderHTML: (attrs) => ({ 'data-checked': attrs.checked }),
            },
            parentId: {
              default: null,
              parseHTML: (element) => element.getAttribute('data-parent-id'),
              renderHTML: (attrs) => ({ 'data-parent-id': attrs.parentId }),
            },
            editable: {
              default: true,
              parseHTML: (element) => element.getAttribute('data-editable'),
              renderHTML: (attrs) => ({ 'data-editable': attrs.editable }),
            },
            scheduleType: {
              default: null,
              parseHTML: (element) => element.getAttribute('data-schedule-type'),
              renderHTML: (attrs) => ({ 'data-schedule-type': attrs.scheduleType }),
            },
            index: {
              default: -2,
              parseHTML: (element) => element.getAttribute('data-index'),
              renderHTML: (attrs) => ({ 'data-index': attrs.index }),
            },
            color: {
              default: 'black',
              parseHTML: (element) => element.getAttribute('data-color'),
              renderHTML: (attrs) => ({ 'data-color': attrs.color }),
            },
            userOrder: {
              default: undefined,
              parseHTML: (element) => element.getAttribute('data-user-order'),
              renderHTML: (attrs) => ({ 'data-user-order': attrs.userOrder }),
            },
            onboarding: {
              default: onboarding,
              parseHTML: (element) => element.getAttribute('data-onboarding'),
              renderHTML: (attrs) => ({ 'data-onboarding': attrs.onboarding }),
            },
          };
        },
        addNodeView() {
          return ReactNodeViewRenderer(EditorItemReact, {
            className: '[&.is-empty_p]:before:content-[attr(data-placeholder)]',
          });
        },
      }),
    [defaultItemType, onboarding]
  );
}
