type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const CopyIcon = ({ className, size = 18, color }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 19"
    fill="none">
    <path
      d="M10.6666 6.27734H2.33329C1.41413 6.27734 0.666626 7.02484 0.666626 7.94401V16.2773C0.666626 17.1965 1.41413 17.944 2.33329 17.944H10.6666C11.5858 17.944 12.3333 17.1965 12.3333 16.2773V7.94401C12.3333 7.02484 11.5858 6.27734 10.6666 6.27734Z"
      fill={color ? color : '#111317'}
    />
    <path
      d="M15.6666 1.27734H7.33329C6.89127 1.27734 6.46734 1.45294 6.15478 1.7655C5.84222 2.07806 5.66663 2.50198 5.66663 2.94401V4.61068H12.3333C12.7753 4.61068 13.1992 4.78627 13.5118 5.09883C13.8244 5.41139 14 5.83532 14 6.27734V12.944H15.6666C16.1087 12.944 16.5326 12.7684 16.8451 12.4559C17.1577 12.1433 17.3333 11.7194 17.3333 11.2773V2.94401C17.3333 2.50198 17.1577 2.07806 16.8451 1.7655C16.5326 1.45294 16.1087 1.27734 15.6666 1.27734Z"
      fill={color ? color : '#111317'}
    />
  </svg>
);
