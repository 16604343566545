import { useState } from 'react';
import Button from '@/components/Button';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { RecurringInstance } from '@/models/EventModel';

type EditRecurrentEventModalProps = {
  onNo: () => void;
  onYesRecurrent?: (picked: RecurringInstance) => void;
  className?: string;
  itemType: "TASK" | "EVENT";
};
type FormValues = {
  picked: string;
};

export const EditRecurrentEventModal = ({ onNo, onYesRecurrent, className, itemType }: EditRecurrentEventModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const options = ['This event', 'This and following events', 'All events'];

  const formik = useFormik<FormValues>({
    initialValues: {
      picked: 'This event',
    },
    onSubmit: () => {},
  });

  const handleNo = () => {
    onNo();
    setIsOpen(false);
  };

  const handleYesRecurrent = () => {
    const picked = () => {
      switch (formik.values.picked) {
        case 'This event':
          return 'THIS_INSTANCE';
        case 'This and following events':
          return 'THIS_AND_FOLLOWING';
        case 'All events':
          return 'ALL_INSTANCES';
        default:
          return 'THIS_INSTANCE';
      }
    };
    onYesRecurrent!(picked() as RecurringInstance);
    setIsOpen(false);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black/50 z-[999] ${isOpen ? 'flex' : 'hidden'} ${className}`}>
          <div className="overflow-hidden flex flex-col justify-between m-auto bg-white rounded-lg min-h-fit min-w-[350px] z-40 shadow-xl">
            <h1 className="w-full text-[24px] text-left px-[20px] pt-[20px] bg-white font-inter mb-[20px] font-medium">
              {`Edit recurring ${itemType.toLowerCase()}`}
            </h1>
            <Form>
              <div
                className="flex flex-col px-[20px] py-[10px] text-[14px] gap-1 mb-[8px] font-normal"
                role="group"
                aria-labelledby="my-radio-group">
                {options.map((option) => (
                  <label key={option} className="flex items-center">
                    <Field className="mr-[10px] scale-125" type="radio" name="picked" value={option} />
                    {option}
                  </label>
                ))}
              </div>
              <div className="flex flex-row w-full gap-4 m-0 bg-gray-100 h-[80px] items-center justify-end pr-[20px]">
                <Button variant="secondary" className="h-[48px]" onClick={handleNo}>
                  Cancel
                </Button>
                <Button variant="primary" className="h-[48px] flex gap-1" onClick={() => handleYesRecurrent()}>
                  Ok
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </FormikProvider>
    </>
  );
};
