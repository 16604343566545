import { useEasySession } from '@/hooks/useEasySession';
import { Avatar } from '@/components/Avatar';

type UserAvatarProps = {
  onClick?: () => void;
  disabled?: boolean;
};

export const UserAvatar = ({ onClick, disabled }: UserAvatarProps) => {
  const { user } = useEasySession();

  return (
    <button onClick={onClick} className="grid mb-1 overflow-hidden rounded-full place-items-center" disabled={disabled}>
      <Avatar picture={user?.picture} name={user?.name} size={30} />
    </button>
  );
};
