import { ReactNode } from "react";
import { ReactRenderer } from '@tiptap/react';
import { Mention } from '@tiptap/extension-mention';
import tippy, { Instance } from 'tippy.js';
import MentionList from './MentionList';
import { PluginKey } from '@tiptap/pm/state';
import type { Suggestion } from './types';
import type { Editor, Range } from '@tiptap/core';
import { getItemFromEditor } from '../../utils/editor';

type Options = {
  char: string;
  items: (args: { query: string; editor: Editor }) => Suggestion[] | Promise<Suggestion[]>;
  renderSuggestion?: (args: { suggestion: Suggestion; editor: Editor }) => ReactNode;
  renderCategory?: (args: { category: string; editor: Editor }) => ReactNode;
  command?: (args: { editor: Editor; range: Range; props: any }) => void;
  onExit?: () => void;
  addNodeView?: any;
};

function bindEditor<FN extends (...args: any) => any>(fn: FN, editor: Editor) {
  return (args: Parameters<FN>['0']): ReturnType<FN> => fn({ ...args, editor });
}

export default function createSuggestions({
  char,
  renderSuggestion,
  renderCategory,
  onExit,
  addNodeView,
  ...rest
}: Options) {
  const name = `SUGGESTION_${char}`;

  return Mention.extend({
    name,
    addNodeView,
  }).configure({
    HTMLAttributes: {
      class: 'bg-select-light-gray rounded px-1 leading-6 inline-block text-gray-500',
    },
    renderLabel: ({ node }) => node.attrs.label,
    suggestion: {
      allowSpaces: true,
      pluginKey: new PluginKey(name),
      char,
      ...rest,
      render: () => {
        let component: ReactRenderer | undefined;
        let popup: Instance[] | null = null;

        return {
          onStart: (props) => {
            const item = getItemFromEditor(props.editor);
            if (item?.parentId) {
              return;
            } // sub-tasks don't show the submenu decorator
            component = new ReactRenderer(MentionList, {
              props: {
                ...props,
                renderSuggestion: renderSuggestion ? bindEditor(renderSuggestion, props.editor) : undefined,
                renderCategory: renderCategory ? bindEditor(renderCategory, props.editor) : undefined,
              },
              editor: props.editor as any,
            });

            if (!props.clientRect) {
              return;
            }

            const clientRect = props.clientRect();

            popup = tippy('body', {
              getReferenceClientRect: clientRect ? () => clientRect : null,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },

          onUpdate(props) {
            component?.updateProps({
              ...props,
              renderSuggestion: renderSuggestion ? bindEditor(renderSuggestion, props.editor) : undefined,
              renderCategory: renderCategory ? bindEditor(renderCategory, props.editor) : undefined,
            });

            if (!props.clientRect) {
              return;
            }

            popup?.[0].setProps({
              getReferenceClientRect: props.clientRect as () => DOMRect,
            });
          },

          onKeyDown(props) {
            if (props.event.key === 'Escape') {
              popup?.[0].hide();

              return true;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return component?.ref?.onKeyDown(props);
          },

          onExit() {
            if (popup?.length && !popup[0].state.isDestroyed) {
              popup[0].destroy();
              popup = null;
            }
            component?.destroy();
            onExit?.();
          },
        };
      },
    },
  });
}
