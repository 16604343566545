import { Select } from '@/components/Select';
import { useRouter } from 'next/router';
import useLeftSidebar from './LeftSidebar/useLeftSidebar';
import { findTimeZone } from '@/utils/date';
import { useMemo } from 'react';
import { LabelValue } from '@/utils/labelValue';

type Props = {
  timeZone: string;
  timeZones: string[];
  onChange: (timeZone: string) => void;
};

export const TimeZoneSelector = ({ timeZone, timeZones, onChange }: Props) => {
  const router = useRouter();
  const { closeLeftSidebar } = useLeftSidebar();

  const handleTimeZoneChange = (newTimeZone: LabelValue) => {
    if (newTimeZone.value === 'add' || newTimeZone.value === 'edit') {
      closeLeftSidebar();
      void router.push('/settings/timezones');
      return;
    }

    onChange(newTimeZone.value);
  };

  const options = useMemo(
    () => [
      ...timeZones.map((tz) => findTimeZone(tz)! as LabelValue),
      { label: 'Add new time zone', value: 'add' },
      { label: 'Edit time zones', value: 'edit' },
    ],
    [timeZones]
  );

  return (
    <Select<LabelValue>
      extractLabel={(option) => option.label}
      extractValue={(option) => option.value}
      label="Default timezone"
      onChange={handleTimeZoneChange}
      options={options}
      classNameOption="last:text-primary-500 last:underline [&:nth-last-child(2)]:text-primary-500 [&:nth-last-child(2)]:underline"
      className="text-xs"
      value={options.find((tz) => tz.value === timeZone)}
    />
  );
};
