import { useEffect, useRef, useState } from 'react';

type Props = {
  width?: number;
};

export const useScreenWidth = (props: Props) => {
  const width = useRef<number | undefined>();
  const [band, setBand] = useState<'SMALLER' | 'SAME' | 'BIGGER' | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      const lastWidth = width.current;
      width.current = window.innerWidth;

      if (props.width === undefined) {
        setBand(undefined);
        return;
      }

      if (window.innerWidth > props.width) {
        if (!lastWidth || lastWidth <= props.width) {
          setBand('BIGGER');
        }
        return;
      }

      if (window.innerWidth < props.width) {
        if (!lastWidth || lastWidth >= props.width) {
          setBand('SMALLER');
        }
        return;
      }

      if (window.innerWidth === props.width) {
        if (!lastWidth || lastWidth !== props.width) {
          setBand('SAME');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.width]);

  return band;
};
