type Props = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const AddNewIcon = ({ className, width = 16, height = 15, color = '#000000' }: Props) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 14.1666V7.49992M7.99967 7.49992V0.833252M7.99967 7.49992H14.6663M7.99967 7.49992H1.33301"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="1.08333"
      strokeLinecap="round"
    />
  </svg>
);
