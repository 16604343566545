import { useCallback } from 'react';
import { useEvents } from './useEvents';
import { useNotes } from './useNotes';
import { useTasks } from './useTasks';

import type { Task } from '@/models/TaskModel';
import type { Event } from '@/models/EventModel';
import type { Note } from '@/models/NoteModel';

type Type = 'TASK' | 'EVENT' | 'NOTE';
type ReturnType<T extends Type | undefined> = T extends 'TASK'
  ? Task
  : T extends 'EVENT'
    ? Event
    : T extends 'NOTE'
      ? Note
      : Task | Event | Note;

export default function useFindRef() {
  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { notes } = useNotes();

  return useCallback(
    <T extends Type>(id: string, type?: T): ReturnType<T> => {
      const _tasks = tasks?.results || [];
      const _notes = notes?.results || [];
      const _events = events || [];

      switch (type) {
        case 'TASK':
          return _tasks.find((task) => task.id === id)! as ReturnType<T>;
        case 'EVENT':
          return _events.find((event) => event.id === id)! as ReturnType<T>;
        case 'NOTE':
          return _notes.find((note) => note.id === id)! as ReturnType<T>;
        default:
          return [..._tasks, ..._notes, ..._events].find((item) => item.id === id) as ReturnType<T>;
      }
    },
    [tasks, events, notes]
  );
}
