import { useEffect, useState } from 'react';
import Switch from '@/components/Switch';
import { FloatingInput } from '@/components/FloatingInput';
import { Select } from '@/components/Select';
import { useDeleteEvent } from '@/hooks/useEvents';

type HoldManagerProps = {
  initialHasAHold: boolean | undefined;
  initialHoldType: string | undefined;
  initialHoldDuration: number | undefined;
  initialHoldDurationUnit: string | undefined;
  initialHoldEventIds: string[] | undefined;
  initialHoldExpiration: Date | undefined;
  onHoldChange: (hasAHold: boolean, holdType: string, holdDuration: number, holdDurationUnit: string, holdExpiration: Date | undefined, holdEventIds: string[]) => void;
};

export default function HoldManager({
  initialHasAHold,
  initialHoldType,
  initialHoldDuration,
  initialHoldDurationUnit,
  initialHoldEventIds,
  initialHoldExpiration,
  onHoldChange,
}: HoldManagerProps) {
  const { deleteEvent } = useDeleteEvent();
  const [hasAHold, setHasAHold] = useState(initialHasAHold || false);
  const [holdType, setHoldType] = useState(initialHoldType || 'until recipient selects a time');
  const [holdDuration, setHoldDuration] = useState(initialHoldDuration || 1);
  const [holdDurationUnit, setHoldDurationUnit] = useState(initialHoldDurationUnit || 'Days');
  const [holdExpiration, setHoldExpiration] = useState(initialHoldExpiration || undefined);
  const [holdEventIds, setHoldEventIds] = useState(initialHoldEventIds || []);

  useEffect(() => {
    onHoldChange(hasAHold, holdType, holdDuration, holdDurationUnit, holdExpiration, holdEventIds);
  }, [hasAHold, holdType, holdDuration, holdDurationUnit, holdExpiration, holdEventIds, onHoldChange]);

  return (
    <div>
      { initialHoldEventIds && initialHoldEventIds.length > 0 ? (
        <>
          <div>
            <p>
              {initialHoldEventIds.length} holds already created {holdType}
              {initialHoldExpiration && `until ${initialHoldExpiration.toLocaleDateString()}`}
              <br /> 
              <button 
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  initialHoldEventIds.forEach((eventId) => {
                    deleteEvent({ eventId });
                  });
                  setHoldEventIds([]);
                  setHoldExpiration(undefined);
                  setHasAHold(false);
                }}
              >
                Delete existing holds
              </button>
            </p>
          </div>
        </>
      ) : (
        <>
          <Switch label="Add a hold" value={hasAHold} onChange={setHasAHold} />
          {hasAHold && (
            <div className="mt-2">
              <Select
                options={['for the time selected', 'until recipient selects a time']}
                value={holdType}
                onChange={setHoldType}
                label="Hold selected times"
              />
              <div className="mt-4">
                {holdType === 'for the time selected' && (
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row w-full gap-3">
                      <FloatingInput
                        type="number"
                        className="w-1/2"
                        name="holdTime"
                        label="Number"
                        variant="primary"
                        rounded
                        min={1}
                        max={60}
                        value={holdDuration}
                        onChange={(e) => setHoldDuration(Number(e.target.value))}
                      />
                      <Select
                        className="w-1/2"
                        options={['Hour(s)', 'Day(s)']}
                        label="Interval"
                        value={holdDurationUnit}
                        onChange={setHoldDurationUnit}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
