// NavigationInboxTabs.tsx
import React, { useState, useEffect } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { EmailLabel, LabelDisplayNames, MAIN_TABS, MORE_TABS, icon } from './utils/constants';

interface NavigationTabsProps {
  selectedTab: EmailLabel;
  onTabChange: (newTab: string) => void;
  moreTabsVisible: boolean;
  setMoreTabsVisible: (visible: boolean) => void;
}

export const NavigationInboxTabs = ({ selectedTab, onTabChange, moreTabsVisible, setMoreTabsVisible }: NavigationTabsProps) => {
  const [currentMoreIndex, setCurrentMoreIndex] = useState(0);
  const [showSelectedMoreTab, setShowSelectedMoreTab] = useState(false);
  const [nameMoreTab, setNameMoreTab] = React.useState<EmailLabel>(MORE_TABS[0]);

  useEffect(() => {
    setCurrentMoreIndex(0);
  }, [moreTabsVisible]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (moreTabsVisible) {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          const nextIndex = (currentMoreIndex + 1) % MORE_TABS.length;
          setCurrentMoreIndex(nextIndex);
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          const nextIndex = (currentMoreIndex - 1 + MORE_TABS.length) % MORE_TABS.length;
          setCurrentMoreIndex(nextIndex);
        } else if (event.key === 'Enter') {
          const selectedMoreTab = MORE_TABS[currentMoreIndex];
          onTabChange(selectedMoreTab);
          setMoreTabsVisible(false);
          setShowSelectedMoreTab(true);
          setNameMoreTab(selectedMoreTab);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentMoreIndex, moreTabsVisible, onTabChange, setMoreTabsVisible]);

  return (
    <div className="flex flex-col h-auto bg-white relative">
      <Tabs.Root value={selectedTab} onValueChange={onTabChange} orientation="horizontal" className="flex flex-1 min-h-0 mt-2">
        <Tabs.List className="flex overflow-hidden overflow-x-auto mb-2">
          <Tabs.Trigger
            onClick={() => setMoreTabsVisible(!moreTabsVisible)}
            value={"More"}
            key={"More"}
            className={`pl-3 pr-3 h-[45px] flex items-center justify-center text-[14px] leading-[21px] font-medium select-none first:rounded-tl-md last:rounded-tr-md hover:text-black ${
              moreTabsVisible ? "text-black border-b border-black" : "text-gray-400"
            }`}>
              <div className="flex items-center gap-[4px]">
                {icon("MORE")}
              </div>
          </Tabs.Trigger>
          {showSelectedMoreTab && (
            <Tabs.Trigger
              value={nameMoreTab}
              key={"MoreTab"}
              className="text-gray-400 pl-2 pr-2 h-[45px] flex items-center justify-center text-[14px] leading-[21px] font-medium select-none first:rounded-tl-md last:rounded-tr-md hover:text-black data-[state=active]:border-b data-[state=active]:border-black data-[state=active]:text-black">
                <div className="flex items-center gap-[4px]">
                  {icon(nameMoreTab)}
                  <p className="text-black-800 min-w-[50px]">{LabelDisplayNames[nameMoreTab]}</p>
                </div>
            </Tabs.Trigger>
          )}
          {MAIN_TABS.map((tab) => (
            <Tabs.Trigger
              onClick={() => {
                onTabChange(tab)
                setShowSelectedMoreTab(false);
              }}
              value={tab}
              key={tab}
              className="text-gray-400 pl-2 pr-2 h-[45px] flex items-center justify-center text-[14px] leading-[21px] font-medium select-none first:rounded-tl-md last:rounded-tr-md hover:text-black data-[state=active]:border-b data-[state=active]:border-black data-[state=active]:text-black">
                <div className="flex items-center gap-[4px]">
                  {icon(tab)}
                  <p className="text-black-800 min-w-[50px]">{LabelDisplayNames[tab]}</p>
                </div>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>
      {moreTabsVisible && (
        <div className="absolute top-14 z-10 w-40 bg-white shadow-lg mt-1 rounded-md">
          {MORE_TABS.map((tab, index) => (
            <div
              key={tab}
              onClick={() => {
                onTabChange(tab);
                setMoreTabsVisible(false);
                setShowSelectedMoreTab(true);
                setNameMoreTab(tab);
              }}
              className={`px-4 py-2 text-sm flex items-center gap-[4px] text-gray-700 cursor-pointer hover:bg-gray-100 ${index === currentMoreIndex ? "bg-blue-100" : ""}`}>
              {icon(tab)}
              {LabelDisplayNames[tab]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
