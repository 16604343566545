import { useState } from 'react';

export default function useModal() {
  const [isOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleModal = () => {
    setIsModalOpen(!isOpen);
  };

  return { isOpen, openModal, closeModal, toggleModal };
}
