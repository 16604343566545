import { atomWithStorage } from 'jotai/utils';

export const todayAtom = atomWithStorage<boolean>('todayAtom', false);
export const thisWeekCollapsedAtom = atomWithStorage<boolean>('thisWeekCollapsedAtom', false);
export const upcomingCollapsedAtom = atomWithStorage<boolean>('upcomingCollapsedAtom', true);
export const pastCollapsedAtom = atomWithStorage<boolean>('pastCollapsedAtom', true);
export const unscheduledCollapsedAtom = atomWithStorage<boolean>('unscheduledCollapsedAtom', true);
export const parentTaskCollapsedAtom = atomWithStorage<string[]>('parentTaskCollapsedAtom', []);
export const shownDatesAtom = atomWithStorage<string[]>('ShownDates', []);
export const showDockedNavBarAtom = atomWithStorage<boolean>('showDockedNavBar', false);
