import { CSSProperties, ReactNode } from 'react';

type Props = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

export function PageTitle({ children, className, style }: Props) {
  return (
    <h1 className={`font-lexend text-2xl leading-[100%] font-semibold select-none ${className}`} style={style}>
      {children}
    </h1>
  );
}

export function HeaderText({ children, className, style }: Props) {
  return (
    <h2 className={`font-lexend text-[24px] leading-[24px] select-none ${className}`} style={style}>
      {children}
    </h2>
  );
}

export function SubHeaderText({ children, className, style }: Props) {
  return (
    <h3 className={`text-[14px] select-none ${className}`} style={style}>
      {children}
    </h3>
  );
}

export function ParagraphText({ children, className, style }: Props) {
  return (
    <p className={`text-sm font-medium ${className}`} style={style}>
      {children}
    </p>
  );
}
