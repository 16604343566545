import ToolTip from '@/components/Tooltip';
import useLeftSidebar from '../useLeftSidebar';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { ContactsIcon } from '@/icons/ContactsIcon';

export const EditGroupHeader = ({ add = false }: { add: boolean }) => {
  const { openLeftSidebar } = useLeftSidebar();
  const handleOpenLeftSidebarAllGroups = () => {
    openLeftSidebar({
      type: 'ALL_GROUPS',
    });
  };

  return (
    <div className="flex flex-col gap-6 px-4 py-[29px] h-[140px]">
      <div className="flex items-center justify-end">
        <ToolTip tooltip={add ? 'Close Add Group bar' : 'Close Edit Group bar'}>
          <button type="button" onClick={handleOpenLeftSidebarAllGroups}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center">
        <ContactsIcon size={18} color="#000000" className="mr-[10px]" />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">Edit Contact Group</h1>
      </div>
    </div>
  );
};
