import { useEffect, useMemo, useRef } from 'react';
import createSuggestions from './create';
import { Suggestion, SuggestionType } from './types';
import { capitalize } from 'utils/string';
import { useEasySession } from '@/hooks/useEasySession';
import { useProjects } from '@/hooks/useProjects';
import ProjectIcon from '@/icons/ProjectIcon';
import { buildSuggestionId, extractSuggestionTypeAndValue } from '../../utils/suggestions';
import Decorators from './Decorators';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Bullet } from '@/icons/Bullet';
import { getItemFromEditor } from '../../utils/editor';
import { ItemType } from '../../types';
import useReferences from '@/hooks/useReferences';

function renderCategory({ category }: { category: string }) {
  let icon;
  switch (category) {
    case 'PROJECTS':
      icon = <ProjectIcon size={14} color="black" />;
      break;
    case 'REFERENCES':
      icon = <Bullet size={14} color="black" />;
      break;
  }

  return (
    <div key={category} className="flex items-center gap-2 px-3 py-2.5 text-sm">
      {icon} {capitalize(category)}
    </div>
  );
}

function renderSuggestion({ suggestion }: { suggestion: Suggestion }) {
  const type = extractSuggestionTypeAndValue(suggestion.id).type;
  let Icon;
  
  switch (type) {
    case SuggestionType.PROJECT:{
      Icon = ProjectIcon;
      break;
    }
    default: {
      Icon = Bullet;
      break;
    }
  }

  return (
    <div key={suggestion.id} className="flex gap-2 p-1 px-10 text-xs leading-snug">
      {Icon && <Icon size={14} color="black" />}
      {suggestion.label}
    </div>
  );
}

function addNodeView() {
  return ReactNodeViewRenderer(Decorators);
}

export default function useHashtagSuggestions() {
  const { isUnauthenticated } = useEasySession();
  const { projects } = useProjects({});

  const items = useReferences();
  const itemsRef = useRef(items);
  itemsRef.current = items;

  const projectsRef = useRef(projects);

  useEffect(() => {
    projectsRef.current = projects;
  }, [projects]);

  return useMemo(
    () =>
      createSuggestions({
        char: '#',
        items: async ({ query, editor }): Promise<Suggestion[]> => {
          if (isUnauthenticated) {
            return [
              {
                id: buildSuggestionId(SuggestionType.PROJECT, 'create_new_project_' + query),
                label: 'Create new project: ' + query,
                category: 'DATE',
              },
            ];
          }

          const item = getItemFromEditor(editor);
          const suggestions: Suggestion[] = [];

          if (item.type === ItemType.NOTE) {
            const references = itemsRef.current;
            references
              .filter((ref) => {
                if (ref.id === item.id) return false;
                const title = 'summary' in ref ? ref.summary : ref.title;
                return title.toLowerCase().includes(query.toLowerCase());
              })
              .slice(0, 5)
              .forEach((reference) => {
                suggestions.push({
                  id: buildSuggestionId(reference.itemType as SuggestionType, reference.id),
                  label: 'summary' in reference ? reference.summary : reference.title,
                  category: 'REFERENCES',
                });
              });
          }

          const projects = projectsRef.current;
          let suggestedProjects =
            projects
              ?.filter((v) => !v.parentProjectId)
              .map((project) => ({
                id: buildSuggestionId(SuggestionType.PROJECT, project.id),
                label: project.subprojects?.length
                  ? ` ${project.name}/${project.subprojects.map((v) => v.name).join('/')}`
                  : `${project.name}`,
                category: 'PROJECTS',
              })) || [];
          suggestedProjects = suggestedProjects.filter(({ label }) =>
            label.toLowerCase().startsWith(query.toLowerCase())
          );
          if (query.trim().length >= 1) {
            suggestedProjects.push({
              id: buildSuggestionId(SuggestionType.PROJECT, 'create_new_project_' + query),
              label: 'Create new project: ' + query,
              category: 'PROJECTS',
            });
          }
          suggestions.push(...suggestedProjects);

          return suggestions;
        },
        addNodeView,
        renderCategory,
        renderSuggestion,
      }),
    [isUnauthenticated]
  );
}
