import { SVGProps } from "react";

type Props = {
  size?: number;
  color?: string;
} & SVGProps<SVGSVGElement>;

export default function QuestionIcon({ size = 12, color = '#279AF1', ...svgProps }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12" fill="none" {...svgProps}>
      <path
        d="M6 1.5C3.25 1.5 1 3.3 1 5.5C1 6.55 1.5 7.55 2.4 8.25C2.4 8.55 2.2 9.35 1 10.5C1 10.5 2.75 10.5 4.25 9.25C4.8 9.4 5.4 9.5 6 9.5C8.75 9.5 11 7.7 11 5.5C11 3.3 8.75 1.5 6 1.5ZM6.5 7.5H5.5V6.5H6.5V7.5ZM7.4 5C7.25 5.2 7.05 5.3 6.85 5.4C6.7 5.5 6.65 5.55 6.6 5.65C6.5 5.75 6.5 5.85 6.5 6H5.5C5.5 5.75 5.55 5.6 5.65 5.45C5.75 5.35 5.95 5.2 6.2 5.05C6.35 5 6.45 4.9 6.5 4.8C6.55 4.7 6.6 4.55 6.6 4.45C6.6 4.3 6.55 4.2 6.45 4.1C6.35 4 6.2 3.95 6.05 3.95C5.9 3.95 5.8 4 5.7 4.05C5.6 4.1 5.55 4.2 5.55 4.35H4.55C4.6 4 4.75 3.7 5 3.5C5.25 3.3 5.6 3.25 6.05 3.25C6.5 3.25 6.9 3.35 7.15 3.55C7.4 3.75 7.55 4.05 7.55 4.4C7.6 4.6 7.55 4.8 7.4 5Z"
        fill={color}
      />
    </svg>
  );
}
