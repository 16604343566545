import moment from 'moment-timezone';
import {
  collapseIntervals,
  DateInterval,
  daysInRange,
  joinFreeSlotsByDuration,
  minutesOfDay,
  RecurringInterval,
  sortIntervals,
} from '@/utils/date';
import { getFreeSlots } from '@/hooks/useFreeSlots';

export const convertAvailableSpecificIntervalsIntoBlockedIntervals = (
  start: Date,
  end: Date,
  intervals: DateInterval[],
  timeZone: string
) => {
  const blockedIntervals: DateInterval[] = [];
  daysInRange(start, end, timeZone)
    .map((date) => moment.tz(date, timeZone))
    .forEach((date) => {
      let intervalsInDay = intervals
        .filter((interval) => moment.tz(interval.start, timeZone).isSame(date, 'day'))
        .map((interval) => ({
          start: interval.start,
          end: interval.end,
        }));
      intervalsInDay.sort((a, b) => minutesOfDay(a.start, timeZone) - minutesOfDay(b.start, timeZone));
      // collapse intervals so having in the same day 9-17 and 8-14 doesn't break this algorithm
      intervalsInDay = collapseIntervals(intervalsInDay);

      // working hours -> intervalCollapsed
      // 9:00-12:00
      // 13:00-17:00
      //
      // find busy intervals
      // 0:00 - interval1.start
      // interval1.end - interval2.start
      //
      // find busy intervals
      // 0:00-9:00
      // 12:00-13:00
      // 17:00-0:00

      // if no working hours, add the whole days as blocked
      if (!intervalsInDay.length) {
        blockedIntervals.push({
          start: date.toDate(),
          end: date.clone().add(1, 'days').startOf('day').toDate(),
        });
        return;
      }

      // add intervals between working hours
      let blockedIntervalStart = date.clone();
      intervalsInDay.forEach((workingHoursInterval) => {
        if (minutesOfDay(workingHoursInterval.start, timeZone) > 0) {
          blockedIntervals.push({
            start: blockedIntervalStart.toDate(),
            end: date
              .clone()
              .startOf('days')
              .add(minutesOfDay(workingHoursInterval.start, timeZone), 'minutes')
              .toDate(),
          });
        }
        blockedIntervalStart = date
          .clone()
          .startOf('days')
          .add(minutesOfDay(workingHoursInterval.end, timeZone), 'minutes');
      });

      // add a "rest of the day" blocked interval
      if (blockedIntervalStart.hours() > 0) {
        blockedIntervals.push({
          start: blockedIntervalStart.toDate(),
          end: date.clone().startOf('days').add(1, 'days').toDate(),
        });
      }
    });

  return blockedIntervals;
};

export const convertScheduleIntervalsToSpecificIntervals = (
  start: Date,
  end: Date,
  intervals: RecurringInterval[],
  timeZone: string
): DateInterval[] => {
  return (
    daysInRange(start, end, timeZone)
      .map((date) => moment.tz(date, timeZone).startOf('day'))
      .map((date) => {
        const intervalDay = intervals
          .filter((interval) => {
            const isoWeekday = date.isoWeekday();
            const adjustedDayOfWeek = interval.dayOfWeek === 0 ? 7 : interval.dayOfWeek;
            return adjustedDayOfWeek === isoWeekday;
          })
          .map((interval) => ({
            start: date.clone().add(interval.startTime, 'minutes').toDate(),
            end: date.clone().add(interval.endTime, 'minutes').toDate(),
          }));
        sortIntervals(intervalDay);
        return collapseIntervals(intervalDay);
      })
      .flat()
      // remove intervals that end before start
      .filter((interval) => interval.end.getTime() >= start.getTime())
      // remove intervals that start after end
      .filter((interval) => interval.start.getTime() <= end.getTime())
      // coerce intervals to be inside start and end
      .map((interval) => {
        if (interval.start.getTime() < start.getTime()) {
          interval.start = start;
        }
        if (interval.end.getTime() > end.getTime()) {
          interval.end = end;
        }
        return interval;
      })
  );
};

export const convertScheduleIntervalsToBlockedIntervals = (
  start: Date,
  end: Date,
  intervals: RecurringInterval[],
  timeZone: string
) => {
  const specificIntervals = convertScheduleIntervalsToSpecificIntervals(start, end, intervals, timeZone);
  return convertAvailableSpecificIntervalsIntoBlockedIntervals(start, end, specificIntervals, timeZone);
};

export const generateSlots = async ({
  start,
  end,
  scheduleIntervals,
  checkCalendarIds,
  contactEmails,
  duration,
  timeZone,
}: {
  start: Date;
  end: Date;
  scheduleIntervals: RecurringInterval[];
  checkCalendarIds?: string[];
  contactEmails?: string[];
  duration: number;
  timeZone: string;
}) => {
  const slots = convertScheduleIntervalsToSpecificIntervals(start, end, scheduleIntervals, timeZone);
  const freeSlots = await getFreeSlots({
    timeZone,
    checkCalendarIds,
    contactEmails,
    duration,
    end,
    start,
    slots,
  });
  return joinFreeSlotsByDuration(freeSlots, duration, timeZone);
};
