import { useSearchEmails } from '@/hooks/useEmails';
import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';
import { ChangeEvent, useRef, useState } from 'react';
import { VscSearch } from 'react-icons/vsc';
import useLeftSidebar from '../useLeftSidebar';

export const SearchEmails = ({ labelId }: { labelId: string }) => {
  const [inputData, setInputData] = useState<string>('');
  const [isOpen, setOpen] = useState(false);
  const { openLeftSidebar } = useLeftSidebar();
  const { results: suggestedEmails } = useSearchEmails({
    search: inputData,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useHandleClickOutside(containerRef, () => {
    setOpen(false);
  });

  const handleOpenEmail = (id: string) => () => {
    openLeftSidebar({
      type: 'EMAIL_DETAIL',
      context: {
        threadId: id,
        labelId,
      },
    });
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputData(e.target.value);
    setOpen(true);
  };

  return (
    <div className="relative w-full" ref={containerRef}>
      <div className="w-full flex flex-col gap-1">
        <div className="border rounded-lg">
          <label className="text-[8px] leading-[12px] absolute bg-[#FFFFFF] text-gray-border -top-1.5 px-1 rounded-lg left-3.5">
            Search emails
          </label>
          <div className="flex items-center gap-0.5 px-2 h-[44px]">
            <VscSearch className="text-gray-400" />
            <input
              placeholder="Search...."
              className="w-full p-1.5 text-[14px] leading-[12px] outline-none"
              value={inputData}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <ul
        className={`absolute w-full bg-white mt-1 shadow-md max-h-80 overflow-y-scroll overflow-x-hidden p-0 z-10 ${
          !suggestedEmails || !isOpen ? 'hidden' : ''
        }`}>
        {suggestedEmails?.map((item) => (
          <button
            type="button"
            onClick={handleOpenEmail(item?.id ?? '')}
            className="hover:bg-blue-300 py-2 px-3 shadow-sm flex flex-col"
            key={item.id}>
            <p
              className="mt-[12px] text-[14px] leading-[18px] text-black text-left truncate select-none"
              dangerouslySetInnerHTML={{ __html: item.snippet ?? '' }}
            />
          </button>
        ))}
      </ul>
    </div>
  );
};
