import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  size?: number | string;
  hidden?: boolean;
};

export const DeleteIcon = ({ fill = 'currentColor', size = 14, hidden, ...rest }: Props) => {
  if (hidden) return null;
  return (
    <svg width={size} height={size} fill={fill} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M5.4 2.7H8.1C8.1 2.34196 7.95777 1.99858 7.70459 1.74541C7.45142 1.49223 7.10804 1.35 6.75 1.35C6.39196 1.35 6.04858 1.49223 5.79541 1.74541C5.54223 1.99858 5.4 2.34196 5.4 2.7V2.7ZM4.05 2.7C4.05 1.98392 4.33446 1.29716 4.84081 0.790812C5.34716 0.284464 6.03392 0 6.75 0C7.46608 0 8.15284 0.284464 8.65919 0.790812C9.16554 1.29716 9.45 1.98392 9.45 2.7H12.825C13.004 2.7 13.1757 2.77112 13.3023 2.8977C13.4289 3.02429 13.5 3.19598 13.5 3.375C13.5 3.55402 13.4289 3.72571 13.3023 3.8523C13.1757 3.97888 13.004 4.05 12.825 4.05H12.2297L11.6316 11.0295C11.5741 11.7035 11.2657 12.3313 10.7675 12.7888C10.2693 13.2463 9.61746 13.5001 8.94105 13.5H4.55895C3.88254 13.5001 3.23074 13.2463 2.7325 12.7888C2.23427 12.3313 1.9259 11.7035 1.8684 11.0295L1.27035 4.05H0.675C0.495979 4.05 0.32429 3.97888 0.197703 3.8523C0.0711158 3.72571 0 3.55402 0 3.375C0 3.19598 0.0711158 3.02429 0.197703 2.8977C0.32429 2.77112 0.495979 2.7 0.675 2.7H4.05ZM8.775 6.75C8.775 6.57098 8.70388 6.39929 8.5773 6.2727C8.45071 6.14612 8.27902 6.075 8.1 6.075C7.92098 6.075 7.74929 6.14612 7.6227 6.2727C7.49612 6.39929 7.425 6.57098 7.425 6.75V9.45C7.425 9.62902 7.49612 9.80071 7.6227 9.9273C7.74929 10.0539 7.92098 10.125 8.1 10.125C8.27902 10.125 8.45071 10.0539 8.5773 9.9273C8.70388 9.80071 8.775 9.62902 8.775 9.45V6.75ZM5.4 6.075C5.57902 6.075 5.75071 6.14612 5.8773 6.2727C6.00388 6.39929 6.075 6.57098 6.075 6.75V9.45C6.075 9.62902 6.00388 9.80071 5.8773 9.9273C5.75071 10.0539 5.57902 10.125 5.4 10.125C5.22098 10.125 5.04929 10.0539 4.9227 9.9273C4.79612 9.80071 4.725 9.62902 4.725 9.45V6.75C4.725 6.57098 4.79612 6.39929 4.9227 6.2727C5.04929 6.14612 5.22098 6.075 5.4 6.075V6.075ZM3.213 10.9148C3.24176 11.2518 3.39604 11.5659 3.6453 11.7946C3.89456 12.0234 4.22063 12.1502 4.55895 12.15H8.94105C9.27914 12.1499 9.60487 12.0229 9.85385 11.7942C10.1028 11.5654 10.2569 11.2516 10.2857 10.9148L10.8743 4.05H2.62575L3.21435 10.9148H3.213Z" />
    </svg>
  );
};
