import deepEqual from '@/utils/objects';
import { SuggestionType } from '../nodes/suggestions/types';
import { extractSuggestionTypeAndValue } from './suggestions';
import moment from 'moment-timezone';
import { roundToNextBlock } from '@/utils/date';
import { ItemType, ReferenceType, RichTextItem } from '../types';
import { Deadline, EventVisibility, Priority, TaskScheduleType, TimeBlockingType } from '@prisma/client';
import type { Editor } from '@tiptap/core';
import type { JSONContent } from '@tiptap/react';
import { isNewItem } from './item';
import { ConferencingData } from '@/models/EventArg';

export function getItemTypeFromEditor(editor: Editor) {
  return editor.state.selection.$head.node().attrs.type as ItemType;
}

export function getOnboardingStateFromEditor(editor: Editor) {
  return editor.state.selection.$head.node().attrs.onboarding as boolean;
}

export function getItemFromEditor(editor: Editor) {
  return editor.state.selection.$head.node().attrs as { id: string; type: ItemType; parentId?: string };
}

export function flattenCollapsibleNodes(state: JSONContent) {
  return (
    state.content?.reduce<JSONContent[]>((acc, node) => {
      if (node.type === 'item') {
        return [...acc, node];
      }

      node.content?.forEach((innerNode) => {
        acc.push(...innerNode.content!);
      });

      return acc;
    }, []) || []
  );
}

export function getDiff(currentState: JSONContent, prevState: JSONContent) {
  const flattenedCurrentContent = flattenCollapsibleNodes(currentState);
  const flattenedPrevContent = flattenCollapsibleNodes(prevState);
  const [newItems, updatedTypes, updatedItems] = flattenedCurrentContent.reduce<
    [JSONContent[], [JSONContent, JSONContent][], JSONContent[]]
  >(
    (acc, node) => {
      if (!node.attrs!.editable) return acc;
      if (!node.content?.some((n) => n.type === 'text' && n.text?.trim())) return acc;

      if (isNewItem(node.attrs!.id)) {
        acc[0].push(node);
        return acc;
      }

      const prevNode = flattenedPrevContent.find((n) => n.attrs!.id === node.attrs!.id)!;
      if (prevNode && node.attrs?.type !== prevNode.attrs?.type) {
        delete node.attrs?.id;
        acc[1].push([node, prevNode]);
        return acc;
      }

      if (deepEqual(node, prevNode)) return acc;
      acc[2].push(node);
      return acc;
    },
    [[], [], []]
  ) || [[], [], []];

  const deletedItems =
    flattenedPrevContent.filter(
      (node) => node.attrs!.id && !flattenedCurrentContent.some((n) => n!.attrs!.id === node.attrs!.id)
    ) || [];

  return { newItems, updatedItems, deletedItems, updatedTypes } as const;
}

type Options = {
  date?: string;
  projectIds?: string[];
  reference?: ReferenceType;
};

export function parseEditorItems(items: JSONContent[], { date, projectIds = [], reference }: Options = {}) {
  return items.reduce<RichTextItem[]>((acc, item) => {
    if (!item.content) return acc;

    let duration: number | undefined;

    const parsedItem = item.content.reduce(
      (acc, node) => {
        switch (node.type) {
          case 'text':
            acc.summary += node.text!;
            break;
          case 'SUGGESTION_@': {
            const { type, value } = extractSuggestionTypeAndValue(node.attrs!.id);

            switch (type) {
              case SuggestionType.DATE: {
                const [start, end, allDay] = value.split(/%%%/);

                acc.start = moment(start).toDate();
                acc.end = end && end !== 'null' ? moment(end).toDate() : null;
                acc.allDay = allDay && JSON.parse(allDay);
                break;
              }

              case SuggestionType.DEADLINE: {
                const [deadline] = value.split(/%%%/);
                acc.deadlineType = Deadline.HARD_DEADLINE;
                acc.dueDate = moment(deadline).toDate();
                break;
              }
              case SuggestionType.CONTACT:
                acc.contacts.push({ email: value });
                break;

              case SuggestionType.PROJECT:
                acc.projectIds.push(value);
                break;

              case SuggestionType.PRIORITY:
                acc.priority = value as Priority;
                break;

              case SuggestionType.AUTO_SCHEDULE: {
                acc.taskScheduleType = TaskScheduleType.AUTO_SCHEDULED;
                if (!value) break;

                const [start, end] = value.split(/%%%/);

                acc.earliestStartDate = moment(start).toDate();
                acc.start = moment(start).toDate();

                if (end && end !== 'undefined') {
                  acc.deadlineType = Deadline.HARD_DEADLINE;
                  acc.dueDate = moment(end).toDate();
                  acc.end = moment(acc.dueDate).toDate();
                  acc.allDay = false;
                }

                break;
              }
              case SuggestionType.DURATION:
                duration = Number(value);
                break;

              case SuggestionType.LOCATIONS:
                acc.location = value;
                break;

              case SuggestionType.TIME_BLOCKING:
                acc.timeBlockingType = value as TimeBlockingType;
                break;

              case SuggestionType.VISIBILITY:
                acc.visibility = value as EventVisibility;
                break;

              case SuggestionType.CONFERENCE:
                acc.conferenceData = value as ConferencingData;
                break;

              case SuggestionType.RECURRENCE:
                acc.recurrence = [value];
                break;

              case SuggestionType.SCHEDULE:
                acc.scheduleId = value;
                break;

              case SuggestionType.CALENDAR:
                acc.calendarId = value;
                break;

              default:
                (acc as any)[type] = value;
            }

            break;
          }
          case 'SUGGESTION_#': {
            const { type, value } = extractSuggestionTypeAndValue(node.attrs!.id);

            switch (type) {
              case SuggestionType.PROJECT:
                acc.projectIds.push(value);
                break;
              case SuggestionType.TASK:
                acc.taskIds.push(value);
                break;
              case SuggestionType.EVENT:
                acc.eventIds.push(value);
                break;
              case SuggestionType.NOTE:
                acc.noteIds.push(value);
                break;
              case SuggestionType.EMAIL:
                acc.emailIds.push(value);
                break;
            }

            break;
          }
        }
        return acc;
      },
      {
        summary: '',
        parentTaskId: null,
        contacts: [],
        projectIds: [...projectIds],
        taskIds: reference?.type === 'TASK' ? [reference.id] : [],
        eventIds: reference?.type === 'EVENT' ? [reference.id] : [],
        noteIds: reference?.type === 'NOTE' ? [reference.id] : [],
        emailIds: reference?.type === 'EMAIL' ? [reference.id] : [],
        deadlineType: Deadline.NONE,
        itemType: ItemType.TASK,
        location: null,
        subtasks: [],
      } as RichTextItem
    );

    parsedItem.id = item.attrs?.id;
    parsedItem.parentTaskId = item.attrs?.parentId;
    parsedItem.summary = parsedItem.summary.trim().replace(/ +/g, ' ');
    parsedItem.itemType = item.attrs?.type || null;
    parsedItem.isCompleted = item.attrs?.checked || false;
    parsedItem.userOrder = item.attrs?.userOrder;
    parsedItem.taskScheduleType = parsedItem.taskScheduleType || item.attrs?.scheduleType || TaskScheduleType.NONE;

    if (parsedItem.taskScheduleType === TaskScheduleType.AUTO_SCHEDULED) {
      if (parsedItem.start) {
        parsedItem.earliestStartDate = parsedItem?.start;
        delete parsedItem.start;
      }
      if (parsedItem.dueDate) {
        parsedItem.deadlineType = Deadline.HARD_DEADLINE;
        delete parsedItem.end;
      }
      if (parsedItem.allDay) {
        delete parsedItem.allDay;
      }
      if (duration) {
        parsedItem.duration = duration;
      }
    } else {
      if (date && !parsedItem.start) {
        parsedItem.start = new Date(date);
        parsedItem.end = moment(date).add(1, 'day').toDate();
        parsedItem.allDay = true;
      }

      if (duration) {
        if (!parsedItem.start) parsedItem.start = roundToNextBlock(new Date(), 30);
        parsedItem.end = moment(parsedItem.start).add(duration, 'minutes').toDate();
      }

      parsedItem.taskScheduleType = TaskScheduleType.NONE;
      if (parsedItem.start) {
        parsedItem.taskScheduleType = TaskScheduleType.SPECIFIC;
      }
    }

    if (parsedItem.itemType === ItemType.SCHEDULING_LINK) {
      parsedItem.duration = duration;
    }

    if (parsedItem.parentTaskId) {
      const parentTask = acc.find((item) => item.id === parsedItem.parentTaskId);
      if (parentTask) {
        parentTask?.subtasks.push(parsedItem);
        return acc;
      }
    }

    return [...acc, parsedItem];
  }, []);
}
