import { useEffect, useState } from 'react';

const TypingText = ({ text }: { text: string }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingEffect = setTimeout(() => {
      const newDisplayText = text.substring(0, currentIndex + 1);
      setDisplayText(newDisplayText);
      setCurrentIndex(currentIndex + 1);
    }, 1); // Adjust the typing speed (in milliseconds) as desired

    return () => clearTimeout(typingEffect);
  }, [currentIndex, text]);

  return <div dangerouslySetInnerHTML={{ __html: displayText.replace(/\n/g, '<br/>') }} />;
};

export default TypingText;
