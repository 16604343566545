/* eslint-disable @next/next/no-img-element */
// TODO(hmassad): rename to ContactContextMenu, EventDragHandleContextMenu

import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import {
  MdChevronRight as ExpandIcon,
  MdContentCopy as CopyIcon,
  MdGroups as PeopleIcon,
  MdLocationOn as LocationIcon,
  MdModeEdit as EditIcon,
  MdVideocam as VideoIcon,
} from 'react-icons/md';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Contact } from '@/models/ContactModel';
import { Project } from '@/models/ProjectModel';
import { Avatar } from '@/components/Avatar';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { Divider } from '../Divider';
import { Reminder } from '@/models/EventModel';
import { AttendeeResponseStatus } from '@/utils/contacts';
import { useContacts } from '@/hooks/useContacts';
import { useEasySession } from '@/hooks/useEasySession';
import { AttendeChecked, AttendeDeclined, AttendeUnknow } from '@/icons/Attendees';

type PopupDragHandleMouseOverSubBaseProps = {
  trigger: ReactNode;
  info: ReactNode;
};

export type ConferenceDataProps = {
  conferenceId: string;
  conferenceSolution: {
    iconUri: string;
    name: string;
    key: { type: string };
  };
  createRequest: {
    requestId: string;
    conferenceSolutionKey: {
      type: string;
    };
    status: { statusCode: string };
  };
  entryPoints: {
    uri: string;
    label: string;
    pin?: string;
    regionCode?: string;
    entryPointType: string;
  }[];
};

export const PopupDragHandleMouseOverSubBase = ({ trigger, info }: PopupDragHandleMouseOverSubBaseProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <DropdownMenu.Sub open={isOpen}>
      <DropdownMenu.SubTrigger
        onMouseEnter={() => {
          setOpen(true);
        }}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}>
        {trigger}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent
          className="z-10 bg-white rounded-md pb-[15px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
          sideOffset={-2}
          alignOffset={-5}>
          {info}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

type ContactContextMenuProps = {
  conferenceData?: ConferenceDataProps | null;
  hangoutLink?: string | null;
  location?: string | null;
  creatorEmail?: { email: string };
  contacts?: {
    email: string;
    name: string;
    responseStatus: string;
    picture?: string;
  }[];
  projects?: Project[];
  reminders?: Reminder[];
  takeMeetingNotes?: boolean;
  status?: string;
  calendarId?: string;
  onChangeStatus?: (status: AttendeeResponseStatus) => void;
};

export const ContactContextMenu = ({
  conferenceData,
  hangoutLink,
  location,
  contacts: modalContacts,
  creatorEmail,
  status,
  // event,
  onChangeStatus,
}: ContactContextMenuProps) => {
  const isURL = location?.trim().startsWith('https://') || location?.trim().startsWith('http://');
  const { contacts } = useContacts({});
  const { user } = useEasySession();

  const handleResponseEvent = (response: string) => (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    switch (response) {
      case 'Yes':
        onChangeStatus?.('accepted');
        break;
      case 'No':
        onChangeStatus?.('declined');
        break;
      case 'Maybe':
        onChangeStatus?.('tentative');
        break;
      default:
        break;
    }
  };

  const attendeeFiltered = useMemo(() => {
    if (!modalContacts) return [];
    const newContacts = [];

    const googleContactsValue = contacts
      .filter((contact) => modalContacts?.map((modalContact) => modalContact.email).includes(contact.email))
      .map((contact) => ({
        email: contact.email,
        name: contact.name,
        picture: contact.picture,
        responseStatus: modalContacts?.find((modalContact) => modalContact.email === contact.email)?.responseStatus,
      }));

    newContacts.push(...googleContactsValue, ...modalContacts);
    return newContacts
      .filter((contact, index, self) => index === self.findIndex((t) => t?.email === contact?.email))
      .filter((c) => c.email !== creatorEmail?.email);
  }, [modalContacts, contacts, creatorEmail?.email]);

  const creatorFiltered = useMemo(() => {
    if (!creatorEmail) return [];

    if (user?.email === creatorEmail.email)
      return [
        {
          email: user.email,
          name: user.name,
          picture: user.picture,
          responseStatus: 'accepted',
        },
      ];

    return contacts
      .filter((contact) => creatorEmail?.email === contact.email)
      .map((contact) => ({
        email: contact.email,
        name: contact.name,
        picture: contact.picture,
        responseStatus: 'accepted',
      }));
  }, [contacts, creatorEmail, user]);

  return (
    <div className="flex flex-col">
      {conferenceData ? (
        <a
          href={conferenceData?.entryPoints[0].uri}
          target="_blank"
          rel="noopener noreferrer"
          className="h-[40px] flex items-center my-1.5">
          <img
            src={conferenceData?.conferenceSolution.iconUri ?? `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`}
            alt="conference platform logo"
            className="w-[20px] h-[20px] mr-1"
          />
          <p className="text-sm leading-[14px] font-inter font-medium text-primary-500 underline">
            Join {conferenceData?.conferenceSolution.name}
          </p>
        </a>
      ) : null}

      {hangoutLink && !conferenceData ? (
        <div className="flex items-center my-1.5">
          <VideoIcon className="inline" /> {hangoutLink}
        </div>
      ) : null}

      {location ? (
        <div className="flex items-center my-1.5">
          <div className="mr-1">
            <LocationIcon className="inline" />
          </div>
          {isURL ? (
            <a
              className="text-sm font-inter font-medium truncate leading-[14px] underline cursor-pointer"
              href={location}
              target="_blank"
              rel="noreferrer">
              {location}
            </a>
          ) : (
            <p className="text-sm font-inter font-medium text-wrap leading-[14px]">{location}</p>
          )}
        </div>
      ) : null}

      {attendeeFiltered ? (
        <>
          <p className="flex items-center text-sm font-inter font-medium my-1.5">
            <PeopleIcon className="inline mt-1 mr-2" />
            {(attendeeFiltered.length || creatorFiltered.length) +
              `${attendeeFiltered.length > 1 ? ' Guests' : ' Guest'}`}
          </p>
          <div className="ml-[12px]">
            {creatorFiltered.map((contact, index) => (
              <div key={index} className="flex items-center">
                <div className="flex items-center">
                  <Avatar picture={contact?.picture ?? ''} name={contact?.name} className="rounded-full" size={18} />
                  <span className="relative flex">
                    <span className="relative inline-flex rounded-full -left-1.5 -bottom-1.5">
                      {contact?.responseStatus === 'accepted' ? <AttendeChecked size={10} /> : null}
                      {contact?.responseStatus === 'declined' ? <AttendeDeclined size={10} /> : null}
                      {contact?.responseStatus === 'needsAction' ? <AttendeUnknow size={10} /> : null}
                      {contact?.responseStatus === 'tentative' ? <AttendeUnknow size={10} /> : null}
                    </span>
                  </span>
                </div>
                <div className="ml-[8px]">
                  <span className="text-sm font-inter leading-[14px] font-medium cursor-default text-secondary-500 mb-2">
                    {contact?.name || contact.email}
                  </span>
                  <p className="text-xs font-inter leading-[14px] text-gray-500">(Creator)</p>
                </div>
              </div>
            ))}

            {attendeeFiltered.map((contact) => (
              <div className="flex items-center my-[8px]">
                <div className="flex items-center">
                  <Avatar picture={contact?.picture ?? ''} name={contact?.name} className="rounded-full" size={18} />
                  <span className="relative flex">
                    <span className="relative inline-flex rounded-full -left-1.5 -bottom-1.5">
                      {contact?.responseStatus === 'accepted' ? <AttendeChecked size={10} /> : null}
                      {contact?.responseStatus === 'declined' ? <AttendeDeclined size={10} /> : null}
                      {contact?.responseStatus === 'needsAction' ? <AttendeUnknow size={10} /> : null}
                      {contact?.responseStatus === 'tentative' ? <AttendeUnknow size={10} /> : null}
                    </span>
                  </span>
                </div>
                <div className="ml-[4px]">
                  <span className="text-sm font-inter leading-[14px] font-medium cursor-default text-secondary-500 mb-2">
                    {contact?.name || contact.email}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
      <Divider />
      <div className="flex items-center justify-between mb-1">
        <p className="text-sm font-inter leading-[14px]">Going?</p>
        <div className="flex items-center gap-[8px]">
          <button
            className={`text-base font-inter leading-[14px] ${
              status === 'accepted' ? 'text-primary-500' : 'text-gray-500'
            } uppercase`}
            onClick={handleResponseEvent('Yes')}>
            Yes
          </button>
          <button
            className={`text-base font-inter leading-[14px] text-gray-500 uppercase ${
              status === 'declined' ? 'text-primary-500' : 'text-gray-500'
            }`}
            onClick={handleResponseEvent('No')}>
            No
          </button>
          <button
            className={`text-base font-inter leading-[14px] uppercase ${
              status === 'tentative'
                ? 'text-primary-500'
                : status === 'needsAction'
                  ? 'text-primary-500'
                  : 'text-gray-500'
            }`}
            onClick={handleResponseEvent('Maybe')}>
            Maybe
          </button>
        </div>
      </div>
    </div>
  );
};

type ContactsPopupDragHandleMouseOverProps = {
  contact: Contact;
};

export const ContactsPopupDragHandleMouseOver = ({ contact }: ContactsPopupDragHandleMouseOverProps) => {
  const { openLeftSidebar, closeLeftSidebar, leftSidebarState } = useLeftSidebar();

  const handleOpenRightSidebar = (id: string) => {
    if (leftSidebarState.context?.email === id) {
      closeLeftSidebar();
      return;
    }

    openLeftSidebar({
      type: 'CONTACT',
      context: {
        email: contact.email,
      },
    });
  };

  return (
    <PopupDragHandleMouseOverSubBase
      trigger={
        <DropdownMenuItem
          text={contact?.email || ''}
          suffix={<ExpandIcon />}
          onClick={() => {
            handleOpenRightSidebar(contact?.email || '');
          }}
        />
      }
      info={
        <div className="flex flex-row p-[10px]">
          <Avatar name={contact.name} picture={contact.picture} size={80} className="rounded-full" />
          <div className="px-[20px] grid grid-cols-1 gap-1 place-content-center ">
            <div className="grid content-center grid-cols-5 gap-3 group">
              <p className="col-span-4 text-lg">{contact.name || contact.email}</p>
              <CopyIcon
                className="text-sm hidden mt-[8px] group-hover:block cursor-pointer"
                onClick={() => {
                  void navigator.clipboard.writeText(contact.name ? contact.name : '');
                }}
              />
            </div>
            {contact.name ? (
              <div className="grid content-center grid-cols-5 group">
                <p className="col-span-4 text-xs text-slate-600">{contact.email}</p>
                <CopyIcon
                  className="text-xs ml-[-40px] hidden group-hover:block cursor-pointer"
                  onClick={() => {
                    void navigator.clipboard.writeText(contact.email || '');
                  }}
                />
              </div>
            ) : null}
          </div>
          <EditIcon
            className="cursor-pointer"
            onClick={() => {
              handleOpenRightSidebar(contact.email || '');
            }}
          />
        </div>
      }
    />
  );
};

type DropdownMenuItemProps = {
  text?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  url?: string;
  onClick?: () => void;
};

const DropdownMenuItem = ({ text, prefix, suffix, url, onClick }: DropdownMenuItemProps) => {
  return (
    <DropdownMenu.Item
      onClick={onClick}
      className={`${onClick && 'cursor-pointer'} ${
        url && 'cursor-pointer'
      } group text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 hover:bg-sky-100 pl-[10px] pr-[40px] py-[17px]`}>
      {prefix && <div className="rounded-xl mr-[10px] text-lg">{prefix}</div>}
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {text}
        </a>
      ) : (
        text
      )}
      {suffix && <div className="rounded-xl mr-[10px] text-lg absolute right-0">{suffix}</div>}
    </DropdownMenu.Item>
  );
};
