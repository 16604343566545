import { useCallback, useEffect, useState } from 'react';
import { useSettings } from '@/hooks/useSettings';
import moment from 'moment-timezone';
import { Priority, RecurrenceType, TaskScheduleType, TimeBlockingType } from '@prisma/client';
import { ItemCreated } from '@/components/ToastItemCreated';
import { ToastGeneric } from '@/components/ToastGeneric';
import { toast } from 'react-hot-toast';
import { useEasySession } from '@/hooks/useEasySession';
import Header from '../Header';
import { useTasks } from '@/hooks/useTasks';
import { convertTaskToTaskArg, TaskArg } from '@/models/TaskArg';
import { useSetAtom } from 'jotai';
import { activeSectionsAtom } from '../atoms/activeSections';
import Title from '../sections/Title';
import AutoSchedule from '../sections/AutoSchedule';
import useLeftSidebar from '../../useLeftSidebar';
import TimeBlocking from '../sections/TimeBlocking';
import Tags from '../sections/Tags';
import SubtaskSection from '@/components/SubtaskSection';
import Button from '@/components/Button';
import Description from '../sections/Description';
import ReferenceList from '../sections/References';
import { EditRecurrentEventModal } from '@/components/EditRecurrentEventModal';
import useModal from '@/hooks/useModal';
import { RecurringInstance } from '@/models/EventModel';
import { SkeletonEvent } from '../Event/SkeletonEvent';
import { FaCalendarAlt, FaClipboardList } from 'react-icons/fa';
import ToggleableSection from '@/components/LeftSidebar/editors/sections/ToggleableSection';
import { formatDateRangeTimeSimple } from '@/utils/date';

type Props = {
  id?: string;
  start?: Date;
  end?: Date;
  isAllDay?: boolean;
  summary?: string;
};

export default function TaskEditor({ id, end, isAllDay, start, summary }: Props) {
  const closeSections = useSetAtom(activeSectionsAtom);
  const { closeLeftSidebar } = useLeftSidebar();
  const { defaultTimeZone: timeZone } = useSettings();
  const { isUnauthenticated } = useEasySession();
  const [task, setTask] = useState<TaskArg>();

  const isRecurring = task?.recurrence || task?.recurringTaskId;

  const { tasks, createTask, isCreatingTask, resetCreateTask, updateTask, isUpdatingTask, resetUpdateTask } = useTasks({
    onCreated: (task) => {
      closeLeftSidebar();
      toast(<ItemCreated item={task} />, {
        position: 'bottom-center',
        style: { background: 'black' },
      });
      closeSections({
        AUTO_SCHEDULE: false,
        TIME_BLOCKING: false,
        TAGS: false,
        REFERENCES: false,
        DESCRIPTION: false,
        SCHEDULE: false,
        GUESTS: false,
        SETTINGS: false,
        INVITEE_QUESTIONS: false,
        SLOTS_SETTINGS: false,
      });
      resetCreateTask();
    },
    onCreateError: () => {
      if (isUnauthenticated) return;
      toast(<ToastGeneric title="Something went wrong, task not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateTask();
    },
    onUpdated: () => {
      toast(<ToastGeneric title="Task updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      closeLeftSidebar();
      closeSections({
        AUTO_SCHEDULE: false,
        TIME_BLOCKING: false,
        TAGS: false,
        REFERENCES: false,
        DESCRIPTION: false,
        SCHEDULE: false,
        GUESTS: false,
        SETTINGS: false,
        INVITEE_QUESTIONS: false,
        SLOTS_SETTINGS: false,
      });
      resetUpdateTask();
    },
    onUpdateError: () => {
      if (isUnauthenticated) return;
      toast(<ToastGeneric title="Something went wrong, Task not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
    },
  });

  useEffect(() => {
    if (!tasks) return;

    const task = tasks.results?.find((t) => t.id === id);
    if (task) {
      setTask({
        ...convertTaskToTaskArg(task),
        summary: summary ?? task.summary,
        start: start ?? task.start,
        end: end ?? task.end,
        allDay: isAllDay ?? task.allDay,
      });
    } else {
      setTask({
        summary: summary ?? '',
        start: start ?? moment.tz(timeZone).startOf('day').toDate(),
        end: end ?? moment.tz(timeZone).startOf('day').add(1, 'day').toDate(),
        allDay: isAllDay ?? false,
        contacts: [],
        description: JSON.stringify({}),
        emailIds: [],
        eventIds: [],
        noteIds: [],
        projectIds: [],
        isCompleted: false,
        userOrder: 9999,
        priority: Priority.NO_PRIORITY,
        taskScheduleType: TaskScheduleType.NONE,
        timeBlockingType: TimeBlockingType.BUSY,
        recurrenceType: RecurrenceType.DOES_NOT_REPEAT,
        taskIds: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, id]);

  const handleOnSave = useCallback(
    (taskArg: TaskArg, picked?: RecurringInstance) => {
      if (!id) {
        createTask(taskArg);
        return;
      }

      if (isRecurring) {
        updateTask({ taskId: id, task: taskArg, recurringChange: picked, originalStart: start });
        return;
      }

      updateTask({ taskId: id, task: taskArg });
    },
    [id, isRecurring, updateTask, createTask, start]
  );

  if (!tasks || !task) {
    return <SkeletonEvent />;
  }

  return <TaskForm onSave={handleOnSave} id={id} defaultTask={task} isDisabled={isUpdatingTask || isCreatingTask} />;
}

interface TaskFormProps {
  onSave: (taskArg: TaskArg, recurringInstance?: RecurringInstance) => void;
  isDisabled: boolean;
  id?: string;
  isRecurring?: boolean;
  defaultTask: TaskArg;
}

const TaskForm = ({ onSave, defaultTask, isDisabled = false, id }: TaskFormProps) => {
  const { isOpen, closeModal, openModal } = useModal();
  const [task, setTask] = useState(defaultTask);
  const isRecurring = Boolean(task.recurrence && task.recurringTaskId);

  const handleSave = () => {
    if (isRecurring && id) {
      openModal();
      return;
    }
    onSave(task);
  };

  const confirmEdit = useCallback(
    async (picked?: RecurringInstance) => {
      if (!picked) return;
      if (!id) return;
      onSave(task, picked);
      closeModal();
    },
    [closeModal, task, onSave, id]
  );

  return (
    <section className="flex flex-col h-full xs:w-[75%] sm:w-[90%] md:w-full">
      <Header id={id ?? ''} itemType="TASK" isRecurring={isRecurring} originalStartEvent={task.start ?? new Date()} />
      <article className="lg:h-full lg:p-6 xs:p-3 overflow-auto" style={{ height: 'calc(100dvh - 125px)' }}>
        <Title
          placeholder="Untitled Task"
          type="TASK"
          title={task.summary}
          onChange={(title) => setTask({ ...task, summary: title })}
        />
        {!task.parentTaskId ? (
          <SubtaskSection
            subtasks={task.subtasks}
            setSubtasks={(subtasks) => setTask({ ...task, subtasks: subtasks as any })}
          />
        ) : null}
        <div className="mt-4"></div>
        <Description value={task.description} onChange={(description) => setTask({ ...task, description })} />
        <ToggleableSection 
          summary={task.taskScheduleType === 'NONE' ? 'Unscheduled' : 
            task.taskScheduleType === 'SPECIFIC' && task.start && task.end ? 
            formatDateRangeTimeSimple(task.start, task.end) : 
            task.taskScheduleType === 'RECURRING' ? 'Recurring' : 
            task.taskScheduleType === 'AUTO_SCHEDULED' && task.start && task.end ? 
            <>Auto-scheduled:<br />{formatDateRangeTimeSimple(task.start, task.end)}</> : 'Auto-scheduling this task'}
          title="Scheduling this task" 
          icon={FaCalendarAlt}
        >
          <>
            <AutoSchedule
              taskScheduleType={task.taskScheduleType}
              allDay={task.allDay}
              start={task.start}
              end={task.end}
              recurrenceType={task.recurrenceType}
              recurringTaskId={task.recurringTaskId ?? ''}
              onChange={(value) => setTask({ ...task, ...value })}
              scheduleId={task.scheduleId}
              duration={task.duration}
              earliestStartDate={task.earliestStartDate}
              onChangeScheduleId={(scheduleId) => setTask({ ...task, scheduleId })}
              onChangeDuration={(duration) => setTask({ ...task, duration })}
              onChangeEarliestStartDate={(earliestStartDate, taskScheduleType) =>
                setTask({ ...task, earliestStartDate, taskScheduleType })
              }
              disabled={task.isCompleted}
            />
            <TimeBlocking
              deadlineRangeType={task.deadlineRangeType}
              start={task.start}
              deadlineType={task.deadlineType}
              priority={task.priority}
              timeBlocking={task.timeBlockingType}
              dueDate={task.dueDate}
              allDay={task.allDay}
              onChange={(value) => setTask({ ...task, ...value })}
              disabled={task.isCompleted}
            />
          </>
        </ToggleableSection>
        <Tags
          hideContacts={true}
          location={task.location}
          projectIds={task.projectIds}
          onChange={(value) => setTask({ ...task, ...value })}
          disabled={task.isCompleted}
        />
        <ToggleableSection title="Related Items" icon={FaClipboardList}>
          <ReferenceList value={task.emailIds} />
        </ToggleableSection>
        <div onClick={(e) => e.stopPropagation()}>
          {isOpen ? <EditRecurrentEventModal itemType="TASK" onNo={closeModal} onYesRecurrent={confirmEdit} /> : null}
        </div>
      </article>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="shrink-0 mt-auto flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t flex-1 xs:w-[75%] sm:w-[90%] md:w-full">
        <Button variant="primary" onClick={handleSave} disabled={isDisabled}>
          Save Form
        </Button>
      </footer>
    </section>
  );
};
