import { useEffect, useState } from 'react';
import Input from './Input';
import { cn } from 'ui/cn';
import { useSettings } from '@/hooks/useSettings';

export const DEFAULT_DURATION_OPTIONS = [15, 30, 45, 60];

export enum DurationInterval {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export const DurationSelector = ({
  className,
  value,
  isSettingsPage,
  onChange,
  dark,
}: {
  className?: string;
  value: number;
  isSettingsPage?: boolean;
  onChange?: (value: number) => void;
  dark?: boolean;
}) => {
  const { defaultDuration } = useSettings();

  const [duration, setDuration] = useState<number | string>(value ?? defaultDuration ?? 15);

  const onChangeNumber = (value: string) => {
    const duration = Number(value);
    if (isSettingsPage && value === '') return setDuration(15);
    if (value === '') return setDuration('');
    setDuration(duration);
    onChange?.(duration);
  };

  useEffect(() => {
    const newDuration = Number(duration);
    if (newDuration === value) return;
    onChange?.(newDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, value]);

  return (
    <div className={cn('flex gap-3', className)}>
      <Input
        className="flex-1 outline-none"
        name="duration"
        labelContent="Duration"
        type="number"
        min={15}
        max={60}
        hasError={(Number(duration) < 15 || Number(duration) > 60) ?? Number(duration) < 1}
        value={duration}
        onChange={onChangeNumber}
        dark={dark}
      />
    </div>
  );
};
