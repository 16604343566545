import { useCallback } from 'react';
import type { Editor } from '@tiptap/core';

import useRichTextItemToItemArg from './useRichTextItemToItemArg';
import { flattenCollapsibleNodes, parseEditorItems } from '../utils/editor';
import { Reference } from '@/components/Overlay/types';

export default function useEditorToItemArg() {
  const richTextItemToItemArg = useRichTextItemToItemArg();

  return useCallback(
    (editor: Editor, reference?: Reference) => {
      const flattenContent = flattenCollapsibleNodes(editor.getJSON());
      const parsedItem = parseEditorItems(flattenContent, { reference });
      return richTextItemToItemArg(parsedItem[0]);
    },
    [richTextItemToItemArg]
  );
}
