import { useMemo } from 'react';
import { useGroups } from '@/hooks/useGroups';
import { convertGroupToGroupArg, GroupArg } from '@/models/GroupModel';
import { toast } from 'react-hot-toast';
import { useContacts } from '@/hooks/useContacts';
import { GroupForm } from '@/components/GroupForm';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ToastGeneric } from '@/components/ToastGeneric';

type GroupProps = {
  groupId: string;
};

export const GroupEditor = ({ groupId }: GroupProps) => {
  const { closeLeftSidebar } = useLeftSidebar();

  const { contacts: allContacts } = useContacts({});
  const { groups, createGroup, isCreatingGroup, resetCreateGroup, updateGroup, isUpdatingGroup, resetUpdateGroup } =
    useGroups({
      onCreated: (group) => {
        closeLeftSidebar();
        toast(<ToastGeneric title={`Group '${group.name}' created`} />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetCreateGroup();
      },
      onCreateError: () => {
        toast(<ToastGeneric title="Something went wrong, group not created" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetCreateGroup();
      },
      onUpdated: () => {
        resetUpdateGroup();
        closeLeftSidebar();
      },
      onUpdateError: () => {
        toast(<ToastGeneric title="Something went wrong, group not updated" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetUpdateGroup();
        return;
      },
    });

  const group = useMemo((): GroupArg => {
    const c = groups?.find((t) => t.id === groupId);
    if (c) return convertGroupToGroupArg(c);

    // it's a new group
    return {
      name: '',
      contactIds: [],
    };
  }, [groups, groupId]);

  const handleSave = (group: GroupArg) => {
    if (!groupId) {
      createGroup(group);
    } else {
      updateGroup({ groupId, group });
    }
  };

  if (!group || !allContacts) return <>Loading...</>;

  return (
    <GroupForm
      group={group}
      onSave={handleSave}
      isSaving={isCreatingGroup || isUpdatingGroup}
      allContacts={allContacts ?? []}
    />
  );
};
