import { FormEvent, useCallback, useState } from 'react';
import cuid from 'cuid';

import { Prisma } from '@prisma/client';
import { MdEditNote } from 'react-icons/md';
import { Divider } from '@/components/Divider';
import { QuestionsForm } from '@/components/SchedulingLinksQuestionsForm';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { SchedulingLinkQuestion } from '@/models/SchedulingLinkArg';
import ToggleableSection from '../sections/ToggleableSection';

type Props = {
  questions?: Prisma.JsonValue & SchedulingLinkQuestion[];
  onChange: (value: Prisma.JsonValue & SchedulingLinkQuestion[]) => void;
};

export default function InviteeQuestions({ questions = [], onChange }: Props) {
  const [showForm, setShowForm] = useState(false);

  const handleDeleteQuestion = useCallback(
    (index: number, event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const newQuestions = [...questions];
      newQuestions.splice(index, 1);
      onChange(newQuestions);
    },
    [onChange, questions]
  );

  const questionsBuilder = useCallback(
    (question: {
      question: string;
      answerFormat: 'Single line' | 'Multi-line';
      isMandatory: boolean;
      answers: {
        id: string;
        answer: string;
      }[];
    }) => {
      onChange([
        ...questions,
        {
          id: cuid(),
          question: question.question,
          answers: question.answers,
          answerFormat: question.answerFormat,
          isMandatory: question.isMandatory,
        },
      ]);
      setShowForm(false);
    },
    [onChange, questions]
  );

  const summaryString =
    questions.length === 0 ? 'Name, Email' : `Name, Email, + ${questions.length} additional questions`;

  return (
    <ToggleableSection title="Booking Page Options" summary={summaryString} icon={MdEditNote}>
      Name, Email <br />
      {questions.length > 0 && (
        <>
          <div className="mt-5">
            {questions.map((question, index) => (
              <div key={index} className="flex flex-col gap-3 mt-2.5">
                <Divider />
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center gap-3">
                    <div className="text-sm font-medium tracking-tight text-secondary-300">Question {index + 1}</div>
                    <div
                      className="text-sm font-medium tracking-tight"
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    />
                  </div>
                  <button onClick={(event) => handleDeleteQuestion(index, event)}>
                    <DeleteIcon className="fill-gray-400 hover:cursor-pointer hover:fill-red-500" />
                  </button>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        </>
      )}
      {!showForm && (
        <button
          onClick={() => setShowForm(true)}
          className="items-center mt-4 p-2 border text-black hover:text-blue-500">
          Add a question
        </button>
      )}
      {showForm && <QuestionsForm onSave={questionsBuilder} onCancel={() => setShowForm(false)} />}
    </ToggleableSection>
  );
}
