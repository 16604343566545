export type Suggestion = {
  id: string;
  label: string;
  category?: string;
};

export enum SuggestionType {
  CONTACT = 'CONTACT',
  PROJECT = 'PROJECT',
  DATE = 'DATE',
  DEADLINE = 'DEADLINE',
  PRIORITY = 'PRIORITY',
  DURATION = 'DURATION',
  SCHEDULE = 'SCHEDULE',
  CALENDAR = 'CALENDAR',
  AUTO_SCHEDULE = 'AUTO_SCHEDULE',
  SUBMENU = 'SUBMENU',
  LOCATIONS = 'LOCATIONS',
  TASK = 'TASK',
  EVENT = 'EVENT',
  NOTE = 'NOTE',
  EMAIL = 'EMAIL',
  TIME_BLOCKING = 'TIME_BLOCKING',
  VISIBILITY = 'VISIBILITY',
  CONFERENCE = 'CONFERENCE',
  RECURRENCE = 'RECURRENCE',
}

export enum Submenu {
  PRIORITY = 'PRIORITY',
  DURATION = 'DURATION',
  DEADLINE = 'DEADLINE',
  TIME_BLOCKING = 'TIME_BLOCKING',
  VISIBILITY = 'VISIBILITY',
  CONFERENCE = 'CONFERENCE',
  CALENDAR = 'CALENDAR',
}

export type Reference = SuggestionType.NOTE | SuggestionType.EVENT | SuggestionType.TASK;
