import { AddNewIcon } from '@/icons/AddNewIcon';
import useLeftSidebar from '../useLeftSidebar';
import ToolTip from '@/components/Tooltip';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { ContactsIcon } from '@/icons/ContactsIcon';

export const AllGroupsHeader = () => {
  const { openLeftSidebar } = useLeftSidebar();

  const handleOpenLeftSidebarAddGroup = () => {
    openLeftSidebar({
      type: 'GROUP_EDITOR',
    });
  };

  const handleOpenLeftSidebarContacts = () => {
    openLeftSidebar({
      type: 'ALL_CONTACTS',
    });
  };

  return (
    <div className="flex flex-col gap-6 px-4 py-6 h-[140px] min-h-[100px]">
      <div className="flex items-center justify-between">
        <div
          onClick={handleOpenLeftSidebarAddGroup}
          className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-[#EFEFEF] rounded-[14px]">
          <AddNewIcon />
        </div>
        <ToolTip tooltip="Open All Contacts">
          <button type="button" onClick={handleOpenLeftSidebarContacts}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center px-[15px]">
        <ContactsIcon className="mr-[8px]" size={18} />
        <p className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">All groups</p>
      </div>
    </div>
  );
};
