import { Contact } from '@/models/ContactModel';
import { useSettings } from '@/hooks/useSettings';

type DetailsViewProps = {
  contact: Contact;
};

export const DetailsView = ({ contact }: DetailsViewProps) => {
  const { defaultTimeZone: timeZone } = useSettings();

  if (!contact || !timeZone) return null;

  return (
    <div className="flex flex-col flex-1 min-h-0 ">
      {contact.description?.length ? (
        <span className="text-[14px] font-inter leading-0 p-2 border border-gray-scheduled rounded select-none">
          {contact.description}
        </span>
      ) : null}
    </div>
  );
};
