import { CalendarAccessRole, EventVisibility, Priority, TimeBlockingType } from '@prisma/client';
import { conferencingOptions } from './useConferencingOptions';
import { SuggestionDataItem } from 'ff-mentions';
import { capitalize } from 'utils/string';
import { DEFAULT_DURATION_OPTIONS } from '../DurationSelector';
import { Calendar } from '@/models/CalendarModel';
import { parseRecurrence } from '@/utils/parseRecurrence';
import { parseDuration } from '@/utils/parseDuration';
import { parseDatesTimes } from '@/utils/parseDatesTimes';
import { formatDate, formatDateRange, roundToNextBlock } from '@/utils/date';
import { getFreeSlots } from '@/hooks/useFreeSlots';
import moment from 'moment-timezone';
import { searchContacts } from '@/hooks/useContactSearch';
import { Availability } from '@/models/AvailabilityModel';

type SuggestionType =
  | 'contact'
  | 'parsedDate'
  | 'freeSlot'
  | 'auto-schedule'
  | 'project'
  | 'open-sidebar'
  | 'search'
  | 'priority'
  | 'duration'
  | 'location'
  | 'submenu'
  | 'schedule'
  | 'deadline'
  | 'EVENT-REF'
  | 'TASK-REF'
  | 'NOTE-REF'
  | 'recurrence'
  | 'timeBlocking'
  | 'calendar'
  | 'visibility'
  | 'conference';

export const buildSuggestionId = (type: SuggestionType, id: string) => {
  return `${type}:${id}`;
};

export const extractSuggestionTypeAndValue = (id: string): { type: SuggestionType; value: string } => {
  let getId = id;
  if (getId.includes('~~JOIN~~')) {
    const parts = getId.split(/~~JOIN~~/);
    getId = parts[0];
  }
  const parts = getId.split(/:/);
  return { type: parts.shift() as SuggestionType, value: parts.join(':') };
};

export const PRIORITY_SUGGESTIONS = Object.values(Priority).map((priority) => ({
  id: buildSuggestionId('priority', priority),
  display: capitalize(priority).replace('_', ' '),
  category: 'priority',
}));

export const TIME_BLOCKING_SUGGESTIONS = Object.values(TimeBlockingType).map((type) => ({
  id: buildSuggestionId('timeBlocking', type),
  display: capitalize(type),
  category: 'Time Blocking',
}));

export const VISIBILITY_TYPE_SUGGESTIONS = Object.values(EventVisibility).map((type) => ({
  id: buildSuggestionId('visibility', type),
  display: type,
  category: 'visibility',
}));

export const CONFERENCE_SUGGESTIONS = Object.entries(conferencingOptions).map(([option, display]) => ({
  id: buildSuggestionId('conference', option),
  display: display,
  category: 'conference',
}));

export async function getSuggestedFreeSlots({
  search,
  submenu,
  timeZone,
}: {
  timeZone: string;
  submenu: string | null;
  search: string;
}) {
  let freeSlots: SuggestionDataItem[] = [];
  const type = submenu === 'deadline' ? 'deadline' : 'freeSlot';
  const category = submenu === 'deadline' ? 'deadline' : 'date';

  if (search.length === 0) {
    const allFreeSlots = await getFreeSlots({
      start: roundToNextBlock(new Date(), 30),
      end: roundToNextBlock(moment.tz(timeZone).startOf('day').add(4, 'days').toDate(), 30), // TODO limit
      timeZone,
    });
    freeSlots =
      allFreeSlots
        ?.filter((_, index) => index < 2)
        ?.map((slot) => ({
          id: buildSuggestionId(type, slot.toISOString()),
          display: formatDate({ date: slot, timeZone, allDay: false }),
          category,
        })) ?? []; // show next 2 free slots
  }

  return freeSlots;
}

export async function getSuggestedLocation({
  ready,
  status,
  data,
}: {
  ready: boolean;
  status: string;
  data: google.maps.places.AutocompletePrediction[];
}) {
  if (ready && status === 'OK' && data?.length) {
    return data.map((place) => ({
      id: buildSuggestionId('location', place.description),
      display: place.description,
      category: 'location',
    }));
  }
  return [];
}

export async function getSuggestedContacts({
  mentions,
  search,
  itemType,
}: {
  search: string;
  mentions: any[];
  itemType: string;
}) {
  const mentionsExclude = mentions
    .map((mention) => extractSuggestionTypeAndValue(mention.id))
    .filter(({ type }) => type === 'contact')
    .map(({ value }) => value);

  const suggestedContactsP = await searchContacts(search, mentionsExclude.join(','));

  const suggestedContacts =
    suggestedContactsP?.map((contact) => ({
      id: buildSuggestionId(
        'contact',
        contact.picture ? contact.email + '~~JOIN~~' + contact.picture : contact.email || ''
      ),
      display: contact.name || contact.email,
      picture: contact.picture || '',
      category: itemType === 'SCHEDULING_LINK' ? 'Check for conflicts with' : 'contacts',
    })) ?? [];

  if (!search.length) {
    return suggestedContacts.splice(0, 3);
  }

  return suggestedContacts;
}

export async function getSuggestedCustomSchedules({
  search,
  availability = [],
  itemType,
}: {
  search: string;
  availability: Availability[] | undefined;
  itemType: string;
}) {
  return (
    availability
      ?.filter((schedule) => schedule.title.toLowerCase().includes(search.toLowerCase()))
      .map((schedule) => ({
        id: buildSuggestionId('schedule', schedule.id),
        display: (itemType === 'TASK' ? 'Auto-schedule during ' : '') + schedule.title,
        category: 'schedules',
      })) || []
  );
}

export async function getSubmenuSuggestions({
  submenu,
  search,
  calendars = [],
  parsedDates,
  timeZone,
}: {
  submenu: string | null;
  search: string;
  calendars?: Calendar[];
  parsedDates: SuggestionDataItem[];
  timeZone: string;
}) {
  let suggestions: SuggestionDataItem[] = [];

  switch (submenu) {
    case 'priority':
      suggestions = PRIORITY_SUGGESTIONS;
      break;
    case 'timeBlocking':
      suggestions = TIME_BLOCKING_SUGGESTIONS;
      break;
    case 'visibility':
      suggestions = VISIBILITY_TYPE_SUGGESTIONS;
      break;
    case 'conference':
      suggestions = CONFERENCE_SUGGESTIONS;
      break;

    case 'duration':
      suggestions = DEFAULT_DURATION_OPTIONS.map<SuggestionDataItem>((value) => ({
        id: buildSuggestionId('duration', value.toString()),
        display: `${value} minutes`,
        category: 'duration',
      }));
      break;

    case 'deadline':
      if (parsedDates.length) {
        return parsedDates;
      }

      return getSuggestedFreeSlots({ search, submenu, timeZone });

    case 'calendar': {
      suggestions =
        calendars
          ?.filter(({ accessRole }) =>
            [CalendarAccessRole.owner, CalendarAccessRole.writer].includes(accessRole as any)
          )
          .map((calendar) => ({
            id: buildSuggestionId('calendar', calendar.id),
            display: calendar.summary,
            category: 'calendar',
          })) || [];
      break;
    }
  }

  return suggestions.filter(({ display }) => display?.toLowerCase().includes(search.toLowerCase()));
}

export function filterBySearch(search: string, suggestions: SuggestionDataItem[]) {
  return suggestions.filter(({ display }) => display!.toLowerCase().includes(search.toLowerCase()));
}

export const ITEM_TYPE_SUGGESTIONS = {
  SCHEDULING_LINK: [
    {
      id: buildSuggestionId('submenu', 'duration'),
      display: 'Duration',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'calendar'),
      display: 'Calendar',
      category: 'DETAILS',
    },
  ],
  EVENT: [
    {
      id: buildSuggestionId('submenu', 'calendar'),
      display: 'Calendar',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'visibility'),
      display: 'Visibility',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'conference'),
      display: 'Conference',
      category: 'DETAILS',
    },
  ],
  NOTE: [
    {
      id: buildSuggestionId('submenu', 'priority'),
      display: 'Priority',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'duration'),
      display: 'Duration',
      category: 'DETAILS',
    },
  ],
  TASK: [
    {
      id: buildSuggestionId('submenu', 'priority'),
      display: 'Priority',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'duration'),
      display: 'Duration',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'deadline'),
      display: 'Hard deadline',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId('submenu', 'timeBlocking'),
      display: 'Time blocking',
      category: 'DETAILS',
    },
  ],
};

export function getRecurrenceSuggestions(search: string) {
  const rrule = parseRecurrence(search);

  if (!rrule) {
    return [];
  }

  return [
    {
      id: buildSuggestionId('recurrence', rrule.toString()),
      display: rrule.toText(),
      category: 'schedules',
    },
  ];
}

export function getDurationSuggestions(search: string) {
  const duration = parseDuration(search);

  if (!duration) {
    return [];
  }

  return [
    {
      id: buildSuggestionId('duration', duration.valueInMinutes.toString()),
      display: duration.display,
      category: 'duration',
    },
  ];
}

export function getAutoScheduleSuggestions(search: string) {
  if (!'auto-schedule'.includes(search)) {
    return [];
  }

  return [
    {
      id: 'auto-schedule',
      display: 'Auto-schedule with Smarty',
      category: 'date',
    },
  ];
}

export function getDatesSuggestions({
  search,
  itemType,
  timeZone,
  submenu,
}: {
  search: string;
  itemType: string;
  timeZone: string;
  submenu: string | null;
}) {
  return parseDatesTimes(search, itemType, timeZone).map((date) => {
    let type: SuggestionType;

    if (date.isTimeRange && itemType === 'TASK') {
      type = 'auto-schedule';
    } else {
      type = submenu === 'deadline' ? 'deadline' : 'parsedDate';
    }

    const category = submenu === 'deadline' ? 'deadline' : 'date';

    let display = formatDateRange({
      start: date.start,
      end: date.end,
      timeZone,
      allDay: date.allDay,
    });

    if (date.display) {
      if (date.display === 'before' || date.display === 'by') {
        const formattedDate = formatDate({
          date: date.end,
          timeZone,
          allDay: date.timeIsCertain ? date.allDay : true,
        });

        display = `Auto-schedule for ${date.display} ${formattedDate}`;
      } else if (date.display === 'after') {
        const formattedDate = formatDate({
          date: date.start,
          timeZone,
          allDay: date.timeIsCertain ? date.allDay : true,
        });

        display = `Auto-schedule for ${date.display} ${formattedDate}`;
      } else {
        display = `Auto-schedule for ${display}`;
      }
    } else if (date.isTimeRange && itemType === 'TASK') {
      display = `Auto-schedule for ${display}`;
    }

    return {
      id: buildSuggestionId(type, `${date.start.toISOString()}%%%${date.end?.toISOString()}%%%${date.allDay ?? false}`),
      display,
      category,
    };
  });
}
