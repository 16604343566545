import { ContactEvent } from '@/hooks/useContactEvents';
import { Filter } from '@/hooks/useFilter';
import { SmartyItemType } from '@/hooks/useSmartyItems';
import { Task } from '@/models/TaskModel';
import { Deadline } from '@prisma/client';
import moment from 'moment-timezone';

export function filterItems(items: (SmartyItemType | ContactEvent)[], filter: Filter) {
  return items.filter((item) => {
    if (filter.types.length && !filter.types.some((type) => type.id === item.itemType)) {
      return false;
    }
    if (
      filter.projects.length &&
      !filter.projects.some((project) =>
        (item as SmartyItemType).projectIds?.some((projectId) => projectId === project.id)
      )
    ) {
      return false;
    }
    if (
      filter.statuses.length &&
      !filter.statuses.some((status) => {
        if (status.id === 'COMPLETED' && (item as Task).isCompleted) return true;
        if (status.id === 'ACTIVE' && !(item as Task).isCompleted) return true;
        return false;
      })
    ) {
      return false;
    }
    if (filter.priorities.length && !filter.priorities.some((priority) => priority.id === (item as Task).priority)) {
      return false;
    }
    if (
      filter.contacts.length &&
      !filter.contacts.some((contact) =>
        (item as Task).contacts?.some((itemContact) => itemContact.email === contact.id)
      )
    ) {
      return false;
    }
    if (filter.tasks.length && !filter.tasks.some((task) => item.id === task.id)) {
      return false;
    }
    if (
      filter.schedulingLinks.length &&
      !filter.schedulingLinks.some((schedulingLink) => item.id === schedulingLink.id)
    ) {
      return false;
    }
    if (filter.events.length && !filter.events.some((event) => item.id === event.id)) {
      return false;
    }
    if (filter.notes.length && !filter.notes.some((note) => item.id === note.id)) {
      return false;
    }

    if (
      item.itemType !== 'CONTACT_EVENT' &&
      filter.createdDate.length &&
      !filter.createdDate.some((date) => moment(item.createdAt).isSame(date.id, 'day'))
    ) {
      return false;
    }

    if (
      filter.deadlineDate.length &&
      !filter.deadlineDate.some((date) => {
        if (item.itemType !== 'TASK') return false;
        if (!item.deadlineType || item.deadlineType === Deadline.NONE) return true;
        if (item.deadlineType === Deadline.HARD_DEADLINE && moment(item.dueDate!).isSame(date.id, 'day')) return true;
        if (
          item.deadlineType === Deadline.SOFT_DEADLINE &&
          moment(date.id).isBetween(item.deadlineStart!, item.dueDate!, 'day')
        )
          return true;
      })
    ) {
      return false;
    }

    if (
      filter.scheduledDate.length &&
      !filter.scheduledDate.some((date) => moment(item.start).isSame(date.id, 'day'))
    ) {
      return false;
    }

    return true;
  });
}
