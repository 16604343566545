import { MdClose } from 'react-icons/md';
import { useRouter } from 'next/router';
import Button from '@/components/Button';
import { buildZoomAuthorizeLink } from '@/hooks/useZoom';

type ZoomAuthenticationErrorModalProps = {
  onClose: () => void;
};

export default function ZoomAuthenticationErrorModal({ onClose }: ZoomAuthenticationErrorModalProps) {
  const router = useRouter();

  const handleReconnectZoom = async () => {
    await router.push(buildZoomAuthorizeLink());
  };

  return (
    <div className={`fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex`}>
      <div className="overflow-hidden flex flex-col m-auto bg-white rounded-lg max-w-[800px] w-full p-[25px] transition-all">
        <button type="button" className="text-xl text-black place-self-end mt-[8px] mr-[8px]" onClick={onClose}>
          <MdClose />
        </button>
        <p>We’re having trouble connecting to your Zoom account.</p>
        <p className="my-8">Please sign in again.</p>
        <Button variant="primary" className="mt-4" onClick={handleReconnectZoom}>
          Log into Zoom again
        </Button>
      </div>
    </div>
  );
}
