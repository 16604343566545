import { Node, NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';

export default Node.create({
  name: 'details',
  group: 'block',
  content: 'item+',
  parseHTML() {
    return [
      {
        tag: 'details',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['details', mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Details);
  },
});

function Details() {
  return (
    <NodeViewWrapper>
      <NodeViewContent as="div" className="collapsible-details" />
    </NodeViewWrapper>
  );
}
