import { useDeleteEvent, useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useDeleteSchedulingLink, useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { useDeleteTask, useTasks } from '@/hooks/useTasks';
import { ItemType, RichTextItem } from '../../types';
import { useCallback, useEffect, useRef } from 'react';
import { convertTaskToTaskArg, TaskArg } from '@/models/TaskArg';
import { convertEventToEventArg, EventArg } from '@/models/EventArg';
import { convertNoteToNoteArg, NoteArg } from '@/models/NoteArg';
import { convertSchedulingLinkToSchedulingLinkArg, SchedulingLinkArg } from '@/models/SchedulingLinkArg';
import { SmartyItemType } from '@/hooks/useSmartyItems';
import useRichTextItemToItemArg from '../../hooks/useRichTextItemToItemArg';
import { isTaskCompleted } from '@/utils/tasks';
import { toast } from 'react-hot-toast';
import { ItemCreated } from '@/components/ToastItemCreated';
import { ToastGeneric } from '@/components/ToastGeneric';
import moment from 'moment-timezone';
import { useSettings } from '@/hooks/useSettings';

export default function useItemMutations() {
  const { defaultDuration } = useSettings();
  const { tasks, createTaskAsync, updateTask, resetCreateTask, resetUpdateTask } = useTasks({
    onUpdated: (task) => {
      toast(<ItemCreated item={task} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
      return;
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Task not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
      return;
    },
    onCreated: (task) => {
      toast(<ItemCreated item={task} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
      return;
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Task not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateTask();
      return;
    },
  });

  const { deleteTask } = useDeleteTask();
  const { createEvent, updateEvent, resetCreateEvent, resetUpdateEvent } = useEvents({
    onCreated: (event) => {
      if (event.summary) {
        toast(<ItemCreated item={event} />, { position: 'bottom-center', style: { background: '#000' } });
      }
      resetCreateEvent();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Event not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateEvent();
    },
    onUpdated: (event) => {
      toast(<ItemCreated item={event} isUpdate={true} />, { position: 'bottom-center', style: { background: '#000' } });
      resetUpdateEvent();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Event not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateEvent();
      return;
    },
  });
  const { deleteEvent } = useDeleteEvent();
  const { createSchedulingLink, updateSchedulingLink } = useSchedulingLinks({});
  const { deleteSchedulingLink } = useDeleteSchedulingLink();
  const { createNote, updateNote, deleteNote } = useNotes();

  const richTextItemToItemArg = useRichTextItemToItemArg();
  const tasksRef = useRef(tasks?.results);

  useEffect(() => {
    tasksRef.current = tasks?.results;
  }, [tasks?.results]);

  const createItem = useCallback(
    async (item: RichTextItem, notifyGuests?: boolean) => {
      const itemArg = await richTextItemToItemArg(item);
      if (!itemArg) return;

      switch (item.itemType) {
        case ItemType.TASK: {
          const end =
            itemArg.end ?? itemArg.start ? moment(itemArg.start).add(defaultDuration, 'minutes').toDate() : null;
          return createTaskAsync({ ...(itemArg as TaskArg), end: end ?? null });
        }

        case ItemType.EVENT:
          return createEvent({ ...(itemArg as EventArg), notifyGuests });
        case ItemType.SCHEDULING_LINK:
          return createSchedulingLink(itemArg as SchedulingLinkArg);
        case ItemType.NOTE:
          return createNote(itemArg as NoteArg);
      }
    },
    [defaultDuration, richTextItemToItemArg, createTaskAsync, createEvent, createSchedulingLink, createNote]
  );

  const updateItem = useCallback(
    async (item: RichTextItem, notifyGuests?: boolean) => {
      const itemArg = await richTextItemToItemArg(item);
      if (!itemArg || !item.id) return;

      switch (item.itemType) {
        case ItemType.TASK: {
          const oldTask = tasksRef.current?.find((t) => t.id === item.id);
          const wasChecked = oldTask ? isTaskCompleted({ ...oldTask, start: item.start }) : false;

          if (wasChecked !== item.isCompleted) {
            return updateTask({
              taskId: item.id,
              task: { ...(itemArg as TaskArg), isCompleted: Boolean(item.isCompleted), completedDate: new Date() },
            });
          }
          return updateTask({ taskId: item.id, task: { ...(itemArg as TaskArg) } });
        }
        case ItemType.EVENT:
          return updateEvent({ eventId: item.id, event: { ...(itemArg as EventArg), notifyGuests } });
        case ItemType.SCHEDULING_LINK:
          return updateSchedulingLink({ schedulingLinkId: item.id, schedulingLink: itemArg as SchedulingLinkArg });
        case ItemType.NOTE:
          return updateNote({ noteId: item.id, note: itemArg as NoteArg });
      }
    },
    [richTextItemToItemArg, updateTask, updateEvent, updateSchedulingLink, updateNote]
  );

  const updateSmartyItem = useCallback(
    async (item: SmartyItemType) => {
      switch (item.itemType) {
        case ItemType.TASK: {
          return updateTask({ taskId: item.id, task: convertTaskToTaskArg(item) });
        }
        case ItemType.EVENT: {
          return updateEvent({ eventId: item.id, event: convertEventToEventArg(item) });
        }
        case ItemType.SCHEDULING_LINK: {
          return updateSchedulingLink({
            schedulingLinkId: item.id,
            schedulingLink: convertSchedulingLinkToSchedulingLinkArg(item),
          });
        }
        case ItemType.NOTE: {
          return updateNote({
            noteId: item.id,
            note: convertNoteToNoteArg(item),
          });
        }
      }
    },
    [updateEvent, updateNote, updateSchedulingLink, updateTask]
  );

  const deleteItem = useCallback(
    async (item: { id: string; itemType: ItemType }) => {
      switch (item.itemType) {
        case ItemType.TASK:
          return deleteTask({ taskId: item.id! });
        case ItemType.EVENT:
          return deleteEvent({ eventId: item.id! });
        case ItemType.SCHEDULING_LINK:
          return deleteSchedulingLink({ schedulingLinkId: item.id! });
        case ItemType.NOTE:
          return deleteNote({ noteId: item.id! });
      }
    },
    [deleteTask, deleteEvent, deleteSchedulingLink, deleteNote]
  );

  return { createItem, updateItem, deleteItem, updateSmartyItem };
}
