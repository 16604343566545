import { ReactNode } from 'react';
import { useShortcuts } from '@/context/Common';

type Props = {
  shortcut: string;
  icon?: ReactNode;
};

const KeyboardShortcutIndicator = ({ shortcut, icon }: Props) => {
  const { showShortcuts } = useShortcuts();
  if (!showShortcuts) return null;
  return (
    <>
      {icon ? (
        <div className="text-white font-bold bg-[#79C0F6] rounded select-none px-0.5 text-xs p-[2px] justify-center flex items-center w-auto min-w-[20px] h-[20px] text-center mr-[2px]">
          {icon}
        </div>
      ) : null}
      <span
        className={`text-white font-bold bg-[#79C0F6] rounded select-none px-0.5 text-xs p-[2px] justify-center flex items-center w-auto min-w-[20px] h-[20px] text-center ml-[2px]`}>
        {shortcut}
      </span>
    </>
  );
};

export default KeyboardShortcutIndicator;
