import { useQuery } from '@tanstack/react-query';
import { httpGet } from 'utils/smarty-api';
import moment from 'moment-timezone';
import { DateInterval, serializeDateIntervals } from '@/utils/date';

type getFreeSlotsParams = {
  start?: Date | null;
  end?: Date | null;
  slots?: DateInterval[];
  timeZone: string;
  duration?: number;
  resolution?: number;
  contactEmails?: string[];
  checkCalendarIds?: string[];
  allowPastSlots?: boolean;
  useWorkingHours?: boolean;
  ignoreAutoScheduledTasks?: boolean;
  availabilityId?: string;
  isBusy?: boolean;
};

export const getFreeSlots = async (params: getFreeSlotsParams) => {
  if (params.slots && !params.slots.length) return [];
  const data = await httpGet<string[]>('/free-slots', {
    params: {
      ...params,
      checkCalendarIds: params.checkCalendarIds?.join(',') || undefined,
      contactEmails: params.contactEmails?.join(',') || undefined,
      slots: serializeDateIntervals(params.slots) || undefined,
    },
  });
  return data.map((s) => moment(s).toDate());
};

type useFreeSlotsParams = getFreeSlotsParams & {
  enabled?: boolean;
  initialFreeSlots?: Date[];
  checkCalendarIds?: string[];
};

export const useFreeSlots = ({
  enabled = true,
  start,
  end,
  timeZone,
  duration,
  resolution,
  contactEmails,
  slots,
  initialFreeSlots,
  checkCalendarIds,
  availabilityId,
  ignoreAutoScheduledTasks,
}: useFreeSlotsParams) => {
  const { data: freeSlots, isLoading: isLoadingFreeSlots } = useQuery({
    queryKey: [
      'FREE_SLOTS',
      start,
      end,
      timeZone,
      duration,
      resolution,
      contactEmails,
      slots,
      availabilityId,
      ignoreAutoScheduledTasks,
    ],
    queryFn: () => {
      return getFreeSlots({
        start,
        end,
        timeZone,
        duration,
        contactEmails,
        slots,
        resolution,
        checkCalendarIds,
        availabilityId,
        ignoreAutoScheduledTasks,
      });
    },
    enabled,
    refetchOnWindowFocus: false,
    initialData: initialFreeSlots,
  });
  return {
    freeSlots,
    isLoadingFreeSlots,
  };
};

type useSchedulingLinkFreeSlotsProps = useFreeSlotsParams & {
  adjacent?: boolean;
};

export const useSchedulingLinkFreeSlots = ({ adjacent, ...rest }: useSchedulingLinkFreeSlotsProps) => {
  const { freeSlots, isLoadingFreeSlots } = useFreeSlots(rest);
  return {
    freeSlots: adjacent
      ? formatFreeSlotsAdjacent(freeSlots, rest.duration, rest.timeZone)
      : formatFreeSlotsDiscrete(freeSlots, rest.duration, rest.timeZone),
    isLoadingFreeSlots,
  };
};

export const formatFreeSlotsAdjacent = (
  freeSlots: Date[] | undefined,
  duration: number | undefined,
  timeZone: string
) => {
  const combinedSlots: {
    [key: string]: {
      label: string;
      slots: string[][];
    };
  } = {};

  freeSlots?.forEach((slot) => {
    const date = moment.tz(slot, timeZone).format('YYYY-MM-DD');
    const time = moment.tz(slot, timeZone).format('hh:mm A');

    if (!combinedSlots[date]) {
      combinedSlots[date] = {
        label: moment.tz(slot, timeZone).format('MMMM DD (dddd)'),
        slots: [[time]],
      };
    } else {
      const lastSlot = combinedSlots[date].slots[combinedSlots[date].slots.length - 1];
      const slotTimeParsed = moment(time, 'hh:mm A');
      const lastSlotEndTime = moment(lastSlot[1] || lastSlot[0], 'hh:mm A').add(duration, 'minutes');

      if (lastSlotEndTime.isSameOrAfter(slotTimeParsed)) {
        lastSlot[1] = time;
      } else {
        combinedSlots[date].slots.push([time]);
        lastSlot[1] = lastSlot[1] || lastSlot[0]; // Make sure the previous slot has an end time
      }
    }
  });

  return combinedSlots;
};

export const formatFreeSlotsDiscrete = (
  freeSlots: Date[] | undefined,
  duration: number | undefined,
  timeZone: string
) => {
  const combinedSlots: {
    [key: string]: {
      label: string;
      slots: string[][];
    };
  } = {};

  freeSlots?.forEach((slot) => {
    const date = moment.tz(slot, timeZone).format('YYYY-MM-DD');
    const time = moment.tz(slot, timeZone).format('hh:mm A');

    if (!combinedSlots[date]) {
      combinedSlots[date] = {
        label: moment.tz(slot, timeZone).format('MMMM DD (dddd)'),
        slots: [[time]],
      };
    } else {
      const lastSlot = combinedSlots[date].slots[combinedSlots[date].slots.length - 1];
      const lastSlotEndTime = moment
        .tz(lastSlot[1] || lastSlot[0], 'hh:mm A', timeZone)
        .add(duration, 'minutes')
        .format('hh:mm A');

      const currentTime = moment.tz(time, 'hh:mm A', timeZone);

      if (currentTime.isAfter(moment.tz(lastSlotEndTime, 'hh:mm A', timeZone))) {
        lastSlot[1] = lastSlot[1] || lastSlotEndTime; // Set the end time of the last slot before adding a new slot
        combinedSlots[date].slots.push([time]);
      }
    }
  });

  // Set the end time for the last slot of each date
  Object.values(combinedSlots).forEach(({ slots }) => {
    const lastSlot = slots[slots.length - 1];
    if (!lastSlot[1]) {
      lastSlot[1] = moment.tz(lastSlot[0], 'hh:mm A', timeZone).add(duration, 'minutes').format('hh:mm A');
    }
  });

  return combinedSlots;
};
