import { useEffect, useRef, useState, useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { chatStateAtom } from './Overlay/utils';
import { httpPost, httpPostBinary } from 'utils/smarty-api';
import { TypingIndicator } from './TypingIndicator';
import { createChannelName, getPusherInstance } from 'utils/pusherClient';
import moment from 'moment-timezone';
import { formatFreeSlotsAdjacent } from '@/hooks/useFreeSlots';
import { buildLink, copyToClipboard, copyToClipboardPlainText } from '@/utils/copyToClipboard';
import { useSettings } from '@/hooks/useSettings';
import { ServerConfirmation } from '@/utils/chat/schema';
import { FaMicrophone, FaStop, FaSpinner } from 'react-icons/fa';

type Message = {
  sender: 'user' | 'server' | 'server-html';
  text: string;
  isConfirmationResponse?: boolean;
  dataJSON?: string;
  copyToClipboard?: boolean;
  copyData?: any;
};

type ChatApiResponse = {
  response?: string;
  message?: string;
  status?: string;
  confirmationMessage?: string;
  confirmationExecutedActions?: string[];
  parsedUserResponse?: ServerConfirmation[];
  duration?: number;
  compiledSlots?: Date[];
  timeZone?: string;
  availabilityDetails: any;
  username?: string;
};

export default function ChatThread({ onboarding = false }: { onboarding: boolean }) {
  const chatValue = useAtomValue(chatStateAtom);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { settings } = useSettings();
  const pusherChannelName = createChannelName(settings?.username ?? '');
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isTranscribing, setIsTranscribing] = useState(false);

  const appendMessage = (newMessage: Message) => {
    setMessages(prevMessages => {
      const messagesCopy = [...prevMessages];
      if (messagesCopy.length > 0 && messagesCopy[messagesCopy.length - 1].text === '...') {
        messagesCopy.pop();
      }
      messagesCopy.push(newMessage);
      return messagesCopy;
    });
  };

  const parseServerResponse = useCallback((data: ChatApiResponse) => {
    if (data.status === 'server-confirmation-response') {
      const confirmationResults = data.confirmationExecutedActions;
      if (confirmationResults && confirmationResults.length > 0) {
        confirmationResults.forEach((item) => {
          appendMessage({ sender: 'server', text: item });
        });
      }
    } else if (data.status === 'server-final-confirmation-scheduling') {
      if (data.duration && data.timeZone) {
        const freeSlotsEntries = formatFreeSlotsAdjacent(data.compiledSlots, data.duration, data.timeZone);
        if (freeSlotsEntries) {
          const slotsText = Object.entries(freeSlotsEntries)
            .map(([, info]) => {
              const slotLabel = info.label;
              const slotTimes = info.slots
                .map((slotRange: any[]) => {
                  const startTime = slotRange[0];
                  const endTime = moment(slotRange[1] || startTime, 'h:mm A')
                    .add(data.duration, 'minutes')
                    .format('h:mm A');
                  return `• ${startTime} - ${endTime}`;
                })
                .join('\n');
              return `${slotLabel}\n${slotTimes}`;
            })
            .join('\n\n');

          const url = `${process.env.NEXT_PUBLIC_URL}/${data.username || ''}/${data.availabilityDetails.userLink.code}`;
          const link = { url, title: data.availabilityDetails.title };

          const formattedLinkURL = buildLink(link.url, link.title);
          appendMessage({ sender: 'server', text: slotsText });
          appendMessage({
            sender: 'server',
            text: 'Scheduling link also created.\n',
            copyToClipboard: true,
            copyData: { linkHTML: formattedLinkURL, linkURL: link.url, slots: slotsText },
          });
        }
      } else {
        appendMessage({ sender: 'server', text: 'No free times found.' });
      }
    } else if (data.status === 'server-final-confirmation') {
      const messageToUser = data.message + (data.confirmationMessage ? data.confirmationMessage : '');
      appendMessage({ sender: 'server', text: messageToUser });
    } else if (data.status === 'server-confirming-action') {
      const serverConfirmations = data.parsedUserResponse;
      if (data.status === 'server-confirming-action' && serverConfirmations) {
        serverConfirmations.forEach((item) => {
          const confirmationMessage = item.confirmationMessage;
          const response = item.response.response;
          const action = item.action;
          appendMessage({ sender: 'server', text: response });
          if(action.action === 'email.create'){
            appendMessage({ sender: 'server', text: "I will create this email" });
            appendMessage({ sender: 'server', text: `Email Subject:\n${action.data.subject}` });
            appendMessage({ sender: 'server-html', text: `<p>Email Body:</p>${action.data.body}` });
          } else {
            appendMessage({ sender: 'server', text: confirmationMessage });
          }
        });
        appendMessage({
          sender: 'server',
          text: 'Please confirm and I will get it done.',
          dataJSON: JSON.stringify(data.parsedUserResponse),
          isConfirmationResponse: true,
        });
      }
    } else if (data.status === 'server-conducting-action') {
      appendMessage({ sender: 'server', text: data.message || 'I will let you know soon.' });
    } else {
      appendMessage({ sender: 'server', text: data.message || '', dataJSON: JSON.stringify(data) });
    }
  }, []);

  useEffect(() => {
    const pusher = getPusherInstance();
    const channel = pusher.subscribe(pusherChannelName);

    const handleNewMessage = (data: ChatApiResponse) => {
      parseServerResponse(data);
      inputRef.current?.focus();
    };

    channel.bind('new-message', handleNewMessage);
    return () => {
      channel.unbind('new-message', handleNewMessage);
      pusher.unsubscribe(pusherChannelName);
    };
  }, [parseServerResponse, pusherChannelName]);

  const sendMessageToServer = useCallback(async (text = '', lastMessageDetails?: Message) => {
    setIsLoading(true);
    appendMessage({ sender: 'server', text: '...' });
    const { isConfirmationResponse: isLastMessageConfirmationResponse, dataJSON: dataJSONLastMessage } =
      lastMessageDetails || { isConfirmationResponse: false, dataJSON: '' };

    try {
      if (isLastMessageConfirmationResponse) {
        (await httpPost('/chat', {
          userResponse: text,
          isConfirmationResponse: isLastMessageConfirmationResponse,
          dataJSON: dataJSONLastMessage,
        })) as ChatApiResponse;
      } else {
        (await httpPost('/chat', { userResponse: text, dataJSON: dataJSONLastMessage })) as ChatApiResponse;
      }
    } catch (error) {
      console.error('Failed to fetch/send message:', error);
      if (onboarding) appendMessage({ sender: 'server', text: 'You must be logged in to chat with Smarty.' });
      else appendMessage({ sender: 'server', text: 'Failed to load message.' });
    }
    setIsLoading(false);
  }, [onboarding]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (chatValue.value && chatValue.value?.trim() !== '') {
      const newUserMessage: Message = { sender: 'user', text: chatValue.value };
      appendMessage(newUserMessage);
      sendMessageToServer(chatValue.value);
    }
  }, [chatValue, sendMessageToServer]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    adjustTextareaHeight();
  };

  const handleSendMessage = async () => {
    if (input.trim() !== '' && !isLoading) {
      const newUserMessage: Message = { sender: 'user', text: input };
      appendMessage(newUserMessage);
      const lastMessage = messages[messages.length - 1];
      sendMessageToServer(input, lastMessage);
      setInput('');
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
  };

  const handleRecordVoice = async () => {
    if (!navigator.mediaDevices || !window.MediaRecorder) {
      alert('Recording is not supported in this browser.');
      return;
    }

    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const newMediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        newMediaRecorder.ondataavailable = (event: BlobEvent) => {
          audioChunks.push(event.data);
        };

        newMediaRecorder.onstop = async () => {
          setIsTranscribing(true);
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          const fd = new FormData();
          fd.append('file', audioBlob, 'audio.wav');

          try {
            const response = await httpPostBinary('/voice-transcription', fd, {
              headers: {
                'Content-Type': 'audio/wav'
              }
            });
            const responseData = response.data as { transcript?: string };
            if (responseData.transcript) {
              setInput(responseData.transcript);
            }
          } catch (error) {
            console.error('Error during transcription fetch:', error);
          }
          setIsRecording(false);
          setIsTranscribing(false);
        };

        newMediaRecorder.start(1000);
        setIsRecording(true);
        setMediaRecorder(newMediaRecorder);
      } catch (error) {
        console.error('Error recording audio:', error);
        alert('Error recording audio. Please try again.');
        setIsRecording(false);
      }
    } else {
      mediaRecorder?.stop();
      mediaRecorder?.stream.getTracks().forEach(track => {
        track.stop();
      });
      setIsRecording(false);
    }
  };
  return (
    <div className="flex flex-col h-full overflow-x-hidden">
      <div className="p-2 overflow-y-auto lg:h-full" style={{ height: 'calc(100dvh - 125px)' }}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex w-full mb-2 ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div
              className={`w-[75%] p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-200' : 'bg-gray-200 text-left whitespace-pre-wrap'}`}>
              {message.sender === 'server-html' ? (
                <div dangerouslySetInnerHTML={{ __html: message.text }} />
              ) : (
                <p className="flex text-wrap">{message.text === '...' ? <TypingIndicator /> : message.text}</p>
              )}
              {message.copyToClipboard && (
                <>
                  <div>
                    <button
                      onClick={() => copyToClipboardPlainText(message.copyData.slots)}
                      className="p-2 mt-1 mb-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
                      Copy Slots
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => copyToClipboard(message.copyData.linkHTML, message.copyData.linkURL)}
                      className="p-2 mb-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
                      Copy Link
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        copyToClipboard(
                          message.copyData.linkHTML + `<div>${message.copyData.slots}</div>`,
                          message.copyData.slots
                        )
                      }
                      className="p-2 mb-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
                      Copy Both
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      {onboarding && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p>This preview of the Smarty chat feature is limited for onboarding purposes.</p>
        </div>
      )}
      <footer className="shrink-0 flex gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t w-full h-[75px]">
        <textarea
          ref={inputRef}
          value={input}
          onChange={handleInputChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>}
          onKeyDown={handleKeyDown as React.KeyboardEventHandler<HTMLTextAreaElement>}
          placeholder="Type a message..."
          className="w-full rounded-l-lg resize-none overflow-hidden p-2"
          disabled={isLoading || onboarding}
          rows={1}
        />
        {!onboarding ? (<>
          {!isLoading && (
            <>
              <button
                onClick={handleRecordVoice}
                className={`p-2 text-white ${isRecording ? 'bg-red-500' : 'bg-green-500'} rounded hover:bg-${isRecording ? 'red' : 'green'}-600 h-[45px]`}
                disabled={isLoading}>
                {isRecording ? <FaStop /> : <FaMicrophone />}
              </button>
              {isTranscribing ? (
                <div className="flex items-center justify-center h-[45px] w-[45px]">
                  <FaSpinner className="animate-spin text-blue-500" />
                </div>
              ) : (
                <button
                  onClick={handleSendMessage}
                  className="p-2 text-white bg-blue-500 rounded-r-lg hover:bg-blue-600 h-[45px]"
                  disabled={isLoading}>
                  Send
                </button>
              )}
            </>
          )}
          {isLoading && (
            <button onClick={handleCancel} className="p-2 ml-2 text-white bg-red-500 rounded hover:bg-red-600 h-[45px]">
              Cancel
            </button>
          )}
        </>) : (
          <>
            <button
              className="p-2 text-white rounded-r-lg bg-blue-600 h-[45px]"
              disabled={true}>
              Send
            </button>
          </>
        )}
      </footer>
    </div>
  );
}
