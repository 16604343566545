import { ButtonHTMLAttributes, forwardRef, MouseEventHandler, ReactNode } from 'react';
import { cn } from 'ui/cn';

type Props = {
  variant: 'primary' | 'secondary';
  type?: ButtonHTMLAttributes<unknown>['type'];
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  hasError?: boolean;
};

const Button = forwardRef<HTMLButtonElement, Props>((props, forwardedRef) => {
  const { variant, isLoading, type = 'button', onClick, disabled, className, children, hasError } = props;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    onClick?.(e);
  };

  return (
    <button
      ref={forwardedRef}
      type={type}
      data-variant={variant}
      className={cn(
        'flex items-center justify-center gap-[10px] py-[10px] px-[20px] rounded-[8px] text-[16px] leading-[24px] border',
        variant === 'primary' && 'bg-primary-500 font-lexend font-bold text-white border-primary-500',
        variant === 'secondary' && 'bg-white font-inter font-medium text-black border-gray-border',
        disabled && 'bg-gray-600 text-gray-400 border-gray-600',
        isLoading ? 'min-w-[14px] min-h-[14px]' : 'min-w-min min-h-min',
        hasError && 'bg-red-500 text-white',
        className
      )}
      disabled={disabled}
      data-loading={isLoading}
      onClick={handleClick}>
      {isLoading ? (
        <div
          className={cn(
            'w-3 h-3 border-b-2 rounded-full animate-spin',
            variant === 'primary' && 'border-white',
            variant === 'secondary' && 'border-primary-500'
          )}
        />
      ) : null}
      {children}
    </button>
  );
});

Button.displayName = 'Button';
export default Button;
