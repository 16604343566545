import { DraggableProvidedDraggableProps, DraggableStateSnapshot } from 'react-beautiful-dnd';

export enum DraggableType {
  TASK = 'TASK',
  EVENT = 'EVENT',
  SLOT = 'SLOT',
}

export enum DroppableType {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
  CALENDAR_HEADER = 'CALENDAR_HEADER',
  
}

export function createDroppableId(droppableType: DroppableType, id: string) {
  return `${droppableType}::${id}`;
}

export function createDraggableId(droppableType: DroppableType, draggableType: DraggableType, id: string) {
  return `${droppableType}::${draggableType}::${id}`;
}

export function parseDroppableId(droppableId: string) {
  const [droppableType, id] = droppableId.split('::');
  return {
    droppableType: droppableType as DroppableType,
    id,
  };
}

export function parseDraggableId(droppableId: string) {
  const [droppableType, draggableType, id] = droppableId.split('::');
  return {
    droppableType: droppableType as DroppableType,
    draggableType: draggableType as DraggableType,
    id,
  };
}

export function isDraggable(type: string) {
  return type in DraggableType;
}

export function getDraggableStyles(
  style: DraggableProvidedDraggableProps['style'],
  snapshot: DraggableStateSnapshot | undefined,
  animateList = true
) {
  if (!snapshot) return style;

  if (!animateList && !snapshot.isDragging) return { ...style, transform: undefined };

  if (!snapshot.draggingOver) return style;

  const { droppableType } = parseDroppableId(snapshot.draggingOver);

  if (!snapshot?.isDropAnimating || droppableType !== DroppableType.CALENDAR) {
    return style;
  }

  return { ...style, transitionDuration: '1ms', opacity: 0 };
}
