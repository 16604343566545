import { Prisma } from '@prisma/client';
import { ByWeekday, RRule, rrulestr } from 'rrule';
import { useMemo } from 'react';

export const FREQUENCY = ['YEAR', 'MONTH', 'WEEK', 'DAY'];

export const getRRule = (recurrence: Prisma.JsonValue | null) => {
  if (!recurrence || (recurrence instanceof Array && !recurrence[0])) return null;
  const index = (recurrence as string[]).findIndex((r) => r.startsWith('RRULE'));
  const rruleString = (recurrence as string[])[index];
  const freqIndex = rruleString.indexOf(':FREQ');
  let rruleFixed;
  if (freqIndex !== -1) {
    rruleFixed = 'RRULE' + rruleString.substring(freqIndex);
  } else {
    rruleFixed = rruleString;
  }
  return rrulestr(rruleFixed);
};

export const fixedDaysOfWeek = (recurrence: Prisma.JsonValue | null) => {
  const rule = getRRule(recurrence);
  if (!rule) return null;
  return rule.options.byweekday.map((day) => {
    if (day === 6) return 0;
    return day + 1;
  });
};

export const getDayOfWeek = (date: Date) => {
  const day = date.getDay();
  return [day];
};

type RecurrenceObjectProps = {
  recurrenceStart: Date;
  recurrenceEnd: Date | null;
  frequency: string;
  daysOfWeek: number[];
  count: number | null;
  interval: number;
  ordinal?: number;
};

export function deleteDTSTART(value: string): string {
  return value.replace(/^DTSTART:\d{8}T\d{6}Z\n/, '');
}

export const useGetRecurrence = ({
  recurrenceStart,
  recurrenceEnd,
  frequency,
  daysOfWeek,
  count,
  interval,
  ordinal,
}: RecurrenceObjectProps): string[] => {
  const rule = new RRule({
    freq: rightFrequency(frequency),
    interval: interval,
    byweekday:
      daysOfWeek.length > 0
        ? ordinal
          ? byWeekdayConstructor(daysOfWeek, ordinal)
          : daysOfWeek.map((day) => day - 1)
        : undefined,
    dtstart: recurrenceStart,
    until: recurrenceEnd,
    count: count,
  });

  const rruleStringWithoutDTSTART = deleteDTSTART(rule.toString());
  return useMemo(() => [rruleStringWithoutDTSTART], [rruleStringWithoutDTSTART]);
};
export const useRecurrence = (start: Date | null, recurrenceType: string) => {
  const freq = () => {
    switch (recurrenceType) {
      case 'DAILY':
        return 'DAY';
      case 'WEEKLY':
        return 'WEEK';
      case 'MONTHLY':
        return 'MONTH';
      case 'YEARLY':
        return 'YEAR';
      default:
        return 'DAY';
    }
  };
  const recurrence = useGetRecurrence({
    daysOfWeek: getDayOfWeek(start ?? new Date()),
    frequency: freq(),
    interval: 1,
    recurrenceEnd: null,
    recurrenceStart: start ?? new Date(),
    count: null,
  });

  return recurrenceType === 'DOES_NOT_REPEAT' || recurrenceType === 'CUSTOM' ? [] : recurrence;
};

export const byWeekdayConstructor = (daysOfWeek: number[], ordinal?: number) => {
  if (!daysOfWeek.length) return undefined;
  if (!ordinal) return daysOfWeek.map((day) => day - 1);
  const days: ByWeekday | ByWeekday[] = daysOfWeek.map((day) => {
    if (day === 0) return RRule.SU.nth(+ordinal);
    if (day === 6) return RRule.SA.nth(+ordinal);
    if (day === 1) return RRule.MO.nth(+ordinal);
    if (day === 2) return RRule.TU.nth(+ordinal);
    if (day === 3) return RRule.WE.nth(+ordinal);
    if (day === 4) return RRule.TH.nth(+ordinal);
    if (day === 5) return RRule.FR.nth(+ordinal);
    return day;
  });
  return days;
};
export const rightFrequency = (frequency: string) => {
  switch (frequency) {
    case 'DAILY':
      return RRule.DAILY;
    case 'WEEKLY':
      return RRule.WEEKLY;
    case 'MONTHLY':
      return RRule.MONTHLY;
    case 'YEARLY':
      return RRule.YEARLY;
    default:
      return RRule.DAILY;
  }
};

export const frequencyText = (recurrenceType: string | undefined) => {
  switch (recurrenceType) {
    case 'YEARLY':
      return 'Repeats annually';
    case 'MONTHLY':
      return `Repeats monthly`;
    case 'WEEKLY':
      return `Repeats weekly`;
    case 'DAILY':
      return 'Repeats daily';
    case 'DOES_NOT_REPEAT':
      return 'Does not repeat';
    case 'CUSTOM':
      return 'Custom recurrence';
    default:
      return 'Does not repeat';
  }
};
