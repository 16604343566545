import { Conferencing } from '@/screens/settings/Conferencing';
import { MdClose } from 'react-icons/md';
import * as Dialog from '@radix-ui/react-dialog';

export const ConferencingSelectorModal = ({ onClose }: { onClose?: () => void }) => {
  const handleOnClose = (open: boolean) => {
    if (open) return;
    onClose?.();
  };

  return (
    <Dialog.Root open={true} onOpenChange={handleOnClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        <Dialog.Content className="fixed inset-0 flex items-center justify-center">
          <div className="overflow-hidden flex flex-col m-auto bg-white rounded-lg max-w-[800px] w-full p-[25px] transition-all">
            <Dialog.Close className="text-xl text-black place-self-end mt-[8px] mr-[8px]">
              <MdClose />
            </Dialog.Close>
            <Conferencing />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
