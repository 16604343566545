import { useCallback, useMemo } from 'react';
import { buildSuggestionId, extractSuggestionTypeAndValue } from '@/components/Editor/utils/suggestions';
import { Node } from '@tiptap/pm/model';
import { JSONContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { SuggestionType } from '../types';
import moment from 'moment-timezone';
import { NameEmail } from '@/utils/contacts';
import DateRange from './DateRange';
import DueDate from './DueDate';
import Contacts from './Contacts';
import Project from './Project';
import Priority from './Priority';
import { Priority as PriorityType } from '@prisma/client';
import { RolledOverIndicator } from './RolledOver';
import ScheduledDate from './ScheduledDate';
import Reference from './Reference';
import { ItemType } from '@/components/Editor/types';

export default function Decorators({ node, editor, getPos, updateAttributes, extension }: NodeViewProps) {
  const getParentNode = useCallback(() => {
    if (typeof getPos !== 'function') return null;
    let parentNode: Node;

    editor.state.doc?.nodesBetween(getPos(), getPos(), (node) => {
      parentNode = node;
    });

    return parentNode!;
  }, [editor.state.doc, getPos]);

  const content = useMemo(() => {
    const { type, value } = extractSuggestionTypeAndValue(node.attrs!.id);
    const parentNode = getParentNode();
    if (parentNode && parentNode.attrs.parentId) {
      // sub-tasks don't show the date range decorator
      return null;
    }

    switch (type) {
      case SuggestionType.DATE: {
        const [_start, _end, _allDay] = value.split(/%%%/);

        const start = moment(_start).toDate();
        const end = _end && _end !== 'undefined' ? moment(_end).toDate() : null;
        const allDay = _allDay && JSON.parse(_allDay);

        const onChangeDates = (start: Date, end: Date | null, allDay: boolean) => {
          if (typeof getPos !== 'function') return;
          updateAttributes({
            id: buildSuggestionId(type, `${start.toISOString()}%%%${end?.toISOString() || null}%%%${allDay}`),
          });
          editor.commands.save();
        };

        const isBeforeToday = !!end && moment(end).isBefore(moment(), 'day');
        const itemType = parentNode?.attrs.type;
        const forceShowDate = !!parentNode?.attrs.id.startsWith('NEW_ITEM');

        if (itemType !== 'TASK') {
          return (
            <DateRange
              start={start}
              end={end}
              allDay={allDay || itemType === ItemType.SCHEDULING_LINK}
              onChangeDates={onChangeDates}
              forceShowDate={forceShowDate}
            />
          );
        }

        return (
          <>
            {isBeforeToday ? (
              <RolledOverIndicator end={end} start={start} isAllDay={allDay} />
            ) : (
              <ScheduledDate
                start={start}
                end={end}
                allDay={allDay}
                onChangeDates={onChangeDates}
                type={parentNode!.attrs.scheduleType}
                forceShowDate={forceShowDate}
              />
            )}
          </>
        );
      }

      case SuggestionType.DEADLINE: {
        const [deadline] = value.split(/%%%/);
        return <DueDate date={moment(deadline).toDate()} />;
      }

      case SuggestionType.CONTACT: {
        const getSelectedEmails = () => {
          return (
            (getParentNode()
              ?.content.toJSON()
              .map((node: JSONContent) => {
                if (node.type !== 'SUGGESTION_@') return null;
                const { type, value } = extractSuggestionTypeAndValue(node.attrs!.id);
                if (type !== SuggestionType.CONTACT) return null;
                return value;
              }) as (string | null)[]) || []
          );
        };

        const onChange = (contacts: NameEmail[]) => {
          const parentNode = getParentNode();
          if (!parentNode) return;
          editor.commands.updateContacts({ contacts, id: parentNode.attrs.id });
        };

        return (
          <Contacts
            email={value}
            getSelectedEmails={getSelectedEmails}
            onChange={onChange}
            alwaysExpanded={parentNode?.attrs.id.includes('NEW_ITEM')}
          />
        );
      }

      case SuggestionType.PROJECT: {
        if (value.includes('create_new_project_')) {
          const title = value.replace('create_new_project_', '');
          return <Project projectId="" createNew={true} nameNew={title} />;
        }
        return <Project projectId={value} />;
      }

      case SuggestionType.PRIORITY: {
        const onChange = (value: string) => {
          if (typeof getPos !== 'function') return;
          editor
            .chain()
            .setNodeSelection(getPos())
            .updateAttributes(node.type, { id: buildSuggestionId(type, value), label: value })
            .run();

          editor.commands.save();
        };
        return <Priority priority={value as PriorityType} onChange={onChange} />;
      }

      case SuggestionType.NOTE:
      case SuggestionType.EVENT:
      case SuggestionType.TASK: {
        return <Reference type={type} label={node.attrs.label} />;
      }

      case SuggestionType.LOCATIONS:
      case SuggestionType.AUTO_SCHEDULE:
      case SuggestionType.TIME_BLOCKING:
      case SuggestionType.VISIBILITY:
      case SuggestionType.CONFERENCE:
      case SuggestionType.RECURRENCE:
      case SuggestionType.SCHEDULE:
      case SuggestionType.DURATION: {
        const parentNode = getParentNode();
        if (!parentNode?.attrs.id.startsWith('NEW_ITEM')) return null;
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        return <span className={extension.options.HTMLAttributes.class}>{node.attrs!.label}</span>;
      }
    }
  }, [editor, extension.options.HTMLAttributes.class, getParentNode, getPos, node.attrs, node.type, updateAttributes]);

  return (
    <NodeViewWrapper as="span" contentEditable={false} data-id={node.attrs.id} data-label={node.attrs.label}>
      {content}
    </NodeViewWrapper>
  );
}
