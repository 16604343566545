import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { DropResult } from 'react-beautiful-dnd';
import { isItemCompleted } from '@/models/shared';
import { Task } from '@/models/TaskModel';
import { Event } from '@/models/EventModel';
import { useSettings } from '@/hooks/useSettings';
import { useTasks } from '@/hooks/useTasks';
import { SubHeaderText } from '@/components/Typography';
import { CollapsibleSection } from '@/components/CollapsibleSection';
import { SmartyItemType } from '@/hooks/useSmartyItems';
import { useWallClock } from 'ui/WallClock';
import { useShortcuts } from '@/context/Common';
import { useSetAtom } from 'jotai';
import { searchStateAtom } from '@/components/Overlay/utils';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { useEvents } from '@/hooks/useEvents';
import { cn } from 'ui/cn';
import { DraggableType, DroppableType, parseDraggableId, parseDroppableId } from '@/utils/dnd';
import useDropActions from '@/hooks/useDndActions';
import { useRightSidebar } from '@/components/RightSidebar';
import useLeftSidebar, { LeftSidebarType } from '@/components/LeftSidebar/useLeftSidebar';

import { convertEventToEventArg } from '@/models/EventArg';
import Editor from '@/components/Editor';
import { ItemType } from '@/components/Editor/types';
import { useSegment } from '@/components/useSegment';
import { useItemsContacts } from './useItemsContacts';

export type MomentsViewProps = {
  email: string;
};

export const MomentsListView = ({ email }: MomentsViewProps) => {
  const { trackEvent } = useSegment();
  const setSearchState = useSetAtom(searchStateAtom);
  const { registerShortcut, unregisterShortcut } = useShortcuts();
  const now = useWallClock();
  const { defaultTimeZone: timeZone } = useSettings();
  const { defaultDuration } = useSettings();
  const { tasks, reorderTask } = useTasks({});
  const { isFetchingEvents, events, updateEvent } = useEvents({});
  const { openLeftSidebar } = useLeftSidebar();
  const { items } = useItemsContacts({ contactEmail: email });
  const { globalDateRange, setGlobalDateRange } = useGlobalDateRange();
  const [showLoading, setShowLoading] = useState(true);
  const [isEndOfPage, setLastSection] = useIntersectionObserver();
  const { closeRightSidebar, rightSidebarState } = useRightSidebar();

  if (rightSidebarState.show && rightSidebarState.type === 'CALENDAR_DOCK') closeRightSidebar();

  useEffect(() => {
    if (!isEndOfPage || isFetchingEvents) return;
    // reached the range limit

    if (moment(globalDateRange.end).diff(moment(globalDateRange.start).startOf('year'), 'years') >= 2) {
      setShowLoading(false);
      return;
    }

    setGlobalDateRange({ end: moment(globalDateRange.end).add(1, 'month').toDate() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndOfPage, isFetchingEvents]);

  const collections = useMemo(() => {
    if (!items) return null;

    const c = new Map<string, SmartyItemType[]>();

    // filter by email
    const processItem = (item: SmartyItemType): SmartyItemType => {
      if (item.itemType === 'EVENT') {
        return {
          ...item,
          summary: item.summary === '' ? '(no title)' : item.summary,
        };
      }

      if (item.itemType === 'SCHEDULING_LINK' || item.itemType === 'NOTE') {
        return {
          ...item,
          title: item.title === '' ? '(no title)' : item.title,
        };
      }

      if (item.itemType === 'TASK' && item.subtasks && item.subtasks.length > 0) {
        return {
          ...item,
          summary: item.summary === '' ? '(no title)' : item.summary,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          subtasks: item.subtasks?.map((subtask: Task) => processItem(subtask)),
        };
      }

      return item;
    };

    items.forEach((day) => {
      const processedItems = day.items?.map(processItem) ?? [];
      c.set(day.date, processedItems);
    });

    const todayS = moment.tz(now, timeZone).format('YYYY-MM-DD');
    const [todayCompletedItems, rolledOverItems, todayItems] = c.get(todayS)?.reduce(
      (acc, item) => {
        if (item.itemType === 'SCHEDULING_LINK' && item.when === 'RECURRING_SLOTS') {
          return acc;
        }

        if (isItemCompleted(item)) {
          acc[0].push(item);
          return acc;
        }
        const isRolledOverTask =
          item.itemType === 'TASK'
            ? item.allDay
              ? item.end && moment(item.end, 'day').isBefore(moment(now).add(1, 'day'), 'day')
              : item.end && moment(item.end, 'day').isBefore(now, 'day')
            : false;

        if (item.itemType === 'TASK' && isRolledOverTask) {
          acc[1].push(item);
          return acc;
        }

        acc[2].push(item);
        return acc;
      },
      [[] as SmartyItemType[], [] as SmartyItemType[], [] as SmartyItemType[]]
    ) || [[], [], []];

    rolledOverItems.sort((a, b) => {
      const startA = moment(a.start);
      const startB = moment(b.start);
      return startA.diff(startB);
    });

    const unscheduled = items.find((item) => item.date === 'unscheduled')?.items || [];

    return {
      rolledOver: rolledOverItems,
      today: todayItems,
      completedToday: todayCompletedItems,
      thisWeek: new Map(
        Array.from(c.keys())
          .filter(
            (day) =>
              day !== 'unscheduled' &&
              moment.tz(day, timeZone).isSame(now, 'weeks') &&
              moment.tz(day, timeZone).isAfter(now, 'day')
          )
          .map((key) => [key, c.get(key) ?? []])
      ),
      upcoming: new Map(
        Array.from(c.keys())
          .filter((day) => day !== 'unscheduled' && moment.tz(day, timeZone).isAfter(now, 'weeks'))
          .map((key) => [key, c.get(key) ?? []])
      ),
      past: new Map(
        Array.from(c.keys())
          .filter((day) => day !== 'unscheduled' && moment.tz(day, timeZone).isBefore(now, 'weeks'))
          .map((key) => [key, c.get(key) ?? []])
      ),
      unscheduledItems: unscheduled,
    };
  }, [items, now, timeZone]);

  const findCollection = useCallback(
    (date: string) => {
      if (date === 'today') return collections?.today;
      if (date === 'completedToday') return collections?.completedToday;
      if (collections?.thisWeek.has(date)) return collections?.thisWeek.get(date);
      if (collections?.upcoming.has(date)) return collections?.upcoming.get(date);
      if (collections?.past.has(date)) return collections?.past.get(date);
      return null;
    },
    [collections]
  );

  /*
    task reorder test cases
    create 3 tasks
    1. move task up in the same day
    2. move task down in the same day
    3. move task to another day
    4. move task before an event
    5. move task after an event
    6. move task before an all day event
    7. move task after an all day event
    8. move task to the beginning of the day
    9. move task to the end of the day
 */

  const { addAction, removeAction } = useDropActions();

  useEffect(() => {
    async function handleDragEnd(result: DropResult) {
      const { destination, source, draggableId: _draggableId } = result;

      if (!collections || !destination) return;

      const { draggableType, id: draggableId } = parseDraggableId(_draggableId);
      const { id: sourceDate } = parseDroppableId(source.droppableId);
      const { droppableType, id: destinationDate } = parseDroppableId(destination.droppableId);

      trackEvent('Entity DragDropped', {
        view: 'list',
        selected_entity_type: draggableType.toLowerCase(),
        droppable_type: droppableType.toLowerCase(),
        selected_entity_id: draggableId,
        is_re_ordered: Boolean(destinationDate === sourceDate && destination.index !== source.index),
        is_moved: Boolean(destinationDate !== sourceDate),
      });

      if (droppableType !== DroppableType.LIST) return;
      if (destinationDate === sourceDate && destination.index === source.index) {
        return;
      }

      const destinationCollection = findCollection(destinationDate);

      if (!destinationCollection) {
        return;
      }

      // get the dragged task
      let draggedItem;
      switch (draggableType) {
        case DraggableType.TASK:
          draggedItem = tasks?.results.find((item) => item.id === draggableId);
          break;
        case DraggableType.EVENT:
          draggedItem = events?.find((item) => item.id === draggableId);
          break;
      }

      if (!draggedItem) {
        return;
      }

      let prevItemIndex = destination.index;
      if (source.index > destination.index || sourceDate !== destinationDate) {
        prevItemIndex--;
      }

      const previousItem = destinationCollection
        .slice(0, prevItemIndex + 1)
        .findLast((item) => ['TASK', 'EVENT'].includes(item.itemType)) as Task | Event | undefined;
      const nextItem = destinationCollection.slice(prevItemIndex + 1).find((item) => {
        return item.id !== draggableId && ['TASK', 'EVENT'].includes(item.itemType);
      }) as Task | Event | undefined;
      const previousTask = destinationCollection
        .slice(0, prevItemIndex + 1)
        .findLast((item) => item.itemType === 'TASK') as Task | undefined;

      let duration = defaultDuration;
      if (!draggedItem.allDay && draggedItem.start && draggedItem.end) {
        duration = moment(draggedItem.end).diff(draggedItem.start, 'minutes');
      }
      let start: Date | undefined;
      let end: Date | undefined;
      let allDay = false;
      // if the beforeItem has start and end, put before start

      if (nextItem?.allDay && nextItem.start && nextItem.end) {
        start = nextItem.start;
        end = nextItem.end;
        allDay = true;
      } else if (!previousItem?.allDay && previousItem?.end) {
        if (!nextItem || !nextItem.start || nextItem.start >= previousItem.end) {
          start = previousItem.end;
          end = moment.tz(start, timeZone).add(duration, 'minutes').toDate();
        } else {
          start = nextItem.start;
          end = moment.tz(start, timeZone).add(duration, 'minutes').toDate();
        }
      } else if (nextItem?.start) {
        end = nextItem.start;
        start = moment.tz(end, timeZone).subtract(duration, 'minutes').toDate();
      } else {
        if (destinationDate === 'today' || destinationDate === 'completedToday') {
          start = moment.tz(now, timeZone).toDate();
        } else {
          start = moment.tz(destinationDate, timeZone).toDate();
        }
        end = moment.tz(start, timeZone).add(duration, 'minutes').toDate();
      }

      try {
        switch (draggedItem.itemType) {
          case DraggableType.TASK:
            await reorderTask(draggedItem.id, start, end, previousTask?.id);
            break;
          case DraggableType.EVENT:
            updateEvent?.({
              eventId: draggedItem.id,
              event: convertEventToEventArg({ ...draggedItem, start, end, allDay }),
            });
            break;
        }
      } catch (error) {
        console.error(`Error while reordering ${draggableType}`, error);
      }
    }

    addAction(handleDragEnd);

    return () => {
      removeAction(handleDragEnd);
    };
  }, [
    addAction,
    collections,
    defaultDuration,
    findCollection,
    now,
    removeAction,
    reorderTask,
    tasks?.results,
    timeZone,
    updateEvent,
    events,
    trackEvent,
  ]);

  const openSearchBar = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      setSearchState({
        show: true,
        type: 'SEARCH',
      });
    },
    [setSearchState]
  );

  const closeSearchBar = useCallback(() => {
    setSearchState({
      show: false,
      type: 'SEARCH',
    });
  }, [setSearchState]);

  const navigateItems = useCallback(
    (direction: 'up' | 'down') => (e: KeyboardEvent) => {
      const nodes = Array.from(document.querySelectorAll('[data-targetable-item]'));

      if (!nodes.length) return;
      e.preventDefault();
      const currentIndex = nodes.findIndex((node) => node === document.activeElement);
      if (currentIndex === -1) {
        (nodes[0] as HTMLDivElement).focus();
        return;
      }
      const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
      if (nextIndex < 0 || nextIndex >= nodes.length) return;
      (nodes[nextIndex] as HTMLDivElement).focus();
    },
    []
  );

  const openItemDetails = useCallback(() => {
    const selectedItem = document.activeElement as HTMLDivElement;
    const itemId = selectedItem.getAttribute('data-item-id');
    const itemType = selectedItem.getAttribute('data-item-type');
    if (!itemId || !itemType) return;

    openLeftSidebar({
      type: `${itemType}_EDITOR` as LeftSidebarType,
      context: { id: itemId, autoFocus: true },
    });
  }, [openLeftSidebar]);

  const deleteItem = useCallback(() => {
    const selectedItem = document.activeElement as HTMLDivElement;
    const deleteButton = selectedItem.querySelector('[data-delete-button]') as HTMLButtonElement;
    deleteButton?.click();
  }, []);

  const toggleTask = useCallback(() => {
    const selectedItem = document.activeElement as HTMLDivElement;
    if (selectedItem.getAttribute('data-item-type') !== 'TASK') return;

    const checkbox = selectedItem.querySelector('[type=checkbox]') as HTMLButtonElement;
    checkbox.click();
  }, []);

  useEffect(() => {
    registerShortcut(['command+k', 'ctrl+k'], openSearchBar);
    registerShortcut('esc', closeSearchBar);
    registerShortcut('up', navigateItems('up'));
    registerShortcut('down', navigateItems('down'));
    registerShortcut('enter', openItemDetails);
    registerShortcut('backspace', deleteItem);
    registerShortcut('D', toggleTask);

    return () => {
      unregisterShortcut(['command+k', 'ctrl+k']);
      unregisterShortcut('esc');
      unregisterShortcut('up');
      unregisterShortcut('down');
      unregisterShortcut('enter');
      unregisterShortcut('backspace');
      unregisterShortcut('D');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn('w-full md:w-[800px] mx-auto max-w-full')}>
      {!items ? (
        <div className="my-5 mx-9">
          <div className="flex mb-3 space-x-4 animate-pulse">
            <div className="w-4 h-4 bg-slate-200"></div>
            <div className="flex-1 py-1 space-y-6">
              <div className="h-2 rounded bg-slate-200"></div>
            </div>
          </div>
          <div className="flex mb-3 space-x-4 animate-pulse">
            <div className="w-4 h-4 bg-slate-200"></div>
            <div className="flex-1 py-1 space-y-6">
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 col-span-2 rounded bg-slate-200"></div>
                  <div className="h-2 col-span-1 rounded bg-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-4 animate-pulse">
            <div className="w-4 h-4 bg-slate-200"></div>
            <div className="flex-1 py-1 space-y-6">
              <div className="h-2 rounded bg-slate-200"></div>
            </div>
          </div>
        </div>
      ) : null}
      <>
        {collections?.past?.size ? (
          <CollapsibleSection
            header={<SubHeaderText className="my-[8px]">Past</SubHeaderText>}
            content={
              <>
                {Array.from(collections.past).map(([date, items]) => (
                  <Editor date={date} items={items} />
                ))}
              </>
            }
            defaultCollapsed
          />
        ) : null}
        {collections?.unscheduledItems?.length ? (
          <CollapsibleSection
            header={<SubHeaderText className="my-[8px]">Unscheduled</SubHeaderText>}
            content={
              <>
                <Editor
                  date="unscheduled"
                  items={collections?.unscheduledItems}
                  allowedTypes={[ItemType.NOTE, ItemType.TASK]}
                />
              </>
            }
            defaultCollapsed
          />
        ) : null}
        {/* Past */}
      </>
      {/* Today */}
      {collections?.completedToday.length || collections?.today.length || collections?.rolledOver.length ? (
        <>
          <CollapsibleSection
            header={<SubHeaderText className="my-[8px]">Today</SubHeaderText>}
            content={
              <>
                <Editor date="today" items={collections?.rolledOver} />
                <Editor date="today" items={collections?.today} />
                {collections?.completedToday.length ? (
                  <CollapsibleSection
                    header={<SubHeaderText className="my-[8px]">Completed</SubHeaderText>}
                    content={<Editor items={collections?.completedToday} disableNewLine />}
                    defaultCollapsed
                  />
                ) : null}
              </>
            }
            defaultCollapsed
          />
        </>
      ) : null}

      {/* This week */}
      {collections?.thisWeek?.size ? (
        <>
          <CollapsibleSection
            header={<SubHeaderText className="my-[8px]">This week</SubHeaderText>}
            content={
              <>
                {Array.from(collections.thisWeek).map(([date, items]) => (
                  <Editor date={date} items={items} />
                ))}
              </>
            }
            defaultCollapsed
          />
        </>
      ) : null}
      {/* Upcoming */}
      {collections?.upcoming?.size ? (
        <>
          <CollapsibleSection
            header={<SubHeaderText className="my-[8px]">Upcoming</SubHeaderText>}
            content={
              <>
                {Array.from(collections.upcoming).map(([date, items]) => (
                  <Editor date={date} items={items} />
                ))}
              </>
            }
            defaultCollapsed
          />
          {showLoading ? (
            <div
              ref={setLastSection}
              className="p-4 text-center animate-pulse flex-1 min-h-0 flex items-center gap-[8px]">
              <div className="rounded-full bg-slate-300 h-[15px] w-[15px]" />
              <div className="w-full h-2 rounded bg-slate-300" />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
