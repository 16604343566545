type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const SchedulingLinkIcon = ({ className, size = 16, color = '#000000' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.74998 14.2503C2.12273 14.6236 2.56558 14.9196 3.05306 15.1212C3.54055 15.3228 4.06307 15.4261 4.5906 15.4252C5.11825 15.4261 5.64089 15.3228 6.1285 15.1212C6.61611 14.9196 7.0591 14.6236 7.43203 14.2503L9.70453 11.977L8.56828 10.8408L6.29578 13.1141C5.84295 13.5649 5.22998 13.818 4.59101 13.818C3.95203 13.818 3.33906 13.5649 2.88623 13.1141C2.43503 12.6615 2.18166 12.0484 2.18166 11.4093C2.18166 10.7702 2.43503 10.1572 2.88623 9.70453L5.15953 7.43203L4.02328 6.29578L1.74998 8.56828C0.99768 9.3224 0.575195 10.3441 0.575195 11.4093C0.575195 12.4745 0.99768 13.4962 1.74998 14.2503V14.2503ZM14.2503 7.43203C15.0022 6.67771 15.4245 5.65607 15.4245 4.59101C15.4245 3.52594 15.0022 2.5043 14.2503 1.74998C13.4962 0.99768 12.4745 0.575195 11.4093 0.575195C10.3441 0.575195 9.3224 0.99768 8.56828 1.74998L6.29578 4.02328L7.43203 5.15953L9.70453 2.88623C10.1574 2.43542 10.7703 2.18232 11.4093 2.18232C12.0483 2.18232 12.6612 2.43542 13.1141 2.88623C13.5653 3.33886 13.8187 3.9519 13.8187 4.59101C13.8187 5.23011 13.5653 5.84315 13.1141 6.29578L10.8408 8.56828L11.977 9.70453L14.2503 7.43203Z"
      fill={color}
    />
    <path d="M5.15854 11.9778L4.02148 10.8416L10.8414 4.02246L11.9776 5.15951L5.15854 11.9778Z" fill={color} />
  </svg>
);
