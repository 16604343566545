import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { SettingsIcon } from '@/icons/SettingsIcon';
import { PageTitle, ParagraphText } from '@/components/Typography';

const TABS = [
  { label: 'Profile', path: '/settings/profile' },
  { label: 'Custom Availability Schedules', path: '/settings/availability' },
  { label: 'Time Zones', path: '/settings/timezones' },
  { label: 'Conferencing', path: '/settings/conferencing' },
  { label: 'Accounts & Integrations', path: '/settings/integrations' },
  { label: 'Billing & Subscription', path: '/settings/subscriptions' },
];

export const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const { asPath: currentPath } = useRouter();

  return (
    <div className="w-full max-w-[850px] mx-auto flex flex-col gap-[24px] mt-5">
      <header className="flex flex-row items-center gap-[24px]">
        <SettingsIcon size={24} />
        <PageTitle>Settings</PageTitle>
      </header>

      <div className="flex w-full border-b-2 border-b-gray-100">
        {TABS.map(({ label, path: tabPath }) => (
          <Link
            key={tabPath}
            data-active={tabPath === currentPath}
            className="hover:text-primary-500 pb-3 hover:cursor-pointer select-none text-gray-400 data-[active=true]:text-black data-[active=true]:border-b-2 data-[active=true]:border-b-black whitespace-nowrap"
            href={tabPath}
            shallow
            draggable="false">
            <ParagraphText className="mx-2">{label}</ParagraphText>
          </Link>
        ))}
      </div>

      {children}
    </div>
  );
};
