import React from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const ChatIcon = ({ className, size = 45, color = '#000000' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 45.779 45.779"
    fill={color}
  >
    <g>
      <g>
        <path d="M37.426,2.633H8.362C3.746,2.633,0,6.369,0,10.985v17.003c0,4.615,3.747,8.344,8.362,8.344h18.48l3.902,5.604 c0.527,0.756,1.39,1.209,2.311,1.211c0.92,0.002,1.785-0.443,2.314-1.197l4.129-5.865c3.611-0.924,6.281-4.198,6.281-8.098V10.985 C45.779,6.369,42.042,2.633,37.426,2.633z M15.431,22.203c-1.505,0-2.726-1.215-2.726-2.717c0-1.499,1.221-2.716,2.726-2.716 c1.506,0,2.726,1.217,2.726,2.716C18.157,20.988,16.937,22.203,15.431,22.203z M22.894,22.203c-1.505,0-2.726-1.215-2.726-2.717 c0-1.499,1.221-2.716,2.726-2.716c1.506,0,2.725,1.217,2.725,2.716C25.619,20.988,24.4,22.203,22.894,22.203z M30.357,22.203 c-1.506,0-2.727-1.215-2.727-2.717c0-1.499,1.221-2.716,2.727-2.716s2.726,1.217,2.726,2.716 C33.083,20.988,31.863,22.203,30.357,22.203z"></path>
      </g>
    </g>
  </svg>
);

export default ChatIcon;
