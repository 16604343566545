import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { calcTop } from '../utils';

type Props = {
  hourHeight: number;
  timeZone: string;
};

export default function TimeIndicator({ hourHeight, timeZone }: Props) {
  const [top, setTop] = useState<number | null>(0);

  useEffect(() => {
    function calcNowTop() {
      const now = moment.tz(timeZone);

      if (now.hours() < 0 || now.hours() > 24) {
        return null;
      }

      return calcTop(hourHeight, timeZone, now.toDate());
    }

    setTop(calcNowTop());

    const intervalHandle = setInterval(() => {
      setTop(calcNowTop());
    }, 1000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [hourHeight, timeZone]);

  if (!top) return null;

  return (
    <div
      className="pointer-events-none w-full z-20 h-[3px] bg-black relative before:pointer-events-none before:content-[''] before:absolute before:top-1/2 before:left-0 before:w-0 before:h-0 before:border-y-2 before:rounded before:border-l-2  before:border-l-black before:-translate-y-1/2"
      style={{ top }}
    />
  );
}
