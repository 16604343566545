import React, { FormEventHandler } from 'react';
import { EmailThreads } from '@/models/EmailModel'; // Assuming the location of EmailThreads type
import { formatDate, getBrowserTimeZone } from '@/utils/date';
import { extractParticipantsArray } from 'utils/email';
import { useStarThread, useUnstarThread, useImportantThread, useNotImportantThread } from '@/hooks/useEmails';
import { MdLabelImportant, MdLabelImportantOutline, MdStar, MdStarBorder } from 'react-icons/md';
import ToolTip from '@/components/Tooltip';
import { cn } from 'ui/cn'; // Assuming location of the cn function for className bindings

export const ThreadItem = ({
  thread,
  onOpen,
  isSelected,
}: {
  thread: EmailThreads['results'][number];
  onOpen: FormEventHandler<HTMLButtonElement>;
  isSelected: boolean;
}) => {
  const dateHeader = thread?.headers?.find((h) => h.name === 'Date')?.value;
  const dateTime = formatDate({ timeZone: getBrowserTimeZone(), allDay: false, date: dateHeader });
  const { from } = extractParticipantsArray(thread.headers);
  const senderName = from.name ?? from.address ?? '';
  const subjectHeader = thread?.headers?.find((h) => h.name === 'Subject')?.value;
  const subject = subjectHeader?.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/, '') || '';

  const { starThread } = useStarThread();
  const { unstarThread } = useUnstarThread();
  const { importantThread } = useImportantThread();
  const { notImportanThread } = useNotImportantThread();

  const isStarred = thread.labelIds?.includes('STARRED') ?? false;
  const isImportant = thread.labelIds?.includes('IMPORTANT') ?? false;
  const isUnread = thread.labelIds?.includes('UNREAD') ?? false;

  const handleStarred: FormEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!thread.id) return;
    if (isStarred) {
      void unstarThread({ threadId: thread.id });
    } else {
      void starThread({ threadId: thread.id });
    }
  };

  const handleImportant: FormEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!thread.id) return;
    if (isImportant) {
      void notImportanThread({ threadId: thread.id });
    } else {
      void importantThread({ threadId: thread.id });
    }
  };

  return (
    <button
      type="button"
      onClick={onOpen}
      className={cn(
        'w-full p-2 text-left border-b outline-none cursor-pointer transition-all',
        isSelected ? 'bg-slate-300' : 'hover:bg-slate-200'
      )}
      title={`${subject}\n${thread.snippet}`}
      data-email-thread
      data-selected={isSelected}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[4px] flex-grow">
          <button type="button" onClick={handleImportant}>
            {isImportant ? (
              <MdLabelImportant className="text-yellow-500 hover:text-yellow-600 transition-all" size={18} />
            ) : (
              <MdLabelImportantOutline className="hover:text-yellow-600 transition-all" size={18} />
            )}
          </button>

          <div className="flex flex-col gap-[5px] flex-grow">
            <span className="text-[12px] text-black leading-[14px] select-none truncate">{senderName}</span>
            <div className="flex items-center gap-[8px] flex-grow">
              {isUnread ? <div className="w-[8px] h-[8px] bg-[#146FFF] rounded-full flex-shrink-0" /> : null}
              <span
                className={`flex-grow text-[14px] leading-[16px] ${isUnread ? 'font-bold' : 'font-medium'} text-black select-none overflow-hidden`}
                style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                {subject}
              </span>
            </div>
          </div>
        </div>

        <span className="text-xs text-gray-500 select-none whitespace-nowrap">{dateTime}</span>
      </div>


      <div className="flex items-center justify-between">
        <p
          className="mt-1 text-[12px] leading-[18px] text-gray-500 truncate select-none"
          dangerouslySetInnerHTML={{ __html: thread.snippet ?? '' }}
        />
        <ToolTip tooltip={isStarred ? 'Unstar' : 'Star'}>
          <button
            type="button"
            className="mt-1 text-yellow-500 hover:text-yellow-600 items-end justify-end transition-all"
            onClick={handleStarred}>
            {isStarred ? <MdStar size={24} /> : <MdStarBorder size={24} />}
          </button>
        </ToolTip>
      </div>
    </button>
  );
};
