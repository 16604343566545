import { useState } from 'react';
import * as Select from '@radix-ui/react-select';

import { cn } from 'ui/cn';
import { capitalize } from 'utils/string';
import HighPriorityIcon from '@/icons/HighPriorityIcon';
import LowPriorityIcon from '@/icons/LowPriorityIcon';
import MediumPriorityIcon from '@/icons/MediumPriorityIcon';
import { Priority } from '@prisma/client';

type Props = {
  priority: Priority | null | undefined;
  onChange: (priority: Priority) => void;
};

const PROPS_BY_PRIORITY = {
  [Priority.HIGH]: {
    className: 'bg-red-200 text-red-500',
    Icon: HighPriorityIcon,
    color: '#D65745',
  },
  [Priority.NORMAL]: {
    className: 'bg-select-light-gray text-gray-500',
    Icon: MediumPriorityIcon,
    color: '#A1A1A3',
  },
  [Priority.LOW]: {
    className: 'bg-blue-200 text-blue-500',
    Icon: LowPriorityIcon,
    color: '#3B60E4',
  },
  [Priority.NO_PRIORITY]: {
    Icon: null,
  },
};

export default function PriorityDecorator({ priority, onChange }: Props) {
  const [open, setOpen] = useState(false);

  if (!priority || priority === 'NO_PRIORITY') return null;

  const { className, Icon, color } = PROPS_BY_PRIORITY[priority];
  const label = capitalize(priority);

  return (
    <Select.Root onValueChange={onChange} onOpenChange={setOpen}>
      <Select.Trigger asChild>
        <div
          role="button"
          className={cn('rounded inline-flex align-top items-center px-1 transition-all whitespace-nowrap', className)}
          title={`${label} Priority`}
          tabIndex={1}>
          <Icon color={color} />
          <span
            className={cn(
              'ml-0 overflow-hidden transition-all group-hover:max-w-sm group-hover:ml-1 text-[14px]',
              !open ? 'max-w-0' : 'ml-1'
            )}>
            {label}
          </span>
        </div>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content position="popper" className="z-30 bg-white border rounded-lg w-80">
          <Select.Viewport className="p-0.5" contextMenu="px-1">
            {Object.values(Priority).map((priority) => {
              const { Icon } = PROPS_BY_PRIORITY[priority];

              return (
                <Select.Item
                  className="flex items-center gap-3 p-2 text-[14px] outline-none cursor-pointer select-none focus:bg-black/5  "
                  key={priority}
                  value={priority}>
                  {Icon ? <Icon /> : null}
                  <span>{capitalize(priority).replace('_', ' ')}</span>
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
