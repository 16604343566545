import { useSettings } from '@/hooks/useSettings';
import { formatDateRangeDecorator } from '@/utils/date';
import { TaskScheduleType } from '@prisma/client';
import { ClockIcon } from '@/icons/ClockIcon';
import { MdHdrAuto, MdSnooze } from 'react-icons/md';
import { useCallback } from 'react';
import DatePicker from './DatePicker';

type Props = {
  type: TaskScheduleType | null | undefined;
  start: Date | null | undefined;
  end: Date | null | undefined;
  allDay: boolean | undefined;
  date?: string;
  recurrence?: boolean | undefined;
  isCompleted?: boolean | undefined;
  onChangeDates: (start: Date, end: Date | null, allDay: boolean) => void;
  forceShowDate?: boolean;
};

export default function ScheduledDateDecorator({
  type,
  start,
  end,
  allDay = false,
  date,
  recurrence = false,
  isCompleted = false,
  onChangeDates,
  forceShowDate,
}: Props) {
  const { defaultTimeZone: timeZone } = useSettings();
  const renderDecorator = useCallback(
    (_: unknown, onClick: () => void) => {
      const isAutoScheduled = type === 'AUTO_SCHEDULED';
      const isSnoozed = type === 'SNOOZED';
      const scheduledDateString = formatDateRangeDecorator({
        timeZone,
        start,
        end,
        isCompleted,
        allDay,
        forceShowDate,
      });

      return (
        <div
          className="rounded-[4px] bg-select-light-gray inline-flex align-top items-center gap-[5px] px-[5px] text-gray-500 whitespace-nowrap cursor-pointer text-[14px]"
          onClick={onClick}
          title={
            recurrence && isAutoScheduled
              ? 'Recurring Task'
              : `${
                  end && end.getTime() > new Date().getTime()
                    ? isSnoozed
                      ? 'Snoozed until'
                      : isAutoScheduled
                        ? 'Auto-Scheduled for'
                        : 'Scheduled for'
                    : 'Task will be automatically re-scheduled at the end of the day'
                } ${end && end.getTime() > new Date().getTime() ? scheduledDateString : ''} `
          }>
          {isAutoScheduled ? <MdHdrAuto size={15} className="-mr-2.5" color="black" /> : null}
          <ClockIcon
            stroke={isAutoScheduled ? 'white' : undefined}
            strokeWidth={isAutoScheduled ? '0.25' : undefined}
          />
          {scheduledDateString === 'Invalid date'
            ? null
            : recurrence && isAutoScheduled
              ? 'Recurring Task'
              : scheduledDateString}
          {isSnoozed ? <MdSnooze /> : null}
        </div>
      );
    },
    [allDay, end, forceShowDate, isCompleted, recurrence, start, timeZone, type]
  );

  if (start && allDay && type === 'AUTO_SCHEDULED') {
    const isAutoScheduled = type === 'AUTO_SCHEDULED';
    const scheduledDateString = formatDateRangeDecorator({ timeZone, start, end, isCompleted, forceShowDate });
    return (
      <div
        className="rounded-[4px] bg-select-light-gray flex items-center gap-[5px] px-[5px] text-gray-500 whitespace-nowrap cursor-pointer text-[14px]"
        title={`Auto-Scheduled for ${end && end.getTime() > new Date().getTime() ? scheduledDateString : ''} `}>
        {type === 'AUTO_SCHEDULED' ? <MdHdrAuto size={15} className="-mr-2.5" color="black" /> : null}
        <ClockIcon stroke={isAutoScheduled ? 'white' : undefined} strokeWidth={isAutoScheduled ? '0.25' : undefined} />
      </div>
    );
  } else if (type === 'NONE' || date === 'unscheduled') {
    return null;
  }

  return (
    <DatePicker
      start={start!}
      end={end}
      allDay={allDay}
      onChangeDates={onChangeDates}
      disabled={isCompleted}
      renderButton={renderDecorator}
    />
  );
}
