import React, { useState } from 'react';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { IconType } from 'react-icons';

type ToggleableSectionProps = {
  title: string;
  summary?: React.ReactNode;
  children: React.ReactNode;
  icon: IconType;
  disabled?: boolean;  // Optional disabled prop
};

const ToggleableSection: React.FC<ToggleableSectionProps> = ({ title, summary, children, icon: Icon, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`border-b py-2 ${disabled ? 'opacity-50' : ''}`}>
      <div className={`flex justify-between items-center cursor-pointer ${disabled ? 'cursor-not-allowed' : ''}`} onClick={toggleOpen}>
        <div className="flex items-center">
          <Icon className="mr-2" />
          <h3 className="font-medium">{title}</h3>
        </div>
        <span>
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      </div>
      {isOpen ? (
        <div className="mt-2">
          {children}
        </div>
      ) : (
        <div className="mt-2 text-gray-500">
          {summary}
        </div>
      )}
    </div>
  );
};

export default ToggleableSection;
