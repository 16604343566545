export const hexToRGB = (hex: string) => {
  let r = '0';
  let g = '0';
  let b = '0';

  // 3 digits
  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  return { r, g, b };
};

//TODO: verify because it does not accept the type number
export const RGBToHSL = (r: string | number, g: string | number, b: string | number) => {
  r = Number(r) / 255;
  g = Number(g) / 255;
  b = Number(b) / 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h;
  let s;
  let l;

  if (delta == 0) h = 0;
  // Red is max
  else if (cmax == r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax == g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  return { h, s, l };
};

export const hexToHSL = (color: string) => {
  const { r, g, b } = hexToRGB(color);
  const { h, s, l } = RGBToHSL(r, g, b);
  return { h, s, l };
};

export const getTextColorHex = (hex: string) => {
  const [r, g, b] = [parseInt(hex.slice(1, 3), 16), parseInt(hex.slice(3, 5), 16), parseInt(hex.slice(5, 7), 16)];

  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return 'black';
  } else {
    return 'white';
  }
};

export const colorLuminance = (hex: string, lum = 0) => {
  // validate hex string
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    // eslint-disable-next-line no-param-reassign
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const blendWithWhite = (hex: string, percent: number): string => {
  percent = Math.min(100, Math.max(0, percent)) / 100;

  let rNum = parseInt(hex.substring(1, 3), 16);
  let gNum = parseInt(hex.substring(3, 5), 16);
  let bNum = parseInt(hex.substring(5, 7), 16);

  rNum += (255 - rNum) * percent;
  gNum += (255 - gNum) * percent;
  bNum += (255 - bNum) * percent;

  const rHex = Math.round(rNum).toString(16).padStart(2, '0');
  const gHex = Math.round(gNum).toString(16).padStart(2, '0');
  const bHex = Math.round(bNum).toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
};

export function stringToColor(str: string) {
  let hash = 0;
  for (const char of str) {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }

  return color;
}
