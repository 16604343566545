import * as Dialog from '@radix-ui/react-dialog';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Day from '@/components/AvailabilityList/Day';
import { useAvailability } from '@/hooks/useAvailability';
import { useCallback, useEffect, useState } from 'react';
import { useSettings } from '@/hooks/useSettings';
import { toast } from 'react-hot-toast';
import { buildDefaultWorkingHourIntervals } from '@/utils/date';
import { Availability } from '@/models/AvailabilityModel';
import { ToastGeneric } from '../../../ToastGeneric';

type Props = {
  isOpen: boolean;
  close: () => void;
  onCreate: (schedule: Availability) => void;
};

function generateNewSchedule() {
  return {
    id: '',
    title: '',
    isEnabled: true,
    isDefault: false,
    intervals: buildDefaultWorkingHourIntervals(),
  };
}

export default function ScheduleDialog({ isOpen, close, onCreate }: Props) {
  const { availabilityIncludingDisabled, saveAvailability } = useAvailability();
  const [schedule, setSchedule] = useState<Availability>(generateNewSchedule());
  const [scheduleTitle, setScheduleTitle] = useState('');
  const [error, setError] = useState(false);

  const [isTitleError, setIsTitleError] = useState(false);

  useEffect(() => {
    setIsTitleError(scheduleTitle.trim() === '');
  }, [scheduleTitle]);

  const { defaultDuration } = useSettings();

  useEffect(() => {
    setSchedule(generateNewSchedule());
    setScheduleTitle('');
  }, [isOpen]);

  const handleOnSave = useCallback(async () => {
    if (!availabilityIncludingDisabled) return;
    if (!scheduleTitle) {
      setError(true);
      return;
    }

    saveAvailability([...availabilityIncludingDisabled, { ...schedule, title: scheduleTitle }], {
      onError: () => {
        toast(<ToastGeneric title="Something went wrong" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
      },
      onSuccess: (newItems) => {
        const newItem = newItems.find(
          ({ id }) => !availabilityIncludingDisabled.some((availability) => id === availability.id)
        );
        if (!newItem) return;
        onCreate(newItem);
      },
    });
  }, [availabilityIncludingDisabled, onCreate, saveAvailability, schedule, scheduleTitle]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/90" />
        <Dialog.Content className="fixed inset-0 flex items-center justify-center">
          <div className="min-w-[636px] p-12 bg-white rounded-lg shadow-xl">
            <Dialog.Title className="pb-6 m-0 text-xl">Custom Schedule</Dialog.Title>
            <Input
              name="custom schedule name"
              labelContent="Custom schedule name"
              placeholder="Name your custom schedule"
              className="w-full outline-none"
              value={scheduleTitle}
              onChange={setScheduleTitle}
              hasError={isTitleError}
            />
            <div className="mb-3 mt-[2px]">
              {error && !scheduleTitle && <p className="text-xs text-red-500">Please enter a title</p>}
            </div>
            <Day schedule={schedule} onChange={setSchedule} defaultDuration={defaultDuration} />
            <div className="flex flex-col items-center gap-3 mt-3">
              <Button variant="secondary" className="w-3/4" onClick={close}>
                Delete
              </Button>
              <Button
                variant="primary"
                className="w-3/4"
                disabled={!availabilityIncludingDisabled || !schedule}
                onClick={handleOnSave}>
                Save custom schedule
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
