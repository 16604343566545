import ProjectIcon from '@/icons/ProjectIcon';
import EntityHeader from '../Headers/EntityHeader';
import { useDeleteProject, useProjects } from '@/hooks/useProjects';
import { ChangeEvent, FocusEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import EllipsisIcon from '@/icons/EllipsisIcon';
import Dropdown from '@/components/DropdownMenu';
import { FiEdit3 } from 'react-icons/fi';
import { BiDuplicate } from 'react-icons/bi';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { Project } from '@/models/ProjectModel';
import useLeftSidebar from '../useLeftSidebar';
import ProjectColorPicker from '@/components/ProjectColorPicker';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { useTasks } from '@/hooks/useTasks';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { useEvents } from '@/hooks/useEvents';

type Props = {
  onClose: () => void;
};

export default function Projects({ onClose }: Props) {
  const { openLeftSidebar } = useLeftSidebar();
  const router = useRouter();
  const { setGlobalDateRange } = useGlobalDateRange();
  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { schedulingLinks } = useSchedulingLinks({});
  const { projects, updateProject, createProject } = useProjects({});
  const { deleteProject } = useDeleteProject();

  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState('');

  const handleOnEdit = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditingValue(e.target.value);
  }, []);

  const handleOnUpdate = useCallback(
    (e: FormEvent | FocusEvent) => {
      e.preventDefault();
      if (!editingId || !editingValue) return;
      const project = projects.find((p) => p.id === editingId);
      if (!project) return;

      updateProject({
        projectId: editingId,
        project: {
          name: editingValue,
          eventIds: project.eventIds,
          parentProjectId: project.parentProjectId ?? '',
          schedulingLinksIds: project.schedulingLinksIds,
          tasksIds: project.tasksIds,
        },
      });

      setEditingId(null);
      setEditingValue('');
    },
    [editingId, editingValue, projects, updateProject]
  );

  const handleOnClickProject = useCallback(
    (project: Project) => async () => {
      try {
        // extract all the dates from the tasks and events
        const tasksDates = tasks?.results
          .filter((task) => task.projectIds?.includes(project.id))
          .map((task) => task.start);
        const eventsDates = events
          ?.filter((event) => event.projectIds?.includes(project.id))
          .map((event) => event.start);
        const linksDates = schedulingLinks?.results
          .filter((link) => link.projectIds?.includes(project.id))
          .map((link) => link.start);

        // merge and sort the dates
        const allDates = [tasksDates, eventsDates, linksDates]
          .flatMap((d) => d ?? undefined)
          .filter((d) => !!d)
          .map(d=> moment(d));
        if (allDates.length) {
          setGlobalDateRange({ start: moment.min(allDates).toDate() });
        }
        await router.push(`/projects/${project.id}`);
      } catch (error) {
        console.error('Failed to navigate:', error);
      }
    },
    [events, router, schedulingLinks?.results, setGlobalDateRange, tasks?.results]
  );

  const handleOnAddNew = useCallback(() => {
    openLeftSidebar({
      type: 'NEW_PROJECT',
    });
  }, [openLeftSidebar]);

  const renderedProjects = useMemo(() => {
    if (!projects.length) {
      return <p className="text-sm font-medium text-secondary-300">You have no existing projects</p>;
    }

    return projects
      .filter((v) => !v.parentProjectId)
      .map((project) => {
        const isEditing = editingId === project.id;

        const actions = [
          {
            content: (
              <>
                <FiEdit3 className="text-secondary-300" />
                Edit
              </>
            ),
            onClick: () => {
              setEditingId(project.id);
              setEditingValue(project.name);
            },
          },
          {
            content: (
              <>
                <BiDuplicate className="text-secondary-300" />
                Duplicate
              </>
            ),
            onClick: () => {
              createProject({
                name: project.name,
                eventIds: [],
                tasksIds: [],
                parentProjectId: '',
                schedulingLinksIds: [],
              });
            },
          },
          {
            content: (
              <>
                <DeleteIcon className="text-secondary-300" />
                Delete
              </>
            ),
            onClick: () => {
              deleteProject({ projectId: project.id });
            },
          },
        ];

        return (
          <div
            key={project.id}
            className="flex items-center flex-1 px-2 text-sm font-medium leading-6 rounded-sm hover:bg-primary-100 group">
            <button type="button" className="flex items-center gap-2.5 flex-1" onClick={handleOnClickProject(project)}>
              <ProjectIcon className="-mt-0.5 shrink-0" />
              {isEditing ? (
                <form onSubmit={handleOnUpdate}>
                  <input
                    value={editingValue}
                    onChange={handleOnEdit}
                    ref={(ref) => {
                      setTimeout(() => ref?.focus(), 100);
                    }}
                    onBlur={handleOnUpdate}
                  />
                </form>
              ) : (
                <>
                  <span className="overflow-hidden">{project.name}</span>
                  <ProjectColorPicker projectId={project.id} />
                </>
              )}
            </button>
            <Dropdown
              button={
                <button className="ml-auto opacity-0 text-secondary-300 group-hover:opacity-100">
                  <EllipsisIcon />
                </button>
              }
              items={actions}
            />
          </div>
        );
      });
  }, [
    createProject,
    deleteProject,
    editingId,
    editingValue,
    handleOnClickProject,
    handleOnEdit,
    handleOnUpdate,
    projects,
  ]);

  return (
    <section>
      <EntityHeader title="Projects" Icon={ProjectIcon} onAddNew={handleOnAddNew} onClose={onClose} />
      <article className="flex flex-col flex-1 min-h-0 gap-3 px-4 py-6">{renderedProjects}</article>
    </section>
  );
}
