import { useCallback, useMemo } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { SubTaskIcon } from '@/icons/SubTaskIcon';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { useDeleteTask } from '@/hooks/useTasks';

type Props = {
  subtasks?: { id?: string; summary: string; isCompleted: boolean, projectIds?: string[] }[];
  setSubtasks: (subtasks: { id?: string; summary: string; isCompleted: boolean }[]) => void;
};

export default function SubtaskSection({ subtasks, setSubtasks }: Props) {
  const { deleteTask } = useDeleteTask();
  const handleOnClickAddSubtask = useCallback(() => {
    setSubtasks([...(subtasks ?? []), { summary: '', isCompleted: false }]);
  }, [setSubtasks, subtasks]);

  const renderedSubtasks = useMemo(
    () =>
      subtasks?.map((subtask, index) => (
        <div key={index} className="flex items-center gap-[10px] h-[21px]">
          <SubTaskIcon />
          <input
            name="isCompleted"
            type="checkbox"
            title="isCompleted"
            checked={subtask.isCompleted}
            onChange={(e) => {
              const newSubtasks = [...subtasks];
              const isCompleted = e.target.checked;
              newSubtasks[index].isCompleted = isCompleted;
              setSubtasks(newSubtasks);
            }}
          />
          <input
            className="w-full text-[21px] font-lexend"
            placeholder="Summary"
            type="text"
            value={subtask.summary}
            autoFocus={!subtask.id}
            onChange={(e) => {
              const newSubtasks = [...subtasks];
              newSubtasks[index].summary = e.target.value;
              setSubtasks(newSubtasks);
            }}
          />
          <button
            type="button"
            className="flex items-center text-[14px] gap-[12px] underline"
            onClick={(e) => {
              e.preventDefault();
              const newSubtasks = [...subtasks];
              newSubtasks.splice(index, 1);
              deleteTask({ taskId: subtask.id ?? '' });
              setSubtasks(newSubtasks);
            }}>
            <DeleteIcon className="text-black hover:fill-red-500 transition-all" />
          </button>
        </div>
      )),
    [subtasks, setSubtasks, deleteTask]
  );

  return (
    <div className="flex flex-col mt-[15px] gap-[12px]">
      {renderedSubtasks}
      <button
        type="button"
        className="flex items-center text-[14px] gap-[12px] underline"
        onClick={handleOnClickAddSubtask}>
        <PlusIcon fontSize={8} />
        Add sub-task
      </button>
    </div>
  );
}
