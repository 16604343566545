import '../styles/globals.scss';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { WallClockProvider } from 'ui/WallClock';
import { Analytics } from '@vercel/analytics/react';
import { ShortcutProvider } from '@/context/Common';
import { SegmentProvider } from '@/components/useSegment';
import { RootLayout } from '@/components/RootLayout';
import { SettingsPageHeader } from '@/screens/home/SettingsPageHeader';
import { SettingsLayout } from '@/components/SettingsLayout';
import { BigSpinner } from '@/components/Common/Loaders/BigSpinner';

export type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const [queryClient] = useState(new QueryClient());
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [isGoingToSettings, setIsGoingToSettings] = useState(false);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const start = (url: string) => {
      if (url.includes('/settings')) {
        setIsGoingToSettings(true);
      }
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
      setIsGoingToSettings(false);
    };
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events]);

  return (
    <>
      <SegmentProvider>
        <ShortcutProvider>
          <QueryClientProvider client={queryClient}>
            <SessionProvider session={pageProps.session} refetchInterval={5 * 60}>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <WallClockProvider>
                  {loading && isGoingToSettings ? (
                    <RootLayout header={<SettingsPageHeader />}>
                      <SettingsLayout>
                        <div className="flex items-center space-x-2 mx-auto mt-20">
                          <div aria-label="Loading..." role="status">
                            <BigSpinner size={8} />
                          </div>
                          <span className="text-xl font-medium text-slate-500">Loading...</span>
                        </div>
                      </SettingsLayout>
                    </RootLayout>
                  ) : (
                    <>{getLayout(<Component {...pageProps} />)}</>
                  )}
                </WallClockProvider>
              </HydrationBoundary>
            </SessionProvider>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
          </QueryClientProvider>
        </ShortcutProvider>
        <Analytics />
      </SegmentProvider>
    </>
  );
}
