import { DeleteIcon } from '@/icons/DeleteIcon';
import { useSegment } from '@/components/useSegment';
import { RightSidebarIcon } from '@/icons/RightSidebarIcon';
import { Note } from '@/models/NoteModel';
import { useNotes } from '@/hooks/useNotes';
import { NoteIcon } from '@/icons/NoteIcon';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { cn } from 'ui/cn';
import { Popover } from '@/components/Popover';
import { GrMore } from 'react-icons/gr';
import { MouseEvent } from 'react';
import { NewCommandBox } from '../CommandBox/NewCommandBox';

type NoteItemProps = {
  note: Note;
  isColumnView?: boolean;
};

export default function NoteItem({ note, isColumnView = false }: NoteItemProps) {
  const { trackEvent } = useSegment();
  const { deleteNote } = useNotes();
  const { openLeftSidebar } = useLeftSidebar();

  const handleOpenLeftSidebar = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openLeftSidebar({
      type: 'NOTE_EDITOR',
      context: { id: note.id },
    });
    trackEvent('Entity Viewed From Left Side Bar', {
      entity_id: note.id,
      entity_type: 'note',
      method: 'sidebar icon click',
    });
  };

  if (!note) return null;

  return (
    <>
      <div
        className={cn('flex gap-x-2 flex-wrap items-center w-full', isColumnView ? 'gap-2' : 'focus:bg-primary-100')}
        onClick={handleOpenLeftSidebar}>
        <div className={cn('flex min-w-0 items-center gap-2', isColumnView && 'w-full')}>
          {isColumnView ? (
            <>
              <NoteIcon className="mr-[8px]" size={18} />
              <span className="py-1 pl-2 text-sm" onClick={handleOpenLeftSidebar}>
                {note.title}
              </span>
            </>
          ) : (
            <>
              <NoteIcon size={18} />
              <NewCommandBox
                use="update"
                className={cn('py-1 pl-[4px] bg-transparent max-w-[592px]', isColumnView && 'flex-1')}
                initialItemType="NOTE"
                hideRightSection={true}
                hideTooltip={true}
                key={note.id}
                item={note}
              />
            </>
          )}
          {isColumnView ? (
            <Popover
              trigger={
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <GrMore color="#000" size={14} />
                </div>
              }
              closeVisible={false}
              arrowVisible={false}
              className="flex gap-3.5 w-auto bg-gray-100 px-6 py-2"
              align="end"
              side="top">
              <div onClick={handleOpenLeftSidebar}>
                <RightSidebarIcon className="hover:cursor-pointer" />
              </div>
              <div
                data-delete-button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteNote({ noteId: note.id });
                }}>
                <DeleteIcon className="fill-black hover:cursor-pointer hover:fill-red-500" />
              </div>
            </Popover>
          ) : null}
        </div>
        <div
          className={cn(
            'ml-auto hidden group-hover:flex group-focus-within:flex mr-[5px] gap-6',
            isColumnView && '!hidden'
          )}>
          <button onClick={handleOpenLeftSidebar}>
            <RightSidebarIcon className="hover:cursor-pointer" />
          </button>
          <button
            data-delete-button
            onClick={(e) => {
              e.stopPropagation();
              deleteNote({ noteId: note.id });
            }}>
            <DeleteIcon className="fill-black hover:cursor-pointer hover:fill-red-500" />
          </button>
        </div>
      </div>
    </>
  );
}
