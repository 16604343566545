import { FormEvent, useEffect, useState } from 'react';
import { useEasySession } from '@/hooks/useEasySession';
import { extractParticipants } from 'utils/email';
import { toast } from 'react-hot-toast';
import { Avatar } from '@/components/Avatar';
import { Tiptap } from '@/components/TipTap';
import { sendEmail, useEmailThread } from '@/hooks/useEmails';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { useAccounts } from '@/hooks/useAccounts';
import { ToastGeneric } from '@/components/ToastGeneric';
import { Divider } from '@/components/Divider';
import { DeleteIcon } from '@/icons/DeleteIcon';
import moment from 'moment-timezone';
import { ComposeType } from './Messages';
import { GuestsComposeEmail } from './GuestComposeEmail';
import { NameEmail } from '@/utils/contacts';

export type ComposeEmailProps = {
  threadId?: string;
  originalMessageId?: string;
  onClose?: () => void;
  composeType?: ComposeType;
};

export const ComposeEmail = ({ threadId, originalMessageId, composeType, onClose }: ComposeEmailProps) => {
  const { user } = useEasySession();
  const { accounts } = useAccounts();

  const [toEmails, setToEmails] = useState<NameEmail[]>([]);
  const [ccEmails, setCcEmails] = useState<NameEmail[]>([]);
  const [bccEmails, setBccEmails] = useState<NameEmail[]>([]);
  const [subject, setSubject] = useState<string>();
  const [body, setBody] = useState<string>();

  const [isShowCCField, setIsCCField] = useState(false);
  const [isShowBCCField, setIsBCCField] = useState(false);

  const { emailThread: originalEmailThread, isLoading } = useEmailThread({ threadId });

  useEffect(() => {
    if (isLoading) return;

    const message = originalEmailThread?.messages.find((m) => m.id === originalMessageId);
    if (!message) {
      // reset inputs
      setSubject('');
      setBody('');
      setIsCCField(false);
      setIsBCCField(false);
      return;
    }

    const { from, cc, subject } = extractParticipants(message.headers);

    // Start with two line breaks for space at the top
    let fullThreadContent = '<br><br>';
    let indentLevel = 0;

    originalEmailThread!.messages
      .slice()
      .reverse()
      .forEach((msg) => {
        const { from: msgFrom, date: msgDate } = extractParticipants(message.headers);

        // Ensure indentation is applied
        const indentPadding = 'padding-left:' + indentLevel * 40 + 'px;'; // Adjust as needed
        fullThreadContent += `
          <div style="${indentPadding} margin-left:${
            indentLevel * 10
          }px; border-left:1px solid #ccc; padding-left:10px; margin-bottom:10px;">
            On ${moment(msgDate).format('ddd, MMM D, YYYY, h:mm A')}, ${msgFrom.address.match(/(.*)\s<.*>/)?.[1] || msgFrom} wrote:
            <blockquote>
              ${msg.textHtml}
            </blockquote>
          </div>
        `;
        indentLevel++;
      });

    switch (composeType) {
      case 'reply':
        setSubject(`Re: ${subject}`);
        setToEmails([{ email: from.address }]); // Reply only to the sender
        setBody(fullThreadContent);
        break;
      case 'forward':
        setSubject(`Fwd: ${subject}`);
        setBody(fullThreadContent);
        // Do not automatically include any recipients for forwarding
        break;
    }

    // actualizar todos los useState
    setSubject(message.headers['subject'] ?? '');

    setIsCCField(cc.length > 0);
    setIsBCCField(false);
  }, [composeType, originalEmailThread, originalMessageId, isLoading, accounts, user?.email]);

  const showCloseButton = !!originalMessageId;

  const { openLeftSidebar } = useLeftSidebar();

  const handleCCButtonClick = () => {
    setIsCCField((prev) => !prev);
  };

  const handleBccButtonClick = () => {
    setIsBCCField((prev) => !prev);
  };

  const handleChangeEmailsTo = (value: NameEmail[]) => {
    if (!value) {
      return;
    }
    setToEmails(value);
  };

  const handleChangeEmailsBcc = (value: NameEmail[]) => {
    if (!value) {
      return;
    }
    setBccEmails(value);
  };

  const handleChangeEmailsCc = (value: NameEmail[]) => {
    if (!value) {
      return;
    }
    setCcEmails(value);
  };

  const handleSubjectChange = (subject: string) => {
    setSubject(subject);
  };

  const handleBodyChange = (body: string) => {
    setBody(body);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!user) return;
    if (!subject) {
      toast(<ToastGeneric title="Please enter a subject" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      return;
    }
    if (!body) {
      toast(<ToastGeneric title="Cannot send empty emails" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      return;
    }

    await Promise.allSettled(
      toEmails.map((email) => {
        sendEmail({
          to: email.email,
          cc: ccEmails.map((cc) => cc.email).join(','),
          bcc: bccEmails.map((bcc) => bcc.email).join(','),
          content: body,
          subject: subject,
          track: true,
        });
      })
    );
    toast(<ToastGeneric title="Email sent" />, {
      position: 'bottom-center',
      style: { background: '#000' },
    });
    openLeftSidebar({
      type: 'ALL_EMAILS',
    });
  };

  return (
    <form className="shadow-lg bg-white border p-2 mt-1 min-h-0 overflow-y-scroll" onSubmit={handleSubmit}>
      <div className="flex items-center">
        <label className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none">To</label>
        <GuestsComposeEmail onChange={handleChangeEmailsTo} selectedContacts={toEmails} placeholder="" />
        {!isShowCCField ? (
          <button
            className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none"
            type="button"
            onClick={handleCCButtonClick}>
            Cc
          </button>
        ) : null}
        {!isShowBCCField ? (
          <button
            className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none"
            type="button"
            onClick={handleBccButtonClick}>
            Bcc
          </button>
        ) : null}
      </div>
      <Divider />
      <div className="flex items-center gap-3">
        <p className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none">From</p>
        <Avatar picture={user?.picture} name={user?.name} className="rounded-full" size={25} />
        <div>
          <p className="text-[12px] leading-[14px]">{user?.name ?? accounts?.[0].email}</p>
        </div>
      </div>
      <Divider />
      <div className="mt-4">
        {isShowCCField ? (
          <>
            <div className="flex items-center">
              <label className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none">Cc</label>
              <GuestsComposeEmail onChange={handleChangeEmailsCc} selectedContacts={ccEmails} placeholder="" />
            </div>
            <Divider />
          </>
        ) : null}

        {isShowBCCField ? (
          <>
            <div className="flex items-center">
              <label className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none">Bcc</label>
              <GuestsComposeEmail onChange={handleChangeEmailsBcc} selectedContacts={bccEmails} placeholder="" />
            </div>
            <Divider />
          </>
        ) : null}
      </div>

      <div className="flex gap-2 items-center">
        <div className="text-[12px] leading-[14px] text-[#A1A1A3] mr-[8px] select-none">Subject</div>
        <input
          value={subject ?? ''}
          onChange={(e) => handleSubjectChange(e.target.value)}
          name="subject"
          className="w-full text-[12px] leading-[14px] h-[35px] px-[10px] outline-none"
          type="text"
        />
      </div>
      <Divider />
      <div className="mt-4 max-h-[450px] h-[400px] overflow-y-auto">
        <Tiptap
          placeholder="Compose your email"
          classNameEditor="max-h-[350px] h-[350px]"
          content={body}
          setContent={handleBodyChange}
        />
      </div>
      <div className="flex items-center justify-between mt-4">
        <button
          type="submit"
          className="w-[95px] h-[35px] font-semibold text-white transition duration-200 bg-blue-600 rounded hover:bg-blue-700">
          Send
        </button>
        <div>
          {showCloseButton ? (
            <button type="button" className="text-gray-400 transition-all hover:text-red-600" onClick={onClose}>
              <DeleteIcon size={20} />
            </button>
          ) : null}
        </div>
      </div>
    </form>
  );
};
