import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Mousetrap from 'mousetrap';
import { useRightSidebar } from '@/components/RightSidebar';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';

type Keybinding = {
  key: string | string[];
  action: (e: KeyboardEvent) => void;
};

type MousetrapContextType = {
  registerShortcut: (key: Keybinding['key'], action: Keybinding['action']) => void;
  unregisterShortcut: (key: Keybinding['key']) => void;
  showShortcuts: boolean;
};

export const ShortcutContext = createContext<MousetrapContextType>({
  registerShortcut: () => {},
  unregisterShortcut: () => {},
  showShortcuts: false,
});

export const useShortcuts = () => useContext(ShortcutContext);

// const VIEW_ORDER = ['/list', '/calendar'];

function shouldStopKeybindings(target: EventTarget | null) {
  if (!(target instanceof HTMLElement)) return false;

  return (
    target.tagName === 'INPUT' ||
    target.tagName === 'SELECT' ||
    target.tagName === 'TEXTAREA' ||
    (target.contentEditable && target.contentEditable === 'true')
  );
}

export const ShortcutProvider = ({ children }: { children: ReactNode }) => {
  const isSlashKeyPressed = useRef(false);
  const [keybindings, setKeybindings] = useState<Keybinding[]>([]);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const { openRightSidebar, rightSidebarState, closeRightSidebar, messageSidebarState } = useRightSidebar();
  const { closeLeftSidebar, leftSidebarState, openLeftSidebar } = useLeftSidebar();

  const router = useRouter();
  const { asPath: currentPath } = router;

  useEffect(() => {
    keybindings.forEach(({ key, action }) => {
      Mousetrap.bind(key, action);
    });

    const handleKeyDown = (event: KeyboardEvent) => {
      if (shouldStopKeybindings(event.target)) return;
      const isOnboarding = currentPath === '/onboarding';
      if (isOnboarding) return;

      if (event.key === '?' || event.key === '/') {
        setShowShortcuts(true);
        isSlashKeyPressed.current = true;
        return;
      }
      if (!leftSidebarState.show || rightSidebarState.type !== 'CALENDAR_DOCK') {
        setShowShortcuts(false);
        if (!isSlashKeyPressed.current) return;
        
          switch (event.key) {
            case 'B':
              if (leftSidebarState.show && leftSidebarState.type === 'CONTACT_EDITOR') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'CONTACT_EDITOR',
              });
              break;
            case 'b':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_CONTACTS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'ALL_CONTACTS',
              });
              break;

            case 'M':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_EMAILS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'ALL_EMAILS',
              });
              break;
            case 'm':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_EMAILS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'ALL_EMAILS',
              });
              break;

            case 'f':
            case 'F': {
              event.preventDefault();
              const filterButton = document.querySelector('[data-filter-button]') as HTMLButtonElement;
              filterButton?.focus();
              filterButton?.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter', bubbles: true }));
              break;
            }
            case 'G':
              if (leftSidebarState.show && leftSidebarState.type === 'GROUP_EDITOR') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'GROUP_EDITOR',
              });
              break;
            case 'g':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_GROUPS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'ALL_GROUPS',
              });
              break;
            case 'H':
              if (leftSidebarState.show && leftSidebarState.type === 'EVENT_EDITOR') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({
                type: 'EVENT_EDITOR',
              });
              break;
            case 'h': {
              if (leftSidebarState.show && leftSidebarState.type === 'SINGLE_DAY_CALENDAR') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({ type: 'SINGLE_DAY_CALENDAR' });
              break;
            }
            case 'i':
            case 'I':
              void router.push('/settings/integrations', undefined, { shallow: true });
              break;
            case 'q':
            case 'Q':
              if (leftSidebarState.show && leftSidebarState.type === 'FEEDBACK') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({
                type: 'FEEDBACK',
              });
              break;
            case 'N':
              if (leftSidebarState.show && leftSidebarState.type === 'NOTE_EDITOR') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'NOTE_EDITOR',
              });
              break;
            case 'n':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_NOTES') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({
                type: 'ALL_NOTES',
              });
              break;
            case 'P':
              event.preventDefault();
              if (leftSidebarState.show && leftSidebarState.type === 'NEW_PROJECT') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({
                type: 'NEW_PROJECT',
              });
              break;
            case 'p':
              if (leftSidebarState.show && leftSidebarState.type === 'PROJECTS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'PROJECTS',
              });
              break;
            case 'S':
              if (leftSidebarState.show && leftSidebarState.type === 'SCHEDULING_LINK_EDITOR') {
                closeLeftSidebar();
                return;
              }
              if (!messageSidebarState?.show) {
                openLeftSidebar({
                  type: 'SCHEDULING_LINK_EDITOR',
                });
              }
              break;
            case 's':
              if (leftSidebarState.show && leftSidebarState.type === 'ALL_SCHEDULING_LINKS') {
                closeLeftSidebar();
                return;
              }
              if (!messageSidebarState?.show) {
                openLeftSidebar({
                  type: 'ALL_SCHEDULING_LINKS',
                });
              }
              break;
            case 'u':
            case 'U':
              if (leftSidebarState.show && leftSidebarState.type === 'UNSCHEDULED') {
                closeLeftSidebar();
                return;
              }
              openLeftSidebar({
                type: 'UNSCHEDULED',
              });
              break;
            case 'y':
            case 'Y':
              closeRightSidebar();
              closeLeftSidebar();
              break;
            case '.':
            case '>':
              if (leftSidebarState.show && leftSidebarState.type === 'SETTINGS') {
                closeLeftSidebar();
                break;
              }
              openLeftSidebar({
                type: 'SETTINGS',
              });
              break;
            // case '-':
            //   if (isSlashKeyPressed.current) {
            //     isSlashKeyPressed.current = false;
            //     if (leftSidebarState.show && leftSidebarState.type === 'TASK_EDITOR') {
            //       closeLeftSidebar();
            //       break;
            //     }
            //     openLeftSidebar({
            //       type: 'TASK_EDITOR',
            //     });
            //   }
            //   break;
            // case ' ': {
            //   if (isSlashKeyPressed) {
            //     event.preventDefault();
            //     isSlashKeyPressed.current = false;

            //     const currentPathIndex = VIEW_ORDER.indexOf(currentPath);
            //     if (event.shiftKey) {
            //       // Add the Shift + Space behavior here
            //       void router.push(VIEW_ORDER[(currentPathIndex - 1 + VIEW_ORDER.length) % VIEW_ORDER.length]);
            //       return;
            //     }

            //     void router.push(VIEW_ORDER[(currentPathIndex + 1) % VIEW_ORDER.length]);
            //   }
            //   break;
            // }
            case 'k':
            case 'K':
              if (isSlashKeyPressed.current) {
                event.preventDefault();
                isSlashKeyPressed.current = false;
                if (leftSidebarState.show && leftSidebarState.type === 'SHORTCUTS') {
                  closeLeftSidebar();
                  return;
                }
                openLeftSidebar({
                  type: 'SHORTCUTS',
                });
              }
              break;
            case 'c':
            case 'C':
              if (isSlashKeyPressed) {
                isSlashKeyPressed.current = false;
              }
              break;
            case '/': {
              if (isSlashKeyPressed) {
                isSlashKeyPressed.current = false;
              }
              break;
            }
          }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (shouldStopKeybindings(event.target)) return;
      setShowShortcuts(false);
      if (event.key === '/') {
        isSlashKeyPressed.current = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      keybindings.forEach(({ key }) => Mousetrap.unbind(key));
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    keybindings,
    rightSidebarState,
    leftSidebarState,
    openRightSidebar,
    closeRightSidebar,
    currentPath,
    router,
    openLeftSidebar,
    closeLeftSidebar,
    isSlashKeyPressed,
    messageSidebarState?.show,
  ]);

  const registerShortcut = useCallback((key: Keybinding['key'], action: Keybinding['action']) => {
    setKeybindings((prevKeybindings) => [...prevKeybindings, { key, action }]);
    isSlashKeyPressed.current = false;
  }, []);

  const unregisterShortcut = useCallback((key: Keybinding['key']) => {
    isSlashKeyPressed.current = false;
    setKeybindings((prevKeybindings) => prevKeybindings.filter((binding) => binding.key !== key));
  }, []);

  return (
    <ShortcutContext.Provider value={{ registerShortcut, unregisterShortcut, showShortcuts }}>
      {children}
    </ShortcutContext.Provider>
  );
};
