import {
  MdEditDocument,
  MdForum,
  MdGroups2,
  MdLabelImportantOutline,
  MdMarkEmailUnread,
  MdMarkunread,
  MdOutlineMoveToInbox,
  MdOutlinePriorityHigh,
  MdOutlineSell,
  MdOutlineSend,
  MdPerson,
  MdStarBorder,
  MdMenu,
  MdUpcoming,
  MdAllInbox
} from 'react-icons/md';
import { CalendarIcon } from '@radix-ui/react-icons';
import { VscArchive } from 'react-icons/vsc';
import { Bullet } from '@/icons/Bullet';
import { DeleteIcon } from '@/icons/DeleteIcon';

export const EMAIL_LABELS = [
  'INBOX',
  'IMPORTANT',
  'CALENDAR',
  'NEWSLETTER',
  'OTHER',
  'STARRED',
  'SENT',
  'DRAFT',
  'TRASH',
  'SPAM',
  'IMPORTANT',
  'IMPORTANT_LABEL',
  'CATEGORY_UPDATES',
  'CATEGORY_SOCIAL',
  'CATEGORY_PROMOTIONS',
  'CATEGORY_FORUMS',
  'CATEGORY_PERSONAL',
  'UNREAD',
  'ALL_EMAILS',
  'CALENDAR',
  'OTHER',
  'SPAM',
  'MORE',
] as const;

export type EmailLabel = (typeof EMAIL_LABELS)[number];

export const LabelDisplayNames: Record<EmailLabel, string> = {
  INBOX: 'Inbox',
  STARRED: 'Starred',
  SENT: 'Sent',
  DRAFT: 'Draft',
  IMPORTANT: 'Important',
  TRASH: 'Trash',
  SPAM: 'Spam',
  IMPORTANT_LABEL: 'Label: Important',
  CATEGORY_UPDATES: 'Updates',
  CATEGORY_SOCIAL: 'Social',
  CATEGORY_PROMOTIONS: 'Promotions',
  CATEGORY_FORUMS: 'Forums',
  CATEGORY_PERSONAL: 'Personal',
  UNREAD: 'Unread',
  ALL_EMAILS: 'All Email',
  CALENDAR: 'Calendar',
  OTHER: 'Other',
  MORE: 'More',
  NEWSLETTER: 'Newsletters',
};

export const MAIN_TABS: EmailLabel[] = ['IMPORTANT', 'CALENDAR', 'OTHER'];

export const MORE_TABS: EmailLabel[] = [
  'INBOX',
  'STARRED',
  'SENT',
  'UNREAD',
  'DRAFT',
  'SPAM',
  'TRASH',
  'ALL_EMAILS',
  'CATEGORY_SOCIAL',
  'CATEGORY_UPDATES',
  'CATEGORY_FORUMS',
  'CATEGORY_PROMOTIONS',
  'CATEGORY_PERSONAL',
];

export const icon = (key: EmailLabel) => {
  switch (key) {
    case 'IMPORTANT': {
      return <MdUpcoming size={14} />
    }
    case 'MORE': {
      return <MdMenu size={14} />
    }
    case 'ALL_EMAILS': {
      return <MdMarkunread size={14} />;
    }
    case 'IMPORTANT_LABEL': {
      return <MdLabelImportantOutline size={14} />;
    }
    case 'CALENDAR': {
      return <CalendarIcon />;
    }
    case 'OTHER': {
      return <MdAllInbox size={14} />;
    }
    case 'SPAM': {
      return <VscArchive size={14} />;
    }
    case 'UNREAD': {
      return <MdMarkEmailUnread size={14} />;
    }
    case 'CATEGORY_FORUMS': {
      return <MdForum size={14} />;
    }
    case 'CATEGORY_PERSONAL': {
      return <MdPerson size={14} />;
    }
    case 'CATEGORY_PROMOTIONS': {
      return <MdOutlineSell size={14} />;
    }
    case 'CATEGORY_SOCIAL': {
      return <MdGroups2 size={14} />;
    }
    case 'CATEGORY_UPDATES': {
      return <MdOutlinePriorityHigh size={14} />;
    }
    case 'DRAFT': {
      return <MdEditDocument size={14} />;
    }
    case 'SENT': {
      return <MdOutlineSend size={14} />;
    }
    case 'TRASH': {
      return <DeleteIcon size={14} />;
    }
    case 'STARRED': {
      return <MdStarBorder size={14} />;
    }
    case 'INBOX': {
      return <MdOutlineMoveToInbox size={14} />;
    }

    default: {
      return <Bullet size={14} color="#a1a1a3" />;
    }
  }
};