import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  className?: string;
  size?: number;
  color?: string;
  hidden?: boolean;
};

export const RightSidebarIcon = ({ size = 16, color = '#000000', hidden, ...rest }: Props) => {
  if (hidden) return null;
  return (
    <svg width={size} height={size} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_9356_275097)">
        <path
          d="M15.4166 0.75H1.91663C1.29788 0.75 0.791626 1.25625 0.791626 1.875V13.125C0.791626 13.7437 1.29788 14.25 1.91663 14.25H15.4166C16.0354 14.25 16.5416 13.7437 16.5416 13.125V1.875C16.5416 1.25625 16.0354 0.75 15.4166 0.75ZM15.4166 6.9375H9.67913L11.7041 4.9125L10.9166 4.125L7.54163 7.5L10.9166 10.875L11.7041 10.0875L9.67913 8.0625H15.4166V13.125H6.41663V1.875H15.4166V6.9375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9356_275097">
          <rect width="16" height="14" fill="white" transform="translate(0.666626 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
