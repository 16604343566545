import { useSettings } from '@/hooks/useSettings';
import { formatDate } from '@/utils/date';
import moment from 'moment-timezone';
import { MdAlarm } from 'react-icons/md';

type Props = {
  date: Date | null | undefined;
};

export default function DueDateDecorator({ date }: Props) {
  const { defaultTimeZone: timeZone } = useSettings();
  if (!date) return null;

  const dueDate = moment(date);
  const status = dueDate.isBefore() ? 'expired' : 'normal';
  const dueDateString = formatDate({ timeZone, date });

  return (
    <div
      className="rounded bg-select-light-gray text-gray-500 inline-flex align-top items-center gap-1 text-[14px] px-1 data-[status=expired]:bg-red-100 data-[status=expired]:text-red-400"
      title={`Due on ${dueDateString}`}
      data-status={status}>
      <MdAlarm />
      {dueDateString}
    </div>
  );
}
