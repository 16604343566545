import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { searchStateAtom } from '@/components/Overlay/utils';
import { useSegment } from '@/components/useSegment';
import { useAtom, useSetAtom } from 'jotai';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { MdArrowForwardIos, MdEmail, MdLink } from 'react-icons/md';
import { useEasySession } from '@/hooks/useEasySession';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { Divider } from '../Divider';
import { UserAvatar } from '../UserAvatar';
import { NoteIcon } from '@/icons/NoteIcon';
import useLeftSidebar, { LeftSidebarType } from '@/components/LeftSidebar/useLeftSidebar';
import { FeedbackIcon } from '@/icons/FeedbackIcon';
import { RoundedCalendarIcon } from '@/icons/RoundedCalendarIcon';
import AlertIcon from '@/icons/AlertIcon';
import { useTasks } from '@/hooks/useTasks';
import { showDockedNavBarAtom } from '@/utils/collapsibleAtoms';
import { UnscheduledIcon } from '@/icons/UnscheduledIcon';
import { MenuClosedIcon, MenuOpenIcon } from '@/icons/MenuIcon';
import { CommandBarIcon } from '@/icons/CommandBarIcon';
import ProjectIcon from '@/icons/ProjectIcon';
import { useProjects } from '@/hooks/useProjects';
import { CommandMacOsIcon } from '@/icons/CommandIcon';
import { Project } from '@/models/ProjectModel';
import ProjectColorPicker from '../ProjectColorPicker';
import { cn } from 'ui/cn';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import moment from 'moment-timezone';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { useEvents } from '@/hooks/useEvents';
import { Task } from '@/models/TaskModel';
import { useScreenWidth } from '@/hooks/useScreenWidth';

export const NavigatorView = ({ className }: { className?: string }) => {
  const { trackEvent } = useSegment();
  const { isActive, hasTrialEnded } = useEasySession();
  const shouldDisableButtons = !isActive && hasTrialEnded;
  const screenRelativeSize = useScreenWidth({ width: 430 });
  const router = useRouter();
  const { closeLeftSidebar, openLeftSidebar, leftSidebarState } = useLeftSidebar();
  const setSearchState = useSetAtom(searchStateAtom);
  const [expanded, setExpanded] = useState(false);
  const [showDockedNavBar, setShowDockedNavBar] = useAtom(showDockedNavBarAtom);
  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { schedulingLinks } = useSchedulingLinks({});
  const { projects } = useProjects({});
  const { setGlobalDateRange } = useGlobalDateRange();
  const [viewProjects, setViewProjects] = useState(false);

  const showUnscheduledTaskAlert = useMemo(
    () =>
      tasks?.results.some(
        (task) => !task.start && task.taskScheduleType === 'AUTO_SCHEDULED' && task.isCompleted === false
      ),
    [tasks]
  );

  // prettier-ignore
  const unscheduledTaskCount = useMemo(() => {
    return tasks?.results.filter(
      (task: Task) =>
        task.taskScheduleType === 'NONE' &&
        !task.isCompleted &&
        !task.parentTaskId
    )?.length;
  }, [tasks]);

  const isSidebarActive = (sidebarType: LeftSidebarType) => {
    if (!sidebarType) return false;
    return leftSidebarState.type === sidebarType;
  };

  const disabledAvatarButton = router.asPath.startsWith('/settings');

  const openSearch = () => {
    setSearchState({
      show: true,
      type: 'SEARCH',
    });
    trackEvent('Command Bar Selected');
  };

  const handleOpenSidebarAllContacts = () => {
    if (leftSidebarState.type === 'ALL_CONTACTS' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({ type: 'ALL_CONTACTS' });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    trackEvent('Open from Left Sidebar', { itemID: 'ALL_CONTACTS', itemType: 'Contact' });
  };

  const handleOpenSidebarAllSchedulingLinks = () => {
    if (leftSidebarState.type === 'ALL_SCHEDULING_LINKS' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({ type: 'ALL_SCHEDULING_LINKS' });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    trackEvent('Open from Left Sidebar', { itemID: 'ALL_SCHEDULING_LINKS', itemType: 'SchedulingLink' });
  };

  const handleOpenSidebarAllNotes = () => {
    if (leftSidebarState.type === 'ALL_NOTES' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({ type: 'ALL_NOTES' });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    trackEvent('Open from Left Sidebar', { itemID: 'ALL_NOTES', itemType: 'Note' });
  };

  const handleUnscheduledClick = () => {
    if (leftSidebarState.type === 'UNSCHEDULED' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    openLeftSidebar({ type: 'UNSCHEDULED' });
  };

  const handleSingleDayCalendarClick = () => {
    if (leftSidebarState.type === 'SINGLE_DAY_CALENDAR' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    openLeftSidebar({ type: 'SINGLE_DAY_CALENDAR' });
  };

  const handleAvatarClick = () => {
    if (leftSidebarState.type === 'SETTINGS' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({
      type: 'SETTINGS',
    });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
  };

  const handleFeedbackClick = () => {
    if (leftSidebarState.type === 'FEEDBACK' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({
      type: 'FEEDBACK',
    });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    trackEvent('Feedback Modal Toggled', {
      source: 'Left Sidebar',
    });
  };

  const handleOpenSidebarAllEmail = () => {
    if (leftSidebarState.type === 'ALL_EMAILS' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({ type: 'ALL_EMAILS' });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    void trackEvent('Open from Left Sidebar', { itemID: 'ALL_EMAILS', itemType: 'EMAILS' });
  };

  const handleOpenSidebarAllProjects = () => {
    if (leftSidebarState.type === 'PROJECTS' && leftSidebarState.show) {
      return closeLeftSidebar();
    }
    openLeftSidebar({ type: 'PROJECTS' });
    if (screenRelativeSize === 'SAME' || screenRelativeSize === 'SMALLER') {
      setExpanded(false);
      setShowDockedNavBar(false);
    }
    void trackEvent('Open from Left Sidebar', { itemID: 'ALL_EMAILS', itemType: 'EMAILS' });
  };

  const setDockedNavBar = () => {
    setShowDockedNavBar((prev) => !prev);
  };

  const handleOnClickProject = useCallback(
    (project: Project) => async () => {
      try {
        // extract all the dates from the tasks and events
        const tasksDates = tasks?.results
          .filter((task) => task.projectIds?.includes(project.id))
          .map((task) => task.start);
        const eventsDates = events
          ?.filter((event) => event.projectIds?.includes(project.id))
          .map((event) => event.start);
        const linksDates = schedulingLinks?.results
          .filter((link) => link.projectIds?.includes(project.id))
          .map((link) => link.start);

        // merge and sort the dates
        const allDates = [tasksDates, eventsDates, linksDates]
          .flatMap((d) => d ?? undefined)
          .filter((d) => !!d)
          .map((d) => moment(d));
        if (allDates.length) {
          setGlobalDateRange({ start: moment.min(allDates).toDate() });
        }
        await router.push(`/projects/${project.id}`);
      } catch (error) {
        console.error('Failed to navigate:', error);
      }
    },
    [events, router, schedulingLinks?.results, setGlobalDateRange, tasks?.results]
  );

  return (
    <div
      className={cn('pt-[30px]', className)}
      onMouseEnter={() => {
        if (showDockedNavBar) return;
        setExpanded(true);
      }}
      onMouseLeave={() => {
        if (showDockedNavBar) return;
        setTimeout(() => {
          setExpanded(false);
        }, 300);
      }}>
      <div
        className={cn(
          'gap-[16px] relative flex-1 max-h-full flex flex-col min-h-0 h-screen overflow-y-auto',
          showDockedNavBar ? 'w-[200px]' : 'w-[60px]',
          'hover:w-[200px] transition-width ease duration-500'
        )}>
        <div className={`flex flex-col ${expanded ? 'mx-[16px]' : 'mx-[4px] items-center'} `}>
          <div className={`flex justify-between gap-[4px] mb-2.5`}>
            {!expanded && !showDockedNavBar ? (
              <button type="button" className="h-[24px] w-[24px]" onClick={setDockedNavBar}>
                <MenuClosedIcon />
              </button>
            ) : (
              <>
                <span className="text-sm text-gray-400 truncate select-none font-inter transition-width ease">
                  Menu
                </span>
                <button
                  type="button"
                  className={`h-[24px] w-[24px] transition-all duration-300 ${showDockedNavBar ? 'rotate-180' : ''}`}
                  onClick={setDockedNavBar}>
                  <MenuOpenIcon />
                </button>
              </>
            )}
          </div>
          <div className="block mt-2">
            <div className={`flex justify-between items-center cursor-pointer`} onClick={handleAvatarClick}>
              <div
                className={cn(
                  `flex items-center gap-[10px] transition-all hover:bg-gray-200`,
                  `hover:rounded-[4px] w-full`,
                  isSidebarActive('SETTINGS') ? 'bg-gray-200 rounded-[4px]' : ''
                )}>
                <div className="h-[30px] w-[30px]">
                  <UserAvatar disabled={disabledAvatarButton} />
                </div>
                <div>
                  {expanded && !showDockedNavBar ? (
                    <span className="text-sm truncate font-inter transition-width ease">Settings</span>
                  ) : null}
                  {showDockedNavBar ? (
                    <span className="text-sm truncate font-inter transition-width ease">Settings</span>
                  ) : null}
                </div>
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut={'>'} />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut={'>'} />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut={'>'} />
                </div>
              ) : null}
            </div>
          </div>

          <Divider className="my-[16px]" />
          <div className="flex flex-col gap-[16px]">
            <div className="flex justify-between cursor-pointer h-[20px]" onClick={openSearch}>
              <div className="flex items-center gap-[10px] hover:bg-gray-200 hover:rounded-[4px] w-full transition-all">
                <button
                  type="button"
                  title="Command menu"
                  className={`flex justify-center`}
                  disabled={shouldDisableButtons}>
                  <CommandBarIcon size={16} />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate font-inter transition-width ease">Command Bar</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate font-inter transition-width ease">Command Bar</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="k" icon={<CommandMacOsIcon />} />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="k" icon={<CommandMacOsIcon />} />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="k" icon={<CommandMacOsIcon />} />
                </div>
              ) : null}
            </div>

            <div
              className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 
                hover:rounded-[4px] w-full ${isSidebarActive('UNSCHEDULED') ? 'bg-gray-200 rounded-[4px]' : ''}`}
              onClick={handleUnscheduledClick}>
              <button
                type="button"
                className="relative flex items-center justify-center cursor-pointer"
                disabled={shouldDisableButtons}>
                <UnscheduledIcon size={16} />
                {showUnscheduledTaskAlert ? <AlertIcon className="absolute bottom-0 right-[-10px]" size={16} /> : null}
              </button>
              <div className="flex items-center justify-between w-full cursor-pointer">
                {expanded && !showDockedNavBar ? (
                  <>
                    <span className="text-sm truncate select-none font-inter transition-width ease">To-dos</span>
                    <span className="text-sm text-gray-400 truncate font-inter transition-width ease">
                      {unscheduledTaskCount}
                    </span>
                  </>
                ) : null}

                {showDockedNavBar ? (
                  <>
                    <span className="text-sm truncate select-none font-inter transition-width ease">To-dos</span>
                    <span className="text-sm text-gray-400 truncate font-inter transition-width ease">
                      {unscheduledTaskCount}
                    </span>
                  </>
                ) : null}
                {!expanded && !showDockedNavBar ? (
                  <div className="flex fixed left-[70px] z-50">
                    <KeyboardShortcutIndicator shortcut="u" />
                  </div>
                ) : null}
                {showDockedNavBar ? (
                  <div className="flex fixed left-[210px] z-50">
                    <KeyboardShortcutIndicator shortcut="u" />
                  </div>
                ) : null}
                {expanded && !showDockedNavBar ? (
                  <div className="flex fixed left-[210px] z-50">
                    <KeyboardShortcutIndicator shortcut="u" />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleSingleDayCalendarClick}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 
                hover:rounded-[4px] w-full 
                ${isSidebarActive('SINGLE_DAY_CALENDAR') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className="flex items-center justify-center" disabled={shouldDisableButtons}>
                  <RoundedCalendarIcon color="#A1A1A3" size={16} />
                </button>

                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Today</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Today</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="h" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="h" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="h" />
                </div>
              ) : null}
            </div>
          </div>

          <Divider className="my-[16px]" />

          <div className="flex flex-col gap-[16px] transition-height ease-in duration-200">
            <div
              className={`flex items-center gap-[10px] cursor-pointer h-[20px] 
              transition-all hover:bg-gray-200 hover:rounded-[4px] 
              ${isSidebarActive('PROJECTS') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
              <button type="button" className={`flex justify-center items-center`} disabled={shouldDisableButtons}>
                <ProjectIcon color="#A1A1A3" size={16} />
              </button>
              <div className="flex items-center justify-between w-full">
                {expanded && !showDockedNavBar ? (
                  <>
                    <div className="flex items-center gap-[10px]">
                      <span
                        className="text-[12px] leading-[10px] font-inter truncate transition-width ease select-none"
                        onClick={handleOpenSidebarAllProjects}>
                        Projects
                      </span>
                      <MdArrowForwardIos
                        color="#A1A1A3"
                        size={12}
                        className={`${viewProjects ? 'rotate-90 transition-all ease duration-150' : ''}`}
                        onClick={() => {
                          setViewProjects((prev) => !prev);
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {showDockedNavBar ? (
                  <>
                    <div className="flex items-center gap-[10px]">
                      <span
                        className="text-[12px] leading-[10px] font-inter truncate transition-width ease select-none"
                        onClick={handleOpenSidebarAllProjects}>
                        Projects
                      </span>
                      <MdArrowForwardIos
                        color="#A1A1A3"
                        size={12}
                        className={`${viewProjects ? 'rotate-90 transition-all ease duration-150' : ''}`}
                        onClick={() => {
                          setViewProjects((prev) => !prev);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {viewProjects && showDockedNavBar ? (
              <div className="flex flex-col gap-[16px] transition-height ease-in duration-200">
                {projects
                  ?.filter((v) => !v.parentProjectId)
                  .slice(0, 5)
                  .map((project) => {
                    return (
                      <div className="flex items-center gap-[4px]">
                        <button
                          type="button"
                          className="flex items-center gap-[10px]"
                          onClick={handleOnClickProject(project)}>
                          <ProjectIcon size={16} />
                          <span className="overflow-hidden text-[12px] leading-[10px]">{project.name}</span>
                        </button>
                        <ProjectColorPicker projectId={project.id} />
                      </div>
                    );
                  })}
              </div>
            ) : null}
            {viewProjects && expanded && !showDockedNavBar ? (
              <div className="flex flex-col gap-[16px] transition-height ease-in duration-200">
                {projects
                  ?.filter((v) => !v.parentProjectId)
                  .slice(0, 5)
                  .map((project) => {
                    return (
                      <div className="flex items-center gap-[4px]">
                        <button type="button" className="flex items-center" onClick={handleOnClickProject(project)}>
                          <ProjectIcon size={16} className="shrink-0" />
                          <span className="overflow-hidden text-[12px] leading-[10px] ml-1">{project.name}</span>
                        </button>
                        <ProjectColorPicker projectId={project.id} />
                      </div>
                    );
                  })}
              </div>
            ) : null}
            {!expanded && !showDockedNavBar ? (
              <div className="flex fixed left-[70px] z-50">
                <KeyboardShortcutIndicator shortcut="p" />
              </div>
            ) : null}
            {showDockedNavBar ? (
              <div className="flex fixed left-[210px] z-50">
                <KeyboardShortcutIndicator shortcut="p" />
              </div>
            ) : null}
            {expanded && !showDockedNavBar ? (
              <div className="flex fixed left-[210px] z-50">
                <KeyboardShortcutIndicator shortcut="p" />
              </div>
            ) : null}
          </div>

          {expanded && !showDockedNavBar ? (
            <button
              type="button"
              className="text-[12px] leading-[10px] font-inter truncate transition-width ease mt-3 text-start select-none"
              onClick={handleOpenSidebarAllProjects}>
              See all Projects
            </button>
          ) : null}
          {showDockedNavBar ? (
            <button
              type="button"
              className="text-[12px] leading-[10px] font-inter truncate transition-width ease mt-3 text-start select-none"
              onClick={handleOpenSidebarAllProjects}>
              See all Projects
            </button>
          ) : null}
          <Divider className="my-[16px]" />

          <div className="flex flex-col gap-[16px]">
            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleOpenSidebarAllNotes}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 hover:rounded-[4px] w-full ${isSidebarActive('ALL_NOTES') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className="flex items-center justify-center" disabled={shouldDisableButtons}>
                  <NoteIcon size={16} color="#A1A1A3" />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Notes</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Notes</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="n" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="n" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="n" />
                </div>
              ) : null}
            </div>

            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleOpenSidebarAllSchedulingLinks}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 hover:rounded-[4px] w-full ${isSidebarActive('ALL_SCHEDULING_LINKS') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className="flex items-center gap-[4px]" disabled={shouldDisableButtons}>
                  <MdLink size={16} className="-rotate-45" color="#A1A1A3" />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Scheduling</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Scheduling</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="s" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="s" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="s" />
                </div>
              ) : null}
            </div>

            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleOpenSidebarAllContacts}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200
                hover:rounded-[4px] w-full ${isSidebarActive('ALL_CONTACTS') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className="flex items-center justify-center" disabled={shouldDisableButtons}>
                  <ContactsIcon size={16} color="#A1A1A3" />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Contacts</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Contacts</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="b" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="b" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="b" />
                </div>
              ) : null}
            </div>

            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleOpenSidebarAllEmail}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 
                hover:rounded-[4px] w-full ${isSidebarActive('ALL_EMAILS') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className="flex items-center justify-center" disabled={shouldDisableButtons}>
                  <MdEmail size={16} color="#A1A1A3" />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter">Emails</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Emails</span>
                ) : null}
              </div>
              {!expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[70px] z-50">
                  <KeyboardShortcutIndicator shortcut="m" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="m" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="m" />
                </div>
              ) : null}
            </div>

            <div className="flex justify-between cursor-pointer h-[20px]" onClick={handleFeedbackClick}>
              <div
                className={`flex items-center gap-[10px] transition-all hover:bg-gray-200 hover:rounded-[4px]
                 w-full ${isSidebarActive('FEEDBACK') ? 'bg-gray-200 rounded-[4px]' : ''}`}>
                <button type="button" className={`flex justify-center items-center`} disabled={shouldDisableButtons}>
                  <FeedbackIcon size={16} color="#A1A1A3" />
                </button>
                {expanded && !showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Feedback</span>
                ) : null}
                {showDockedNavBar ? (
                  <span className="text-sm truncate select-none font-inter transition-width ease">Feedback</span>
                ) : null}
              </div>

              {!expanded && !showDockedNavBar ? (
                <div className={`flex fixed left-[70px] z-50`}>
                  <KeyboardShortcutIndicator shortcut="q" />
                </div>
              ) : null}
              {showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="q" />
                </div>
              ) : null}
              {expanded && !showDockedNavBar ? (
                <div className="flex fixed left-[210px] z-50">
                  <KeyboardShortcutIndicator shortcut="q" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
