type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export default function AlertIcon({ size = 14, color = '#E33D25', className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 13 12"
      fill="none"
      className={className}>
      <g clipPath="url(#clip0_1019_81162)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.52678 0.03125C3.23164 0.03125 0.560265 2.70262 0.560265 5.99777C0.560265 9.29291 3.23164 11.9643 6.52678 11.9643C9.82193 11.9643 12.4933 9.29291 12.4933 5.99777C12.4933 2.70262 9.82193 0.03125 6.52678 0.03125ZM7.06919 3.28571C7.06919 3.14186 7.01205 3.00389 6.91033 2.90217C6.8086 2.80045 6.67064 2.7433 6.52678 2.7433C6.38293 2.7433 6.24496 2.80045 6.14324 2.90217C6.04152 3.00389 5.98437 3.14186 5.98437 3.28571V6.54018C5.98437 6.68403 6.04152 6.822 6.14324 6.92372C6.24496 7.02544 6.38293 7.08259 6.52678 7.08259C6.67064 7.08259 6.8086 7.02544 6.91033 6.92372C7.01205 6.822 7.06919 6.68403 7.06919 6.54018V3.28571ZM7.06919 8.43862C7.06919 8.29476 7.01205 8.1568 6.91033 8.05507C6.8086 7.95335 6.67064 7.89621 6.52678 7.89621C6.38293 7.89621 6.24496 7.95335 6.14324 8.05507C6.04152 8.1568 5.98437 8.29476 5.98437 8.43862V8.70982C5.98437 8.85368 6.04152 8.99164 6.14324 9.09336C6.24496 9.19509 6.38293 9.25223 6.52678 9.25223C6.67064 9.25223 6.8086 9.19509 6.91033 9.09336C7.01205 8.99164 7.06919 8.85368 7.06919 8.70982V8.43862Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1019_81162">
          <rect width="13" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
