import { cn } from 'ui/cn';
import { ParagraphText } from './Typography';
import Button from './Button';
import { EmailIcon } from '@/icons/EmailIcon';

type Answer = 'yes' | 'no' | undefined;

type Props = {
  onClose?: (answer: Answer) => void;
};

export const GuestsNotificationsModal = ({ onClose }: Props) => {
  const handleButtonClick = (answer?: Answer) => () => {
    onClose?.(answer);
  };

  return (
    <div className={cn('fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex justify-center items-center')}>
      <div className="bg-white shadow-[0_15px_35px_rgb(107,107,129,0.1)] rounded-[10px] p-[20px] sticky justify-center flex flex-col items-center gap-5">
        <div className="absolute top-[10px] right-[10px] cursor-pointer" onClick={handleButtonClick()}>
          X
        </div>
        <EmailIcon size={48} />
        <ParagraphText>Would you like to send notification emails to guests?</ParagraphText>
        <div className="flex flex-row gap-10">
          <Button variant="secondary" onClick={handleButtonClick('no')}>
            {`Don't send`}
          </Button>
          <Button
            variant="secondary"
            className="h-[48px] border-primary-500 hover:border-gray-border hover:bg-primary-500 hover:text-white flex gap-1"
            onClick={handleButtonClick('yes')}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
