import { useCallback, useMemo } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { Urls } from '@/models/ContactArg';
import { DeleteIcon } from '@/icons/DeleteIcon';
import cuid from 'cuid';

type Props = {
  urls: Urls[];
  setNewUrls: (urls: Urls[]) => void;
};

export default function UrlsSection({ urls, setNewUrls }: Props) {
  const handleOnClickAddOrganization = useCallback(() => {
    setNewUrls([
      ...urls,
      {
        metadata: '',
        type: null,
        value: '',
        id: cuid(),
      },
    ]);
  }, [setNewUrls, urls]);

  const renderedUrls = useMemo(
    () =>
      urls.map((url, index) => (
        <div key={index} className="flex items-center gap-[10px] px-[4px]">
          <div className="flex flex-col gap-[10px] w-full">
            <input
              className="w-full text-[14px] font-lexend border rounded-lg border-gray-scheduled p-2"
              placeholder="Link"
              type="text"
              value={url.value!}
              onChange={(e) => {
                const newUrls = [...urls];
                newUrls[index].value = e.target.value;
                setNewUrls(newUrls);
              }}
            />
            <input
              className="w-full text-[14px] font-lexend border rounded-lg border-gray-scheduled p-2"
              placeholder="Description"
              type="text"
              value={url.metadata!}
              onChange={(e) => {
                const newUrls = [...urls];
                newUrls[index].metadata = e.target.value;
                setNewUrls(newUrls);
              }}
            />
          </div>
          <div>
            <button onClick={() => setNewUrls(urls.filter((_, i) => i !== index))}>
              <DeleteIcon />
            </button>
          </div>
        </div>
      )),
    [urls, setNewUrls]
  );

  return (
    <div className="flex flex-col gap-[12px]">
      {renderedUrls}
      <button
        type="button"
        className="flex items-center text-[14px] gap-[12px] underline"
        onClick={handleOnClickAddOrganization}>
        <PlusIcon fontSize={8} />
        Add new url
      </button>
    </div>
  );
}
