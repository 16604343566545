/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useState } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { cn } from 'ui/cn';
import { MdCancel as RemoveButton, MdKeyboardArrowDown as ArrowDown } from 'react-icons/md';
import { useSearchContacts } from '@/hooks/useContactSearch';
import { NameEmail } from '@/utils/contacts';
import { Avatar } from '@/components/Avatar';

export const GuestsComposeEmail = ({
  selectedContacts,
  onChange,
  disabled = false,
  placeholder,
}: {
  selectedContacts?: NameEmail[];
  onChange: (contacts: NameEmail[]) => void;
  disabled?: boolean;
  placeholder?: string;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [inputData, setInputData] = useState<string>('');

  const { results: suggestedContacts } = useSearchContacts({
    enabled: isOpen,
    term: inputData,
    search: inputData,
  });

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems: selectedContacts,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          onChange?.(newSelectedItems!);
          break;
        default:
          break;
      }
    },
  });

  const items = useMemo(() => {
    const lowerCasedInputValue = inputData?.toLowerCase();
    if (!suggestedContacts) return;

    return suggestedContacts.filter(
      (p) => !selectedContacts?.includes(p) && p.email.toLowerCase().includes(lowerCasedInputValue)
    );
  }, [selectedContacts, inputData, suggestedContacts]);

  const {
    isOpen: uncontrolledIsOpen, // hack for cyclical reference of isOpen
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: items ?? [],
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    //@ts-ignore
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      const title = state.inputValue;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          return {
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: state.highlightedIndex, // don't change the highlightedIndex after selection.
            selectedItem: { email: title?.trim() },
          };
        }
        case useCombobox.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: state.highlightedIndex, // don't change the highlightedIndex after selection.
          };
        }
        case useCombobox.stateChangeTypes.InputBlur:
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      const newItems = [...(selectedContacts ?? []), newSelectedItem!];
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (newSelectedItem) {
            onChange?.(newItems);
            setInputData('');
          }
          break;
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            onChange?.(newItems);
            setInputData('');
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputData(newInputValue!);
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setOpen(uncontrolledIsOpen);
  }, [uncontrolledIsOpen]);

  return (
    <div className="relative w-full">
      <div className="flex flex-wrap items-center gap-[8px] bg-white rounded-lg mb-2">
        {selectedContacts?.map((selectedItemForRender, index) => {
          return (
            <div
              className="flex items-center my-[2px] px-[4px] py-[2px] text-sm border rounded-full max-w-fit shadow-sm"
              key={selectedItemForRender?.email}
              {...getSelectedItemProps({
                selectedItem: selectedItemForRender,
                index,
              })}>
              <div className="flex items-center">{selectedItemForRender?.name || selectedItemForRender?.email}</div>
              <button
                className="px-1 rounded-full"
                type="button"
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItemForRender);
                }}>
                <RemoveButton color="#D3D3D3" />
              </button>
            </div>
          );
        })}
      </div>
      <div className="relative flex flex-col gap-1">
        <div className="flex gap-[4px] rounded-lg p-[6px] w-full">
          <input
            placeholder={placeholder ?? 'Select Contact'}
            className="w-full text-[14px] min-h-[32px] pl-[11px] font-medium border-none!important border-b text-black placeholder:text-gray-border focus:outline-none"
            data-value={inputData ?? ''}
            {...getInputProps(
              getDropdownProps({ preventKeyAction: isOpen, value: inputData ?? '', key: selectedItem?.id ?? '' }),
              { suppressRefError: true }
            )}
            disabled={disabled}
          />
          <button
            aria-label="toggle menu"
            className="px-[8px]"
            type="button"
            {...getToggleButtonProps()}
            disabled={disabled}>
            <ArrowDown />
          </button>
        </div>
      </div>
      <ul
        className={cn(
          'w-full bg-white mt-[4px] max-h-[320px] overflow-scroll overflow-x-hidden p-0 rounded-lg shadow-lg absolute z-10',
          !(isOpen && items?.length) && 'hidden'
        )}
        {...getMenuProps()}>
        {isOpen &&
          items?.map((item, index) => (
            <li
              className={cn(
                'p-3 flex flex-col overflow-x-hidden',
                highlightedIndex === index && 'bg-gray-200',
                selectedItem === item && 'font-bold'
              )}
              key={`${item.id}-${index}`}
              {...getItemProps({ item, index })}>
              <div className="flex items-center">
                <Avatar
                  picture={item?.picture ?? ''}
                  name={item?.name ?? item?.email}
                  className="mr-2 rounded-full"
                  size={20}
                />
                <div className="flex flex-col">
                  <span className="text-sm capitalize">{item?.name}</span>
                  <span className="text-xs text-gray-700">{item?.email}</span>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};
