type Props = {
  className?: string;
  size?: number;
  color?: string;
  onClick?: () => void;
};

export const FilterIcon = ({ className, size = 24, color = '#A1A1A3', onClick }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      onClick={() => onClick?.()}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M3 15.75C3 15.5511 3.07902 15.3603 3.21967 15.2197C3.36032 15.079 3.55109 15 3.75 15H8.25C8.44891 15 8.63968 15.079 8.78033 15.2197C8.92098 15.3603 9 15.5511 9 15.75C9 15.9489 8.92098 16.1397 8.78033 16.2803C8.63968 16.421 8.44891 16.5 8.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75ZM3 11.25C3 11.0511 3.07902 10.8603 3.21967 10.7197C3.36032 10.579 3.55109 10.5 3.75 10.5H14.25C14.4489 10.5 14.6397 10.579 14.7803 10.7197C14.921 10.8603 15 11.0511 15 11.25C15 11.4489 14.921 11.6397 14.7803 11.7803C14.6397 11.921 14.4489 12 14.25 12H3.75C3.55109 12 3.36032 11.921 3.21967 11.7803C3.07902 11.6397 3 11.4489 3 11.25ZM3 6.75C3 6.55109 3.07902 6.36032 3.21967 6.21967C3.36032 6.07902 3.55109 6 3.75 6H20.25C20.4489 6 20.6397 6.07902 20.7803 6.21967C20.921 6.36032 21 6.55109 21 6.75C21 6.94891 20.921 7.13968 20.7803 7.28033C20.6397 7.42098 20.4489 7.5 20.25 7.5H3.75C3.55109 7.5 3.36032 7.42098 3.21967 7.28033C3.07902 7.13968 3 6.94891 3 6.75Z"
        fill={color}
      />
    </svg>
  );
};
