import moment from 'moment-timezone';
import { atom, useAtom } from 'jotai';
import { DateInterval } from '@/utils/date';
import { useCallback } from 'react';

const globalDateRangeAtom = atom<DateInterval>({
  start: moment().subtract(1, 'month').startOf('week').toDate(),
  end: moment().add(1, 'month').endOf('week').toDate(),
});

export const useGlobalDateRange = () => {
  const [globalDateRange, _setGlobalDateRange] = useAtom(globalDateRangeAtom);

  const setGlobalDateRange = useCallback(
    ({ start, end }: { start?: Date; end?: Date }) => {
      _setGlobalDateRange((prev) => ({
        start: start && start.getTime() < prev.start.getTime() ? start : prev.start,
        end: end && prev.end.getTime() < end.getTime() ? end : prev.end,
      }));
    },
    [_setGlobalDateRange]
  );

  return { globalDateRange, setGlobalDateRange };
};
