import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useTasks } from '@/hooks/useTasks';
import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import useLeftSidebar from '../../LeftSidebar/useLeftSidebar';
import useSearchState from './useSearchState';
import { useSetAtom } from 'jotai';

import { ALL_OPTIONS, ITEM_OPTIONS } from '../constants';
import { iconByType, chatStateAtom } from '../utils';

import type { Event } from '@/models/EventModel';
import type { Task } from '@/models/TaskModel';
import type { Note } from '@/models/NoteModel';
import type { SchedulingLink } from '@/models/SchedulingLinkModel';
import type { SearchOption } from '../types';
import type { LeftSidebarType } from '../../LeftSidebar/useLeftSidebar';
import { editorFor } from '@/components/LeftSidebar';
import { ChatIcon } from '@/icons/ChatIcon';
import { rightSidebarStateAtom } from '@/components/RightSidebar';

export function useSearchItems() {
  const router = useRouter();

  const {
    searchState: { value = '', options, selectedItem, isCreating },
    closeSearch,
    showOptions,
    setSelectedItem,
    setReference,
  } = useSearchState();

  const { openLeftSidebar } = useLeftSidebar();
  const setChatState = useSetAtom(chatStateAtom);
  const setRightSidebarState = useSetAtom(rightSidebarStateAtom);

  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { schedulingLinks } = useSchedulingLinks({});
  const { notes } = useNotes({});

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key !== 'Enter') return;
      showOptions();
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showOptions]);

  const allOptionsWithAction = useMemo(() => {
    return ALL_OPTIONS.map((option) => {
      if (option.id.startsWith('/')) {
        return {
          ...option,
          onSelect: () => {
            void router.push(option.id);
            closeSearch();
          },
        };
      }

      return {
        ...option,
        onSelect: () => {
          openLeftSidebar({ type: option.id as LeftSidebarType });
          closeSearch();
        },
      };
    });
  }, [router, closeSearch, openLeftSidebar]);

  const allItems = useMemo((): SearchOption[] => {
    const _events = (events || []) as Event[];
    const _tasks = (tasks?.results || []) as Task[];
    const _notes = (notes?.results || []) as Note[];
    const _schedulingLinks = (schedulingLinks?.results || []) as SchedulingLink[];

    return [..._events, ..._tasks, ..._notes, ..._schedulingLinks].map((item) => {
      const title = 'summary' in item ? item.summary : item.title;
      return {
        id: item.id,
        title,
        Icon: iconByType[item.itemType],
        onSelect: () => {
          setSelectedItem(item.id);
        },
        category: 'Search result',
      };
    });
  }, [events, tasks?.results, notes?.results, schedulingLinks?.results, setSelectedItem]);

  const itemOptionsWithAction = useMemo(() => {
    return ITEM_OPTIONS.map((option) => {
      return {
        ...option,
        onSelect: () => {
          if (option.id === 'OPEN_PANEL') {
            openLeftSidebar({ type: editorFor(selectedItem!.itemType), context: { id: selectedItem!.id } });
            closeSearch();
            return;
          }

          if (option.id === 'CREATE_REFERENCE_LINK') {
            const name = 'summary' in selectedItem! ? selectedItem.summary : selectedItem!.title;
            setReference({ id: selectedItem!.id, type: selectedItem!.itemType, name });
            setSelectedItem();
            return;
          }

          setSelectedItem(option.id);
        },
      };
    });
  }, [closeSearch, openLeftSidebar, selectedItem, setReference, setSelectedItem]);

  return useMemo(() => {
    const searchValue = value.toLowerCase();

    if (selectedItem) return itemOptionsWithAction;
    if ((!searchValue && !options) || isCreating || searchValue === '/') return [];
    if (!searchValue) return allOptionsWithAction;
    const chatSmarty = {
      id: "chatsmarty", 
      title: "Chat Smarty: '" + searchValue + "'",
      category: "Chat",
      label: "Chat Smarty: '" + searchValue + "'",
      Icon: ChatIcon,
      onSelect: () => {
        setChatState({show: true, value: value});
        setRightSidebarState({show: true, type: 'CHAT'});
        closeSearch();
      }
    };
    
    const items = [chatSmarty, ...allOptionsWithAction, ...allItems];
    return items.filter((item) => item.title.toLowerCase().includes(searchValue));
  }, [value, selectedItem, itemOptionsWithAction, options, isCreating, allOptionsWithAction, allItems, setChatState, setRightSidebarState, closeSearch]);
}
