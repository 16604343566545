export const AsanaIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      viewBox="781.361 0 944.893 873.377">
      <radialGradient
        id="a"
        cx="943.992"
        cy="1221.416"
        r=".663"
        gradientTransform="matrix(944.8934 0 0 -873.3772 -890717.875 1067234.75)"
        gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#ffb900" />
        <stop offset=".6" stopColor="#f95d8f" />
        <stop offset=".999" stopColor="#f95353" />
      </radialGradient>
      <path
        fill="url(#a)"
        d="M1520.766 462.371c-113.508 0-205.508 92-205.508 205.488 0 113.499 92 205.518 205.508 205.518 113.489 0 205.488-92.019 205.488-205.518 0-113.488-91.999-205.488-205.488-205.488zm-533.907.01c-113.489.01-205.498 91.99-205.498 205.488 0 113.489 92.009 205.498 205.498 205.498 113.498 0 205.508-92.009 205.508-205.498 0-113.499-92.01-205.488-205.518-205.488h.01zm472.447-256.883c0 113.489-91.999 205.518-205.488 205.518-113.508 0-205.508-92.029-205.508-205.518S1140.31 0 1253.817 0c113.489 0 205.479 92.009 205.479 205.498h.01z"
      />
    </svg>
  );
};

export const ClickUpIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="ClickUp"
      className={className}
      data-testid="cu-logo">
      <defs>
        <linearGradient
          id="clickupLogoBottom"
          x1="5.61511"
          y1="31.179"
          x2="32.7893"
          y2="31.179"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8930FD"></stop>
          <stop offset="1" stopColor="#49CCF9"></stop>
        </linearGradient>
        <linearGradient
          id="clickupLogoTop"
          x1="5.94116"
          y1="13.0864"
          x2="31.9781"
          y2="13.0864"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF02F0"></stop>
          <stop offset="1" stopColor="#FFC800"></stop>
        </linearGradient>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.61511 27.2592L10.6296 23.4086C13.2937 26.894 16.1242 28.5006 19.2749 28.5006C22.4086 28.5006 25.1591 26.913 27.7032 23.455L32.7893 27.2128C29.1185 32.1993 24.5568 34.8339 19.2749 34.8339C14.0098 34.8339 9.40387 32.2161 5.61511 27.2592Z"
        fill="url(#clickupLogoBottom)"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9923 10.5228L10.0669 18.2325L5.94116 13.4361L19.0112 2.14587L31.9781 13.4445L27.8334 18.2241L18.9923 10.5228Z"
        fill="url(#clickupLogoTop)"></path>
    </svg>
  );
};

export const GoogleContactIcons = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      version="1.1"
      id="logo_x5F_contacts_x5F_192px_1_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500">
      <g id="art_layer">
        <circle id="bg_circle" fill="#1A73E8" cx="250" cy="250" r="250" />
        <path
          id="body_shadow"
          fill="#185ABC"
          d="M332.4,375H170.5c-19.6,0-36.9-11.8-36.9-31.2V358c0,19.4,17.3,34.1,36.9,34.1h161.9
		c19.6,0,36.9-14.7,36.9-34.1v-14.2C369.3,363.2,352,375,332.4,375z"
        />
        <path
          id="head_shadow"
          fill="#185ABC"
          d="M250,227.3c-29.3-0.1-52.4-20.4-54-48.3v11.4c0,28.9,24.2,54,54,54s54-25.1,54-54V179
		C301.7,207.1,279.3,227.4,250,227.3z"
        />
        <g id="subject">
          <path
            id="body"
            fill="#FFFFFF"
            d="M250,261.4c-57,0-116.5,27.8-116.5,65.3v17c0,19.4,15.9,34.1,35.5,34.1h164.7
			c19.6,0,35.5-14.7,35.5-34.1v-17C369.3,289.1,307,261.4,250,261.4z"
          />
          <circle id="head" fill="#FFFFFF" cx="250" cy="176.1" r="54" />
        </g>
      </g>
    </svg>
  );
};

export const JiraIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      viewBox="2.59 0 214.09101008 224"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}>
      <linearGradient
        id="a"
        gradientTransform="matrix(1 0 0 -1 0 264)"
        gradientUnits="userSpaceOnUse"
        x1="102.4"
        x2="56.15"
        y1="218.63"
        y2="172.39">
        <stop offset=".18" stopColor="#0052cc" />
        <stop offset="1" stopColor="#2684ff" />
      </linearGradient>
      <linearGradient id="b" x1="114.65" x2="160.81" y1="85.77" y2="131.92" />
      <path
        d="m214.06 105.73-96.39-96.39-9.34-9.34-72.56 72.56-33.18 33.17a8.89 8.89 0 0 0 0 12.54l66.29 66.29 39.45 39.44 72.55-72.56 1.13-1.12 32.05-32a8.87 8.87 0 0 0 0-12.59zm-105.73 39.39-33.12-33.12 33.12-33.12 33.11 33.12z"
        fill="#2684ff"
      />
      <path d="m108.33 78.88a55.75 55.75 0 0 1 -.24-78.61l-72.47 72.44 39.44 39.44z" fill="url(#a)" />
      <path d="m141.53 111.91-33.2 33.21a55.77 55.77 0 0 1 0 78.86l72.67-72.63z" fill="url(#b)" />
    </svg>
  );
};

export const LinearIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          d="M3.03509 12.9431C3.24245 14.9227 4.10472 16.8468 5.62188 18.364C7.13904 19.8811 9.0631 20.7434 11.0428 20.9508L3.03509 12.9431Z"
          fill="#5f6dcb"
        />
        <path
          d="M3 11.4938L12.4921 20.9858C13.2976 20.9407 14.0981 20.7879 14.8704 20.5273L3.4585 9.11548C3.19793 9.88771 3.0451 10.6883 3 11.4938Z"
          fill="#5f6dcb"
        />
        <path
          d="M3.86722 8.10999L15.8758 20.1186C16.4988 19.8201 17.0946 19.4458 17.6493 18.9956L4.99021 6.33659C4.54006 6.89125 4.16573 7.487 3.86722 8.10999Z"
          fill="#5f6dcb"
        />
        <path
          d="M5.66301 5.59517C9.18091 2.12137 14.8488 2.135 18.3498 5.63604C21.8508 9.13708 21.8645 14.8049 18.3907 18.3228L5.66301 5.59517Z"
          fill="#5f6dcb"
        />
      </g>
    </svg>
  );
};

export const NotionIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.017 4.313l55.333 -4.087c6.797 -0.583 8.543 -0.19 12.817 2.917l17.663 12.443c2.913 2.14 3.883 2.723 3.883 5.053v68.243c0 4.277 -1.553 6.807 -6.99 7.193L24.467 99.967c-4.08 0.193 -6.023 -0.39 -8.16 -3.113L3.3 79.94c-2.333 -3.113 -3.3 -5.443 -3.3 -8.167V11.113c0 -3.497 1.553 -6.413 6.017 -6.8z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.35 0.227l-55.333 4.087C1.553 4.7 0 7.617 0 11.113v60.66c0 2.723 0.967 5.053 3.3 8.167l13.007 16.913c2.137 2.723 4.08 3.307 8.16 3.113l64.257 -3.89c5.433 -0.387 6.99 -2.917 6.99 -7.193V20.64c0 -2.21 -0.873 -2.847 -3.443 -4.733L74.167 3.143c-4.273 -3.107 -6.02 -3.5 -12.817 -2.917zM25.92 19.523c-5.247 0.353 -6.437 0.433 -9.417 -1.99L8.927 11.507c-0.77 -0.78 -0.383 -1.753 1.557 -1.947l53.193 -3.887c4.467 -0.39 6.793 1.167 8.54 2.527l9.123 6.61c0.39 0.197 1.36 1.36 0.193 1.36l-54.933 3.307 -0.68 0.047zM19.803 88.3V30.367c0 -2.53 0.777 -3.697 3.103 -3.893L86 22.78c2.14 -0.193 3.107 1.167 3.107 3.693v57.547c0 2.53 -0.39 4.67 -3.883 4.863l-60.377 3.5c-3.493 0.193 -5.043 -0.97 -5.043 -4.083zm59.6 -54.827c0.387 1.75 0 3.5 -1.75 3.7l-2.91 0.577v42.773c-2.527 1.36 -4.853 2.137 -6.797 2.137 -3.107 0 -3.883 -0.973 -6.21 -3.887l-19.03 -29.94v28.967l6.02 1.363s0 3.5 -4.857 3.5l-13.39 0.777c-0.39 -0.78 0 -2.723 1.357 -3.11l3.497 -0.97v-38.3L30.48 40.667c-0.39 -1.75 0.58 -4.277 3.3 -4.473l14.367 -0.967 19.8 30.327v-26.83l-5.047 -0.58c-0.39 -2.143 1.163 -3.7 3.103 -3.89l13.4 -0.78z"
        fill="#000"
      />
    </svg>
  );
};

export const GithubIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
        transform="scale(64)"
        fill="#1B1F23"
      />
    </svg>
  );
};

export const SlackIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m53.8412698 161.320635c0 14.831746-11.9873015 26.819048-26.8190476 26.819048-14.831746 0-26.8190476-11.987302-26.8190476-26.819048s11.9873016-26.819048 26.8190476-26.819048h26.8190476zm13.4095239 0c0-14.831746 11.9873015-26.819048 26.8190476-26.819048 14.8317457 0 26.8190477 11.987302 26.8190477 26.819048v67.047619c0 14.831746-11.987302 26.819048-26.8190477 26.819048-14.8317461 0-26.8190476-11.987302-26.8190476-26.819048z"
        fill="#e01e5a"
      />
      <path
        d="m94.0698413 53.6380952c-14.8317461 0-26.8190476-11.9873015-26.8190476-26.8190476 0-14.831746 11.9873015-26.8190476 26.8190476-26.8190476 14.8317457 0 26.8190477 11.9873016 26.8190477 26.8190476v26.8190476zm0 13.6126985c14.8317457 0 26.8190477 11.9873015 26.8190477 26.8190476 0 14.8317457-11.987302 26.8190477-26.8190477 26.8190477h-67.2507937c-14.831746 0-26.8190476-11.987302-26.8190476-26.8190477 0-14.8317461 11.9873016-26.8190476 26.8190476-26.8190476z"
        fill="#36c5f0"
      />
      <path
        d="m201.549206 94.0698413c0-14.8317461 11.987302-26.8190476 26.819048-26.8190476s26.819048 11.9873015 26.819048 26.8190476c0 14.8317457-11.987302 26.8190477-26.819048 26.8190477h-26.819048zm-13.409523 0c0 14.8317457-11.987302 26.8190477-26.819048 26.8190477s-26.819048-11.987302-26.819048-26.8190477v-67.2507937c0-14.831746 11.987302-26.8190476 26.819048-26.8190476s26.819048 11.9873016 26.819048 26.8190476z"
        fill="#2eb67d"
      />
      <path
        d="m161.320635 201.549206c14.831746 0 26.819048 11.987302 26.819048 26.819048s-11.987302 26.819048-26.819048 26.819048-26.819048-11.987302-26.819048-26.819048v-26.819048zm0-13.409523c-14.831746 0-26.819048-11.987302-26.819048-26.819048s11.987302-26.819048 26.819048-26.819048h67.250794c14.831746 0 26.819047 11.987302 26.819047 26.819048s-11.987301 26.819048-26.819047 26.819048z"
        fill="#ecb22e"
      />
    </svg>
  );
};

export const TodoistIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m224.001997 0h-192.0039944c-17.6400645.03949644-31.93652166 14.336846-31.9980026 32v192c0 17.6 14.3971038 32 31.9980026 32h192.0039944c17.600899 0 31.998003-14.4 31.998003-32v-192c0-17.6-14.397104-32-31.998003-32"
        fill="#e44332"
      />
      <g fill="#fff">
        <path d="m54.132778 120.802491c4.4632444-2.606216 100.343297-58.32504 102.535069-59.6161929 2.191772-1.2752126 2.311323-5.1964916-.159401-6.6151657-2.454785-1.410704-7.117281-4.0886507-8.846788-5.1167909-2.469416-1.3585708-5.470489-1.3222675-7.906319.095641-1.227392.7173071-83.1518364 48.29868-85.8855736 49.8608156-3.2916427 1.8809389-7.3324729 1.9128189-10.6002053 0l-43.2695601-25.3926724v21.5829745c10.5205046 6.2007218 36.7181549 21.5989148 43.062338 25.2253008 3.7857876 2.151921 7.4121737 2.104101 11.0784101-.02391" />
        <path d="m54.132778 161.609296c4.4632444-2.606216 100.343297-58.325039 102.535069-59.616192 2.191772-1.275213 2.311323-5.1964919-.159401-6.615166-2.454785-1.4107041-7.117281-4.0886507-8.846788-5.1167909-2.469416-1.3585708-5.470489-1.3222675-7.906319.095641-1.227392.7173071-83.1518364 48.2986799-85.8855736 49.8608159-3.2916427 1.880938-7.3324729 1.912819-10.6002053 0l-43.2695601-25.392673v21.582975c10.5205046 6.200721 36.7181549 21.598914 43.062338 25.2253 3.7857876 2.151922 7.4121737 2.104101 11.0784101-.02391" />
        <path d="m54.132778 204.966527c4.4632444-2.606216 100.343297-58.32504 102.535069-59.616192 2.191772-1.275213 2.311323-5.196492-.159401-6.615166-2.454785-1.410704-7.117281-4.088651-8.846788-5.116791-2.469416-1.358571-5.470489-1.322268-7.906319.095641-1.227392.717307-83.1518364 48.29868-85.8855736 49.860816-3.2916427 1.880938-7.3324729 1.912819-10.6002053 0l-43.2695601-25.392673v21.582975c10.5205046 6.200721 36.7181549 21.598914 43.062338 25.2253 3.7857876 2.151922 7.4121737 2.104101 11.0784101-.02391" />
      </g>
    </svg>
  );
};

export const TrelloIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      fill="#fff"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg">
      <rect fill="#0079bf" height="512" rx="15%" width="512" />
      <rect height="188" rx="23" width="132" x="284" y="95" />
      <rect height="296" rx="23" width="132" x="97" y="95" />
    </svg>
  );
};

export const ZoomIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.667 50.667">
      <path
        d="M25.333 50.667c13.992 0 25.334-11.343 25.334-25.334S39.325 0 25.333 0 0 11.342 0 25.333s11.342 25.334 25.333 25.334z"
        fill="#2196f3"
      />
      <path
        clipRule="evenodd"
        d="M14.866 32.574h16.755V20.288c0-1.851-1.5-3.351-3.351-3.351H11.515v12.286c0 1.851 1.5 3.351 3.351 3.351zm18.988-4.467l6.702 4.467V16.937l-6.701 4.468z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const GmailIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 92 92"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m21.6354 66h8.4828v-20.6008l-12.1182-9.0887v26.0541c0 2.0116 1.6299 3.6354 3.6354 3.6354z"
        fill="#4285f4"
      />
      <path d="m59.2031 66h8.4827c2.0117 0 3.6355-1.6298 3.6355-3.6354v-26.0541l-12.1182 9.0887" fill="#34a853" />
      <path d="m59.2031 29.6458v15.7536l12.1182-9.0886v-4.8473c0-4.4959-5.1321-7.0588-8.7251-4.3626" fill="#fbbc04" />
      <path d="m30.1172 45.3991v-15.7536l14.5418 10.9064 14.5418-10.9064v15.7536l-14.5418 10.9064" fill="#ea4335" />
      <path
        d="m18 31.4635v4.8473l12.1182 9.0886v-15.7536l-3.3931-2.5449c-3.5991-2.6962-8.7251-.1333-8.7251 4.3626z"
        fill="#c5221f"
      />
    </svg>
  );
};
