import { FC, ReactNode, useMemo } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { MdKeyboardArrowDown as ArrowDownIcon } from 'react-icons/md';
import { cn } from 'ui/cn';

type SelectProps<T> = {
  id?: string;
  name?: string;
  value?: T;
  options: T[];
  placeholder?: ReactNode;
  label?: string;
  CustomTrigger?: FC<{ children: ReactNode }>;
  className?: string;
  disabled?: boolean;
  onChange?: (value: T) => void;
  extractLabel?: (option: T) => ReactNode;
  extractValue?: (option: T) => string;
  position?: 'item-aligned' | 'popper';
  contentClassName?: string;
  classNameOption?: string;
  classNameValue?: string;
  dark?: boolean;
};

export const Select = <T,>({
  id,
  name,
  value,
  options,
  placeholder,
  label,
  CustomTrigger,
  className,
  disabled,
  onChange,
  extractLabel = (option) => option as string,
  extractValue = (option) => option as string,
  position = 'item-aligned',
  contentClassName,
  classNameOption,
  classNameValue,
  dark,
}: SelectProps<T>) => {
  const handleChange = (selectedValue: string): void => {
    onChange?.(options.find((option) => extractValue(option) === selectedValue)!);
  };

  const contentClass = useMemo(
    () => cn('z-30 w-full bg-white border rounded-lg', contentClassName),
    [contentClassName]
  );

  return (
    <div id={id} className={`relative ${className}`}>
      <div
        className={cn(
          dark ? 'bg-[#111317] text-white' : 'bg-[#FFFFFF] text-gray-border',
          'text-[8px] leading-[12px] absolute -top-1.5 px-1 rounded-lg left-3.5'
        )}>
        {label}
      </div>
      <SelectPrimitive.Root
        disabled={disabled}
        value={value ? extractValue(value) : ''}
        onValueChange={handleChange}
        name={name}>
        {!CustomTrigger ? (
          <SelectPrimitive.SelectTrigger
            className={cn(
              'flex items-center justify-between px-4 h-[46px] gap-[5px] focus:border-primary-500 rounded-lg w-full outline-none placeholder-shown:text-secondary-gray-2 select-none text-sm',
              dark ? 'bg-[#111317] text-white border' : 'bg-[#FFFFFF] border',
              classNameValue,
            )}>
            <SelectPrimitive.Value placeholder={placeholder}>
              {value ? extractLabel(value) : null}
            </SelectPrimitive.Value>
            <SelectPrimitive.Icon>
              <ArrowDownIcon color={dark ? 'white' : 'black'} width={9.26} height={4.42} />
            </SelectPrimitive.Icon>
          </SelectPrimitive.SelectTrigger>
        ) : (
          <SelectPrimitive.Trigger className="w-full">
            <CustomTrigger>
              <SelectPrimitive.Value placeholder={placeholder}>
                {value ? extractLabel(value) : null}
              </SelectPrimitive.Value>
            </CustomTrigger>
          </SelectPrimitive.Trigger>
        )}
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content position={position} className={contentClass}>
            <SelectPrimitive.Viewport className="p-[2.5px]" contextMenu="px-[5px]">
              {options?.map((option: T) => {
                const optionLabel = extractLabel(option);
                const optionValue = extractValue(option);
                return (
                  <SelectPrimitive.Item
                    key={optionValue}
                    value={optionValue}
                    className={cn(
                      'h-[40px] flex outline-none items-center px-2.5 cursor-pointer select-none text-sm',
                      classNameOption,
                      dark
                        ? 'bg-[#111317] focus:bg-[#08080810] focus:text-black text-white'
                        : 'bg-[#FFFFFF] focus:bg-[#00000010]'
                    )}>
                    <SelectPrimitive.ItemText>{optionLabel}</SelectPrimitive.ItemText>
                  </SelectPrimitive.Item>
                );
              })}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
};
