import { useCallback } from 'react';
import { DateObject, DatePicker } from '@/components/DatePicker';
import Switch from '@/components/Switch';
import { useSettings } from '@/hooks/useSettings';
import moment from 'moment-timezone';
import { getOccurrencesOfMonth, getWeekdayByTypeAndDate, RecurringSelect } from '@/components/RecurringSelect';
import { deleteDTSTART, rightFrequency } from '@/utils/recurrenceUtils';
import { Options, RRule } from 'rrule';
import { RecurrenceType, TaskScheduleType } from '@prisma/client';
import { Divider } from '@/components/Divider';

type Props = {
  start?: Date | null;
  end?: Date | null;
  allDay?: boolean;
  disabled?: boolean;
  recurringTaskId?: string;
  onChange: (value: {
    start?: Date;
    end?: Date;
    allDay?: boolean;
    recurrence?: string[];
    recurrenceType?: RecurrenceType;
    recurringTaskId?: string;
    taskScheduleType?: TaskScheduleType;
  }) => void;
  recurrenceType?: RecurrenceType;
};

export default function Schedule({ start, end, allDay, disabled, recurrenceType, recurringTaskId, onChange }: Props) {
  const { defaultDuration, defaultTimeZone: timeZone } = useSettings();

  const handleChangeDate = useCallback(
    (key: 'start' | 'end') => (value: DateObject) => {
      let date = value.toDate();
      if (allDay) {
        date = moment.tz(date, timeZone).startOf('day').toDate();
      }
      const updatedValues: any = { [key]: date, taskScheduleType: TaskScheduleType.SPECIFIC };

      if (key === 'start') {
        let originalDuration = moment(end).diff(moment(start), 'minutes');
        if (originalDuration < 10) {
          originalDuration = defaultDuration;
        }
        const endDate = moment(date).add(originalDuration, 'minutes').toDate();
        updatedValues.end = endDate;
      }

      onChange(updatedValues);
    },
    [allDay, defaultDuration, end, onChange, start, timeZone]
  );

  const handleIsAllDayChange = useCallback(
    (value: boolean) => {
      const _start = moment.tz(start, timeZone).startOf('day').toDate();
      const _end = moment.tz(_start, timeZone).add(1, 'day').toDate();

      onChange({
        start: _start,
        end: _end,
        allDay: value,
      });
    },
    [onChange, start, timeZone]
  );

  const handleRecurrenceChange = useCallback(
    (value: string) => {
      if (value === 'DOES_NOT_REPEAT' || value === 'CUSTOM') {
        onChange({ recurrenceType: value as RecurrenceType, recurrence: [] });
        return;
      }

      const options = {
        freq: rightFrequency(value),
        interval: 1,
        dtstart: start!,
        until: null,
        count: null,
        byweekday: getWeekdayByTypeAndDate(value as any, start!),
      } as Options;

      if (value === 'MONTHLY') {
        options.bysetpos = getOccurrencesOfMonth(start!);
      }

      const rule = new RRule(options);
      const rruleStringWithoutDTSTART = deleteDTSTART(rule.toString());

      onChange({
        recurrence: [rruleStringWithoutDTSTART],
        recurrenceType: value as RecurrenceType,
        recurringTaskId: recurringTaskId,
        taskScheduleType: TaskScheduleType.RECURRING,
      });
    },
    [onChange, start, recurringTaskId]
  );

  return (
    <section className="flex flex-col gap-3 py-5 border-t border-gray-border">
      <DatePicker
        label="Start"
        className="xs:flex-col lg:flex-row"
        showTime={!allDay}
        disabled={disabled}
        value={start}
        onChange={handleChangeDate('start')}
      />
      <div className="sm:hidden lg:hidden">
        <Divider />
      </div>
      <DatePicker
        label="End"
        className="xs:flex-col lg:flex-row"
        showTime={!allDay}
        disabled={disabled}
        value={end}
        minDate={start ?? new Date()}
        onChange={handleChangeDate('end')}
      />
      <Switch label="All day?" disabled={disabled} value={!!allDay} onChange={handleIsAllDayChange} />
      <RecurringSelect
        date={start || null}
        recurrenceType={recurrenceType || 'DOES_NOT_REPEAT'}
        onChange={handleRecurrenceChange}
        timeZone={timeZone}
      />
    </section>
  );
}
