import { ScheduleInterval } from '@prisma/client';
import moment from 'moment-timezone';
import { Select } from '@/components/Select';
import { Availability } from '@/models/AvailabilityModel';
import { DeleteIcon } from '@/icons/DeleteIcon';

type Props = {
  schedule: Availability;
  intervals: { interval: Pick<ScheduleInterval, 'dayOfWeek' | 'startTime' | 'endTime'>; originalIndex: number }[];
  hourSelectOptions: string[];
  onDateChange: (value: string, intervalIndex: number, field: string) => void;
  onDeleteInterval: (deletedIndex: number) => void;
  getFilteredHoursSelectedOptions: (dayOfWeek: number) => string[];
};

export default function Intervals({
  schedule,
  intervals,
  hourSelectOptions,
  onDateChange,
  onDeleteInterval,
  getFilteredHoursSelectedOptions,
}: Props) {
  const startOfDay = moment.utc().startOf('day');
  const formatMinutes = (minutes: number) => {
    if (minutes === 1440) {
      return 'Midnight'; // if we check for military, then we use '24:00';
    } else {
      const time = moment.utc(startOfDay).add(minutes, 'minutes');
      return time.format('hh:mm A');
    }
  };
  return (
    <div className="flex flex-col items-center">
      {intervals.map((interval) => {
        const start = formatMinutes(interval.interval.startTime);
        const end = formatMinutes(interval.interval.endTime);
        const selectDateStyle = 'w-[183px] h-[44px]';

        return (
          <div className="flex flex-row items-center gap-2" key={interval.originalIndex}>
            <div className="flex flex-row justify-between gap-3 my-2">
              <Select
                options={hourSelectOptions}
                value={start}
                label="Start time"
                extractLabel={(option) => option}
                extractValue={(option) => option}
                className={selectDateStyle}
                name={schedule.intervals?.[interval.originalIndex]?.startTime.toString() || ''}
                onChange={(value) => onDateChange(value, interval.originalIndex, 'startTime')}
              />
              <Select
                options={getFilteredHoursSelectedOptions(interval.interval.dayOfWeek)}
                value={end}
                label="End time"
                extractLabel={(option) => option}
                extractValue={(option) => option}
                className={selectDateStyle}
                name={schedule.intervals?.[interval.originalIndex]?.endTime.toString() || ''}
                onChange={(value) => onDateChange(value, interval.originalIndex, 'endTime')}
              />
            </div>
            <div onClick={() => onDeleteInterval(interval.originalIndex)}>
              <DeleteIcon className="text-gray-400 hover:cursor-pointer hover:fill-red-500" />
            </div>
          </div>
        );
      })}
    </div>
  );
}
