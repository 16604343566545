import { useEffect } from 'react';

import { useTasks } from '@/hooks/useTasks';
import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';

import useLeftSidebar from '../../LeftSidebar/useLeftSidebar';
import useSearchState from './useSearchState';
import { useGetEmailThread } from '@/hooks/useEmails';

const TYPE_BY_SIDEBAR = {
  TASK_EDITOR: 'TASK',
  EVENT_EDITOR: 'EVENT',
  NOTE_EDITOR: 'NOTE',
  EMAIL_DETAIL: 'EMAIL',
} as const;

export default function useReference() {
  const { leftSidebarState } = useLeftSidebar();
  const { setReference } = useSearchState();

  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { notes } = useNotes();
  const getEmailThread = useGetEmailThread();

  useEffect(() => {
    async function fetchReference() {
      const sidebarType = leftSidebarState.type || '';
      const id = leftSidebarState.context?.id || leftSidebarState.context?.threadId;

      if (!id || !(sidebarType in TYPE_BY_SIDEBAR)) {
        setReference(null);
        return;
      }

      const type = TYPE_BY_SIDEBAR[sidebarType as keyof typeof TYPE_BY_SIDEBAR];

      let name: string | undefined;
      switch (type) {
        case 'TASK':
          name = tasks?.results.find((task) => task.id === id)?.summary;
          break;
        case 'EVENT':
          name = events?.find((event) => event.id === id)?.summary;
          break;
        case 'NOTE':
          name = notes?.results.find((note) => note.id === id)?.title;
          break;
        case 'EMAIL': {
          setReference({ id, type, name: 'loading...' });
          const email = (await getEmailThread(id)).messages.at(-1)!;

          name = email.headers.subject;
          break;
        }
      }

      if (!name) {
        setReference(null);
        return;
      }

      setReference({ id, type, name });
    }

    fetchReference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftSidebarState.context?.id, leftSidebarState.type]);
}
