import { memo, useEffect, useRef } from 'react';
import { EditorContent, EditorOptions, JSONContent, useEditor } from '@tiptap/react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { History } from '@tiptap/extension-history';
import { Text } from '@tiptap/extension-text';
import { Droppable } from 'react-beautiful-dnd';
import type { Editor as EditorType } from '@tiptap/core';

import { createDroppableId, DroppableType } from '@/utils/dnd';
import { SmartyItemType } from '@/hooks/useSmartyItems';

import useEditorParser from './hooks/useEditorParser';
import useGuestsNotificationModal from './hooks/useGuestsNotificationModal';
import useStateManager from './hooks/useStateManager';
import useDndHandler from './hooks/useDndHandler';

import ReadOnlyHandler from './extensions/ReadOnlyHandler';
import useCommands from './extensions/commands/useCommands';

import useSlashSuggestions from './nodes/suggestions/useSlashSuggestions';
import useAtSuggestions from './nodes/suggestions/useAtSuggestions';
import useHashtagSuggestions from './nodes/suggestions/useHashtagSuggestions';
import useEditorItem from './nodes/EditorItem';

import Collapsible from './nodes/Collapsible';
import Details from './nodes/Collapsible/Details';
import Summary from './nodes/Collapsible/Summary';
import Document from './nodes/Document';

import { cn } from 'ui/cn';
import { ItemType } from './types';

type Props = {
  items?: SmartyItemType[];
  date?: string;
  disableNewLine?: boolean;
  preventSave?: boolean;
  onUpdate?: (editor: EditorType) => void;
  onSubmit?: (editor: EditorType) => void;
  disableDnd?: boolean;
  disableSlash?: boolean;
  hideIcon?: boolean;
  hideButtons?: boolean;
  allowedTypes?: ItemType[];
  onlyCreate?: boolean;
  defaultItemType?: ItemType | null;
  autofocus?: EditorOptions['autofocus'];
  className?: string;
  onboarding?: boolean;
};

const defaultItems: SmartyItemType[] = [];
const defaultAllowedTypes = [ItemType.TASK, ItemType.EVENT, ItemType.NOTE, ItemType.SCHEDULING_LINK];

function Editor({
  items = defaultItems,
  disableNewLine,
  date,
  preventSave,
  onUpdate,
  onSubmit,
  disableDnd,
  disableSlash,
  hideIcon,
  hideButtons,
  allowedTypes = defaultAllowedTypes,
  onlyCreate = false,
  defaultItemType = ItemType.TASK,
  autofocus,
  className,
  onboarding = false,
}: Props) {
  const prevStateRef = useRef<JSONContent | null>(null);
  const { guestNotificationModal, showNotificationModal } = useGuestsNotificationModal();

  const content = useEditorParser(items, defaultItemType);

  const SlashSuggestions = useSlashSuggestions(allowedTypes);
  const AtSuggestions = useAtSuggestions();
  const HashtagSuggestions = useHashtagSuggestions();
  const Commands = useCommands({ content, items, prevStateRef, showNotificationModal, preventSave });
  const EditorItem = useEditorItem(defaultItemType, onSubmit, onboarding);

  const editor = useEditor({
    extensions: [
      Document,
      Text,
      EditorItem,
      !disableSlash ? SlashSuggestions : null,
      AtSuggestions,
      HashtagSuggestions,
      Placeholder.configure({ includeChildren: true }),
      Collapsible,
      Details,
      Summary,
      ReadOnlyHandler,
      Commands,
      History,
    ],
    autofocus,
    content: { type: 'doc', content, attrs: { date, disableNewLine, onlyCreate } },
  });

  useStateManager({ prevStateRef, content, editor });
  useDndHandler(editor);

  useEffect(() => {
    function handleOnUpdate({ editor }: { editor: EditorType }) {
      onUpdate?.(editor);
    }

    if (onUpdate) {
      editor?.on('update', handleOnUpdate);
    }

    return () => {
      editor?.off('update', handleOnUpdate);
    };
  }, [editor, onUpdate]);

  useEffect(() => {
    if (autofocus && editor) {
      editor.commands.focus();
    }
  }, [autofocus, editor]);

  if (!onlyCreate && !items.length) {
    return null;
  }

  return (
    <>
      {guestNotificationModal}
      <Droppable droppableId={createDroppableId(DroppableType.LIST, date || 'unscheduled')}>
        {(provided) => (
          <div className="w-full" ref={provided.innerRef} {...provided.droppableProps}>
            <EditorContent
              className={cn(
                '[&>*]:!outline-none text-sm',
                disableDnd && '[&_.draggable-icon]:hidden',
                hideIcon && '[&_.item-icon]:hidden',
                hideButtons && '[&_.item-buttons]:hidden',
                'text-black',
                className
              )}
              editor={editor}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}

export default memo(Editor);
