import { Availability } from '@/models/AvailabilityModel';
import { hoursSelectOptions } from '@/utils/date';
import { ScheduleInterval } from '@prisma/client';
import moment from 'moment-timezone';
import { memo, useCallback, useMemo } from 'react';
import { ParagraphText } from '../Typography';
import { MdAdd } from 'react-icons/md';
import Intervals from './Intervals';

type Props = {
  schedule: Availability;
  onChange: (schedule: Availability) => void;
  defaultDuration: number;
};

const Day = ({ schedule, onChange, defaultDuration }: Props) => {
  // Root of Data Structure
  type IntervalEntry = {
  dayOfWeek: number;
  intervals: {
    interval: Pick<ScheduleInterval, 'dayOfWeek' | 'startTime' | 'endTime'>;
    originalIndex: number;
  }[];
};

  const intervalsByDayOfWeek = useMemo(() => {
    // Initialize the accumulator with an entry for each day of the week
    const initialAccValue: IntervalEntry[] = Array.from({ length: 7 }).map((_, index) => ({
      dayOfWeek: index,
      intervals: [],
    }));

    const intervals = (schedule.intervals ?? []).reduce<IntervalEntry[]>((acc, interval, currentIndex) => {
      const dayEntry = acc.find((entry) => entry.dayOfWeek === interval.dayOfWeek % 7);
      if (!dayEntry) {
        throw new Error(`Day entry for dayOfWeek ${interval.dayOfWeek} not found in accumulator`);
      }

      dayEntry.intervals.push({
        interval,
        originalIndex: currentIndex,
      });

      return acc;
    }, initialAccValue);
    intervals.forEach((dayEntry) => {
      dayEntry.intervals.sort((a, b) => a.interval.startTime - b.interval.startTime);
    });

    return intervals;
  }, [schedule.intervals]);

  const hourSelectOptions = useMemo(() => hoursSelectOptions(defaultDuration, false), [defaultDuration]);

  const getFilteredHoursSelectedOptions = useCallback(
    (dayOfWeek: number) => {
      const selectedHours = intervalsByDayOfWeek
        ?.find((aaa) => aaa.dayOfWeek === dayOfWeek)
        ?.intervals?.map((interval) => {
          const start = moment().startOf('day').add(interval.interval.startTime, 'minutes').format('HH:mm:ss');
          return { value: start, label: start };
        });
      return hourSelectOptions.filter(
        (option) => !selectedHours?.find((selectedHour) => selectedHour.value === option)
      );
    },
    [hourSelectOptions, intervalsByDayOfWeek]
  );

  const handleOnDeleteInterval = useCallback(
    (deletedIndex: number) => {
      const filteredIntervals = schedule?.intervals?.filter((_, index) => index !== deletedIndex);
      const newSchedule = {
        ...schedule,
        intervals: filteredIntervals,
      };

      onChange(newSchedule);
    },

    [onChange, schedule]
  );

  const handleOnAddIntervals = useCallback(
    (dayOfWeek: number) => () => {
      const newIntervals = [
        ...schedule.intervals,
        {
          dayOfWeek,
          startTime: 9 * 60,
          endTime: 17 * 60,
        },
      ];
      const newSchedule = {
        ...schedule,
        intervals: newIntervals,
      };

      onChange(newSchedule);
    },
    [onChange, schedule]
  );

  const handleOnDateChange = useCallback(
    (value: string, intervalIndex: number, field: string) => {
      let minutesFromStartOfDay;
      if (value === 'Midnight') {
        minutesFromStartOfDay = 24 * 60;
      } else {
        const formattedValue = moment.utc(value, 'HH:mm A');
        const startOfDay = moment.utc(formattedValue).startOf('day');
        minutesFromStartOfDay = formattedValue.diff(startOfDay, 'minutes');
      }

      const newIntervals = [...(schedule.intervals || [])];
      newIntervals[intervalIndex] = {
        ...(newIntervals[intervalIndex] || {}),
        [field]: minutesFromStartOfDay,
      };

      const newSchedule = {
        ...schedule,
        intervals: newIntervals,
      };

      onChange(newSchedule);
    },
    [schedule, onChange]
  );

  const rows = useMemo(
    () =>
      Array.from({ length: 7 }).map((_, index) => {
        const dayIndex = index % 7;
        const intervals = intervalsByDayOfWeek?.find((entry) => entry.dayOfWeek === dayIndex)?.intervals;

        return (
          <div key={index} className="flex flex-row">
            <div>
              <div className="mt-5 text-sm w-28">{moment.weekdays((index + 1) % 7)}</div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-3">
              {!intervals?.length ? (
                <ParagraphText className="my-4 mt-5 text-sm text-gray-300">Unavailable</ParagraphText>
              ) : (
                <Intervals
                  intervals={intervals}
                  getFilteredHoursSelectedOptions={getFilteredHoursSelectedOptions}
                  hourSelectOptions={hourSelectOptions}
                  onDateChange={handleOnDateChange}
                  onDeleteInterval={handleOnDeleteInterval}
                  schedule={schedule}
                />
              )}
              <div className="gap-2">
                <MdAdd className="hover:cursor-pointer" onClick={handleOnAddIntervals(index)} />
              </div>
            </div>
          </div>
        );
      }),
    [
      getFilteredHoursSelectedOptions,
      handleOnAddIntervals,
      handleOnDateChange,
      handleOnDeleteInterval,
      hourSelectOptions,
      intervalsByDayOfWeek,
      schedule,
    ]
  );

  return <>{rows}</>;
};

export default memo(Day);
