import { RRule } from 'rrule';
import { parseDate } from 'chrono-node';

const RECURRENCE_STING_REGEX = /(?:ev(?:ery)?)|between/gi;
const REPETITION_REGEX = /(every|ev)\s(.+?)(?=\sstarting|\sbetween|\suntil|\sending|\sfor|\sand|$)/i;
const START_DATE_REGEX = /(starting|between)\s(.+?)(?=\suntil|\sending|\sfor|\sand|$)/i;
const END_DATE_REGEX = /(until|ending|for|and)\s(.+)/i;
const MONTH_DAY_REGEX =
  /(\b(?:jan(?:uary)?|feb(?:ruary)?|mar(?:ch)?|apr(?:il)?|may|jun(?:e)?|jul(?:y)?|aug(?:ust)?|sep(?:tember)?|oct(?:ober)?|nov(?:ember)?|dec(?:ember)?)\b) (first|last|\d+(?:st|nd|rd|th)?)/gi;
const NUMBER_REGEX = /\b(\d+)\b/g;
const EVERY_NUMBER_REGEX = /every\s((?:\d+(?:st|nd|rd|th)?(?:,\s?)?)+)$/i;

const SHORTCUT_MAP = Object.entries({
  weekend: 'saturday',
  morning: 'day at 9am',
  afternoon: 'day at 12pm',
  evening: 'day at 7pm',
  night: 'day at 10pm',
  workday: 'mo, tu, we, th, fr',
  quarter: '3 months',
  daily: 'every day',
  weekly: 'every week',
  monthly: 'every month',
  yearly: 'every year',
  quarterly: 'every 3 months',
  other: '2',
  'new year day': 'january on the 1st',
  'new year eve': 'december on the 31st',
  valentine: 'february on the 14th',
  'memorial day': 'may on the last monday',
  halloween: 'october on the 31st',
});

function parseShortcuts(timeExpression: string) {
  return SHORTCUT_MAP.reduce((acc, [shortcut, value]) => {
    return acc.replace(new RegExp(shortcut, 'i'), value);
  }, timeExpression);
}

function parseRepetition(repetition: string) {
  if (MONTH_DAY_REGEX.test(repetition)) {
    return repetition.replace(MONTH_DAY_REGEX, '$1 on the $2').replace(NUMBER_REGEX, '$1th');
  }

  if (EVERY_NUMBER_REGEX.test(repetition)) {
    return repetition.replace(EVERY_NUMBER_REGEX, (_, numbers) => {
      const numberArray = numbers.split(',');
      const transformedNumbers = numberArray.map((number: string) => {
        const trimmedNumber = number.trim();
        const suffix = trimmedNumber.match(/\d+(st|nd|rd|th)/i) ? '' : 'th';
        return trimmedNumber + suffix;
      });

      return `every month on the ${transformedNumbers.join(', ')}`;
    });
  }

  return repetition;
}

function parseTimeExpression(timeExpression: string) {
  const repetitionMatch = timeExpression.match(REPETITION_REGEX);
  const startDateMatch = timeExpression.match(START_DATE_REGEX);
  const endDateMatch = timeExpression.match(END_DATE_REGEX);

  const repetition = repetitionMatch ? repetitionMatch[0].trim() : '';
  const startDate = startDateMatch ? startDateMatch[2].trim() : '';
  const endDate = endDateMatch ? endDateMatch[2].trim() : '';

  return {
    repetition,
    startDate,
    endDate,
  };
}

function generateRecurringRule(parsedInfo: { repetition: string; startDate: string; endDate: string }) {
  const { repetition, startDate, endDate } = parsedInfo;
  const parsedRepetition = parseRepetition(repetition);

  const parsedStartDate = parseDate(startDate, new Date(), { forwardDate: true });
  const parsedEndDate = parseDate(endDate, new Date(), { forwardDate: true });

  try {
    const rruleOptions = RRule.fromText(parsedRepetition).origOptions;

    return new RRule({
      ...rruleOptions,
      dtstart: parsedStartDate,
      until: parsedEndDate,
    });
  } catch {
    return null;
  }
}

export function parseRecurrence(timeExpression: string) {
  timeExpression = parseShortcuts(timeExpression);
  if (!RECURRENCE_STING_REGEX.test(timeExpression)) return null;
  const parsedInfo = parseTimeExpression(timeExpression);
  return generateRecurringRule(parsedInfo);
}
