import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { QuickSettings } from '@/components/LeftSidebar/QuickSettings';
import { ContactEditor } from '@/components/LeftSidebar/Contact/ContactEditor';
import { EmailSidebarHeader } from '@/components/LeftSidebar/Emails/EmailSidebarHeader';
import { EmailInbox } from '@/components/LeftSidebar/Emails/EmailInbox';
import { Messages } from './Emails/Messages';
import ToolTip from '@/components/Tooltip';
import { AllContacts } from '@/components/LeftSidebar/Contact/AllContacts';
import { AllSchedulingLinks } from '@/components/LeftSidebar/AllSchedulingLinks';
import { ComposeEmail } from '@/components/LeftSidebar/Emails/ComposeEmail';
import { AllGroups } from '@/components/LeftSidebar/Groups/AllGroups';
import { GroupEditor } from '@/components/LeftSidebar/Groups/GroupEditor';
import { AllNotes } from '@/components/LeftSidebar/AllNotes';
import { AllUnScheduled } from './AllUnScheduled';
import { ContactViewer } from '@/components/LeftSidebar/Contact/ContactViewer';
import { Shortcuts } from './Shortcuts';
import { SingleDayCalendar } from './SingleDayCalendar';

import { ItemType } from '@/models/shared';
import useLeftSidebar, { LeftSidebarType } from './useLeftSidebar';
import { ContactHeader } from './Headers/ContactHeader';
import { AllSchedulingLinkHeader } from './Headers/AllSchedulingLinkHeader';
import { NoteHeader } from './Headers/NoteHeader';
import { SettingsHeader } from './Headers/SettingsHeader';
import { AllContactsHeader } from './Headers/AllContactHeader';
import { AllGroupsHeader } from './Headers/AllGroupsHeader';
import { EditGroupHeader } from './Headers/EditGroupHeader';
import { SingleDayCalendarHeader } from './Headers/SingleDayCalendarHeader';
import { UnscheduledHeader } from './Headers/UnscheduledHeader';
import { Feedback } from './Feedback';
import SingleDayList from './SingleDayList';
import { useRouter } from 'next/router';
import Projects from './Projects/Projects';
import NewProject from './Projects/NewProject';

import TaskEditor from './editors/Task';
import EventEditor from './editors/Event';
import NoteEditor from './editors/Note';
import SchedulingLinkEditor from './editors/SchedulingLink';
import { ContactEditorHeader } from './Headers/ContactEditorHeader';

export const editorFor = (itemType: ItemType): LeftSidebarType | undefined => {
  switch (itemType) {
    case 'TASK':
      return 'TASK_EDITOR';
    case 'EVENT':
      return 'EVENT_EDITOR';
    case 'SCHEDULING_LINK':
      return 'SCHEDULING_LINK_EDITOR';
    case 'NOTE':
      return 'NOTE_EDITOR';
    case 'EMAIL':
      return 'EMAIL_DETAIL';
    default:
      return undefined;
  }
};

const DefaultHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex items-center justify-end gap-[16px] px-[16px] py-[29px] text-gray-400">
      <ToolTip tooltip="Close Sidebar">
        <button onClick={onClose}>
          <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
          <KeyboardShortcutIndicator shortcut="Esc" />
        </button>
      </ToolTip>
    </div>
  );
};

type Props = {
  className?: string;
  onClose: () => void;
};

export const LeftSidebar = ({ className, onClose }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const router = useRouter();

  const isCalendarView = router.asPath.startsWith('/calendar') || router.asPath.startsWith('/scheduling-link');

  const { leftSidebarState, openLeftSidebar, closeLeftSidebar } = useLeftSidebar();
  const { type, context } = leftSidebarState;

  /**
   * Handle contact search input
   */
  const handleSearchInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(e.target.value);
  };

  /**
   * Handle compose sidebar toggle state change
   */
  const handleComposeClick = () => {
    openLeftSidebar({
      type: 'COMPOSE',
    });
  };

  const handleClose = useCallback(() => {
    onClose?.();
    closeLeftSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  /**
   * Handle compose sidebar toggle state change
   */

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeLeftSidebar();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`flex flex-col h-full min-h-0 z-30 ${className} overflow-y-auto`}>
      {type === 'SETTINGS' ? (
        <>
          <SettingsHeader onClose={handleClose} />
          <QuickSettings />
        </>
      ) : type === 'ALL_SCHEDULING_LINKS' ? (
        <>
          <AllSchedulingLinkHeader onClose={handleClose} />
          <AllSchedulingLinks />
        </>
      ) : type === 'ALL_CONTACTS' ? (
        <>
          <AllContactsHeader onClose={handleClose} handleInput={handleSearchInput} inputValue={searchTerm} />
          <AllContacts searchTerm={searchTerm} />
        </>
      ) : type === 'ALL_GROUPS' ? (
        <>
          <AllGroupsHeader />
          <AllGroups />
        </>
      ) : type === 'PROJECTS' ? (
        <Projects onClose={handleClose} />
      ) : type === 'ALL_NOTES' ? (
        <>
          <NoteHeader onClose={handleClose} />
          <AllNotes />
        </>
      ) : type === 'ALL_EMAILS' ? (
        <EmailInbox />
      ) : type === 'EMAIL_DETAIL' ? (
        <Messages key={context?.threadId} threadId={context?.threadId} labelId={context?.labelId} />
      ) : type === 'CONTACT' ? (
        <>
          <ContactHeader onClose={handleClose} contactId={context?.contactId} editMode={context?.editMode} />{' '}
          <ContactViewer contactId={context?.contactId} />
        </>
      ) : type === 'CONTACT_EDITOR' ? (
        <>
          <ContactEditorHeader onClose={handleClose} contactId={context?.contactId} editMode={context?.editMode} />
          <ContactEditor contactId={context?.contactId} name={context?.name} email={context?.email} />
        </>
      ) : type === 'GROUP_EDITOR' ? (
        <>
          <EditGroupHeader add={false} />
          <GroupEditor groupId={context?.groupId} />
        </>
      ) : type === 'UNSCHEDULED' ? (
        <>
          <UnscheduledHeader onClose={handleClose} />
          <AllUnScheduled />
        </>
      ) : type === 'NEW_PROJECT' ? (
        <NewProject onClose={handleClose} />
      ) : type === 'COMPOSE' ? (
        <>
          <EmailSidebarHeader
            labelId={context?.labelId}
            onClose={handleClose}
            onComposeClick={handleComposeClick}
            isCompose
          />
          <ComposeEmail />
        </>
      ) : type === 'SHORTCUTS' ? (
        <>
          <DefaultHeader onClose={handleClose} />
          <Shortcuts />
        </>
      ) : type === 'SINGLE_DAY_CALENDAR' ? (
        isCalendarView ? (
          <SingleDayList onClose={handleClose} />
        ) : (
          <>
            <SingleDayCalendarHeader onClose={handleClose} />
            <SingleDayCalendar />
          </>
        )
      ) : type === 'FEEDBACK' ? (
        <>
          <DefaultHeader onClose={handleClose} />
          <Feedback />
        </>
      ) : type === 'TASK_EDITOR' ? (
        <TaskEditor
          key={`TASK-${context?.id}::${context?.start}`}
          id={context?.id}
          end={context?.end}
          start={context?.start}
          isAllDay={context?.isAllDay}
          summary={context?.summary}
        />
      ) : type === 'EVENT_EDITOR' ? (
        <EventEditor
          key={`EVENT-${context?.id}::${context?.start}`}
          id={context?.id}
          end={context?.end}
          start={context?.start}
          isAllDay={context?.isAllDay}
          summary={context?.summary}
        />
      ) : type === 'NOTE_EDITOR' ? (
        <NoteEditor key={context?.id} id={context?.id} />
      ) : type === 'SCHEDULING_LINK_EDITOR' ? (
        <SchedulingLinkEditor key={context?.id} id={context?.id} />
      ) : null}
    </div>
  );
};
