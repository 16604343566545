import { ConferencingData } from '@/models/EventArg';
import { NameEmail } from '@/utils/contacts';
import { Deadline, EventVisibility, Priority, TaskScheduleType, TimeBlockingType } from '@prisma/client';

export type ReferenceType = {
  id: string;
  type: 'EMAIL' | 'EVENT' | 'NOTE' | 'TASK';
};

export enum ItemType {
  TASK = 'TASK',
  EVENT = 'EVENT',
  SCHEDULING_LINK = 'SCHEDULING_LINK',
  NOTE = 'NOTE',
}

export type RichTextItem = {
  itemType: ItemType;
  id?: string;
  parentTaskId: string | null;
  summary: string;
  projectIds: string[];
  taskIds: string[];
  eventIds: string[];
  noteIds: string[];
  emailIds: string[];
  contacts: NameEmail[];
  priority?: Priority;
  start?: Date | null;
  end?: Date | null;
  allDay?: boolean;
  taskScheduleType?: TaskScheduleType;
  earliestStartDate?: Date;
  deadlineType: Deadline;
  dueDate?: Date;
  duration?: number;
  isCompleted?: boolean;
  userOrder?: number;
  location: string | null;
  subtasks: RichTextItem[];
  timeBlockingType?: TimeBlockingType;
  visibility?: EventVisibility;
  conferenceData?: ConferencingData;
  recurrence?: string[];
  scheduleId?: string;
  calendarId?: string;
  recurringTaskId?: string;
  originalStart?: Date;
};
