import { useMemo } from 'react';
import { Calendar } from '@/models/CalendarModel';
import { httpDelete, httpGet, httpPost } from 'utils/smarty-api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEasySession } from './useEasySession';

const getCalendars = () => httpGet<Calendar[]>('/calendars');

export const useCalendars = () => {
  const { isAuthenticated } = useEasySession();

  const { data: calendars, isLoading } = useQuery({
    queryKey: ['CALENDARS'],
    queryFn: getCalendars,
    enabled: isAuthenticated,
  });

  const defaultCalendar = useMemo(() => {
    if (!calendars || calendars.length === 0) return null;
    return calendars.find((calendar) => calendar.isDefault) ?? calendars.find((calendar) => calendar.isPrimary);
  }, [calendars]);

  return {
    calendars: isAuthenticated ? calendars : [],
    defaultCalendar: isAuthenticated ? defaultCalendar : null,
    isCalendarLoading: isAuthenticated ? isLoading : false,
  };
};

export const useUpdateCalendarEnabled = () => {
  const queryClient = useQueryClient();

  const updateCalendarEnabled = async (calendarId: string | 'all', enabled: boolean) => {
    const prevCalendars = queryClient.getQueryData<Calendar[]>(['CALENDARS']);
    if (!prevCalendars) return;

    const updateIndividualCalendar = async (id: string) => {
      const calendar = prevCalendars.find((calendar) => calendar.id === id);
      if (!calendar || calendar.isEnabled === enabled) {
        return { id, isEnabled: calendar ? calendar.isEnabled : false };
      }

      try {
        if (enabled) {
          await httpPost(`/calendars/${id}/selected`);
        } else {
          await httpDelete(`/calendars/${id}/selected`);
        }
        return { id, isEnabled: enabled };
      } catch {
        return { id, isEnabled: !enabled };
      }
    };

    try {
      const updatePromises =
        calendarId === 'all'
          ? prevCalendars.map((calendar) => updateIndividualCalendar(calendar.id))
          : [updateIndividualCalendar(calendarId)];

      const results = await Promise.all(updatePromises);

      queryClient.setQueryData<Calendar[]>(['CALENDARS'], (previous) =>
        previous?.map((oldCalendar) => {
          const result = results.find((r) => r && r.id === oldCalendar.id);
          return result ? { ...oldCalendar, isEnabled: result.isEnabled } : oldCalendar;
        })
      );

      void queryClient.invalidateQueries({queryKey:['EVENTS']});
    } catch (error) {
      console.error("An error occurred while updating calendars' enabled status", error);
    }
  };

  return { updateCalendarEnabled };
};

export const useMakeCalendarDefault = () => {
  const queryClient = useQueryClient();

  const makeCalendarDefault = async (calendarId: string) => {
    const prevCalendars = queryClient.getQueryData<Calendar[]>(['CALENDARS']);
    if (!prevCalendars) return;
    const previousDefaultId = prevCalendars?.find((calendar) => calendar.isDefault)?.id;
    if (previousDefaultId === calendarId) return;

    try {
      queryClient.setQueryData<Calendar[]>(['CALENDARS'], (previous) =>
        previous?.map((oldCalendar) => {
          if (oldCalendar.id !== calendarId && oldCalendar.id !== previousDefaultId) return oldCalendar;
          return {
            ...oldCalendar,
            isDefault: oldCalendar.id === calendarId,
          };
        })
      );
      await httpPost(`/calendars/${encodeURIComponent(calendarId)}/default`);
    } catch {
      queryClient.setQueryData<Calendar[]>(['CALENDARS'], (previous) =>
        previous?.map((oldCalendar) => {
          if (oldCalendar.id !== calendarId && oldCalendar.id !== previousDefaultId) return oldCalendar;
          return {
            ...oldCalendar,
            isDefault: oldCalendar.id === previousDefaultId,
          };
        })
      );
    }
  };

  return { makeCalendarDefault };
};
