import CalendarContext from '@/components/Calendar/context';
import { ContactEvent } from '@/hooks/useContactEvents';
import { Event } from '@/models/EventModel';
import { Task } from '@/models/TaskModel';
import { createDroppableId, DroppableType } from '@/utils/dnd';
import { useAtomValue } from 'jotai';
import moment from 'moment-timezone';
import { useContext, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { cn } from 'ui/cn';

import { ScrollAreaComponent } from '../ScrollArea';
import { ParagraphText } from '../Typography';
import { calendarTypeAtom } from '../calendarAtoms';
import Item from './Column/Item';
import { minutesToPixels } from './utils';
import useDragIndicator from './hooks/useDragIndicator';

type Props = {
  date: moment.Moment;
  isToday: boolean;
  className?: string;
};

export default function ColumnHeader({ date, isToday, className }: Props) {
  const { timeZone, hourHeight, items } = useContext(CalendarContext);
  const { setColumnRef, renderedItemAllDay, renderedDragIndicatorAllDay, updateItem } = useDragIndicator(date);
  const [moreSlots, setMoreSlots] = useState(false);
  const calendarType = useAtomValue(calendarTypeAtom);

  const allDayItems = items.filter((item) => {
    if (item.itemType !== 'EVENT' && item.itemType !== 'TASK' && item.itemType !== 'CONTACT_EVENT') return false;
    if (item.itemType === 'CONTACT_EVENT' && item.originalStartTime) {
      return item.originalStartTime.date === date.format('YYYY-MM-DD');
    }
    return item.allDay && date.isSame(moment.tz(item.start, timeZone), 'day');
  }) as (Event | Task | ContactEvent)[];

  return (
    <div
      className={`flex flex-col items-center flex-1 px-1 ${className}`}
      key={date.valueOf()}
      onClick={(e) => e.stopPropagation()}>
      <div className="text-center mb-2.5">
        <div className="mb-3 text-sm font-light">
          <ParagraphText>{date.format('ddd')}</ParagraphText>
        </div>
        {calendarType === 'SPECIFIC' ? (
          <span className={cn('p-2.5 font-bold text-sm', isToday && 'rounded-lg bg-secondary-100')}>
            {date.format('D')}
          </span>
        ) : null}
      </div>
      <div
        className={cn(
          'flex flex-col items-center w-full flex-1 min-h-[2rem] max-h-28 gap-1',
          moreSlots ? 'overflow-y-auto' : 'overflow-y-hidden'
        )}>
        <Droppable droppableId={createDroppableId(DroppableType.CALENDAR_HEADER, date.format('YYYY-MM-DD'))}>
          {(provided) => (
            <ScrollAreaComponent
              ref={(ref) => {
                setColumnRef(ref);
                provided.innerRef(ref);
              }}
              {...provided.droppableProps}
              className="h-full">
              {allDayItems.map((item, index) => {
                if (index > 1 && !moreSlots) return null;
                return (
                  <Item
                    key={index}
                    index={index}
                    item={item}
                    onChangeStart={updateItem(item, 'start')}
                    onChangeEnd={updateItem(item, 'end')}
                    className="my-[2px]"
                    style={{ height: minutesToPixels(hourHeight, 30) + 4 }}
                  />
                );
              })}
              <div hidden>{provided.placeholder}</div>
              {renderedItemAllDay}
              {renderedDragIndicatorAllDay}
            </ScrollAreaComponent>
          )}
        </Droppable>
      </div>
      {!moreSlots && allDayItems?.length > 2 ? (
        <button
          type="button"
          className="self-start justify-start w-full px-2 py-1 mb-1 text-left transition-colors rounded hover:bg-primary-100"
          onClick={() => setMoreSlots(true)}>
          <ParagraphText className="font-semibold">{allDayItems.length - 2} more</ParagraphText>
        </button>
      ) : null}
    </div>
  );
}
