import { forwardRef, Fragment, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Suggestion } from './types';

type Props = {
  items: Suggestion[];
  command: (suggestion: Suggestion) => void;
  renderSuggestion?: ({ suggestion }: { suggestion: Suggestion }) => ReactNode;
  renderCategory?: ({ category }: { category: string }) => ReactNode;
};

const MentionList = forwardRef(function MentionList(
  {
    items,
    command,
    renderSuggestion = ({ suggestion }) => suggestion.label,
    renderCategory = ({ category }) => category,
  }: Props,
  ref
) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      switch (event.key) {
        case 'ArrowUp':
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);
          return true;
        case 'ArrowDown':
          setSelectedIndex((selectedIndex + 1) % items.length);
          return true;
        case 'Enter':
          selectItem(selectedIndex);
          return true;
        default:
          return false;
      }
    },
  }));

  const itemsByCategory = useMemo(
    () =>
      Object.entries(
        Object.values(items).reduce(
          (accResults, suggestion) => {
            const { category = '__none__' } = suggestion;

            if (!accResults[category]) {
              accResults[category] = [];
            }

            accResults[category].push(suggestion);

            return accResults;
          },
          {} as Record<string, Suggestion[]>
        )
      ),
    [items]
  );

  const selectItem = useCallback(
    (index: number) => {
      const items = itemsByCategory.flatMap(([, suggestions]) => suggestions);
      const item = items[index];
      if (!item) return;

      command(item);
    },
    [command, itemsByCategory]
  );

  const renderedItems = useMemo(() => {
    if (!itemsByCategory.length) {
      return (
        <div className="px-2 py-2.5 max-h-[250px] min-w-[200px]">
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="w-full max-w-sm p-2 mx-auto">
                <div className="flex space-x-2 animate-pulse">
                  <div className="flex-1 py-1 space-y-2">
                    <div className="h-2 rounded bg-slate-500"></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>
      );
    }

    let index = 0;
    return itemsByCategory.map(([category, categorySuggestions]) => {
      return (
        <Fragment key={category}>
          {category !== '__none__' ? <div>{renderCategory({ category })}</div> : null}
          {categorySuggestions.map((suggestion) => {
            const itemIndex = index++;
            return (
              <button
                className="data-[active=true]:bg-select-light-gray hover:bg-select-light-gray text-left"
                key={itemIndex}
                data-active={itemIndex === selectedIndex}
                onClick={() => selectItem(itemIndex)}>
                {renderSuggestion({ suggestion })}
              </button>
            );
          })}
        </Fragment>
      );
    });
  }, [itemsByCategory, renderCategory, renderSuggestion, selectItem, selectedIndex]);

  return (
    <div
      className="max-h-[250px] overflow-y-auto bg-white rounded shadow-popover flex flex-col overflow-hidden justify-start"
      id="mention-list">
      {renderedItems}
    </div>
  );
});

export default MentionList;
