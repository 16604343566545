import type { SmartyItemType } from '@/hooks/useSmartyItems';
import useLeftSidebar from './LeftSidebar/useLeftSidebar';
import { editorFor } from './LeftSidebar';
import { useCallback, useMemo } from 'react';
import { formatDate } from '@/utils/date';
import { useSettings } from '@/hooks/useSettings';
import { ProjectArg } from '@/models/ProjectModel';

export function ItemCreated({
  item,
  isUpdate,
  isAllDay,
}: {
  item: SmartyItemType;
  isUpdate?: boolean;
  isAllDay?: boolean;
}) {
  const { openLeftSidebar } = useLeftSidebar();
  const { defaultTimeZone: timeZone } = useSettings();
  const hideDate = useMemo(() => {
    switch (item.itemType) {
      case 'SCHEDULING_LINK':
        return !item.start;
      default:
        return false;
    }
  }, [item]);

  const handleOnClick = useCallback(() => {
    openLeftSidebar({ type: editorFor(item.itemType), context: { id: item.id } });
  }, [item, openLeftSidebar]);

  const title = useMemo(() => {
    if ('summary' in item) return item.summary;
    return item.title;
  }, [item]);

  const date = useMemo(() => {
    const date =  item.start ?? item.createdAt;
    const allDay = 'allDay' in item ? item.allDay : isAllDay ?? false;
    return date ? formatDate({ timeZone, allDay, date }) : 'Unscheduled';
  }, [item, timeZone, isAllDay]);

  let taskText = 'Created';
  if (isUpdate) {
    taskText = 'Updated';
  } else if ('taskScheduleType' in item && item.taskScheduleType === 'AUTO_SCHEDULED') {
    taskText = 'Auto-scheduled';
  }

  return (
    <div className="text-sm text-white bg-black w-[250px]">
      <div className="flex flex-wrap items-center">
        {taskText}
        &nbsp;
        <div className="truncate">&quot;{title}&nbsp;</div>
        {hideDate ? '' : `@ ${date}`}
        &quot;&nbsp;
      </div>
      <button className="underline" onClick={handleOnClick}>
        View here
      </button>
    </div>
  );
}

export function AutoScheduledItemBeingCreated({ title }: { title: string }) {
  return (
    <div className="text-sm text-white bg-black w-[250px]">
      <div className="flex flex-wrap items-center">
        Calculating auto-scheduling for &nbsp;
        <div className="truncate">&quot;{title}&nbsp;</div>
        &quot;&nbsp;
      </div>
    </div>
  );
}

export function ProjectCreated({ project }: { project: ProjectArg }) {
  const title = useMemo(() => {
    return project.name;
  }, [project]);

  return (
    <div className="text-sm text-white bg-black">
      <div className="flex flex-wrap items-center">
        Created&nbsp;
        <span className="truncate">&quot;{title}&quot;</span>
      </div>
    </div>
  );
}
