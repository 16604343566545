import { useCallback, useEffect, useRef } from 'react';
import { useShortcuts } from '@/context/Common';
import { editorFor } from '@/components/LeftSidebar';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ItemType } from '../types';

export default function useNavigation() {
  const { openLeftSidebar, leftSidebarState } = useLeftSidebar();
  const { registerShortcut, unregisterShortcut } = useShortcuts();

  const navigationColumnRef = useRef<HTMLDivElement | null>(null);

  const navigateItems = useCallback(
    (direction: 'up' | 'down') => (e: KeyboardEvent) => {
      if (leftSidebarState.type === 'ALL_EMAILS') return; 
      const columns = Array.from(document.querySelectorAll('[data-targetable-column]')).filter(
        (col) => col.querySelectorAll('[data-targetable-item]').length
      ) as HTMLDivElement[];

      if (!columns.length) return;
      e.preventDefault();

      if (!navigationColumnRef.current) {
        const firstItem = columns[0].querySelector('[data-targetable-item]') as HTMLDivElement;
        navigationColumnRef.current = columns[0];
        firstItem.focus();
        return;
      }

      const nodes = Array.from(navigationColumnRef.current.querySelectorAll('[data-targetable-item]'));

      if (!nodes.length) return;
      const currentIndex = nodes.findIndex((node) => node === document.activeElement);

      if (currentIndex === -1) {
        (nodes[0] as HTMLDivElement).focus();
        return;
      }

      const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
      if (nextIndex < 0 || nextIndex >= nodes.length) return;
      (nodes[nextIndex] as HTMLDivElement).focus();
    },
    [leftSidebarState.type]
  );

  const navigateColumns = useCallback(
    (direction: 'left' | 'right') => (e: KeyboardEvent) => {
      if (leftSidebarState.type === 'ALL_EMAILS') return;
      const columns = Array.from(document.querySelectorAll('[data-targetable-column]')).filter(
        (col) => col.querySelectorAll('[data-targetable-item]').length
      ) as HTMLDivElement[];

      if (!columns.length) return;
      e.preventDefault();
      if (!navigationColumnRef.current) {
        const firstItem = columns[0].querySelector('[data-targetable-item]') as HTMLDivElement;
        navigationColumnRef.current = columns[0];
        firstItem.focus();
        return;
      }

      if (!columns.length) return;
      const currentIndex = columns.findIndex((column) => column === navigationColumnRef.current)!;

      const nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
      if (nextIndex < 0 || nextIndex >= columns.length) return;
      navigationColumnRef.current = columns[nextIndex] as HTMLDivElement;
      const nodes = Array.from(navigationColumnRef.current.querySelectorAll('[data-targetable-item]'));
      (nodes[0] as HTMLDivElement).focus();
    },
    [leftSidebarState.type]
  );

  const openItemDetails = useCallback(() => {
    const selectedItem = document.activeElement as HTMLDivElement;
    const itemId = selectedItem.getAttribute('data-item-id');
    const itemType = selectedItem.getAttribute('data-item-type') as ItemType['itemType'];
    if (!itemId || !itemType) return;
    if (itemType === 'SLOT' || itemType === 'BOOKING' || itemType === 'CONTACT_EVENT') return;

    openLeftSidebar({
      type: editorFor(itemType),
      context: { id: itemId },
    });
  }, [openLeftSidebar]);

  const toggleTask = useCallback(() => {
    const selectedItem = document.activeElement as HTMLDivElement;
    if (selectedItem.getAttribute('data-item-type') !== 'TASK') return;

    const checkbox = selectedItem.querySelector('[type=checkbox]') as HTMLButtonElement;
    checkbox.click();
  }, []);

  useEffect(() => {
    registerShortcut('enter', openItemDetails);
    registerShortcut('D', toggleTask);

    return () => {
      unregisterShortcut('enter');
      unregisterShortcut('D');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemDetails, toggleTask]);

  useEffect(() => {
    registerShortcut('up', navigateItems('up'));
    registerShortcut('down', navigateItems('down'));

    return () => {
      unregisterShortcut('up');
      unregisterShortcut('down');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateItems]);

  useEffect(() => {
    registerShortcut('left', navigateColumns('left'));
    registerShortcut('right', navigateColumns('right'));

    return () => {
      unregisterShortcut('left');
      unregisterShortcut('right');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateColumns]);
}
