type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export default function ScheduleIcon({ className, size = 14, color = 'black' }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none">
      <path
        d="M7 12.167C8.23768 12.167 9.42466 11.6753 10.2998 10.8002C11.175 9.92499 11.6667 8.738 11.6667 7.50033C11.6667 6.26265 11.175 5.07566 10.2998 4.20049C9.42466 3.32532 8.23768 2.83366 7 2.83366C5.76232 2.83366 4.57534 3.32532 3.70017 4.20049C2.825 5.07566 2.33333 6.26265 2.33333 7.50033C2.33333 8.738 2.825 9.92499 3.70017 10.8002C4.57534 11.6753 5.76232 12.167 7 12.167ZM7 1.66699C7.76605 1.66699 8.52459 1.81788 9.23232 2.11103C9.94005 2.40418 10.5831 2.83386 11.1248 3.37554C11.6665 3.91721 12.0961 4.56027 12.3893 5.26801C12.6825 5.97574 12.8333 6.73428 12.8333 7.50033C12.8333 9.04742 12.2188 10.5312 11.1248 11.6251C10.0308 12.7191 8.5471 13.3337 7 13.3337C3.77417 13.3337 1.16667 10.7087 1.16667 7.50033C1.16667 5.95323 1.78125 4.4695 2.87521 3.37554C3.96917 2.28157 5.45291 1.66699 7 1.66699ZM7.29167 4.58366V7.64616L9.91667 9.20366L9.47917 9.92116L6.41667 8.08366V4.58366H7.29167Z"
        fill={color}
      />
    </svg>
  );
}
