import { ChangeEvent, FormEvent, useState } from 'react';
import moment from 'moment-timezone';
import { ContactArg, Organization, Urls } from '@/models/ContactArg';
import { DateObject, DatePicker } from '@/components/DatePicker';
import { PlacesAutocomplete } from '@/components/PlacesAutocomplete';
import { CollapsibleSection } from '@/components/CollapsibleSection';
import { FloatingInput } from '@/components/FloatingInput';
import { toast } from 'react-hot-toast';
import { isValidEmail } from 'utils/email';
import { ToastGeneric } from './ToastGeneric';
import SubmitButton from '@/components/SubmitButton';
import OrganizationSection from './LeftSidebar/Contact/SubSections/OrganizationSection';
import { Divider } from './Divider';
import UrlsSection from './LeftSidebar/Contact/SubSections/UrlsSection';
import { JsonValue } from '@prisma/client/runtime/library';

type ContactFormProps = {
  contact: ContactArg;
  onSave: (contact: ContactArg) => void;
  isSaving: boolean;
};

export const ContactForm = ({ contact, onSave, isSaving }: ContactFormProps) => {
  const [firstName, setFirstName] = useState<string>(contact.firstName ?? '');
  const [lastName, setLastName] = useState<string>(contact.lastName ?? '');
  const [picture] = useState<string>(contact.picture ?? '');
  const [description, setDescription] = useState<string>(contact.description ?? '');
  const [email, setEmail] = useState<string>(contact.email ?? '');
  const [birthday, setBirthday] = useState<Date | null>(contactBirthdayToDate(contact.birthday));
  const [location, setLocation] = useState<string>(contact.location ?? '');
  const [organization, setOrganization] = useState((contact.organization as Organization[]) ?? []);
  const [urls, setUrls] = useState(contact.urls as Urls[] ?? []);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleLocationChange = (e: string) => {
    setLocation(e);
  };

  const handleBirthdayChange = (value: DateObject) => {
    setBirthday(value.toDate());
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!firstName) {
      toast(<ToastGeneric title="Invalid name" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      return;
    }

    if (!isValidEmail(email)) {
      toast(<ToastGeneric title="Invalid email" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      return;
    }

    onSave({
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      description,
      email,
      picture,
      birthday: contactDateToBirthday(birthday),
      location,
      organization: organization as JsonValue,
      urls: urls as JsonValue,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col flex-1 min-h-0 px-[16px] pb-[24px] gap-[16px]">
      <div className="flex-1 overflow-y-auto flex flex-col gap-[10px] px-[5px] pt-[5px] pb-[15px]">
        <div className="flex flex-col gap-5">
          <FloatingInput
            variant="primary"
            rounded
            label="First Name"
            name="first-name"
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <FloatingInput
            variant="primary"
            rounded
            label="Last Name"
            name="last-name"
            value={lastName}
            onChange={handleLastNameChange}
          />
          <FloatingInput
            variant="primary"
            rounded
            label="Email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          <Divider />
          <OrganizationSection organizations={organization} setNewOrganization={setOrganization} />
          <Divider />
          <UrlsSection urls={urls} setNewUrls={setUrls} />
          <Divider />
          <DatePicker label="Birthday" value={birthday} onChange={handleBirthdayChange} />
          <PlacesAutocomplete
            mode="full-address"
            labelInput="Location"
            name="location"
            value={location}
            onChange={handleLocationChange}
            placeholder="Choose a location"
          />
          <Divider />
          <CollapsibleSection
            header="Notes"
            defaultCollapsed={false}
            content={
              <div className="flex flex-col mt-[20px] py-2 border rounded-lg border-gray-scheduled px-[18px]">
                <textarea
                  className="w-full m-0 outline-none resize-none"
                  name="Notes"
                  placeholder="Notes"
                  rows={5}
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-[10px] p-[5px] pt-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t">
        <SubmitButton isLoading={isSaving} variant="primary">
          Save Contact
        </SubmitButton>
      </div>
    </form>
  );
};

const contactBirthdayToDate = (birthday?: any) => {
  if (!birthday) return null;
  return moment()
    .year(birthday?.year ?? moment().year())
    .startOf('year')
    .month(birthday?.month)
    .startOf('month')
    .day(birthday?.day)
    .startOf('day')
    .toDate();
};

const contactDateToBirthday = (date: Date | null) => {
  if (!date) return null;
  const m = moment(date);
  return {
    day: m.date(),
    month: m.month(),
    year: m.year() === moment().year() ? undefined : m.year(),
  };
};
