export const MenuClosedIcon = ({ className }: { className?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const MenuOpenIcon = ({ className }: { className?: string }) => (
  <svg width="18" height="12" viewBox="0 0 18 12" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 -8.74228e-08L6 -1.04907e-06C5.45 -1.09716e-06 5 0.449999 5 0.999999C5 1.55 5.45 2 6 2L17 2C17.55 2 18 1.55 18 1C18 0.45 17.55 -3.93402e-08 17 -8.74228e-08ZM17 5L9 5C8.45 5 8 5.45 8 6C8 6.55 8.45 7 9 7L17 7C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5ZM18 11C18 10.45 17.55 10 17 10L6 10C5.45 10 5 10.45 5 11C5 11.55 5.45 12 6 12L17 12C17.55 12 18 11.55 18 11ZM0.700002 3.12L3.58 6L0.700001 8.88C0.310002 9.27 0.310002 9.9 0.700001 10.29C1.09 10.68 1.72 10.68 2.11 10.29L5.7 6.7C6.09 6.31 6.09 5.68 5.7 5.29L2.11 1.7C1.72 1.31 1.09 1.31 0.700002 1.7C0.320002 2.09 0.310002 2.73 0.700002 3.12Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
