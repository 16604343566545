import moment from 'moment-timezone';
import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { useSettings } from '@/hooks/useSettings';

const globalSelectedDateAtom = atom<Date | null>(null);

export const useGlobalSelectedDate = () => {
  const { defaultTimeZone: timeZone } = useSettings();
  const [globalSelectedDate, setGlobalSelectedDate] = useAtom(globalSelectedDateAtom);

  useEffect(() => {
    if (!timeZone) return;
    if (!globalSelectedDate) {
      setGlobalSelectedDate(moment.tz(timeZone).startOf('day').toDate());
    }
  }, [timeZone, globalSelectedDate, setGlobalSelectedDate]);

  return { globalSelectedDate, setGlobalSelectedDate };
};
