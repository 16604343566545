import { Fragment, ReactNode, useMemo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Command } from 'cmdk';
import { FilterIcon } from '@/icons/FilterIcon';

type ComboboxItem = {
  content: ReactNode;
  onSelect?: () => void;
  categoryName?: string;
};

type ComboboxProps = {
  items: ComboboxItem[];
  categories?: Record<string, ReactNode>;
  button: ReactNode;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  open?: boolean;
  align?: 'start' | 'end' | 'center';
};

const NONE_CATEGORY = '__none__';

export default function Combobox({
  items,
  button,
  onOpenChange,
  defaultOpen,
  open,
  categories = {},
  align,
}: ComboboxProps) {
  const renderedItems = useMemo(() => {
    const itemsByCategory = items.reduce(
      (acc, item) => {
        const category = item.categoryName || NONE_CATEGORY;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      },
      {} as Record<string, ComboboxItem[]>
    );

    return Object.entries(itemsByCategory).map(([categoryName, items], index) => {
      const category = categories[categoryName];
      const heading = category ? <div className="px-2 py-1 text-sm font-medium text-gray-500">{category}</div> : '';

      return (
        <Fragment key={categoryName}>
          {index !== 0 ? <Command.Separator className="h-px my-2.5 bg-gray-300" /> : null}
          <Command.Group heading={heading} className="max-w-[300px]">
            {items.map(({ content, onSelect }, index) => (
              <Command.Item
                onSelect={onSelect}
                className="flex items-center p-2 py-2.5 rounded-md cursor-pointer truncate hover:bg-primary-100 aria-selected:bg-primary-100 gap-x-2"
                key={index}>
                {content}
              </Command.Item>
            ))}
          </Command.Group>
        </Fragment>
      );
    });
  }, [categories, items]);

  return (
    <DropdownMenu.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger asChild>{button}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-10 max-w-[300px] p-2 bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity] animate-fade max-h-[650px] overflow-y-auto"
          sideOffset={5}
          align={align}>
          <Command>
            <div className="flex items-center gap-2 px-2 py-2.5 pb-5 border-b border-gray-300 mb-2.5">
              <FilterIcon aria-hidden size={24} />
              <Command.Input placeholder="Filter By…" autoFocus className="focus:outline-none" />
            </div>
            <Command.List className="text-sm font-medium leading-normal">
              <Command.Empty className="text-center p-2 py-2.5">No results found.</Command.Empty>
              {renderedItems}
            </Command.List>
          </Command>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
