import { useCallback, useMemo } from 'react';
import { EventTransparency, EventVisibility } from '@prisma/client';

import { Select } from '@/components/Select';
import { useCalendars } from '@/hooks/useCalendars';
import { EVENT_COLOR_IDS, calculateEventColor } from '@/models/GoogleColorsModel';

type Props = {
  colorId?: string | null;
  calendarId?: string;
  visibility?: EventVisibility;
  availability?: EventTransparency | null;
  disabled: boolean;
  onChange: (data: {
    colorId?: string;
    calendarId?: string;
    transparency?: EventTransparency;
    visibility?: EventVisibility;
  }) => void;
};

const AVAILABILITY_OPTIONS = Object.values(EventTransparency);
const VISIBILITY_OPTIONS = Object.values(EventVisibility);
const EVENT_COLOR_OPTIONS = ['DEFAULT', ...EVENT_COLOR_IDS];

export default function Settings({ calendarId, colorId, visibility, availability, disabled, onChange }: Props) {
  const { calendars = [] } = useCalendars();
  const calendar = useMemo(() => calendars?.find((calendar) => calendar.id === calendarId), [calendarId, calendars]);

  const handleChangeColor = useCallback(
    (colorId: string) => {
      onChange({ colorId });
    },
    [onChange]
  );

  const handleChangeCalendar = useCallback(
    (calendarId: string) => {
      onChange({ calendarId: calendarId || '' });
    },
    [onChange]
  );

  const handleChangeAvailability = useCallback(
    (transparency: EventTransparency) => {
      onChange({ transparency });
    },
    [onChange]
  );

  const handleChangeVisibility = useCallback(
    (visibility: EventVisibility) => {
      onChange({ visibility });
    },
    [onChange]
  );

  return (
    <section className="flex flex-col gap-3 py-5 border-t border-gray-border">
      <div className="flex gap-4">
        <Select
          className="flex items-center h-12 rounded-lg"
          value={colorId || 'DEFAULT'}
          onChange={handleChangeColor}
          options={EVENT_COLOR_OPTIONS}
          disabled={disabled}
          extractLabel={(option) => {
            const color = calculateEventColor(
              calendar?.colorId || undefined,
              calendar?.backgroundColor || undefined,
              calendar?.foregroundColor || undefined,
              option
            );

            return (
              <div
                className="rounded-[4px] w-[16px] h-[16px]"
                style={{ backgroundColor: color?.background, color: color?.foreground }}
              />
            );
          }}
          extractValue={(option) => option}
        />
        <Select
          className="w-[315px]"
          label="Calendar"
          options={calendars
            .filter((calendar) => calendar.accessRole && ['writer', 'owner'].includes(calendar.accessRole))
            .map((calendar) => calendar.id)}
          value={calendarId}
          disabled={disabled}
          name="calendarId"
          extractLabel={(option) => {
            if (!option) return '';
            return calendars.find((calendar) => calendar.id === option)?.summary;
          }}
          extractValue={(option) => option}
          onChange={handleChangeCalendar}
        />
      </div>
      <div className="flex gap-4">
        <Select
          options={AVAILABILITY_OPTIONS}
          label="Availability"
          className="flex-1 capitalize"
          value={availability || EventTransparency.opaque}
          name="availability"
          disabled={disabled}
          extractLabel={(option) => {
            if (EventTransparency.opaque === option) return 'Busy';
            return 'Free';
          }}
          extractValue={(option) => option || ''}
          onChange={handleChangeAvailability}
        />
        <Select
          options={VISIBILITY_OPTIONS}
          label="Visibility"
          className="flex-1 capitalize"
          value={visibility}
          name="visibility"
          disabled={disabled}
          extractLabel={(option) => option}
          extractValue={(option) => option || ''}
          onChange={handleChangeVisibility}
        />
      </div>
    </section>
  );
}
