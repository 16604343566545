type props = {
  className?: string;
  size?: number;
  color?: string;
};

export const EmailIcon = ({ className, size = 16 }: props) => (
  <svg
    className={className}
    width={size}
    height={size + 4}
    viewBox="0 0 68 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.0546 9.34943L31.0908 3.7652C30.2775 3.00616 29.0305 3.00616 28.2173 3.7652L22.3076 9.29516"
      fill="white"
    />
    <path
      d="M34.83 9.34941L30.6553 5.44569C30.059 4.90352 29.1915 4.90352 28.6493 5.44569L24.4746 9.34941"
      fill="#EAF7FD"
      fillOpacity="0.6"
    />
    <path
      d="M8.86145 21.8191L1 29.1384V61.8853C1 62.6444 1.65059 63.295 2.40963 63.295H56.8434C57.6024 63.295 58.253 62.6444 58.253 61.8853V29.1384L50.4458 21.8191"
      fill="white"
    />
    <path
      d="M9.83673 23.7167L1.27051 31.7949V62.536C1.27051 63.2951 1.86689 63.3493 2.62593 63.3493H56.6801C57.4392 63.3493 58.0355 62.7529 58.0355 61.9939V31.7949L49.4693 23.7167"
      fill="#EAF7FD"
      fillOpacity="0.6"
    />
    <path
      d="M58.0355 29.3553V61.9939C58.0355 62.5903 57.656 63.0781 57.1681 63.2408C57.0054 63.295 56.8428 63.3493 56.6801 63.3493H2.62593C1.86689 63.3493 1.27051 63.2951 1.27051 62.5361V30.277L2.73434 30.4396L28.8668 46.6504L56.3006 30.2228L58.0355 29.3553Z"
      fill="#EAF7FD"
    />
    <path
      d="M57.1673 48.4941V63.2409C57.0047 63.2951 56.842 63.3495 56.6794 63.3495H40.1432C39.8179 62.2651 39.6553 61.1264 39.6553 59.9336C39.6553 53.0481 45.2396 47.4638 52.1252 47.4638C53.9685 47.4638 55.6493 47.8435 57.1673 48.4941Z"
      fill="#A9D3EA"
      fillOpacity="0.2"
    />
    <path
      d="M50.4442 12.7109V30.6564L29.0285 43.4517L8.91406 30.8193V12.7109C8.91406 10.8133 10.4321 9.29504 12.3297 9.29504H47.0828C48.8719 9.29504 50.4442 10.8675 50.4442 12.7109Z"
      fill="white"
    />
    <path
      d="M37.0546 9.34943L31.0908 3.7652C30.2775 3.00616 29.0305 3.00616 28.2173 3.7652L22.3076 9.29516"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.86145 21.8191L1 29.1384V61.8853C1 62.6444 1.65059 63.295 2.40963 63.295H56.8434C57.6024 63.295 58.253 62.6444 58.253 61.8853V29.1384L50.4458 21.8191"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M44.4812 66.2229C43.18 64.2711 42.4209 61.8855 42.4209 59.3915C42.4209 52.506 48.0052 46.9216 54.8908 46.9216C61.7763 46.9216 67.3607 52.506 67.3607 59.3915C67.3607 66.2771 61.7763 71.8614 54.8908 71.8614C50.5534 71.8614 46.7041 69.6386 44.4812 66.2229Z"
        fill="#8EBCD6"
      />
    </g>
    <path
      d="M56.6265 65.0843C56.0844 65.0843 55.5964 64.8676 55.2169 64.4881C54.4579 63.7291 54.4579 62.482 55.2169 61.6687L55.488 61.3976H49.4699C48.3855 61.3976 47.4639 60.5301 47.4639 59.3915C47.4639 58.3072 48.3313 57.3856 49.4699 57.3856H55.488L55.2169 57.1146C54.8374 56.735 54.6205 56.247 54.6205 55.7049C54.6205 55.1627 54.8374 54.6747 55.2169 54.2952C55.5964 53.9157 56.0844 53.6987 56.6265 53.6987C57.1687 53.6987 57.6566 53.9157 58.0362 54.2952L61.7229 57.9821C62.1024 58.3616 62.3193 58.8493 62.3193 59.3915C62.3193 59.9337 62.1024 60.4217 61.7229 60.8012L58.0362 64.4881C57.6566 64.8676 57.1145 65.0843 56.6265 65.0843Z"
      fill="white"
    />
    <path
      d="M56.6253 54.5661C56.9506 54.5661 57.2217 54.6746 57.4386 54.8914L61.1253 58.5781C61.3422 58.795 61.4506 59.1203 61.4506 59.3913C61.4506 59.6624 61.3422 59.9877 61.1253 60.2046L57.4386 63.8915C57.2217 64.1084 56.8964 64.2169 56.6253 64.2169C56.3 64.2169 56.0289 64.1084 55.8121 63.8915C55.3783 63.4578 55.3783 62.6987 55.8121 62.2107L57.4928 60.5299H49.5229C48.8723 60.5299 48.3301 59.9879 48.3301 59.3373C48.3301 58.6867 48.8723 58.1445 49.5229 58.1445H57.4928L55.8121 56.4637C55.3783 56.0299 55.3783 55.2709 55.8121 54.7829C56.0289 54.6745 56.3 54.5661 56.6253 54.5661ZM56.6253 52.9396C55.8663 52.9396 55.1615 53.2107 54.6735 53.7528C54.1856 54.295 53.8603 54.9999 53.8603 55.7047C53.8603 56.03 53.9145 56.3011 54.0229 56.5722H49.5771C48.0591 56.5722 46.7578 57.8191 46.7578 59.3913C46.7578 60.9636 48.0048 62.2107 49.5771 62.2107H54.0229C53.9145 62.4818 53.8603 62.8072 53.8603 63.0782C53.8603 63.8373 54.1314 64.5422 54.6735 65.0301C55.2157 65.5723 55.9205 65.8434 56.6253 65.8434C57.3844 65.8434 58.0892 65.5723 58.5771 65.0301L62.2639 61.3432C62.8061 60.801 63.0771 60.0962 63.0771 59.3913C63.0771 58.6323 62.8061 57.9277 62.2639 57.4397L58.5771 53.7528C58.0349 53.2107 57.3301 52.9396 56.6253 52.9396Z"
      fill="#8EBCD6"
    />
    <path
      d="M25.5045 44.4279L1.05273 29.1929"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0713 54.6748L58.252 29.1929"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1914 57.6025L12.6553 56.6807"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.252 9.34924H46.9748C48.8724 9.34924 50.3905 10.8672 50.3905 12.7648V33.9637"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8623 34.0722V12.7648C8.8623 10.8672 10.3803 9.34924 12.2779 9.34924H17.5912"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M49.5244 5.60843V1" stroke="#A9D3EA" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M54.1309 12.1144H58.7393"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.9395 8.21097L56.1925 4.90369"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3115 17.6443H43.0164"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3115 25.0181H31.0344"
      stroke="#A9D3EA"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d"
        x="17.4209"
        y="21.9216"
        width="74.9398"
        height="74.9398"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
