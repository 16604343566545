import { Label } from '@radix-ui/react-label';
import { useId } from 'react';
import OriginalDatePicker, { DateObject as OriginalDateObject, Value } from 'react-multi-date-picker';
import OriginalTimePicker from 'react-multi-date-picker/plugins/time_picker';

export type DateObject = OriginalDateObject;

type DatePickerProps = {
  className?: string;
  label?: string;
  value?: Value;
  disabled?: boolean;
  showTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange?(selectedDates: DateObject | DateObject[] | null): void;
  calendarPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  datePlaceholder?: string;
  timePlaceholder?: string;
};

export const DatePicker = ({
  label,
  className,
  value,
  onChange,
  disabled,
  showTime,
  minDate,
  maxDate,
  calendarPosition,
  datePlaceholder,
  timePlaceholder,
}: DatePickerProps) => {
  const id = useId();

  return (
    <div className={`relative flex gap-2 ${className}`}>
      {label ? (
        <Label
          htmlFor={id}
          className={`text-[8px] leading-[12px] absolute bg-[#FFFFFF] text-gray-border -top-1.5 px-1 rounded-lg left-3.5 ${
            disabled ? 'bg-gray-100' : ''
          }`}>
          {label}
        </Label>
      ) : null}
      <OriginalDatePicker
        id={id}
        value={value}
        onChange={onChange}
        containerClassName="flex-1"
        format={'MM/DD/YYYY'}
        inputClass="pl-[16px] py-[8px] border rounded-[8px] border-gray-200 w-full disabled:bg-gray-100 disabled:cursor-not-allowed text-sm min-h-[46px] focus:outline-none focus:border focus:border-[#279AF1]"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        fixMainPosition
        calendarPosition={calendarPosition}
        placeholder={datePlaceholder}
      />
      {showTime ? (
        <TimePicker
          label="Time"
          value={value}
          onChange={onChange}
          disabled={disabled}
          showTime={showTime}
          calendarPosition="bottom-right"
          timePlaceholder={timePlaceholder}
        />
      ) : null}
    </div>
  );
};

export const TimePicker = ({
  label,
  className,
  value,
  onChange,
  disabled,
  calendarPosition,
  timePlaceholder,
}: DatePickerProps) => {
  const id = useId();

  return (
    <div className={`relative ${className}`}>
      {label ? (
        <Label
          htmlFor={id}
          className={`text-[8px] leading-[12px] absolute bg-[#FFFFFF] text-gray-border -top-1.5 px-1 rounded-lg left-3.5 ${
            disabled ? 'bg-gray-100' : ''
          }`}>
          {label}
        </Label>
      ) : null}
      <OriginalDatePicker
        disableDayPicker
        id={id}
        value={value}
        format="hh:mm A"
        onChange={onChange}
        plugins={[<OriginalTimePicker hideSeconds position="bottom" key={1} />]}
        containerStyle={{ display: 'block' }}
        inputClass="pl-[16px] py-[8px] text-sm min-h-[46px] border rounded-[8px] border-gray-200 w-full disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:border focus:border-[#279AF1]"
        fixMainPosition
        placeholder={timePlaceholder}
        calendarPosition={calendarPosition}
        disabled={disabled}
      />
    </div>
  );
};
