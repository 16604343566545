import { forwardRef, ReactNode } from 'react';
import Button from '@/components/Button';

type SubmitButtonProps = {
  isLoading?: boolean;
  hasError?: boolean;
  className?: string;
  children?: ReactNode;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  onClick?: () => void;
};

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ isLoading, hasError, className, children, variant = 'primary', disabled, onClick }, forwardedRef) => (
    <Button
      ref={forwardedRef}
      variant={variant}
      type="submit"
      isLoading={isLoading}
      disabled={isLoading || disabled}
      className={className}
      onClick={onClick}
      hasError={hasError}>
      {children || 'Save changes'}
    </Button>
  )
);

SubmitButton.displayName = 'SubmitButton';
export default SubmitButton;
