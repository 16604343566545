import {
  JSONContent,
  Node,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import { NEW_ITEM_PREFIX } from '../../utils/item';
import cuid from 'cuid';

export default Node.create({
  name: 'summary',
  group: 'block',
  content: 'item+',
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        if (document.getElementById('mention-list')) return false;
        if (editor.state.doc.attrs.disableNewLine) return false;
        if (editor.state.selection.$from.node(2)?.type.name !== 'summary') return false;

        const currentNode = editor.state.selection.$from.node();
        const from = editor.state.selection.from;
        const to = editor.state.selection.$from.end();

        if (!currentNode.attrs.editable) {
          editor.commands.insertContentAt(to, [{ type: 'item' }]);
          return true;
        }

        const suggestions: JSONContent[] = [];
        editor.state.doc.nodesBetween(from, to, (node) => {
          if (!node.type.name.includes('SUGGESTION')) return;
          suggestions.push({ type: 'text', text: ' ' }, node.toJSON());
        });

        if (suggestions.length) {
          editor.commands.insertContentAt(from, suggestions);
        }

        return editor
          .chain()
          .splitBlock()
          .lift('summary')
          .wrapIn('details')
          .resetAttributes('item', ['id', 'editable', 'index', 'color', 'userOrder', 'scheduleType'])
          .updateAttributes('item', { parentId: currentNode.attrs.id, id: `${NEW_ITEM_PREFIX}_${cuid()}` })
          .run();
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'summary',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['summary', mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Summary);
  },
});

function Summary() {
  return (
    <NodeViewWrapper>
      <NodeViewContent as="div" />
    </NodeViewWrapper>
  );
}
