import { useMemo } from 'react';
import { Calendar } from '@/models/CalendarModel';
import { calculateCalendarColor } from '@/models/GoogleColorsModel';
import { cn } from 'ui/cn';
import { useCalendars, useMakeCalendarDefault, useUpdateCalendarEnabled } from '@/hooks/useCalendars';

export function getColorFromCalendar(calendar: Calendar) {
  return (
    calculateCalendarColor(
      calendar.colorId || undefined,
      calendar.backgroundColor || undefined,
      calendar.foregroundColor || undefined
    )?.background || ''
  );
}

type Props = {
  id: string;
  className?: string;
};

export default function CalendarItem({ id, className }: Props) {
  const { calendars } = useCalendars();
  const { updateCalendarEnabled } = useUpdateCalendarEnabled();
  const { makeCalendarDefault } = useMakeCalendarDefault();

  const calendar = useMemo(() => calendars?.find((calendar) => calendar.id === id), [calendars, id]);
  if (!calendar) return null;

  return (
    <div className={cn('flex items-center text-sm font-medium border-t border-gray-scheduled', className)}>
      <div>
        <input
          type="checkbox"
          className="relative w-[22px] h-[22px] border-[3px] [&:not(:checked)]:!bg-transparent border-solid rounded-md appearance-none checked:after:absolute checked:after:top-0.5 checked:after:left-[5px] checked:after:h-2.5 checked:after:w-1.5 checked:after:rotate-45 checked:after:border-[3px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:content-[''] hover:cursor-pointer focus:shadow-none focus:transition-[border-color_0.2s]"
          style={{ borderColor: getColorFromCalendar(calendar), backgroundColor: getColorFromCalendar(calendar) }}
          checked={calendar.isEnabled}
          onChange={() => updateCalendarEnabled(calendar.id, !calendar.isEnabled)}
        />
      </div>
      <label className="flex items-center gap-2.5 p-2.5 cursor-pointer truncate">{calendar.summary}</label>
      <div className="flex flex-row items-end gap-6 ml-auto">
        {calendar.isDefault ? (
          <p className="px-4 py-2 ml-auto">Default</p>
        ) : calendar.accessRole === 'owner' || calendar.accessRole === 'writer' ? (
          <button
            type="button"
            className="px-4 py-2 ml-auto underline whitespace-nowrap"
            onClick={() => makeCalendarDefault(calendar.id)}>
            Make default
          </button>
        ) : null}
      </div>
    </div>
  );
}
