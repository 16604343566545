import { parseAddressList, ParsedMailbox, parseOneAddress } from 'email-addresses';
import { gmail_v1 } from 'googleapis';
import moment from 'moment-timezone';

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

export const isValidEmail = (email: string) => !!email && EMAIL_REGEX.test(email);

export const extractParticipants = (headers: Record<string, string>) => {
  const date = moment(headers['date'] ?? '').toDate();
  const subject = headers['subject'] ?? '';
  const from = parseOneAddress(headers['from'] ?? '') as ParsedMailbox;
  const to = (parseAddressList(headers['to'] ?? '') as ParsedMailbox[]) ?? [];
  const cc = (parseAddressList(headers['cc'] ?? '') as ParsedMailbox[]) ?? [];
  return { date, subject, from, to, cc };
};

export const extractParticipantsArray = (headers: gmail_v1.Schema$MessagePartHeader[]) => {
  const from = parseOneAddress(headers.find((h) => h.name?.toLowerCase() === 'from')?.value ?? '') as ParsedMailbox;
  const to =
    (parseAddressList(headers.find((h) => h.name?.toLowerCase() === 'to')?.value ?? '') as ParsedMailbox[]) ?? [];
  const cc =
    (parseAddressList(headers.find((h) => h.name?.toLowerCase() === 'cc')?.value ?? '') as ParsedMailbox[]) ?? [];
  return { from, to, cc };
};

export const formatEmailAddress = (email: ParsedMailbox) => {
  if (email.name) return `${email.name} <${email.address}>`;
  return email.address;
};
