import { DatePicker } from '@/components/DatePicker';
import { DurationSelector } from '@/components/DurationSelector';
import { Select } from '@/components/Select';
import { useAvailability } from '@/hooks/useAvailability';
import { useSettings } from '@/hooks/useSettings';
import AlertIcon from '@/icons/AlertIcon';
import { TaskScheduleType, RecurrenceType } from '@prisma/client';
import { useCallback, useMemo, useState } from 'react';
import { MdHdrAuto } from 'react-icons/md';
import { DateObject } from 'react-multi-date-picker';
import Switch from '@/components/Switch';
import { RecurringSelect , getOccurrencesOfMonth, getWeekdayByTypeAndDate } from '@/components/RecurringSelect';
import moment from 'moment-timezone';
import { Options, RRule } from 'rrule';
import { deleteDTSTART, rightFrequency } from '@/utils/recurrenceUtils';
import { Divider } from '@/components/Divider';
import { formatDateRangeTimeSimple } from '@/utils/date';

type Props = {
  taskScheduleType?: TaskScheduleType;
  disabled?: boolean;
  scheduleId?: string;
  onChangeScheduleId: (value: string) => void;
  duration?: number | null;
  onChangeDuration: (value: number) => void;
  earliestStartDate?: Date;
  onChangeEarliestStartDate: (value: Date, scheduledType: TaskScheduleType) => void;
  start?: Date | null;
  end?: Date | null;
  allDay?: boolean;
  onChange: (value: {
    start?: Date;
    end?: Date;
    allDay?: boolean;
    recurrence?: string[];
    recurrenceType?: RecurrenceType;
    recurringTaskId?: string;
    taskScheduleType?: TaskScheduleType;
  }) => void;
  recurrenceType?: RecurrenceType;
  recurringTaskId?: string;
};

const CREATE_CUSTOM_SCHEDULE_OPT = {
  id: 'CREATE_CUSTOM_SCHEDULE',
  title: 'Create a custom schedule',
};

export default function AutoSchedule({
  taskScheduleType,
  disabled,
  scheduleId,
  onChangeScheduleId,
  duration,
  onChangeDuration,
  earliestStartDate,
  onChangeEarliestStartDate,
  start,
  end,
  allDay,
  onChange,
  recurrenceType,
  recurringTaskId,
}: Props) {
  const { defaultDuration, defaultTimeZone: timeZone } = useSettings();
  const { availability, defaultAvailability } = useAvailability();
  const [isAutoScheduleEnabled, setIsAutoScheduleEnabled] = useState(taskScheduleType === 'AUTO_SCHEDULED');

  const availabilityOptions = useMemo(() => {
    if (!availability) return [CREATE_CUSTOM_SCHEDULE_OPT];
    const options = availability.filter(({ id }) => Boolean(id)).map(({ id, title }) => ({ id, title }));
    return [...options, CREATE_CUSTOM_SCHEDULE_OPT];
  }, [availability]);

  const handleOnChangeEarliestStartDate = useCallback(
    (value: DateObject) => {
      onChangeEarliestStartDate(value.toDate(), 'AUTO_SCHEDULED');
    },
    [onChangeEarliestStartDate]
  );

  const handleChangeDate = useCallback(
    (key: 'start' | 'end') => (value: DateObject) => {
      let date = value.toDate();
      if (allDay) {
        date = moment.tz(date, timeZone).startOf('day').toDate();
      }
      const updatedValues: any = { [key]: date, taskScheduleType: TaskScheduleType.SPECIFIC };

      if (key === 'start' && duration) {
        const originalDuration = moment(end).diff(moment(start), 'minutes');
        const endDate = moment(date).add(originalDuration, 'minutes').toDate();
        updatedValues.end = endDate;
      }

      onChange(updatedValues);
    },
    [allDay, duration, onChange, timeZone, end, start]
  );

  const handleIsAllDayChange = useCallback(
    (value: boolean) => {
      const _start = moment.tz(start, timeZone).startOf('day').toDate();
      const _end = moment.tz(_start, timeZone).add(1, 'day').toDate();

      onChange({
        start: _start,
        end: _end,
        allDay: value,
        taskScheduleType: TaskScheduleType.SPECIFIC,
      });
    },
    [onChange, start, timeZone]
  );

  const handleRecurrenceChange = useCallback(
    (value: string) => {
      if (value === 'DOES_NOT_REPEAT' || value === 'CUSTOM') {
        onChange({ recurrenceType: value as RecurrenceType, recurrence: [] });
        return;
      }

      const options = {
        freq: rightFrequency(value),
        interval: 1,
        dtstart: start!,
        until: null,
        count: null,
        byweekday: getWeekdayByTypeAndDate(value as any, start!),
      } as Options;

      if (value === 'MONTHLY') {
        options.bysetpos = getOccurrencesOfMonth(start!);
      }

      const rule = new RRule(options);
      const rruleStringWithoutDTSTART = deleteDTSTART(rule.toString());

      onChange({
        recurrence: [rruleStringWithoutDTSTART],
        recurrenceType: value as RecurrenceType,
        recurringTaskId: recurringTaskId,
        taskScheduleType: TaskScheduleType.RECURRING,
      });
    },
    [onChange, start, recurringTaskId]
  );

  const minDate = useMemo(() => new Date(), []);

  return (
    <section className="flex flex-col gap-3 py-5 border-t border-gray-border">
      <div className="flex items-center justify-between">
        <p className="flex items-center text-[14px] leading-none mr-2">
          <MdHdrAuto fontSize={18} className="mr-2.5" /> Should Smarty automatically schedule <br/> this task instead?
        </p>              
        <Switch
          value={isAutoScheduleEnabled}
          onChange={() => {
            const isAutoSchedule = !isAutoScheduleEnabled;
            setIsAutoScheduleEnabled(isAutoSchedule);
            onChange({ 
              taskScheduleType: isAutoSchedule ? TaskScheduleType.AUTO_SCHEDULED : TaskScheduleType.NONE,
              start: isAutoSchedule ? (start ?? undefined) : undefined,
              end: isAutoSchedule ? (end ?? undefined) : undefined,
            });
          }}
          label=""
        />
      </div>
      {isAutoScheduleEnabled ? (
        <div className="flex flex-col gap-3">
          <div className="flex items-center mb-2">
            <AlertIcon color="#FFB20F" size={18} />
            <span className="mx-2 text-xs">
              {isAutoScheduleEnabled && start && end ? (
                <>Currently auto-scheduled for:<br/> {formatDateRangeTimeSimple(start, end)}</>
              ) : (
                <>Smarty will calculate the perfect date & time on save.</>
              )}
            </span>
          </div>
          <Select
            label="Schedule during"
            name="scheduleId"
            disabled={disabled}
            options={availabilityOptions?.map((item) => item.id!)}
            extractLabel={(option) => availabilityOptions?.find((av) => av.id === option)?.title}
            extractValue={(option) => option}
            value={scheduleId || defaultAvailability?.id}
            onChange={onChangeScheduleId}
          />
          <DurationSelector onChange={onChangeDuration} className='xs:flex-col' value={duration || defaultDuration} />
          <DatePicker
            label="Earliest start date"
            showTime
            className='xs:flex-col'
            disabled={disabled}
            minDate={minDate}
            value={earliestStartDate}
            onChange={handleOnChangeEarliestStartDate}
            datePlaceholder="Earliest date"
            timePlaceholder="Earliest time"
          />
        </div>
      ) : (
        <>
          <DatePicker
            label="Start"
            className="xs:flex-col lg:flex-row"
            showTime={!allDay}
            disabled={disabled}
            value={start}
            onChange={handleChangeDate('start')}
          />
          <div className="sm:hidden lg:hidden">
            <Divider />
          </div>
          <DatePicker
            label="End"
            className="xs:flex-col lg:flex-row"
            showTime={!allDay}
            disabled={disabled}
            value={end}
            minDate={start ?? new Date()}
            onChange={handleChangeDate('end')}
          />
          {!allDay && start && end && !moment(start).isSame(end, 'day') && (
            <div className="text-xs text-red-500">
              Note: The start and end dates are different.
            </div>
          )}
          <div className="flex items-center justify-between">
            <p className="flex items-center text-[14px] leading-none mr-2">
              All day?
            </p>              
            <Switch label="" disabled={disabled} value={!!allDay} onChange={handleIsAllDayChange} />
          </div>
          
          <RecurringSelect
            date={start || null}
            recurrenceType={recurrenceType || 'DOES_NOT_REPEAT'}
            onChange={handleRecurrenceChange}
            timeZone={timeZone}
          />
        </>
      )}
    </section>
  );
}
