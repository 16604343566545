import { FabIcon } from '@/icons/FabIcon';
import { TaskIcon } from '@/icons/TaskIcon';
import { EventIcon } from '@/icons/EventIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import DropdownMenu from '@/components/DropdownMenu';
import { useRouter } from 'next/router';
import useLeftSidebar from './LeftSidebar/useLeftSidebar';
import Key from '@/components/Key';
import { eventBeingCreatedAtom } from './calendarAtoms';
import { useSetAtom } from 'jotai';

export default function Fab() {
  const { openLeftSidebar } = useLeftSidebar();
  const router = useRouter();
  const isSlotCalendar = router.pathname === '/scheduling-link/[newOrId]';
  const setEventBeingCreated = useSetAtom(eventBeingCreatedAtom);

  function openTaskEditor() {
    if (isSlotCalendar) {
      router.push('/calendar', undefined, { shallow: true });
      setEventBeingCreated(null);
      openLeftSidebar({
        type: 'TASK_EDITOR',
        context: {
          autoFocus: true,
        },
      });
    }
    openLeftSidebar({
      type: 'TASK_EDITOR',
      context: {
        autoFocus: true,
      },
    });
  }

  function openEventEditor() {
    if (isSlotCalendar) {
      router.push('/calendar', undefined, { shallow: true });
      setEventBeingCreated(null);
      openLeftSidebar({
        type: 'EVENT_EDITOR',
        context: {
          autoFocus: true,
        },
      });
    }
    openLeftSidebar({
      type: 'EVENT_EDITOR',
      context: {
        autoFocus: true,
      },
    });
  }

  function openSchedulingLinkEditor() {
    openLeftSidebar({ type: 'SCHEDULING_LINK_EDITOR' });
    void router.push('/scheduling-link/new', undefined, { shallow: true });
  }

  return (
    <DropdownMenu
      button={
        <button className="absolute p-3 transition-all rounded-lg bottom-5 hover:mb-1 right-9 z-50">
          <FabIcon className="bg-white rounded-full shadow-md" />
        </button>
      }
      items={[
        {
          content: (
            <>
              <TaskIcon /> New Task <Key>{'/'}</Key>
              <Key>{'-'}</Key>
            </>
          ),
          onClick: openTaskEditor,
        },
        {
          content: (
            <>
              <EventIcon /> New Event <Key>{'/'}</Key>
              <Key>{'⇧'}</Key>
              <Key>{'H'}</Key>
            </>
          ),
          onClick: openEventEditor,
        },
        {
          content: (
            <>
              <SchedulingLinkIcon />
              New Scheduling Link <Key>{'/'}</Key>
              <Key>{'⇧'}</Key>
              <Key>{'S'}</Key>
            </>
          ),
          onClick: openSchedulingLinkEditor,
        },
      ]}
    />
  );
}
