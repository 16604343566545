import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { $Enums } from '@prisma/client';
import { toast } from 'react-hot-toast';

import { ItemCreated } from '@/components/ToastItemCreated';
import { ToastGeneric } from '@/components/ToastGeneric';
import Header from '../Header';
import Title from '../sections/Title';
import Schedule from '../sections/Schedule';
import Tags from '../sections/Tags';
import Button from '@/components/Button';
import Description from '../sections/Description';
import Breadcrumb from '@/components/Breadcrumb';
import { convertNoteToNoteArg } from '@/models/NoteArg';
import { useNotes } from '@/hooks/useNotes';
import { useSettings } from '@/hooks/useSettings';
import useLeftSidebar from '../../useLeftSidebar';
import type { Note } from '@/models/NoteModel';
import ReferenceList from '../sections/References';
import ToggleableSection from '../sections/ToggleableSection';
import { formatDateRangeTimeSimple } from '@/utils/date';
import { FaCalendarAlt, FaClipboardList } from 'react-icons/fa';
import { Divider } from '@/components/Divider';

type Props = {
  id?: string;
};

export default function NoteEditor({ id }: Props) {
  const { defaultTimeZone: timeZone } = useSettings();
  const { openLeftSidebar } = useLeftSidebar();
  const { closeLeftSidebar } = useLeftSidebar();

  const [tempNote, setTempNote] = useState<Note>({
    id: '',
    title: 'Untitled Note',
    content: {},
    itemType: 'NOTE',
    priority: $Enums.Priority.NO_PRIORITY,
    start: moment.tz(timeZone).startOf('day').toDate(),
    end: moment.tz(timeZone).startOf('day').add(1, 'day').toDate(),
    linked: [],
    projectIds: [],
    eventIds: [],
    taskIds: [],
    noteIds: [],
    allDay: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  } satisfies Note);

  const { notes, createNote, isCreatingNote, resetCreateNote, updateNote, isUpdatingNote, resetUpdateNote } = useNotes({
    onCreated: (note) => {
      closeLeftSidebar();
      toast(<ItemCreated item={note} />, {
        position: 'bottom-center',
        style: { background: 'black' },
      });
      resetCreateNote();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Note not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateNote();
    },
    onUpdated: () => {
      toast(<ToastGeneric title="Note updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      closeLeftSidebar();
      resetUpdateNote();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Note not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateNote();
    },
  });

  const skipEffect = useRef(false);
  useEffect(() => {
    if (!id || skipEffect.current) return;
    const note = notes?.results.find((note) => note.id === id) as Note;
    if (!note) return;
    skipEffect.current = true;
    setTempNote(note);
  }, [id, notes?.results]);

  const path = useMemo(() => {
    return [
      {
        name: 'Notes',
        onClick: () => {
          openLeftSidebar({ type: 'ALL_NOTES' });
        },
      },
      { name: tempNote?.title || '' },
    ];
  }, [openLeftSidebar, tempNote?.title]);

  const handleOnSave = useCallback(() => {
    const noteArg = convertNoteToNoteArg(tempNote);

    if (!id) {
      createNote(noteArg);
      return;
    }

    updateNote({ noteId: id, note: noteArg });
  }, [createNote, id, tempNote, updateNote]);

  return (
    <section className="relative flex flex-col h-full">
      <Header id={tempNote.id} itemType="NOTE" />
      <article className="flex-1 p-6 overflow-auto">
        <div className="flex flex-col gap-3">
          <Breadcrumb path={path} />
          <Title
            placeholder="Untitled Note"
            type="NOTE"
            title={tempNote.title}
            onChange={(title) => setTempNote({ ...tempNote, title })}
          />
        </div>
        <Description
          value={JSON.stringify(tempNote.content) as string}
          alwaysActive
          onChange={(content) => setTempNote({ ...tempNote, content })}
        />
        <Divider />
        <ToggleableSection 
          summary={tempNote.allDay ? formatDateRangeTimeSimple(tempNote.start, tempNote.end as Date, false, true) : tempNote.start && tempNote.end ? formatDateRangeTimeSimple(tempNote.start, tempNote.end, false) : ''}
          title="Scheduling this note" 
          icon={FaCalendarAlt}
        >
          <Schedule
            allDay={tempNote.allDay}
            start={tempNote.start}
            end={tempNote.end}
            onChange={(value) => setTempNote({ ...tempNote, ...value })}
          />
        </ToggleableSection>
        <Tags
          hideContacts
          hideLocation
          projectIds={tempNote.projectIds}
          priority={tempNote.priority}
          onChange={(value) => setTempNote({ ...tempNote, ...value })}
        />
        <ToggleableSection title="Related Items" icon={FaClipboardList}>
          <ReferenceList value={tempNote.linked} />
        </ToggleableSection>
      </article>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="shrink-0 mt-auto flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t">
        <Button variant="primary" onClick={handleOnSave} disabled={isCreatingNote || isUpdatingNote}>
          Save Form
        </Button>
      </footer>
    </section>
  );
}
