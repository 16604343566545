import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { AddNewIcon } from '@/icons/AddNewIcon';
import { FC } from 'react';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';

type Props = {
  title: string;
  Icon: FC<{ className?: string; size: number }>;
  onAddNew?: () => void;
  onClose: () => void;
};

export default function EntityHeader({ title, Icon, onAddNew, onClose }: Props) {
  return (
    <header className="flex flex-col gap-6 px-4 py-6 h-[140px] border-b-2 border-gray-muted">
      <div className="flex items-center justify-between">
        {onAddNew ? (
          <div className="flex gap-[10px] mr-[15px]">
            <div
              onClick={onAddNew}
              className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-gray-muted rounded-[14px]">
              <AddNewIcon />
            </div>
          </div>
        ) : null}
        <ToolTip tooltip="Close Sidebar">
          <button type="button" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center px-[15px]">
        <Icon className="mr-[8px]" size={14} />
        <p className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">{title}</p>
      </div>
    </header>
  );
}
