import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';
import { useSetAtom } from 'jotai';
import { activeSectionsAtom } from './atoms/activeSections';
import { ItemType } from '@/models/shared';
import { useDeleteTask } from '@/hooks/useTasks';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { toast } from 'react-hot-toast';
import { ToastGeneric } from '@/components/ToastGeneric';
import { useDeleteEvent } from '@/hooks/useEvents';
import { useDeleteSchedulingLink } from '@/hooks/useSchedulingLinks';
import { useNotes } from '@/hooks/useNotes';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { useCallback } from 'react';
import { RecurringInstance } from '@/models/EventModel';
import useModal from '@/hooks/useModal';

export default function Header({
  id,
  itemType,
  isRecurring,
  originalStartEvent,
}: {
  id: string;
  itemType: ItemType;
  isRecurring?: boolean;
  originalStartEvent?: Date;
}) {
  const { isOpen, openModal, closeModal } = useModal();
  const { closeLeftSidebar } = useLeftSidebar();
  const { deleteTask } = useDeleteTask();
  const { deleteEvent } = useDeleteEvent();
  const { deleteSchedulingLink } = useDeleteSchedulingLink();
  const { deleteNote } = useNotes();
  const closeSections = useSetAtom(activeSectionsAtom);

  const handleClose = () => {
    closeSections({
      AUTO_SCHEDULE: false,
      TIME_BLOCKING: false,
      TAGS: false,
      REFERENCES: false,
      DESCRIPTION: false,
      SCHEDULE: false,
      GUESTS: false,
      SETTINGS: false,
      INVITEE_QUESTIONS: false,
      SLOTS_SETTINGS: false,
    });
    closeLeftSidebar();
  };

  const deleteItem = async () => {
    switch (itemType) {
      case 'TASK': {
        if (isRecurring) {
          openModal();
          return;
        }
        if (!id) return;
        deleteTask({ taskId: id });
        closeLeftSidebar();
        toast(<ToastGeneric title="Deleted Task" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        break;
      }
      case 'EVENT': {
        if (isRecurring) {
          openModal();
          return;
        }
        if (!id) return;
        await deleteEvent({ eventId: id });
        closeLeftSidebar();

        toast(<ToastGeneric title="Deleted Event" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        break;
      }
      case 'SCHEDULING_LINK': {
        await deleteSchedulingLink({ schedulingLinkId: id });
        closeLeftSidebar();
        toast(<ToastGeneric title="Deleted Scheduling Link" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        break;
      }
      case 'NOTE': {
        await deleteNote({ noteId: id });
        closeLeftSidebar();
        toast(<ToastGeneric title="Deleted Note" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        break;
      }
      default:
        break;
    }
  };

  const confirmDelete = useCallback(
    async (picked?: RecurringInstance) => {
      switch (itemType) {
        case 'EVENT': {
          await deleteEvent({ eventId: id, recurringDeletion: picked, originalStart: originalStartEvent });

          closeModal();
          closeLeftSidebar();
          toast(<ToastGeneric title="Deleted Event" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }
        case 'TASK': {
          if (!id) return;
          if (!originalStartEvent) return;

          await deleteTask({ taskId: id, recurringDeletion: picked, dateDelete: originalStartEvent });

          closeModal();
          closeLeftSidebar();
          toast(<ToastGeneric title="Deleted Task" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }
      }
    },
    [itemType, deleteEvent, id, originalStartEvent, closeModal, closeLeftSidebar, deleteTask]
  );

  return (
    <header className="flex items-center justify-between px-4 xs:px-2 py-2.5 bg-white border-b-2 border-solid shrink-0 xs:w-[75%] sm:w-[90%] md:w-full">
      {id || isRecurring ? (
        <ToolTip tooltip={`Delete ${itemType?.toLowerCase()}`}>
          <button type="button" onClick={deleteItem}>
            <DeleteIcon className="hover:fill-red-500 transition-all" />
          </button>
        </ToolTip>
      ) : null}
      <ToolTip tooltip="Close Sidebar">
        <button className="ml-auto" type="button" onClick={handleClose}>
          <MdKeyboardDoubleArrowLeft color="#A1A1A3" className="-m-3" size={48} />
          <KeyboardShortcutIndicator shortcut="Esc" />
        </button>
      </ToolTip>
      <div onClick={(e) => e.stopPropagation()}>
        {isOpen ? (
          isRecurring ? (
            <ConfirmationModal
              modalText="This is a recurring event. What do you want to do?"
              isARecurrentEvent={true}
              onNo={closeModal}
              onYesRecurrent={confirmDelete}
            />
          ) : (
            <ConfirmationModal
              modalText="Are you sure you want to delete this event? This action cannot be undone."
              onNo={closeModal}
              onYes={confirmDelete}
            />
          )
        ) : null}
      </div>
    </header>
  );
}
