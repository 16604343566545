import { useMemo } from 'react';
import { httpGet, httpPut } from 'utils/smarty-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Availability, UpdateAvailabilityInput } from '@/models/AvailabilityModel';
import { useEasySession } from './useEasySession';

const getAvailability = () => httpGet<Availability[]>(`/settings/availability`);

const updateAvailability = (payload: UpdateAvailabilityInput) =>
  httpPut<void, Availability[], UpdateAvailabilityInput>(`/settings/availability`, payload);

export const useAvailability = () => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: availabilityIncludingDisabled, isLoading } = useQuery({
    queryKey: ['AVAILABILITY'],
    queryFn: getAvailability,
    enabled: isAuthenticated,
  });

  const { mutate: saveAvailability } = useMutation({
    mutationKey: ['AVAILABILITY_UPDATE'],
    mutationFn: updateAvailability,
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['AVAILABILITY'] });
      const previous = queryClient.getQueryData<Availability[]>(['AVAILABILITY']);
      queryClient.setQueryData(['AVAILABILITY'], () => payload);
      return previous;
    },
    onError: (error, variables, previous) => {
      if (previous) {
        queryClient.setQueryData(['AVAILABILITY'], previous);
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['AVAILABILITY'] });
    },
  });

  const availability = useMemo(() => {
    return availabilityIncludingDisabled?.filter((av) => av.isEnabled);
  }, [availabilityIncludingDisabled]);

  const defaultAvailability = useMemo(() => {
    return availabilityIncludingDisabled?.find((av) => av.isDefault);
  }, [availabilityIncludingDisabled]);

  return {
    availability,
    defaultAvailability,
    availabilityIncludingDisabled,
    saveAvailability,
    isLoading,
  };
};
