type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const UnscheduledIcon = ({ className, size = 16, color = '#A1A1A3' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8342 0.963928C13.1351 0.964053 13.4309 1.0488 13.693 1.20999C13.9551 1.37117 14.1746 1.60335 14.3305 1.88411L14.3996 2.01966L17.4726 8.72534C17.6242 9.05592 17.7158 9.4154 17.7421 9.78393L17.75 10.0054V15.2821C17.7501 15.7638 17.5834 16.2277 17.2832 16.5808C16.983 16.934 16.5715 17.1503 16.1312 17.1864L16 17.1912H2C1.5585 17.1914 1.13325 17.0094 0.809521 16.682C0.485788 16.3545 0.287489 15.9056 0.254376 15.4253L0.250001 15.2821V10.0054C0.249736 9.63565 0.315106 9.26936 0.442501 8.92675L0.527376 8.72438L3.60038 2.01966C3.73492 1.72571 3.93673 1.47462 4.18629 1.29067C4.43584 1.10673 4.72468 0.996158 5.02488 0.969656L5.16575 0.963928H12.8342ZM5.5 10.5094H2V15.2821H16V10.5094H12.5V10.9867C12.5 11.3664 12.3617 11.7306 12.1156 11.9991C11.8694 12.2676 11.5356 12.4185 11.1875 12.4185H6.8125C6.4644 12.4185 6.13056 12.2676 5.88442 11.9991C5.63828 11.7306 5.5 11.3664 5.5 10.9867V10.5094ZM12.8342 2.87302H5.16575L2.54075 8.60029H5.9375C6.26377 8.60031 6.57834 8.7329 6.81987 8.9722C7.06139 9.2115 7.21255 9.54037 7.24388 9.89466L7.25 10.0321V10.5094H10.75V10.0321C10.75 9.67618 10.8716 9.33301 11.0909 9.06953C11.3103 8.80605 11.6117 8.64114 11.9365 8.60697L12.0625 8.60029H15.4592L12.8342 2.87302Z"
      fill={color}
    />
  </svg>
);
