import { useMemo } from 'react';
import moment from 'moment-timezone';
import ScheduleDurationPicker from './ScheduleDurationPicker';
import DateRangePicker from './DateRangePicker';
import { NameEmail } from '@/utils/contacts';

import { useSettings } from '@/hooks/useSettings';
import { SchedulingWhen } from '@prisma/client';
import { useAvailability } from '@/hooks/useAvailability';
import ToggleableSection from '../sections/ToggleableSection';
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { findTimeZone } from '@/utils/date';
import { SchedulingLinkArg } from '@/models/SchedulingLinkArg';


type Props = {
  scheduleId?: string;
  duration?: number;
  start?: Date | null;
  end?: Date | null;
  contacts?: NameEmail[];
  calendarIds?: string[];
  when?: SchedulingWhen;
  useCustomSchedule?: boolean;
  recurrence?: SchedulingLinkArg['recurrence'];
  onChange: (data: {
    scheduleId?: string;
    duration?: number;
    start?: Date | null;
    end?: Date | null;
    when?: SchedulingWhen;
  }) => void;
};

export default function Schedule({
  start,
  end,
  scheduleId,
  duration,
  calendarIds,
  contacts,
  when,
  recurrence,
  onChange,
  useCustomSchedule,
}: Props) {
  const { defaultTimeZone: timeZone } = useSettings();
  const { availability } = useAvailability();

  const contactEmails = useMemo(() => contacts?.map((contact) => contact.email) || [], [contacts]);
  const availabilitySchedule = availability?.find((a) => a.id === scheduleId);

  const timeZoneData = findTimeZone(timeZone);

  const scheduleString = `${when === 'RECURRING_SLOTS' ? 'Personal Link:' : 'One Time Link:'} ${availabilitySchedule?.title} using ${timeZoneData?.abbr} for ${duration} minutes between ${moment.tz(start, timeZone).format('MM/DD/YYYY')}${end ? ` and ${moment.tz(end, timeZone).format('MM/DD/YYYY')}` : ''}`;

  return (
    <ToggleableSection title="Availability, Duration & Timezone" summary={scheduleString} icon={BsFillGrid1X2Fill}>
      <section className="flex flex-col gap-3 py-5">
        <ScheduleDurationPicker
          useCustomSchedule={useCustomSchedule}
          duration={duration || 30}
          scheduleId={scheduleId || ''}
          onChange={onChange}
        />
        <DateRangePicker
          calendarIds={calendarIds || []}
          contactEmails={contactEmails}
          duration={duration}
          recurrence={recurrence}
          scheduleId={scheduleId}
          start={start}
          end={end}
          when={when}
          useCustomSchedule={useCustomSchedule}
          onChange={onChange}
        />
      </section>
    </ToggleableSection>
  );
}