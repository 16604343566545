import { atom, useAtom } from 'jotai';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useCallback } from 'react';

type Action = (result: DropResult, provided: ResponderProvided) => void;

const dropActionsAtom = atom<Action[]>([]);

export default function useDropActions() {
  const [dropActions, setDropActions] = useAtom(dropActionsAtom);

  const addAction = useCallback(
    (action: Action) => {
      setDropActions((actions) => [...actions, action]);
      return action;
    },
    [setDropActions]
  );

  const removeAction = useCallback(
    (action: Action) => {
      setDropActions((actions) => actions.filter((a) => a !== action));
    },
    [setDropActions]
  );

  const dropHandler = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      dropActions.forEach((action) => action(result, provided));
    },
    [dropActions]
  );

  return {
    dropHandler,
    addAction,
    removeAction,
  };
}
