import Key from '@/components/Key';
import { SearchIcon } from '@/icons/SearchIcon';
import { Command } from 'cmdk';
import { useMemo } from 'react';

const SHORTCUTS = [
  ['Select command line on list view', ['/', 'c']],
  ['Command Menu', ['cmd', 'k']],
  ['Create task', ['/', '-']],
  ['Change view', ['/', ' space ']],
  ['Open left sidebar (on calendar view)', ['/', '<']],
  ['Open settings sidebar', ['/', '>']],
  ['Close sidebar', [' esc ']],
  ['Show contacts', ['/', 'b']],
  ['Add a contact', ['/', '⇧', 'B']],
  ['Open filter', ['/', 'f']],
  ['Show groups', ['/', 'g']],
  ['Add a group', ['/', '⇧', 'G']],
  ['Open calendar sidebar', ['/', 'h']],
  ['Create event', ['/', '⇧', 'H']],
  ['Show notes', ['/', 'n']],
  ['Create a note', ['/', '⇧', 'N']],
  ['Show projects', ['/', 'p']],
  ['Create a project', ['/', '⇧', 'P']],
  ['Show scheduling links', ['/', 's']],
  ['Create a scheduling link', ['/', '⇧', 'S']],
  ['Open unscheduled panel', ['/', 'u']],
  ['Questions or have feedback?', ['/', 'q']],
  ['Show Keyboard Shortcuts sidebar', ['/', 'k']],
] as const;

export const Shortcuts = () => {
  const renderedItems = useMemo(() => {
    return SHORTCUTS.map(([description, ...shortcuts], index) => (
      <Command.Group className="px-2.5" key={index}>
        <Command.Item className="flex justify-between p-1">
          {description}
          <div className="inline-flex items-center">
            {shortcuts.map((keys, index) => (
              <span className="flex items-center gap-1 text-sm" key={index}>
                {index !== 0 ? ' or ' : null}
                {keys.map((key, index) => (
                  <Key key={index}>{key}</Key>
                ))}
              </span>
            ))}
          </div>
        </Command.Item>
      </Command.Group>
    ));
  }, []);

  return (
    <div className="flex flex-col flex-1 min-h-0 gap-5 px-2.5">
      <h1 className="text-2xl font-semibold leading-none">
        <span className="font-normal text-gray-500">⌘</span> Keyboard Shortcuts
      </h1>
      <Command className="pb-5 overflow-y-scroll">
        <div className="flex items-center gap-2 px-2 py-2.5 rounded-lg border border-gray-300 mb-2.5">
          <SearchIcon aria-hidden size={24} />
          <Command.Input placeholder="Search for keyboard shortcuts" autoFocus className="flex-1 focus:outline-none" />
        </div>
        <Command.List className="font-medium leading-normal">
          <Command.Empty className="text-center p-2 py-2.5">No shorcuts found.</Command.Empty>
          {renderedItems}
        </Command.List>
      </Command>
    </div>
  );
};
