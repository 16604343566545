import { useRef } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';
import { cn } from 'ui/cn';

type Props = {
  id?: string;
  value?: string;
  onChange?: (selectedOption: string) => void;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  primaryColor?: string;
  labelInput?: string;
  placeholder?: string;
  usOnly?: boolean;
  mode: 'full-address' | 'locality';
  name: string;
};

export const PlacesAutocomplete = ({
  id,
  value,
  onChange,
  disabled,
  className,
  inputClassName,
  primaryColor = '#279AF1',
  labelInput,
  placeholder,
  usOnly = false,
  mode,
  name,
}: Props) => {
  const {
    ready,
    value: searchText,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: usOnly ? ['us', 'pr', 'vi', 'gu', 'mp'] : null,
      },
      types: mode === 'locality' ? ['locality', 'country'] : undefined,
    },
    debounce: 300,
    defaultValue: value,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  useHandleClickOutside(containerRef, () => {
    clearSuggestions();
  });

  const handleInputChange = (value: string) => {
    setValue(value);
  };

  const handleSelect = ({ description }: any) => {
    setValue(description, false);
    onChange?.(description ?? '');
    clearSuggestions();
  };

  return (
    <div id={id} className={cn('relative w-full', className)} ref={containerRef}>
      <div
        className={cn(
          `relative flex w-full border rounded-lg bg-white focus-within:border-[${primaryColor}]`, // TODO(hmassad) fix focus-within:border-[${primaryColor}]
          inputClassName,
          disabled ? 'cursor-not-allowed' : ''
        )}
        style={{ caretColor: primaryColor }}>
        <label className="absolute -top-1.5 left-4 flex rounded-2xl bg-white px-1 text-[8px] leading-[12px] transition-all text-gray-border">
          {labelInput}
        </label>
        <div className="flex w-full items-center justify-between gap-2.5 px-4 text-sm">
          <input
            className={cn(
              'min-h-[44px] w-full bg-transparent text-sm font-medium placeholder:text-gray-border focus:outline-none',
              disabled && 'cursor-not-allowed'
            )}
            placeholder={searchText ? '' : placeholder}
            type="text"
            name={name}
            value={searchText}
            onChange={(e) => handleInputChange(e.target.value)}
            disabled={disabled || !ready}
          />
        </div>
      </div>

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' ? (
        <div className="absolute mt-1 max-h-[200px] w-full overflow-hidden overflow-y-scroll rounded-xl bg-white shadow-lg z-20">
          <ul>
            {data.map((suggestion, index: number) => {
              const {
                place_id,
                structured_formatting: { main_text, secondary_text },
              } = suggestion;

              return (
                <li
                  key={place_id}
                  onClick={() => handleSelect(suggestion)}
                  className="flex h-10 cursor-pointer items-center px-3.5 py-2.5 text-sm font-light">
                  <div className={cn('w-full truncate px-1.5 py-2.5', index != 0 && 'border-t-2')}>
                    <span className="text-sm font-bold">{main_text} </span>
                    <span className="text-xs font-light">{secondary_text}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
