import { FormikProps } from 'formik';
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import { cn } from 'ui/cn';

const InputField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    formik?: FormikProps<any>;
    label?: string;
    name: string;
    inputClassName?: string;
    icon?: any;
  }
>((props, forwardedRef) => {
  const { formik, name, type, label, icon, inputClassName, ...rest } = props;
  const isNotTextArea = type !== 'textarea';
  const formikValue = formik?.values[name];
  const formikError = formik?.errors[name];
  return (
    <div className={`${rest.className} relative`}>
      {label ? (
        <label className={`block font-medium leading-6 text-gray-700 ${isNotTextArea ? 'text-[14px]' : ''}`}>
          {label}
        </label>
      ) : null}
      <div className="relative">
        {icon ? (
          <div
            className={`absolute inset-y-0 left-0 pl-3 pointer-events-none ${
              isNotTextArea ? 'flex items-center' : 'mt-2'
            }`}>
            <span className="text-gray-500">{icon}</span>
          </div>
        ) : null}
        {isNotTextArea ? (
          <input
            {...rest}
            ref={forwardedRef as ForwardedRef<HTMLInputElement>}
            name={name}
            type={type}
            value={formik ? formikValue : rest.value}
            onChange={formik ? formik.handleChange : rest.onChange}
            className={cn(
              `block w-full rounded-md border-0 py-1.5 px-[8px] text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`,
              inputClassName,
              icon && 'pl-8'
            )}
          />
        ) : (
          <textarea
            {...rest}
            ref={forwardedRef as ForwardedRef<HTMLTextAreaElement>}
            name={name}
            value={formik ? formikValue : rest.value}
            onChange={formik ? formik.handleChange : rest.onChange}
            className={cn(
              `block w-full rounded-md border-0 py-1.5 px-[8px] text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`,
              inputClassName,
              icon && 'pl-8'
            )}
          />
        )}
        {formik?.touched[name] && formik?.errors[name] ? (
          <div className="absolute top-[calc(100%+2px)] right-0 text-[12px] text-red-600 leading-none">
            {typeof formikError === 'string' ? formikError : null}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
});

InputField.displayName = 'InputField';
export default InputField;
