import { useCallback } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { rightSidebarStateAtom } from '../RightSidebar';
import { calendarTypeAtom, eventBeingCreatedAtom, slotsAtom } from '../calendarAtoms';
import { useRouter } from 'next/router';

export type LeftSidebarType =
  | 'SETTINGS'
  | 'ALL_CONTACTS'
  | 'CONTACT_EDITOR'
  | 'GROUP_EDITOR'
  | 'ALL_GROUPS'
  | 'ALL_SCHEDULING_LINKS'
  | 'PROJECTS'
  | 'NEW_PROJECT'
  | 'CONTACT'
  | 'NOTE'
  | 'NOTE_EDITOR'
  | 'ALL_NOTES'
  | 'ALL_EMAILS'
  | 'EMAIL_DETAIL'
  | 'TRACKABLE_LINKS'
  | 'WORKFLOW_EDITOR'
  | 'COMPOSE'
  | 'UNSCHEDULED'
  | 'TASK_EDITOR'
  | 'EVENT_TEMPLATE'
  | 'SCHEDULING_LINK_EDITOR'
  | 'EVENT_EDITOR'
  | 'BOOKING_EDITOR'
  | 'SINGLE_DAY_CALENDAR'
  | 'SHORTCUTS'
  | 'FEEDBACK';

export const leftSidebarStateAtom = atom<{
  show: boolean;
  type?: LeftSidebarType;
  context?: { [key: string]: any };
}>({
  show: false,
});

export default function useLeftSidebar() {
  const router = useRouter();
  const [leftSidebarState, setLeftSidebarState] = useAtom(leftSidebarStateAtom);
  const closeRightSidebar = useSetAtom(rightSidebarStateAtom);
  const setEventBeingCreated = useSetAtom(eventBeingCreatedAtom);
  const setSlots = useSetAtom(slotsAtom);
  const setCalendarType = useSetAtom(calendarTypeAtom);

  const closeLeftSidebar = useCallback(() => {
    setSlots([]);
    // if closing the sidebar from the scheduling link editor, go back to CalendarView
    if (router.asPath.startsWith('/scheduling-link')) {
      setCalendarType('SPECIFIC');
      void router.push(`/calendar`, undefined, { shallow: true });
    }
    if (router.asPath.startsWith('/calendar')) {
      setLeftSidebarState({
        show: false,
      });
      setEventBeingCreated(null);
      return;
    }
    setLeftSidebarState({
      show: false,
    });
    setEventBeingCreated(null);
  }, [router, setCalendarType, setEventBeingCreated, setLeftSidebarState, setSlots]);

  const openLeftSidebar = useCallback(
    (args?: { type?: LeftSidebarType; context?: { [key: string]: any } }) => {
      closeRightSidebar({
        show: false,
      });

      if (args?.type === 'SCHEDULING_LINK_EDITOR' && router.asPath.startsWith('/calendar')) {
        void router.push(`/scheduling-link/${args?.context?.id ?? 'new'}`, undefined, { shallow: true });
      }

      setLeftSidebarState({
        show: true,
        ...args,
      });
    },
    [closeRightSidebar, router, setLeftSidebarState]
  );

  return {
    openLeftSidebar,
    closeLeftSidebar,
    leftSidebarState,
  };
}
