import { useSettings } from '@/hooks/useSettings';
import { formatDateRangeDecorator } from '@/utils/date';
import { ClockIcon } from '@/icons/ClockIcon';
import { useCallback } from 'react';
import DatePicker from './DatePicker';

type Props = {
  start: Date;
  end?: Date | null;
  allDay: boolean;
  onChangeDates: (start: Date, end: Date | null, allDay: boolean) => void;
  forceShowDate?: boolean;
};

export default function DateRangeDecorator({ onChangeDates, forceShowDate, ...item }: Props) {
  const { defaultTimeZone: timeZone } = useSettings();

  const renderDecorator = useCallback(
    (_: unknown, onClick: () => void) => (
      <button
        className="bg-select-light-gray rounded px-1 inline-flex align-top items-center gap-1 text-[14px] text-gray-500 whitespace-nowrap select-none"
        onClick={onClick}
        title={`start: ${item.start}\nend: ${item.end}`}>
        <ClockIcon />
        {formatDateRangeDecorator({
          timeZone: timeZone,
          allDay: item.allDay,
          start: item.start,
          end: item.end,
          forceShowDate,
        })}
      </button>
    ),
    [item.allDay, item.end, item.start, timeZone, forceShowDate]
  );

  return <DatePicker {...item} onChangeDates={onChangeDates} renderButton={renderDecorator} />;
}
