import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import cuid from 'cuid';
import { atom, useSetAtom } from 'jotai';
import { Mention, MentionItem, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem } from 'ff-mentions';
import { FaNetworkWired } from 'react-icons/fa';
import { MdEmail, MdLocationOn, MdOutlineSignalCellularAlt } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { EventVisibility, Priority, TaskScheduleType, TimeBlockingType } from '@prisma/client';
import { editorFor } from '@/components/LeftSidebar';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { NameEmail } from '@/utils/contacts';
import { findTimeZone, roundToNextBlock } from '@/utils/date';
import { capitalize, removeMultipleSpaces, removeRange, removeRanges } from 'utils/string';
import { useProjects } from '@/hooks/useProjects';
import { NoteIcon } from '@/icons/NoteIcon';
import { ClockIcon } from '@/icons/ClockIcon';
import { EventIcon } from '@/icons/EventIcon';
import { TaskIcon } from '@/icons/TaskIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { Popover } from '@/components/Popover';
import { useSettings } from '@/hooks/useSettings';
import { useDeleteTask, useTasks } from '@/hooks/useTasks';
import { useDeleteEvent, useEvents } from '@/hooks/useEvents';
import { useCalendars } from '@/hooks/useCalendars';
import { useDeleteSchedulingLink, useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { useAvailability } from '@/hooks/useAvailability';
import useModal from '@/hooks/useModal';
import { generateSlots } from '@/utils/scheduling';
import { InfoIcon } from '@/icons/InfoIcon';
import { RightSidebarIcon } from '@/icons/RightSidebarIcon';
import ProjectIcon from '@/icons/ProjectIcon';
import { TaskArg } from '@/models/TaskArg';
import { ParagraphText } from '../Typography';
import { GuestsNotificationsModal } from '../GuestsNotificationsModal';
import { useNotes } from '@/hooks/useNotes';
import { Task } from '@/models/TaskModel';
import { Event } from '@/models/EventModel';
import { SchedulingLink } from '@/models/SchedulingLinkModel';
import { ConferencingData, EventArg } from '@/models/EventArg';
import Button from '../Button';
import { useShortcuts } from '@/context/Common';
import KeyboardShortcutIndicator from '../KeyboardShortcutsIndicator';
import { cn } from 'ui/cn';
import { Note } from '@/models/NoteModel';
import { calculateEventColor } from '@/models/GoogleColorsModel';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { useSegment } from '@/components/useSegment';
import { AutoScheduledItemBeingCreated, ItemCreated } from '../ToastItemCreated';

import { MenuModal } from '../MenuModal';
import { CommandBarIcon } from '@/icons/CommandBarIcon';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { ToastGeneric } from '../ToastGeneric';
import useReferences from '@/hooks/useReferences';
import {
  CONFERENCE_SUGGESTIONS,
  ITEM_TYPE_SUGGESTIONS,
  PRIORITY_SUGGESTIONS,
  TIME_BLOCKING_SUGGESTIONS,
  VISIBILITY_TYPE_SUGGESTIONS,
  buildSuggestionId,
  extractSuggestionTypeAndValue,
  filterBySearch,
  getAutoScheduleSuggestions,
  getDatesSuggestions,
  getDurationSuggestions,
  getRecurrenceSuggestions,
  getSubmenuSuggestions,
  getSuggestedContacts,
  getSuggestedCustomSchedules,
  getSuggestedFreeSlots,
  getSuggestedLocation,
} from './commands';
import { useConferencingOptions } from './useConferencingOptions';
import { ReferenceItemType } from '../Overlay/types';
import { generateRandomColor } from '@/utils/generateRandomColor';

const ALL_ITEM_TYPES = ['TASK', 'EVENT', 'SCHEDULING_LINK', 'NOTE', 'SEARCH'] as const;
type ItemType = (typeof ALL_ITEM_TYPES)[number];

type NewCommandBoxProps = {
  className?: string;
  use: 'create' | 'update' | 'allow-unscheduled-task';
  initialItemType?: ItemType;
  item?: Task | Event | SchedulingLink | Note;
  disableSlash?: boolean;
  hideRightSection?: boolean;
  placeholder?: string;
  onSidebar?: boolean;
  defaultValue?: string;
  autoFocus?: boolean;
  hideTooltip?: boolean;
  projectsIds?: string[];
  disabled?: boolean;
  isSearchEnabled?: boolean;
  onChangeItemType?: (value: ItemType) => void;
  onChangeMentionType?: (value: boolean) => void;
  reference?: {
    type: ReferenceItemType;
    id: string;
  } | null;
};

export const menuStateAtom = atom(false);

const iconByItemType = {
  TASK: <TaskIcon color="black" />,
  EVENT: <EventIcon color="black" />,
  SCHEDULING_LINK: <SchedulingLinkIcon color="black" />,
  NOTE: <NoteIcon color="#000000" />,
  EMAIL: <MdEmail color="black" />,
  WORKFLOW: <FaNetworkWired color="black" />,
  SEARCH: <CommandBarIcon size={20} color="black" />,
} as Record<ItemType, ReactNode>;

function renderAtSuggestion(
  suggestion: SuggestionDataItem
  // search: string,
  // highlightedDisplay: ReactNode,
  // index: number,
  // focused: boolean
) {
  return (
    <div key={suggestion.id} className="p-1 text-xs leading-snug pl-11">
      {suggestion.display}
    </div>
  );
}

function renderAtCategory(category: string) {
  let icon;
  switch (category) {
    case 'priority':
      icon = <MdOutlineSignalCellularAlt color="black" />;
      break;
    case 'duration':
    case 'details':
      icon = <ClockIcon color="black" />;
      break;
    case 'date':
      icon = <ClockIcon color="black" />;
      break;
    case 'projects':
      icon = <ProjectIcon color="black" />;
      break;
    case 'contacts':
      icon = <ContactsIcon color="black" />;
      break;
    case 'location':
      icon = <MdLocationOn color="black" />;
      break;
  }

  return (
    <div key={category} className="flex items-center gap-2 px-3 py-2.5 text-sm">
      {icon} {capitalize(category)}
    </div>
  );
}

type DateRange = { start: Date; end?: Date | null; allDay?: boolean };

export const NewCommandBox = ({
  className = '',
  initialItemType,
  use,
  item,
  disableSlash,
  hideRightSection,
  placeholder: _placeholder,
  onSidebar,
  defaultValue,
  autoFocus,
  hideTooltip,
  projectsIds,
  disabled,
  isSearchEnabled,
  onChangeItemType,
  reference,
}: NewCommandBoxProps) => {
  const { trackEvent } = useSegment();
  const { defaultTimeZone: timeZone } = useSettings();
  const mentionInput = useRef<HTMLInputElement>(null);
  const [itemType, setItemType] = useState<ItemType>(initialItemType || 'TASK');
  const [taskType, setTaskType] = useState<string>('');
  const [editorKey, setEditorKey] = useState(cuid()); // force re-creation of editor
  const [isCompleted, setCompleted] = useState(
    use === 'create' || use === 'allow-unscheduled-task'
      ? false
      : itemType === 'TASK'
        ? (item as Task).isCompleted
        : false
  );
  const [isLoading, setLoading] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const { projects, createProject } = useProjects({});
  const { defaultDuration, settings } = useSettings();
  const { calendars, defaultCalendar } = useCalendars();
  const { defaultAvailability, availability } = useAvailability();
  const { isOpen, openModal, closeModal } = useModal();
  const { registerShortcut, unregisterShortcut } = useShortcuts();
  const { conferencingSelectorModal, getConferencingDataFromOption } = useConferencingOptions();

  const { openLeftSidebar, closeLeftSidebar } = useLeftSidebar();
  const { createNote, updateNote, resetCreateNote, resetUpdateNote, deleteNote } = useNotes({
    onCreated: (note) => {
      toast(<ItemCreated item={note} isAllDay={true} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      if (!onSidebar) return;
      resetCreateNote();
      closeLeftSidebar();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Note not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateNote();
    },
    onUpdated: (note) => {
      toast(<ItemCreated item={note} isUpdate={true} isAllDay={true} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateNote();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Note not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateNote();
    },
  });

  const { createTask, resetCreateTask, updateTask, resetUpdateTask } = useTasks({
    disableQuery: true,
    onCreated: (task) => {
      toast(<ItemCreated item={task} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateTask();
      if (!onSidebar) return;
      closeLeftSidebar();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Task not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateTask();
      return;
    },
    onUpdated: (task) => {
      toast(<ItemCreated item={task} isUpdate={true} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Task not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateTask();
      return;
    },
  });

  const { deleteTask } = useDeleteTask();
  const { deleteEvent } = useDeleteEvent();
  const { deleteSchedulingLink } = useDeleteSchedulingLink();

  const { createEvent, resetCreateEvent, updateEvent, resetUpdateEvent } = useEvents({
    disableQuery: true,
    onCreated: (event) => {
      if (event.summary) {
        toast(<ItemCreated item={event} />, { position: 'bottom-center', style: { background: '#000' } });
      }
      resetCreateEvent();
      if (!onSidebar) return;
      closeLeftSidebar();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Event not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateEvent();
    },
    onUpdated: (event) => {
      toast(<ItemCreated item={event} isUpdate={true} />, { position: 'bottom-center', style: { background: '#000' } });
      resetUpdateEvent();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Event not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateEvent();
      return;
    },
  });

  const { createSchedulingLink, resetCreateSchedulingLink, updateSchedulingLink, resetUpdateSchedulingLink } =
    useSchedulingLinks({
      onCreated: (schedulingLink) => {
        toast(<ItemCreated item={schedulingLink} />, { position: 'bottom-center', style: { background: '#000' } });
        resetCreateSchedulingLink();

        const groupedSlots =
          schedulingLink.slots?.reduce<{ [key: string]: { start: Date; end: Date | null }[] }>((acc, slot) => {
            const date = moment.tz(slot.start, timeZone).format('YYYY-MM-DD');
            if (acc[date]) {
              acc[date].push(slot);
            } else {
              acc[date] = [slot];
            }
            return acc;
          }, {}) || {};

        const displayedFreeSlots = Object.entries(groupedSlots).splice(0, 3);
        const timeZoneDisplayName = findTimeZone(timeZone)?.abbr || timeZone;

        // Build the plain text version
        const timeZoneText = `Do any of these times (${timeZoneDisplayName}) work for you?\n\n`;
        const slotsText = displayedFreeSlots.map(([date, slots]) => {
          const dateLabel = moment.tz(date, timeZone).format('MMMM DD (dddd)');
          const slotTimes = slots.map(({ start, end }) => {
            const startTime = moment(start).format('h:mm A');
            const endTime = moment(end).format('h:mm A');
            return `• ${startTime} - ${endTime}`;
          });
          return `${dateLabel}\n${slotTimes.join('\n')}`;
        });
        const moreTimesText = `\n\nMore times are listed at this scheduling link instead`;
        const plainText = `${timeZoneText}${slotsText.join('\n\n')}${moreTimesText}`;

        // Build the HTML version
        const linkURL = `${process.env.NEXT_PUBLIC_URL}/${settings?.username}/${schedulingLink.code}`;
        const timeZoneHtml = `<p>Do any of these times (${timeZoneDisplayName}) work for you?</p>`;
        const slotsHtml = displayedFreeSlots.map(([date, slots]) => {
          const dateLabel = `<p>${moment.tz(date, timeZone).format('MMMM DD (dddd)')}</p>`;
          const slotTimes = slots.map(({ start, end }) => {
            const startTime = moment(start).format('h:mm A');
            const endTime = moment(end).format('h:mm A');
            const slotTime = `${startTime} - ${endTime}`;
            return `<p><span class='text-black'>&#8226;</span> <a href='${linkURL}'>${slotTime}</a></p>`;
          });

          return `${dateLabel}${slotTimes.join('')}`;
        });
        const moreTimesHtml = `<p>If it’s easier, you can click on a slot below to book: <br /> <a href='${linkURL}'>scheduling link</a></p>`;
        const htmlText = `${timeZoneHtml}${slotsHtml.join(' ')}${moreTimesHtml}`;

        // Use the copyToClipboard function to copy both versions
        copyToClipboard(htmlText, plainText);

        if (!onSidebar) return;
        closeLeftSidebar();
      },
      onCreateError: () => {
        toast(<ToastGeneric title="Something went wrong, Scheduling Link not created" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetCreateSchedulingLink();
      },
      onUpdated: (schedulingLink) => {
        toast(<ItemCreated item={schedulingLink} isUpdate={true} />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetUpdateSchedulingLink();
      },
      onUpdateError: () => {
        toast(<ToastGeneric title="Something went wrong, Scheduling Link not updated" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetUpdateSchedulingLink();
        return;
      },
    });

  const [mentionInputValue, setMentionInputValue] = useState(() => {
    if (defaultValue) return defaultValue;
    if (use === 'update') return (item as Event)?.summary || (item as SchedulingLink)?.title || '';
    return '';
  });

  const [plainText, setPlainText] = useState(
    use === 'update' ? (item as Event)?.summary || (item as SchedulingLink)?.title || '' : ''
  );

  const {
    ready,
    clearCache,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const [mentions, setMentions] = useState<MentionItem[]>([]);

  const handleAllEmailOpen = useCallback(() => {
    openLeftSidebar({ type: 'ALL_EMAILS' });
    setItemType(initialItemType || 'TASK');
    setCompleted(false);
    setEditorKey(cuid());
    setPlainText('');
    setMentions([]);
    setMentionInputValue('');
    clearSuggestions();
  }, [initialItemType, openLeftSidebar, clearSuggestions]);

  const handleOpenWorkflows = useCallback(() => {
    openLeftSidebar({ type: 'WORKFLOW_EDITOR' });
  }, [openLeftSidebar]);

  /**
   * Create a new task with auto schedule
   * @param values
   */
  const createAutoScheduleTask = useCallback(
    (values: Omit<TaskArg, 'taskScheduleType'>) => {
      createTask({
        ...values,
        taskScheduleType: TaskScheduleType.AUTO_SCHEDULED,
      });
    },
    [createTask]
  );

  const [propsToPass, setPropsToPass] = useState({
    summary: '',
    allDay: false,
    startTimeZone: '',
    endTimeZone: '',
    start: roundToNextBlock(new Date(), 30),
    end: moment(roundToNextBlock(new Date(), 30)).add(defaultDuration, 'minutes').toDate(),
    attendees: [] as NameEmail[],
    calendarId: defaultCalendar?.id,
    reminders: defaultCalendar?.defaultReminders ?? [],
    location: '',
    projectIds: [] as string[],
    emailIds: [] as string[],
    eventIds: [] as string[],
    noteIds: [] as string[],
    taskIds: [] as string[],
  });

  const [eventInfoForUpdate, setEventInfoForUpdate] = useState({
    eventId: '',
    event: {} as EventArg,
  });

  useEffect(() => {
    onChangeItemType?.(itemType);
  }, [itemType, onChangeItemType]);

  const trackCommandBarActionCreate = useCallback(
    (itemType: string) => {
      if (['NOTE', 'TASK', 'EVENT', 'SCHEDULING_LINK'].includes(itemType)) {
        trackEvent('CommandBar / Action Submitted', {
          entity_type: itemType.toLowerCase(),
        });
      }
    },
    [trackEvent]
  );

  const submit = useCallback(
    async (action?: 'auto-schedule' | 'open-sidebar', summary?: string | null) => {
      if (!summary?.trim() && !plainText.trim()) {
        if (!item) {
          toast(<ToastGeneric title="Input field is empty" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          return;
        }
        summary = 'summary' in item ? item.summary : item.title || 'No Title';
      }

      const contacts: NameEmail[] = [];
      const projectIds: string[] = projectsIds ?? [];
      let scheduleIdMentions: string | undefined;
      let dateRange: DateRange | undefined;
      let priority: Priority = Priority.NO_PRIORITY;
      let duration: number | null | undefined;
      let isAutoSchedule = false;
      let locations: string | null | undefined;
      let recurrence: string[] | undefined;
      let timeBlocking: TimeBlockingType = TimeBlockingType.BUSY;
      let calendarId: string | undefined;
      let visibility: EventVisibility | undefined;
      let conferenceData: ConferencingData | null = null;

      const taskIds: string[] = [];
      const eventIds: string[] = [];
      const noteIds: string[] = [];
      const emailIds: string[] = [];

      if (reference) {
        switch (reference.type) {
          case 'EVENT': {
            eventIds.push(reference.id);
            break;
          }
          case 'TASK': {
            taskIds.push(reference.id);
            break;
          }
          case 'NOTE': {
            noteIds.push(reference.id);
            break;
          }
          case 'EMAIL': {
            emailIds.push(reference.id);
            break;
          }
        }
      }

      for (const mention of mentions) {
        const { type, value } = extractSuggestionTypeAndValue(mention.id);

        switch (type) {
          case 'contact': {
            contacts.push({
              email: value || '',
              name: mention.display || '',
            });
            break;
          }
          case 'project': {
            if (value.includes('create_new_project')) {
              let search;
              if (mention.display.includes('Create new project: ')) {
                search = mention.display.split(':')[1].trim();
              } else {
                search = mention.display;
              }
              setLoading(true);
              const newProjectCreated = await createProject({
                name: search,
                eventIds: [],
                taskIds: [],
                color: generateRandomColor(),
                parentProjectId: '',
                schedulingLinksIds: [],
                tasksIds: [],
              });
              setLoading(false);
              projectIds.push(newProjectCreated.id);
            } else {
              projectIds.push(value);
            }
            break;
          }
          case 'schedule': {
            scheduleIdMentions = value;
            break;
          }
          case 'parsedDate': {
            const parts = value.split(/%%%/);
            if (!dateRange) dateRange = {} as DateRange;
            dateRange.start = moment(parts[0]).toDate();
            dateRange.end = parts[1] && parts[1] !== 'undefined' ? moment(parts[1]).toDate() : null;
            dateRange.allDay = parts[2] === 'true' || false;
            break;
          }
          case 'freeSlot': {
            if (!dateRange) dateRange = {} as DateRange;
            dateRange.start = moment(value).toDate();
            dateRange.end = null;
            dateRange.allDay = false;
            break;
          }
          case 'deadline': {
            if (!dateRange) dateRange = {} as DateRange;

            const parts = value.split(/%%%/);

            if (parts.length < 3) {
              dateRange.end = moment(value).toDate();
              if (!dateRange.start) dateRange.start = moment(value).subtract(30, 'minutes').toDate();
              break;
            }

            dateRange.end = moment(parts[0]).toDate();
            dateRange.allDay = parts[2] === 'true' || undefined;

            if (!dateRange.start) dateRange.start = moment(parts[0]).subtract(30, 'minutes').toDate();
            break;
          }
          case 'priority': {
            priority = value as Priority;
            break;
          }
          case 'duration': {
            duration = Number(value);
            break;
          }
          case 'auto-schedule': {
            isAutoSchedule = true;
            if (!value) continue;
            const parts = value.split(/%%%/);
            if (!dateRange) dateRange = {} as DateRange;
            if (!mention.display.includes('before')) {
              dateRange.start = moment(parts[0]).toDate();
            }
            dateRange.end = parts[1] && parts[1] !== 'undefined' ? moment(parts[1]).toDate() : null;
            dateRange.allDay = parts[2] === 'true' || false;
            break;
          }
          case 'location': {
            locations = value;
            break;
          }
          case 'EVENT-REF': {
            eventIds.push(value);
            break;
          }
          case 'TASK-REF': {
            taskIds.push(value);
            break;
          }
          case 'NOTE-REF': {
            noteIds.push(value);
            break;
          }
          case 'recurrence': {
            recurrence = [value];
            break;
          }
          case 'timeBlocking': {
            timeBlocking = value as TimeBlockingType;
            break;
          }
          case 'calendar': {
            calendarId = value;
            break;
          }

          case 'visibility': {
            visibility = value as EventVisibility;
            break;
          }

          case 'conference': {
            conferenceData = await getConferencingDataFromOption(value);
            break;
          }
        }
      }
      if (duration && (dateRange?.allDay || dateRange === undefined)) {
        if (dateRange) {
          dateRange.allDay = false;
        }
        isAutoSchedule = true;
      } else if (!isAutoSchedule && dateRange?.start && (duration || !dateRange?.end)) {
        dateRange.end = moment(dateRange.start)
          .add(duration || defaultDuration, 'minutes')
          .toDate();
      }

      if (use === 'update') {
        if (!item) return;

        if (!dateRange) {
          dateRange = {} as DateRange;
          if ('start' in item) {
            dateRange.start = item.start!;
          }
          if ('end' in item) {
            dateRange.end = item.end || undefined;
          }
          if ('allDay' in item) {
            dateRange.allDay = item.allDay;
          }
        }

        if (item.projectIds?.length) {
          projectIds.push(...item.projectIds);
        }

        if ('contacts' in item && item.contacts?.length) {
          contacts.push(...item.contacts);
        }
        if ('attendees' in item && item.attendees?.length) {
          contacts.push(...item.attendees);
        }
        if ('eventIds' in item && item.eventIds?.length) {
          eventIds.push(...item.eventIds);
        }
        if ('taskIds' in item && item.taskIds?.length) {
          taskIds.push(...item.taskIds);
        }
        if ('noteIds' in item && item.noteIds?.length) {
          noteIds.push(...item.noteIds);
        }
        if (!priority && 'priority' in item) {
          priority = item.priority;
        }
        if (!duration && 'duration' in item) {
          duration = item.duration;
        }
        if (!locations && 'location' in item) {
          locations = item.location || null;
        }
        if (!recurrence && 'recurrence' in item) {
          recurrence = item.recurrence as string[];
        }

        if (action !== 'open-sidebar') {
          if (itemType === item.itemType) {
            if (onSidebar) {
              closeLeftSidebar();
            }

            switch (item.itemType) {
              case 'TASK': {
                let taskScheduleType: TaskScheduleType = TaskScheduleType.NONE;
                if (isAutoSchedule) {
                  taskScheduleType = TaskScheduleType.AUTO_SCHEDULED;
                } else if (dateRange?.start || item.start) {
                  taskScheduleType = TaskScheduleType.SPECIFIC;
                }

                updateTask({
                  taskId: item.id,
                  task: {
                    summary: (summary ?? plainText)?.trim(),
                    contacts: contacts ?? [],
                    isCompleted,
                    timeBlockingType: timeBlocking,
                    taskScheduleType,
                    priority: priority || item.priority,
                    allDay: isAutoSchedule ? false : dateRange?.allDay,
                    start: isAutoSchedule ? null : dateRange?.start,
                    end: isAutoSchedule ? null : dateRange?.end,
                    duration,
                    scheduleId: scheduleIdMentions,
                    location: locations,
                    recurrence,
                    projectIds,
                    emailIds,
                    eventIds,
                    noteIds,
                    taskIds,
                  },
                });
                return;
              }
              case 'EVENT': {
                const _start = dateRange
                  ? dateRange?.start === item.start
                    ? item.start
                    : dateRange?.start
                  : item.start;
                const _end = dateRange ? (dateRange?.end === item.end ? item.end : dateRange?.end) : item.end;

                setEventInfoForUpdate({
                  eventId: item.id,
                  event: {
                    ...item,
                    summary: (summary ?? plainText)?.trim(),
                    allDay: item.allDay ?? dateRange?.allDay,
                    start: _start!,
                    end: _end!,
                    attendees: contacts,
                    projectIds: projectIds,
                    calendarId: calendarId ?? item.calendarId ?? defaultCalendar?.id,
                    startTimeZone: item.startTimeZone ?? defaultCalendar?.timeZone,
                    endTimeZone: item.endTimeZone ?? defaultCalendar?.timeZone,
                    reminders: item.reminders ?? defaultCalendar?.defaultReminders,
                    location: locations,
                    visibility: visibility ?? item.visibility,
                    recurrence,
                    conferenceData,
                  },
                });

                if ((item as Event).recurrence || (item as Event).recurringEventId) {
                  toast((t) => (
                    <div>
                      <div className="mt-1">
                        This is a recurring event. Please use the right side panel to edit this event.
                      </div>
                      <Button
                        className="px-8 mx-auto mt-4 mb-1"
                        variant="primary"
                        onClick={() => {
                          toast.dismiss(t.id);
                          openLeftSidebar({
                            type: editorFor(itemType)!,
                            context: {
                              ...item,
                              isCompleted,
                              summary: summary?.trim(),
                              start: dateRange?.start,
                              end: dateRange?.end,
                              allDay: dateRange?.allDay,
                              contacts,
                              projectIds,
                              autoschedule: action === 'auto-schedule' || isAutoSchedule,
                              location: locations,
                              autoFocus: true,
                            },
                          });
                        }}>
                        Dismiss
                      </Button>
                    </div>
                  ));

                  return;
                }

                if (item.attendees?.length || contacts?.length) {
                  openModal();
                  return;
                }

                updateEvent({
                  eventId: item.id,
                  event: {
                    ...item,
                    summary: (summary ?? plainText)?.trim(),
                    allDay: item.allDay ?? dateRange?.allDay,
                    start: _start!,
                    end: _end!,
                    attendees: contacts,
                    projectIds: projectIds,
                    calendarId: calendarId || item.calendarId || defaultCalendar?.id,
                    startTimeZone: item.startTimeZone ?? defaultCalendar?.timeZone,
                    endTimeZone: item.endTimeZone ?? defaultCalendar?.timeZone,
                    reminders: item.reminders ?? defaultCalendar?.defaultReminders,
                    location: locations,
                    visibility: visibility ?? item.visibility,
                    recurrence,
                    conferenceData,
                    emailIds,
                  },
                });
                return;
              }
              case 'SCHEDULING_LINK': {
                if (!defaultDuration || !defaultAvailability) return;

                let _start = dateRange?.start == item.start ? item.start : dateRange?.start;
                if (!_start || _start.getTime() < Date.now()) _start = roundToNextBlock(new Date(), 30);
                let _end = dateRange?.end == item.end ? item.end : dateRange?.end;
                if (!_end) _end = moment.tz(timeZone).startOf('week').add(1, 'week').startOf('day').toDate();

                const slots = await generateSlots({
                  start: _start,
                  end: _end,
                  duration: item.duration ?? defaultDuration,
                  scheduleIntervals: defaultAvailability.intervals || [],
                  timeZone,
                  checkCalendarIds: calendars?.map((calendar) => calendar.id) || [],
                  contactEmails: contacts?.map(({ email }) => email) || [],
                });

                updateSchedulingLink({
                  schedulingLinkId: item.id,
                  schedulingLink: {
                    title: (summary ?? plainText)?.trim(),
                    code: item.code,
                    start: _start,
                    end: _end,
                    contacts: contacts ?? [],
                    projectIds: projectIds ?? [],
                    useCustomSchedule: true,
                    scheduleId: scheduleIdMentions ? scheduleIdMentions : item.scheduleId ?? defaultAvailability.id,
                    when: dateRange?.start ? 'CUSTOM_DATES' : 'THIS_WEEK',
                    slots: slots || [],
                    recurringSlots: item.recurringSlots ?? [],
                    calendarId: (calendarId || item.calendarId) ?? defaultCalendar?.id,
                    duration: item.duration ?? defaultDuration,
                  },
                });
                return;
              }
              case 'NOTE': {
                updateNote({
                  noteId: item.id,
                  note: {
                    title: (summary ?? plainText)?.trim(),
                    content: item.content,
                    allDay: dateRange?.allDay || true,
                    start: dateRange?.start || moment.tz(timeZone).startOf('day').toDate(),
                    end: dateRange?.end || moment.tz(timeZone).startOf('day').add(1, 'day').toDate(),
                    projectIds,
                    eventIds,
                    emailIds,
                    noteIds,
                    taskIds,
                    priority,
                  },
                });
                return;
              }
            }
          }

          switch (item.itemType) {
            case 'TASK': {
              deleteTask({ taskId: item.id });
              break;
            }
            case 'EVENT': {
              deleteEvent({ eventId: item.id });
              break;
            }
            case 'SCHEDULING_LINK': {
              deleteSchedulingLink({ schedulingLinkId: item.id });
              break;
            }
            case 'NOTE': {
              deleteNote({ noteId: item.id });
              break;
            }
          }
        }
      }

      if (action === 'open-sidebar') {
        openLeftSidebar({
          type: editorFor(itemType)!,
          context: {
            key: onSidebar ? cuid() : undefined,
            id: item?.id,
            isCompleted,
            summary: (summary ?? plainText)?.trim(),
            start: dateRange?.start,
            end: dateRange?.end,
            allDay: isAutoSchedule ? null : dateRange?.allDay,
            priority,
            contacts,
            projectIds,
            autoschedule: isAutoSchedule,
            location: locations,
            autoFocus: true,
            recurrence,
            conferenceData,
          },
        });
      } else {
        const autoScheduling = action === 'auto-schedule' || scheduleIdMentions !== undefined || isAutoSchedule;
        if (
          itemType !== 'SCHEDULING_LINK' &&
          !dateRange &&
          (itemType !== 'TASK' || (use !== 'allow-unscheduled-task' && !autoScheduling))
        ) {
          dateRange = {} as DateRange;
          dateRange.start = item?.start || moment.tz(timeZone).startOf('day').toDate();
          dateRange.end = moment.tz(dateRange.start, timeZone).add(1, 'day').toDate();
          dateRange.allDay = true;
        }
        switch (itemType) {
          case 'TASK': {
            if (autoScheduling) {
              createAutoScheduleTask({
                summary: (summary ?? plainText)?.trim(),
                contacts,
                isCompleted,
                timeBlockingType: timeBlocking,
                priority,
                earliestStartDate: dateRange?.start,
                deadlineType: dateRange?.end ? 'HARD_DEADLINE' : undefined,
                dueDate: dateRange?.end,
                duration,
                projectIds: projectIds ?? [],
                scheduleId: scheduleIdMentions,
                location: locations,
                recurrence,
                emailIds,
                eventIds,
                noteIds,
                taskIds,
              });
              toast(<AutoScheduledItemBeingCreated title={(summary ?? plainText)?.trim()} />, {
                position: 'bottom-center',
                style: { background: '#000' },
              });
            } else {
              if (use === 'allow-unscheduled-task') {
                createTask({
                  summary: (summary ?? plainText)?.trim(),
                  contacts,
                  isCompleted,
                  timeBlockingType: timeBlocking,
                  taskScheduleType: dateRange?.start ? TaskScheduleType.SPECIFIC : TaskScheduleType.NONE,
                  priority,
                  allDay: dateRange?.allDay,
                  start: dateRange?.start,
                  end: dateRange?.end,
                  duration,
                  projectIds: projectIds ?? [],
                  scheduleId: scheduleIdMentions,
                  recurrence,
                  emailIds,
                  eventIds,
                  noteIds,
                  taskIds,
                });
              } else {
                createTask({
                  summary: (summary ?? plainText)?.trim(),
                  contacts,
                  isCompleted,
                  timeBlockingType: timeBlocking,
                  taskScheduleType: TaskScheduleType.SPECIFIC,
                  priority,
                  allDay: dateRange!.allDay,
                  start: dateRange!.start,
                  end: dateRange!.end,
                  duration,
                  projectIds: projectIds ?? [],
                  scheduleId: scheduleIdMentions,
                  location: locations,
                  recurrence,
                  emailIds,
                  eventIds,
                  noteIds,
                  taskIds,
                });
              }
            }
            break;
          }
          case 'NOTE': {
            createNote({
              title: (summary ?? plainText)?.trim(),
              content: '',
              allDay: dateRange?.allDay || true,
              start: dateRange?.start || moment.tz(timeZone).startOf('day').toDate(),
              end: dateRange?.end || moment.tz(timeZone).startOf('day').add(1, 'day').toDate(),
              projectIds,
              eventIds,
              emailIds,
              taskIds,
              noteIds,
              priority,
            });
            break;
          }
          case 'EVENT': {
            setPropsToPass({
              summary: (summary || plainText)?.trim() || '',
              allDay: dateRange!.allDay || false,
              startTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
              endTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
              start: dateRange!.start,
              end: dateRange!.end || moment(dateRange!.start).add(defaultDuration, 'minutes').toDate(),
              attendees: contacts ?? [],
              projectIds: projectIds ?? [],
              calendarId: calendarId || defaultCalendar?.id || '',
              reminders: defaultCalendar?.defaultReminders ?? [],
              location: locations || '',
              emailIds,
              eventIds,
              noteIds,
              taskIds,
            });
            const { background, foreground } =
              calculateEventColor(
                defaultCalendar?.colorId || undefined,
                defaultCalendar?.backgroundColor || undefined,
                defaultCalendar?.foregroundColor || undefined,
                undefined
              ) || {};
            if (contacts.length <= 0) {
              createEvent({
                summary: (summary || plainText)?.trim(),
                allDay: dateRange!.allDay || false,
                startTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
                endTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
                start: dateRange!.start,
                end: dateRange!.end || moment(dateRange!.start).add(defaultDuration, 'minutes').toDate(),
                attendees: contacts ?? [],
                projectIds: projectIds ?? [],
                calendarId: calendarId || defaultCalendar?.id || '',
                reminders: [...(defaultCalendar?.defaultReminders ?? [])],
                backgroundColor: background,
                foregroundColor: foreground,
                location: locations,
                status: 'tentative',
                recurrence,
                visibility,
                conferenceData,
                emailIds,
                eventIds,
                noteIds,
                taskIds,
              });
            } else {
              if (isSearchEnabled) {
                createEvent({
                  summary: (summary || plainText)?.trim(),
                  allDay: dateRange!.allDay || false,
                  startTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
                  endTimeZone: timeZone ? timeZone : defaultCalendar?.timeZone ?? '',
                  start: dateRange!.start,
                  end: dateRange!.end || moment(dateRange!.start).add(defaultDuration, 'minutes').toDate(),
                  attendees: contacts ?? [],
                  projectIds: projectIds ?? [],
                  calendarId: calendarId || defaultCalendar?.id || '',
                  reminders: [...(defaultCalendar?.defaultReminders ?? [])],
                  backgroundColor: background,
                  foregroundColor: foreground,
                  location: locations,
                  notifyGuests: true,
                  status: 'tentative',
                  recurrence,
                  visibility,
                  emailIds,
                  eventIds,
                  noteIds,
                  taskIds,
                });
              } else {
                openModal();
              }
            }
            break;
          }
          case 'SCHEDULING_LINK': {
            if (!defaultDuration || !defaultAvailability) return undefined;

            let _start = dateRange?.start;
            let _end = dateRange?.end;
            if (!_start || _start.getTime() < Date.now()) _start = roundToNextBlock(new Date(), 30);
            if (!_end || _end.getTime() < Date.now()) {
              _end = moment.tz(_start, timeZone).add(1, 'week').startOf('day').toDate();
            }

            const slots = await generateSlots({
              start: _start,
              end: _end,
              duration: defaultDuration,
              scheduleIntervals: defaultAvailability.intervals || [],
              timeZone,
              checkCalendarIds: calendars?.map((calendar) => calendar.id) || [],
              contactEmails: contacts?.map(({ email }) => email) || [],
            });

            createSchedulingLink({
              title: (summary || plainText)?.trim(),
              code: cuid(),
              start: _start,
              end: _end,
              contacts: contacts.map((c) => ({ email: c.email, name: c.name ?? '' })) ?? [],
              projectIds: projectIds ?? [],
              useCustomSchedule: true,
              scheduleId: scheduleIdMentions ? scheduleIdMentions : defaultAvailability.id,
              when: dateRange?.start ? 'CUSTOM_DATES' : 'THIS_WEEK',
              slots: slots || [],
              recurringSlots: [],
              calendarId: calendarId || defaultCalendar?.id,
              duration: defaultDuration,
            });
            break;
          }
        }
      }

      trackCommandBarActionCreate(itemType);
      if (onSidebar) return;

      // reset
      setItemType(initialItemType ?? 'TASK');
      setCompleted(false);
      setEditorKey(cuid());
      setPlainText('');
      setMentions([]);
      clearSuggestions();
      clearCache();
      setMentionInputValue('');
      mentionInput.current?.focus();

      // TODO(hmassad): focus on left sidebar -> create hook useLeftSidebar that focuses when opened
    },
    [
      plainText,
      projectsIds,
      reference,
      use,
      trackCommandBarActionCreate,
      itemType,
      onSidebar,
      initialItemType,
      clearSuggestions,
      clearCache,
      item,
      mentions,
      createProject,
      getConferencingDataFromOption,
      defaultDuration,
      closeLeftSidebar,
      updateTask,
      isCompleted,
      defaultCalendar?.id,
      defaultCalendar?.timeZone,
      defaultCalendar?.defaultReminders,
      defaultCalendar?.colorId,
      defaultCalendar?.backgroundColor,
      defaultCalendar?.foregroundColor,
      updateEvent,
      openLeftSidebar,
      openModal,
      defaultAvailability,
      timeZone,
      calendars,
      updateSchedulingLink,
      updateNote,
      deleteTask,
      deleteEvent,
      deleteSchedulingLink,
      deleteNote,
      createAutoScheduleTask,
      createTask,
      createNote,
      createEvent,
      isSearchEnabled,
      createSchedulingLink,
    ]
  );
  const submitRef = useRef(submit);
  submitRef.current = submit;

  const trackCommandBarTrigger = useCallback(
    (newInputValue: string) => {
      const [currentTrigger, precedingChar] = [newInputValue.slice(-1), newInputValue.slice(-2, -1)];
      const [lastCharInPlainText, secondLastCharInPlainText] = [plainText.slice(-1), plainText.slice(-2, -1)];

      const isLegitTrigger = ['/', '@'].includes(currentTrigger);
      const isFirstCharOrFollowsSpace = newInputValue.length === 1 || precedingChar === ' ';
      const isModalNotAlreadyTriggered =
        (!['/', '@'].includes(lastCharInPlainText) && !['/', '@'].includes(secondLastCharInPlainText)) ||
        (lastCharInPlainText === currentTrigger && secondLastCharInPlainText === currentTrigger);
      if (isLegitTrigger && isFirstCharOrFollowsSpace && isModalNotAlreadyTriggered) {
        trackEvent(`CommandBar ${currentTrigger} Trigger Invoked`, {
          ...(currentTrigger === '@' && {
            is_sidebar: Boolean(onSidebar),
          }),
        });
      }
    },
    [onSidebar, plainText, trackEvent]
  );

  const handleMentionInputValueChange: OnChangeHandlerFunc = useCallback(
    (event, newValue, newPlainTextValue, mentions) => {
      setLoading(false);
      trackCommandBarTrigger(newValue);
      let value = event.target.value.replace(/\n/g, '');

      const lastMention = mentions?.length ? mentions[mentions.length - 1] : null;
      let openSidebar = false;

      if (lastMention) {
        const newMentionInputValueWithoutTrigger = removeRange(
          value,
          lastMention.index,
          lastMention.index + lastMention.id.length + lastMention.display.length + 5
        );
        const newPlainTextValueWithoutTrigger = removeRange(
          newPlainTextValue,
          lastMention.plainTextIndex,
          lastMention.plainTextIndex + lastMention.display.length + 2
        );

        const { type, value: _mentionValue } = extractSuggestionTypeAndValue(lastMention.id);

        switch (type) {
          case 'submenu':
            setSelectedSubmenu(_mentionValue);
            setMentionInputValue(`${newMentionInputValueWithoutTrigger}@`);
            setPlainText(`${newPlainTextValueWithoutTrigger}@`);
            return;
          case 'auto-schedule':
            setTaskType('auto-schedule');
            break;
          case 'open-sidebar':
            value = newMentionInputValueWithoutTrigger;
            newPlainTextValue = newPlainTextValueWithoutTrigger;
            mentions.pop();
            openSidebar = true;
            break;
          default:
            if (ALL_ITEM_TYPES.includes(type as ItemType)) {
              setItemType(lastMention.id as ItemType);
              setMentionInputValue(newMentionInputValueWithoutTrigger);
              setPlainText(newPlainTextValueWithoutTrigger);
              if (lastMention.id === 'WORKFLOW') {
                handleOpenWorkflows();
              }
              if (lastMention.id === 'EMAIL') {
                handleAllEmailOpen();
              }
              value = newMentionInputValueWithoutTrigger;
              newPlainTextValue = newPlainTextValueWithoutTrigger;
              mentions.pop();
            }
        }
      } else {
        setTaskType('');
      }

      // remove mentions from newPlainTextValue
      let mentionLessPlainText = removeRanges(
        newPlainTextValue,
        mentions.map((m) => ({
          from: m.plainTextIndex,
          to: m.plainTextIndex + m.display.length,
        }))
      );

      mentionLessPlainText = removeMultipleSpaces(mentionLessPlainText);
      mentionLessPlainText = mentionLessPlainText.trim();
      if (mentionLessPlainText.charAt(0) === '@') {
        mentionLessPlainText = mentionLessPlainText.substring(1);
      }
      setPlainText(mentionLessPlainText);
      setMentions(mentions);
      setMentionInputValue(value);
      if (openSidebar) {
        void submit('open-sidebar', mentionLessPlainText);
      }
    },
    [handleAllEmailOpen, handleOpenWorkflows, submit, trackCommandBarTrigger]
  );

  const trackCommandBarAction = useCallback(
    (actionType: string) => {
      if (actionType === 'open-sidebar') {
        trackEvent('CommandBar / Action Selected', {
          selected_action: `Add ${itemType.toLowerCase()} details`,
        });
      } else if (['NOTE', 'TASK', 'EVENT', 'SCHEDULING_LINK'].includes(actionType)) {
        trackEvent('CommandBar / Action Selected', {
          selected_action: `Create ${actionType.toLowerCase()}`,
        });
      } else if (
        ['contact', 'auto-schedule', 'freeSlot', 'priority', 'duration', 'deadline', 'project', 'location'].includes(
          actionType.split(':')[0]
        )
      ) {
        trackEvent('CommandBar @ Attribute Selected', {
          selected_attribute: `Add ${actionType.split(':')[0].toLowerCase()}`,
          attribute_type: actionType.split(':')[0].toLowerCase(),
          entity_type: itemType.toLowerCase(),
          details: actionType.split(':')[1],
          is_sidebar: Boolean(onSidebar),
        });
      }
    },
    [itemType, onSidebar, trackEvent]
  );

  useEffect(() => {
    if (!onSidebar) return;

    const summary = plainText.trim();
    if (!summary && !item) return;

    submitRef.current('open-sidebar', summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSidebar, plainText, mentions]);

  const placeholder = useMemo(() => {
    if (_placeholder) return _placeholder;

    switch (itemType) {
      case 'TASK':
        return 'Create a task or use the / command';
      case 'EVENT':
        if (use === 'update') return '(No title)';
        return 'Create an event';
      case 'SCHEDULING_LINK':
        return 'Create a scheduling link';
      case 'NOTE':
        return 'Create a note';
      case 'SEARCH':
        return 'Search for content & commands, or use the / command';
    }
  }, [_placeholder, itemType, use]);

  const atCommandOptions = useCallback(
    async (search: string, callback: (data: SuggestionDataItem[]) => void) => {
      const suggestions: SuggestionDataItem[] = [];

      const parsedDates = getDatesSuggestions({ search, itemType, timeZone, submenu: selectedSubmenu });
      suggestions.push(...parsedDates);

      if (selectedSubmenu) {
        const submenuSuggestions = await getSubmenuSuggestions({
          submenu: selectedSubmenu,
          search,
          calendars,
          parsedDates,
          timeZone,
        });

        callback(submenuSuggestions);
        return;
      }

      suggestions.push(...getDurationSuggestions(search));

      if (search.length >= 3) {
        switch (itemType) {
          case 'NOTE':
            suggestions.push(...filterBySearch(search, PRIORITY_SUGGESTIONS));
            break;
          case 'TASK':
            suggestions.push(
              ...filterBySearch(search, PRIORITY_SUGGESTIONS),
              ...filterBySearch(search, TIME_BLOCKING_SUGGESTIONS),
              ...getRecurrenceSuggestions(search),
              ...getAutoScheduleSuggestions(search)
            );
            break;
          case 'EVENT':
            suggestions.push(
              ...filterBySearch(search, VISIBILITY_TYPE_SUGGESTIONS),
              ...filterBySearch(search, CONFERENCE_SUGGESTIONS),
              ...getRecurrenceSuggestions(search),
              ...getAutoScheduleSuggestions(search)
            );
            break;
        }
      }

      if (itemType in ITEM_TYPE_SUGGESTIONS) {
        suggestions.push(
          ...filterBySearch(search, ITEM_TYPE_SUGGESTIONS[itemType as keyof typeof ITEM_TYPE_SUGGESTIONS])
        );
      }

      callback([...suggestions]);

      void getSuggestedFreeSlots({ search, submenu: selectedSubmenu, timeZone }).then((newSuggestions) => {
        suggestions.push(...newSuggestions);
        callback([...suggestions]);
      });

      void getSuggestedContacts({ itemType, mentions, search }).then((newSuggestions) => {
        suggestions.push(...newSuggestions);
        callback([...suggestions]);
      });

      void getSuggestedCustomSchedules({ availability, itemType, search }).then((newSuggestions) => {
        suggestions.push(...newSuggestions);
        callback([...suggestions]);
      });

      if (itemType !== 'SCHEDULING_LINK') {
        setValue(search, true);
        void getSuggestedLocation({ data, ready, status }).then((newSuggestions) => {
          suggestions.push(...newSuggestions);
          callback([...suggestions]);
        });
      }
    },
    [itemType, timeZone, selectedSubmenu, calendars, setValue, ready, status, data, mentions, availability]
  );

  const references = useReferences();
  const hashtagCommandOptions = useCallback(
    async (search: string, callback: (data: SuggestionDataItem[]) => void) => {
      const suggestions =
        projects
          ?.filter((project) => project.name.toLowerCase().includes(search.toLowerCase()))
          .filter((v) => !v.parentProjectId)
          .map((project) => ({
            id: buildSuggestionId('project', project.id),
            display: project.subprojects?.length
              ? ` ${project.name}/${project.subprojects.map((v) => v.name).join('/')}`
              : `${project.name}`,
            category: 'projects',
          })) || [];
      if (search.trim().length >= 1) {
        suggestions.push({
          id: buildSuggestionId('project', 'create_new_project_' + search),
          display: 'Create new project: ' + search,
          category: 'projects',
        });
      }

      if (itemType === 'NOTE') {
        references
          .filter((ref) => {
            if (ref.id === item?.id) return false;
            const title = 'summary' in ref ? ref.summary : ref.title;
            return title.toLowerCase().includes(search.toLowerCase());
          })
          .slice(0, 5)
          .forEach((reference) => {
            suggestions.push({
              id: buildSuggestionId(`${reference.itemType}-REF`, reference.id),
              display: 'summary' in reference ? reference.summary : reference.title,
              category: 'REFERENCES',
            });
          });
      }

      callback(suggestions);
    },
    [itemType, item, projects, references]
  );

  const slashCommandOptions = useMemo(
    () => [
      {
        id: 'open-sidebar',
        display: 'Open Sidebar',
      },
      ...ALL_ITEM_TYPES.filter((et) => et !== itemType).map((et) => ({
        id: et,
        display: et,
      })),
    ],
    [itemType]
  );

  const renderSlashSuggestion = useCallback(
    (
      suggestion: SuggestionDataItem
      // search: string,
      // highlightedDisplay: ReactNode,
      // index: number,
      // focused: boolean
    ) => {
      let renderedContent: ReactNode | null = null;

      switch (suggestion.id) {
        case 'open-sidebar':
          renderedContent = (
            <>
              {iconByItemType[itemType]}
              Add {itemType.toLocaleLowerCase().replace('_', ' ')} details
            </>
          );
          break;
        case 'EVENT':
        case 'TASK':
        case 'SCHEDULING_LINK':
        case 'NOTE':
          renderedContent = (
            <>
              {iconByItemType[suggestion.id as ItemType]}
              {capitalize(suggestion.id).replace('_', ' ')}
            </>
          );
          break;
        case 'search':
          if (!isSearchEnabled) return null;
          renderedContent = (
            <>
              {iconByItemType[suggestion.id as ItemType]}
              {capitalize(suggestion.id).replace('_', ' ')}
            </>
          );
          break;
        default:
          return null;
      }

      return (
        <div key={suggestion.id} className="px-2 py-2.5 flex items-center gap-[10px]">
          {renderedContent}
        </div>
      );
    },
    [itemType, isSearchEnabled]
  );

  const handleMentionInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key !== 'Enter' || itemType === 'SEARCH') return;
      void submit(taskType as 'auto-schedule');
    },
    [itemType, submit, taskType]
  );

  const handleOnAdd = useCallback(() => {
    if (!selectedSubmenu) return;
    setSelectedSubmenu(null);
  }, [selectedSubmenu]);

  useEffect(() => {
    if (use !== 'create') return;

    function focusMentionInput(e: KeyboardEvent) {
      e.preventDefault();
      mentionInput.current?.focus();
    }

    registerShortcut(['c', 'C'], focusMentionInput);

    return () => {
      unregisterShortcut(['c', 'C']);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commands = useMemo(() => {
    const _commands = [
      <Mention
        key="@"
        trigger="@"
        data={atCommandOptions}
        renderSuggestion={renderAtSuggestion}
        style={defaultMentionStyle}
        appendSpaceOnAdd
        isLoading={isLoading}
        onAdd={(action) => {
          handleOnAdd();
          trackCommandBarAction(String(action));
        }}
      />,
      <Mention
        key="#"
        trigger="#"
        data={hashtagCommandOptions}
        renderSuggestion={renderAtSuggestion}
        style={defaultMentionStyle}
        appendSpaceOnAdd
        isLoading={isLoading}
        onAdd={(action) => {
          handleOnAdd();
          trackCommandBarAction(String(action));
        }}
      />,
    ];

    if (!disableSlash) {
      _commands.push(
        <Mention
          key="/"
          trigger="/"
          data={slashCommandOptions}
          renderSuggestion={renderSlashSuggestion}
          style={defaultMentionStyle}
          onAdd={(action) => {
            trackCommandBarAction(String(action));
          }}
        />
      );
    }

    return _commands;
  }, [
    atCommandOptions,
    hashtagCommandOptions,
    disableSlash,
    handleOnAdd,
    isLoading,
    renderSlashSuggestion,
    slashCommandOptions,
    trackCommandBarAction,
  ]);

  const setShowMenu = useSetAtom(menuStateAtom);
  const handleCloseMenu = useMemo(
    () => (type: 'TASK' | 'EVENT' | 'SCHEDULING_LINK' | 'NOTE' | 'SEARCH') => {
      setItemType(type);
      setShowMenu((prevState) => !prevState);
    },
    [setShowMenu]
  );

  const rightSection = useMemo(() => {
    if (hideRightSection) return null;

    switch (use) {
      case 'create':
        return (
          <>
            {!hideTooltip ? (
              <div className={`flex flex-col gap-5 text-center mr-1`}>
                <Popover
                  defaultOpen={false}
                  open={openPopover}
                  handleOpenPopover={setOpenPopover}
                  trigger={
                    <button className={`rounded h-[32px] w-[32px] inline-flex items-center justify-center`}>
                      <InfoIcon />
                    </button>
                  }>
                  <div className="flex flex-col gap-[4px]">
                    <p className="font-bold">? Keyboard Shortcuts</p>
                    Press ? to flash keyboard shortcuts for a hint
                  </div>
                  <div className="flex flex-col gap-[4px]">
                    <p className="mt-[15px] font-bold">/ command</p>
                    Use / to create tasks, events, & scheduling links
                  </div>
                  <div className="flex flex-col gap-[4px]">
                    <p className="mt-[15px] font-bold">@ command</p>
                    Type @ to tag dates, contacts, & projects
                  </div>
                  <div className="flex flex-col gap-[4px]">
                    <p className="mt-[15px] flex items-center font-bold">
                      <RightSidebarIcon className="mr-[5px]" /> button
                    </p>
                    Click on the side panel button to add more details
                  </div>
                </Popover>
              </div>
            ) : null}
            <div className="w-[20px] relative">
              <div className="cursor-pointer" onClick={() => setShowMenu((prevState) => !prevState)}>
                {iconByItemType[itemType]}
              </div>
              <MenuModal itemType={itemType} onChange={handleCloseMenu} />
            </div>
            <KeyboardShortcutIndicator shortcut="/ + c" />
          </>
        );
      case 'allow-unscheduled-task':
        return (
          <div className="w-[20px] relative">
            <div className="cursor-pointer">{iconByItemType[itemType]}</div>
          </div>
        );
      case 'update':
        if (item?.itemType === itemType) return null;
        return <div className="w-[20px]">{iconByItemType[itemType]}</div>;
    }
  }, [handleCloseMenu, hideRightSection, hideTooltip, item?.itemType, itemType, openPopover, setShowMenu, use]);

  useEffect(() => {
    if (!autoFocus) return;
    requestAnimationFrame(() => {
      if (!mentionInput.current) return;
      mentionInput.current?.focus();
      mentionInput.current.setSelectionRange(mentionInput.current.value.length, mentionInput.current.value.length);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = (type: 'create' | 'update') => (answer: 'yes' | 'no' | undefined) => {
    if (!answer) {
      closeModal();
      return;
    }

    switch (type) {
      case 'create':
        createEvent({
          summary: propsToPass.summary,
          allDay: propsToPass.allDay,
          startTimeZone: propsToPass.startTimeZone,
          endTimeZone: propsToPass.endTimeZone,
          start: propsToPass.start,
          end: propsToPass.end,
          attendees: propsToPass.attendees,
          projectIds: propsToPass.projectIds,
          calendarId: propsToPass.calendarId,
          reminders: propsToPass.reminders,
          location: propsToPass.location,
          notifyGuests: answer === 'yes',
          status: 'tentative',
          eventIds: propsToPass.eventIds,
          emailIds: propsToPass.emailIds,
          noteIds: propsToPass.noteIds,
          taskIds: propsToPass.taskIds,
        });
        break;
      case 'update':
        updateEvent({
          eventId: eventInfoForUpdate.eventId,
          event: {
            ...eventInfoForUpdate.event,
            notifyGuests: answer === 'yes',
          },
        });
        break;
    }
    closeModal();
  };

  return (
    <div className={cn('relative flex items-center text-sm', className)}>
      {rightSection}
      <MentionsInput
        inputRef={mentionInput}
        key={editorKey}
        disabled={disabled}
        className="flex-1 max-w-[592px]"
        style={defaultStyle}
        customSuggestionsContainer={(children) => {
          return (
            <>
              {isLoading ? (
                <div className="flex py-[10px] px-[2px]">
                  <ParagraphText>Loading...</ParagraphText>
                  <div className="border-primary-500 w-[12px] h-[12px] border-b-2 rounded-full animate-spin" />
                </div>
              ) : (
                <div className="rounded-[4px] shadow-popover min-w-[210px] text-sm bg-white">{children}</div>
              )}
            </>
          );
        }}
        value={mentionInputValue ? mentionInputValue : ''}
        onChange={handleMentionInputValueChange}
        placeholder={placeholder}
        onKeyDown={handleMentionInputKeyDown}
        allowSpaceInQuery
        renderCategory={renderAtCategory}
        autoFocus
        onFocus={() => setShowMenu(false)}>
        {commands}
      </MentionsInput>
      {isOpen ? <GuestsNotificationsModal onClose={handleModalClose(use === 'create' ? 'create' : 'update')} /> : null}
      {conferencingSelectorModal}
    </div>
  );
};

const defaultStyle = {
  suggestions: {
    list: { backgroundColor: 'white', borderRadius: 4, overflow: 'hidden' },
    item: {
      '&focused': {
        backgroundColor: '#8C8C8C1A',
      },
    },
  },
  input: {
    paddingLeft: 5,
    outline: 'none',
  },
};

const defaultMentionStyle = {
  backgroundColor: '#8C8C8C1A',
  paddingTop: 4,
  paddingBottom: 4,
};
