import { Prisma } from '@prisma/client';
import { Contact } from '@/models/ContactModel';
import { JsonObject } from '@prisma/client/runtime/library';

export type ContactArg = Omit<Contact, 'id'>;

export const contactSelect: Pick<
  Prisma.ContactSelect,
  | 'id'
  | 'email'
  | 'picture'
  | 'firstName'
  | 'lastName'
  | 'birthday'
  | 'description'
  | 'name'
  | 'location'
  | 'organization'
  | 'urls'
> = {
  id: true,
  email: true,
  name: true,
  picture: true,
  firstName: true,
  lastName: true,
  birthday: true,
  description: true,
  location: true,
  organization: true,
  urls: true,
};

export type Organization = {
  name: string;
  current: boolean | null;
  domain: string | null;
  startDate: Date | null;
  endDate: Date | null;
  location: string | null;
  metadata: string;
  type: string;
  position: string;
  id?: string;
};

export type Urls = {
  value: string | null;
  type: string | null;
  metadata: string | null;
  id?: string;
};

export const convertContactToContactArg = (contact: Contact): ContactArg => ({
  email: contact.email,
  name: contact.name,
  picture: contact.picture,
  firstName: contact.firstName,
  lastName: contact.lastName,
  birthday: contact.birthday as JsonObject,
  location: contact.location,
  description: contact.description,
  organization: contact.organization as JsonObject,
  urls: contact.urls as JsonObject,
});
