import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Common } from '../Column/Item';
import CalendarContext from '@/components/Calendar/context';
import {
  calcHeight,
  calcTop,
  minutesToPixels,
  pixelsToMinutes,
  sidebarTypeByEditionMode,
  updateRecurringSlot,
} from '../utils';
import moment from 'moment-timezone';
import { useSettings } from '@/hooks/useSettings';
import { nearestNumber } from 'utils/number';

import { DragAction, EditionMode, ItemType } from '../types';
import { useTasks } from '@/hooks/useTasks';
import { useEvents } from '@/hooks/useEvents';
import { convertEventToEventArg } from '@/models/EventArg';
import { TaskArg } from '@/models/TaskArg';
import useDndContext from '@/hooks/useDndContext';
import { parseDraggableId } from '@/utils/dnd';
import { calendarTypeAtom, eventBeingCreatedAtom, recurringSlotsAtom, slotsAtom } from '@/components/calendarAtoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateInterval, dateIntervalToRecurringInterval } from '@/utils/date';
import { cn } from 'ui/cn';
import { useRouter } from 'next/router';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';

function Indicator({ start, end, className, allDay }: DateInterval & { className?: string; allDay?: boolean }) {
  const { hourHeight, timeZone, editionMode } = useContext(CalendarContext);

  let bgColor;
  switch (editionMode) {
    case EditionMode.EVENTS:
      bgColor = 'bg-primary-200/50';
      break;
    case EditionMode.SLOTS:
      bgColor = 'bg-calendar-recommendedSlotBackgroundColor/50';
      break;
    case EditionMode.BOOKING:
      bgColor = 'bg-calendar-recommendedSlotBackgroundColor/50';
      break;
    default:
      bgColor = 'bg-primary-200/50';
      break;
  }

  return (
    <div
      className={cn(
        'absolute flex w-full px-1 py-0.5 overflow-hidden text-left text-gray-500 rounded-md whitespace-nowrap z-20',
        bgColor,
        className
      )}
      style={{
        top: allDay ? '' : calcTop(hourHeight, timeZone, start),
        height: allDay ? minutesToPixels(hourHeight, 30) + 4 : calcHeight(hourHeight, timeZone, start, end),
      }}>
      <Common item={{ start, end, allDay }} />
    </div>
  );
}

function isIndicatorOverlappingItem(
  x: number,
  indicatorTop: number,
  indicatorBottom: number,
  elements: Element[],
  ignoreId?: string
) {
  return elements.some((item: Element) => {
    const { top, bottom, left, right } = item.getBoundingClientRect();

    if (item.getAttribute('data-item-id') === ignoreId) return false;

    return (
      x >= left &&
      x <= right &&
      ((indicatorTop > top && indicatorTop < bottom) ||
        (indicatorBottom > top && indicatorBottom < bottom) ||
        (indicatorTop < top && indicatorBottom >= bottom) ||
        (indicatorTop === top && indicatorBottom === bottom))
    );
  });
}

export default function useDragIndicator(date: moment.Moment) {
  const router = useRouter();
  const { hourHeight, timeZone, step, editionMode, indicatorContext, items } = useContext(CalendarContext);
  const { dndContext } = useDndContext();
  const { defaultDuration } = useSettings();
  const { openLeftSidebar } = useLeftSidebar();
  const [itemBeingCreated, setItemBeingCreated] = useAtom(eventBeingCreatedAtom);

  const { updateTask } = useTasks({});
  const { updateEvent } = useEvents({});
  const setSlots = useSetAtom(slotsAtom);
  const setRecurringSlots = useSetAtom(recurringSlotsAtom);
  const calendarType = useAtomValue(calendarTypeAtom);

  const [dragIndicator, setDragIndicator] = useState<DateInterval | null>(null);
  const [columnRef, setColumnRef] = useState<HTMLDivElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);
  const { droppableType } = parseDraggableId(indicatorContext.activeColumn?.dataset?.rbdDroppableId ?? '');

  const duration = useMemo(() => {
    if (indicatorContext.duration) {
      return indicatorContext.duration;
    }

    if (!dndContext.isDragging) {
      return defaultDuration;
    }

    const { id } = parseDraggableId(dndContext.itemId);
    const draggedItem = items.find((item) => item.id === id);
    if (!draggedItem) return defaultDuration;
    if ('allDay' in draggedItem && draggedItem.allDay) return defaultDuration;

    return moment(draggedItem.end).diff(draggedItem.start, 'minutes');
  }, [indicatorContext.duration, defaultDuration, dndContext, items]);

  const createItem = useCallback(
    (start: Date, end: Date) => {
      if (editionMode === EditionMode.SLOTS) {
        if (calendarType === 'SPECIFIC') {
          setSlots((prev) => [...prev, { start, end }]);
          return;
        }

        setRecurringSlots((prev) => [...prev, dateIntervalToRecurringInterval({ start, end }, timeZone)]);
        return;
      }

      if (editionMode === EditionMode.BOOKING) {
        void router.replace(
          { pathname: router.pathname, query: { ...router.query, slot: start.toISOString() } },
          undefined,
          { shallow: true }
        );
        return;
      }

      const type = sidebarTypeByEditionMode(editionMode);
      if (!type) return;

      const isAllDay = droppableType === 'CALENDAR_HEADER';
      const newEnd = isAllDay ? moment(end).add(1, 'day').toDate() : end;
      openLeftSidebar({
        type,
        context: {
          start: start,
          end: newEnd,
          autoFocus: true,
          allDay: isAllDay,
        },
      });
    },
    [calendarType, droppableType, editionMode, openLeftSidebar, router, setRecurringSlots, setSlots, timeZone]
  );

  const updateItem = useCallback(
    (item: ItemType, type: 'start' | 'end') => () => {
      setDragIndicator({ start: item.start!, end: item.end! });
      indicatorContext.action = type === 'start' ? DragAction.CHANGE_START : DragAction.CHANGE_END;
      indicatorContext.activeColumn = columnRef;
      setSelectedItem(item);
    },
    [indicatorContext, columnRef]
  );

  const reset = useCallback(() => {
    indicatorContext.action = null;
    indicatorContext.selectedDate = null;
    indicatorContext.activeColumn = null;
    setDragIndicator(null);
    setSelectedItem(null);
  }, [indicatorContext]);

  const renderedDragIndicator = useMemo(() => {
    if (!dragIndicator) return null;
    return <Indicator {...dragIndicator} allDay={false} />;
  }, [dragIndicator]);

  const renderedDragIndicatorAllDay = useMemo(() => {
    if (!dragIndicator) return null;
    if (EditionMode.SLOTS === editionMode) return null;

    const newEnd = moment(dragIndicator.end).add(1, 'day').toDate();
    const allDayIndicator = { ...dragIndicator, end: newEnd, allDay: true };
    return <Indicator {...allDayIndicator} />;
  }, [dragIndicator, editionMode]);

  const renderedItemBeingCreated = useMemo(() => {
    if (!itemBeingCreated) return null;
    if (itemBeingCreated.allDay) return null;
    if (indicatorContext.action) return null;
    if (!date.isSame(itemBeingCreated.start, 'day')) return null;

    return <Indicator {...itemBeingCreated} className="bg-primary-200/80" />;
  }, [itemBeingCreated, indicatorContext.action, date]);

  const renderedItemAllDay = useMemo(() => {
    if (!itemBeingCreated?.allDay) return null;
    if (indicatorContext.action) return null;
    if (!itemBeingCreated.allDay) return null;
    if (EditionMode.SLOTS === editionMode) return null;
    if (date.isSame(itemBeingCreated.start, 'day')) {
      return <Indicator {...itemBeingCreated} allDay={true} className="bg-primary-200/80" />;
    }
  }, [itemBeingCreated, indicatorContext.action, date, editionMode]);

  const handleSetDragIndicator = useCallback(
    (date: moment.Moment, minutes: number) => {
      let start = date.clone().add(minutes, 'minutes');
      let end = start.clone().add(duration, 'minutes');
      const { dates, selectedDate, activeColumn } = indicatorContext;
      const { droppableType } = parseDraggableId(activeColumn?.dataset?.rbdDroppableId ?? '');

      switch (indicatorContext.action) {
        case DragAction.CREATE: {
          if (!dates || !selectedDate) break;
          const selectedDateEnd = moment(selectedDate).add(duration, 'minutes');

          if (start.isBefore(selectedDate)) {
            end = moment(dates.end);
          }
          if (end.isAfter(selectedDateEnd)) {
            start = moment(dates.start);
          }
          break;
        }

        case DragAction.CHANGE_START: {
          const { dates } = indicatorContext;
          if (!dates) break;

          end = moment(dates.end);
          if (end.diff(start, 'minutes') < 15) return;

          break;
        }

        case DragAction.CHANGE_END: {
          const { dates } = indicatorContext;
          if (!dates) break;

          start = moment(dates.start);
          if (end.diff(start, 'minutes') < 15) return;

          break;
        }
      }

      const isAllDay = droppableType === 'CALENDAR_HEADER';

      setDragIndicator({
        start: start.toDate(),
        end: end.toDate(),
        allDay: isAllDay,
      });
    },

    [duration, indicatorContext]
  );

  const handleUpdateItem = useCallback(
    (start: Date, end: Date) => {
      const { selectedItem } = indicatorContext;
      if (!selectedItem) return;

      switch (selectedItem.itemType) {
        case 'TASK':
          updateTask({
            taskId: selectedItem.id,
            task: {
              start: start,
              end: end,
            } as TaskArg,
          });
          break;
        case 'EVENT':
          updateEvent({
            eventId: selectedItem.id,
            event: convertEventToEventArg({
              ...selectedItem,
              start: start,
              end: end,
            }),
          });
          break;
        case 'SLOT': {
          if (calendarType === 'SPECIFIC') {
            setSlots((prev) => [...prev.filter((_, index) => index !== Number(selectedItem.id)), { start, end }]);
            return;
          }

          setRecurringSlots((prev) => updateRecurringSlot(prev, selectedItem, { start, end }, timeZone));
          break;
        }
      }
    },
    [calendarType, indicatorContext, setRecurringSlots, setSlots, timeZone, updateEvent, updateTask]
  );

  useEffect(() => {
    indicatorContext.dates = dragIndicator;
  }, [indicatorContext, dragIndicator]);

  useEffect(() => {
    indicatorContext.selectedItem = selectedItem;
  }, [selectedItem, indicatorContext]);

  useEffect(() => {
    if (!columnRef) return;

    const onMouseMove = (e: MouseEvent) => {
      if (indicatorContext.activeColumn && indicatorContext.activeColumn !== columnRef) return;

      const { action } = indicatorContext;
      const { top } = columnRef.getBoundingClientRect();
      const minutes = pixelsToMinutes(hourHeight, e.clientY - top);
      const roundedMinutes = nearestNumber(minutes, step);

      const dragIndicatorTop = e.clientY;
      const dragIndicatorBottom = minutesToPixels(hourHeight, roundedMinutes + duration) + top;

      let ignoreId: string | undefined;
      if (dndContext.isDragging) {
        ignoreId = parseDraggableId(dndContext.itemId).id;
      }

      const itemElements = Array.from(columnRef.querySelectorAll('[data-item-id]'));
      const blockedIntervalElements = Array.from(columnRef.querySelectorAll('[data-blocked-interval]'));

      const isOverlapping = isIndicatorOverlappingItem(
        e.clientX,
        dragIndicatorTop,
        dragIndicatorBottom,
        [...itemElements, ...blockedIntervalElements],
        ignoreId
      );

      if (!isOverlapping || action) {
        handleSetDragIndicator(date, roundedMinutes);
        return;
      }

      setDragIndicator(null);
      return;
    };

    const onMouseLeave = () => {
      if (indicatorContext.activeColumn) return;
      reset();
    };

    const onMouseDown = () => {
      if (!indicatorContext.dates) return;

      indicatorContext.activeColumn = columnRef;
      if (editionMode === EditionMode.BOOKING) return;

      indicatorContext.action = DragAction.CREATE;
      indicatorContext.selectedDate = indicatorContext.dates.start;
      setItemBeingCreated(indicatorContext.dates);
      const { droppableType } = parseDraggableId(indicatorContext.activeColumn?.dataset?.rbdDroppableId ?? '');

      switch (droppableType) {
        case 'CALENDAR_HEADER': {
          setItemBeingCreated({ start: indicatorContext.dates.start, end: indicatorContext.dates.end, allDay: true });
          return;
        }
        case 'CALENDAR': {
          setItemBeingCreated(indicatorContext.dates);
          return;
        }
        default: {
          setItemBeingCreated(indicatorContext.dates);
          return;
        }
      }
    };

    function onMouseUp() {
      if (dndContext.isDragging) return;
      if (indicatorContext.activeColumn !== columnRef) return;
      if (!indicatorContext.dates) return;
      if (editionMode !== EditionMode.BOOKING && !indicatorContext.action) return;
      const { droppableType } = parseDraggableId(indicatorContext?.activeColumn?.dataset?.rbdDroppableId ?? '');

      const { start, end } = indicatorContext.dates;

      switch (indicatorContext.action) {
        case DragAction.CHANGE_START:
        case DragAction.CHANGE_END:
          handleUpdateItem(start, end);
          break;
        case DragAction.CREATE:
        default: {
          if (droppableType === 'CALENDAR_HEADER') {
            createItem(start, end);
            setItemBeingCreated({ start, end, allDay: true });
            break;
          } else {
            createItem(start, end);
            setItemBeingCreated(indicatorContext.dates);
            break;
          }
        }
      }

      reset();
    }

    columnRef.addEventListener('mousemove', onMouseMove);
    columnRef.addEventListener('mouseleave', onMouseLeave);
    columnRef.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      columnRef.removeEventListener('mousemove', onMouseMove);
      columnRef.removeEventListener('mouseleave', onMouseLeave);
      columnRef.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    columnRef,
    createItem,
    date,
    duration,
    handleSetDragIndicator,
    hourHeight,
    step,
    timeZone,
    handleUpdateItem,
    indicatorContext,
    reset,
    dndContext,
    setItemBeingCreated,
    editionMode,
    droppableType,
  ]);

  useEffect(() => {
    if (dndContext.isDragging) return;
    reset();
  }, [dndContext.isDragging, reset]);

  return {
    renderedDragIndicator,
    renderedItemBeingCreated,
    renderedItemAllDay,
    renderedDragIndicatorAllDay,
    setColumnRef,
    updateItem,
    selectedItem,
  };
}
