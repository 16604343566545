import React, { useCallback, useEffect } from 'react';
import { GmailIcon } from '@/icons/SocialIcons';
import Link from 'next/link';
import { EmailSidebarHeader } from '@/components/LeftSidebar/Emails/EmailSidebarHeader';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { useAccounts } from '@/hooks/useAccounts';
import { NavigationInboxTabs } from './NavigationInboxTabs';
import { InboxTabContent } from './InboxTabContent';
import { EmailLabel, LabelDisplayNames, MAIN_TABS } from './utils/constants';

export const EmailInbox = () => {
  const { openLeftSidebar, closeLeftSidebar } = useLeftSidebar();
  const { accounts } = useAccounts();
  const [selectedTab, setSelectedTab] = React.useState<EmailLabel>(MAIN_TABS[0]);
  const [moreTabsVisible, setMoreTabsVisible] = React.useState(false);

  const hasPermission =
    accounts
      ?.filter((account) => account.provider === 'GOOGLE')
      ?.some((a) => a.scope.includes('https://www.googleapis.com/auth/gmail.modify')) ?? false;
  
  const handleCompose = useCallback(() => {
    openLeftSidebar({ type: 'COMPOSE' });
  }, [openLeftSidebar]);

  const handleTabChange = (newTab: string) => {
    const emailLabel = newTab as EmailLabel;
    
    if (emailLabel in LabelDisplayNames) {
      setSelectedTab(emailLabel);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const currentIndex = MAIN_TABS.indexOf(selectedTab);

        if (!moreTabsVisible && ((event.key === 'ArrowLeft' && selectedTab === MAIN_TABS[0]) || (event.key === 'ArrowRight' && selectedTab === MAIN_TABS[MAIN_TABS.length - 1]))) {
          setMoreTabsVisible(true);
        } else {
          if (moreTabsVisible) {
            setMoreTabsVisible(false);
          }
          const newIndex = event.key === 'ArrowRight'
            ? (currentIndex + 1) % MAIN_TABS.length
            : (currentIndex - 1 + MAIN_TABS.length) % MAIN_TABS.length;
          setSelectedTab(MAIN_TABS[newIndex]);
        }
      } else if (event.key === 'Escape' && moreTabsVisible) {
        setMoreTabsVisible(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedTab, moreTabsVisible]);

  return (
   <div className="flex flex-col min-h-0">
      {!hasPermission ? (
        <>
          <EmailSidebarHeader onClose={() => closeLeftSidebar()} />
          <div className="mt-[100px] text-center">
            <Link
              className="flex items-center font-lexend transition border hover:shadow p-[10px] rounded-[10px] gap-[10px] mx-auto w-[60%]"
              href={'/settings/integrations'}
              shallow
              draggable="false"
              onClick={() => closeLeftSidebar()}>
              <GmailIcon size={25} />
              Sign in to access your email
            </Link>
          </div>
        </>
      ) : (
        <div className="flex flex-col min-h-0 overflow-hidden">
          <EmailSidebarHeader
            hasPermission={hasPermission}
            onComposeClick={handleCompose}
            onClose={() => closeLeftSidebar()}
          />
          <NavigationInboxTabs selectedTab={selectedTab} onTabChange={handleTabChange} moreTabsVisible={moreTabsVisible} setMoreTabsVisible={setMoreTabsVisible} />
          <InboxTabContent selectedTab={selectedTab} moreTabsVisible={moreTabsVisible} />
        </div>
      )}
    </div>
  );
};