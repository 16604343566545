import { useSession } from 'next-auth/react';
import { useQuery } from '@tanstack/react-query';
import { httpGet } from 'utils/smarty-api';
import Stripe from 'stripe';
import moment from 'moment-timezone';

export const useEasySession = () => {
  const { status, data } = useSession();
  const isLoading = status === 'loading';
  const isAuthenticated = status === 'authenticated';
  const isUnauthenticated = status === 'unauthenticated';

  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    refetch: reloadStripeSubscription,
  } = useQuery({
    queryKey: ['STRIPE_SUBSCRIPTION'],
    queryFn: () => httpGet<Stripe.Subscription>('/stripe'),
    enabled: isAuthenticated,
  });

  const userCreatedAt = data?.user.createdAt;
  const trialEnd = moment(userCreatedAt).add(7, 'days');
  const now = moment();

  const hasTrialEnded = now.isAfter(trialEnd);
  const daysLeftTrial = trialEnd.diff(now, 'days');

  const isActive = subscription && ['active'].includes(subscription.status);

  return {
    user: data?.user,
    isLoading: isLoading || (isAuthenticated && isSubscriptionLoading),
    isAuthenticated,
    isUnauthenticated,
    trialEnds: trialEnd.toDate(),
    isActive,
    hasTrialEnded,
    daysLeftTrial,
    reloadStripeSubscription,
  };
};
