import * as chrono from 'chrono-node';
import moment from 'moment-timezone';
import { getBrowserTimeZone } from './date';

const generalAllDayTerms = ['today', 'tomorrow', 'yesterday', 'the day before', 'the day after'];
const timeKeywords = {
  morning: {
    start: '07:00',
    end: '12:00',
  },
  afternoon: {
    start: '12:00',
    end: '17:00',
  },
  evening: {
    start: '17:00',
    end: '21:00',
  },
  night: {
    start: '21:00',
    end: '24:00',
  },
  lunch: {
    start: '11:30',
    end: '13:30',
  },
  dinner: {
    start: '18:00',
    end: '21:00',
  },
  breakfast: {
    start: '06:00',
    end: '09:00',
  },
  'happy hour': {
    start: '16:00',
    end: '19:00',
  },
};

export const parseDatesTimes = (term?: string, itemType?: string, timeZone: string = getBrowserTimeZone()) => {
  if (!term) return [];
  let searchTerm = term;

  searchTerm = searchTerm.replace(/\btod\b/i, 'today');
  searchTerm = searchTerm.replace(/\btom\b/i, 'tomorrow');
  searchTerm = searchTerm.replace(/\byes\b/i, 'yesterday');

  const isBetween = searchTerm.toLowerCase().includes('between');
  const isBefore = searchTerm.toLowerCase().includes('before');
  const isBy = searchTerm.toLowerCase().includes('by');
  const isAfter = searchTerm.toLowerCase().includes('after');

  const timeKeywordFound = Object.keys(timeKeywords).find((key) => new RegExp(`\\b${key}\\b`, 'i').test(searchTerm));
  const parsedDates = chrono.parse(searchTerm, new Date(), { forwardDate: true });

  return parsedDates.map((parseDate) => {
    let startDate = parseDate.start.date();
    let endDate = parseDate.end?.date() ?? undefined;

    if (generalAllDayTerms.includes(searchTerm.trim().toLowerCase()) && !timeKeywordFound) {
      return {
        allDay: true,
        start: moment.tz(startDate, timeZone).startOf('day').toDate(),
        end: moment.tz(startDate, timeZone).endOf('day').toDate(),
      };
    }

    if (timeKeywordFound) {
      const timeRange = timeKeywords[timeKeywordFound as keyof typeof timeKeywords];
      const [startHour, startMinute] = timeRange.start.split(':').map(Number);
      const [endHour, endMinute] = timeRange.end.split(':').map(Number);

      startDate.setHours(startHour, startMinute, 0, 0);
      if (!endDate) {
        endDate = new Date(startDate);
      }
      endDate.setHours(endHour, endMinute, 0, 0);

      return {
        start: startDate,
        end: endDate,
        isTimeRange: true,
      };
    }

    const impliedStartValues = (parseDate.start as any).impliedValues;
    if (impliedStartValues) {
      if (impliedStartValues.hour !== undefined) startDate.setHours(impliedStartValues.hour);
      if (impliedStartValues.minute !== undefined) startDate.setMinutes(impliedStartValues.minute);
      if (impliedStartValues.second !== undefined) startDate.setSeconds(impliedStartValues.second);
      if (impliedStartValues.millisecond !== undefined) startDate.setMilliseconds(impliedStartValues.millisecond);
    }

    if (parseDate.end) {
      const impliedEndValues = (parseDate.end as any).impliedValues;
      if (impliedEndValues) {
        if (impliedEndValues.hour !== undefined) endDate?.setHours(impliedEndValues.hour);
        if (impliedEndValues.minute !== undefined) endDate?.setMinutes(impliedEndValues.minute);
        if (impliedEndValues.second !== undefined) endDate?.setSeconds(impliedEndValues.second);
        if (impliedEndValues.millisecond !== undefined) endDate?.setMilliseconds(impliedEndValues.millisecond);
      }

      if (endDate && startDate.getHours() < 12 && endDate.getHours() < startDate.getHours()) {
        endDate.setHours(endDate.getHours() + 12);
      }
    }

    const timeIsCertain = parseDate.start.isCertain('hour') && parseDate.start.isCertain('minute');

    if (isBefore || isBy) {
      return {
        end: timeIsCertain ? startDate : moment(startDate).startOf('day').toDate(),
        start: moment().toDate(),
        isTimeRange: true,
        timeIsCertain,
        display: isBefore ? 'before' : 'by',
      };
    }

    if (isAfter) {
      return {
        start: timeIsCertain ? startDate : moment(startDate).endOf('day').toDate(),
        isTimeRange: true,
        timeIsCertain,
        display: 'after',
      };
    }

    if (isBetween) {
      return {
        end: endDate,
        start: startDate,
        isTimeRange: true,
        display: 'between',
      };
    }

    const timeTerms = ['noon', 'midday', 'midnight', 'now'];
    const timeTermExists = timeTerms.some((phrase) => searchTerm.includes(phrase));

    if (
      parseDate.start.isCertain('hour') ||
      (impliedStartValues && impliedStartValues.hour !== undefined && timeTermExists)
    ) {
      return {
        start: startDate,
        end: endDate,
        isTimeRange: false,
      };
    }
    let generalWeekOrMonthTimeRange = false;

    if (parseDate.text.includes('this week')) {
      generalWeekOrMonthTimeRange = true;
      startDate = moment().startOf('week').toDate();
      endDate = moment().endOf('week').toDate();
    } else if (parseDate.text.includes('next week')) {
      generalWeekOrMonthTimeRange = true;
      startDate = moment().add(1, 'week').startOf('week').toDate();
      endDate = moment().add(1, 'week').endOf('week').toDate();
    } else if (parseDate.text.includes('this month')) {
      generalWeekOrMonthTimeRange = true;
      startDate = moment().startOf('month').toDate();
      endDate = moment().endOf('month').toDate();
    } else if (parseDate.text.includes('next month')) {
      generalWeekOrMonthTimeRange = true;
      startDate = moment().add(1, 'month').startOf('month').toDate();
      endDate = moment().add(1, 'month').endOf('month').toDate();
    }

    if (generalWeekOrMonthTimeRange && itemType === 'SCHEDULING_LINK') {
      return {
        start: startDate,
        end: endDate,
      };
    } else if (generalWeekOrMonthTimeRange) {
      return {
        start: startDate,
        end: endDate,
        isTimeRange: true,
      };
    }

    if (parseDate.start.isCertain('month') && !parseDate.start.isCertain('day')) {
      return {
        start: moment(startDate).startOf('day').toDate(),
        end: moment(startDate).endOf('month').endOf('day').toDate(),
        isTimeRange: true,
        allDay: true
      };
    } else if (parseDate.text.includes('week')) {
      return {
        start: startDate,
        end: moment(startDate).add(7, 'days').toDate(),
        isTimeRange: true,
        allDay: true
      };
    }
    if(endDate) {
      return {
        allDay: true,
        start: moment.tz(startDate, timeZone).startOf('day').toDate(),
        end: moment.tz(endDate, timeZone).endOf('day').toDate(),
        isTimeRange: false, // time range means make it auto-schedule
      };
    } else {
      return {
        allDay: true,
        start: moment.tz(startDate, timeZone).startOf('day').toDate(),
        end: moment.tz(startDate, timeZone).endOf('day').toDate(),
        isTimeRange: false,
      };
    }
  });
};
