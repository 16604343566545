import { ReactNode } from 'react';
import * as PopoverRadix from '@radix-ui/react-popover';
import { Cross2Icon } from '@radix-ui/react-icons';
import { cn } from 'ui/cn';

export const PopoverClose = PopoverRadix;

export const Popover = ({
  className = '',
  trigger,
  defaultOpen,
  arrowVisible = true,
  closeVisible = true,
  open,
  children,
  handleOpenPopover,
  align,
  side,
}: {
  className?: string;
  trigger: ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  arrowVisible?: boolean;
  closeVisible?: boolean;
  handleOpenPopover?: (value: boolean) => void;
  children: ReactNode;
  align?: 'center' | 'end' | 'start';
  side?: 'top' | 'right' | 'bottom' | 'left';
}) => (
  <PopoverRadix.Root onOpenChange={handleOpenPopover} open={open} defaultOpen={defaultOpen}>
    <PopoverRadix.Trigger asChild>{trigger}</PopoverRadix.Trigger>
    <PopoverRadix.Portal>
      <PopoverRadix.Content
        className={cn('rounded p-[20px] w-[250px] bg-white shadow will-change-transform', className)}
        align={align}
        side={side}>
        {children}
        {closeVisible ? (
          <PopoverRadix.Close
            className="rounded h-[24px] w-[24px] inline-flex items-center justify-center absolute top-[5px] right-[5px]"
            aria-label="Close">
            <Cross2Icon />
          </PopoverRadix.Close>
        ) : null}
        {arrowVisible ? <PopoverRadix.Arrow className="fill-white" /> : null}
      </PopoverRadix.Content>
    </PopoverRadix.Portal>
  </PopoverRadix.Root>
);
