type Props = {
  color?: string;
  size?: number;
  className?: string;
};

export default function DraggableIcon({ size = 16, color = '#A1A1A3', className }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_719_78881)">
        <path
          d="M1.25 2C1.25 2.41421 1.58579 2.75 2 2.75C2.41421 2.75 2.75 2.41421 2.75 2C2.75 1.58579 2.41421 1.25 2 1.25C1.58579 1.25 1.25 1.58579 1.25 2Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M7.25 2C7.25 2.41421 7.58579 2.75 8 2.75C8.41421 2.75 8.75 2.41421 8.75 2C8.75 1.58579 8.41421 1.25 8 1.25C7.58579 1.25 7.25 1.58579 7.25 2Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M13.25 2C13.25 2.41421 13.5858 2.75 14 2.75C14.4142 2.75 14.75 2.41421 14.75 2C14.75 1.58579 14.4142 1.25 14 1.25C13.5858 1.25 13.25 1.58579 13.25 2Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M1.25 8C1.25 8.41421 1.58579 8.75 2 8.75C2.41421 8.75 2.75 8.41421 2.75 8C2.75 7.58579 2.41421 7.25 2 7.25C1.58579 7.25 1.25 7.58579 1.25 8Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M7.25 8C7.25 8.41421 7.58579 8.75 8 8.75C8.41421 8.75 8.75 8.41421 8.75 8C8.75 7.58579 8.41421 7.25 8 7.25C7.58579 7.25 7.25 7.58579 7.25 8Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M13.25 8C13.25 8.41421 13.5858 8.75 14 8.75C14.4142 8.75 14.75 8.41421 14.75 8C14.75 7.58579 14.4142 7.25 14 7.25C13.5858 7.25 13.25 7.58579 13.25 8Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M1.25 14C1.25 14.4142 1.58579 14.75 2 14.75C2.41421 14.75 2.75 14.4142 2.75 14C2.75 13.5858 2.41421 13.25 2 13.25C1.58579 13.25 1.25 13.5858 1.25 14Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M7.25 14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14C8.75 13.5858 8.41421 13.25 8 13.25C7.58579 13.25 7.25 13.5858 7.25 14Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M13.25 14C13.25 14.4142 13.5858 14.75 14 14.75C14.4142 14.75 14.75 14.4142 14.75 14C14.75 13.5858 14.4142 13.25 14 13.25C13.5858 13.25 13.25 13.5858 13.25 14Z"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_719_78881">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
