import { useRef, useCallback } from 'react';

export default function usePlacesAsync() {
  const asRef = useRef<google.maps.places.AutocompleteService>();
  return useCallback(async (input: string) => {
    if (!input) return { status: 'OK' as const, data: [] };

    if (!asRef.current) {
      const { google } = window;
      const placesLib = google?.maps?.places;
      asRef.current = new placesLib.AutocompleteService();
    }

    try {
      const data = await asRef.current.getPlacePredictions({ input });
      return { status: 'OK' as const, data: data.predictions };
    } catch (error) {
      return { status: 'ERROR' as const, data: [] };
    }
  }, []);
}
