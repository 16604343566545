type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const FeedbackIcon = ({ className, size = 16, color = '#111317' }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none">
    <path
      d="M0.625 19.9297V3.05469C0.625 2.53906 0.80875 2.0975 1.17625 1.73C1.54375 1.3625 1.985 1.17906 2.5 1.17969H17.5C18.0156 1.17969 18.4572 1.36344 18.8247 1.73094C19.1922 2.09844 19.3756 2.53969 19.375 3.05469V14.3047C19.375 14.8203 19.1912 15.2619 18.8238 15.6294C18.4563 15.9969 18.015 16.1803 17.5 16.1797H4.375L0.625 19.9297ZM10 13.3672C10.2656 13.3672 10.4884 13.2772 10.6684 13.0972C10.8484 12.9172 10.9381 12.6947 10.9375 12.4297C10.9375 12.1641 10.8475 11.9413 10.6675 11.7613C10.4875 11.5813 10.265 11.4916 10 11.4922C9.73438 11.4922 9.51156 11.5822 9.33156 11.7622C9.15156 11.9422 9.06188 12.1647 9.0625 12.4297C9.0625 12.6953 9.1525 12.9181 9.3325 13.0981C9.5125 13.2781 9.735 13.3678 10 13.3672ZM9.0625 9.61719H10.9375V3.99219H9.0625V9.61719Z"
      fill={color}
    />
  </svg>
);
