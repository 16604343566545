type Props = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const ReferencesIcon = ({ className, width = 16, height = 15, color = "#000000" }: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_65_75679)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55204 2.2805L3.83704 3.994L3.27004 3.434L4.30504 2.394H1.90504C1.74745 2.394 1.59141 2.42504 1.44582 2.48534C1.30023 2.54565 1.16794 2.63404 1.05651 2.74547C0.945083 2.8569 0.856691 2.98919 0.796386 3.13478C0.73608 3.28037 0.705041 3.43641 0.705041 3.594C0.705041 3.75159 0.73608 3.90763 0.796386 4.05322C0.856691 4.19881 0.945083 4.3311 1.05651 4.44253C1.16794 4.55396 1.30023 4.64235 1.44582 4.70266C1.59141 4.76296 1.74745 4.794 1.90504 4.794H2.35004V5.594H1.91004C1.39661 5.56962 0.912266 5.34851 0.557526 4.97652C0.202786 4.60454 0.00488281 4.11026 0.00488281 3.59625C0.00488281 3.08224 0.202786 2.58796 0.557526 2.21598C0.912266 1.84399 1.39661 1.62287 1.91004 1.5985H4.31004L3.26954 0.565L3.83704 0L5.55204 1.716V2.2805ZM8.30954 12H3.50954L3.10954 11.6V5.206L3.50954 4.806H8.30954L8.70954 5.206V11.6L8.30954 12ZM3.90954 11.2H7.90954V5.606H3.90954V11.2ZM6.70954 0.801H11.5095L11.9095 1.201V7.594L11.5095 7.994H9.50954V7.194H11.1095V1.6H7.10954V3.9935H6.30954V1.2005L6.70954 0.8005V0.801ZM7.10954 6.394H4.70954V7.194H7.10954V6.394ZM4.70954 7.994H7.10954V8.794H4.70954V7.994ZM7.10954 9.594H4.70954V10.394H7.10954V9.594ZM7.90954 2.394H10.3095V3.194H7.90954V2.394ZM10.3095 5.594H9.50954V6.394H10.3095V5.594ZM8.64054 4.006V3.994H10.3095V4.794H9.42854L8.64054 4.006Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_65_75679">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
