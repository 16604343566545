import { useCallback } from 'react';
import moment from 'moment-timezone';
import { GetTasks, Task } from '@/models/TaskModel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut, transformIntoDate } from 'utils/smarty-api';
import { useEasySession } from '@/hooks/useEasySession';
import { convertTaskArgToTask, convertTaskToTaskArg, TaskArg } from '@/models/TaskArg';
import { RecurringInstance } from '@/models/EventModel';

const emptyTasks = () => ({
  count: 0,
  results: [] as Task[],
});

export function processTaskResponse(task: Task) {
  return {
    ...task,
    createdAt: transformIntoDate(task, 'createdAt'),
    updatedAt: transformIntoDate(task, 'updatedAt'),
    dueDate: transformIntoDate(task, 'dueDate'),
    start: transformIntoDate(task, 'start'),
    end: transformIntoDate(task, 'end'),
    completedDate: transformIntoDate(task, 'completedDate'),
    subtasks: task.subtasks.map((subtask) => ({
      ...subtask,
      createdAt: transformIntoDate(task, 'createdAt'),
      updatedAt: transformIntoDate(task, 'updatedAt'),
      dueDate: transformIntoDate(subtask, 'dueDate'),
      start: transformIntoDate(subtask, 'start'),
      end: transformIntoDate(subtask, 'end'),
      completedDate: transformIntoDate(subtask, 'completedDate'),
    })),
  };
}

type useTasksProps = {
  onCreated?: (task: Task) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (task: Task) => void;
  onUpdateError?: (error: void) => void;
  disableQuery?: boolean;
};

export const useTasks = ({ onCreated, onCreateError, onUpdated, onUpdateError, disableQuery }: useTasksProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: tasks, isLoading: isLoadingTasks } = useQuery({
    queryKey: ['TASKS'],
    queryFn: async () => {
      const res = await httpGet<GetTasks>(`/tasks`);
      const allTasks = res.results.map((task) => processTaskResponse(task));
      return {
        ...res,
        results: allTasks,
      };
    },
    enabled: !disableQuery && isAuthenticated,
  });

  const {
    mutate: createTask,
    mutateAsync: createTaskAsync,
    status: creatingTaskStatus,
    error: createTaskError,
    isSuccess: isCreateTaskSuccess,
    reset: resetCreateTask,
  } = useMutation<Task, void, TaskArg>({
    mutationKey: ['CREATE_TASK'],
    mutationFn: async (data) => {
      const response = await httpPost<void, Task, TaskArg>('/tasks', data);
      return processTaskResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['TASKS'] });
      const previous = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();
      const results = [...previous!.results!, convertTaskArgToTask({ taskArg: payload })];
      if (payload.userOrder) {
        results.sort((a, b) => a.userOrder - b.userOrder);
      }
      queryClient.setQueryData<GetTasks>(['TASKS'], () => ({ count: previous!.count + 1, results }));
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<GetTasks>(['TASKS'], (previous) => previous);
      onCreateError?.(error);
    },
    onSuccess: (task) => {
      void queryClient.invalidateQueries({ queryKey: ['TASKS'] });
      onCreated?.(task);
    },
  });

  const {
    mutate: updateTask,
    status: updatingTaskStatus,
    error: updateTaskError,
    isSuccess: isUpdateTaskSuccess,
    reset: resetUpdateTask,
  } = useMutation<
    Task,
    void,
    {
      taskId: string;
      task: TaskArg;
      recurringChange?: RecurringInstance;
      originalStart?: Date;
    }
  >({
    mutationKey: ['UPDATE_TASK'],
    mutationFn: async ({ taskId, task, recurringChange, originalStart }) => {
      const response = await httpPut<void, Task, TaskArg>(`/tasks/${taskId}`, task, {
        params: {
          recurringChange,
          originalStart,
        },
      });
      return processTaskResponse(response);
    },
    onMutate: async (payload) => {
      const previous = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();
      await queryClient.invalidateQueries({ queryKey: ['TASKS'] });
      queryClient.setQueryData<GetTasks>(['TASKS'], () => ({
        count: previous.count,
        results: previous.results.map((p) => {
          if (p.id === payload.task.parentTaskId) {
            return {
              ...p,
              subtasks: p.subtasks?.map((subtask) => {
                if (subtask.id !== payload.taskId) return subtask;
                return {
                  ...subtask,
                  ...convertTaskArgToTask({
                    taskArg: payload.task,
                    taskId: payload.taskId,
                    contacts: payload.task.contacts,
                    userOrder: subtask.userOrder,
                  }),
                };
              }) as Task['subtasks'],
            };
          }

          if (p.id !== payload.taskId) {
            return p;
          }

          return {
            ...p,
            ...convertTaskArgToTask({
              taskArg: payload.task,
              taskId: payload.taskId,
              contacts: payload.task.contacts,
              userOrder: p.userOrder,
              subtasks: p.subtasks,
            }),
          };
        }),
      }));
    },
    onError: (error) => {
      queryClient.setQueryData<GetTasks>(['TASKS'], (previous) => previous);
      onUpdateError?.(error);
    },
    onSuccess: (task) => {
      void queryClient.invalidateQueries({ queryKey: ['TASKS'] });
      onUpdated?.(task);
    },
  });

  // order client-side, and send the request to order server-side
  const reorderTask = useCallback(
    async (
      taskId: string,
      start: Date | null | undefined,
      end: Date | null | undefined,
      previousTaskId: string | null | undefined
    ) => {
      const tasksInCache = queryClient.getQueryData<GetTasks>(['TASKS']);
      if (!tasksInCache) return;
      const newTasks = [...tasksInCache.results];
      const sourceIndex = newTasks.findIndex((t) => t.id === taskId);

      // place the dragged task right after the previous one
      const previousTaskIndex = newTasks.findIndex((t) => t.id === previousTaskId);

      // calculate new order
      let newUserOrder;
      // first position
      if (previousTaskIndex === -1) {
        newUserOrder = newTasks[0].userOrder / 2;
      }
      // last position
      else if (previousTaskIndex === newTasks.length - 1) {
        newUserOrder = newTasks[newTasks.length - 1].userOrder + 1;
      } else {
        newUserOrder = (newTasks[previousTaskIndex].userOrder + newTasks[previousTaskIndex + 1].userOrder) / 2;
      }

      // we extract the target and add the source to the destination
      const [extracted] = newTasks.splice(sourceIndex, 1);
      const allDay = moment(start).diff(end, 'minute') % 1440 === 0;

      newTasks.splice(previousTaskIndex, 0, {
        ...extracted,
        allDay,
        taskScheduleType: start ? 'SPECIFIC' : 'NONE',
        start,
        end,
        userOrder: newUserOrder,
      });
      newTasks.sort((a, b) => a.userOrder - b.userOrder);

      queryClient.setQueryData<GetTasks>(['TASKS'], {
        ...tasksInCache,
        results: newTasks,
      });

      const response = await httpPost<
        void,
        Task,
        {
          previousTaskId: string | null | undefined;
          start: string | null | undefined;
          end: string | null | undefined;
        }
      >(`/tasks/${taskId}/order`, {
        start: start?.toISOString(),
        end: end?.toISOString(),
        previousTaskId,
      });
      const newTask = processTaskResponse(response);

      queryClient.setQueryData<GetTasks>(['TASKS'], {
        ...tasksInCache,
        results: newTasks.map((t) => (t.id === taskId ? newTask : t)),
      });
    },
    [queryClient]
  );

  return {
    tasks,
    isLoadingTasks,
    createTask,
    createTaskAsync,
    isCreatingTask: creatingTaskStatus === 'pending',
    isCreateTaskSuccess,
    createTaskError,
    resetCreateTask,
    updateTask,
    isUpdatingTask: updatingTaskStatus === 'pending',
    isUpdateTaskSuccess,
    updateTaskError,
    resetUpdateTask,
    reorderTask,
  };
};

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    void,
    void,
    {
      taskId: string;
      recurringDeletion?: RecurringInstance;
      dateDelete?: Date;
    }
  >({
    mutationKey: ['DELETE_TASK'],
    mutationFn: async ({ taskId, recurringDeletion, dateDelete }) => {
      await httpDelete(`/tasks/${taskId}`, {
        params: {
          recurringDeletion,
          dateDelete,
        },
      });
    },

    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['TASKS'] });
      const cached = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();

      const newResults = cached?.results?.filter((t) => t.id !== variables.taskId);

      newResults.forEach((task) => {
        if (!task.subtasks?.some((subtask) => subtask.id === variables.taskId)) return;
        task.subtasks = task.subtasks?.filter((subtask) => subtask.id !== variables.taskId);
      });

      queryClient.setQueryData(['TASKS'], () => ({
        results: newResults,
        count: cached.count - 1,
      }));

      return cached;
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({ queryKey: ['TASKS'] });
      const cached = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();
      queryClient.setQueryData(['TASKS'], () => ({
        results: cached.results.filter((t) => t.id !== variables.taskId),
        count: cached.count - 1,
      }));
    },
    onError: () => {
      queryClient.setQueryData<Task>(['TASKS'], (previous) => previous);
    },
  });
  return {
    deleteTask: mutation.mutate,
    isDeletingTask: mutation.status === 'pending',
    isDeleteTaskSuccess: mutation.isSuccess,
    deleteTaskError: mutation.error,
    resetDeleteTask: mutation.reset,
  };
};

export const useTaskCompleted = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Task,
    void,
    {
      taskId: string;
      isCompleted: boolean;
      taskDate?: Date;
      recurringInstance?: RecurringInstance;
      originalStart?: Date;
    }
  >({
    mutationKey: ['COMPLETE_TASK'],
    mutationFn: async (data) => {
      const tasks = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();
      const oldTask = tasks.results.find((t) => t.id === data.taskId);
      if (!oldTask) throw new Error('Task not found');
      const updatedTask = convertTaskToTaskArg(oldTask);

      if (updatedTask.recurrence?.[0]) {
        const key = moment(data.taskDate).format('YYYY-MM-DD');
        const recurrenceInstances = updatedTask.recurrenceInstances as Record<
          string,
          Record<string, boolean | Date | null | undefined>
        >;

        if (!recurrenceInstances[key]) recurrenceInstances[key] = {};

        recurrenceInstances[key].isCompleted = data.isCompleted;
        recurrenceInstances[key].completedDate = data.isCompleted ? new Date() : undefined;
        recurrenceInstances[key].originalStart = data.taskDate;
      } else {
        updatedTask.isCompleted = data.isCompleted;
        updatedTask.completedDate = data.isCompleted ? new Date() : null;
      }
      const response = await httpPut<void, Task, TaskArg>(`/tasks/${data.taskId}`, updatedTask);
      return processTaskResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['TASKS'] });
      const previous = queryClient.getQueryData<GetTasks>(['TASKS']) ?? emptyTasks();
      queryClient.setQueryData<GetTasks>(['TASKS'], () => ({
        count: previous.count,
        results: previous.results.map((t) => {
          if (t.id !== payload.taskId) {
            // if the task is not the one we are looking for, we check if it has subtasks
            // if it does, we check if the subtask is the one we are looking for and update it
            if (!t.subtasks?.some((subtask) => subtask.id === payload.taskId)) return t;
            return {
              ...t,
              subtasks: t.subtasks?.map((subtask) => {
                if (subtask.id !== payload.taskId) return subtask;
                return {
                  ...subtask,
                  isCompleted: payload.isCompleted,
                  completedDate: payload.isCompleted ? new Date() : undefined,
                };
              }),
            };
          }

          if (t.recurrence?.[0]) {
            const key = moment(payload.taskDate).format('YYYY-MM-DD');
            const recurrenceInstances = t.recurrenceInstances as Record<
              string,
              Record<string, boolean | Date | null | undefined>
            >;

            if (!recurrenceInstances[key]) recurrenceInstances[key] = {};

            recurrenceInstances[key].isCompleted = payload.isCompleted;
            recurrenceInstances[key].completedDate = payload.isCompleted ? new Date() : undefined;

            return t;
          }

          return {
            ...t,
            isCompleted: payload.isCompleted,
            completedDate: payload.isCompleted ? new Date() : undefined,
          };
        }),
      }));
      return previous;
    },
    onError: () => {
      queryClient.setQueryData<GetTasks>(['TASKS'], (previous) => previous);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['TASKS'] });
    },
  });

  return {
    toggleTaskCompleted: mutation.mutate,
    isTogglingTaskCompleted: mutation.status === 'pending',
    toggleTaskCompletedError: mutation.error,
    isTogglingTaskCompletedSuccess: mutation.isSuccess,
    resetUpdateTaskCompleted: mutation.reset,
  };
};
