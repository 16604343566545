import moment from 'moment-timezone';
import { useEvents } from '@/hooks/useEvents';
import Fab from '@/components/Fab';
import { useTasks } from '@/hooks/useTasks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { Task } from '@/models/TaskModel';
import { Event } from '@/models/EventModel';
import Calendar from '@/components/Calendar';
import { EditionMode, IndicatorContext } from '@/components/Calendar/types';
import CalendarContext from '@/components/Calendar/context';
import { useSettings } from '@/hooks/useSettings';
import { ContactEvent, useContactEvents } from '@/hooks/useContactEvents';
import { useAtomValue } from 'jotai/index';
import { timeZoneAtom } from '@/components/calendarAtoms';

export const SingleDayCalendar = () => {
  const { tasks } = useTasks({});
  const { events, refetchEvents } = useEvents({});

  const { timeZones, defaultTimeZone } = useSettings();
  const { globalDateRange } = useGlobalDateRange();

  const timeZoneAtomValue = useAtomValue(timeZoneAtom);
  const [timeZone, setTimeZone] = useState(defaultTimeZone ?? '');
  useEffect(() => {
    setTimeZone(timeZoneAtomValue || defaultTimeZone || '');
  }, [timeZoneAtomValue, setTimeZone, defaultTimeZone]);

  const { contactEvents } = useContactEvents({
    start: globalDateRange.start,
    end: globalDateRange.end,
    contactEmails: [], // TODO move selected contacts to a shared atom
    timeZone,
  });

  const items = useMemo(() => {
    const _events = (events || []) as Event[];
    const _tasks = (tasks?.results || []) as Task[];
    const _contactEvents = (contactEvents || []) as ContactEvent[];
    return [..._events, ..._tasks, ..._contactEvents]
      .filter((item) => {
        if (!item.start || !item.end) return false;
        if (item.allDay) return true;

        // noinspection RedundantIfStatementJS
        if (moment(item.start).isSame(item.end, 'day') && moment(item.start).hours() > 24) return false;

        return true;
      })
      .sort((item1, item2) => item1.start!.getTime() - item2.start!.getTime());
  }, [contactEvents, events, tasks?.results]);

  const indicatorContext = useRef<IndicatorContext>({
    selectedDate: null,
    dates: null,
    action: null,
    selectedItem: null,
    activeColumn: null,
    duration: null,
  });

  useEffect(() => {
    void refetchEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDateRange, contactEvents]);

  return (
    <CalendarContext.Provider
      value={{
        items,
        timeZone,
        setTimeZone,
        timeZones: timeZones?.map((tz) => tz.name) || [timeZone],
        hourHeight: 48,
        step: 15,
        editionMode: EditionMode.EVENTS,
        indicatorContext: indicatorContext.current,
        forceSingleDay: true,
      }}>
      <Calendar />
      <Fab />
    </CalendarContext.Provider>
  );
};
