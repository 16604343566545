import Link from 'next/link';
import { EventIcon } from '@/icons/EventIcon';
import { ViewSelector } from '@/screens/home/CalendarView/ViewSelector';
import { SmartyLogo } from 'ui/icons/SmartyLogo';
import { useEasySession } from '@/hooks/useEasySession';
import { WallClock } from 'ui/WallClock';

export const SettingsPageHeader = () => {
  const { isActive, hasTrialEnded } = useEasySession();
  const shouldDisableButtons = !isActive && hasTrialEnded;

  return (
    <header
      className={`h-[99px] min-h-[99px] flex md:grid grid-cols-1 grid-rows-1 text-[14px] leading-[100%] px-[1.5rem]`}>
      {/* LEFT -> NOTHING */}
      <div className="row-span-full col-span-full md:mr-auto flex items-baseline gap-[10px] max-w-[850px] py-[10px]"></div>

      {/* CENTER */}
      <div className="row-span-full col-span-full md:mx-auto flex items-center gap-[16px] max-w-[850px] py-[10px]">
        <Link href={'/'} shallow draggable={false}>
          <SmartyLogo />
          {/* <h1 className="font-lexend text-[20px] font-bold hover:cursor-pointer">{`/smarty`}</h1> */}
        </Link>
        <div className="flex items-center gap-[12px]">
          <WallClock />
          <EventIcon color="#000" className="w-[20px] h-[20px]" />
        </div>
      </div>

      {/* RIGHT */}
      {!shouldDisableButtons ? (
        <div className="ml-auto row-span-full col-span-full place-self-stretch flex items-center gap-[10px]">
          <ViewSelector />
        </div>
      ) : null}
    </header>
  );
};
