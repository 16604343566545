import { cn } from 'ui/cn';
import * as SwitchPrimitive from '@radix-ui/react-switch';

type Props = {
  label?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  name?: string;
};

export default function Switch({ label, disabled, value, onChange, className, name }: Props) {
  return (
    <label className={cn('flex justify-between items-center text-sm leading-normal', className)}>
      {label}
      <SwitchPrimitive.Root
        name={name || label}
        className="
        group focus:ring-1 w-[36px] h-[18px]
        rounded-full bg-gray-border enabled:data-[state=checked]:bg-black
        disabled:bg-gray-100 disabled:cursor-not-allowed"
        disabled={disabled}
        checked={value}
        onCheckedChange={onChange}>
        <SwitchPrimitive.Thumb
          className="
            block w-2.5 h-2.5 rounded-full
            will-change-transform
            transition-transform translate-x-1
            data-[state=checked]:translate-x-[22px]
            bg-white group-disabled:bg-gray-300 select-none"
        />
      </SwitchPrimitive.Root>
    </label>
  );
}
