import { ChangeEvent, useCallback } from 'react';
import { EditorTypes } from '../types';
import { iconByItemType } from '@/utils/icons';

type Props = {
  type: EditorTypes;
  placeholder: string;
  title: string;
  onChange: (title: string) => void;
};

export default function Title({ type, title, placeholder, onChange }: Props) {
  const Icon = iconByItemType[type];

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-2.5">
      <div>
        <Icon size={18} color="black" />
      </div>
      <input className="text-2xl font-semibold leading-none xs:text-xl w-full" value={title} onChange={handleOnChange} placeholder={placeholder} />
    </div>
  );
}
