type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export default function HighPriorityIcon({ className, size = 14, color = '#A1A1A3' }: Props) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M9.75 1.375C9.75 1.07663 9.86853 0.790483 10.0795 0.579505C10.2905 0.368526 10.5766 0.25 10.875 0.25H12.375C12.6734 0.25 12.9595 0.368526 13.1705 0.579505C13.3815 0.790483 13.5 1.07663 13.5 1.375V12.625C13.5 12.9234 13.3815 13.2095 13.1705 13.4205C12.9595 13.6315 12.6734 13.75 12.375 13.75H10.875C10.5766 13.75 10.2905 13.6315 10.0795 13.4205C9.86853 13.2095 9.75 12.9234 9.75 12.625V1.375ZM4.875 5.875C4.875 5.57663 4.99353 5.29048 5.2045 5.0795C5.41548 4.86853 5.70163 4.75 6 4.75H7.5C7.79837 4.75 8.08452 4.86853 8.2955 5.0795C8.50647 5.29048 8.625 5.57663 8.625 5.875V12.625C8.625 12.9234 8.50647 13.2095 8.2955 13.4205C8.08452 13.6315 7.79837 13.75 7.5 13.75H6C5.70163 13.75 5.41548 13.6315 5.2045 13.4205C4.99353 13.2095 4.875 12.9234 4.875 12.625V5.875ZM1.125 9.25C0.826631 9.25 0.540483 9.36853 0.329505 9.5795C0.118526 9.79048 0 10.0766 0 10.375V12.625C0 12.9234 0.118526 13.2095 0.329505 13.4205C0.540483 13.6315 0.826631 13.75 1.125 13.75H2.625C2.92337 13.75 3.20952 13.6315 3.4205 13.4205C3.63147 13.2095 3.75 12.9234 3.75 12.625V10.375C3.75 10.0766 3.63147 9.79048 3.4205 9.5795C3.20952 9.36853 2.92337 9.25 2.625 9.25H1.125Z"
        fill={color}
      />
    </svg>
  );
}
