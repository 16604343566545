import { httpDelete, httpGet } from 'utils/smarty-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEasySession } from '@/hooks/useEasySession';
import { AccountProvider } from '@prisma/client';

export type Account = {
  id: string;
  provider: AccountProvider;
  email: string;
  scope: string;
  isDefault: boolean;
  canUseCalendarAccount: boolean;
  canUseConferencingAccount: boolean;
  canUseContactsAccount: boolean;
  canUseEmailAccount: boolean;
};

export const useAccounts = () => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: accounts } = useQuery(
    {
      queryKey: ['ACCOUNTS'],
      queryFn: () => httpGet<Account[]>('/accounts'),
      enabled: isAuthenticated,
    },
    queryClient
  );

  const { mutateAsync: deleteAccount } = useMutation<void, void, { accountId: string }>(
    {
      mutationKey: ['DELETE_ACCOUNT'],
      mutationFn: ({ accountId }) => httpDelete(`/accounts/${accountId}`),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['ACCOUNTS'] });
      },
    },
    queryClient
  );

  return {
    accounts,
    deleteAccount,
  };
};
