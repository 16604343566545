import { calcHeight, calcTop, hoursToPixels } from '../utils';
import TimeIndicator from './TimeIndicator';
import { Droppable } from 'react-beautiful-dnd';
import { createDroppableId, DroppableType } from '@/utils/dnd';
import { useContext, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { EditionMode, ItemType } from '../types';
import { checkCollision } from '@/utils/date';
import Item from './Item';
import CalendarContext from '@/components/Calendar/context';
import useDragIndicator from '../hooks/useDragIndicator';
import { convertAvailableSpecificIntervalsIntoBlockedIntervals } from '@/utils/scheduling';

function Grid() {
  useEffect(() => {
    // Get the element representing the 7am
    const seventhPositionElement = document.getElementById('position-7');

    // Scroll to the 7am position
    seventhPositionElement?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <div className="absolute inset-0 flex flex-col">
      {Array.from({ length: 24 }).map((_, hour) => (
        <div key={hour} id={`position-${hour}`} className="flex flex-1 w-full border" />
      ))}
    </div>
  );
}

// Helper function to calculate the duration of an event
const getDuration = (start?: Date | null, end?: Date | null): number => {
  if (!start || !end) return 0;
  return (end.getTime() - start.getTime()) / 1000 / 60;
};

const canAddToColumn = (column: ItemType[][], group: ItemType[]): boolean => {
  const earliestStartInGroup = new Date(group[0].start as Date);
  return column.every((existingGroup) => {
    const start = new Date(existingGroup[0].start as Date);
    return earliestStartInGroup.getTime() - start.getTime() > 60 * 60 * 1000;
  });
};

// Function to check if two columns can be merged based on end times and start times
const canMergeColumns = (column1: ItemType[][], column2: ItemType[][]): boolean => {
  const latestEndInColumn1 = new Date(Math.max(...column1.flat().map((item) => new Date(item.end as Date).getTime())));
  const earliestStartInColumn2 = new Date(
    Math.min(...column2.flat().map((item) => new Date(item.start as Date).getTime()))
  );
  return latestEndInColumn1 <= earliestStartInColumn2;
};

// Main function to sort and organize items into columns
const sortItemsIntoColumns = (items: ItemType[]): ItemType[][][] => {
  // Step 1: Sort items by start time
  items.sort((a, b) => new Date(a.start as Date).getTime() - new Date(b.start as Date).getTime());
  const groups: Record<string, ItemType[]> = {};

  // Step 2: Group items by start time
  items.forEach((item) => {
    const startTime = (item.start && item.start.toISOString()) || new Date().toISOString();
    if (!groups[startTime]) {
      groups[startTime] = [];
    }
    groups[startTime].push(item);
  });

  // Step 3: Sort items within each group by duration
  Object.keys(groups).forEach((startTime) => {
    groups[startTime].sort((a, b) => getDuration(b.start, b.end) - getDuration(a.start, a.end));
  });

  // Step 4: Create initial columns based on rules about when columns can be merged
  const columns: ItemType[][][] = [];
  Object.keys(groups).forEach((startTime) => {
    const group = groups[startTime];
    let addedToColumn = false;

    for (let i = 0; i < columns.length; i++) {
      if (canAddToColumn(columns[i], group)) {
        columns[i].push(group);
        addedToColumn = true;
        break;
      }
    }

    if (!addedToColumn) {
      columns.push([group]); // Note the change here; we're pushing an array containing the group
    }
  });

  // Step 5: Merge columns if possible based on the new rule
  for (let i = 0; i < columns.length - 1; i++) {
    if (canMergeColumns(columns[i], columns[i + 1])) {
      columns[i] = [...columns[i], ...columns[i + 1]];
      columns.splice(i + 1, 1);
      i--; // adjust index after merging
    }
  }

  return columns;
};

const calculateLeftOffset = (
  columnIndex: number,
  columnsCount: number,
  dayViewWidth: number,
  numberOfEventsAtSameTime: number,
  itemIndex: number
): number => {
  return (
    (dayViewWidth / columnsCount) * columnIndex + ((dayViewWidth / columnsCount) * itemIndex) / numberOfEventsAtSameTime
  );
};

// Helper function to calculate width based on overlapping columns
const calculateWidth = (
  columnIndex: number,
  columnsCount: number,
  dayViewWidth: number,
  numberOfEventsAtSameTime: number,
  itemIndex: number
): number => {
  if (columnIndex === columnsCount - 1) {
    // Last column takes the remaining width
    return (
      dayViewWidth - calculateLeftOffset(columnIndex, columnsCount, dayViewWidth, numberOfEventsAtSameTime, itemIndex)
    );
  }
  return dayViewWidth / columnsCount; // Divide the width equally among columns
};

type Props = {
  date: moment.Moment;
  isToday: boolean;
};

export default function Column({ date, isToday }: Props) {
  const { hourHeight, timeZone, items, freeSlots, editionMode } = useContext(CalendarContext);
  const { setColumnRef, updateItem, selectedItem, renderedDragIndicator, renderedItemBeingCreated } =
    useDragIndicator(date);

  const renderedItems = useMemo(() => {
    const startOfDay = date.clone().startOf('days');

    const itemsByGroup = items.reduce((acc: ItemType[][], item: ItemType) => {
      if ((item.itemType === 'EVENT' || item.itemType === 'TASK' || item.itemType === 'CONTACT_EVENT') && item.allDay)
        return acc;
      if (!startOfDay.isSame(moment.tz(item.start, timeZone), 'day')) return acc;
      if (selectedItem?.id === item.id) return acc;

      if (item.itemType !== 'SLOT') {
        const group = acc.find((group) => {
          if (group[0].itemType === 'SLOT') return false;
          return group.some((item2) => checkCollision(item.start!, item.end!, item2.start!, item2.end!));
        });

        if (group) {
          group.push(item);
          return acc;
        }
      }

      return [...acc, [item]];
    }, [] as ItemType[][]);

    let index = 0;
    return itemsByGroup.flatMap((group) => {
      const columns = sortItemsIntoColumns(group);
      const zIndexBase = 1;
      const dayViewWidth = 90;
      return columns.flatMap((column, columnIndex, allColumns) => {
        const columnsCount = allColumns.length;

        return column.flatMap((startTimeObj) => {
          const numberOfEventsAtSameTime = startTimeObj.length;
          return startTimeObj.map((item, itemIndex) => {
            const width = calculateWidth(columnIndex, columnsCount, dayViewWidth, numberOfEventsAtSameTime, itemIndex);
            const leftOffset = calculateLeftOffset(
              columnIndex,
              columnsCount,
              dayViewWidth,
              numberOfEventsAtSameTime,
              itemIndex
            );
            const zIndex = zIndexBase + columnIndex + itemIndex;
            return (
              <Item
                key={index++}
                index={index}
                item={item}
                onChangeStart={updateItem(item, 'start')}
                onChangeEnd={updateItem(item, 'end')}
                style={{
                  top: calcTop(hourHeight, timeZone, item.start!),
                  height: calcHeight(hourHeight, timeZone, item.start!, item.end!),
                  width: `${width}%`,
                  borderRadius: '5px',
                  left: `${leftOffset}%`,
                  zIndex: zIndex,
                }}
              />
            );
          });
        });
      });
    });
  }, [date, hourHeight, items, selectedItem?.id, timeZone, updateItem]);

  const renderedBlockedIntervals = useMemo(() => {
    if (!freeSlots) return null;
    const freeSlotsOfDay = freeSlots.filter((freeSlot) => moment.tz(freeSlot.start, timeZone).isSame(date, 'day'));

    // invert free slots into blocked intervals
    // if there are freeslots at 9:00, 9:30, 10:00, then return blocked intervals 0-9:00, 10:30-24:00
    const blockedIntervals = convertAvailableSpecificIntervalsIntoBlockedIntervals(
      date.clone().toDate(),
      date.clone().startOf('day').add(1, 'days').toDate(),
      freeSlotsOfDay,
      timeZone
    );

    return blockedIntervals.map((event, eventIndex) => {
      return (
        <div
          key={eventIndex}
          className="absolute inset-x-0 z-10 overflow-hidden border pointer-events-none bg-gray-700/20"
          data-blocked-interval
          style={{
            top: calcTop(hourHeight, timeZone, event.start),
            height: calcHeight(hourHeight, timeZone, event.start, event.end!),
          }}
        />
      );
    });
  }, [freeSlots, date, timeZone, hourHeight]);

  return (
    <Droppable
      droppableId={createDroppableId(DroppableType.CALENDAR, date.format('YYYY-MM-DD'))}
      isDropDisabled={false}>
      {(provided) => (
        <div
          className="relative flex-1 border-0 rounded cursor-pointer user-select-none"
          style={{ height: hoursToPixels(hourHeight, 24) }}
          onClick={(e) => e.stopPropagation()}
          ref={(ref) => {
            setColumnRef(ref);
            provided.innerRef(ref);
          }}
          {...provided.droppableProps}
          data-targetable-column>
          <div hidden>{provided.placeholder}</div>
          <Grid />
          {renderedItems}
          {renderedDragIndicator}
          {editionMode === EditionMode.SLOTS
            ? null
            : editionMode === EditionMode.BOOKING
              ? null
              : renderedItemBeingCreated}
          {renderedBlockedIntervals}
          {/* time of day indicator */}
          {isToday && <TimeIndicator hourHeight={hourHeight} timeZone={timeZone} />}
        </div>
      )}
    </Droppable>
  );
}
