type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export default function TagIcon({ className, size = 14, color = 'black' }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none">
      <path
        d="M2.75 4.5C2.94891 4.5 3.13968 4.42098 3.28033 4.28033C3.42098 4.13968 3.5 3.94891 3.5 3.75C3.5 3.55109 3.42098 3.36032 3.28033 3.21967C3.13968 3.07902 2.94891 3 2.75 3C2.55109 3 2.36032 3.07902 2.21967 3.21967C2.07902 3.36032 2 3.55109 2 3.75C2 3.94891 2.07902 4.13968 2.21967 4.28033C2.36032 4.42098 2.55109 4.5 2.75 4.5ZM8.705 5.79C8.885 5.97 9 6.22 9 6.5C9 6.775 8.89 7.025 8.705 7.205L6.205 9.705C6.11226 9.79822 6.00202 9.87219 5.8806 9.92266C5.75918 9.97314 5.62899 9.99912 5.4975 9.99912C5.36601 9.99912 5.23582 9.97314 5.1144 9.92266C4.99298 9.87219 4.88274 9.79822 4.79 9.705L1.295 6.21C1.11 6.025 1 5.775 1 5.5V3C1 2.445 1.445 2 2 2H4.5C4.775 2 5.025 2.11 5.205 2.29L8.705 5.79ZM6.77 2.855L7.27 2.355L10.705 5.79C10.89 5.97 11 6.225 11 6.5C11 6.775 10.89 7.025 10.71 7.205L8.02 9.895L7.52 9.395L10.375 6.5L6.77 2.855Z"
        fill={color}
      />
    </svg>
  );
}
