import type { JSONContent } from '@tiptap/react';
import type { Editor } from '@tiptap/core';
import { type MutableRefObject, useEffect } from 'react';

export default function useStateManager({
  prevStateRef,
  content,
  editor,
}: {
  prevStateRef: MutableRefObject<JSONContent | null>;
  content: JSONContent[];
  editor: Editor | null;
}) {
  useEffect(() => {
    if (!editor) return;
    
    const handleEditorContentSet = () => {
      setTimeout(() => {
        if (!editor.isFocused) {
          editor.commands.setContent(content);
          prevStateRef.current = editor.getJSON() || null;
        }
      }, 0);
    };
    handleEditorContentSet();

    return () => {
      editor.off('update', handleEditorContentSet);
    };
  }, [content, editor, prevStateRef]);

  useEffect(() => {
    if (!editor) return;

    const onFocus = ({ editor }: { editor: Editor }) => {
      prevStateRef.current = editor.getJSON();
    };

    const onBlur = () => {
      if (document.getElementById('mention-list')) {
        return;
      }
      setTimeout(() => {
        if (editor) editor.commands.save();
      }, 0);
    };

    editor.on('focus', onFocus);
    editor.on('blur', onBlur);

    return () => {
      editor.off('focus', onFocus);
      editor.off('blur', onBlur);
    };
  }, [editor, prevStateRef]);
}
