import Tooltip from '@/components/Tooltip';
import { useSettings } from '@/hooks/useSettings';
import AlertIcon from '@/icons/AlertIcon';
import { formatDateRangeDecorator } from '@/utils/date';

export const RolledOverIndicator = ({ start, end, isAllDay }: { start: Date; end: Date; isAllDay?: boolean }) => {
  const { defaultTimeZone: timeZone } = useSettings();
  return (
    <Tooltip
      tooltip="Rolled Over: This task was scheduled for a previous date but was not marked as complete so it has rolled over to today."
      size="sm">
      <div className="inline-flex items-center mx-2 h-6 align-top text-[#FFB20F] bg-[#EFEFEF] p-1 rounded">
        {formatDateRangeDecorator({
          timeZone: timeZone,
          allDay: isAllDay,
          start: start,
          end: end,
          forceShowDate: true,
        })}
        <AlertIcon className="ml-[2px]" color="#FFB20F" />
      </div>
    </Tooltip>
  );
};
