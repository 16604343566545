import { useEffect, useMemo, useRef, useState } from 'react';
import createSuggestions from '../create';
import { Submenu, Suggestion, SuggestionType } from '../types';
import { useSettings } from '@/hooks/useSettings';
import { capitalize } from 'utils/string';
import { MdLocationOn, MdOutlineSignalCellularAlt } from 'react-icons/md';
import { ClockIcon } from '@radix-ui/react-icons';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { getItemTypeFromEditor } from '../../../utils/editor';
import { extractSuggestionTypeAndValue } from '../../../utils/suggestions';
import Decorators from '../Decorators';
import { ReactNodeViewRenderer } from '@tiptap/react';
import {
  CONFERENCE_SUGGESTIONS,
  ITEM_TYPE_SUGGESTIONS,
  PRIORITY_SUGGESTIONS,
  TIME_BLOCKING_SUGGESTIONS,
  VISIBILITY_TYPE_SUGGESTIONS,
  filterBySearch,
  getAutoScheduleSuggestions,
  getDatesSuggestions,
  getDurationSuggestions,
  getRecurrenceSuggestions,
  getSubmenuSuggestions,
  getSuggestedContacts,
  getSuggestedCustomSchedules,
  getSuggestedFreeSlots,
  useSuggestedLocationAsync,
} from './commands';
import { useCalendars } from '@/hooks/useCalendars';
import { useAvailability } from '@/hooks/useAvailability';
import { useEasySession } from '@/hooks/useEasySession';

function renderCategory({ category }: { category: string }) {
  let icon;
  switch (category) {
    case 'PRIORITY':
      icon = <MdOutlineSignalCellularAlt color="black" />;
      break;
    case 'DURATION':
    case 'DETAILS':
    case 'DATE':
      icon = <ClockIcon color="black" />;
      break;
    case 'CONTACTS':
      icon = <ContactsIcon color="black" />;
      break;
    case 'LOCATIONS':
      icon = <MdLocationOn color="black" />;
      break;
  }

  return (
    <div key={category} className="flex items-center gap-2 px-3 py-2.5 text-sm">
      {icon} {capitalize(category)}
    </div>
  );
}

function renderSuggestion({ suggestion }: { suggestion: Suggestion }) {
  return (
    <div key={suggestion.id} className="p-1 px-10 text-xs leading-snug">
      {suggestion.label}
    </div>
  );
}

function addNodeView() {
  return ReactNodeViewRenderer(Decorators);
}

export default function useAtSuggestions() {
  const { isAuthenticated } = useEasySession();
  const [selectedSubmenu, setSelectedSubmenu] = useState<Submenu | null>(null);

  const { defaultTimeZone: timeZone } = useSettings();
  const { calendars } = useCalendars();
  const { availability } = useAvailability();

  const getSuggestedLocation = useSuggestedLocationAsync();
  const selectedSubmenuRef = useRef(selectedSubmenu);

  useEffect(() => {
    selectedSubmenuRef.current = selectedSubmenu;
  }, [selectedSubmenu]);

  return useMemo(
    () =>
      createSuggestions({
        char: '@',
        items: async ({ query, editor }): Promise<Suggestion[]> => {
          const suggestions: Suggestion[] = [];
          const selectedSubmenu = selectedSubmenuRef.current;
          const itemType = getItemTypeFromEditor(editor) || 'TASK';

          const parsedDates = getDatesSuggestions({ query, itemType, timeZone, submenu: selectedSubmenu });

          if (selectedSubmenu) {
            const submenuSuggestions = await getSubmenuSuggestions({
              submenu: selectedSubmenu,
              query,
              calendars,
              parsedDates,
              timeZone,
            });

            return submenuSuggestions;
          }

          if (query.length >= 3) {
            suggestions.push(...getDurationSuggestions(query));

            switch (itemType) {
              case 'NOTE':
                suggestions.push(...filterBySearch(query, PRIORITY_SUGGESTIONS));
                break;
              case 'TASK':
                suggestions.push(
                  ...filterBySearch(query, PRIORITY_SUGGESTIONS),
                  ...filterBySearch(query, TIME_BLOCKING_SUGGESTIONS),
                  ...getRecurrenceSuggestions(query),
                  ...getAutoScheduleSuggestions(query)
                );
                break;
              case 'EVENT':
                suggestions.push(
                  ...filterBySearch(query, VISIBILITY_TYPE_SUGGESTIONS),
                  ...filterBySearch(query, CONFERENCE_SUGGESTIONS),
                  ...getRecurrenceSuggestions(query),
                  ...getAutoScheduleSuggestions(query)
                );
                break;
            }
          }

          suggestions.push(...parsedDates);

          if (itemType in ITEM_TYPE_SUGGESTIONS) {
            suggestions.push(
              ...filterBySearch(query, ITEM_TYPE_SUGGESTIONS[itemType as keyof typeof ITEM_TYPE_SUGGESTIONS])
            );
          }

          const MAX_PER_CATEGORY = 3;

          if (isAuthenticated) {
            const [freeSlotsSuggestions, contactsSuggestions, locationSuggestions] = await Promise.all([
              getSuggestedFreeSlots({ query, submenu: selectedSubmenu, timeZone }),
              getSuggestedContacts({ query, itemType }),
              itemType !== 'SCHEDULING_LINK' ? getSuggestedLocation(query) : Promise.resolve([]),
            ]);
            
            const slicedFreeSlotsSuggestions = freeSlotsSuggestions.slice(0, MAX_PER_CATEGORY);
            const slicedContactsSuggestions = contactsSuggestions.slice(0, MAX_PER_CATEGORY);
            const slicedLocationSuggestions = locationSuggestions.slice(0, MAX_PER_CATEGORY);

            suggestions.push(...slicedLocationSuggestions);
            const customSchedules = getSuggestedCustomSchedules({ query, itemType, availability });
            suggestions.push(...customSchedules.slice(0, MAX_PER_CATEGORY));
            suggestions.push(...slicedFreeSlotsSuggestions);
            suggestions.push(...slicedContactsSuggestions);
          }

          return suggestions;
        },
        addNodeView,
        renderCategory,
        renderSuggestion,
        command: ({ editor, range, props }) => {
          const { type, value } = extractSuggestionTypeAndValue(props.id);

          if (type === SuggestionType.SUBMENU) {
            setSelectedSubmenu(value as Submenu);
            editor.commands.deleteRange(range);
            editor.commands.insertContentAt(range.from, '@');
            return;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: 'SUGGESTION_@', attrs: { id: props.id, label: props.label } },
              { type: 'text', text: ' ' },
            ])
            .run();
        },
        onExit: () => {
          setTimeout(() => {
            if (document.getElementById('mention-list')) return;
            setSelectedSubmenu(null);
          }, 100);
        },
      }),
    [timeZone, isAuthenticated, calendars, getSuggestedLocation, availability]
  );
}
