import { useCalendars, useUpdateCalendarEnabled } from '@/hooks/useCalendars';
import { CollapsibleSection } from './CollapsibleSection';
import { ScrollAreaComponent } from './ScrollArea';
import CalendarItem from './CalendarItem';
import { MouseEvent } from 'react';

export default function MyCalendars() {
  const { calendars } = useCalendars();
  const { updateCalendarEnabled } = useUpdateCalendarEnabled();

  if (!calendars?.length) {
    return <p className="mb-5">No Calendar data available.</p>;
  }

  const sortedCalendars = calendars.slice().sort((a, b) => {
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;
    return a.summary.localeCompare(b.summary);
  });

  const handleSelectAll = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    updateCalendarEnabled('all', true);
  };

  const handleSelectNoneButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    updateCalendarEnabled('all', false);
  };

  return (
    <CollapsibleSection
      header={
        <div className="flex items-center justify-between w-full">
          <div className="text-xs">My Calendars</div>
          <div className="flex flex-row gap-[10px]">
            <button
              className="text-sm font-medium tracking-tight cursor-pointer font-inter text-secondary-300"
              type="button"
              onClick={handleSelectAll}>
              Select all
            </button>
            <button
              type="button"
              className="text-sm font-medium tracking-tight cursor-pointer font-inter text-secondary-300"
              onClick={handleSelectNoneButton}>
              Select none
            </button>
          </div>
        </div>
      }
      headerClassName="w-full"
      className="my-[10px]"
      content={
        <ScrollAreaComponent className="max-h-[320px] overflow-y-auto min-h-0">
          {sortedCalendars.map((calendar) => (
            <CalendarItem key={calendar.id} id={calendar.id} className="border-0" />
          ))}
        </ScrollAreaComponent>
      }
    />
  );
}
