export const Bullet = ({
  className,
  size = 10,
  color = 'black',
}: {
  className?: string;
  size?: number;
  color?: string;
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill={color} />
  </svg>
);
