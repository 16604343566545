import { useMemo } from 'react';
import { Avatar } from '@/components/Avatar';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ParagraphText, SubHeaderText } from '@/components/Typography';
import { useContacts } from '@/hooks/useContacts';
import { Contact } from '@/models/ContactModel';

export const AllContacts = ({ searchTerm }: { searchTerm?: string }) => {
  const { contacts } = useContacts({});
  const { openLeftSidebar } = useLeftSidebar();

  const handleOpenLeftSidebarContact = (contact: Contact) => () => {
    openLeftSidebar({
      type: 'CONTACT',
      context: {
        contactId: contact.id,
        contactName: contact.name || contact.email,
        contactPicture: contact.picture,
      },
    });
  };

  const sortedContacts = useMemo(() => {
    if (!contacts) return [];
    const _searchTerm = searchTerm?.toLowerCase();

    const filteredList = searchTerm
      ? [...contacts].filter(
          (contact) =>
            _searchTerm &&
            (contact.name?.toLowerCase().includes(_searchTerm) || contact.email.toLowerCase().includes(_searchTerm))
        )
      : [...contacts];

    return filteredList.sort((a, b) => {
      const nameA = a.name || a.firstName || a.email || '';
      const nameB = b.name || b.firstName || b.email || '';
      return nameA.localeCompare(nameB);
    });
  }, [contacts, searchTerm]);

  if (!contacts) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 min-h-0 px-[16px] pb-[24px] gap-[16px] overflow-y-auto">
      <div className="mx-[10px]">
        {sortedContacts?.map((contact) => (
          <div
            key={contact.id}
            className="flex justify-between items-center hover:cursor-pointer p-[8px] my-[6px] hover:bg-gray-200 rounded overflow-hidden"
            onClick={handleOpenLeftSidebarContact(contact)}>
            <div className="flex items-center h-[27px] truncate w-full">
              <div className="mr-[10px] flex-shrink-0">
                <Avatar picture={contact.picture} name={contact.name} className="object-cover rounded-full" size={22} />
              </div>
              <div className="flex gap-2 whitespace-nowrap truncate max-w-[320px]">
                {contact.name ? (
                  <SubHeaderText className="capitalize font-semibold">{contact.name}</SubHeaderText>
                ) : null}
                <ParagraphText className="text-[#5b5650] truncate">{contact.email}</ParagraphText>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
