import DraggableIcon from '@/icons/Draggable';
import { cn } from 'ui/cn';
import { createDraggableId, DraggableType, DroppableType } from '@/utils/dnd';
import {
  mergeAttributes,
  Node,
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ArrowIcon } from '@/icons/Arrow';

export default Node.create({
  name: 'collapsible',
  group: 'block',
  content: 'block*',
  parseHTML() {
    return [
      {
        tag: 'collapsible',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['collapsible', mergeAttributes(HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attrs) => ({ 'data-id': attrs.id }),
      },
      index: {
        default: -1,
        parseHTML: (element) => element.getAttribute('data-index'),
        renderHTML: (attrs) => ({ 'data-index': attrs.index }),
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(Collapsible);
  },
});

function Collapsible({ node }: NodeViewProps) {
  const [open, setOpen] = useState(true);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <Draggable
      index={node.attrs.index}
      draggableId={createDraggableId(DroppableType.LIST, DraggableType.TASK, node.attrs.id)}
      shouldRespectForcePress
      key={node.attrs.id}>
      {(draggableProvided, snapshot) => {
        return (
          <NodeViewWrapper
            as="div"
            className={cn(
              'group/collapsible flex bg-white rounded hover:bg-primary-100 transition-colors',
              snapshot?.isDragging && 'shadow-2xl',
              !open && '[&_.collapsible-details]:hidden'
            )}
            ref={draggableProvided?.innerRef}
            {...draggableProvided?.draggableProps}
            contentEditable={true}>
            <div className="flex items-start select-none">
              <div {...draggableProvided.dragHandleProps} className="h-6 m-1">
                <DraggableIcon className="h-6 w-4 group-hover/collapsible:opacity-100 opacity-0 transition-opacity cursor-grab" />
              </div>
              <button onClick={toggleOpen} className="h-6 w-4 px-1 m-1 !outline-none">
                <ArrowIcon className={cn('transition-transform', open && 'rotate-90')} />
              </button>
            </div>
            <NodeViewContent as="div" className="w-full" />
          </NodeViewWrapper>
        );
      }}
    </Draggable>
  );
}
