type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const CommandBarIcon = ({ className, size = 18, color = '#A1A1A3' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.1606 19.4185C1.78388 19.4185 2.16 19.1069 2.35343 18.4728L7.27522 1.53672C7.31821 1.37552 7.32895 1.24657 7.32895 1.09612C7.32895 0.440597 6.86687 0 6.1791 0C5.53433 0 5.16895 0.322388 4.96478 1.01015L0.0644776 17.8818C0.0322388 18 0 18.1827 0 18.3654C0 18.9779 0.440597 19.4185 1.1606 19.4185Z"
      fill={color}
    />
    <path
      d="M13.1427 15.9475C16.0442 15.9475 18 14.5182 18 12.3367C18 10.6496 17.0436 9.77911 14.6364 9.23105L12.8203 8.83343C11.6382 8.56478 11.1439 8.11343 11.1439 7.44716C11.1439 6.63045 11.9499 6.0609 13.1212 6.0609C14.2496 6.0609 14.9051 6.55522 15.4316 7.3397C15.6788 7.69433 16.0657 7.83403 16.4525 7.83403C17.1296 7.83403 17.4842 7.43642 17.4842 6.87761C17.4842 6.74866 17.4627 6.6197 17.4304 6.48C17.0758 5.16896 15.3779 4.09433 13.1857 4.09433C10.3701 4.09433 8.54328 5.4806 8.54328 7.59761C8.54328 9.25254 9.6394 10.3487 11.8639 10.8537L13.7122 11.2728C14.8836 11.5415 15.3134 11.9176 15.3134 12.6054C15.3134 13.4651 14.486 13.9916 13.2394 13.9916C11.9606 13.9916 11.2728 13.6048 10.671 12.7343C10.3272 12.283 9.95104 12.0788 9.51045 12.0788C8.86567 12.0788 8.39284 12.4872 8.39284 13.1319C8.39284 13.2931 8.42507 13.4651 8.5003 13.6585C9.04836 15.0233 10.7355 15.9475 13.1427 15.9475Z"
      fill={color}
    />
  </svg>
);
