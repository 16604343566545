import ToolTip from '@/components/Tooltip';
import { MdArrowBack, MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { AddNewIcon } from '@/icons/AddNewIcon';
import useLeftSidebar from '../useLeftSidebar';
import { useEmailThreads } from '@/hooks/useEmails';
import { VscDebugRestart, VscSearch } from 'react-icons/vsc';
import { useState } from 'react';
import { SearchEmails } from './SearchEmail';

type EmailHeaderProps = {
  onClose: () => void;
  isCompose?: boolean;
  openMessageSidebar?: boolean;
  hasPermission?: boolean;
  onComposeClick?: () => void;
  labelId?: string;
};

export const EmailSidebarHeader = ({
  onClose,
  onComposeClick,
  isCompose,
  hasPermission,
  labelId,
}: EmailHeaderProps) => {
  const { refetch } = useEmailThreads({ labelId });
  const [openSearch, setOpenSearch] = useState(false);

  const { openLeftSidebar } = useLeftSidebar();
  const handleBack = () => {
    openLeftSidebar({
      type: 'ALL_EMAILS',
      context: {
        labelId,
      },
    });
  };

  const handleSearch = () => {
    setOpenSearch((prev) => !prev);
  };

  return (
    <div className="flex flex-col pt-4 pl-2 bg-white xs:w-[90%] sm:w-full md:w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {isCompose ? (
            <ToolTip tooltip="Back">
              <button className="text-gray-700 hover:text-gray-900" onClick={handleBack}>
                <MdArrowBack size={24} />
              </button>
            </ToolTip>
          ) : null}

          {hasPermission ? (
            <>
              <ToolTip tooltip="Compose">
                <button
                  onClick={onComposeClick}
                  className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-gray-muted rounded-[14px]">
                  <AddNewIcon className="text-gray-600" />
                </button>
              </ToolTip>
              <div className="ml-2 flex items-center h-[44px] bg-gray-muted rounded-[14px]">
                <ToolTip tooltip="Search">
                  <button onClick={handleSearch} className="flex items-center justify-center cursor-pointer w-[52px]">
                    <VscSearch className="text-gray-400" />
                  </button>
                </ToolTip>
                <ToolTip tooltip="Reload">
                  <button
                    onClick={() => refetch()}
                    className="flex items-center justify-center cursor-pointer w-[52px]">
                    <VscDebugRestart className="text-gray-400" />
                  </button>
                </ToolTip>
              </div>
            </>
          ) : null}
        </div>
        <div className="flex items-center">
          <ToolTip tooltip="Close Sidebar">
            <button onClick={onClose} className="p-2 transition rounded-full hover:bg-gray-200">
              <MdKeyboardDoubleArrowLeft size={30} className="text-gray-600" />
              <KeyboardShortcutIndicator shortcut="Esc" />
            </button>
          </ToolTip>
        </div>
      </div>
      <div className="py-2">
        {openSearch ? (
          <div className="flex items-center gap-4">
            <SearchEmails labelId={labelId ?? ''} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
