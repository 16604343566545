type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const NoteIcon = ({ className, size = 16, color = '#000000' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8175 0.509766H2.04823C1.6402 0.509766 1.24889 0.671853 0.960371 0.960371C0.671853 1.24889 0.509766 1.6402 0.509766 2.04823V12.8175C0.509766 13.2255 0.671853 13.6168 0.960371 13.9053C1.24889 14.1938 1.6402 14.3559 2.04823 14.3559H8.20207L14.3559 8.20207V2.04823C14.3559 1.6402 14.1938 1.24889 13.9053 0.960371C13.6168 0.671853 13.2255 0.509766 12.8175 0.509766ZM7.43284 12.8175V7.43284H12.8175L7.43284 12.8175Z"
      fill={color}
    />
  </svg>
);
