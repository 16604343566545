import { GuestsSelector } from '@/components/GuestsSelector';
import { AddProject } from '../../Projects/AddProject';
import { PlacesAutocomplete } from '@/components/PlacesAutocomplete';
import { Attendee, NameEmail } from '@/utils/contacts';
import { Priority } from '@prisma/client';
import { Select } from '@/components/Select';
import { capitalize } from 'utils/string';
import ToggleableSection from './ToggleableSection';
import { MdPeopleAlt, MdLocationOn } from 'react-icons/md';
import { ConferencingSelector } from '@/components/ConferencingSelector';
import { ConferencingData } from '@/models/EventArg';
import { JsonValue } from '@prisma/client/runtime/library';

type Props = {
  disabled?: boolean;
  location?: string | null;
  contacts?: Attendee[];
  projectIds?: string[];
  conferenceData?: JsonValue;
  priority?: Priority;
  hideLocation?: boolean;
  hideContacts?: boolean;
  when?: string;
  hasAHold?: boolean;
  holdType?: string;
  holdDuration?: number;
  holdDurationUnit?: string;
  onChange: (value: {
    location?: string;
    contacts?: NameEmail[];
    projectIds?: string[];
    priority?: Priority;
    conferenceData?: ConferencingData;
    hasAHold?: boolean;
    holdType?: string;
    holdDuration?: number;
    holdDurationUnit?: string;
  }) => void;
};

const PRIORITIES = Object.values(Priority);

function priorityLabelExtractor(when: Priority) {
  switch (when) {
    case 'NO_PRIORITY':
      return 'None';
    default:
      return capitalize(when);
  }
}

export default function Tags({
  disabled,
  location,
  contacts,
  projectIds,
  priority,
  conferenceData,
  onChange,
  hideLocation,
  hideContacts,
}: Props) {

  const handleLocationChange = (location: string) => {
    onChange({ location });
  };

  const handleContactsChange = (contacts: Attendee[]) => {
    onChange({ contacts });
  };

  const handleProjectChange = (projectIds: string[]) => {
    onChange({ projectIds });
  };

  const handlePriorityChange = (priority: Priority) => {
    onChange({ priority });
  };
  const handleConferencingChange = (conferencing: ConferencingData | null) => {
    onChange({ conferenceData: conferencing || undefined });
  };

  return (
    <section className="flex flex-col gap-3 py-5 border-t boreder-gray-border">
      
        {!hideLocation && (
          <ToggleableSection title="Location & Conferencing" summary={location || 'Choose a location'} icon={MdLocationOn}>
            <PlacesAutocomplete
              mode="full-address"
              labelInput="Location"
              className="pb-[2px]"
              disabled={disabled}
              name="location"
              value={location || ''}
              onChange={handleLocationChange}
              placeholder="Choose a location"
            />
            <ConferencingSelector
              className="mt-3"
              initialConferenceData={conferenceData as ConferencingData}
              onConferenceDataChange={handleConferencingChange}
            />
          </ToggleableSection>
        )}
      {!hideContacts && (
        <ToggleableSection
          title="Attendees"
          summary={
            contacts?.length ? contacts.map((contact) => contact.email).join(', ') : 'Choose any additional attendees'
          }
          icon={MdPeopleAlt}>
            <GuestsSelector
              disabled={disabled}
              selectedContacts={contacts}
              onChange={handleContactsChange}
              guestsCanSeeOtherGuests={true}
            />
        </ToggleableSection>
      )}
      <ToggleableSection
        title="Projects"
        summary={
          projectIds?.length
            ? `${projectIds.length} ${projectIds.length === 1 ? 'project' : 'projects'} selected`
            : 'Choose associated projects'
        }
        icon={MdPeopleAlt}>
        <>
          <AddProject disabled={disabled} projectIds={projectIds} onChange={handleProjectChange} />
        </>
      </ToggleableSection>
      {!!priority && (
        <Select
          options={PRIORITIES}
          label="Priority"
          value={priority}
          extractLabel={priorityLabelExtractor}
          extractValue={(option) => option}
          disabled={disabled}
          onChange={handlePriorityChange}
          placeholder="Select a priority"
        />
      )}
    </section>
  );
}
