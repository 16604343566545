type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const SchedulingClockIcon = ({ className, size = 20, color = '#000000' }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none">
    <circle
      cx="10"
      cy="10.1094"
      r="7.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4167 7.19141V10.1796C10.4167 10.4006 10.3289 10.6125 10.1726 10.7688L8.75 12.1914"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
