type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const RoundedCalendarIcon = ({ className, size = 18, color = '#279AF1' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_741_45137)">
      <path
        d="M1.5 4.88932C1.5 3.96885 2.24619 3.22266 3.16667 3.22266H14.8333C15.7538 3.22266 16.5 3.96885 16.5 4.88932V14.056C16.5 14.9765 15.7538 15.7227 14.8333 15.7227H3.16667C2.24619 15.7227 1.5 14.9765 1.5 14.056V4.88932Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83301 3.22135V1.55469M13.1663 3.22135V1.55469"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
      <path d="M1.91699 6.55469H15.667" stroke={color} strokeWidth="1.66667" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_741_45137">
        <rect width="18" height="17" fill="white" transform="translate(0 0.0546875)" />
      </clipPath>
    </defs>
  </svg>
);
