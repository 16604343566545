import { JSONContent } from '@tiptap/react';
import { useMemo } from 'react';
import { Deadline, Priority, TaskScheduleType } from '@prisma/client';
import moment from 'moment-timezone';
import cuid from 'cuid';

import { capitalize } from 'utils/string';
import { formatDateDecorator, formatDateRangeDecorator } from '@/utils/date';
import { isTaskCompleted } from '@/utils/tasks';
import { buildSuggestionId } from '../utils/suggestions';
import { NEW_ITEM_PREFIX } from '../utils/item';
import { SuggestionType } from '../nodes/suggestions/types';
import { ItemType } from '../types';

import { useProjects } from '@/hooks/useProjects';
import { useSettings } from '@/hooks/useSettings';
import { useAccounts } from '@/hooks/useAccounts';
import { useCalendars } from '@/hooks/useCalendars';
import useFindRef from '@/hooks/useFindRef';

import type { SmartyItemType } from '@/hooks/useSmartyItems';
import type { Task } from '@/models/TaskModel';

function createCollapsible(summary: JSONContent, details: JSONContent[], index: number): JSONContent {
  return {
    type: 'collapsible',
    content: [
      { type: 'summary', content: [summary] },
      { type: 'details', content: details },
    ],
    attrs: {
      id: summary.attrs!.id,
      index,
    },
  };
}

export default function useEditorParser(items: SmartyItemType[], defaultItemType: ItemType | null): JSONContent[] {
  const { defaultTimeZone: timeZone } = useSettings();
  const { accounts } = useAccounts();
  const { calendars } = useCalendars();
  const { projects } = useProjects({});
  const findRef = useFindRef();

  return useMemo(() => {
    if (!items.length) {
      return [
        {
          type: 'item',
          attrs: {
            id: `${NEW_ITEM_PREFIX}_${cuid()}`,
            type: defaultItemType || '',
          },
        },
      ];
    }

    function parseItem(item: SmartyItemType, index = -1): JSONContent {
      const suggestions: JSONContent[] = [];

      function pushSuggestion(suggestion: { id: string; label: string }, type: '@' | '#' = '@') {
        return suggestions.push({ type: 'text', text: ' ' }, { type: `SUGGESTION_${type}`, attrs: suggestion });
      }

      const isBeforeToday = 'end' in item && item.end && moment(item.end).isBefore(moment(), 'day');
      const moreThanOneDay = 'end' in item && item.end && moment(item.end).diff(moment(item.start), 'hours') > 24;

      if (item.start && (item.itemType !== 'TASK' || !item.allDay || isBeforeToday || moreThanOneDay)) {
        // eslint-disable-next-line prefer-const
        let { start, end = null, allDay = false } = item as Task;

        if (typeof start === 'string') {
          start = new Date(start);
        }
        if (typeof end === 'string') {
          end = new Date(end);
        }

        if (start?.toString() === 'Invalid Date') {
          start = null;
        }
        if (end?.toString() === 'Invalid Date') {
          end = null;
        }

        pushSuggestion({
          id: `${SuggestionType.DATE}::${start?.toISOString()}%%%${end?.toISOString()}%%%${allDay}`,
          label: end
            ? formatDateRangeDecorator({ start, end, allDay, timeZone })
            : formatDateDecorator({ date: start, allDay, timeZone }),
        });
      }

      if (('contacts' in item && item.contacts?.length) || ('attendees' in item && item.attendees?.length)) {
        const contacts = 'contacts' in item ? item.contacts! : item.attendees!;
        contacts.forEach((contact) =>
          pushSuggestion({ id: `${SuggestionType.CONTACT}::${contact.email}`, label: contact.name || contact.email })
        );
      }

      if ('projectIds' in item && item.projectIds?.length) {
        item.projectIds.forEach((projectId) => {
          const project = projects?.find(({ id }) => id === projectId);
          if (!project) return;
          pushSuggestion({ id: `${SuggestionType.PROJECT}::${projectId}`, label: project.name }, '#');
        });
      }

      if ('location' in item && item.location) {
        pushSuggestion({ id: `${SuggestionType.LOCATIONS}::${item.location}`, label: item.location });
      }

      if ('taskIds' in item && item.taskIds?.length) {
        item.taskIds.forEach((taskId) => {
          const task = findRef(taskId, 'TASK');
          pushSuggestion({ id: buildSuggestionId(SuggestionType.TASK, taskId), label: task.summary }, '#');
        });
      }
      if ('eventIds' in item && item.eventIds?.length) {
        item.eventIds.forEach((eventId) => {
          const event = findRef(eventId, 'EVENT');
          pushSuggestion({ id: buildSuggestionId(SuggestionType.EVENT, eventId), label: event.summary }, '#');
        });
      }
      if ('noteIds' in item && item.noteIds?.length) {
        item.noteIds.forEach((noteId) => {
          const note = findRef(noteId, 'NOTE');
          pushSuggestion({ id: buildSuggestionId(SuggestionType.NOTE, noteId), label: note.title }, '#');
        });
      }

      let extraAttrs = {};

      switch (item.itemType) {
        case 'TASK': {
          if (item.taskScheduleType === TaskScheduleType.AUTO_SCHEDULED && item.duration) {
            pushSuggestion({ id: `${SuggestionType.DURATION}::${item.duration}`, label: `${item.duration} minutes` });
          }

          if (item.deadlineType === Deadline.HARD_DEADLINE && item.dueDate) {
            pushSuggestion({
              id: `${SuggestionType.DEADLINE}::${item.dueDate.toISOString()}`,
              label: item.dueDate.toISOString(),
            });
          }

          const isChecked = isTaskCompleted(item);

          extraAttrs = {
            checked: isChecked,
            parentId: item.parentTaskId,
            scheduleType: item.taskScheduleType,
          };
        }
        // eslint-disable-next-line no-fallthrough
        case 'NOTE': {
          if (item.priority && item.priority !== Priority.NO_PRIORITY) {
            pushSuggestion({ id: `${SuggestionType.PRIORITY}::${item.priority}`, label: capitalize(item.priority) });
          }
          break;
        }
        case 'EVENT': {
          const calendar = calendars?.find(({ id }) => id === item.calendarId);
          extraAttrs = {
            color: item.backgroundColor,
            editable:
              calendar?.accessRole !== 'reader' &&
              (accounts?.some(({ email }) => (item.creator as any)?.email === email) || !!item.guestsCanModify),
          };
          break;
        }
      }

      let title = 'summary' in item ? item.summary : item.title;
      if (!title) {
        title = `Untitled ${item.itemType.toLowerCase().replace('_', ' ')}`;
      }

      return {
        type: 'item',
        content: [{ type: 'text', text: title }, ...suggestions],
        attrs: {
          id: item.id,
          type: item.itemType,
          index,
          ...extraAttrs,
        },
      };
    }

    let index = 0;
    return items.reduce<JSONContent[]>((acc, item) => {
      if (item.itemType === 'TASK' && item.subtasks?.length) {
        const parentTask = parseItem(item);
        const subtasks = item.subtasks.map((subtask) => parseItem(subtask as unknown as Task));

        acc.push(createCollapsible(parentTask, subtasks, index++));
        return acc;
      }

      acc.push(parseItem(item, index++));

      return acc;
    }, []);
  }, [accounts, calendars, defaultItemType, findRef, items, projects, timeZone]);
}
