import { useAvailability } from '@/hooks/useAvailability';
import { useCalendars } from '@/hooks/useCalendars';
import { useSettings } from '@/hooks/useSettings';
import { useCallback } from 'react';
import { ItemType, RichTextItem } from '../types';
import { TaskArg } from '@/models/TaskArg';
import { isNewItem } from '../utils/item';
import { Priority, TaskScheduleType } from '@prisma/client';
import { calculateEventColor } from '@/models/GoogleColorsModel';
import { roundToNextBlock } from '@/utils/date';
import moment from 'moment-timezone';
import { EventArg } from '@/models/EventArg';
import { NoteArg } from '@/models/NoteArg';
import { generateSlots } from '@/utils/scheduling';
import cuid from 'cuid';
import { SchedulingLinkArg } from '@/models/SchedulingLinkArg';
import { useProjects } from '@/hooks/useProjects';
import { generateRandomColor } from '@/utils/generateRandomColor';

export default function useRichTextItemToItemArg() {
  const { defaultTimeZone: timeZone } = useSettings();
  const { defaultDuration } = useSettings();
  const { createProject } = useProjects({});
  const { defaultAvailability } = useAvailability();
  const { calendars, defaultCalendar } = useCalendars();

  const richTextItemToItemArg = useCallback(
    async (item: RichTextItem) => {
      switch (item.itemType) {
        case ItemType.TASK: {
          const { id, parentTaskId: parentId, itemType, subtasks: _subtasks, projectIds, ...taskArgs } = item;
          let projectsIdsTasks: string[] = [];

          if (projectIds.length > 0) {
            const newTitles = item.projectIds.map(async (project) => {
              if (!project.includes('create_new_project_')) return project;
              const title = project.replace('create_new_project_', '');
              const newProject = await createProject({
                name: title,
                eventIds: [],
                taskIds: [],
                color: generateRandomColor(),
                parentProjectId: '',
                schedulingLinksIds: [],
                tasksIds: [],
              });
              return newProject.id;
            });
            const result = await Promise.all(newTitles);
            projectsIdsTasks = result.map((project) => project);
          }

          const subtasks = (await Promise.all(
            _subtasks?.map(async (subtask) => {
              const parsedSubtask = (await richTextItemToItemArg(subtask)) as TaskArg;
              delete parsedSubtask.subtasks;
              return parsedSubtask;
            })
          )) as TaskArg[];

          const parentTaskId = !parentId || isNewItem(parentId) ? undefined : parentId;

          return {
            isCompleted: false,
            priority: Priority.NO_PRIORITY,
            taskScheduleType: TaskScheduleType.NONE,
            parentTaskId,
            subtasks,
            timeBlockingType: 'BUSY',
            projectIds: projectsIdsTasks,
            ...taskArgs,
          } satisfies TaskArg;
        }
        case ItemType.EVENT: {
          const { itemType, contacts, taskIds, noteIds, eventIds, calendarId, projectIds, ...eventArgs } = item;

          const calendar = calendars?.find(({ id }) => id === calendarId) || defaultCalendar;

          const { background, foreground } =
            calculateEventColor(
              calendar?.colorId || undefined,
              calendar?.backgroundColor || undefined,
              calendar?.foregroundColor || undefined,
              undefined
            ) || {};

          const start = eventArgs.start || roundToNextBlock(new Date(), 30)!;
          const end = eventArgs.end || moment(start).add(defaultDuration, 'minutes').toDate();

          let projectIdsEvents: string[] = [];

          if (projectIds.length > 0) {
            const newTitles = item.projectIds.map(async (project) => {
              if (!project.includes('create_new_project_')) return project;
              const title = project.replace('create_new_project_', '');
              const newProject = await createProject({
                name: title,
                eventIds: [],
                taskIds: [],
                color: generateRandomColor(),
                parentProjectId: '',
                schedulingLinksIds: [],
                tasksIds: [],
              });
              return newProject.id;
            });
            const result = await Promise.all(newTitles);
            projectIdsEvents = result.map((project) => project);
          }
          return {
            ...eventArgs,
            startTimeZone: timeZone ? timeZone : calendar?.timeZone ?? '',
            endTimeZone: timeZone ? timeZone : calendar?.timeZone ?? '',
            start,
            end,
            allDay: eventArgs.allDay || false,
            attendees: contacts,
            calendarId: calendar?.id || '',
            reminders: [...(calendar?.defaultReminders ?? [])],
            backgroundColor: background,
            foregroundColor: foreground,
            status: 'tentative',
            taskIds,
            noteIds,
            eventIds,
            projectIds: projectIdsEvents,
          } satisfies EventArg;
        }
        case ItemType.NOTE: {
          let projectsIdsNotes: string[] = [];

          if (item.projectIds.length > 0) {
            const newTitles = item.projectIds.map(async (project) => {
              if (!project.includes('create_new_project_')) return project;
              const title = project.replace('create_new_project_', '');
              const newProject = await createProject({
                name: title,
                eventIds: [],
                taskIds: [],
                color: generateRandomColor(),
                parentProjectId: '',
                schedulingLinksIds: [],
                tasksIds: [],
              });
              return newProject.id;
            });
            const result = await Promise.all(newTitles);
            projectsIdsNotes = result.map((project) => project);
          }
          return {
            title: item.summary,
            content: '',
            start: item.start || moment.tz(timeZone).startOf('day').toDate(),
            priority: item.priority || Priority.NO_PRIORITY,
            projectIds: projectsIdsNotes,
            eventIds: item.eventIds,
            taskIds: item.taskIds,
            emailIds: item.emailIds,
            noteIds: item.noteIds,
          } satisfies NoteArg;
        }
        case ItemType.SCHEDULING_LINK: {
          if (!defaultDuration || !defaultAvailability) return undefined;
          const duration = item.duration || defaultDuration;
          let start = item?.start;
          let end = item?.end;

          if (!start || start.getTime() < Date.now()) {
            start = roundToNextBlock(new Date(), 30)!;
          }

          if (!end || end.getTime() < start.getTime()) {
            end = moment.tz(start, timeZone).add(1, 'week').startOf('day').toDate();
          }

          const slots = await generateSlots({
            start,
            end,
            duration,
            scheduleIntervals: defaultAvailability.intervals || [],
            timeZone,
            checkCalendarIds: calendars?.map((calendar) => calendar.id) || [],
            contactEmails: item.contacts.map(({ email }) => email),
          });
          let projectIdsLinks: string[] = [];

          if (item.projectIds.length > 0) {
            const newTitles = item.projectIds.map(async (project) => {
              if (!project.includes('create_new_project_')) return project;
              const title = project.replace('create_new_project_', '');
              const newProject = await createProject({
                name: title,
                eventIds: [],
                taskIds: [],
                color: generateRandomColor(),
                schedulingLinksIds: [],
                tasksIds: [],
                parentProjectId: '',
              });
              return newProject.id;
            });
            const result = await Promise.all(newTitles);
            projectIdsLinks = result.map((project) => project);
          }

          return {
            title: item.summary,
            code: cuid(),
            start,
            end,
            duration,
            contacts: item.contacts,
            projectIds: projectIdsLinks,
            useCustomSchedule: true,
            scheduleId: defaultAvailability.id,
            when: item?.start ? 'CUSTOM_DATES' : 'THIS_WEEK',
            slots: slots || [],
            recurringSlots: [],
            calendarId: defaultCalendar?.id,
            location: item.location,
            emailIds: item.emailIds,
          } satisfies SchedulingLinkArg;
        }
      }
    },
    [calendars, createProject, defaultAvailability, defaultCalendar, defaultDuration, timeZone]
  );

  return richTextItemToItemArg;
}
