type Props = {
  className?: string;
  size?: number;
};

export const SubTaskIcon = ({ className = '', size = 14 }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75033 5.47917V1.6875H1.16699V9.27083H4.95866"
      stroke="#111317"
      strokeWidth="1.08333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.62565 9.27018H4.95898M4.95898 5.47852V13.0618M12.5423 13.0618V5.47852H4.95898V13.0618H12.5423ZM9.62565 9.27018L8.16732 7.81185L9.62565 9.27018ZM9.62565 9.27018L8.16732 10.7285L9.62565 9.27018Z"
      stroke="#111317"
      strokeWidth="1.08333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
