import { Reference as RefType } from '../types';
import { iconByType } from '../utils';

type Props = {
  label: string;
  type: RefType;
};

export default function Reference({ label, type }: Props) {
  const Icon = iconByType[type];

  return (
    <div className="inline-flex items-center gap-2 px-1 text-gray-500 align-top rounded bg-select-light-gray whitespace-nowrap hover:cursor-pointer">
      <Icon size={15} color="#A1A1A3" className="shrink-0" />
      <span className="text-[14px] overflow-hidden">{label}</span>
    </div>
  );
}
