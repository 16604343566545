import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { SettingsIcon } from '@/icons/SettingsIcon';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';

export const SettingsHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex flex-col gap-6 px-4 py-6 h-[140px] ">
      <div className="flex items-center justify-end">
        <ToolTip tooltip="Close Sidebar">
          <button type="button" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center">
        <SettingsIcon className="mr-[8px]" color="#000000" size={24} />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">Settings</h1>
      </div>
    </div>
  );
};
