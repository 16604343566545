import Button from '@/components/Button';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { ProjectCreated } from '@/components/ToastItemCreated';
import ToolTip from '@/components/Tooltip';
import { useProjects } from '@/hooks/useProjects';
import ProjectIcon from '@/icons/ProjectIcon';
import { FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';

type Props = {
  onClose: () => void;
};

export default function NewProject({ onClose }: Props) {
  const { openLeftSidebar } = useLeftSidebar();

  const { createProject, isCreatingProject } = useProjects({
    onCreated: (newProject) => {
      toast(<ProjectCreated project={newProject} />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
    },
  });
  const [projectName, setProjectName] = useState('');

  async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await createProject({ name: projectName, eventIds: [], parentProjectId: '', schedulingLinksIds: [], tasksIds: [] });
    openLeftSidebar({
      type: 'PROJECTS',
    });
  }

  function handleOnChange(event: FormEvent<HTMLInputElement>) {
    setProjectName(event.currentTarget.value);
  }

  return (
    <section className="h-full">
      <form className="flex flex-col h-full" onSubmit={handleOnSubmit}>
        <header className="flex flex-col gap-6 px-4 py-6 h-[140px] border-b-2 border-gray-muted">
          <div className="flex items-center justify-end">
            <ToolTip tooltip="Close Sidebar">
              <button type="button" onClick={onClose}>
                <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
                <KeyboardShortcutIndicator shortcut="Esc" />
              </button>
            </ToolTip>
          </div>
          <div className="flex items-center px-4">
            <ProjectIcon className="mr-[8px]" size={14} />
            <input
              value={projectName}
              onChange={handleOnChange}
              placeholder="Project name…"
              autoFocus
              className="text-2xl font-semibold tracking-tighter font-inter !outline-none"
            />
          </div>
        </header>
        <article className="p-6 text-sm font-medium text-secondary-500 opacity-40">
          Create the title of your new project, once you save your project you can add project specific tasks, and
          events.
        </article>
        <footer className="flex flex-col p-6 shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t mt-auto">
          <Button type="submit" isLoading={isCreatingProject} variant="primary" disabled={!projectName}>
            Save
          </Button>
        </footer>
      </form>
    </section>
  );
}
