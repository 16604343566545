import { SVGProps } from "react";

type Props = {
  size?: number;
  color?: string;
} & SVGProps<SVGSVGElement>;

export default function VisiblityIcon({ size = 12, color = '#279AF1', ...svgProps }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12" fill="none" {...svgProps}>
      <path
        d="M6 7C8.7615 7 11 4 11 4C11 4 8.7615 1 6 1C3.2385 1 1 4 1 4C1 4 3.2385 7 6 7Z"
        stroke={color}
        strokeWidth="1.16667"
        strokeLinejoin="round"
      />
      <path
        d="M6 5.25C6.33152 5.25 6.64946 5.1183 6.88388 4.88388C7.1183 4.64946 7.25 4.33152 7.25 4C7.25 3.66848 7.1183 3.35054 6.88388 3.11612C6.64946 2.8817 6.33152 2.75 6 2.75C5.66848 2.75 5.35054 2.8817 5.11612 3.11612C4.8817 3.35054 4.75 3.66848 4.75 4C4.75 4.33152 4.8817 4.64946 5.11612 4.88388C5.35054 5.1183 5.66848 5.25 6 5.25Z"
        stroke={color}
        strokeWidth="1.16667"
        strokeLinejoin="round"
      />
    </svg>
  );
}
