import { Contact } from '@/models/ContactModel';
import { useSettings } from '@/hooks/useSettings';
import { MomentsListView } from './SubSections/MomentsListView';

type MomentsViewProps = {
  contact: Contact;
};

export const MomentsView = ({ contact }: MomentsViewProps) => {
  const { defaultTimeZone: timeZone } = useSettings();
  if (!contact || !timeZone) return null;

  return (
    <div className="flex flex-col flex-1 min-h-0 ">
      <MomentsListView email={contact.email} />
    </div>
  );
};
