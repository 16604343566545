import { useMemo } from 'react';
import moment from 'moment-timezone';
import { useSettings } from '@/hooks/useSettings';
import { Task } from '@/models/TaskModel';
import { Event } from '@/models/EventModel';
import { SchedulingLink } from '@/models/SchedulingLinkModel';
import { extractItemSummary } from '@/models/shared';
import { useEasySession } from '@/hooks/useEasySession';
import { useWallClock } from 'ui/WallClock';
import { Note } from '@/models/NoteModel';
import { expandRecurringTask } from '@/utils/tasks';
import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useTasks } from '@/hooks/useTasks';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import useFilter from '@/hooks/useFilter';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { filterItemsContacts } from '@/utils/filterContacts';

export type SmartyItemType = Task | Event | SchedulingLink | Note;

export type ItemsByDay = {
  date: string;
  items: SmartyItemType[];
};

export const useItemsContacts = ({ contactEmail }: { contactEmail: string }) => {
  const { isAuthenticated } = useEasySession();
  const { defaultTimeZone: timeZone } = useSettings();
  const { events } = useEvents({});
  const { notes } = useNotes();
  const { tasks } = useTasks({});
  const { schedulingLinks } = useSchedulingLinks({});
  const { filter } = useFilter();
  const { globalDateRange } = useGlobalDateRange();

  const now = useWallClock();

  const today = useMemo(() => moment.tz(now, timeZone).startOf('day'), [now, timeZone]);

  const items = useMemo(() => {
    if (!isAuthenticated) return;
    if (!timeZone || !tasks || !events || !schedulingLinks) return;

    const itemsByDay: ItemsByDay[] = [];

    tasks?.results?.forEach((task) => {
      // do not show completed tasks completed in the past
      if (task.isCompleted && moment.tz(task.completedDate, timeZone).isBefore(today)) {
        return;
      }

      if (task.parentTaskId) {
        return;
      }

      let sortDate = task.isCompleted ? task.completedDate : task.start;
      if (moment(sortDate).isBefore(today)) {
        sortDate = today.toDate();
      }

      if (task.recurrence?.[0]) {
        const tasks = expandRecurringTask(task, globalDateRange, timeZone);
        tasks?.forEach((t) => {
          if (moment(task.start).valueOf() === moment(t.start).valueOf()) t.isFirstRecurring = true;
        });

        tasks?.forEach((task) => {
          const currentDay = moment(task.start).format('YYYY-MM-DD');
          const itemsDay = itemsByDay.find((d) => d.date === currentDay);

          if (!itemsDay) {
            itemsByDay.push({ date: currentDay, items: [task] });
            return;
          }

          itemsDay.items.push(task);
        });

        return;
      }

      const date = !sortDate ? 'unscheduled' : moment.tz(sortDate, timeZone).format('YYYY-MM-DD');
      const day = itemsByDay.find((d) => d.date === date);
      if (!day) {
        itemsByDay.push({ date: date, items: [task] });
      } else {
        day.items.push(task);
      }
    });

    events?.forEach((event) => {
      const date = moment.tz(event.start, timeZone).format('YYYY-MM-DD');
      const day = itemsByDay.find((d) => d.date === date);

      if (!day) {
        itemsByDay.push({ date, items: [event] });
      } else {
        day.items.push(event);
      }
    });

    notes?.results.forEach((event) => {
      const date = moment.tz(event.start, timeZone).format('YYYY-MM-DD');
      const day = itemsByDay.find((d) => d.date === date);
      if (!day) {
        itemsByDay.push({ date, items: [event] });
      } else {
        day.items.push(event);
      }
    });

    schedulingLinks?.results?.forEach((schedulingLink) => {
      const date = moment.tz(schedulingLink.createdAt, timeZone).format('YYYY-MM-DD');
      if (moment(date).isBefore(today) || schedulingLink.default) {
        return;
      }

      const day = itemsByDay.find((d) => d.date === date);
      if (!day) {
        itemsByDay.push({ date, items: [schedulingLink] });
      } else {
        day.items.push(schedulingLink);
      }
    });

    // sort days
    itemsByDay.sort((a, b) => {
      if (a.date === 'unscheduled') return -1;
      if (b.date === 'unscheduled') return 1;
      return moment(a.date).valueOf() - moment(b.date).valueOf();
    });

    // sort items within days
    itemsByDay.forEach((day) => {
      day.items.sort((a, b) => {
        // if items are allDay, order events first
        if (['TASK', 'EVENT'].includes(a.itemType) && ['TASK', 'EVENT'].includes(b.itemType)) {
          const aa = a as Task | Event;
          const bb = b as Task | Event;
          if (aa.allDay && !bb.allDay) return -1;
          if (bb.allDay && !aa.allDay) return 1;
          if (aa.allDay && bb.allDay) {
            if (aa.itemType === 'EVENT' && bb.itemType === 'EVENT') return aa.summary.localeCompare(bb.summary);
            if (aa.itemType === 'EVENT') return -1;
            return 1;
          }
        }

        // order by date
        const diff = (a?.start?.valueOf() ?? 0) - (b?.start?.valueOf() ?? 0);
        if (diff !== 0) return diff;

        // if both are tasks, order by userOrder
        if (a.itemType === 'TASK' && b.itemType === 'TASK') return a.userOrder - b.userOrder;

        // if they are the same date, order alphabetically
        return (extractItemSummary(a) ?? '').localeCompare(extractItemSummary(b) ?? '');
      });
    });

    // filter items
    return itemsByDay.filter((day) => {
      day.items = filterItemsContacts(day.items, filter, contactEmail) as SmartyItemType[];
      return Boolean(day.items.length);
    });
  }, [
    isAuthenticated,
    timeZone,
    tasks,
    events,
    schedulingLinks,
    notes?.results,
    today,
    filter,
    globalDateRange,
    contactEmail,
  ]);

  const unscheduledItems = useMemo(() => items?.find((ddd) => ddd.date === 'unscheduled'), [items]);

  const scheduledItems = useMemo(() => items?.filter((ddd) => ddd.date !== 'unscheduled'), [items]);

  return { items, unscheduledItems, scheduledItems };
};
