import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as Menubar from '@radix-ui/react-menubar';
import { cn } from 'ui/cn';
import moment from 'moment-timezone';
import { extractEmailContent } from 'utils/gmail';
import { extractParticipants, formatEmailAddress } from 'utils/email';
import { useShortcuts } from '@/context/Common';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { Divider } from '@/components/Divider';
import { Avatar } from '@/components/Avatar';
import { ScrollAreaComponent } from '@/components/ScrollArea';
import ToolTip from '@/components/Tooltip';
import { useRightSidebar } from '@/components/RightSidebar';
import { ComposeEmail } from './ComposeEmail';
import ReferenceList from '../editor/sections/ReferenceList';
import useEmailRefs from './useEmailRefs';
import {
  deleteEmail,
  unTrashThread,
  useArchiveThread,
  useContiguousEmailThreads,
  useEmailThread,
  useImportantThread,
  useNotImportantThread,
  useSpamThread,
  useStarThread,
  useUnarchiveThread,
  useUnspamThread,
  useUnstarThread,
} from '@/hooks/useEmails';
import { VscReply, VscWarning } from 'react-icons/vsc';
import { BiCheck, BiCheckDouble, BiLaptop } from 'react-icons/bi';
import { DeleteIcon } from '@/icons/DeleteIcon';
import {
  MdArchive,
  MdArrowBack,
  MdArrowForwardIos,
  MdLabelImportant,
  MdLabelImportantOutline,
  MdOutlineMoreHoriz,
  MdStar,
  MdStarBorder,
  MdUnarchive,
} from 'react-icons/md';
import { useContacts } from '@/hooks/useContacts';
import { toast } from 'react-hot-toast';
import { ToastGeneric } from '@/components/ToastGeneric';
import { MessageIframe } from './MessageIframe';

export type ComposeType = 'reply' | 'replyAll' | 'forward';

export const Messages = ({ threadId, labelId }: { threadId: string; labelId: string }) => {
  const { openLeftSidebar } = useLeftSidebar();
  const [openedMessage, setOpenMessage] = useState<number[]>([]);
  const [fullContentVisible, setFullContentVisible] = useState<Record<number, boolean>>({});
  const [composeType, setComposeType] = useState<ComposeType>();
  const [replyForwardMessageId, setReplyForwardMessageId] = useState<string | undefined>();
  const { contacts } = useContacts({});
  const [viewEmails, setViewEmails] = useState(false);
  const [showThread, setShowThread] = useState(true);
  const { registerShortcut, unregisterShortcut } = useShortcuts();
  const { toggleEmailThread } = useRightSidebar();

  const { emailThread, isLoading } = useEmailThread({ threadId });
  const { previousThreadId, nextThreadId } = useContiguousEmailThreads({ labelId, threadId });
  useEmailThread({ threadId: nextThreadId }); // prefetch next email
  const referenceIds = useEmailRefs(emailThread?.messages.at(-1)?.id);
  const subject = emailThread?.messages?.at(-1)?.headers['subject'] ?? '';
  const labelIds = emailThread?.messages?.at(-1)?.labelIds ?? [];
  const { archiveThread, isLoadingArchiveThread } = useArchiveThread();
  const { importantThread } = useImportantThread();
  const { notImportanThread } = useNotImportantThread();
  const { starThread, isLoadingStarThread } = useStarThread();
  const { unstarThread, isLoadingUnstar } = useUnstarThread();
  const { unarchiveThread, isLoadingUnarchiveThread } = useUnarchiveThread();
  const { spamThread } = useSpamThread();
  const { unspamThread } = useUnspamThread();

  const hasDisableButtons =
    (isLoadingArchiveThread || isLoadingStarThread || isLoadingUnstar || isLoadingUnarchiveThread) ?? false;

  useEffect(() => {
    // When GmailMessage changes, set the last message to be open
    if (!emailThread?.messages?.length) return;
    setOpenMessage([emailThread.messages.length - 1]);
  }, [emailThread]);

  const scrollAreaTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollAreaTopRef.current) return;
    scrollAreaTopRef.current.scrollTop = scrollAreaTopRef.current.scrollHeight;
  }, [showThread, emailThread?.messages.length]);

  const handleExpandThreadMessage = (value: boolean, index: number) => {
    if (value) {
      setOpenMessage((prev) => [...prev, index]);
    } else {
      setOpenMessage((prev) => prev.filter((item) => item !== index));
    }
    setSelectedIndex(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!scrollAreaTopRef.current) return;
      scrollAreaTopRef.current.scrollTop = scrollAreaTopRef.current.scrollHeight;
    }, 100);

    return () => clearTimeout(timer);
  }, [emailThread, isLoading]);

  // const shouldShowReplyAll = useMemo(() => {
  //   if (!emailThread?.messages?.length) return false;

  //   const latestEmail = emailThread.messages.at(-1);
  //   if (!latestEmail) {
  //     return false;
  //   }

  //   // Extract emails from headers into a single list
  //   const { from, to, cc } = extractParticipants(latestEmail.headers);

  //   // Combine all emails into a single list
  //   const uniqueEmails = Array.from(new Set([from.address, ...to.map((e) => e.address), ...cc.map((e) => e.address)]));
  //   const currentUserEmail = user?.email || accounts?.[0]?.email || '';

  //   // Return true if there are multiple recipients after excluding the current user's email
  //   return uniqueEmails.filter((email) => email !== currentUserEmail).length > 1;
  // }, [emailThread, user?.email, accounts]);

  const isEmailStarred = emailThread?.messages?.some((m) => m.labelIds?.includes('STARRED')) ?? false;
  const isEmailArchived = !emailThread?.messages?.some((m) => m.labelIds?.includes('INBOX')) ?? false;
  const isEmailInTrash = emailThread?.messages?.every((m) => m.labelIds?.includes('TRASH'));
  const isImportant = emailThread?.messages?.some((m) => m.labelIds?.includes('IMPORTANT')) ?? false;
  const isSpam = emailThread?.messages?.some((m) => m.labelIds?.includes('SPAM')) ?? false;

  const handleStarEmail = () => {
    if (isEmailStarred) {
      void unstarThread({ threadId });
    } else {
      void starThread({ threadId });
    }
  };

  const handleImportantEmail = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isImportant) {
      void notImportanThread({ threadId });
    } else {
      void importantThread({ threadId });
    }
  };

  const handleArchiveEmail = () => {
    if (isEmailStarred) return;

    if (isEmailArchived) {
      void unarchiveThread({ threadId });
    } else {
      void archiveThread({ threadId, labelIds });
      openLeftSidebar({
        type: 'EMAIL_DETAIL',
        context: {
          threadId: nextThreadId,
          labelId,
        },
      });
    }
  };

  const handleDeleteEmail = () => {
    const title = emailThread?.messages?.at(-1)?.headers['subject'] ?? '';

    void deleteEmail(threadId);
    toast(<ToastGeneric title={`"${title}” has been deleted`} />, {
      position: 'bottom-center',
      style: { background: 'black' },
    });
  };

  const handleRestoreEmail = () => {
    void unTrashThread(threadId);
  };

  const handleBack = () => {
    if (isLoadingArchiveThread || isLoadingStarThread || isLoadingUnstar || isLoadingUnarchiveThread) return;

    openLeftSidebar({
      type: 'ALL_EMAILS',
      context: {
        labelId,
      },
    });
  };

  const selectPreviousMessage = useCallback(() => {
    if (!previousThreadId) return;
    openLeftSidebar({
      type: 'EMAIL_DETAIL',
      context: {
        threadId: previousThreadId,
        labelId,
      },
    });
  }, [labelId, previousThreadId, openLeftSidebar]);

  const selectNextMessage = useCallback(() => {
    if (!nextThreadId) return;
    openLeftSidebar({
      type: 'EMAIL_DETAIL',
      context: {
        threadId: nextThreadId,
        labelId,
      },
    });
  }, [labelId, nextThreadId, openLeftSidebar]);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleComposeAction = (type: ComposeType | undefined, messageId: string) => {
    setShowThread(false);
    setComposeType(type);
    setReplyForwardMessageId(messageId);
  };

  const handleReplyEmail = (messageId: string) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleComposeAction('reply', messageId);
  };

  const handleForwardEmail = (messageId: string) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleComposeAction('forward', messageId);
  };

  const handleViewEmails = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setViewEmails((prev) => !prev);
  };

  const handleSetSpamEmail = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!emailThread?.id) return;
    if (isSpam) {
      void unspamThread({ threadId: emailThread.id });
    } else {
      void spamThread({ threadId: emailThread.id, labelIds: ['SPAM'] });
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      document.querySelector('[data-email-message][data-selected="true"]')?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'instant',
      });
    });
  }, [selectedIndex]);

  useEffect(() => {
    if (!emailThread?.messages) return;
    setSelectedIndex(emailThread.messages.length - 1);
  }, [emailThread?.messages]);

  const navigateItems = useCallback(
    (direction: 'up' | 'down') => (e: KeyboardEvent) => {
      e.preventDefault();
      if (!emailThread?.messages) return;
      if (!setSelectedIndex) return;

      const nextIndex = direction === 'up' ? selectedIndex - 1 : selectedIndex + 1;
      if (nextIndex < 0 || nextIndex >= emailThread.messages.length) return;
      setSelectedIndex(nextIndex);
    },
    [selectedIndex, emailThread?.messages]
  );

  useEffect(() => {
    registerShortcut(['r', 'R'], () => handleComposeAction('reply', emailThread?.messages.at(0)?.id ?? ''));
    registerShortcut(['x', 'x'], handleStarEmail);
    registerShortcut(['e', 'E'], handleArchiveEmail);
    registerShortcut(['k', 'K'], selectPreviousMessage);
    registerShortcut(['j', 'J'], selectNextMessage);
    registerShortcut(['enter', 'Enter'], () => handleComposeAction('replyAll', emailThread?.messages.at(0)?.id ?? ''));
    registerShortcut('#', handleDeleteEmail);
    registerShortcut('up', navigateItems('up'));
    registerShortcut('down', navigateItems('down'));

    return () => {
      unregisterShortcut(['r', 'R']);
      unregisterShortcut(['x', 'x']);
      unregisterShortcut(['e', 'E']);
      unregisterShortcut(['j', 'J']);
      unregisterShortcut(['k', 'K']);
      unregisterShortcut(['enter', 'Enter']);
      unregisterShortcut('#');
      unregisterShortcut('up');
      unregisterShortcut('down');
      toggleEmailThread(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailThread, selectedIndex]);

  return (
    <>
      <div className="px-4 mt-4 border-b">
        <div className="flex items-center justify-between px-3 py-2">
          <ToolTip tooltip="Back">
            <button className="text-gray-700 hover:text-gray-900" disabled={hasDisableButtons} onClick={handleBack}>
              <MdArrowBack size={24} />
            </button>
          </ToolTip>
          <div className="flex items-center justify-center cursor-pointer h-[44px] px-[8px] bg-gray-muted rounded-[14px]">
            {!isEmailInTrash ? (
              <div className="flex items-center gap-2">
                {labelIds?.includes('SENT') ? (
                  <ToolTip
                    tooltip={
                      !emailThread?.readByUsers?.length ? null : (
                        <ul>
                          {emailThread?.readByUsers?.map((user, index) => (
                            <li key={index}>
                              <p className="flex items-center gap-2">
                                <BiLaptop />
                                {moment(user?.createdAt).calendar()}
                              </p>
                              {user.email}
                            </li>
                          ))}
                        </ul>
                      )
                    }>
                    {(emailThread?.readByUsers?.length ?? 0) > 0 ? <BiCheckDouble /> : <BiCheck />}
                  </ToolTip>
                ) : null}
                <ToolTip tooltip={isEmailStarred ? 'Unstar' : 'Star'}>
                  <button
                    disabled={hasDisableButtons}
                    type="button"
                    className={`${isEmailStarred ? 'text-yellow-400' : 'text-gray-400'}  transition-all hover:text-yellow-600`}
                    onClick={handleStarEmail}>
                    {isEmailStarred ? <MdStar size={18} /> : <MdStarBorder size={18} />}
                  </button>
                </ToolTip>
                <ToolTip
                  tooltip={isEmailStarred ? 'Cannot archive starred email' : isEmailArchived ? 'Unarchive' : 'Archive'}>
                  <button
                    type="button"
                    disabled={isEmailStarred || hasDisableButtons}
                    className="transition-all text-gray-400 hover:text-gray-600"
                    onClick={handleArchiveEmail}>
                    {isEmailArchived ? <MdUnarchive size={18} /> : <MdArchive size={18} />}
                  </button>
                </ToolTip>
                <ToolTip tooltip="Move to trash">
                  <button
                    type="button"
                    disabled={hasDisableButtons}
                    className="text-gray-400 transition-all hover:text-red-600"
                    onClick={handleDeleteEmail}>
                    <DeleteIcon size={18} />
                  </button>
                </ToolTip>
              </div>
            ) : (
              <ToolTip tooltip="Restore">
                <button
                  className="text-gray-400 hover:text-gray-600"
                  disabled={hasDisableButtons}
                  onClick={handleRestoreEmail}>
                  <MdArchive size={18} />
                </button>
              </ToolTip>
            )}
          </div>
        </div>
        <Divider />
        <div className="flex items-center gap-[4px] justify-between mx-3 py-2">
          {!isLoading ? (
            <button type="button" onClick={handleImportantEmail}>
              {isImportant ? (
                <MdLabelImportant className="text-yellow-500 hover:text-yellow-600" size={18} />
              ) : (
                <MdLabelImportantOutline size={18} />
              )}
            </button>
          ) : null}

          {isLoading ? (
            <div className="animate-pulse flex w-full">
              <div className="flex-1 py-1">
                <div className="h-2 bg-slate-700 rounded mb-2"></div>
                <div className="h-2 bg-slate-700 rounded"></div>
              </div>
            </div>
          ) : (
            <h3 className="w-full text-[18px] leading-[25px] font-semibold">{subject}</h3>
          )}
        </div>
        <ReferenceList referenceIds={referenceIds} emailIds={[]} />
      </div>
      <ScrollAreaComponent ref={scrollAreaTopRef} className={cn(`overflow-y-scroll`)}>
        {emailThread?.messages?.map((item, index) => {
          const isFullContentVisible = fullContentVisible[index];
          const { from, to } = extractParticipants(item.headers);
          const { immediateResponse, quotedContent } = extractEmailContent(item.textHtml ?? '');
          const contactFilterFrom = contacts?.find((contact) => contact.email === from.address);

          const toggleFullContent = () => {
            setFullContentVisible((prev) => ({
              ...prev,
              [index]: !isFullContentVisible,
            }));
          };
          const isOpen =
            openedMessage.findIndex((indexInner) => indexInner === index) > -1 || emailThread?.messages.length === 1;
          const isSelected = index === selectedIndex;
          return (
            <Collapsible.Root
              open={isOpen}
              disabled={hasDisableButtons}
              onOpenChange={(value) => handleExpandThreadMessage(value, index)}
              key={index}
              className={cn('px-2')}
              data-email-message
              data-selected={isSelected}>
              <Collapsible.Trigger asChild>
                <div
                  className={cn(
                    'pt-3 pb-[12px] transition-colors cursor-pointer',
                    index !== selectedIndex ? 'hover:bg-slate-200' : ''
                  )}>
                  <div className={`flex justify-between ${!isOpen ? 'brightness-50 contrast-50' : ''} pb-[8px]`}>
                    <div className="flex items-start gap-[4px]">
                      {isLoading ? (
                        <div className="animate-pulse flex items-center w-[300px]">
                          <div className="rounded-full bg-slate-700 h-[30px] w-[30px]"></div>
                          <div className="flex-1 py-1">
                            <div className="ml-1">
                              <div className="h-2 bg-slate-700 rounded"></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <Avatar
                              name="avatarName"
                              picture={contactFilterFrom?.picture ?? ''}
                              size={30}
                              className="rounded-full"
                            />
                          </div>

                          <div className="flex flex-col">
                            <div className="flex items-center gap-[4px]">
                              <p className="font-medium truncate text-[12px] leading-[16px] m-0">
                                {from.name ?? from.address}
                              </p>
                              <p className="text-[12px] leading-[14px] text-[#686B70] whitespace-nowrap m-0">
                                {moment(item.headers['date']).format('MMM DD, YYYY, h:mma')}
                              </p>
                            </div>
                            <p className="text-[14px] leading-[16px] text-[#686B70] m-0">
                              to me
                              <button type="button" className="ml-1" onClick={handleViewEmails}>
                                <MdArrowForwardIos size={12} />
                              </button>
                            </p>
                            <>
                              {viewEmails ? (
                                <div className="flex flex-col" onClick={handleViewEmails}>
                                  <div className="flex items-center gap-[4px]">
                                    <p className="text-[12px] leading-[16px] font-bold">From:</p>
                                    <p className="text-[12px] leading-[16px] text-[#686B70] m-0">
                                      {formatEmailAddress(from)}
                                    </p>
                                  </div>
                                  <div className="flex items-center gap-[4px]">
                                    <p className="text-[12px] leading-[16px] font-bold">To:</p>
                                    <p className="text-[12px] leading-[16px] text-[#686B70]">
                                      {to.map(formatEmailAddress).join(', ')}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      {!composeType ? (
                        <div className="flex items-center">
                          <ToolTip tooltip="Reply">
                            <button
                              type="button"
                              disabled={hasDisableButtons}
                              className="px-2"
                              onClick={handleReplyEmail(item.id!)}>
                              <VscReply />
                            </button>
                          </ToolTip>

                          <Menubar.Root className="h-[18px]">
                            <Menubar.Menu>
                              <Menubar.Trigger
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                className="MenubarTrigger">
                                <button type="button" className="rotate-90">
                                  <MdOutlineMoreHoriz size={18} />
                                </button>
                              </Menubar.Trigger>
                              <Menubar.Portal>
                                <Menubar.Content
                                  className="bg-white rounded-md py-2 shadow-lg transition-all px-1"
                                  align="center"
                                  side="left">
                                  <Menubar.Item className="gap-[8px] flex flex-col">
                                    <button
                                      type="button"
                                      className="flex items-center hover:bg-slate-200 rounded px-2"
                                      onClick={handleReplyEmail(item.id!)}>
                                      <VscReply className="mr-2" />
                                      Reply
                                    </button>
                                    <button
                                      type="button"
                                      className="flex items-center hover:bg-slate-200 rounded px-2"
                                      onClick={handleForwardEmail(item.id!)}>
                                      <VscReply className="transform -scale-x-100 mr-2" />
                                      Forward
                                    </button>
                                  </Menubar.Item>
                                  <Divider />
                                  <button
                                    type="button"
                                    className="flex items-center hover:bg-slate-200 rounded px-2"
                                    onClick={handleSetSpamEmail}>
                                    <VscWarning className="mr-2" />
                                    Report Spam
                                  </button>
                                </Menubar.Content>
                              </Menubar.Portal>
                            </Menubar.Menu>
                          </Menubar.Root>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Divider />
                </div>
              </Collapsible.Trigger>
              <Collapsible.Content className="pb-6">
                <MessageIframe htmlContent={isFullContentVisible ? immediateResponse + quotedContent : immediateResponse} />
                <>
                  {composeType && replyForwardMessageId ? (
                    <ComposeEmail
                      threadId={threadId}
                      originalMessageId={replyForwardMessageId}
                      composeType={composeType}
                      onClose={() => {
                        setComposeType(undefined);
                        setShowThread(true);
                      }}
                    />
                  ) : null}
                </>
                {!composeType && quotedContent ? (
                  <button
                    onClick={toggleFullContent}
                    disabled={hasDisableButtons}
                    className="inline-flex items-center px-2 py-1 text-sm font-semibold text-blue-600 bg-blue-100 rounded shadow hover:bg-blue-200">
                    {'...'}
                  </button>
                ) : null}
              </Collapsible.Content>
            </Collapsible.Root>
          );
        })}
      </ScrollAreaComponent>
    </>
  );
};
