import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { useSegment } from '@/components/useSegment';
import { useDeleteSchedulingLink, useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { useEasySession } from '@/hooks/useEasySession';
import { MouseEvent } from 'react';
import { ToastGeneric } from '../ToastGeneric';
import { useSettings } from '@/hooks/useSettings';
import { toast } from 'react-hot-toast';
import { buildLink, copyToClipboard } from '@/utils/copyToClipboard';
import { DeleteIcon } from '@/icons/DeleteIcon';

export const AllSchedulingLinks = () => {
  const { user } = useEasySession();
  const { settings } = useSettings();
  const { trackEvent } = useSegment();
  const { schedulingLinks } = useSchedulingLinks({});
  const { deleteSchedulingLink } = useDeleteSchedulingLink();
  const { openLeftSidebar } = useLeftSidebar();

  const handleOpenRightSidebarSchedulingLink = (schedulingLinkId: string) => {
    openLeftSidebar({
      type: 'SCHEDULING_LINK_EDITOR',
      context: { id: schedulingLinkId },
    });
    trackEvent('Open from All links', { itemID: schedulingLinkId, itemType: 'Scheduling Link' });
  };

  if (!user || !settings) {
    return null;
  }

  if (!schedulingLinks) {
    return null;
  }

  const handleDelete = (schedulingLinkId: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!schedulingLinkId) return;
    deleteSchedulingLink({
      schedulingLinkId,
    });
    toast(<ToastGeneric title="Scheduling Link deleted" />, {
      position: 'bottom-center',
      style: { background: '#000' },
    });
  };

  const handleCopy = (code: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const url = `${process.env.NEXT_PUBLIC_URL}/${settings?.username || ''}/${code}`;
    const title = url.replace('https://', '');
    const formattedLinkURL = buildLink(url, title);

    copyToClipboard(formattedLinkURL, url);
    toast(<ToastGeneric title="Copied to clipboard" />, { position: 'bottom-center', style: { background: '#000' } });
  };

  return (
    <div className="flex flex-col flex-1 min-h-0 gap-[20px] lg:px-[16px] xs:px-[8px] pb-[24px]">
      <div className="flex-1 overflow-y-auto flex flex-col gap-[10px] px-[5px] pb-[15px]">
        <ul>
          {schedulingLinks?.results?.map((schedulingLink) => (
            <li
              key={schedulingLink.id}
              className="flex hover:bg-gray-100 hover:cursor-pointer items-center mb-[10px] group"
              onClick={() => handleOpenRightSidebarSchedulingLink(schedulingLink.id)}>
              <div className="mr-[10px]">
                <SchedulingLinkIcon />
              </div>
              <div className="flex-1 flex flex-col lg:text-sm xs:text-[12px]">
                {schedulingLink.title} - {schedulingLink.duration} minutes
              </div>
              <button
                type="button"
                onClick={handleCopy(schedulingLink.code)}
                className="group flex h-6 w-6 select-none items-center justify-center rounded-lg bg-white leading-8 text-zinc-950 shadow-[0_-1px_0_0px_#d4d4d8_inset,0_0_0_1px_#f4f4f5_inset,0_0.5px_0_1.5px_#fff_inset] hover:bg-zinc-50 hover:via-zinc-900 hover:to-zinc-800 active:shadow-[-1px_0px_1px_0px_#e4e4e7_inset,1px_0px_1px_0px_#e4e4e7_inset,0px_0.125rem_1px_0px_#d4d4d8_inset]">
                <div className="flex items-center group-active:[transform:translate3d(0,1px,0)]">
                  <SchedulingLinkIcon />
                </div>
              </button>
              <button type="button" onClick={handleDelete(schedulingLink.id)}>
                <DeleteIcon
                  size={16}
                  className="z-10 w-4 h-6 ml-0.5 transition-all opacity-0 cursor-pointer select-none group-hover:opacity-100 hover:fill-red-500 shrink-0"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
