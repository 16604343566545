type Props = {
  className?: string;
  size?: number;
  color?: string;
};

/* export default function ProjectIcon({ className, size = 15, color = '#111317' }: Props) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10201_1538)">
        <path
          d="M13.083 2.06944H6.15979L4.92695 0.836611C4.85997 0.769446 4.78039 0.716176 4.69276 0.679861C4.60513 0.643546 4.5112 0.624902 4.41634 0.625H1.52745C0.730841 0.625 0.0830078 1.27283 0.0830078 2.06944V12.1806C0.0830078 12.9772 0.730841 13.625 1.52745 13.625H13.083C13.8796 13.625 14.5275 12.9772 14.5275 12.1806V3.51389C14.5275 2.71728 13.8796 2.06944 13.083 2.06944Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10201_1538">
          <rect width="15" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
} */

export default function EllipsisIcon({ className, size = 14, color = '#A1A1A3' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none">
      <g clipPath="url(#clip0_2963_177953)">
        <path
          d="M0.5 5.8125V9.1875H3.875V5.8125H0.5ZM5.5625 5.8125V9.1875H8.9375V5.8125H5.5625ZM10.625 5.8125V9.1875H14V5.8125H10.625Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2963_177953">
          <rect width="13.5" height="13.5" fill="white" transform="translate(0.5 0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
}
