import { Fragment, useMemo } from 'react';
import { Command } from 'cmdk';

import { useSearchItems } from '../hooks/useSearchItems';

import type { SearchOption } from '../types';
import { Divider } from '@/components/Divider';

function mapItemsByCategory(items: SearchOption[]) {
  return items.reduce(
    (acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    },
    {} as Record<string, SearchOption[]>
  );
}

export default function ItemList() {
  const items = useSearchItems();

  const renderedItems = useMemo(() => {
    return Object.entries(mapItemsByCategory(items)).map(([category, items]) => {
      const heading = <div className="text-xs leading-[21px] pb-[5px] text-secondary-300 text-start">{category}</div>;

      return (
        <Fragment key={category}>
          <Command.Group className="mb-[7px]" heading={heading}>
            {items.map(({ title, id, Icon, onSelect }) => (
              <Command.Item
                onSelect={onSelect}
                className="flex items-center w-full gap-2 py-[5px] text-sm font-medium leading-normal bg-white rounded cursor-pointer hover:bg-primary-100 aria-selected:bg-primary-100"
                value={id}
                key={id}>
                <Icon color={category === 'Search result' ? 'black' : '#A1A1A3'} className="shrink-0" size={12} />
                <span className="truncate">{title}</span>
              </Command.Item>
            ))}
          </Command.Group>
        </Fragment>
      );
    });
  }, [items]);

  return (
    <div className="flex flex-col min-h-0 max-h-[350px] w-full min-w-[250px] border-b border-b-gray-border pb-3">
      {!!renderedItems.length && <Divider />}
      <Command.List className="w-full h-full overflow-y-auto">{renderedItems}</Command.List>
    </div>
  );
}
