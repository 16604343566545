import { useEasySession } from '@/hooks/useEasySession';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut, transformIntoDate } from 'utils/smarty-api';
import { GetNotes, Note } from '@/models/NoteModel';
import { NoteArg } from '@/models/NoteArg';

type Options = {
  onCreated?: (note: Note) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (note: Note) => void;
  onUpdateError?: (error: void) => void;
};

export function processNoteResponse(note: Note) {
  return {
    ...note,
    createdAt: transformIntoDate(note, 'createdAt'),
    updatedAt: transformIntoDate(note, 'updatedAt'),
    start: transformIntoDate(note, 'start'),
    end: transformIntoDate(note, 'end'),
  };
}

export const useNotes = ({ onCreated, onCreateError, onUpdated, onUpdateError }: Options | undefined = {}) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: notes, isLoading: isLoadingNotes } = useQuery({
    queryKey: ['NOTES'],
    queryFn: async () => {
      const res = await httpGet<GetNotes>('/notes');
      return {
        ...res,
        results: res.results.map(processNoteResponse),
      };
    },
    enabled: isAuthenticated,
  });

  const {
    mutate: createNote,
    status: creatingNoteStatus,
    error: createNoteError,
    isSuccess: isCreateNoteSuccess,
    reset: resetCreateNote,
  } = useMutation<Note, void, NoteArg>({
    mutationKey: ['CREATE_NOTE'],
    mutationFn: async (data) => {
      const res = await httpPost<void, Note, NoteArg>('/notes', data);
      return processNoteResponse(res);
    },
    onError: (error) => {
      onCreateError?.(error);
    },
    onSuccess: (note) => {
      void queryClient.invalidateQueries({ queryKey: ['NOTES'] });
      onCreated?.(note);
    },
  });

  const {
    mutate: updateNote,
    status: updatingNoteStatus,
    error: updateNoteError,
    isSuccess: isUpdateNoteSuccess,
    reset: resetUpdateNote,
  } = useMutation<
    Note,
    void,
    {
      noteId: string;
      note: NoteArg;
    }
  >({
    mutationKey: ['UPDATE_NOTE'],
    mutationFn: async ({ noteId, note }) => {
      const res = await httpPut<void, Note, NoteArg>(`/notes/${noteId}`, note);
      return processNoteResponse(res);
    },
    onError: (error) => {
      onUpdateError?.(error);
    },
    onSuccess: (note) => {
      void queryClient.invalidateQueries({ queryKey: ['NOTES'] });
      onUpdated?.(note);
    },
  });

  const {
    mutate: deleteNote,
    status: deletingNoteStatus,
    error: deleteNoteError,
    isSuccess: isDeleteNoteSuccess,
    reset: resetDeleteNote,
  } = useMutation<void, void, { noteId: string }>({
    mutationKey: ['DELETE_NOTE'],
    mutationFn: ({ noteId }) => httpDelete(`/notes/${noteId}`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['NOTES'] });
      const cached = queryClient.getQueryData<GetNotes>(['NOTES']);
      if (!cached) return;
      queryClient.setQueryData(['NOTES'], () => ({
        results: cached.results?.filter((n) => n.id !== variables.noteId),
        count: cached.count - 1,
      }));
      return cached;
    },
    onSuccess: (_, variables) => {
      const cached = queryClient.getQueryData<GetNotes>(['NOTES']);
      if (!cached) return;
      queryClient.setQueryData(['NOTES'], () => ({
        results: cached.results?.filter((n) => n.id !== variables.noteId),
        count: cached.count - 1,
      }));
    },
    onError: () => {
      queryClient.setQueryData(['NOTES'], (previous) => previous);
    },
  });

  return {
    notes,
    isLoadingNotes,
    createNote,
    isCreatingNote: creatingNoteStatus === 'pending',
    isCreateNoteSuccess,
    createNoteError,
    resetCreateNote,
    updateNote,
    isUpdatingNote: updatingNoteStatus === 'pending',
    isUpdateNoteSuccess,
    updateNoteError,
    resetUpdateNote,
    deleteNote,
    isDeletingNote: deletingNoteStatus === 'pending',
    isDeleteNoteSuccess,
    deleteNoteError,
    resetDeleteNote,
  };
};
