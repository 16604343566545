type Props = {
  className?: string;
  size?: number;
  color?: string;
  stroke?: string;
  strokeWidth?: string;
};

export const ClockIcon = ({ className, size = 15, color = '#8C8C8C', stroke, strokeWidth }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0.5C3.35826 0.5 0 3.85826 0 8C0 12.1417 3.35826 15.5 7.5 15.5C11.6417 15.5 15 12.1417 15 8C15 3.85826 11.6417 0.5 7.5 0.5ZM10.4548 10.3052L9.976 10.9581C9.96559 10.9724 9.95247 10.9844 9.9374 10.9935C9.92232 11.0026 9.90559 11.0087 9.88817 11.0113C9.87075 11.014 9.85297 11.0132 9.83587 11.0089C9.81876 11.0047 9.80267 10.9971 9.7885 10.9866L7.01953 8.96763C7.00228 8.95525 6.98826 8.9389 6.97864 8.91996C6.96903 8.90102 6.96411 8.88005 6.96429 8.85882V4.25C6.96429 4.17634 7.02455 4.11607 7.09821 4.11607H7.90346C7.97712 4.11607 8.03739 4.17634 8.03739 4.25V8.39341L10.4247 10.1194C10.4849 10.1613 10.4983 10.245 10.4548 10.3052Z"
      fill={color}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);
