import moment from 'moment-timezone';
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut, transformIntoDate } from 'utils/smarty-api';
import { Event, RecurringInstance } from '@/models/EventModel';
import { convertEventArgToEvent, EventArg } from '@/models/EventArg';
import { useEasySession } from '@/hooks/useEasySession';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';

export type FrontEndEvent = Event & {
  updating?: boolean;
};

export const processEventResponse = (event: FrontEndEvent) => ({
  ...event,
  createdAt: transformIntoDate(event, 'createdAt'),
  updatedAt: transformIntoDate(event, 'updatedAt'),
  start: transformIntoDate(event, 'start'),
  end: transformIntoDate(event, 'end'),
  recurrenceStart: transformIntoDate(event, 'recurrenceStart'),
});

type useEventsProps = {
  onCreated?: (event: FrontEndEvent) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (event: FrontEndEvent) => void;
  onUpdateError?: (error: void) => void;
  disableQuery?: boolean;
};

export const useInfiniteEvents = ({ start, end, timeZone }: { start: Date; end: Date; timeZone: string }) => {
  const keyStart = moment.tz(start, timeZone).startOf('month');
  const keyEnd = moment.tz(end, timeZone).add(1, 'month').startOf('month');

  const {
    data: infiniteEvents,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['EVENTS'],
    queryFn: async () => {
      const response = await httpGet<FrontEndEvent[]>(`/events`, {
        params: {
          min: keyStart.toISOString(),
          max: keyEnd.toISOString(),
        },
      });
      return response.map(processEventResponse);
    },
    gcTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      const lastItem = lastPage[lastPage.length - 1];
      if (!lastItem) return null;
      const newStart = moment(lastItem.start).format('YYYY-MM-DD');
      const newEnd = moment(newStart).add(1, 'month').format('YYYY-MM-DD');

      return {
        startDate: newStart,
        endDate: newEnd,
      };
    },
    getPreviousPageParam: (firstPage) => {
      const firstItem = firstPage[0];
      if (!firstItem) return null;
      const newStart = moment(firstItem.start).subtract(1, 'month').format('YYYY-MM-DD');
      const newEnd = moment(firstItem.start).format('YYYY-MM-DD');

      return {
        startDate: newStart,
        endDate: newEnd,
      };
    },
    initialPageParam: undefined,
  });

  const events = infiniteEvents?.pages?.flatMap((event) => event) ?? [];

  return {
    data: events,
    error,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    fetchNextPage,
  };
};

export const useEvents = ({ onCreated, onCreateError, onUpdateError, onUpdated, disableQuery }: useEventsProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();
  const { globalDateRange } = useGlobalDateRange();

  const {
    data: events,
    isLoading: isLoadingEvents,
    refetch: refetchEvents,
    isFetching: isFetchingEvents,
  } = useQuery<FrontEndEvent[]>({
    queryKey: ['OLD_EVENTS'],
    queryFn: async () => {
      const res = await httpGet<FrontEndEvent[]>('/events', {
        params: {
          min: globalDateRange.start.toISOString(),
          max: globalDateRange.end.toISOString(),
        },
      });
      return res.map(processEventResponse);
    },
    enabled: !disableQuery && isAuthenticated,
  });

  const {
    mutate: createEvent,
    status: creatingEventStatus,
    error: createEventError,
    isSuccess: isCreateEventSuccess,
    reset: resetCreateEvent,
  } = useMutation<Event, void, EventArg>({
    mutationKey: ['CREATE_EVENT'],
    mutationFn: async (data) => {
      const response = await httpPost<void, Event, EventArg>('/events', data);
      return processEventResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['EVENTS'] });
      const previous = queryClient.getQueryData<InfiniteData<FrontEndEvent[]>>(['EVENTS']);
      if (!previous) return previous;
      queryClient.setQueryData<InfiniteData<FrontEndEvent[]>>(['EVENTS'], {
        ...previous,
        pages: previous.pages.map((page, index) => {
          if (index === 0) {
            return [
              ...page,
              {
                ...convertEventArgToEvent(payload),
                updating: true,
              },
            ];
          }
          return page;
        }),
      });
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<InfiniteData<FrontEndEvent>>(['EVENTS'], (previous) => previous);
      onCreateError?.(error);
    },
    onSuccess: (event) => {
      void queryClient.invalidateQueries({ queryKey: ['EVENTS'] });
      void queryClient.invalidateQueries({ queryKey: ['OLD_EVENTS'] });
      onCreated?.(event);
    },
  });

  const {
    mutate: updateEvent,
    status: updatingEventStatus,
    error: updateEventError,
    isSuccess: isUpdateEventSuccess,
    reset: resetUpdateEvent,
  } = useMutation<
    Event,
    void,
    {
      eventId: string;
      event: EventArg;
      recurringChange?: RecurringInstance;
      originalStart?: Date;
    }
  >({
    mutationKey: ['UPDATE_EVENT'],
    mutationFn: async ({ eventId, event, recurringChange, originalStart }) => {
      const response = await httpPut<void, Event, EventArg>(`/events/${eventId}`, event, {
        params: {
          recurringChange,
          originalStart,
        },
      });
      return processEventResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['EVENTS'] });
      const previous = queryClient.getQueryData<InfiniteData<Event[]>>(['EVENTS']);
      if (!previous) return previous;

      queryClient.setQueryData<InfiniteData<Event[]>>(['EVENTS'], {
        ...previous,
        pages: previous.pages.map((page) => {
          if (!page.some((event) => event.id === payload.eventId)) {
            return page;
          }
          return page.map((cachedEvent) => {
            if (cachedEvent.id !== payload.eventId) {
              return cachedEvent;
            }
            return {
              ...cachedEvent,
              updating: true,
            };
          });
        }),
      });
      return previous;
    },
    onError: () => {
      queryClient.setQueryData<InfiniteData<Event[]>>(['EVENTS'], (previous) => previous);
      onUpdateError?.();
    },
    onSuccess: (event) => {
      void queryClient.invalidateQueries({ queryKey: ['EVENTS'] });
      void queryClient.invalidateQueries({ queryKey: ['OLD_EVENTS'] });
      onUpdated?.(event);
    },
  });

  return {
    events,
    refetchEvents,
    isFetchingEvents,
    isLoadingEvents,
    createEvent,
    isCreatingEvent: creatingEventStatus === 'pending',
    isCreateEventSuccess,
    createEventError,
    resetCreateEvent,
    updateEvent,
    isUpdatingEvent: updatingEventStatus === 'pending',
    isUpdateEventSuccess,
    updateEventError,
    resetUpdateEvent,
  };
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    void,
    void,
    {
      eventId: string;
      recurringDeletion?: RecurringInstance;
      originalStart?: Date;
    }
  >({
    mutationKey: ['DELETE_EVENT'],
    mutationFn: async ({ eventId, recurringDeletion, originalStart }) => {
      await httpDelete(`/events/${eventId}`, {
        params: {
          recurringDeletion,
          originalStart,
        },
      });
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['DELETE_EVENT'] });
      const previous = queryClient.getQueryData<InfiniteData<FrontEndEvent[]>>(['EVENTS']);
      
      if (!previous) return previous;
      queryClient.setQueryData<InfiniteData<Event[]>>(['EVENTS'], {
        ...previous,
        pages: previous.pages.map((page) => {
          if (!page.some((event) => event.id === payload.eventId)) {
            return page;
          }
          return page.map((cachedEvent) => {
            if (cachedEvent.id !== payload.eventId) {
              return cachedEvent;
            }
            return {
              ...cachedEvent,
              updating: true,
            };
          });
        }),
      });
      return previous;
    },
    onError: () => {
      queryClient.setQueryData<Event>(['EVENTS'], (previous) => previous);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['EVENTS'] });
      void queryClient.invalidateQueries({ queryKey: ['OLD_EVENTS'] });
    },
  });

  return {
    deleteEvent: mutation.mutate,
    isDeletingEvent: mutation.status === 'pending',
    deleteEventError: mutation.error,
    isDeleteEventSuccess: mutation.isSuccess,
    resetDeleteEvent: mutation.reset,
  };
};
