import { ReactNode, useCallback, useEffect } from 'react';
import Head from 'next/head';
import { useEasySession } from '@/hooks/useEasySession';
import { useAtomValue } from 'jotai';
import { RightSidebar, useRightSidebar } from '@/components/RightSidebar';
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import useDropActions from '@/hooks/useDndActions';
import useDndContext from '@/hooks/useDndContext';
import useLeftSidebar from './LeftSidebar/useLeftSidebar';
import { Overlay } from '@/components/Overlay';
import { LeftSidebar } from './LeftSidebar';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import { useSegment } from '@/components/useSegment';
import { NavigatorView } from './IntegrationsSidebar/Navigator';
import { searchStateAtom } from './Overlay/utils';

type RootLayoutProps = {
  header: ReactNode;
  children: ReactNode;
  hideNavigationElements?: boolean;
};

export const RootLayout = ({ header, children, hideNavigationElements = false }: RootLayoutProps) => {
  const { identifyUser } = useSegment();
  const router = useRouter();
  const { isLoading, user, isActive, hasTrialEnded } = useEasySession();
  const { rightSidebarState, closeRightSidebar } = useRightSidebar();
  const { closeLeftSidebar, leftSidebarState } = useLeftSidebar();
  const overlay = useAtomValue(searchStateAtom);

  const { dropHandler } = useDropActions();
  const { onDragStart, onDragEnd } = useDndContext();

  useEffect(() => {
    if (!user?.email) return;
    void identifyUser(user.email);
  }, [identifyUser, user?.email]);

  const handleOnDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      dropHandler(result, provided);
      onDragEnd(result);
    },
    [dropHandler, onDragEnd]
  );

  if (isLoading) return null;

  if (!isActive && hasTrialEnded && router.pathname !== '/settings/subscriptions') {
    void router.push('/settings/subscriptions');
    return null;
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={handleOnDragEnd}>
      <Head>
        <title>/smarty.ai</title>
      </Head>
      <div className="fixed flex flex-row w-full h-screen max-h-screen min-h-screen">
        {!hideNavigationElements && (
          <NavigatorView className="h-screen min-h-screen max-h-screen overflow-hidden min-w-[48px] w-auto border-r border-solid" />
        )}
        {leftSidebarState.show && !hideNavigationElements ? (
          <div className="relative h-screen w-[424px]">
            <LeftSidebar
              className="border-r border-solid z-20 bg-white flex flex-col overflow-y-auto overflow-x-hidden"
              onClose={closeLeftSidebar}
            />
          </div>
        ) : null}
        <div className="flex flex-col flex-1 h-screen max-h-screen min-h-screen overflow-y-scroll">
          {header}
          <main className="relative flex flex-col flex-1 w-full min-h-0 mx-auto">{children}</main>
        </div>
        {rightSidebarState.show ? (
          <div className="relative h-screen w-[424px]">
            <RightSidebar
              className="h-full overflow-y-auto overflow-x-hidden z-20 bg-white"
              onClose={closeRightSidebar}
            />
          </div>
        ) : null}
        {overlay.show ? <Overlay /> : null}
      </div>
      <Toaster />
    </DragDropContext>
  );
};
