import ToolTip from '@/components/Tooltip';

type Props = {
  className?: string;
  width?: number;
  height?: number;
  onAutoscheduleClick: () => void;
};

export const AutoscheduleBtn = ({ className, height = 43, width = 56, onAutoscheduleClick }: Props) => (
  <div
    className={`bg-primary-500 rounded flex items-center justify-center cursor-pointer ${className}`}
    style={{ width: `${width}px`, height: `${height}px` }}
    onClick={onAutoscheduleClick}>
    <ToolTip tooltip="Automatically schedule all To Dos">
      <div className="bg-white text-primary-500 flex justify-center items-center rounded-full w-[23px] h-[23px] font-bold select-none">
        <span>A</span>
      </div>
    </ToolTip>
  </div>
);
