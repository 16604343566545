import { SchedulingLink } from '@/models/SchedulingLinkModel';
import { SchedulingLinkContact } from '@prisma/client';
import { NameEmail } from '@/utils/contacts';
import cuid from 'cuid';

export type SchedulingLinkArg = Omit<SchedulingLink, 'itemType' | 'id' | 'userLink' | 'contacts' | 'createdAt'> & {
  code: string;
  contacts?: NameEmail[];
  questions?: SchedulingLinkQuestion[];
  hasAHold?: boolean;
  holdType?: string;
  holdDuration?: number;
  holdDurationUnit?: string;
};

// non-schema type
export type SchedulingLinkQuestion = {
  id: string;
  question: string;
  answers: {
    id: string;
    answer: string;
  }[];
  isMandatory: boolean;
  answerFormat: 'Single line' | 'Multi-line';
};

export const convertSchedulingLinkToSchedulingLinkArg = (schedulingLink: SchedulingLink): SchedulingLinkArg => {
  const { itemType, id, userLink, contacts, ...rest } = schedulingLink;
  return {
    ...rest,
    contacts: contacts?.map((c) => ({ name: c.name, email: c.email })),
    code: userLink?.code ?? cuid(),
    questions: schedulingLink.questions as SchedulingLinkQuestion[],
    bufferAfter: schedulingLink.bufferAfter ?? undefined,
    bufferBefore: schedulingLink.bufferBefore ?? undefined,
    hasAHold: schedulingLink.hasAHold ?? undefined,
    holdType: schedulingLink.holdType ?? undefined,
    holdDuration: schedulingLink.holdDuration ?? undefined,
    holdDurationUnit: schedulingLink.holdDurationUnit ?? undefined,
    holdEventIds: schedulingLink.holdEventIds ?? undefined,
    holdExpiration: schedulingLink.holdExpiration ?? undefined,
  };
};

export const convertSchedulingLinkArgToSchedulingLink = ({
  schedulingLinkArg,
  schedulingLinkId = 'temp',
  contacts = [],
}: {
  schedulingLinkArg: SchedulingLinkArg;
  schedulingLinkId?: string;
  contacts?: SchedulingLinkContact[];
}): SchedulingLink => {
  return {
    id: schedulingLinkId || 'temp',
    itemType: 'SCHEDULING_LINK',
    ...schedulingLinkArg,
    createdAt: new Date(),
    contacts,
  };
};
