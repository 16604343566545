type Props = {
  className?: string;
  size?: number;
  color?: string;
  onClick?: () => void;
};

export const SearchIcon = ({ className, size = 26, color = '#A1A1A3', onClick }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick?.()}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.49941C5.54131 1.49941 4.14236 2.07887 3.11091 3.11032C2.07946 4.14177 1.5 5.54072 1.5 6.99941C1.5 8.4581 2.07946 9.85705 3.11091 10.8885C4.14236 11.9199 5.54131 12.4994 7 12.4994C8.45869 12.4994 9.85764 11.9199 10.8891 10.8885C11.9205 9.85705 12.5 8.4581 12.5 6.99941C12.5 5.54072 11.9205 4.14177 10.8891 3.11032C9.85764 2.07887 8.45869 1.49941 7 1.49941ZM1.61595e-08 6.99941C9.44369e-05 5.88008 0.268612 4.77709 0.783029 3.78297C1.29745 2.78885 2.04276 1.93259 2.95645 1.28601C3.87015 0.639426 4.92557 0.221384 6.0342 0.0669481C7.14282 -0.0874879 8.27232 0.0261854 9.32794 0.398433C10.3836 0.77068 11.3345 1.39065 12.101 2.20633C12.8676 3.02201 13.4273 4.00961 13.7333 5.0863C14.0393 6.16299 14.0827 7.29736 13.8597 8.39427C13.6368 9.49117 13.154 10.5186 12.452 11.3904L15.78 14.7194C15.8537 14.7881 15.9128 14.8709 15.9538 14.9629C15.9948 15.0549 16.0168 15.1542 16.0186 15.2549C16.0204 15.3556 16.0018 15.4556 15.9641 15.549C15.9264 15.6424 15.8703 15.7272 15.799 15.7984C15.7278 15.8697 15.643 15.9258 15.5496 15.9635C15.4562 16.0013 15.3562 16.0198 15.2555 16.018C15.1548 16.0162 15.0555 15.9942 14.9635 15.9532C14.8715 15.9122 14.7887 15.8531 14.72 15.7794L11.391 12.4514C10.3625 13.2798 9.12074 13.8004 7.80901 13.953C6.49727 14.1056 5.16912 13.8841 3.97795 13.3139C2.78677 12.7438 1.78117 11.8484 1.07727 10.731C0.373379 9.61366 -8.9698e-05 8.31999 1.61595e-08 6.99941Z"
        fill={color}
      />
    </svg>
  );
};
