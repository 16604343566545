import moment from 'moment-timezone';
import { useMemo } from 'react';
import { SmartyItemType, useSmartyItems } from '@/hooks/useSmartyItems';
import { useGlobalSelectedDate } from '@/hooks/useGlobalSelectedDate';
import { useSettings } from '@/hooks/useSettings';
import Header from './Header';
import { NewCommandBox } from '@/components/CommandBox/NewCommandBox';
import { isItemCompleted } from '@/models/shared';
import { CollapsibleSection } from '@/components/CollapsibleSection';
import { SubHeaderText } from '@/components/Typography';
import Editor from '@/components/Editor';

type Props = {
  onClose: () => void;
};

export default function SingleDayList({ onClose }: Props) {
  const { globalSelectedDate } = useGlobalSelectedDate();
  const { scheduledItems } = useSmartyItems();
  const { defaultTimeZone: timeZone } = useSettings();

  const dateItems = useMemo(
    () => scheduledItems?.find((item) => moment.tz(item.date, timeZone).isSame(globalSelectedDate, 'day')) || null,
    [globalSelectedDate, scheduledItems, timeZone]
  );

  const content = useMemo(() => {
    if (!dateItems) return null;

    if (!moment.tz(dateItems.date, timeZone).isSame(new Date(), 'day')) {
      return (
        <div className="flex flex-col py-3">
          <Editor date={moment(dateItems.date).format('YYYY-MM-DD')} items={dateItems.items} />
        </div>
      );
    }

    const [todayCompletedItems, rolledOverItems, todayItems] = dateItems.items.reduce(
      (acc, item) => {
        if (item.itemType === 'SCHEDULING_LINK' && item.when === 'RECURRING_SLOTS') {
          return acc;
        }
        if (isItemCompleted(item)) {
          acc[0].push(item);
          return acc;
        }
        if (item.itemType === 'TASK' && item.end && moment.tz(timeZone).isAfter(item.end)) {
          acc[1].push(item);
          return acc;
        }
        acc[2].push(item);
        return acc;
      },
      [[] as SmartyItemType[], [] as SmartyItemType[], [] as SmartyItemType[]]
    ) || [[], [], []];

    return (
      <div className="flex flex-col">
        {rolledOverItems.length ? (
          <CollapsibleSection
            className="ml-[24px]"
            header={<SubHeaderText className="my-[8px]">Rolled Over</SubHeaderText>}
            content={<Editor date="today" items={rolledOverItems} />}
          />
        ) : null}

        <Editor date="today" items={todayItems} />

        {todayCompletedItems.length ? (
          <CollapsibleSection
            className="ml-[24px]"
            header={<SubHeaderText className="my-[8px]">Completed</SubHeaderText>}
            content={<Editor items={todayCompletedItems} disableNewLine />}
          />
        ) : null}
      </div>
    );
  }, [dateItems, timeZone]);

  return (
    <>
      <Header onClose={onClose} />
      <div className="w-full h-full min-h-0 overflow-y-auto">
        <NewCommandBox
          hideTooltip
          use="create"
          className="px-[24px]"
          item={{ start: globalSelectedDate! } as SmartyItemType}
        />
        {content}
      </div>
    </>
  );
}
