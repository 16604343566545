import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut } from 'utils/smarty-api';
import { CreateTimeZoneInput, Settings, TimeZone, UpdateProfileInput } from '@/models/SettingsModel';
import { useEasySession } from '@/hooks/useEasySession';
import { getBrowserTimeZone, getTimeZones } from '@/utils/date';

const updateSettings = (payload: UpdateProfileInput) =>
  httpPut<void, Settings, UpdateProfileInput>('/settings', payload);

export const useSettings = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useEasySession();

  const { data: settings } = useQuery(
    {
      queryKey: ['SETTINGS'],
      queryFn: () => httpGet<Settings>('/settings'),
      enabled: isAuthenticated,
    },
    queryClient
  );

  const { mutateAsync: saveSettings } = useMutation(
    {
      mutationKey: ['SETTINGS_UPDATE'],
      mutationFn: updateSettings,
      onMutate: async (payload) => {
        await queryClient.cancelQueries({
          queryKey: ['SETTINGS'],
        });
        const previousUser = queryClient.getQueryData<Settings>(['SETTINGS']);
        queryClient.setQueryData<Settings>(['SETTINGS'], (old) => ({
          ...old!,
          ...payload,
        }));
        return previousUser;
      },
      onError: () => {
        queryClient.setQueryData<Settings>(['SETTINGS'], (old) => old);
      },
      onSuccess: (data) => {
        queryClient.setQueryData<Settings>(['SETTINGS'], () => data);
      },
    },
    queryClient
  );

  const { mutateAsync: setDefaultTimeZone } = useMutation(
    {
      mutationKey: ['SET_DEFAULT_TIMEZONE'],
      mutationFn: (name: string) => httpPost<void, TimeZone[], string>(`/settings/time-zones/default`, name),
      onMutate: async (payload) => {
        await queryClient.cancelQueries({ queryKey: ['SET_DEFAULT_TIMEZONE'] });
        const previous = queryClient.getQueryData<Settings>(['SETTINGS']);
        if (!previous) return previous;
        queryClient.setQueryData<Settings>(['SETTINGS'], () => {
          return {
            ...previous,
            timeZones: (previous.timeZones ?? []).map((tz) => {
              if (!tz.isDefault && tz.name !== payload) return tz;
              return {
                ...tz,
                isDefault: tz.name === payload,
              };
            }),
          };
        });
        return previous;
      },
      onError: () => {
        queryClient.setQueryData<Settings>(['SETTINGS'], (previous) => previous);
      },
      onSuccess: (data) => {
        queryClient.setQueryData<Settings>(['SETTINGS'], (previous) => {
          return {
            ...previous!,
            timeZones: data,
          };
        });
      },
    },
    queryClient
  );

  const { mutateAsync: createTimeZone } = useMutation(
    {
      mutationKey: ['TIMEZONES_CREATE'],
      mutationFn: (data: CreateTimeZoneInput) =>
        httpPost<void, TimeZone[], CreateTimeZoneInput>(`/settings/time-zones`, data),
      onMutate: async (data) => {
        await queryClient.cancelQueries({ queryKey: ['SETTINGS'] });
        const previous = queryClient.getQueryData<Settings>(['SETTINGS']);
        if (!previous) return previous;
        queryClient.setQueryData<Settings>(['SETTINGS'], () => {
          return {
            ...previous,
            timeZones: [
              ...(previous.timeZones ?? []),
              {
                name: data.name,
                isEnabled: true,
                isDefault: false,
              },
            ],
          };
        });
        return previous;
      },
      onError: () => {
        queryClient.setQueryData<TimeZone[]>(['SETTINGS'], (previousTimeZones) => previousTimeZones);
      },
    },
    queryClient
  );

  const { mutateAsync: deleteTimeZone } = useMutation(
    {
      mutationKey: ['TIMEZONES_DELETE'],
      mutationFn: (timeZoneName: string) => httpDelete(`/settings/time-zones`, { params: { timeZoneName } }),
      onMutate: async (timeZoneName) => {
        await queryClient.cancelQueries({ queryKey: ['SETTINGS'] });
        const previous = queryClient.getQueryData<Settings>(['SETTINGS']);
        if (!previous) return previous;
        queryClient.setQueryData<Settings>(['SETTINGS'], () => {
          return {
            ...previous,
            timeZones: (previous.timeZones ?? []).filter((p) => p.name !== timeZoneName),
          };
        });
        return previous;
      },
      onError: () => {
        queryClient.setQueryData<TimeZone[]>(['SETTINGS'], (previousTimeZones) => previousTimeZones);
      },
    },
    queryClient
  );

  const defaultDuration = useMemo(() => {
    return settings?.defaultDuration ?? 30;
  }, [queryClient, settings]);

  const defaultTimeZone = useMemo(() => {
    return settings?.timeZones?.find((tz) => tz.isDefault)?.name ?? getBrowserTimeZone();
  }, [settings?.timeZones]);

  const [globalTimeZones] = useState(getTimeZones());

  return {
    settings,
    defaultDuration,
    timeZones: settings?.timeZones,
    defaultTimeZone,
    saveSettings,
    createTimeZone,
    deleteTimeZone,
    setDefaultTimeZone,
    globalTimeZones,
  };
};
