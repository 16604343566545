import { CalendarAccessRole, EventVisibility, Priority, TimeBlockingType } from '@prisma/client';
import { capitalize } from 'utils/string';
import { Calendar } from '@/models/CalendarModel';
import { parseRecurrence } from '@/utils/parseRecurrence';
import { parseDuration } from '@/utils/parseDuration';
import { parseDatesTimes } from '@/utils/parseDatesTimes';
import { formatDate, formatDateRange, roundToNextBlock } from '@/utils/date';
import { getFreeSlots } from '@/hooks/useFreeSlots';
import moment from 'moment-timezone';
import { searchContacts } from '@/hooks/useContactSearch';
import { Availability } from '@/models/AvailabilityModel';
import usePlacesAsync from '@/hooks/usePlacesAsync';
import { useCallback } from 'react';
import { buildSuggestionId } from '@/components/Editor/utils/suggestions';
import { Submenu, Suggestion, SuggestionType } from '../types';
import { conferencingOptions } from '@/components/CommandBox/useConferencingOptions';
import { DEFAULT_DURATION_OPTIONS } from '@/components/DurationSelector';
import { ItemType } from '@/components/Editor/types';

export const PRIORITY_SUGGESTIONS = Object.values(Priority).map<Suggestion>((priority) => ({
  id: buildSuggestionId(SuggestionType.PRIORITY, priority),
  label: capitalize(priority).replace('_', ' '),
  category: 'PRIORITY',
}));

export const TIME_BLOCKING_SUGGESTIONS = Object.values(TimeBlockingType).map<Suggestion>((type) => ({
  id: buildSuggestionId(SuggestionType.TIME_BLOCKING, type),
  label: capitalize(type),
  category: 'TIME BLOCKING',
}));

export const VISIBILITY_TYPE_SUGGESTIONS = Object.values(EventVisibility).map<Suggestion>((type) => ({
  id: buildSuggestionId(SuggestionType.VISIBILITY, type),
  label: type,
  category: 'VISIBILITY',
}));

export const CONFERENCE_SUGGESTIONS = Object.entries(conferencingOptions).map<Suggestion>(([option, display]) => ({
  id: buildSuggestionId(SuggestionType.CONFERENCE, option),
  label: display,
  category: 'CONFERENCE',
}));

export async function getSuggestedFreeSlots({
  query,
  submenu,
  timeZone,
}: {
  timeZone: string;
  submenu: Submenu | null;
  query: string;
}): Promise<Suggestion[]> {
  let type: SuggestionType = SuggestionType.DATE;
  let category = 'DATE';
  if (submenu === Submenu.DEADLINE) {
    type = SuggestionType.DEADLINE;
    category = 'DEADLINE';
  }

  if (query.length) {
    return [];
  }

  const allFreeSlots = await getFreeSlots({
    start: roundToNextBlock(new Date(), 30),
    end: roundToNextBlock(moment.tz(timeZone).startOf('day').add(4, 'days').toDate(), 30), // TODO limit
    timeZone,
  });

  return allFreeSlots
    .filter((_, index) => index < 2)
    .map((slot) => ({
      id: buildSuggestionId(type, slot.toISOString()),
      label: formatDate({ date: slot, timeZone, allDay: false }),
      category,
    })); // show next 2 free slots
}

export function useSuggestedLocationAsync() {
  const getPlaces = usePlacesAsync();

  return useCallback(
    async (query: string): Promise<Suggestion[]> => {
      const { status, data } = await getPlaces(query);

      if (status === 'OK' && data?.length) {
        return data.map((place) => ({
          id: buildSuggestionId(SuggestionType.LOCATIONS, place.description),
          label: place.description,
          category: 'LOCATIONS',
        }));
      }

      return [];
    },
    [getPlaces]
  );
}

export async function getSuggestedContacts({
  query,
  itemType,
}: {
  query: string;
  itemType: ItemType;
}): Promise<Suggestion[]> {
  const mentionsExclude = [] as string[];

  const suggestedContactsP = await searchContacts(query, mentionsExclude.join(','));

  const suggestedContacts =
    suggestedContactsP?.map((contact) => ({
      id: buildSuggestionId(
        SuggestionType.CONTACT,
        contact.picture ? contact.email + '~~JOIN~~' + contact.picture : contact.email || ''
      ),
      label: contact.name || contact.email,
      category: itemType === 'SCHEDULING_LINK' ? 'CHECK FOR CONFLICTS WITH' : 'CONTACTS',
    })) ?? [];

  if (!query.length) {
    return suggestedContacts.splice(0, 3);
  }

  return suggestedContacts;
}

export function getSuggestedCustomSchedules({
  query,
  availability = [],
  itemType,
}: {
  query: string;
  availability: Availability[] | undefined;
  itemType: ItemType;
}): Suggestion[] {
  return (
    availability
      ?.filter((schedule) => schedule.title.toLowerCase().includes(query.toLowerCase()))
      .map((schedule) => ({
        id: buildSuggestionId(SuggestionType.SCHEDULE, schedule.id),
        label: (itemType === 'TASK' ? 'Auto-schedule during ' : '') + schedule.title,
        category: 'SCHEDULES',
      })) || []
  );
}

export async function getSubmenuSuggestions({
  submenu,
  query,
  calendars = [],
  parsedDates,
  timeZone,
}: {
  submenu: Submenu | null;
  query: string;
  calendars?: Calendar[];
  parsedDates: Suggestion[];
  timeZone: string;
}): Promise<Suggestion[]> {
  let suggestions: Suggestion[] = [];

  switch (submenu) {
    case Submenu.PRIORITY:
      suggestions = PRIORITY_SUGGESTIONS;
      break;
    case Submenu.TIME_BLOCKING:
      suggestions = TIME_BLOCKING_SUGGESTIONS;
      break;
    case Submenu.VISIBILITY:
      suggestions = VISIBILITY_TYPE_SUGGESTIONS;
      break;
    case Submenu.CONFERENCE:
      suggestions = CONFERENCE_SUGGESTIONS;
      break;

    case Submenu.DURATION:
      suggestions = DEFAULT_DURATION_OPTIONS.map<Suggestion>((value) => ({
        id: buildSuggestionId(SuggestionType.DURATION, value.toString()),
        label: `${value} minutes`,
        category: 'DURATION',
      }));
      break;

    case Submenu.DEADLINE:
      if (parsedDates.length) {
        return parsedDates;
      }

      return getSuggestedFreeSlots({ query, submenu, timeZone });

    case Submenu.CALENDAR: {
      suggestions = calendars
        .filter(({ accessRole }) => [CalendarAccessRole.owner, CalendarAccessRole.writer].includes(accessRole as any))
        .map((calendar) => ({
          id: buildSuggestionId(SuggestionType.CALENDAR, calendar.id),
          label: calendar.summary,
          category: 'CALENDAR',
        }));
      break;
    }
  }

  return suggestions.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));
}

export function filterBySearch(query: string, suggestions: Suggestion[]) {
  return suggestions.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));
}

export const ITEM_TYPE_SUGGESTIONS = {
  SCHEDULING_LINK: [
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.DURATION),
      label: 'Duration',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.CALENDAR),
      label: 'Calendar',
      category: 'DETAILS',
    },
  ],
  EVENT: [
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.CALENDAR),
      label: 'Calendar',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.VISIBILITY),
      label: 'Visibility',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.CONFERENCE),
      label: 'Conference',
      category: 'DETAILS',
    },
  ],
  NOTE: [
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.PRIORITY),
      label: 'Priority',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.DURATION),
      label: 'Duration',
      category: 'DETAILS',
    },
  ],
  TASK: [
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.PRIORITY),
      label: 'Priority',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.DURATION),
      label: 'Duration',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.DEADLINE),
      label: 'Hard deadline',
      category: 'DETAILS',
    },
    {
      id: buildSuggestionId(SuggestionType.SUBMENU, Submenu.TIME_BLOCKING),
      label: 'Time blocking',
      category: 'DETAILS',
    },
  ],
};

export function getRecurrenceSuggestions(search: string): Suggestion[] {
  const rrule = parseRecurrence(search);

  if (!rrule) {
    return [];
  }

  return [
    {
      id: buildSuggestionId(SuggestionType.RECURRENCE, rrule.toString()),
      label: rrule.toText(),
      category: 'SCHEDULES',
    },
  ];
}

export function getDurationSuggestions(query: string): Suggestion[] {
  const duration = parseDuration(query);

  if (!duration) {
    return [];
  }

  return [
    {
      id: buildSuggestionId(SuggestionType.DURATION, duration.valueInMinutes.toString()),
      label: duration.display,
      category: 'DURATION',
    },
  ];
}

export function getAutoScheduleSuggestions(search: string): Suggestion[] {
  if (!'auto-schedule'.includes(search)) {
    return [];
  }

  return [
    {
      id: SuggestionType.AUTO_SCHEDULE,
      label: 'Auto-schedule with Smarty',
      category: 'DATE',
    },
  ];
}

export function getDatesSuggestions({
  query,
  itemType,
  timeZone,
  submenu,
}: {
  query: string;
  itemType: ItemType;
  timeZone: string;
  submenu: Submenu | null;
}): Suggestion[] {
  return parseDatesTimes(query, itemType, timeZone).map((date) => {
    let type: SuggestionType;

    if (date.isTimeRange && itemType === 'TASK') {
      type = SuggestionType.AUTO_SCHEDULE;
    } else {
      type = submenu === Submenu.DEADLINE ? SuggestionType.DEADLINE : SuggestionType.DATE;
    }

    const category = submenu === Submenu.DEADLINE ? 'DEADLINE' : 'DATE';

    let label = formatDateRange({
      start: date.start,
      end: date.end,
      timeZone,
      allDay: date.allDay,
    });

    if (date.display) {
      if (date.display === 'before' || date.display === 'by') {
        const formattedDate = formatDate({
          date: date.end,
          timeZone,
          allDay: date.timeIsCertain ? date.allDay : true,
        });

        label = `Auto-schedule for ${date.display} ${formattedDate}`;
      } else if (date.display === 'after') {
        const formattedDate = formatDate({
          date: date.start,
          timeZone,
          allDay: date.timeIsCertain ? date.allDay : true,
        });

        label = `Auto-schedule for ${date.display} ${formattedDate}`;
      } else {
        label = `Auto-schedule for ${label}`;
      }
    } else if (date.isTimeRange && itemType === 'TASK') {
      label = `Auto-schedule for ${label}`;
    }

    return {
      id: buildSuggestionId(type, `${date.start.toISOString()}%%%${date.end?.toISOString()}%%%${date.allDay ?? false}`),
      label,
      category,
    };
  });
}
