import { TaskIcon } from '@/icons/TaskIcon';
import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';

export const iconByItemType = {
  TASK: TaskIcon,
  EVENT: EventIcon,
  NOTE: NoteIcon,
  SCHEDULING_LINK: SchedulingLinkIcon,
};
