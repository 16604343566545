import { useEffect, useMemo } from 'react';
import { useSmartyItems } from '@/hooks/useSmartyItems';
import { Task } from '@/models/TaskModel';
import { Event } from '@/models/EventModel';
import useDropActions from '@/hooks/useDndActions';
import { DropResult } from 'react-beautiful-dnd';
import { DraggableType, DroppableType, parseDraggableId, parseDroppableId } from '@/utils/dnd';
import { useTasks } from '@/hooks/useTasks';
import Editor from '../Editor';
import { ItemType } from '../Editor/types';

export const AllUnScheduled = () => {
  const { items } = useSmartyItems();
  const { tasks, reorderTask } = useTasks({});

  const unscheduledItems = useMemo(() => {
    if (!items) return [];

    return items.find((item) => item.date === 'unscheduled')?.items || [];
  }, [items]);

  const { addAction, removeAction } = useDropActions();

  useEffect(() => {
    async function handleDragEnd(result: DropResult) {
      const { destination, source, draggableId: _draggableId } = result;

      if (!unscheduledItems || !destination) return;

      const { draggableType, id: draggableId } = parseDraggableId(_draggableId);
      if (draggableType !== DraggableType.TASK) return;
      const { id: sourceDate } = parseDroppableId(source.droppableId);
      const { droppableType, id: destinationDate } = parseDroppableId(destination.droppableId);

      if (droppableType !== DroppableType.LIST || destinationDate !== 'unscheduled') return;
      if (destinationDate === sourceDate && destination.index === source.index) return;

      // get the dragged task
      const draggedTask = tasks?.results.find((item) => item.id === draggableId);

      if (!draggedTask) {
        return;
      }

      let correctedDestinationIndex = destination.index;
      if (source.index > destination.index || sourceDate !== destinationDate) {
        correctedDestinationIndex--;
      }

      const previousItems = unscheduledItems
        .filter((_, index) => index <= correctedDestinationIndex)
        .filter((t) => ['TASK', 'EVENT'].includes(t.itemType) && !(t as Task | Event).allDay) as (Task | Event)[];
      const previousTasks = previousItems.filter((t) => t.itemType === 'TASK') as Task[];

      const previousTask = previousTasks[previousTasks.length - 1];

      try {
        await reorderTask(draggedTask.id, undefined, undefined, previousTask?.id);
      } catch (error) {
        console.error('Error while reordering task', error);
      }
    }

    addAction(handleDragEnd);

    return () => {
      removeAction(handleDragEnd);
    };
  }, [addAction, items, removeAction, reorderTask, tasks?.results, unscheduledItems]);

  return (
    <div className="flex flex-col min-h-0 overflow-y-auto flex-1 py-[24px]">
      <Editor date="unscheduled" onlyCreate items={[]} allowedTypes={[ItemType.NOTE, ItemType.TASK]} />
      <Editor date="unscheduled" items={unscheduledItems} allowedTypes={[ItemType.NOTE, ItemType.TASK]} className='p-0' />
    </div>
  );
};
