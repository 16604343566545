import * as Dialog from '@radix-ui/react-dialog';
import { ParagraphText } from '@/components/Typography';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Cross1Icon } from '@radix-ui/react-icons';

export enum Action {
  CANCEL = 'CANCEL',
  DONT_SEND = 'DONT_SEND',
  SEND = 'SEND',
}

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit?: ((value: Action) => void) | null;
};

function GuestsNotificationModal({ open, onOpenChange, onSubmit }: Props) {
  const handleOnClick = useCallback(
    (action: Action) => () => {
      onSubmit?.(action);
      onOpenChange(false);
    },
    [onOpenChange, onSubmit]
  );

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        <Dialog.Content className="fixed px-6 py-3 -translate-x-1/2 -translate-y-1/2 bg-white rounded-md top-1/2 left-1/2 shadow-popover">
          <div className="flex flex-col gap-3">
            <button className="self-end" onClick={handleOnClick(Action.CANCEL)}>
              <Cross1Icon />
            </button>
            <ParagraphText>Would you like to send notification emails to guests?</ParagraphText>
            <div className="w-full border-t-2 border-gray-300" />
            <div className="flex gap-3 mb-2">
              <button
                className="text-gray-400 transition-opacity hover:opacity-70"
                onClick={handleOnClick(Action.CANCEL)}>
                CANCEL
              </button>
              <button
                className="ml-auto transition-opacity text-primary-500 hover:opacity-70"
                onClick={handleOnClick(Action.DONT_SEND)}>
                DON&apos;T SEND
              </button>
              <button
                className="transition-opacity text-primary-500 hover:opacity-70"
                onClick={handleOnClick(Action.SEND)}>
                SEND
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default function useGuestsNotificationModal() {
  const [open, setOpen] = useState(false);
  const onSubmitRef = useRef<((value: Action) => void) | null>(null);

  const showNotificationModal = useCallback(() => {
    return new Promise<Action>((resolve) => {
      onSubmitRef.current = resolve;
      setOpen(true);
    });
  }, []);

  const guestNotificationModal = useMemo(() => {
    return <GuestsNotificationModal open={open} onOpenChange={setOpen} onSubmit={onSubmitRef.current} />;
  }, [open]);

  return {
    showNotificationModal,
    guestNotificationModal,
  };
}
