import { TaskIcon } from '@/icons/TaskIcon';
import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { MdEmail } from 'react-icons/md';

import { SuggestionType } from './types';

export const iconByType = {
  [SuggestionType.TASK]: TaskIcon,
  [SuggestionType.EVENT]: EventIcon,
  [SuggestionType.NOTE]: NoteIcon,
  SCHEDULING_LINK: SchedulingLinkIcon,
  EMAIL: MdEmail,
};
