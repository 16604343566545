import * as clipboard from 'clipboard-polyfill';

export const copyToClipboard = (html: string, plainText: string) => {
  let item;
  if (plainText) {
    item = new clipboard.ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([plainText], { type: 'text/plain' }),
      'text/rtf': new Blob([plainText], { type: 'text/rtf' }),
    });
  } else {
    item = new clipboard.ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
    });
  }
  clipboard.write([item]).catch();
  // navigator.clipboard.writeText(plainText);
  return html;
};

export const copyToClipboardPlainText = (plainText: string) => {
  const item = new clipboard.ClipboardItem({
    'text/plain': new Blob([plainText], { type: 'text/plain' }),
    'text/rtf': new Blob([plainText], { type: 'text/rtf' }),
  })
  try {
    clipboard.write([item]);
  } catch (e) {
    throw new Error('Failed to copy to clipboard');
  }
  
};

export const buildLink = (href: string, text: string) =>
  `<a style="color: #64b5f6; font-size: inherit; text-decoration: underline" href="${href}">${text}</a>`;
