import { useState } from 'react';
import Button from '@/components/Button';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { RecurringInstance } from '@/models/EventModel';

type ConfirmationModalProps = {
  modalTitle?: string;
  modalText?: string;
  isARecurrentEvent?: boolean;
  onNo: () => void;
  onYes?: () => void;
  onYesRecurrent?: (picked: RecurringInstance) => void;
};
type FormValues = {
  picked: string;
};

export const ConfirmationModal = ({
  modalTitle,
  modalText,
  isARecurrentEvent,
  onNo,
  onYes,
  onYesRecurrent,
}: ConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const options = ['Delete this event', 'Delete this and following events', 'Delete all events'];

  const formik = useFormik<FormValues>({
    initialValues: {
      picked: 'Delete this event',
    },
    onSubmit: () => {},
  });

  const handleNo = () => {
    onNo();
    setIsOpen(false);
  };
  const handleYes = () => {
    onYes!();
    setIsOpen(false);
  };
  const handleYesRecurrent = () => {
    const picked = () => {
      switch (formik.values.picked) {
        case 'Delete this event':
          return 'THIS_INSTANCE';
        case 'Delete this and following events':
          return 'THIS_AND_FOLLOWING';
        case 'Delete all events':
          return 'ALL_INSTANCES';
        default:
          return 'THIS_INSTANCE';
      }
    };
    onYesRecurrent!(picked() as RecurringInstance);
    setIsOpen(false);
  };

  return (
    <>
      {isARecurrentEvent ? (
        <FormikProvider value={formik}>
          <div className={`fixed top-0 left-0 w-full h-full bg-black/50 z-50 ${isOpen ? 'flex' : 'hidden'}`}>
            <div className="overflow-hidden flex flex-col justify-between m-auto bg-white rounded-lg min-h-fit min-w-[500px]">
              <h1 className="w-full text-[30px] text-left px-[20px] pt-[20px] text-red-500 bg-white font-lexend mb-[20px]">
                {modalTitle ?? 'Caution!'}
              </h1>
              <div className="w-full bg-white text-start text-[20px] px-[20px] mt-[-20px]">
                <p>{modalText}</p>
              </div>
              <Form>
                <div
                  className="flex flex-col px-[20px] py-[10px] text-[14px] gap-1 mb-[8px]"
                  role="group"
                  aria-labelledby="my-radio-group">
                  {options.map((option) => (
                    <label key={option} className="flex items-center">
                      <Field className="mr-[10px] scale-125" type="radio" name="picked" value={option} />
                      {option}
                    </label>
                  ))}
                </div>
                <div className="flex flex-row w-full gap-4 m-0 bg-gray-100 h-[80px] items-center justify-end pr-[20px] ">
                  <Button variant="secondary" className="h-[48px]" onClick={handleNo}>
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    className="h-[48px] enabled:data-[variant=secondary]:border-red-500 enabled:data-[variant=secondary]:hover:border-gray-border enabled:data-[variant=secondary]:hover:bg-red-500 enabled:data-[variant=secondary]:hover:text-white flex gap-1 transition-all"
                    onClick={() => handleYesRecurrent()}>
                    Delete
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </FormikProvider>
      ) : (
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black/50 z-50 ${isOpen ? 'flex' : 'hidden'}`}
          onClick={handleNo}>
          <div className="overflow-hidden flex flex-col justify-between m-auto bg-white rounded-lg h-[240px] min-h-fit min-w-[728px]">
            <h1 className="w-full text-[30px] text-left px-[20px] pt-[20px] text-red-500 bg-white font-lexend mb-[20px]">
              {modalTitle ?? 'Caution!'}
            </h1>
            <div className="w-full bg-white text-start text-[20px] px-[20px] mt-[-20px]">
              <p>{modalText}</p>
            </div>
            <div className="flex flex-row w-full gap-4 m-0 bg-gray-100 h-[80px] items-center justify-end pr-[20px] ">
              <Button variant="secondary" className="h-[48px]" onClick={handleNo}>
                Cancel
              </Button>
              <Button
                variant="secondary"
                className="h-[48px] border-red-500 hover:border-gray-border hover:bg-red-500 hover:text-white flex gap-1"
                onClick={handleYes}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
