const textToNumber = (text: string): number | null => {
  const numbers: { [key: string]: number } = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
  };
  return numbers[text.toLowerCase()] || null;
};

export const parseDuration = (str: string): { display: string; valueInMinutes: number } | null => {
  const match = str.match(
    /(\d+|one|two|three|four|five|six|seven|eight|nine|ten)\s*(minutes?|mins?|hours?|hrs?|days?|d)/i
  );
  if (!match) return null;

  let value: number;
  if (isNaN(Number(match[1]))) {
    const numberFromText = textToNumber(match[1]);
    if (numberFromText === null) return null;
    value = numberFromText;
  } else {
    value = parseInt(match[1], 10);
  }

  let valueInMinutes = 0;
  switch (match[2].toLowerCase()) {
    case 'minute':
    case 'minutes':
    case 'min':
    case 'mins':
      valueInMinutes = value;
      break;
    case 'hour':
    case 'hours':
    case 'hr':
    case 'hrs':
      valueInMinutes = value * 60;
      break;
    case 'day':
    case 'days':
    case 'd':
      valueInMinutes = value * 1440;
      break;
  }

  return { display: match[0], valueInMinutes };
};
