import { useAccounts } from '@/hooks/useAccounts';
import { useSettings } from '@/hooks/useSettings';
import { useCreateZoomLink } from '@/hooks/useZoom';
import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { ConferencingSelectorModal } from '../ConferencingSelectorModal';
import { ConferencingData } from '@/models/EventArg';

export const conferencingOptions = {
  GOOGLE: 'Google Meet',
  ZOOM: 'Zoom',
  CUSTOM: 'Custom link',
  PHONE: 'Phone number',
};

export function useConferencingOptions() {
  const { accounts } = useAccounts();
  const { settings } = useSettings();
  const [openModalConferencing, setOpenModalConferencing] = useState(false);
  const { mutateAsync: createZoomLink } = useCreateZoomLink();

  const getConferencingDataFromOption = useCallback(
    async (option: string): Promise<ConferencingData | null> => {
      const hasZoomAccount = accounts?.some((account) => account.provider === 'ZOOM');

      switch (option) {
        case 'GOOGLE':
          return {
            createRequest: { requestId: cuid() },
          };

        case 'ZOOM': {
          if (!hasZoomAccount) {
            setOpenModalConferencing(true);
            break;
          }

          let zoomLink: string;

          try {
            zoomLink = await createZoomLink({});
          } catch {
            break;
          }

          return {
            conferenceSolution: {
              iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
              name: 'Zoom Meeting',
              key: { type: 'addOn' },
            },
            entryPoints: [
              {
                entryPointType: 'video',
                uri: zoomLink,
                label: zoomLink,
              },
            ],
          };
        }

        case 'CUSTOM':
          if (!settings?.customLink) {
            setOpenModalConferencing(true);
            break;
          }

          return {
            conferenceSolution: {
              name: 'Custom',
              iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
              key: { type: 'addOn' },
            },
            entryPoints: [
              {
                entryPointType: 'video',
                uri: settings.customLink,
                label: settings.customLink,
              },
            ],
          };
        case 'PHONE':
          if (!settings?.phoneNumber) {
            setOpenModalConferencing(true);
            break;
          }

          return {
            conferenceSolution: {
              name: 'Phone call',
              iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
              key: {
                type: 'addOn',
              },
            },
            entryPoints: [
              {
                entryPointType: 'phone',
                uri: `tel:${settings.phoneNumber}`,
                label: settings.phoneNumber,
              },
            ],
            notes: 'Call at ' + settings.phoneNumber,
          };
      }
      return null;
    },
    [accounts, createZoomLink, settings]
  );

  const conferencingSelectorModal = useMemo(() => {
    if (!openModalConferencing) return null;
    return <ConferencingSelectorModal onClose={() => setOpenModalConferencing(false)} />;
  }, [openModalConferencing]);

  return {
    conferencingSelectorModal,
    getConferencingDataFromOption,
  };
}
