import { useCallback, useMemo } from 'react';
import { httpDelete, httpGet, httpPost } from 'utils/smarty-api';
import { EmailThread, EmailThreads, ListEmailThreadInput, SendEmailParams } from '@/models/EmailModel';
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GmailThread } from 'utils/gmail';
import { DefaultError } from '@tanstack/query-core';

export const sendEmail = async (payload: SendEmailParams) =>
  await httpPost<void, void, SendEmailParams>(`/emails`, payload);

export const getEmailThread = async (threadId: string) => await httpGet<EmailThread>(`/emails/${threadId}`);

export const searchEmail = async (search?: string) =>
  await httpGet<GmailThread[]>('/emails/search', {
    params: {
      search,
    },
  });

export const useEmailThread = ({ threadId }: { threadId: string | null | undefined }) => {
  const { data: emailThread, isLoading } = useQuery({
    queryKey: ['EMAIL_THREAD', threadId],
    queryFn: async () => await httpGet<EmailThread>(`/emails/${threadId}`),
    gcTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    enabled: !!threadId,
  });
  return { emailThread, isLoading };
};

export const useGetEmailThread = () => {
  const client = useQueryClient();

  return useCallback(
    (threadId: string) => {
      return new Promise<EmailThread>((resolve, reject) => {
        const email = client.getQueryData<EmailThread>(['EMAIL', threadId]);
        if (email) {
          resolve(email);
          return;
        }

        httpGet<EmailThread>(`/emails/${threadId}`)
          .then((email) => {
            resolve(email);
            client.setQueryData(['EMAIL', threadId], email);
          })
          .catch(reject);
      });
    },
    [client]
  );
};

export const useArchiveThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status, isSuccess } = useMutation({
    mutationFn: async ({ threadId, labelIds }: { threadId: string; labelIds: string[] }) =>
      await httpPost<void, void, string[]>(`/emails/${threadId}/archive`, labelIds),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((list) => list[0][3] !== 'ALL_EMAILS')
        .filter((list) => list[1]?.pages?.filter((r) => r.results?.find((m) => m.id === variables.threadId)))
        .forEach((list) => {
          queryClient.setQueryData<(typeof list)[1]>(list[0], {
            ...list[1]!,
            pages: list[1]!.pages?.map((page) => {
              if (page.results.find((m) => m.id === variables.threadId)) {
                return {
                  ...page,
                  results: page.results.filter((result) => result.id !== variables.threadId),
                };
              } else {
                return page;
              }
            }),
          });
        });
      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: ['ARCHIVED'],
          })),
        });
      }
    },
  });
  return {
    archiveThread: mutateAsync,
    isLoadingArchiveThread: status === 'pending',
    isSuccessArchiveThread: isSuccess,
  };
};

export const useImportantThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status, isSuccess } = useMutation({
    mutationKey: ['EMAIL_THREAD_IMPORTANT'],
    mutationFn: async ({ threadId }: { threadId: string }) =>
      await httpPost<void, void, string[]>(`/emails/${threadId}/important`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((list) => list[0][3] !== 'ALL_EMAILS')
        .filter((list) => list[1]?.pages?.filter((r) => r.results?.find((m) => m.id === variables.threadId)))
        .forEach((list) => {
          queryClient.setQueryData<(typeof list)[1]>(list[0], {
            ...list[1]!,
            pages: list[1]!.pages?.map((page) => {
              if (page.results.find((m) => m.id === variables.threadId)) {
                return {
                  ...page,
                  results: page.results.filter((result) => result.id !== variables.threadId),
                };
              } else {
                return page;
              }
            }),
          });
        });
      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: [...m.labelIds!, 'IMPORTANT'],
          })),
        });
      }
    },
  });
  return {
    importantThread: mutateAsync,
    isLoadingImportantThread: status === 'pending',
    isSuccessImportantThread: isSuccess,
  };
};

export const useNotImportantThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['EMAIL_THREAD_NOT_IMPORTANT'],
    mutationFn: async ({ threadId }: { threadId: string }) => await httpDelete(`/emails/${threadId}/important`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((list) => list[1]?.pages?.filter((r) => r.results?.find((m) => m.id === variables.threadId)))
        .forEach((list) => {
          queryClient.setQueryData<(typeof list)[1]>(list[0], {
            ...list[1]!,
            pages: list[1]!.pages?.map((page) => {
              if (!page.results.some((m) => m.id === variables.threadId)) return page;

              // remove from IMPORTANT list
              if (list[0][1] === 'IMPORTANT') {
                return {
                  ...page,
                  results: page.results.filter((result) => result.id !== variables.threadId),
                };
              }

              // find the message and remove the label in place
              return {
                ...page,
                results: page.results.map((result) => {
                  if (result.id === variables.threadId) {
                    return {
                      ...result,
                      labelIds: result.labelIds?.filter((label) => label !== 'IMPORTANT'),
                    };
                  }
                  return result;
                }),
              };
            }),
          });
        });

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: m?.labelIds?.filter((label) => label !== 'IMPORTANT'),
          })),
        });
      }
    },
  });
  return {
    notImportanThread: mutateAsync,
    isLoadingNotImportant: status === 'pending',
  };
};

export const useUnarchiveThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['EMAIL_THREAD_UNARCHIVE'],
    mutationFn: async ({ threadId }: { threadId: string }) => await httpDelete(`/emails/${threadId}/archive`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((lista) => lista[0][3] !== 'ALL_EMAILS')
        .forEach((lista) => queryClient.invalidateQueries({ queryKey: lista[0] }));

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: [...m!.labelIds!, 'INBOX'],
          })),
        });
      }
    },
  });
  return {
    unarchiveThread: mutateAsync,
    isLoadingUnarchiveThread: status === 'pending',
  };
};

export const useStarThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['EMAIL_THREAD_STAR'],
    mutationFn: async ({ threadId }: { threadId: string }) =>
      await httpPost<void, void, void>(`/emails/${threadId}/star`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((lista) => lista[0][3] === 'STARRED')
        .forEach((lista) => queryClient.invalidateQueries({ queryKey: lista[0] }));

      allCachedLists.forEach((lista) => {
        queryClient.setQueryData<(typeof lista)[1]>(lista[0], {
          ...lista[1]!,
          pages: lista[1]!.pages?.map((page) => {
            if (page.results.find((m) => m.id === variables.threadId)) {
              return {
                ...page,
                results: page.results.map((result) => {
                  if (result.id === variables.threadId) {
                    return {
                      ...result,
                      labelIds: [...(result.labelIds ?? []), 'STARRED'],
                    };
                  }
                  return result;
                }),
              };
            } else {
              return page;
            }
          }),
        });
      });

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: [...m!.labelIds!, 'STARRED'],
          })),
        });
      }
    },
  });
  return { starThread: mutateAsync, isLoadingStarThread: status === 'pending' };
};

export const useUnstarThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['EMAIL_THREAD_UNSTAR'],
    mutationFn: async ({ threadId }: { threadId: string }) => await httpDelete(`/emails/${threadId}/star`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allCachedLists = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allCachedLists
        .filter((list) => list[1]?.pages?.filter((r) => r.results?.find((m) => m.id === variables.threadId)))
        .forEach((list) => {
          queryClient.setQueryData<(typeof list)[1]>(list[0], {
            ...list[1]!,
            pages: list[1]!.pages?.map((page) => {
              if (!page.results.some((m) => m.id === variables.threadId)) return page;

              // remove from STARRED list
              if (list[0][1] === 'STARRED') {
                return {
                  ...page,
                  results: page.results.filter((result) => result.id !== variables.threadId),
                };
              }

              // find the message and remove the label in place
              return {
                ...page,
                results: page.results.map((result) => {
                  if (result.id === variables.threadId) {
                    return {
                      ...result,
                      labelIds: result.labelIds?.filter((label) => label !== 'STARRED'),
                    };
                  }
                  return result;
                }),
              };
            }),
          });
        });

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: m?.labelIds?.filter((label) => label !== 'STARRED'),
          })),
        });
      }
    },
  });
  return {
    unstarThread: mutateAsync,
    isLoadingUnstar: status === 'pending',
  };
};

export const useSpamThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status: loadingSpamThreadStatus } = useMutation({
    mutationKey: ['EMAIL_THREAD_SPAM'],
    mutationFn: async ({ threadId, labelIds }: { threadId: string; labelIds: string[] }) =>
      await httpPost<void, void, string[]>(`/emails/${threadId}/spam`, labelIds),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allDetailListEmail = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allDetailListEmail
        .filter((list) => list[0][3] === 'SPAM')
        .forEach((list) => queryClient.invalidateQueries({ queryKey: list[0] }));

      allDetailListEmail.forEach((list) => {
        queryClient.setQueryData<(typeof list)[1]>(list[0], {
          ...list[1]!,
          pages: list[1]!.pages?.map((page) => {
            if (page.results.find((m) => m.id === variables.threadId)) {
              return {
                ...page,
                results: page.results.map((result) => {
                  if (result.id === variables.threadId) {
                    return {
                      ...result,
                      labelIds: [...(result.labelIds ?? []), 'SPAM'],
                    };
                  }
                  return result;
                }),
              };
            } else {
              return page;
            }
          }),
        });
      });

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: [...m!.labelIds!, 'SPAM'],
          })),
        });
      }
    },
  });
  return { spamThread: mutateAsync, isLoadingSpamThread: loadingSpamThreadStatus === 'pending' };
};

export const useUnspamThread = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['EMAIL_THREAD_UNSPAM'],
    mutationFn: async ({ threadId }: { threadId: string }) => await httpDelete(`/emails/${threadId}/spam`),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['EMAIL_THREAD_LIST'] });
      const allDetailListEmail = queryClient.getQueriesData<InfiniteData<EmailThreads> | undefined>({
        queryKey: ['EMAIL_THREAD_LIST'],
      });
      allDetailListEmail
        .filter((list) => list[1]?.pages?.filter((r) => r.results?.find((m) => m.id === variables.threadId)))
        .forEach((list) => {
          queryClient.setQueryData<(typeof list)[1]>(list[0], {
            ...list[1]!,
            pages: list[1]!.pages?.map((page) => {
              if (page.results.find((m) => m.id === variables.threadId)) {
                return {
                  ...page,
                  results: page.results.map((result) => {
                    if (result.id === variables.threadId) {
                      return {
                        ...result,
                        labelIds: result.labelIds?.filter((label) => label !== 'SPAM'),
                      };
                    }
                    return result;
                  }),
                };
              } else {
                return page;
              }
            }),
          });
        });

      const cachedMessage = queryClient.getQueryData<EmailThread>(['EMAIL_THREAD', variables.threadId]);
      if (cachedMessage) {
        queryClient.setQueryData(['EMAIL_THREAD', variables.threadId], {
          ...cachedMessage,
          messages: cachedMessage?.messages.map((m) => ({
            ...m,
            labelIds: m?.labelIds?.filter((label) => label !== 'SPAM'),
          })),
        });
      }
    },
  });
  return {
    unspamThread: mutateAsync,
    isLoadingUnspam: status === 'pending',
  };
};

export const deleteEmail = async (threadId: string) => await httpDelete(`/emails/${threadId}/trash`);

export const unTrashThread = async (threadId: string) => await httpPost(`/emails/${threadId}/trash`);

export const logEmailRead = async (trackingId: string, email: string) =>
  await httpGet(`/emails/track/${trackingId}?email=${email}`);

export const useEmailThreads = ({ labelId }: { labelId?: string }) => {
  const {
    data: emailThreads,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery<EmailThreads, DefaultError, InfiniteData<EmailThreads>, string[], string>({
    queryKey: ['EMAIL_THREAD_LIST', labelId??''],
    queryFn: ({ pageParam }) =>
      httpGet<EmailThreads>(`/emails`, {
        params: {
          labelId,
          maxResults: 100,
          pageToken: pageParam,
        } satisfies ListEmailThreadInput,
      }),
    gcTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    initialPageParam: '',
  });

  return {
    emailThreads,
    error,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    fetchNextPage,
  };
};

export const useContiguousEmailThreads = ({
  labelId,
  threadId,
}: {
  labelId: string;
  threadId: string | null | undefined;
}) => {
  const { emailThreads, isFetchingNextPage, fetchNextPage } = useEmailThreads({ labelId });

  return useMemo(() => {
    if (!threadId || !emailThreads?.pages.length) {
      return {
        previousThreadId: undefined,
        nextThreadId: undefined,
      };
    }

    let pageIndex = 0;
    while (pageIndex < emailThreads.pages.length) {
      const page = emailThreads.pages[pageIndex];

      const emailIndex = page.results.findIndex((r) => r.id === threadId);
      if (emailIndex < 0) {
        pageIndex++;
        continue;
      }

      let previousThreadId: string | undefined;
      if (emailIndex === 0) {
        if (pageIndex === 0) {
          previousThreadId = undefined;
        } else {
          previousThreadId = emailThreads.pages.at(pageIndex - 1)?.results?.at(-1)?.id ?? undefined;
        }
      } else {
        previousThreadId = page.results.at(emailIndex - 1)?.id ?? undefined;
      }

      let nextThreadId: string | undefined;
      if (emailIndex === page.results.length - 1) {
        if (!isFetchingNextPage) {
          void fetchNextPage();
        }

        if (pageIndex === emailThreads.pages.length - 1) {
          nextThreadId = undefined;
        } else {
          nextThreadId = emailThreads.pages.at(pageIndex + 1)?.results.at(0)?.id ?? undefined;
        }
      } else {
        nextThreadId = page.results.at(emailIndex + 1)?.id ?? undefined;
      }

      return {
        previousThreadId,
        nextThreadId,
      };
    }

    return {
      previousThreadId: undefined,
      nextThreadId: undefined,
    };
  }, [emailThreads?.pages, fetchNextPage, isFetchingNextPage, threadId]);
};

export const useSearchEmails = ({
  term,
  sort = 'ASC',
  search = '',
}: {
  term?: string;
  sort?: string;
  search?: string;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['SEARCH_EMAILS', term, sort, search],
    queryFn: () => searchEmail(search),
    enabled: true,
    refetchOnWindowFocus: false,
  });
  return {
    results: data ?? [],
    isLoadingContacts: isLoading,
  };
};
