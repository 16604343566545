import { memo, useContext, useMemo } from 'react';
import moment from 'moment-timezone';
import { isToday } from '@/utils/date';
import { CalendarViewTypesAtom } from '@/components/RightSidebar';
import { useGlobalSelectedDate } from '@/hooks/useGlobalSelectedDate';
import TimeZonesColumn from './TimeZonesColumn';
import TimeZonesHeader from './TimeZonesHeader';
import Column from './Column';
import ColumnHeader from './ColumnHeader';
import CalendarContext from '@/components/Calendar/context';
import { useAtomValue } from 'jotai';

import useDrop from './hooks/useDrop';
import useNavigation from './hooks/useNavigation';

function Calendar() {
  const { timeZone, forceSingleDay, editionMode } = useContext(CalendarContext);
  const { globalSelectedDate } = useGlobalSelectedDate();
  const calendarView = useAtomValue(CalendarViewTypesAtom);
  useDrop();
  useNavigation();

  const columnDates = useMemo(() => {
    const currentDate = moment.tz(globalSelectedDate, timeZone);
    switch (forceSingleDay ? 'SINGLE_DAY' : calendarView) {
      case 'SINGLE_DAY':
        return [currentDate.startOf('day')];
      case 'THREE_DAYS':
        return Array.from({ length: 3 }).map((_, i) => currentDate.clone().startOf('day').add(i, 'day'));
      case 'WORK_WEEK':
        return Array.from({ length: 5 }).map((_, i) =>
          currentDate
            .clone()
            .startOf('week')
            .add(i + 1, 'day')
        );
      case 'WEEK':
      default:
        return Array.from({ length: 7 }).map((_, i) => currentDate.clone().startOf('week').add(i, 'day'));
    }
  }, [calendarView, forceSingleDay, globalSelectedDate, timeZone]);

  if (editionMode === 'BOOKING')
    return (
      <div>
        {/* Fixed top */}
        <div className="sticky z-20 flex bg-white top-[99px]">
          <div className="flex w-full">
            <TimeZonesHeader className="md:mt-12" />
            <div className="flex-1 hidden grid-flow-col md:grid auto-cols-fr">
              {columnDates.map((date, index) => (
                <ColumnHeader key={index} date={date} isToday={isToday(date, timeZone)} />
              ))}
            </div>
          </div>
        </div>

        {/* Scrollable bottom */}
        <div className="flex flex-col select-none auto-rows-min" style={{ overflowY: 'auto' }}>
          <div className="flex flex-1">
            <TimeZonesColumn />
            {columnDates.map((date, index) => (
              <Column key={index} date={date} isToday={isToday(date, timeZone)} />
            ))}
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col overflow-hidden select-none auto-rows-min">
      <div className="flex mb-1">
        <TimeZonesHeader className="md:mt-12" />
        <div className="flex-1 hidden grid-flow-col md:grid auto-cols-fr">
          {columnDates.map((date, index) => (
            <ColumnHeader key={index} date={date} isToday={isToday(date, timeZone)} />
          ))}
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <TimeZonesColumn />
        {columnDates.map((date, index) => (
          <Column key={index} date={date} isToday={isToday(date, timeZone)} />
        ))}
      </div>
    </div>
  );
}

export default memo(Calendar);
