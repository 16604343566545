type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const EventIcon = ({ className, size = 16, color = '#8C8C8C' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6247 2.375H12.8211V0.96875H11.214V2.375H4.78544V0.96875H3.17829V2.375H2.37472C1.48276 2.375 0.775614 3.00781 0.775614 3.78125L0.767578 13.625C0.767578 14.3984 1.48276 15.0312 2.37472 15.0312H13.6247C14.5086 15.0312 15.2319 14.3984 15.2319 13.625V3.78125C15.2319 3.00781 14.5086 2.375 13.6247 2.375ZM13.6247 13.625H2.37472V6.59375H13.6247V13.625ZM13.6247 5.1875H2.37472V3.78125H13.6247V5.1875ZM7.99972 8.70312H12.0176V12.2188H7.99972V8.70312Z"
      fill={color}
    />
  </svg>
);
