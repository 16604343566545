import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { MdArrowBack, MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { useContacts } from '@/hooks/useContacts';
import { useMemo } from 'react';

export type ContactContext = {
  contactId?: string;
  contactName?: string;
  contactPicture?: string;
  editMode?: boolean;
};

interface PropsHeader {
  editMode?: boolean;
  contactId?: string;
  onClose?: () => void;
}

export const ContactEditorHeader = ({ contactId, onClose }: PropsHeader) => {
  const { contacts } = useContacts({});
  const { openLeftSidebar } = useLeftSidebar();

  const handleOpenLeftSidebarAllContacts = () => {
    openLeftSidebar({
      type: contactId ? 'CONTACT' : 'ALL_CONTACTS',
      context: contactId ? { contactId } : undefined,
    });
  };

  const handleCloseSidebar = () => {
    if (onClose) {
      onClose();
    }
  };

  const contact = useMemo(() => {
    if (!contacts) return null;
    const c = Array.isArray(contacts) ? contacts.find((t) => t.id === contactId) : undefined;
    return c;
  }, [contacts, contactId]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between border-b mt-2">
        <div className="flex gap-4 items-center">
          <ToolTip tooltip="Back">
            <button
              className={`rounded h-[32px] w-[32px] inline-flex items-center justify-center`}
              onClick={handleOpenLeftSidebarAllContacts}>
              <MdArrowBack size={24} className="text-[#5b5650] hover:text-[#000]" />
            </button>
          </ToolTip>
        </div>

        <ToolTip tooltip="Close sidebar">
          <button type="button" onClick={handleCloseSidebar}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="px-[16px]">
        <div className="flex items-center gap-2 py-[24px]">
          <ContactsIcon />
          <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter truncate max-w-[350px]">
            {contact?.name ?? contact?.email ?? 'New Contact'}
          </h1>
        </div>
      </div>
    </div>
  );
};
