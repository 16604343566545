import { useEffect, useRef } from 'react';

interface MessageIframeProps {
    htmlContent: string;
}

export const MessageIframe = ({ htmlContent }: MessageIframeProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe || !iframe.contentDocument || !iframe.contentDocument.body) return;

        iframe.contentDocument.body.innerHTML = htmlContent;
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            if (entry.target === iframe.contentDocument?.body) {
              iframe.style.height = `${entry.target.scrollHeight}px`;
            }
          }
        });

        resizeObserver.observe(iframe.contentDocument.body);

        return () => {
          resizeObserver.disconnect();
        };
    }, [htmlContent]);

    return (
      <iframe
        ref={iframeRef}
        frameBorder="0"
        width="100%"
        style={{ minHeight: "100px" }}
        sandbox="allow-same-origin allow-scripts"
      />
    );
};
