import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { SettingsIcon } from '@/icons/SettingsIcon';
import { TaskIcon } from '@/icons/TaskIcon';
import { Bullet } from '@/icons/Bullet';

import type { SearchOption } from './types';

const CREATE_OPTIONS: SearchOption[] = [
  {
    title: 'New event',
    id: 'EVENT_EDITOR',
    Icon: EventIcon,
    category: 'Create',
  },
  {
    title: 'New task',
    id: 'TASK_EDITOR',
    Icon: TaskIcon,
    category: 'Create',
  },
  {
    title: 'New note',
    id: 'NOTE_EDITOR',
    Icon: NoteIcon,
    category: 'Create',
  },
  {
    title: 'New scheduling link',
    id: 'SCHEDULING_LINK_EDITOR',
    Icon: SchedulingLinkIcon,
    category: 'Create',
  },
];

const NAVIGATION_OPTIONS: SearchOption[] = [
  {
    title: 'To-dos',
    id: 'UNSCHEDULED',
    Icon: Bullet,
    category: 'Navigate',
  },
  {
    title: 'Today',
    id: 'SINGLE_DAY_CALENDAR',
    Icon: Bullet,
    category: 'Navigate',
  },
];

const SETTINGS_OPTIONS: SearchOption[] = [
  {
    title: 'Open settings',
    id: '/settings',
    Icon: SettingsIcon,
    category: 'Settings',
  },
  {
    title: 'Keyboard shortcuts',
    id: 'SHORTCUTS',
    Icon: SettingsIcon,
    category: 'Settings',
  },
];

export const ITEM_OPTIONS = [
  {
    title: 'Save',
    id: 'SAVE',
    Icon: Bullet,
    category: 'Update selected',
  },
  {
    title: 'Open panel',
    id: 'OPEN_PANEL',
    Icon: Bullet,
    category: 'Update selected',
  },
  {
    title: 'Create reference link',
    id: 'CREATE_REFERENCE_LINK',
    Icon: Bullet,
    category: 'Update selected',
  },
];

export const ALL_OPTIONS = [...CREATE_OPTIONS, ...NAVIGATION_OPTIONS, ...SETTINGS_OPTIONS];
