import Pusher from 'pusher-js';

let pusherInstance: Pusher | null = null;

export const getPusherInstance = () => {
  if (!pusherInstance) {
    pusherInstance = new Pusher(`${process.env.NEXT_PUBLIC_PUSHER_KEY}`, {
      cluster: `${process.env.NEXT_PUBLIC_PUSHER_CLUSTER}`
    });
  }
  return pusherInstance;
};

export const createChannelName = (username: string) => {
  const envPrefix = process.env.NEXT_PUBLIC_URL?.replace(/[^a-zA-Z0-9]/g, '_');
  return `${envPrefix}-chat-channel-${username}`;
};
