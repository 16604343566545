import { FeedbackIcon } from '@/icons/FeedbackIcon';
import Button from '../Button';
import Link from 'next/link';
import { FeedbackWidget } from '../FeedbackWidget';
import { useSegment } from '@/components/useSegment';

export const Feedback = () => {
  const { trackEvent } = useSegment();
  return (
    <div className="flex flex-col gap-6 overflow-auto">
      <div className="flex flex-row gap-2.5 mx-4 items-center">
        <FeedbackIcon size={24} />
        <span className="text-2xl font-semibold">Send feedback</span>
      </div>
      <div className="flex flex-col gap-12 m-6">
        <div className="flex flex-col gap-4">
          <div className="text-sm font-medium tracking-tight">
            Have questions? Visit our Help center for documentation, tutorials, and other resources.
          </div>
          <Link href="https://help.smarty.ai/" target="_blank" passHref>
            <Button
              variant="primary"
              className="w-full"
              onClick={() => {
                trackEvent('Feedback CTA Clicked', {
                  source: 'Feedback Modal',
                  cta: 'Help Center',
                });
              }}>
              Help center
            </Button>
          </Link>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-sm font-medium tracking-tight">
            Don’t see what you’re looking for? Let’s us know your feedback, we value your input!
          </div>
          <FeedbackWidget />
        </div>
      </div>
    </div>
  );
};
