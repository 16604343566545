type Props = {
  className?: string;
};

export const FabIcon = ({ className }: Props) => (
  <svg className={className} width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.0423 12.7943H22.959V22.9609H12.7923V28.0443H22.959V38.2109H28.0423V28.0443H38.209V22.9609H28.0423V12.7943ZM25.5006 0.0859375C11.4707 0.0859375 0.0839844 11.4726 0.0839844 25.5026C0.0839844 39.5326 11.4707 50.9193 25.5006 50.9193C39.5306 50.9193 50.9173 39.5326 50.9173 25.5026C50.9173 11.4726 39.5306 0.0859375 25.5006 0.0859375ZM25.5006 45.8359C14.2919 45.8359 5.16732 36.7114 5.16732 25.5026C5.16732 14.2939 14.2919 5.16927 25.5006 5.16927C36.7094 5.16927 45.834 14.2939 45.834 25.5026C45.834 36.7114 36.7094 45.8359 25.5006 45.8359Z"
      fill="#BBBBBB"
    />
  </svg>
);
