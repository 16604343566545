import { useEffect, useMemo, useState } from 'react';
import { useProjects } from '@/hooks/useProjects';
import { useCombobox, useMultipleSelection } from 'downshift';
import { Label } from '@radix-ui/react-label';
import { cn } from 'ui/cn';
import { MdCancel as RemoveButton, MdKeyboardArrowDown as ArrowDown } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { ToastGeneric } from '@/components/ToastGeneric';

type Props = {
  projectIds?: string[];
  disabled?: boolean;
  onChange: (projectIds: string[]) => void;
};

export const AddProject = ({ projectIds, disabled, onChange }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [inputData, setInputData] = useState<string>('');
  const { projects, createProject } = useProjects({});
  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems: projects?.filter((project) => projectIds?.includes(project.id)).map((project) => project.id) ?? [],
    onStateChange({ selectedItems: newlySelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          onChange?.(newlySelectedItems ?? []);
          break;
        default:
          break;
      }
    },
  });

  const items = useMemo(() => {
    return projects.filter((p) => !projectIds?.includes(p.id) && p.name.includes(inputData));
  }, [projectIds, projects, inputData]);

  const {
    isOpen: uncontrolledIsOpen, // hack for cyclical reference of isOpen
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: items ?? [],
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: state.highlightedIndex, // with the first option highlighted.
          };
        }
        case useCombobox.stateChangeTypes.InputBlur:
        default:
          return changes;
      }
    },
    async onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      if (!newSelectedItem) return;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          // create new project
          if (inputData === '') return;
          const newProject = await createProject({
            name: inputData,
            schedulingLinksIds: [],
            tasksIds: [],
            eventIds: [],
            parentProjectId: '',
          });
          onChange?.([...(projectIds ?? []), newProject.id]);
          setInputData('');
          toast(<ToastGeneric title="Project created successfully" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }
        case useCombobox.stateChangeTypes.ItemClick: {
          if (newSelectedItem) {
            onChange?.([...(projectIds ?? []), newSelectedItem.id]);
            setInputData('');
          }
          break;
        }
        case useCombobox.stateChangeTypes.InputChange: {
          setInputData(newInputValue ?? '');
          break;
        }
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setOpen(uncontrolledIsOpen);
  }, [uncontrolledIsOpen]);

  if (!projects) return null;

  return (
    <div className="relative w-full">
      <div className="flex flex-col">
        <div className="flex flex-wrap items-center gap-[8px] bg-white rounded-lg">
          {!disabled
            ? projectIds?.map((projectId, index) => {
                return (
                  <span
                    className="flex items-center p-[4px] border rounded-md text-sm shadow-sm mb-2"
                    key={index}
                    {...getSelectedItemProps({
                      selectedItem: projectId,
                      index,
                    })}>
                    {projects.find((p) => p.id === projectId)?.name}
                    <span
                      className="mx-1 rounded-full cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSelectedItem(projectId);
                      }}>
                      <RemoveButton color="#D3D3D3" />
                    </span>
                  </span>
                );
              })
            : null}
        </div>

        <div className="flex gap-[4px] border rounded-lg p-[6px] relative focus-within:border-[#279AF1]">
          <Label
            className="text-[8px] absolute -top-1.5 left-4 leading-[12px] bg-white text-gray-border px-[4px]"
            {...getLabelProps()}>
            Project Tags
          </Label>
          <input
            placeholder="Tag projects"
            className="w-full relative text-[14px] min-h-[32px] pl-[11px] border-transparent focus:outline-none font-medium text-black placeholder:text-gray-border"
            {...getInputProps(
              getDropdownProps({ preventKeyAction: isOpen, value: inputData ?? '', key: selectedItem?.id ?? '' }),
              {
                suppressRefError: true,
              }
            )}
            disabled={disabled}
          />
          <button aria-label="toggle menu" className="px-[8px]" type="button" {...getToggleButtonProps()}>
            <ArrowDown />
          </button>
        </div>
      </div>
      <ul
        className={cn(
          'w-full bg-white mt-[4px] shadow-lg max-h-[320px] overflow-scroll overflow-x-hidden p-0 rounded-lg absolute z-10',
          !(isOpen && items?.length) && 'hidden'
        )}
        {...getMenuProps()}>
        {isOpen &&
          !disabled &&
          items?.map((item, index) => {
            const isItemSelected = projectIds?.includes(item.id);

            return (
              <button
                className={cn(
                  'p-3 flex flex-col overflow-x-hidden w-full',
                  highlightedIndex === index && 'bg-gray-200',
                  selectedItem === item && 'font-bold',
                  isItemSelected && 'cursor-not-allowed bg-gray-50 text-gray-500'
                )}
                type="button"
                disabled={isItemSelected}
                key={`${item?.id}-${index}`}
                {...getItemProps({ item, index })}>
                <span className="text-sm capitalize">{item?.name}</span>
              </button>
            );
          })}
      </ul>
    </div>
  );
};
