import { ReactNode } from 'react';

export function ToastGeneric({ title, icon }: { title: string; icon?: ReactNode }) {
  return (
    <div className="text-sm flex items-center text-white bg-black w-full">
      {icon}
      <div className="flex flex-wrap items-center">{title}</div>
    </div>
  );
}
