import { httpPost } from 'utils/smarty-api';
import { ZoomLinkArg, ZoomLinkResponse } from '@/models/ZoomModel';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const buildZoomAuthorizeLink = () => {
  const url = new URL('https://zoom.us/oauth/authorize');
  url.searchParams.set('response_type', 'code');
  url.searchParams.set('client_id', process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID);
  url.searchParams.set('redirect_uri', `${process.env.NEXT_PUBLIC_URL}/api/apps/zoom/callback`);
  return url.href;
};

export const useCreateZoomLink = () => {
  const queryClient = useQueryClient();

  return useMutation<string, void, ZoomLinkArg | undefined>({
    mutationKey: ['CREATE_ZOOM_LINK'],
    mutationFn: async (data) => {
      const { url } = await httpPost<void, ZoomLinkResponse, ZoomLinkArg>('/apps/zoom/links', data);
      return url;
    },
    onError: async () => {
      await queryClient.invalidateQueries({ queryKey: ['ACCOUNTS'] });
    },
  });
};
