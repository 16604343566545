import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

export enum Options {
  TYPES = 'types',
  TASKS = 'tasks',
  SCHEDULING_LINKS = 'schedulingLinks',
  EVENTS = 'events',
  NOTES = 'notes',
  PROJECTS = 'projects',
  STATUSES = 'statuses',
  PRIORITIES = 'priorities',
  CONTACTS = 'contacts',
  CREATED_DATE = 'createdDate',
  DEADLINE_DATE = 'deadlineDate',
  SCHEDULED_DATE = 'scheduledDate',
}

type Item = { id: string; name: string };

export type Filter = {
  [key in Options]: Item[];
};

const filterAtom = atom<Filter>({
  types: [],
  tasks: [],
  schedulingLinks: [],
  events: [],
  notes: [],
  projects: [],
  statuses: [],
  priorities: [],
  contacts: [],
  createdDate: [],
  deadlineDate: [],
  scheduledDate: [],
});

export default function useFilter() {
  const [filter, _setFilter] = useAtom(filterAtom);

  const setFilter = useCallback(
    (type: Options, item: Item) => {
      _setFilter((prev) => {
        const items = prev[type];

        if (items.some(({ id }) => id === item.id)) {
          return {
            ...prev,
            [type]: items.filter(({ id }) => id !== item.id),
          };
        }

        return { ...prev, [type]: [...items, { id: item.id, name: item.name }] };
      });
    },
    [_setFilter]
  );

  const deleteFilter = useCallback(
    (type: string) => {
      _setFilter((prev) => ({ ...prev, [type]: [] }));
    },
    [_setFilter]
  );

  return { filter, setFilter, deleteFilter };
}
