import { useCallback, useMemo } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { Organization } from '@/models/ContactArg';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { DateObject, DatePicker } from '@/components/DatePicker';
import Switch from '@/components/Switch';
import { PlacesAutocomplete } from '@/components/PlacesAutocomplete';

type Props = {
  organizations: Organization[];
  setNewOrganization: (org: Organization[]) => void;
};

export default function OrganizationSection({ organizations, setNewOrganization }: Props) {
  const handleOnClickAddOrganization = useCallback(() => {
    setNewOrganization([
      ...organizations,
      {
        name: '',
        current: false,
        domain: '',
        endDate: new Date() ?? undefined,
        startDate: new Date() ?? undefined,
        id: '',
        location: '',
        position: '',
        type: 'FULL_TIME',
        metadata: '',
      },
    ]);
  }, [setNewOrganization, organizations]);

  const handleChangeStartDate = useCallback(
    (index: number) => (value: DateObject) => {
      const date = value.toDate();
      const newStart = [...organizations];
      newStart[index].startDate = date;
      setNewOrganization(newStart);
    },
    [setNewOrganization, organizations]
  );

  const handleChangeEndDate = useCallback(
    (index: number) => (value: DateObject) => {
      const date = value.toDate();
      const newEnd = [...organizations];
      newEnd[index].endDate = date;
      setNewOrganization(newEnd);
    },
    [setNewOrganization, organizations]
  );

  const handleIsCurrent = useCallback(
    (index: number) => (value: boolean) => {
      const newIsCurrent = [...organizations];
      newIsCurrent[index].current = value;
      newIsCurrent[index].endDate = value ? new Date() : null;
      setNewOrganization(newIsCurrent);
    },
    [setNewOrganization, organizations]
  );

  const renderedOrganizations = useMemo(
    () =>
      organizations.map((org, index) => (
        <div key={index} className="flex items-center gap-[10px] px-[4px]">
          <div className="flex flex-col gap-[10px] w-full">
            <input
              className="w-full text-[14px] font-inter p-2 border border-gray-scheduled rounded select-none"
              placeholder="Name"
              type="text"
              value={org.name!}
              onChange={(e) => {
                const newOrganizationTitle = [...organizations];
                newOrganizationTitle[index].name = e.target.value;
                setNewOrganization(newOrganizationTitle);
              }}
            />
            <input
              className="w-full text-[14px] font-inter p-2 border border-gray-scheduled rounded select-none"
              placeholder="Position"
              type="text"
              value={org.position!}
              onChange={(e) => {
                const newOrganizationPosition = [...organizations];
                newOrganizationPosition[index].position = e.target.value;
                setNewOrganization(newOrganizationPosition);
              }}
            />
            <textarea
              className="w-full text-[14px] font-inter p-2 border border-gray-scheduled rounded select-none"
              placeholder="Description"
              rows={5}
              value={org.metadata!}
              onChange={(e) => {
                const newOccupationDescription = [...organizations];
                newOccupationDescription[index].metadata = e.target.value;
                setNewOrganization(newOccupationDescription);
              }}
            />
            <DatePicker
              label="Start"
              value={org.startDate}
              maxDate={new Date()}
              onChange={handleChangeStartDate(index)}
            />
            <DatePicker
              label="End"
              value={org.endDate}
              disabled={org.current ?? false}
              minDate={org.startDate ?? new Date()}
              onChange={handleChangeEndDate(index)}
            />
            <Switch label="Current job?" value={!!org.current} onChange={handleIsCurrent(index)} />
            <input
              className="w-full text-[14px] font-inter p-2 border border-gray-scheduled rounded select-none"
              placeholder="Url"
              type="text"
              value={org.domain!}
              onChange={(e) => {
                const newDomain = [...organizations];
                newDomain[index].domain = e.target.value;
                setNewOrganization(newDomain);
              }}
            />
            <PlacesAutocomplete
              mode="full-address"
              name="Location"
              labelInput="Location"
              onChange={(e) => {
                const newLocation = [...organizations];
                newLocation[index].location = e;
                setNewOrganization(newLocation);
              }}
            />
          </div>
          <div>
            <button onClick={() => setNewOrganization(organizations.filter((_, i) => i !== index))}>
              <DeleteIcon />
            </button>
          </div>
        </div>
      )),
    [handleChangeEndDate, handleChangeStartDate, handleIsCurrent, organizations, setNewOrganization]
  );

  return (
    <div className="flex flex-col gap-[12px]">
      {renderedOrganizations}
      <button
        type="button"
        className="flex items-center text-[14px] gap-[12px] underline"
        onClick={handleOnClickAddOrganization}>
        <PlusIcon fontSize={8} />
        Add new organization
      </button>
    </div>
  );
}
