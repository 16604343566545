import moment, { MomentInput, unitOfTime } from 'moment-timezone';
import { RawTimeZone, rawTimeZones } from '@vvo/tzdb';

export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);

export type DateInterval = {
  start: Date;
  end: Date;
  allDay?: boolean;
};

export type RecurringInterval = {
  startTime: number;
  endTime: number;
  dayOfWeek: number;
};

export const serializeDateIntervals = (intervals?: DateInterval[] | null | undefined): string | undefined => {
  if (!intervals) return undefined;
  return intervals.map((interval) => [interval.start.toISOString(), interval.end.toISOString()].join('~')).join(',');
};

export const deserializeDateIntervals = (s?: string | null | undefined): DateInterval[] | undefined => {
  if (!s) return undefined;
  return s.split(',').map((interval) => {
    const [start, end] = interval.split('~').map((date) => moment(date).toDate());
    return { start, end };
  });
};

export const findTzDbZone = (value: string): RawTimeZone | undefined => {
  // if (value.startsWith('Etc/')) {
  //   switch (value) {
  //     case 'Etc/GMT+12':
  //       value = 'Pacific/Niue';
  //       break;
  //     case 'Etc/GMT+11':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+10':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+9':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+8':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+7':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+6':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+5':
  //       value = '';
  //       break;
  //     case 'Etc/GMT+4':
  //       value = 'Eastern Time';
  //       break;
  //     case 'Etc/GMT+3':
  //       value = 'America/Argentina/Buenos_Aires\t';
  //       break;
  //     case 'Etc/GMT+2':
  //       value = 'Atlantic/Cape_Verde';
  //       break;
  //     case 'Etc/GMT+1':
  //       value = 'Africa/Dakar';
  //       break;
  //     case 'Etc/GMT':
  //     case 'Etc/GMT+0':
  //     case 'Etc/GMT-0':
  //       value = 'UTC';
  //       break;
  //     case 'Etc/GMT-1':
  //       value = 'Europe/Paris';
  //       break;
  //     case 'Etc/GMT-2':
  //       value = 'Europe/Istanbul';
  //       break;
  //     case 'Etc/GMT-3':
  //       value = 'Asia/Dubai';
  //       break;
  //     case 'Etc/GMT-4':
  //       value = 'Europe/Moscow';
  //       break;
  //     case 'Etc/GMT-5':
  //       value = 'Asia/Calcutta';
  //       break;
  //     case 'Etc/GMT-6':
  //       value = 'Asia/Bangkok';
  //       break;
  //     case 'Etc/GMT-7':
  //       value = 'Asia/Macau';
  //       break;
  //     case 'Etc/GMT-8':
  //       value = 'Asia/Tokyo';
  //       break;
  //     case 'Etc/GMT-9':
  //       value = 'Japan'; // Pacific/Palau
  //       break;
  //     case 'Etc/GMT-10':
  //       value = 'Pacific/Guam';
  //       break;
  //     case 'Etc/GMT-11':
  //       value = 'Asia/Vladivostok';
  //       break;
  //     case 'Etc/GMT-12':
  //       value = 'Pacific/Auckland';
  //       break;
  //     case 'Etc/GMT-12.75':
  //       value = 'Pacific/Chatham';
  //       break;
  //     case 'Etc/GMT-13':
  //       value = 'Pacific/Apia';
  //       break;
  //     case 'Etc/GMT-14':
  //       value = 'Pacific/Kiritimati';
  //       break;
  //   }
  // }

  let tzDbZone = rawTimeZones.find((rtz) => rtz.name === value || rtz.group.includes(value));
  if (!tzDbZone) {
    // fix for America/Buenos_Aires → America/Argentina/Buenos_Aires
    const splitSlashesThenGetFirstAndLast = (s: string) => {
      const parts = s.split('/');
      return `${parts[0]}/${parts[parts.length - 1]}`;
    };
    tzDbZone = rawTimeZones.find(
      (rtz) =>
        splitSlashesThenGetFirstAndLast(rtz.name) === value ||
        rtz.group.some((tzg) => splitSlashesThenGetFirstAndLast(tzg) === value)
    );
  }
  // Etc/GTM+3
  if (!tzDbZone) {
    const offset = moment.tz(value).utcOffset();
    tzDbZone = rawTimeZones.filter((rtz) => rtz.rawOffsetInMinutes === offset).find(() => true);
  }
  return tzDbZone;
};

export const getBrowserTimeZone = (): string => {
  const rawTimeZone = findTzDbZone(moment.tz.guess(true));
  if (!rawTimeZone) return '';
  return rawTimeZone.name;
};

const removeOClock = (s: string) => {
  return s.replace(/(:00)/g, '');
};

export const formatDate = ({
  timeZone,
  allDay,
  date,
}: {
  timeZone: string;
  allDay?: boolean;
  date: MomentInput;
}): string => {
  const now = moment.tz(timeZone);
  const d = moment.tz(date, timeZone);
  if (d.isSame(now, 'day')) {
    return allDay ? 'Today' : removeOClock(`Today, ${d.format('h:mma')}`);
  }
  if (d.isSame(now.clone().add(1, 'days'), 'day')) {
    return allDay ? 'Tomorrow' : removeOClock(`Tomorrow, ${d.format('h:mma')}`);
  }
  if (d.isSame(now.clone().add(-1, 'days'), 'day')) {
    return allDay ? 'Yesterday' : removeOClock(`Yesterday, ${d.format('h:mma')}`);
  }
  if (d.isSame(now, 'week')) {
    if (allDay) {
      if (d.isBefore()) return `Last ${d?.format('dddd')}`;
      return d.format('dddd');
    }
    if (d.isBefore()) return removeOClock(`Last ${d?.format('dddd, h:mma')}`);
    return removeOClock(d.format('dddd, h:mma'));
  }
  if (d.isSame(now, 'month')) {
    return allDay ? d.format('dddd Do') : removeOClock(d.format('dddd Do, h:mma'));
  }
  if (d.isSame(now, 'year')) {
    return allDay ? d.format('dddd, D MMM') : removeOClock(d.format('D MMM, h:mma'));
  }
  return allDay ? d.format('dddd, D MMM YYYY') : removeOClock(d.format('D MMM YYYY, h:mma'));
};

export const formatDateDecorator = ({
  timeZone,
  allDay,
  date,
}: {
  timeZone: string;
  allDay?: boolean;
  date: MomentInput;
}): string => {
  const now = moment.tz(timeZone);
  const d = moment.tz(date, timeZone);
  const isBeforeToday = d.isBefore(now, 'day');

  if (allDay && isBeforeToday) return `${d.format('MMM DD')}`;

  if (d.isSame(now, 'day')) {
    return allDay ? 'All day' : removeOClock(`${d.format('h:mma')}`);
  }
  if (d.isSame(now.clone().add(1, 'days'), 'day')) {
    return allDay ? 'All day' : removeOClock(`${d.format('h:mma')}`);
  }
  if (d.isSame(now.clone().add(-1, 'days'), 'day')) {
    return allDay ? 'All day' : removeOClock(`${d.format('h:mma')}`);
  }
  if (d.isSame(now, 'week')) {
    if (allDay) {
      if (d.isBefore()) return `Last ${d?.format('dddd')}`;
      return 'All day';
    }
    if (d.isBefore()) return removeOClock(`${d?.format('h:mma')}`);
    return removeOClock(d.format('h:mma'));
  }
  if (d.isSame(now, 'month')) {
    return allDay ? 'All day' : removeOClock(d.format('h:mma'));
  }
  if (d.isSame(now, 'year')) {
    return allDay ? 'All day' : removeOClock(d.format('h:mma'));
  }
  return allDay ? 'All day' : removeOClock(d.format('h:mma'));
};

export const formatDateRangeTimeSimple = (start: Date, end: Date, showTime = true, onlyShowStart = false) => {
  const currentYear = moment().year();
  const startFormat = moment(start).year() === currentYear ? 'ddd, MMM D' : 'ddd, MMM D YYYY';
  const endFormat = moment(end).year() === currentYear ? (moment(end).isSame(start, 'day') ? 'h:mm A' : 'ddd, MMM D') : 'ddd, MMM D YYYY z';

  const startDate = moment(start).format(showTime ? `${startFormat}, h:mm A` : startFormat);
  const endDate = moment(end).format(showTime ? `${endFormat}, h:mm A` : endFormat);

  if (onlyShowStart) {
    return startDate;
  }

  return `${startDate} - ${endDate}`;
};

export const formatDateRange = ({
  timeZone,
  allDay,
  start: rawStart,
  end: rawEnd,
  isCompleted,
}: {
  timeZone: string;
  allDay?: boolean;
  start: MomentInput;
  end?: MomentInput | null | undefined;
  isCompleted?: boolean;
}): string => {
  if (!rawEnd) return formatDate({ timeZone, allDay, date: rawStart });

  const now = moment.tz(timeZone);

  const start = moment.tz(rawStart, timeZone);
  const end = moment.tz(rawEnd, timeZone);

  const startAndEndAreTheSameDay = start.isSame(end, 'day');

  if (isCompleted) {
    return removeOClock(`${start.format('MM/DD h:mma')} - ${end.format('MM/DD h:mma')}`);
  }

  if (startAndEndAreTheSameDay) {
    if (allDay) {
      if (start.isSame(now, 'day')) {
        return 'Today';
      }
      if (start.isSame(now.clone().add(1, 'days'), 'day')) {
        return 'Tomorrow';
      }
      if (start.isSame(now, 'week')) {
        if (start.isBefore()) return `Last ${start.format('dddd')}`;
        return start.format('dddd');
      }
      if (start.isSame(now, 'month')) {
        return start.format('dddd Do');
      }
      if (start.isSame(now, 'year')) {
        return start.format('D MMM');
      }
      return start.format('D MMM YYYY');
    }

    if (start.isSame(now, 'day')) {
      return removeOClock(`Today, ${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now.clone().add(1, 'days'), 'day')) {
      return removeOClock(`Tomorrow, ${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now.clone().add(-1, 'days'), 'day')) {
      return removeOClock(`Yesterday, ${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'week')) {
      if (start.isBefore()) return removeOClock(`Last ${start.format('dddd, h:mma')} - ${end.format('h:mma')}`);
      return removeOClock(`${start.format('dddd, h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'month')) {
      return removeOClock(`${start.format('dddd Do, h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'year')) {
      return removeOClock(`${start.format('D MMM, h:mma')} - ${end.format('h:mma')}`);
    }
    return removeOClock(`${start.format('D MMM YYYY, h:mma')} - ${end.format('h:mma')}`);
  }

  if (!start.isSame(end, 'year') || !start.isSame(now, 'year')) {
    if (allDay) {
      // 29 Dec 2019-4 Jan 2020
      return removeOClock(`${start.format('D MMM YYYY')} - ${end.format('D MMM YYYY')}`);
    }
    // 29 Dec 2019 2pm-4 Jan 2020 4am
    return removeOClock(`${start.format('D MMM YYYY h:mma')} - ${end.format('D MMM YYYY h:mma')}`);
  }

  if (!start.isSame(end, 'month')) {
    // 26 Jan-1 Feb 2020
    return removeOClock(`${start.format('D MMM')} - ${end.format('D MMM')}`);
  }

  // 5-11 January 2020
  return removeOClock(`${start.format('D')} - ${end.format('D MMMM YYYY')}`);
};

export const formatDateRangeDecorator = ({
  timeZone,
  allDay,
  start: rawStart,
  end: rawEnd,
  itemType,
  isCompleted,
  forceShowDate,
}: {
  timeZone: string;
  allDay?: boolean;
  isCompleted?: boolean;
  start: MomentInput;
  end?: MomentInput | null | undefined;
  itemType?: string;
  forceShowDate?: boolean;
}): string => {
  if (!rawEnd) {
    if (forceShowDate) {
      return formatDate({ timeZone, allDay, date: rawStart });
    }
    return formatDateDecorator({ timeZone, allDay, date: rawStart });
  }

  const now = moment.tz(timeZone);
  const start = moment.tz(rawStart, timeZone);
  const end = moment.tz(rawEnd, timeZone);
  const isBeforeToday = start.isBefore(now, 'day');
  const moreThanOneDay = !!rawEnd && end?.diff(start, 'hours') > 24;

  const startAndEndAreTheSameDay = start.isSame(end, 'day');

  if (allDay && !isBeforeToday && !moreThanOneDay) {
    if (forceShowDate) {
      return formatDate({ timeZone, allDay, date: rawStart });
    }
    return 'All day';
  }

  if (forceShowDate) {
    return formatDateRange({ timeZone, allDay, start: rawStart, end: rawEnd });
  }

  if (allDay && (!isBeforeToday || moreThanOneDay) && !startAndEndAreTheSameDay)
    return removeOClock(`${start.format('MMM DD')} - ${end.format('MMM DD')}`);
  if (allDay && isBeforeToday) return `${start.format('MMM DD')}`;

  if (isCompleted) {
    return removeOClock(`${start.format('MMM DD h:mma')} - ${end.format('MMM DD h:mma')}`);
  }

  if (startAndEndAreTheSameDay && itemType !== 'SCHEDULING_LINK') {
    if (isBeforeToday) {
      return `${removeOClock(start.format('MMM DD h:mma'))} - ${end.format('h:mma')}`;
    }
    if (start.isSame(now, 'day')) {
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now.clone().add(1, 'days'), 'day')) {
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now.clone().add(-1, 'days'), 'day')) {
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'week')) {
      if (start.isBefore()) return removeOClock(`Last ${start.format('dddd h:mma')} - ${end.format('h:mma')}`);
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'month')) {
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    if (start.isSame(now, 'year')) {
      return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
    }
    return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
  }

  if (!start.isSame(end, 'year') || !start.isSame(now, 'year')) {
    // 29 Dec 2019 2pm-4 Jan 2020 4am
    return removeOClock(`${start.format('h:mma')} - ${end.format('h:mma')}`);
  }

  function format(date: moment.Moment) {
    if (Math.abs(date.date() - now.date()) > 1) return date.format('MMM DD h:mma');
    return formatDate({ timeZone, date });
  }

  if (itemType === 'SCHEDULING_LINK') {
    return removeOClock(`${start.format('MMM DD')} - ${end.format('MMM DD')}`);
  }

  return removeOClock(`${format(start)} - ${format(end)}`);
};

/**
 * Gets first and last dates of a period containing date
 */
export const getFirstAndLastOf = (
  period: unitOfTime.StartOf,
  date?: MomentInput,
  timeZone = getBrowserTimeZone()
): DateInterval => ({
  start: moment.tz(date, timeZone).startOf(period).toDate(),
  end: moment.tz(date, timeZone).endOf(period).toDate(),
});

/**
 * @description Gets days of the week for the selected date
 * @export
 * @param {Date} date
 * @param timeZone
 * @return {[Date]}
 */
export const getDaysOfWeek = (date: Date | undefined | null = new Date(), timeZone = getBrowserTimeZone()) => {
  const start = moment.tz(date, timeZone).startOf('weeks');
  return Array.from({ length: 7 }).map((_, i) => start.clone().add(i, 'days').toDate());
};

/**
 * @description Returns the duration options
 * @return {[{label: string, value: string}]}
 */
export const getDurationOptions = () => {
  const options = [];

  for (let i = 60; i > 0; i -= 10) {
    // const hours = Math.floor(i / 60);
    const minutes = i;
    const optionText = [minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : undefined].join(' ');

    options.push({
      value: i,
      label: optionText,
    });
  }
  return options;
};

/**
 * timezones that shouldn't be considered for inference
 */
const inferringExcludedTimeZones = ['Europe/Isle_of_Man', 'Europe/Jersey', 'Europe/Guernsey'];

/**
 * Infer time zone based on offset and abbreviation, returns IANA time zone value
 * @param abbr
 * @param offsetMinutes
 * @param date
 * @returns {string} time zone IANA value, e.g. America/New_York or America/Argentina/Buenos_Aires
 */
export const inferTimeZone = (
  abbr: string,
  offsetMinutes: number | undefined = undefined,
  date: Date | undefined | null = new Date()
) => {
  // add PT and ET
  if (['PT', 'PDT', 'PST'].indexOf(abbr) > -1) return 'America/Los_Angeles';
  if (['MT', 'MDT', 'MST'].indexOf(abbr) > -1) return 'America/Phoenix';
  if (['CT', 'CDT', 'CST'].indexOf(abbr) > -1) return 'America/Chicago';
  if (['ET', 'EDT', 'EST'].indexOf(abbr) > -1) return 'America/New_York';
  if (['BT', 'BDT', 'BST', 'GMT'].indexOf(abbr) > -1) return 'Europe/London';

  // first US timezones, then rest of the world sorted by population
  const usTimeZoneNames = moment.tz.zonesForCountry('US');
  return (
    usTimeZoneNames
      .map((timeZoneName) => moment.tz.zone(timeZoneName))
      .sort((a, b) => {
        // make new york top of the list
        if (a?.name === 'America/New_York') return -1;
        if (b?.name === 'America/New_York') return 1;
        return 0;
      })
      .concat(
        moment.tz
          .names()
          .filter((timeZoneName) => !usTimeZoneNames.some((n) => n === timeZoneName))
          .map((timeZoneName) => moment.tz.zone(timeZoneName))
          .sort((a, b) => b!.population - a!.population)
      )
      .filter((tz) => !inferringExcludedTimeZones.includes(tz!.name))
      .filter((tz) => findTzDbZone(tz!.name))
      .filter(
        (tz) =>
          tz!.abbr(date!.getTime()) === abbr &&
          (offsetMinutes === undefined || tz!.utcOffset(date!.getTime()) === offsetMinutes)
      )
      .map((tz) => tz!.name)
      .find(() => true) || null
  );
};

export const formatUtcOffset = (offset: number) => {
  const sign = offset > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(offset / 60));
  const minutes = Math.abs(offset % 60);
  return `GMT${sign}${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export type TimeZoneLabelValue = {
  label: string;
  value: string;
  abbr: string;
  offset: number;
};

export const mapZoneToLabelValue = (
  momentZone: moment.MomentZone | null,
  tzDbZone: RawTimeZone | undefined,
  date: string | number | Date
): TimeZoneLabelValue | null => {
  const _dateN = moment(date).valueOf();

  if (!momentZone) {
    return null;
  }
  const value = momentZone.name;
  const abbr = momentZone.abbr(_dateN);
  let formattedAbbr;
  if (abbr.startsWith('-') || abbr.startsWith('+')) {
    if (abbr.length === 5) {
      formattedAbbr = `GMT${abbr.substring(0, 3)}:${abbr.substring(3)}`;
    } else if (abbr.length === 3) {
      formattedAbbr = `GMT${abbr}`;
    } else {
      formattedAbbr = abbr;
    }
  } else {
    formattedAbbr = abbr;
  }
  const offset = momentZone.utcOffset(_dateN);

  let label;
  if (tzDbZone) {
    label = `(${formatUtcOffset(offset)}) ${tzDbZone.alternativeName} - ${tzDbZone.mainCities[0]}`;
  } else {
    label = value;
  }

  return {
    label,
    value,
    abbr: formattedAbbr,
    offset,
  };
};

/**
 * get all time zones label, value and abbreviation
 * @param date
 * @returns {[{label: string, value: string, abbr: string}]}
 */
export const getTimeZones = (date = new Date()): TimeZoneLabelValue[] => {
  const _date = new Date(date);

  return rawTimeZones
    .map((rtz) => ({
      momentZone: moment.tz.zone(rtz.name),
      tzDbZone: rtz,
    }))
    .sort((a, b) => b.momentZone!.utcOffset(_date.getTime()) - a.momentZone!.utcOffset(_date.getTime()))
    .map((z) => mapZoneToLabelValue(z.momentZone, z.tzDbZone, _date)!);
};

/**
 * find time zone by value and date, returns time zone label, value and abbreviation
 */
export const findTimeZone = (value: string, date = new Date()) => {
  date = new Date(date);
  const tzDbZone = findTzDbZone(value);
  const foundTimezone = mapZoneToLabelValue(moment.tz.zone((tzDbZone && tzDbZone.name) || value), tzDbZone, date);
  if (value === 'Asia/Colombo') {
    foundTimezone!.abbr = 'GMT+06:30';
  }
  return foundTimezone;
};

export const daysInRange = (start: moment.MomentInput, end: moment.MomentInput, timeZone: string) => {
  const arr: Date[] = [];
  const lastDay = moment.tz(end, timeZone);
  if (lastDay.seconds() > 0) {
    lastDay.add(1, 'days');
  }
  const currentDay = moment.tz(start, timeZone).startOf('day');
  while (currentDay.isBefore(lastDay)) {
    arr.push(currentDay.toDate());
    currentDay.add(1, 'days');
  }
  return arr;
};

/**
 * given a collection of available ranges and a duration of interval, choose the first, the last and middle interval
 */
export const calculateRecommendedTimes = (availableRanges: { [x: string]: any[] }, duration = 0) => {
  const days = Object.keys(availableRanges);

  if (days.length === 0) {
    return [];
  }

  // split ranges into intervals of duration length
  const intervals: Record<string, DateInterval[]> = {};
  days.forEach((day) => {
    intervals[day] = [];
    availableRanges[day].forEach((range) => {
      let intervalStart = moment(range.start);
      while (intervalStart.isSameOrBefore(moment(range.end).subtract(duration, 'minutes'))) {
        const intervalEnd = intervalStart.clone().add(duration, 'minutes');
        intervals[day].push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        intervalStart = intervalStart.clone().add(30, 'minutes');
      }
    });
  });

  if (days.length === 1) {
    const recommendedTimes = [intervals[days[0]][0]];
    if (intervals[days[0]].length === 2) {
      recommendedTimes.push(intervals[days[0]][1]);
    } else if (intervals[days[0]].length > 2) {
      // one from de middle
      recommendedTimes.push(intervals[days[0]][Math.floor(intervals[days[0]].length / 2)]);
      // the last one
      recommendedTimes.push(intervals[days[0]][intervals[days[0]].length - 1]);
    }
    return recommendedTimes;
  }
  if (days.length === 2) {
    const recommendedTimes = [intervals[days[0]][0], intervals[days[1]][0]];
    if (intervals[days[1]].length === 1 && intervals[days[0]].length > 1) {
      recommendedTimes.splice(1, 0, intervals[days[0]][intervals[days[0]].length - 1]);
      return recommendedTimes;
    }
    recommendedTimes.push(intervals[days[1]][intervals[days[1]].length - 1]);

    return recommendedTimes;
  }
  // 3 or more
  const recommendedTimes = [intervals[days[0]][0]];
  let midTimeSlot;
  if (days.length > 1) {
    const midDate = days[Math.floor(days.length / 2)];
    midTimeSlot = intervals[midDate][intervals[midDate].length - 1];
    recommendedTimes.push(midTimeSlot);
  }
  const lastDate = days[days.length - 1];
  const lastTimeSlot = intervals[lastDate][intervals[lastDate].length - 1];
  if (midTimeSlot && midTimeSlot.start.getTime() !== lastTimeSlot.start.getTime()) {
    recommendedTimes.push(lastTimeSlot);
  }
  return recommendedTimes;
};

export const calculateAllTimeSlots = (availableRanges: Record<string, DateInterval[]>, duration = 0) => {
  const days = Object.keys(availableRanges);

  if (days.length === 0) {
    return [];
  }

  // split ranges into intervals of duration length
  const intervals: Record<string, DateInterval[]> = {};

  const recommendedTimes: DateInterval[] = [];

  days.forEach((day) => {
    intervals[day] = [];
    availableRanges[day].forEach((range) => {
      let intervalStart = moment(range.start);
      while (intervalStart.isSameOrBefore(moment(range.end).subtract(duration, 'minutes'))) {
        const intervalEnd = intervalStart.clone().add(duration, 'minutes');
        intervals[day].push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        recommendedTimes.push({
          start: intervalStart.toDate(),
          end: intervalEnd.toDate(),
        });
        intervalStart = intervalStart.clone().add(30, 'minutes');
      }
    });
  });

  return recommendedTimes;
};

export const formatDateWithTwoTimeZones = (
  date: moment.MomentInput,
  primaryTimeZone: string,
  secondaryTimeZone: string | null
) => {
  const _date = moment(date);

  const momentPrimaryTZ = _date.clone().tz(primaryTimeZone);
  const abbrPrimaryTZ = findTimeZone(primaryTimeZone, _date.toDate())!.abbr;
  const abbrSecondaryTZ = secondaryTimeZone ? findTimeZone(secondaryTimeZone, _date.toDate())!.abbr : null;

  // only one timezone or primary is the same as secondary
  if (!secondaryTimeZone || abbrPrimaryTZ === abbrSecondaryTZ) {
    return `${moment.tz(date, primaryTimeZone).format('ddd, MMM D, h:mm a')} ${abbrPrimaryTZ}`;
  }

  const momentSecondaryTZ = _date.clone().tz(secondaryTimeZone);

  // days are different because of timezones
  if (momentPrimaryTZ.day() !== momentSecondaryTZ.day()) {
    return `${momentPrimaryTZ.format('ddd, MMM D, h:mm a')} ${abbrPrimaryTZ} (${momentSecondaryTZ.format(
      'ddd, MMM D, h:mm a'
    )} ${abbrSecondaryTZ})`;
  }

  // same day, two timezones
  return `${momentPrimaryTZ.format('ddd, MMM D, h:mm a')} ${abbrPrimaryTZ} (${momentSecondaryTZ.format(
    'h:mm a'
  )} ${abbrSecondaryTZ})`;
};

export const isSameTimeZone = (timeZone1: string, timeZone2: string, date = new Date()) =>
  findTimeZone(timeZone1, date)!.offset === findTimeZone(timeZone2, date)!.offset;

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestMinutes = (date: moment.MomentInput, minutes: number) => {
  const m = moment(date);
  const roundedMinutes = Math.round(m.minute() / minutes) * minutes;
  return m.startOf('hours').minute(roundedMinutes).toDate();
};

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestPastMinutes = (date: moment.MomentInput, minutes: number) => {
  const m = moment(date);
  const roundedMinutes = Math.floor(m.minute() / minutes) * minutes;
  return m.startOf('hours').minute(roundedMinutes).toDate();
};

/**
 *
 * @param date {Date}
 * @param minutes {number}
 * @returns {Date}
 */
export const nearestFutureMinutes = (date: moment.MomentInput, minutes: number) => {
  const m = moment(date);
  const roundedMinutes = Math.ceil(m.minute() / minutes) * minutes;
  return m.startOf('hours').minute(roundedMinutes).toDate();
};

/**
 *
 * @param start1 {Date}
 * @param end1 {Date}
 * @param start2 {Date}
 * @param end2 {Date}
 * @returns {boolean}
 */
export const checkCollision = (start1: Date, end1: Date, start2: Date, end2: Date) =>
  !!start1 &&
  !!end1 &&
  !!start2 &&
  !!end2 &&
  ((start1.getTime() >= start2.getTime() && start1.getTime() < end2.getTime()) ||
    (end1.getTime() > start2.getTime() && start1.getTime() <= start2.getTime()));

export const checkCollisionUpdate = (start1: Date, end1: Date, start2: Date, end2: Date) =>
  !!start1 &&
  !!end1 &&
  !!start2 &&
  !!end2 &&
  start1.getHours() === start2.getHours() &&
  start1.getDate() === start2.getDate();

export const isToday = (date: moment.MomentInput, timeZone: string) =>
  moment.tz(timeZone).isSame(moment.tz(date, timeZone), 'days');

export const isSameDay = (date1: moment.MomentInput, date2: moment.MomentInput, timeZone: string) =>
  moment.tz(date1, timeZone).isSame(moment.tz(date2, timeZone), 'days');

export const minutesOfDay = (date: moment.MomentInput, timeZone: string) =>
  moment.tz(date, timeZone).diff(moment.tz(date, timeZone).startOf('days'), 'minutes');

export const formatShortDuration = (minutes: number): string => {
  if (minutes < 60) return `${minutes} minutes`;
  if (minutes === 60) return `1 hour`;
  if (minutes % 60 === 0) return `${minutes / 60} hours`;
  return `${Math.floor(minutes / 60)}:${minutes % 60} hours`;
};

export function formatDuration(minutes: number) {
  if (!minutes) return '00:00';
  const hours = Math.trunc(minutes / 60)
    .toFixed(0)
    .padStart(2, '0');
  const remainderMinutes = Math.trunc(minutes % 60)
    .toFixed(0)
    .padStart(2, '0');
  return `${hours}:${remainderMinutes}`;
}

export const roundToNextBlock = (date: Date, minutes: number) => {
  const milliseconds = date.getTime();
  const gap = minutes * 60 * 1000;
  const excess = milliseconds % gap;
  if (!excess) return date;
  return new Date(milliseconds + gap - excess);
};

export const sortIntervals = (intervals: DateInterval[]) =>
  intervals.sort((a, b) => a.start.getTime() - b.start.getTime() || a.end.getTime() - b.end.getTime());

export const collapseIntervals = (inputIntervals: DateInterval[]) => {
  if (inputIntervals.length === 0) return [];
  const intervals = inputIntervals.filter((interval) => interval.start && interval.end);
  sortIntervals(intervals);

  const collapsedBlockedIntervals = [];

  let currentStart = intervals[0].start;
  let currentEnd = intervals[0].end;

  for (let i = 1; i < intervals.length; i++) {
    const { start, end } = intervals[i];
    if (
      (start.getTime() <= currentEnd.getTime() && end.getTime() >= currentEnd.getTime()) ||
      (end.getTime() >= currentStart.getTime() && start.getTime() <= currentStart.getTime()) ||
      (start.getTime() >= currentStart.getTime() && end.getTime() <= currentEnd.getTime())
    ) {
      if (end.getTime() > currentEnd.getTime()) {
        currentEnd = end;
      }
    } else {
      collapsedBlockedIntervals.push({ start: currentStart, end: currentEnd });
      currentStart = start;
      currentEnd = end;
    }
  }

  collapsedBlockedIntervals.push({ start: currentStart, end: currentEnd });

  return collapsedBlockedIntervals;
};

export const calculateAvailableIntervals = ({
  start,
  end,
  duration,
  bufferAfter = 0,
  bufferBefore = 0,
  blockedIntervals,
  resolution,
}: {
  start: Date;
  end: Date;
  duration: number;
  bufferAfter?: number;
  bufferBefore?: number;
  blockedIntervals: DateInterval[];
  resolution: number;
}) => {
  let intervalStart = roundToNextBlock(start, resolution);
  const availableIntervals = [];

  while (moment(intervalStart).isBefore(end)) {
    const overlappingRestriction = blockedIntervals.find(
      (r) => moment(r.start).isSameOrBefore(intervalStart) && moment(r.end).isAfter(intervalStart)
    );
    if (overlappingRestriction) {
      intervalStart = overlappingRestriction.end;
      continue;
    }

    const nextRestriction = blockedIntervals.find(
      (r) => moment(r.start).isAfter(intervalStart) && moment(r.start).isBefore(end)
    );

    // let intervalEnd = !nextRestriction ? end : nextRestriction.start;
    const intervalEnd = !nextRestriction ? moment(end).add(bufferAfter, 'minutes').toDate() : nextRestriction.start;

    if (
      moment(intervalEnd).diff(moment(intervalStart).subtract(bufferBefore, 'minutes').toDate(), 'minutes') >= duration
    ) {
      availableIntervals.push({
        start: moment(intervalStart).subtract(bufferBefore, 'minutes').toDate(),
        end: intervalEnd,
      });
    }

    intervalStart = intervalEnd;
  }

  return availableIntervals;
};

export const splitIntervalsIntoSlots = ({
  availableIntervals,
  duration,
  bufferAfter = 0,
  bufferBefore = 0,
  resolution,
}: {
  availableIntervals: DateInterval[];
  duration: number;
  bufferAfter?: number;
  bufferBefore?: number;
  resolution: number;
}) => {
  // split available intervals into blocks of duration
  const availableSlots: Date[] = [];
  availableIntervals.forEach((interval) => {
    let it = roundToNextBlock(moment(interval.start).add(bufferBefore, 'minutes').toDate(), resolution);
    while (
      it.getTime() < interval.end.getTime() &&
      moment(interval.end).diff(it, 'minutes') >= duration + bufferAfter
    ) {
      availableSlots.push(it);
      it = roundToNextBlock(moment(it).add(resolution, 'minutes').toDate(), resolution);
    }
  });
  return availableSlots;
};

export function hoursSelectOptions(minutes: number, military = true) {
  const hours = 24;
  const interval = minutes / 60;
  const array = [];
  const format = military ? 'HH:mm' : 'hh:mm A';

  for (let i = 0; i <= hours / interval; i++) {
    if (i === hours / interval) {
      array.push(military ? '24:00' : 'Midnight');
    } else {
      array.push(
        moment()
          .startOf('day')
          .add(i * interval, 'hours')
          .format(format)
      );
    }
  }

  return array;
}

export const buildDefaultWorkingHourIntervals = () =>
  Array.from({ length: 5 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 9 * 60,
    endTime: 17 * 60,
  }));

export const buildWeekendIntervals = () => {
  return [6, 0].map((dayOfWeek) => ({
    dayOfWeek: dayOfWeek,
    startTime: 9 * 60,
    endTime: 21 * 60,
  }));
};

export const buildWeeknightIntervals = () => {
  return Array.from({ length: 5 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 18 * 60,
    endTime: 21 * 60,
  }));
};

export const buildMorningIntervals = () => {
  return Array.from({ length: 7 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 7 * 60,
    endTime: 12 * 60,
  }));
};

export const buildAfternoonIntervals = () => {
  return Array.from({ length: 7 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 13 * 60,
    endTime: 17 * 60,
  }));
};

export const buildEveningIntervals = () => {
  return Array.from({ length: 7 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 18 * 60,
    endTime: 21 * 60,
  }));
};

export const buildLateNightIntervals = () => {
  return Array.from({ length: 7 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 21 * 60,
    endTime: 24 * 60,
  }));
};

export const buildAnytimeIntervals = () => {
  return Array.from({ length: 7 }).map((_, index) => ({
    dayOfWeek: index + 1,
    startTime: 0,
    endTime: 24 * 60,
  }));
};

export const formatTimeOfDay = ({ timeZone, date }: { timeZone: string; date: MomentInput }): string =>
  removeOClock(`${moment.tz(date, timeZone).format('h:mma')}`);

export const generateDates = ({
  month,
  currentMonth,
  timeZone,
}: {
  month: Date;
  currentMonth: Date;
  timeZone: string;
}) => {
  const now = moment.tz(timeZone);
  const firstDay = moment.tz(month, timeZone).startOf('month').startOf('week'); // sunday of first week of the month at 0:00
  const lastDay = moment.tz(month, timeZone).startOf('month').add(1, 'month').add(1, 'week').startOf('week'); // sunday of last week of the month at 0:00

  const arrayOfDate = [];

  for (let i = firstDay.clone(); i.isBefore(lastDay); i.add(1, 'days')) {
    const date = i.toDate();

    arrayOfDate.push({
      currentMonth: i.isSame(currentMonth, 'month'),
      date,
      today: now.isSame(i, 'days'),
    });
  }

  return arrayOfDate;
};

export function dateIntervalToRecurringInterval(dateInterval: DateInterval, timeZone: string): RecurringInterval {
  return {
    dayOfWeek: moment.tz(dateInterval.start, timeZone).day(),
    startTime: minutesOfDay(dateInterval.start, timeZone),
    endTime: minutesOfDay(dateInterval.end, timeZone),
  };
}

export function recurringIntervalsToDateIntervals(
  startDate: Date,
  endDate: Date,
  recurringIntervals: RecurringInterval[],
  timeZone = ''
): DateInterval[] {
  const start = moment.tz(startDate, timeZone);
  const end = moment.tz(endDate, timeZone);

  const dateIntervals: DateInterval[] = [];

  while (start.isSameOrBefore(end)) {
    dateIntervals.push(
      ...recurringIntervals.map((rInterval) => {
        const day = start.clone().add(rInterval.dayOfWeek, 'day');
        const dateIntervalStart = day.clone().add(rInterval.startTime, 'minutes').toDate();
        const dateIntervalEnd = day.add(rInterval.endTime, 'minutes').toDate();

        return { start: dateIntervalStart, end: dateIntervalEnd };
      })
    );
    start.add(1, 'week');
  }

  return dateIntervals;
}

export function joinFreeSlotsByDuration(freeSlots: Date[], duration: number, timeZone: string) {
  function getEndDateFromDuration(start: Date) {
    return moment.tz(start, timeZone).add(duration, 'minutes').toDate();
  }

  return freeSlots.reduce((acc: DateInterval[], fslot, index) => {
    const newEnd = getEndDateFromDuration(fslot);

    if (!index) {
      acc.push({ start: fslot, end: newEnd });
    } else {
      const prev = acc[acc.length - 1];
      if (prev.end.getTime() >= fslot.getTime()) {
        if (prev.end.getDate() <= newEnd.getDate()) {
          prev.end = newEnd;
        } else {
          const endOfFirstDay = moment(fslot).endOf('day').toDate();
          prev.end = endOfFirstDay;

          const startOfNextDay = moment(endOfFirstDay).add(1, 'second').toDate();
          acc.push({ start: startOfNextDay, end: newEnd });
        }
      } else {
        acc.push({ start: fslot, end: newEnd });
      }
    }

    return acc;
  }, [] as DateInterval[]);
}
