export function CheckboxIcon({ checked, size = 12 }: { checked?: boolean; size?: number }) {
  if (checked) {
    return (
      <svg width={size} height={size} viewBox="0 0 12 12" className="bg-white">
        <path
          d="M10.3733 0.164062H1.29667C0.577017 0.164062 0 0.747562 0 1.46073V10.5374C0 11.2506 0.577017 11.8341 1.29667 11.8341H10.3733C11.093 11.8341 11.67 11.2506 11.67 10.5374V1.46073C11.67 0.747562 11.093 0.164062 10.3733 0.164062ZM4.53833 9.24073L1.29667 5.99906L2.21082 5.08491L4.53833 7.40595L9.45918 2.4851L10.3733 3.40573L4.53833 9.24073Z"
          fill="#A1A1A3"
        />
      </svg>
    );
  }
  return <div className="border-2 border-black border-solid rounded-sm" style={{ height: size, width: size }} />;
}
