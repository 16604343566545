import prisma from '@/utils/prismaClient';
import { Prisma } from '@prisma/client';

export type GroupArg = Omit<Group, 'id' | 'contacts'> & {
  name: string;
  contactIds: string[];
};

const groupSelect: Pick<Prisma.GroupSelect, 'id' | 'name' | 'contacts'> = {
  id: true,
  name: true,
  contacts: {
    select: {
      id: true,
      name: true,
      email: true,
    },
  },
};

// needed for type inference
const findOne = (userId: string, groupId: string) =>
  prisma.group.findFirst({
    where: {
      id: groupId,
      userId,
    },
    select: groupSelect,
  });

type FindOne = Prisma.PromiseReturnType<typeof findOne>;

const forceType = (result: FindOne) => {
  return {
    ...result,
    id: result!.id!,
    name: result!.name!,
    contacts: result!.contacts,
  };
};

export type Group = ReturnType<typeof forceType>;

export const getGroups = async ({ userId, limit }: { userId: string; limit?: number }) => {
  return await prisma.group.findMany({
    where: {
      userId: userId,
    },
    select: {
      contacts: true,
      name: true,
      id: true,
    },
    take: limit,
  });
};

export type GetGroups = Prisma.PromiseReturnType<typeof getGroups>;

export const createGroup = async (userId: string, data: GroupArg): Promise<Group> => {
  const { contactIds, ...rest } = data;

  const createdGroup = await prisma.group.create({
    data: {
      ...rest,
      contacts: {
        connect: contactIds?.map((id) => ({ id })),
      },
      userId,
    },
    select: groupSelect,
  });

  return forceType(createdGroup);
};

export const updateGroup = async (groupId: string, data: GroupArg): Promise<Group> => {
  const { contactIds, ...rest } = data;

  const updatedGroup = await prisma.group.update({
    where: {
      id: groupId,
    },
    data: {
      ...rest,
      contacts: {
        set: data.contactIds?.map((id) => ({ id })),
      },
    },
    select: groupSelect,
  });

  return forceType(updatedGroup);
};

export const convertGroupToGroupArg = (group: Group): GroupArg => ({
  name: group.name,
  contactIds: group.contacts.map((contact) => contact.id),
});

export const deleteGroup = async (groupId: string): Promise<void> => {
  const deletedGroup = await prisma.group.delete({
    where: { id: groupId },
  });
  if (!deletedGroup) throw Error('group not found');
};
