import { cn } from 'ui/cn';

type Props = {
  children: string;
  size?: number;
  color?: string;
  className?: string;
};

export default function Key({ children, size = 16, color = '#D9D9D9', className }: Props) {
  return (
    <span
      className={cn(
        'inline-flex items-center justify-center text-xs leading-none rounded select-none px-0.5',
        className
      )}
      style={{ height: size, minWidth: size, backgroundColor: color }}>
      {children}
    </span>
  );
}
