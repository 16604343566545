import { useCallback } from 'react';
import { useAtom } from 'jotai';

import { useTasks } from '@/hooks/useTasks';
import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';

import { searchStateAtom } from '../utils';

export default function useSearchState() {
  const [state, setState] = useAtom(searchStateAtom);

  const { tasks } = useTasks({});
  const { events } = useEvents({});
  const { notes } = useNotes({});
  const { schedulingLinks } = useSchedulingLinks({});

  const closeSearch = useCallback(() => {
    setState({ show: false });
  }, [setState]);

  const openSearch = useCallback(() => {
    setState({ show: true });
  }, [setState]);

  const setValue = useCallback(
    (value: string) => {
      setState((prev) => ({ ...prev, value }));
    },
    [setState]
  );

  const setSelectedItem = useCallback(
    (id?: string) => {
      if (!id) {
        setState((prev) => ({ ...prev, selectedItem: undefined }));
        return;
      }
      const allItems = [
        ...(tasks?.results || []),
        ...(events || []),
        ...(notes?.results || []),
        ...(schedulingLinks?.results || []),
      ];
      const item = allItems.find((i) => i.id === id);
      if (item) {
        setState((prev) => ({ ...prev, selectedItem: item }));
      }
    },
    [events, notes?.results, schedulingLinks?.results, setState, tasks?.results]
  );

  const showOptions = useCallback(() => {
    setState((prev) => ({ ...prev, options: true }));
  }, [setState]);

  const setReference = useCallback(
    (reference: any) => {
      setState((prev) => ({ ...prev, reference }));
    },
    [setState]
  );

  const setIsCreating = useCallback(
    (isCreating: boolean) => {
      setState((prev) => ({ ...prev, isCreating }));
    },
    [setState]
  );

  return {
    searchState: state,
    closeSearch,
    openSearch,
    showOptions,
    setValue,
    setSelectedItem,
    setReference,
    setIsCreating,
  };
}
