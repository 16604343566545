type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export default function MediumPriorityIcon({ className, size = 15, color = '#A1A1A3' }: Props) {
  return (
    <svg className={className} width={size} height={size - 1} viewBox="0 0 15 14" fill="none">
      <path
        d="M5.625 5.875C5.625 5.57663 5.74353 5.29048 5.9545 5.0795C6.16548 4.86853 6.45163 4.75 6.75 4.75H8.25C8.54837 4.75 8.83452 4.86853 9.0455 5.0795C9.25647 5.29048 9.375 5.57663 9.375 5.875V12.625C9.375 12.9234 9.25647 13.2095 9.0455 13.4205C8.83452 13.6315 8.54837 13.75 8.25 13.75H6.75C6.45163 13.75 6.16548 13.6315 5.9545 13.4205C5.74353 13.2095 5.625 12.9234 5.625 12.625V5.875ZM0.75 10.375C0.75 10.0766 0.868526 9.79048 1.0795 9.5795C1.29048 9.36853 1.57663 9.25 1.875 9.25H3.375C3.67337 9.25 3.95952 9.36853 4.1705 9.5795C4.38147 9.79048 4.5 10.0766 4.5 10.375V12.625C4.5 12.9234 4.38147 13.2095 4.1705 13.4205C3.95952 13.6315 3.67337 13.75 3.375 13.75H1.875C1.57663 13.75 1.29048 13.6315 1.0795 13.4205C0.868526 13.2095 0.75 12.9234 0.75 12.625V10.375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.375C10.5 1.07663 10.6185 0.790483 10.8295 0.579505C11.0405 0.368526 11.3266 0.25 11.625 0.25H13.125C13.4234 0.25 13.7095 0.368526 13.9205 0.579505C14.1315 0.790483 14.25 1.07663 14.25 1.375V12.625C14.25 12.9234 14.1315 13.2095 13.9205 13.4205C13.7095 13.6315 13.4234 13.75 13.125 13.75H11.625C11.3266 13.75 11.0405 13.6315 10.8295 13.4205C10.6185 13.2095 10.5 12.9234 10.5 12.625V1.375ZM11.625 1C11.5255 1 11.4302 1.03951 11.3598 1.10984C11.2895 1.18016 11.25 1.27554 11.25 1.375V12.625C11.25 12.7245 11.2895 12.8198 11.3598 12.8902C11.4302 12.9605 11.5255 13 11.625 13H13.125C13.2245 13 13.3198 12.9605 13.3902 12.8902C13.4605 12.8198 13.5 12.7245 13.5 12.625V1.375C13.5 1.27554 13.4605 1.18016 13.3902 1.10984C13.3198 1.03951 13.2245 1 13.125 1H11.625Z"
        fill={color}
      />
    </svg>
  );
}
