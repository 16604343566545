import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { atom, useAtom, useSetAtom } from 'jotai';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { calendarTypeAtom, eventBeingCreatedAtom, slotsAtom } from '@/components/calendarAtoms';
import { CalendarControlPanel } from './CalendarControlPanel';
import { leftSidebarStateAtom } from '../LeftSidebar/useLeftSidebar';
import { cn } from 'ui/cn';
import ChatThread from '@/components/ChatThread';

export type CalendarViewType = 'WEEK' | 'WORK_WEEK' | 'SINGLE_DAY' | 'THREE_DAYS' | 'GROUP_POLL_DATES';
export const CalendarViewTypesAtom = atom<CalendarViewType>('WEEK');
export type RightSidebarType = 'CALENDAR_DOCK' | 'CHAT' | 'CHAT_ONBOARDING';

export const rightSidebarStateAtom = atom<{
  show: boolean;
  type?: RightSidebarType;
  context?: object;
}>({
  show: false,
});
export const messageSidebarStateAtom = atom<{
  show: boolean;
  type?: RightSidebarType;
  context?: object;
}>({
  show: false,
});

const DefaultHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex justify-between gap-4 px-1 pt-5 text-gray-400 fixed bg-white z-10">
      <ToolTip tooltip="Close Sidebar">
        <button onClick={onClose}>
          <MdKeyboardDoubleArrowLeft className="rotate-180" color="#A1A1A3" size={34} />
          <KeyboardShortcutIndicator shortcut="Esc" />
        </button>
      </ToolTip>
    </div>
  );
};

const ChatHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex justify-between gap-4 px-2 mt-5 text-gray-400 bg-white z-10 border-b">
      <ToolTip tooltip="Close Sidebar">
        <button onClick={onClose}>
          <MdKeyboardDoubleArrowLeft className="rotate-180" color="#A1A1A3" size={34} />
          <KeyboardShortcutIndicator shortcut="Esc" />
        </button>
      </ToolTip>
      <h4 className="font-semibold font-lexend text-black select-none">Chat</h4>
    </div>
  );
};

type Props = {
  className?: string;
  onClose: () => void;
};

export const RightSidebar = ({ className, onClose }: Props) => {
  const { rightSidebarState, closeRightSidebar } = useRightSidebar();
  const { type } = rightSidebarState;

  /**
   * Handle the sidebar toggle state change
   */
  const handleClose = useCallback(() => {
    onClose?.();
    closeRightSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  /**
   * Handle compose sidebar toggle state change
   */

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeRightSidebar();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(`flex flex-col h-full min-h-0 z-30 overflow-y-auto border-l`, className)}>
      {type === 'CALENDAR_DOCK' ? (
        <>
          <DefaultHeader onClose={handleClose} />
          <div className="mt-16 flex-1 overflow-y-auto">
            <CalendarControlPanel />
          </div>
        </>
      ) : null}
      {type === 'CHAT' || type === 'CHAT_ONBOARDING' ? (
        <>
          <ChatHeader onClose={handleClose} />
          <div className="flex-1 overflow-y-auto">
            <ChatThread onboarding={type === 'CHAT_ONBOARDING'} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export const useRightSidebar = () => {
  const router = useRouter();
  const [rightSidebarState, setRightSidebarState] = useAtom(rightSidebarStateAtom);
  const [messageSidebarState, setMessageSidebarState] = useAtom(messageSidebarStateAtom);
  const setLeftSidebarState = useSetAtom(leftSidebarStateAtom);
  const setSlots = useSetAtom(slotsAtom);
  const setCalendarType = useSetAtom(calendarTypeAtom);
  const setEventBeingCreated = useSetAtom(eventBeingCreatedAtom);

  const closeRightSidebar = useCallback(() => {
    setSlots([]);

    // if closing the sidebar from the scheduling link editor, go back to CalendarView
    if (router.asPath.startsWith('/scheduling-link')) {
      setCalendarType('SPECIFIC');
      void router.push(`/calendar`, undefined, { shallow: true });
    }

    if (router.asPath.startsWith('/calendar')) {
      setRightSidebarState({
        show: false,
      });
      setEventBeingCreated(null);
      return;
    }

    setRightSidebarState({
      show: false,
    });
  }, [router, setCalendarType, setEventBeingCreated, setRightSidebarState, setSlots]);

  const openRightSidebar = useCallback(
    (args?: { type?: RightSidebarType; context?: object }) => {
      setLeftSidebarState({
        show: false,
      });
      setRightSidebarState({
        show: true,
        ...args,
      });
    },
    [setLeftSidebarState, setRightSidebarState]
  );

  const toggleEmailThread = useCallback(
    (isOpen: boolean) => {
      setMessageSidebarState({ show: isOpen });
    },
    [setMessageSidebarState]
  );

  return {
    rightSidebarState,
    openRightSidebar,
    closeRightSidebar,
    messageSidebarState,
    toggleEmailThread,
  };
};
