import React, { Fragment, useId, useCallback, useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmailLabel } from './utils/constants';
import { useEmailThreads } from '@/hooks/useEmails';
import { ThreadItem } from './ThreadItem';
import { Skeleton } from './Skeleton';
import { useAtom } from 'jotai';
import { emailThreadIdAtom, focusedIndexAtom } from './useEmailRefs';
import useLeftSidebar from '../useLeftSidebar';

interface EmailInboxContentPlaceholderProps {
  selectedTab: EmailLabel;
  moreTabsVisible: boolean;
}

export const InboxTabContent = ({ selectedTab, moreTabsVisible }: EmailInboxContentPlaceholderProps) => {
  const { openLeftSidebar } = useLeftSidebar();
  const [focusedIndex, setFocusedIndex] = useAtom(focusedIndexAtom);
  const [selectedThreadId, setSelectedThreadId] = useAtom(emailThreadIdAtom);
  const { 
    emailThreads, 
    hasNextPage, 
    isFetching, 
    isFetchingNextPage, 
    fetchNextPage,
    error
  } = useEmailThreads({ labelId: selectedTab });

  const id = useId();

  useEffect(() => {
    if (emailThreads && emailThreads.pages[0].results.length > 0 && emailThreads.pages[0].results[0].id) {
      setSelectedThreadId(emailThreads.pages[0].results[0].id);
      setFocusedIndex(0);
    }
  }, [emailThreads, setSelectedThreadId, setFocusedIndex]);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (moreTabsVisible) return;
    if (emailThreads?.pages) {
      const flatResults = emailThreads.pages.flatMap(page => page.results.filter(r => r.id));
      const maxIndex = flatResults.length - 1;
      if ((e.key === 'ArrowDown' || e.key === 'j') && focusedIndex < maxIndex) {
        const newIndex = focusedIndex + 1;
        const newThreadId = flatResults[newIndex]?.id;
        if (newThreadId) {
          setFocusedIndex(newIndex);
          setSelectedThreadId(newThreadId);
        }
      } else if ((e.key === 'ArrowUp' || e.key === 'k') && focusedIndex > 0) {
        const newIndex = focusedIndex - 1;
        const newThreadId = flatResults[newIndex]?.id;
        if (newThreadId) {
          setFocusedIndex(newIndex);
          setSelectedThreadId(newThreadId);
        }
      }
    }
  }, [moreTabsVisible, emailThreads, focusedIndex, setFocusedIndex, setSelectedThreadId]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const fetchMoreData = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const length = useMemo(() => emailThreads?.pages?.reduce((prev, cur) => prev + cur.results.length, 0) ?? 0, [emailThreads]);

  const handleOpen = useCallback(
    (threadId: string) => (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedThreadId(threadId);
      openLeftSidebar({ type: 'EMAIL_DETAIL', context: { labelId: selectedTab, threadId } });
    },
    [openLeftSidebar, selectedTab, setSelectedThreadId]
  );

  if (isFetching && !isFetchingNextPage) return (
    <div className="flex justify-center w-full p-4">
      <div className="bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 rounded relative max-w-[200px] text-center">
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0H4z"></path>
        </svg>
        <span>Loading...</span>
      </div>
    </div>);

  if (error) {
    const errorMessage = (error as Error).message;
    return (
      <div className="flex justify-center w-full px-4 mt-10">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative max-w-[250px]" role="alert">
          <strong className="font-bold">Oops!</strong>
          <span className="block sm:inline"> Something went wrong: {errorMessage}</span>
        </div>
      </div>
    );
  }

  if (!length && !isFetching) {
    return (
      <div className="flex justify-center w-full p-4">
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative max-w-[200px] text-center">
          <strong className="font-bold">All clear!</strong>
          <span className="block sm:inline"> Nothing to show.</span>
        </div>
      </div>
    );
  }

  return (
    <div id={id} className="overflow-auto h-full">
      <InfiniteScroll
        dataLength={emailThreads?.pages?.reduce((prev, cur) => prev + cur.results.length, 0) ?? 0}
        next={fetchMoreData}
        hasMore={!!hasNextPage}
        loader={<Skeleton />}
        scrollableTarget={id}
      >
        {emailThreads?.pages?.map((page, i) => (
          <Fragment key={i}>
            {page.results.map((thread) => (
              <ThreadItem
                key={thread.id}
                thread={thread}
                onOpen={handleOpen(thread.id ?? '')}
                isSelected={selectedThreadId === thread.id}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );
};
