import { gmail_v1 } from 'googleapis';

export type GmailMessagePart = {
  filename: string;
  mimeType: string;
  size: number;
  attachmentId?: string;
  headers: Record<string, string>;
  body: string;
};

export type GmailThread = Omit<gmail_v1.Schema$Thread, 'messages'> & {
  messages: GmailMessage[];
};

export type GmailMessage = gmail_v1.Schema$Message & {
  headers: Record<string, string>;
  thread?: GmailThread;
  textHtml?: string;
  textPlain?: string;
  attachments?: GmailMessagePart[];
  inline?: GmailMessagePart[];
};

/**
 * Decodes an url safe Base64 string to its original representation.
 */
const decodeBody = (contentTransferEncoding: string, size: number, body: string | null | undefined) => {
  if (!body || !size) {
    return '';
  }

  const payload = Buffer.alloc(size, body, 'base64').toString();
  switch (contentTransferEncoding) {
    // case '7bit':
    // case '8bit':
    // case 'binary':
    //   return iconvDecode(Buffer.from(payload, 'utf-8'), 'utf8');
    // case 'quoted-printable':
    //   return quotedPrintableDecode(payload);
    case 'base64':
    default:
      return payload;
  }
};

/**
 * Takes the header array filled with objects and transforms it into a more
 * pleasant key-value object.
 */
const indexHeaders = (headers: gmail_v1.Schema$MessagePartHeader[] | undefined): Record<string, string> => {
  if (!headers) {
    return {};
  }

  const result: Record<string, string> = {};
  headers.forEach((header) => {
    result[header.name?.toLowerCase() ?? ''] = header.value ?? '';
  });
  return result;
};

/**
 * Takes a response from the Gmail API's GET message method and extracts all
 * the relevant data.
 */
export const parseMessage = (response: gmail_v1.Schema$Message): GmailMessage => {
  const result: GmailMessage = {
    id: response.id,
    threadId: response.threadId,
    labelIds: response.labelIds,
    snippet: response.snippet,
    historyId: response.historyId,
    headers: {},
  };

  const payload = response.payload;
  if (!payload) {
    return result;
  }
  let parts = [payload];
  let firstPartProcessed = false;

  while (parts.length > 0) {
    const part = parts.shift();
    if (!part) continue;

    if (part.parts) {
      parts = [...parts, ...part.parts];
    }
    
    const headers = indexHeaders(part.headers);
    if (!firstPartProcessed) {
      result.headers = headers;
      firstPartProcessed = true;
    }

    if (!part?.body?.size) {
      continue;
    }

    const isHtml = part.mimeType?.includes('text/html') ?? false;
    const isPlain = part.mimeType?.includes('text/plain') ?? false;
    const isAttachment =
      part.body.attachmentId || headers['content-disposition']?.toLowerCase()?.indexOf('attachment') >= 0;
    const isInline = headers['content-disposition']?.toLowerCase()?.indexOf('inline') >= 0;

    if (isHtml && !isAttachment) {
      result.textHtml = decodeBody(headers['content-transfer-encoding'], part.body.size, part.body.data);
    } else if (isPlain && !isAttachment) {
      result.textPlain = decodeBody(headers['content-transfer-encoding'], part.body.size, part.body.data);
    } else if (isAttachment) {
      if (!result.attachments) {
        result.attachments = [];
      }
      result.attachments.push({
        filename: part.filename ?? '',
        mimeType: part.mimeType ?? '',
        size: part.body.size ?? 0,
        attachmentId: part.body.attachmentId ?? '',
        headers: { ...headers },
        body: part.body.data ?? '',
      });
    } else if (isInline) {
      if (!result.inline) {
        result.inline = [];
      }
      result.inline.push({
        filename: part.filename ?? '',
        mimeType: part.mimeType ?? '',
        size: part.body.size ?? 0,
        attachmentId: part.body.attachmentId ?? '',
        headers: { ...headers },
        body: part.body.data ?? '',
      });
    }
  }
  return result;
};

export const extractEmailContent = (htmlContent: string) => {
  const quoteRegex = /(<div class="gmail_quote">[\s\S]*<\/div>)/;
  const match = htmlContent.match(quoteRegex);
  const quotedContent = match ? match[0] : ''; // Everything inside the 'gmail_quote' div
  const immediateResponse = match ? htmlContent.replace(quoteRegex, '') : htmlContent; // Everything outside the 'gmail_quote' div
  return { immediateResponse, quotedContent };
};
