import moment from 'moment-timezone';
import { Contact } from '@/models/ContactModel';
import { useSettings } from '@/hooks/useSettings';
import { HeaderText } from '@/components/Typography';
import { Divider } from '@/components/Divider';
import { Organization } from '@/models/ContactArg';

type AboutViewProps = {
  contact: Contact;
};

export const AboutView = ({ contact }: AboutViewProps) => {
  const { defaultTimeZone: timeZone } = useSettings();
  if (!contact || !timeZone) return null;

  return (
    <div className="flex flex-col flex-1 min-h-0">
      <div className="flex-1 flex flex-col gap-[10px] px-[5px] pt-[5px] pb-[15px]">
        <div className="flex flex-col gap-5">
          <HeaderText className="font-bold font-inter select-none">Bio</HeaderText>
          <span className="text-[14px] font-inter leading-0 select-none">{contact?.description}</span>
          <button
            role="link"
            type="button"
            className="relative font-inter w-max bg-[linear-gradient(#262626,#262626),linear-gradient(#3b82f6,#3b82f6)] bg-[length:100%_2px,0_2px] bg-[position:100%_100%,0_100%] bg-no-repeat text-neutral-950 transition-[background-size,color] duration-500 hover:bg-[0_2px,100%_2px] hover:text-[#3b82f6]">
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </button>
        </div>
        <Divider />
        {contact.birthday ? (
          <div className="flex flex-col gap-5 my-3">
            <HeaderText className="font-bold font-inter leading-0 select-none">Birthday</HeaderText>
            <span className="text-[14px] font-inter leading-0 select-none">
              {moment.tz(contact.birthday, timeZone).format('MM/DD/YYYY')}
            </span>
            <Divider />
          </div>
        ) : null}

        {(contact.organization as Organization[])?.length ? (
          <div className="flex flex-col gap-[8px] my-3">
            <HeaderText className="font-bold font-inter leading-0 select-none ">Experience</HeaderText>
            {(contact.organization as Organization[])?.map((organization, index) => (
              <div className="pt-4 p-1.5" key={index}>
                <div className="flex justify-between items-center gap-[4px]">
                  {organization.domain ? (
                    <button
                      role="link"
                      type="button"
                      className="relative font-inter w-max bg-[linear-gradient(#262626,#262626),linear-gradient(#3b82f6,#3b82f6)] bg-[length:100%_2px,0_2px] bg-[position:100%_100%,0_100%] bg-no-repeat text-neutral-950 transition-[background-size,color] duration-500 hover:bg-[0_2px,100%_2px] hover:text-[#3b82f6]">
                      <a
                        href={organization.domain}
                        className="text-[16px] select-none"
                        rel="noreferrer"
                        target="_blank">
                        {organization.name}: {organization.position}
                      </a>
                    </button>
                  ) : (
                    <span className="text-[16px] underline font-inter leading-0 select-none">
                      {organization.name}: : {organization.position}
                    </span>
                  )}
                  <div className="flex items-center gap-[4px]">
                    <span className="text-[10px] leading-0">
                      {moment(organization.startDate).format('MMM-YYYY')} -{' '}
                      {organization.current ? 'Current' : moment(organization.endDate).format('MMM-YYYY')}
                    </span>
                  </div>
                </div>
                <span className="text-[10px] leading-0">{organization.location ? organization.location : null}</span>

                <div className="flex flex-1 mt-1">
                  <span className="text-[14px] font-inter leading-0 p-2 select-none">{organization.metadata}</span>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
