type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const AttendeChecked = ({ className, size = 8, color = '#22BFAC' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.84375 10.0312C5.45932 10.0312 6.06886 9.91 6.63758 9.67444C7.20629 9.43887 7.72304 9.09359 8.15831 8.65831C8.59359 8.22304 8.93887 7.70629 9.17444 7.13758C9.41 6.56886 9.53125 5.95932 9.53125 5.34375C9.53125 4.72818 9.41 4.11863 9.17444 3.54992C8.93887 2.98121 8.59359 2.46446 8.15831 2.02919C7.72304 1.59391 7.20629 1.24863 6.63758 1.01306C6.06886 0.777496 5.45932 0.65625 4.84375 0.65625C3.60055 0.65625 2.40826 1.15011 1.52919 2.02919C0.65011 2.90826 0.15625 4.10055 0.15625 5.34375C0.15625 6.58695 0.65011 7.77924 1.52919 8.65831C2.40826 9.53739 3.60055 10.0312 4.84375 10.0312ZM4.72292 7.23958L7.32708 4.11458L6.52708 3.44792L4.2875 6.1349L3.12865 4.97552L2.39219 5.71198L3.95469 7.27448L4.35781 7.6776L4.72292 7.23958Z"
      fill={color}
    />
  </svg>
);

export const AttendeDeclined = ({ className, size = 8, color = '#E33D25' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.3125 7.84375L5 6.15625L6.6875 7.84375L7.34375 7.1875L5.65625 5.5L7.34375 3.8125L6.6875 3.15625L5 4.84375L3.3125 3.15625L2.65625 3.8125L4.34375 5.5L2.65625 7.1875L3.3125 7.84375ZM5 10.1875C4.35156 10.1875 3.74219 10.0644 3.17188 9.81812C2.60156 9.57187 2.10547 9.23797 1.68359 8.81641C1.26172 8.39453 0.927813 7.89844 0.681875 7.32812C0.435938 6.75781 0.312812 6.14844 0.3125 5.5C0.3125 4.85156 0.435625 4.24219 0.681875 3.67188C0.928125 3.10156 1.26203 2.60547 1.68359 2.18359C2.10547 1.76172 2.60156 1.42781 3.17188 1.18188C3.74219 0.935938 4.35156 0.812812 5 0.8125C5.64844 0.8125 6.25781 0.935625 6.82812 1.18188C7.39844 1.42813 7.89453 1.76203 8.31641 2.18359C8.73828 2.60547 9.07234 3.10156 9.31859 3.67188C9.56484 4.24219 9.68781 4.85156 9.6875 5.5C9.6875 6.14844 9.56437 6.75781 9.31812 7.32812C9.07187 7.89844 8.73797 8.39453 8.31641 8.81641C7.89453 9.23828 7.39844 9.57234 6.82812 9.81859C6.25781 10.0648 5.64844 10.1878 5 10.1875Z"
      fill={color}
    />
  </svg>
);

export const AttendeUnknow = ({ className, size = 8, color = '#DCDCDD' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 9.6875C2.41109 9.6875 0.3125 7.58891 0.3125 5C0.3125 2.41109 2.41109 0.3125 5 0.3125C7.58891 0.3125 9.6875 2.41109 9.6875 5C9.6875 7.58891 7.58891 9.6875 5 9.6875ZM4.53125 6.40625V7.34375H5.46875V6.40625H4.53125ZM5.46875 5.63516C5.84547 5.52161 6.16888 5.27654 6.38007 4.94457C6.59127 4.61259 6.6762 4.21581 6.61941 3.82647C6.56261 3.43713 6.36786 3.08114 6.07063 2.82334C5.7734 2.56553 5.39346 2.42306 5 2.42188C4.62071 2.42185 4.25312 2.5532 3.95975 2.79359C3.66637 3.03398 3.46532 3.36858 3.39078 3.74047L4.31047 3.92469C4.33657 3.79411 4.39921 3.67363 4.49111 3.57726C4.58301 3.48089 4.70038 3.4126 4.82957 3.38033C4.95877 3.34806 5.09447 3.35315 5.22088 3.39499C5.3473 3.43683 5.45924 3.51371 5.54367 3.61668C5.6281 3.71965 5.68156 3.84448 5.69782 3.97665C5.71408 4.10881 5.69247 4.24288 5.63552 4.36324C5.57856 4.48361 5.4886 4.58533 5.37609 4.65656C5.26358 4.72779 5.13316 4.76562 5 4.76562C4.87568 4.76562 4.75645 4.81501 4.66854 4.90292C4.58064 4.99083 4.53125 5.11005 4.53125 5.23438V5.9375H5.46875V5.63516Z"
      fill={color}
    />
  </svg>
);
