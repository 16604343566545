import { signOut } from 'next-auth/react';
import { useSettings } from '@/hooks/useSettings';
import { useCalendars } from '@/hooks/useCalendars';
import { DurationSelector } from '@/components/DurationSelector';
import { ParagraphText } from '@/components/Typography';
import { Avatar } from '@/components/Avatar';
import Button from '../Button';
import Link from 'next/link';
import Key from '../Key';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ConferencingSelector } from '@/components/ConferencingSelector';
import { useSegment } from '@/components/useSegment';
import { Account, useAccounts } from '@/hooks/useAccounts';
import { useEasySession } from '@/hooks/useEasySession';
import MyCalendars from '@/components/MyCalendars';
import { FormEvent, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { TimeZoneSelector } from '../TimeZoneSelector';
import { Session } from 'next-auth';
import { Calendar } from '@/models/CalendarModel';
import { ToastGeneric } from '../ToastGeneric';

export const QuickSettings = () => {
  const { user } = useEasySession();
  const { settings, defaultTimeZone } = useSettings();
  const { accounts } = useAccounts();
  const { calendars } = useCalendars();

  if (!user) return null;
  if (!settings?.timeZones) return null;
  if (!defaultTimeZone) return null;
  if (!accounts) return null;
  if (!settings?.defaultDuration) return null;
  if (!calendars) return null;

  return (
    <QuickSettingsForm
      user={user}
      accounts={accounts}
      calendars={calendars}
      timeZones={settings.timeZones.map((tz) => tz.name)}
      defaultTimeZone={defaultTimeZone}
      defaultDuration={settings.defaultDuration}
    />
  );
};

const QuickSettingsForm = ({
  user,
  defaultTimeZone,
  timeZones,
  accounts,
  defaultDuration,
  calendars,
}: {
  user: Session['user'];
  defaultTimeZone: string;
  timeZones: string[];
  accounts: Account[];
  defaultDuration: number;
  calendars: Calendar[];
}) => {
  const { resetIdentifyUser } = useSegment();
  const { closeLeftSidebar, openLeftSidebar } = useLeftSidebar();
  const { saveSettings } = useSettings();

  const [isSaving, setSaving] = useState(false);
  const [newDefaultTimeZone, setNewDefaultTimeZone] = useState(defaultTimeZone);
  const [newDefaultDuration, setNewDefaultDuration] = useState(defaultDuration);

  const handleLogOut = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await signOut();
    resetIdentifyUser();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setSaving(true);
      await saveSettings({
        defaultDuration: newDefaultDuration,
        defaultTimeZone: defaultTimeZone !== newDefaultTimeZone ? newDefaultTimeZone : undefined,
      });
      toast(<ToastGeneric title="Your Settings have been updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      closeLeftSidebar();
    } finally {
      setSaving(false);
    }
  };

  const canSubmit = defaultDuration !== newDefaultDuration || defaultTimeZone !== newDefaultTimeZone;

  const handleChangeDuration = (value: number) => {
    setNewDefaultDuration(value);
  };

  const handleChangeTimezone = (value: string) => {
    setNewDefaultTimeZone(value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col flex-1 min-h-0 gap-[20px] px-[16px] pb-[24px]">
      <Toaster />
      <div className="flex flex-col flex-1 gap-6 overflow-y-auto">
        <div>
          <div className="flex flex-col px-2.5">
            {!calendars?.length ? <p className="mb-5">No Calendar data available.</p> : <MyCalendars />}
          </div>
        </div>
        <DurationSelector className="w-full" value={newDefaultDuration} onChange={handleChangeDuration} />
        <ConferencingSelector isDefault={true} />
        <TimeZoneSelector timeZone={newDefaultTimeZone} timeZones={timeZones} onChange={handleChangeTimezone} />

        <div className="flex flex-col gap-2.5">
          <ParagraphText className="flex items-center text-base gap-2.5">Keyboard Shortcuts </ParagraphText>
          <div className="flex flex-col gap-2.5 px-2.5">
            <p className="flex items-center text-sm leading-normal gap-2.5">
              Flash keyboard shortcuts <Key>?</Key>
            </p>
            <p className="flex items-center text-sm leading-normal gap-2.5">
              <button
                type="button"
                onClick={() => {
                  openLeftSidebar({ type: 'SHORTCUTS' });
                }}>
                Display all keyboard shortcuts
              </button>
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 px-2">
        <Button
          variant="primary"
          isLoading={isSaving}
          className="w-full"
          disabled={!canSubmit || isSaving}
          type="submit">
          Save Changes
        </Button>
        <Button className="w-full" variant="secondary">
          <Link className="w-full" href="/settings" onClick={closeLeftSidebar}>
            See all settings
          </Link>
        </Button>
      </div>

      <div className="flex items-center px-2 py-4 border-t border-gray-border">
        <div className="flex-1 flex items-center text-gray-500 gap-[8px]">
          <Avatar picture={user?.picture} name={user?.name} className="rounded-full" size={26} />

          <div className="flex flex-col">
            <ParagraphText>{user?.name}</ParagraphText>
            {accounts
              ?.filter((acc) => acc.provider !== 'ZOOM')
              ?.map(({ email }) => (
                <ParagraphText key={email} className="max-w-[250px] text-clip">
                  {email}
                </ParagraphText>
              ))}
          </div>
        </div>

        <button className="text-[14px] underline text-primary-500" onClick={handleLogOut}>
          Log out
        </button>
      </div>
    </form>
  );
};
