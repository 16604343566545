import { MdClose } from 'react-icons/md';
import { Command } from 'cmdk';
import { SearchIcon } from '@/icons/SearchIcon';
import * as Dialog from '@radix-ui/react-dialog';
import { useSettings } from '@/hooks/useSettings';
import { TimeZoneLabelValue } from '@/utils/date';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function TimeZoneModal({ isOpen, onClose }: Props) {
  const { globalTimeZones, timeZones, createTimeZone } = useSettings();

  const handleSelect = (timeZone: TimeZoneLabelValue) => async () => {
    if (timeZones?.find((zone) => zone.name === timeZone.value)) return;
    await createTimeZone({ name: timeZone.value });
    onClose?.();
  };

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
        <Dialog.Content className="fixed inset-0 flex items-center justify-center z-50">
          <div className="flex flex-col p-3 bg-white rounded-xl">
            <Dialog.Close
              className="p-1 m-3 text-xl text-black transition-colors rounded-full cursor-pointer place-self-end hover:bg-primary-100"
              asChild
              onClick={onClose}>
              <MdClose size={24} />
            </Dialog.Close>
            <Command>
              <div className="flex items-center gap-2 px-2 py-4 rounded-lg border border-gray-300 mb-2.5">
                <SearchIcon aria-hidden size={24} />
                <Command.Input placeholder="Search time zone" autoFocus className="flex-1 focus:outline-none" />
              </div>
              <Command.List className="font-medium leading-normal h-[300px] w-[600px] overflow-scroll">
                <Command.Empty className="text-center p-2 py-2.5">No time zone found.</Command.Empty>
                {globalTimeZones.map((zone) => (
                  <Command.Item
                    key={zone.value}
                    value={zone.value}
                    onSelect={handleSelect(zone)}
                    className="p-5 cursor-pointer select-none aria-selected:bg-primary-100">
                    {zone.label}
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
