import Button from '@/components/Button';
import Header from '../Header';
import Title from '../sections/Title';
import Schedule from './Schedule';
import { GoWorkflow } from 'react-icons/go';
import { FaCalendar } from 'react-icons/fa';

import SlotsSection from './SlotsSection';
import ToggleableSection from '../sections/ToggleableSection';
import InviteeQuestions from './InviteeQuestions';
import Tags from '../sections/Tags';

export const SchedulingLinkEditorSkeleton = () => {
  return (
    <section className="flex flex-col h-full animate-pulse">
      <Header id={''} itemType="SCHEDULING_LINK" />
      <article
        className="lg:h-full lg:p-6 xs:p-3 overflow-y-auto overflow-x-hidden"
        style={{ height: 'calc(100dvh - 115px)' }}>
        <div className="flex flex-col gap-3">
          <Title placeholder="Untitled Scheduling Link" type="SCHEDULING_LINK" title={''} onChange={() => {}} />
        </div>
        <SlotsSection
          code={''}
          slots={[]}
          calendarIds={[]}
          contacts={[]}
          id={''}
          duration={undefined}
          start={null}
          end={null}
          scheduleId={''}
          openLink={() => {}}
        />
        <Schedule
          start={null}
          end={null}
          duration={undefined}
          calendarIds={[]}
          contacts={[]}
          scheduleId={''}
          when={undefined}
          useCustomSchedule={false}
          onChange={() => {}}
        />
        <Tags projectIds={[]} contacts={[]} location={''} onChange={() => {}} />
        <InviteeQuestions questions={[]} onChange={() => {}} />
        <ToggleableSection title="Calendar & Check for Conflicts" summary="Coming soon..." icon={FaCalendar} disabled>
          TEST
        </ToggleableSection>
        <ToggleableSection title="Automations" summary="Coming soon..." icon={GoWorkflow} disabled>
          Coming soon
        </ToggleableSection>
      </article>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="shrink-0 mt-auto flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t flex-1 xs:w-[75%] sm:w-[90%] md:w-full">
        <Button variant="primary" disabled={true}>
          Save Form
        </Button>
      </footer>
    </section>
  );
};
