type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export default function ProjectIcon({ className, size = 15, color = '#A1A1A3' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.475 0.378597C7.09643 0.482168 7.51786 1.07145 7.41429 1.69288L7.06429 3.79288H10.4607L10.8714 1.31788C10.975 0.696454 11.5643 0.275026 12.1857 0.378597C12.8071 0.482168 13.2286 1.07145 13.125 1.69288L12.7786 3.79288H14.8571C15.4893 3.79288 16 4.3036 16 4.93574C16 5.56788 15.4893 6.0786 14.8571 6.0786H12.3964L11.6357 10.65H13.7143C14.3464 10.65 14.8571 11.1607 14.8571 11.7929C14.8571 12.425 14.3464 12.9357 13.7143 12.9357H11.2536L10.8429 15.4107C10.7393 16.0322 10.15 16.4536 9.52857 16.35C8.90714 16.2465 8.48571 15.6572 8.58929 15.0357L8.93929 12.9393H5.53929L5.12857 15.4143C5.025 16.0357 4.43571 16.4572 3.81429 16.3536C3.19286 16.25 2.77143 15.6607 2.875 15.0393L3.22143 12.9357H1.14286C0.510714 12.9357 0 12.425 0 11.7929C0 11.1607 0.510714 10.65 1.14286 10.65H3.60357L4.36429 6.0786H2.28571C1.65357 6.0786 1.14286 5.56788 1.14286 4.93574C1.14286 4.3036 1.65357 3.79288 2.28571 3.79288H4.74643L5.15714 1.31788C5.26071 0.696454 5.85 0.275026 6.47143 0.378597H6.475ZM6.68214 6.0786L5.92143 10.65H9.31786L10.0786 6.0786H6.68214Z"
        fill={color}
      />
    </svg>
  );
}
