import { useMemo } from 'react';

import { useEvents } from '@/hooks/useEvents';
import { useNotes } from '@/hooks/useNotes';
import { useTasks } from '@/hooks/useTasks';

import type { Event } from '@/models/EventModel';
import type { Note } from '@/models/NoteModel';
import type { Task } from '@/models/TaskModel';

export type ReferenceType = Event | Note | Task;

export default function useReferences({ ignoredIds }: { ignoredIds?: string[] } = {}): ReferenceType[] {
  const { events } = useEvents({});
  const { notes } = useNotes();
  const { tasks } = useTasks({});

  return useMemo(() => {
    const _events = (events || []) as Event[];
    const _tasks = (tasks?.results || []) as Task[];
    const _notes = (notes?.results || []) as Note[];

    return [..._events, ..._tasks, ..._notes]
      .filter((item) => !ignoredIds?.includes(item.id))
      .filter(Boolean)
      .sort(
        (item1, item2) => (item1!.start ?? item1!.createdAt!).getTime() - (item2!.start ?? item2!.createdAt!).getTime()
      );
  }, [events, ignoredIds, notes?.results, tasks?.results]);
}
