import { Task } from '@/models/TaskModel';
import { getRRule } from './recurrenceUtils';
import moment from 'moment-timezone';
import { DateInterval } from './date';

export function expandRecurringTask(task: Task, globalDateRange: DateInterval, timeZone: string) {
  const rrule = getRRule(task?.recurrence || '');
  if (!rrule) return;

  const dates = rrule.all((date, i) => {
    if (i > 100) return false;
    return moment(date).isSameOrBefore(globalDateRange.end);
  });

  return dates.map((date) => {
    const start = moment.tz(date, timeZone).set({
      hour: moment(task.start).get('hour'),
      minute: moment(task.start).get('minute'),
      second: 0,
      millisecond: 0,
    });
    const end = moment.tz(date, timeZone).set({
      hour: moment(task.end).get('hour'),
      minute: moment(task.end).get('minute'),
      second: 0,
      millisecond: 0,
    });
    return { ...task, start: start.toDate(), end: end.toDate() };
  }) as Task[];
}

export function isTaskCompleted(task: Task) {
  if (!task.recurrence?.[0]) return task.isCompleted;
  const day = moment(task.start).format('YYYY-MM-DD');
  const recurrenceInstances = task.recurrenceInstances as Record<string, { isCompleted: boolean }>;
  return Boolean(recurrenceInstances?.[day]?.isCompleted);
}
