import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpGet, httpPost, httpPut } from 'utils/smarty-api';
import { Contact } from '@/models/ContactModel';
import { ContactArg } from '@/models/ContactArg';
import { useEasySession } from './useEasySession';

type useContactsProps = {
  onCreated?: (contact: Contact) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (contact: Contact) => void;
  onUpdateError?: (error: void) => void;
};

export const useContacts = ({ onCreated, onCreateError, onUpdated, onUpdateError }: useContactsProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: contacts = [], isLoading: isLoadingContacts } = useQuery({
    queryKey: ['CONTACTS'],
    queryFn: () => httpGet<Contact[]>('/contacts'),
    enabled: isAuthenticated,
  });

  const {
    mutate: createContact,
    status: creatingContactStatus,
    error: createContactError,
    isSuccess: isCreateContactSuccess,
    reset: resetCreateContact,
  } = useMutation<Contact, void, ContactArg>({
    mutationKey: ['CREATE_CONTACT'],
    mutationFn: (data) => httpPost<void, Contact, ContactArg>('/contacts', data),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['CONTACTS'] });
      const previous = queryClient.getQueryData(['CONTACTS']);
      queryClient.setQueryData(['CONTACTS'], () => payload);
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData(['CONTACTS'], (previous: any) => previous);
      onCreateError?.(error);
    },
    onSuccess: (contact) => {
      void queryClient.invalidateQueries({ queryKey: ['CONTACTS'] });
      onCreated?.(contact);
    },
  });

  const {
    mutate: updateContact,
    status: updatingContactStatus,
    error: updateContactError,
    isSuccess: isUpdateContactSuccess,
    reset: resetUpdateContact,
  } = useMutation<
    Contact,
    void,
    {
      contactId: string;
      contact: ContactArg;
    }
  >({
    mutationKey: ['UPDATE_CONTACT'],
    mutationFn: ({ contactId, contact }) => httpPut<void, Contact, ContactArg>(`/contacts/${contactId}`, contact),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['CONTACTS'] });
      const previous = queryClient.getQueryData(['CONTACTS']);
      queryClient.setQueryData(['CONTACTS'], () => payload);
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<Contact>(['CONTACTS'], (previous) => previous);
      onUpdateError?.(error);
    },
    onSuccess: (contact) => {
      void queryClient.invalidateQueries({ queryKey: ['CONTACTS'] });
      onUpdated?.(contact);
    },
  });

  return {
    contacts,
    isLoadingContacts,
    createContact,
    isCreatingContact: creatingContactStatus === 'pending',
    isCreateContactSuccess,
    createContactError,
    resetCreateContact,
    updateContact,
    isUpdatingContact: updatingContactStatus === 'pending',
    isUpdateContactSuccess,
    updateContactError,
    resetUpdateContact,
  };
};
