import useModal from '@/hooks/useModal';
import TimeZoneModal from './TimeZoneModal';
import { findTimeZone } from '@/utils/date';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useSettings } from '@/hooks/useSettings';
import { cn } from 'ui/cn';
import { useContext, useEffect } from 'react';
import CalendarContext from '@/components/Calendar/context';
import { useShortcuts } from '@/context/Common';
import { useAtom } from 'jotai';
import { timeZoneAtom } from '../calendarAtoms';

export default function TimeZonesHeader({ className }: { className?: string }) {
  const { timeZone, timeZones, setTimeZone } = useContext(CalendarContext);
  const { registerShortcut, unregisterShortcut } = useShortcuts();
  const { deleteTimeZone, defaultTimeZone } = useSettings();
  const [localTimeZone, setLocalTimeZone] = useAtom(timeZoneAtom);

  useEffect(() => {
    setLocalTimeZone(localTimeZone ?? defaultTimeZone ?? timeZone);
  }, [localTimeZone, timeZone, defaultTimeZone, setLocalTimeZone]);

  const { isOpen, closeModal, openModal } = useModal();

  useEffect(() => {
    if (!timeZones || timeZones.length >= 3) return;

    const openTimeZoneModal = (e: KeyboardEvent) => {
      e.preventDefault();
      openModal();
    };

    registerShortcut('Z', openTimeZoneModal);

    return () => {
      unregisterShortcut('Z');
    };
  }, [timeZones, timeZone]);

  return (
    <div className={`flex flex-col justify-center gap-2 px-1 shrink-0 ${className}`}>
      <div className="flex justify-center">
        <button
          onClick={timeZones.length < 3 ? openModal : undefined}
          className="w-[75px] p-1 text-sm font-medium text-center text-gray-600 border rounded-lg border-gray-border bg-secondary-100">
          {findTimeZone(defaultTimeZone ?? timeZone)?.abbr}
        </button>
      </div>

      {timeZones.length > 1 ? (
        <div className="flex gap-2">
          {timeZones.map((timezoneValue) => {
            const abbrTimezone = findTimeZone(timezoneValue)?.abbr ?? '';
            return (
              <div key={timezoneValue} className="relative flex">
                <span
                  hidden={timezoneValue === (defaultTimeZone ?? timeZone)}
                  className="absolute leading-none text-white bg-red-600 rounded-lg -right-1 -top-1">
                  <button type="button" onClick={() => deleteTimeZone(timezoneValue)}>
                    <Cross2Icon />
                  </button>
                </span>
                <div
                  onClick={() => {
                    setTimeZone(timezoneValue);
                    setLocalTimeZone(timezoneValue);
                  }}
                  className={cn(
                    'text-center p-1 rounded-lg border border-gray-border text-gray-600 text-sm font-medium w-[75px]',
                    timezoneValue === timeZone ? 'bg-secondary-100' : 'bg-white'
                  )}>
                  {abbrTimezone}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <TimeZoneModal isOpen={isOpen} onClose={closeModal} />
    </div>
  );
}
