'use client';
import { useEffect } from 'react';
import Script from 'next/script';
import { useEasySession } from '@/hooks/useEasySession';
import { useSegment } from '@/components/useSegment';

declare global {
  interface Window {
    Featurebase?: any;
  }
}

/*
Documentation for Featurebase:
https://developers.featurebase.app/install/widget/setup?_gl=1*g9yq6f*_ga*NzkzNzI4MzEwLjE2OTUyNTAwNzM.*_ga_PYHTHHFWN0*MTY5NTc4MTYxMy40LjEuMTY5NTc4MTg3Ni42MC4wLjA.
*/
export const FeedbackWidget = () => {
  const { trackEvent } = useSegment();
  const { user } = useEasySession();
  useEffect(() => {
    if (typeof window.Featurebase !== 'function') {
      window.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (window.Featurebase.q = window.Featurebase.q || []).push(arguments);
      };
    }
    if(!user) return;
    window.Featurebase('initialize_feedback_widget', {
      organization: 'smarty',
      theme: 'light',
      email: `${user.email!}`,
    });
  }, [user]);

  return (
    <>
      <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
      <div>
        <button
          className="text-[14px] font-medium text-[#279AF1] font-inter underline"
          data-featurebase-feedback
          onClick={() =>
            trackEvent('Feedback CTA Clicked', {
              source: 'Feedback Modal',
              cta: 'Send Us Feedback',
            })
          }>
          Send us your feedback
        </button>
      </div>
    </>
  );
};
