import { useEasySession } from '@/hooks/useEasySession';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpDelete, httpGet, httpPost, httpPut, transformIntoDate } from 'utils/smarty-api';
import { GetSchedulingLinks, SchedulingLink } from '@/models/SchedulingLinkModel';
import { convertSchedulingLinkArgToSchedulingLink, SchedulingLinkArg } from '@/models/SchedulingLinkArg';
import moment, { unitOfTime } from 'moment-timezone';

export const processSchedulingLinkResponse = (schedulingLink: SchedulingLink) => {
  let start = transformIntoDate(schedulingLink, 'start') as Date | null;
  let end = transformIntoDate(schedulingLink, 'end') as Date | null;
  const { recurrence } = schedulingLink;

  if (recurrence?.rollingInterval && recurrence.rollingNumber) {
    start = new Date();
    end = moment()
      .add(recurrence.rollingNumber!, recurrence.rollingInterval.toLowerCase() as unitOfTime.DurationConstructor)
      .toDate();
  }

  return {
    ...schedulingLink,
    createdAt: transformIntoDate(schedulingLink, 'createdAt'),
    start,
    end,
    slots:
      schedulingLink.slots?.map((slot) => ({
        start: transformIntoDate(slot, 'start'),
        end: transformIntoDate(slot, 'end'),
      })) ?? undefined,
    projectIds: schedulingLink.projectIds || [],
  };
};

type useSchedulingLinkProps = {
  onCreated?: (schedulingLink: SchedulingLink) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (schedulingLink: SchedulingLink) => void;
  onUpdateError?: (error: void) => void;
};

export const useSchedulingLinks = ({ onCreated, onCreateError, onUpdated, onUpdateError }: useSchedulingLinkProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const { data: schedulingLinks, isLoading: isLoadingSchedulingLinks } = useQuery({
    queryKey: ['SCHEDULING_LINKS'],
    queryFn: async () => {
      const res = await httpGet<GetSchedulingLinks>('/scheduling-links');
      return {
        ...res,
        results: res?.results?.map(processSchedulingLinkResponse),
      };
    },
    enabled: isAuthenticated,
  });

  const {
    mutate: createSchedulingLink,
    status: creatingSchedulingLinkStatus,
    error: createSchedulingLinkError,
    isSuccess: isCreateSchedulingLinkSuccess,
    reset: resetCreateSchedulingLink,
  } = useMutation<SchedulingLink, void, SchedulingLinkArg>({
    mutationKey: ['CREATE_SCHEDULING_LINK'],
    mutationFn: async (data) => {
      const response = await httpPost<void, SchedulingLink, SchedulingLinkArg>('/scheduling-links', data);
      return processSchedulingLinkResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['SCHEDULING_LINKS'] });
      const previous = queryClient.getQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS']);
      if (!previous) return previous;
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], () => ({
        count: previous.count + 1,
        results: [...previous.results, convertSchedulingLinkArgToSchedulingLink({ schedulingLinkArg: payload })],
      }));
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], (previous) => previous);
      onCreateError?.(error);
    },
    onSuccess: (schedulingLink) => {
      void queryClient.invalidateQueries({ queryKey: ['SCHEDULING_LINKS'] });
      onCreated?.(schedulingLink);
    },
  });

  const {
    mutate: updateSchedulingLink,
    status: updatingSchedulingLinkStatus,
    error: updateSchedulingLinkError,
    isSuccess: isUpdateSchedulingLinkSuccess,
    reset: resetUpdateSchedulingLink,
  } = useMutation<
    SchedulingLink,
    void,
    {
      schedulingLinkId: string;
      schedulingLink: SchedulingLinkArg;
    }
  >({
    mutationKey: ['UPDATE_SCHEDULING_LINK'],
    mutationFn: async ({ schedulingLinkId, schedulingLink }) => {
      const response = await httpPut<void, SchedulingLink, SchedulingLinkArg>(
        `/scheduling-links/${schedulingLinkId}`,
        schedulingLink
      );
      return processSchedulingLinkResponse(response);
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['SCHEDULING_LINKS'] });
      const previous = queryClient.getQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS']);
      if (!previous) return previous;
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], () => ({
        count: previous.count,
        results: previous.results.map((p) => {
          if (p.id !== payload.schedulingLinkId) {
            return p;
          }
          return {
            ...p,
            ...convertSchedulingLinkArgToSchedulingLink({
              schedulingLinkArg: payload.schedulingLink,
              schedulingLinkId: payload.schedulingLinkId,
            }),
          };
        }),
      }));
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], (previous) => previous);
      onUpdateError?.(error);
    },
    onSuccess: (schedulingLink) => {
      void queryClient.invalidateQueries({ queryKey: ['SCHEDULING_LINKS'] });
      onUpdated?.(schedulingLink);
    },
  });

  return {
    schedulingLinks,
    isLoadingSchedulingLinks,
    createSchedulingLink,
    isCreatingSchedulingLink: creatingSchedulingLinkStatus === 'pending',
    isCreateSchedulingLinkSuccess,
    createSchedulingLinkError,
    resetCreateSchedulingLink,
    updateSchedulingLink,
    isUpdatingSchedulingLink: updatingSchedulingLinkStatus === 'pending',
    isUpdateSchedulingLinkSuccess,
    updateSchedulingLinkError,
    resetUpdateSchedulingLink,
  };
};

export const useDeleteSchedulingLink = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, void, { schedulingLinkId: string }>({
    mutationKey: ['DELETE_SCHEDULING_LINK'],
    mutationFn: ({ schedulingLinkId }) => httpDelete(`/scheduling-links/${schedulingLinkId}`),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['SCHEDULING_LINKS'] });
      const previous = queryClient.getQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS']);
      if (!previous) return previous;
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], () => ({
        count: previous.count - 1,
        results: previous.results.filter((p) => p.id !== payload.schedulingLinkId),
      }));
      return previous;
    },
    onError: () => {
      queryClient.setQueryData<GetSchedulingLinks>(['SCHEDULING_LINKS'], (previous) => previous);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['SCHEDULING_LINKS'] });
    },
  }, queryClient);

  return {
    deleteSchedulingLink: mutation.mutate,
    isDeletingSchedulingLink: mutation.status === 'pending',
    deleteSchedulingLinkError: mutation.error,
    isDeleteSchedulingLinkSuccess: mutation.isSuccess,
    resetDeleteSchedulingLink: mutation.reset,
  };
};
