import { useEasySession } from '@/hooks/useEasySession';
import { Project, ProjectArg } from '@/models/ProjectModel';
import { httpDelete, httpGet, httpPost, httpPut } from 'utils/smarty-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { generateRandomColor } from '@/utils/generateRandomColor';

type useProjectsProps = {
  onCreated?: (event: Project) => void;
  onCreateError?: (error: void) => void;
  onUpdated?: (event: Project) => void;
  onUpdateError?: (error: void) => void;
  term?: string;
  projectId?: string;
};

export const useProjects = ({ onCreateError, onCreated, onUpdateError, onUpdated, term }: useProjectsProps) => {
  const { isAuthenticated } = useEasySession();
  const queryClient = useQueryClient();

  const {
    data: projects = [],
    isLoading: isLoadingProjects,
    refetch: refetchProjects,
  } = useQuery({
    queryKey: ['PROJECTS'],
    queryFn: () => httpGet<Project[]>('/projects', { params: term }),
    enabled: isAuthenticated,
  });

  const {
    mutateAsync: createProject,
    status: creatingProjectStatus,
    error: createProjectError,
    isSuccess: isCreateProjectSuccess,
    reset: resetCreateProject,
  } = useMutation<Project, void, ProjectArg>({
    mutationKey: ['CREATE_PROJECT'],
    mutationFn: (data) => httpPost<void, Project, ProjectArg>('/projects', data),
    onMutate: async ({ name }) => {
      await queryClient.cancelQueries({ queryKey: ['PROJECTS'] });
      const previous = queryClient.getQueryData<Project[]>(['PROJECTS']) || [];
      queryClient.setQueryData<Project[]>(
        ['PROJECTS'],
        [
          ...previous,
          {
            name,
            id: 'temp',
            eventIds: [],
            schedulingLinksIds: [],
            tasksIds: [],
            color: generateRandomColor(),
            parentProjectId: '',
            subprojects: [],
          },
        ]
      );
      return previous;
    },
    onError: (error) => {
      queryClient.setQueryData<Project[]>(['PROJECTS'], (previous) => previous);
      onCreateError?.(error);
    },
    onSuccess: (event) => {
      void queryClient.invalidateQueries({ queryKey: ['PROJECTS'] });
      onCreated?.(event);
    },
  });

  const {
    mutate: updateProject,
    status: updatingProjectStatus,
    error: updateProjectError,
    isSuccess: isUpdateProjectSuccess,
    reset: resetUpdateProject,
  } = useMutation<
    Project,
    void,
    {
      projectId: string;
      project: ProjectArg;
    }
  >({
    mutationKey: ['PROJECTS'],
    mutationFn: ({ project, projectId }) =>
      httpPut<void, Project, ProjectArg>(`/projects/${projectId}`, {
        name: project.name,
        id: project.id,
        schedulingLinksIds: project.schedulingLinkIds ?? [],
        tasksIds: project.taskIds ?? [],
        eventIds: project.eventIds ?? [],
        color: project.color,
        parentProjectId: project.parentProjectId ?? '',
      }),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey: ['PROJECTS'] });
      const previous = queryClient.getQueryData<Project[]>(['PROJECTS']);
      return queryClient.setQueryData<Project[]>(
        ['PROJECTS'],
        previous?.map((cachedProject) =>
          cachedProject.id === payload.projectId
            ? {
                ...cachedProject,
                name: payload.project.name,
              }
            : cachedProject
        ) ?? []
      );
    },
    onError: (error) => {
      queryClient.setQueryData<Project[]>(['PROJECTS'], (previous) => previous);
      onUpdateError?.(error);
    },
    onSuccess: (project) => {
      void queryClient.invalidateQueries({ queryKey: ['PROJECTS'] });
      onUpdated?.(project);
    },
  });

  return {
    projects,
    isLoadingProjects,
    isCreatingProject: creatingProjectStatus === 'pending',
    isUpdatingProject: updatingProjectStatus === 'pending',
    updateProjectError,
    isUpdateProjectSuccess,
    createProjectError,
    isCreateProjectSuccess,
    updateProject,
    createProject,
    resetUpdateProject,
    resetCreateProject,
    refetchProjects,
  };
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, void, { projectId: string }>({
    mutationKey: ['DELETE_PROJECTS'],
    mutationFn: ({ projectId }) => httpDelete(`/projects/${projectId}`),
    onMutate: async ({ projectId }) => {
      await queryClient.cancelQueries({ queryKey: ['PROJECTS'] });
      const previous = queryClient.getQueryData<Project[]>(['PROJECTS']);
      if (!previous) return;
      const newResults = previous.filter((p) => p.id !== projectId);
      return queryClient.setQueryData<Project[]>(['PROJECTS'], () => newResults);
    },
    onError: () => {
      queryClient.setQueryData<Project[]>(['PROJECTS'], (previous) => previous);
    },
    onSuccess: (_, variable) => {
      const cached = queryClient.getQueryData<Project[]>(['PROJECTS']);
      if (!cached) return;
      const filteredCache = cached.filter((p) => p.id !== variable.projectId);
      queryClient.setQueryData<Project[]>(['PROJECTS'], () => filteredCache);
    },
  });

  return {
    deleteProject: mutation.mutate,
    isDeletingProject: mutation.status === 'pending',
    deleteProjectError: mutation.error,
    isDeleteProjectSuccess: mutation.isSuccess,
    resetDeleteProject: mutation.reset,
  };
};
