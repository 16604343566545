import { ReactNode, useEffect, useId, useRef } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { cn } from 'ui/cn';
import { useShortcuts } from '@/context/Common';
import { ArrowIcon } from '@/icons/Arrow';

type CollapsibleSectionProps = {
  header: ReactNode;
  content: ReactNode;
  className?: string;
  id?: string;
  headerClassName?: string;
  contentClassName?: string;
  defaultCollapsed?: boolean;
  icon?: ReactNode;
  shortcut?: string;
  onChange?: (value: string) => void;
  triggerProps?: { [key: string]: unknown };
  disabled?: boolean;
};

export function CollapsibleSection({
  header,
  content,
  className,
  id,
  headerClassName,
  contentClassName,
  defaultCollapsed,
  icon,
  shortcut,
  disabled,
  onChange,
  triggerProps,
}: CollapsibleSectionProps) {
  const itemId = useId();
  const defaultCollapsedValue = defaultCollapsed === true ? '' : id ?? itemId;
  const accordionRef = useRef<HTMLButtonElement>(null);
  const { registerShortcut, unregisterShortcut } = useShortcuts();

  useEffect(() => {
    if (!shortcut) return;

    registerShortcut(shortcut, () => {
      accordionRef.current?.click();
    });

    return () => {
      unregisterShortcut(shortcut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcut]);

  return (
    <Accordion.Root
      className={className}
      type="single"
      disabled={disabled}
      onValueChange={onChange}
      collapsible
      defaultValue={defaultCollapsedValue}>
      <Accordion.Item value={id ?? itemId}>
        <Accordion.Trigger
          ref={accordionRef}
          className={cn('group flex items-center gap-[10px]', headerClassName)}
          {...triggerProps}>
          <ArrowIcon className="group-data-[state=open]:rotate-90 transition-transform" />
          {icon}
          {header}
        </Accordion.Trigger>
        <Accordion.Content className={contentClassName}>{content}</Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
