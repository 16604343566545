import moment from 'moment-timezone';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import type { SchedulingWhen } from '@prisma/client';

import { calendarTypeAtom, recurringSlotsAtom, slotsAtom } from '@/components/calendarAtoms';
import { useAvailability } from '@/hooks/useAvailability';
import { useSettings } from '@/hooks/useSettings';
import { NameEmail } from '@/utils/contacts';
import useScheduleIntervals from './useScheduleIntervals';

import { convertScheduleIntervalsToSpecificIntervals, generateSlots } from '@/utils/scheduling';
import { DateInterval, RecurringInterval } from '@/utils/date';

function generateSlotsFromRecurringSlots(recurringSlots: RecurringInterval[], timeZone: string) {
  const start = moment.tz(timeZone).startOf('day').toDate();
  const end = moment.tz(timeZone).startOf('day').add(1, 'month').toDate();

  return convertScheduleIntervalsToSpecificIntervals(start, end, recurringSlots, timeZone);
}

export default function useSlots({
  start,
  end,
  duration,
  when,
  scheduleId,
  calendarIds,
  contacts,
  onChange,
}: {
  start?: Date | null;
  end?: Date | null;
  duration: number;
  when: SchedulingWhen;
  scheduleId?: string;
  calendarIds?: string[];
  contacts?: NameEmail[];
  onChange: (data: { slots?: DateInterval[]; recurringSlots?: RecurringInterval[] }) => void;
}) {
  const [slots, setSlots] = useAtom(slotsAtom);
  const [recurringSlots, setRecurringSlots] = useAtom(recurringSlotsAtom);
  const [calendarType, setCalendarType] = useAtom(calendarTypeAtom);

  const { defaultAvailability } = useAvailability();
  const { defaultTimeZone: timeZone } = useSettings();
  const scheduleIntervals = useScheduleIntervals(scheduleId);

  useEffect(() => {
    if (when === 'RECURRING_SLOTS' && calendarType === 'SPECIFIC') {
      setCalendarType('GENERIC');
      return;
    }
    if (when !== 'RECURRING_SLOTS' && calendarType === 'GENERIC') {
      setCalendarType('SPECIFIC');
    }
  }, [when, calendarType, setCalendarType]);

  useEffect(() => {
    if (slots || !start || !end) return;

    generateSlots({
      start: start,
      end: end,
      duration,
      scheduleIntervals: defaultAvailability?.intervals || [],
      timeZone: timeZone || '',
      checkCalendarIds: calendarIds || [],
      contactEmails: contacts?.map((c) => c.email) ?? [],
    }).then((generatedSlots) => setSlots(generatedSlots || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (when === 'RECURRING_SLOTS') {
      const newValue = scheduleIntervals;
      setRecurringSlots(newValue);
      setSlots(generateSlotsFromRecurringSlots(newValue, timeZone));
      return;
    }

    if (!start || !end) return;

    generateSlots({
      start,
      end,
      duration,
      scheduleIntervals,
      timeZone,
      checkCalendarIds: calendarIds || [],
      contactEmails: contacts?.map((c) => c.email) || [],
    }).then(setSlots);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleIntervals]);

  useEffect(() => {
    if (when === 'RECURRING_SLOTS') return;
    onChange({ slots });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots]);

  useEffect(() => {
    if (when !== 'RECURRING_SLOTS') return;
    onChange({ recurringSlots });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringSlots]);
}
