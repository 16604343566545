type GoogleColorEntry = {
  name: string;
  background: string;
  foreground: string;
  realBackground: string;
  realForeground: string;
};

export const GoogleCalendarColors: { [key: string]: GoogleColorEntry } = {
  '1': {
    name: 'Cocoa',
    background: '#ac725e',
    foreground: '#1d1d1d',
    realBackground: '#805248',
    realForeground: '#ffffff',
  },
  '2': {
    name: 'Flamingo',
    background: '#d06b64',
    foreground: '#1d1d1d',
    realBackground: '#fa6970',
    realForeground: '#ffffff',
  },
  '3': {
    name: 'Tomato',
    background: '#f83a22',
    foreground: '#1d1d1d',
    realBackground: '#ee0000',
    realForeground: '#ffffff',
  },
  '4': {
    name: 'Tangerine',
    background: '#fa573c',
    foreground: '#1d1d1d',
    realBackground: '#ff0713',
    realForeground: '#ffffff',
  },
  '5': {
    name: 'Pumpkin',
    background: '#ff7537',
    foreground: '#1d1d1d',
    realBackground: '#ff5900',
    realForeground: '#ffffff',
  },
  '6': {
    name: 'Mango',
    background: '#ffad46',
    foreground: '#1d1d1d',
    realBackground: '#ff9100',
    realForeground: '#ffffff',
  },
  '7': {
    name: 'Eucalyptus',
    background: '#42d692',
    foreground: '#1d1d1d',
    realBackground: '#00a088',
    realForeground: '#ffffff',
  },
  '8': {
    name: 'Basil',
    background: '#16a765',
    foreground: '#1d1d1d',
    realBackground: '#008e47',
    realForeground: '#ffffff',
  },
  '9': {
    name: 'Pistachio',
    background: '#7bd148',
    foreground: '#1d1d1d',
    realBackground: '#58c448',
    realForeground: '#ffffff',
  },
  '10': {
    name: 'Avocado',
    background: '#b3dc6c',
    foreground: '#1d1d1d',
    realBackground: '#b5da3b',
    realForeground: '#ffffff',
  },
  '11': {
    name: 'Citron',
    background: '#fbe983',
    foreground: '#1d1d1d',
    realBackground: '#e7cf45',
    realForeground: '#ffffff',
  },
  '12': {
    name: 'Banana',
    background: '#fad165',
    foreground: '#1d1d1d',
    realBackground: '#fec62c',
    realForeground: '#ffffff',
  },
  '13': {
    name: 'Sage',
    background: '#92e1c0',
    foreground: '#1d1d1d',
    realBackground: '#00c77b',
    realForeground: '#ffffff',
  },
  '14': {
    name: 'Peacock',
    background: '#9fe1e7',
    foreground: '#1d1d1d',
    realBackground: '#0090e4',
    realForeground: '#ffffff',
  },
  '15': {
    name: 'Cobalt',
    background: '#9fc6e7',
    foreground: '#1d1d1d',
    realBackground: '#4663f3',
    realForeground: '#ffffff',
  },
  '16': {
    name: 'Blueberry',
    background: '#4986e7',
    foreground: '#1d1d1d',
    realBackground: '#4e1fb4',
    realForeground: '#ffffff',
  },
  '17': {
    name: 'Lavender',
    background: '#9a9cff',
    foreground: '#1d1d1d',
    realBackground: '#7e74ca',
    realForeground: '#ffffff',
  },
  '18': {
    name: 'Wisteria',
    background: '#b99aff',
    foreground: '#1d1d1d',
    realBackground: '#be8ada',
    realForeground: '#ffffff',
  },
  '19': {
    name: 'Graphite',
    background: '#c2c2c2',
    foreground: '#1d1d1d',
    realBackground: '#616161',
    realForeground: '#ffffff',
  },
  '20': {
    name: 'Birch',
    background: '#cabdbf',
    foreground: '#1d1d1d',
    realBackground: '#a99c8e',
    realForeground: '#ffffff',
  },
  '21': {
    name: 'Radicchio',
    background: '#cca6ac',
    foreground: '#1d1d1d',
    realBackground: '#c20054',
    realForeground: '#ffffff',
  },
  '22': {
    name: 'Cherry Blossom',
    background: '#f691b2',
    foreground: '#1d1d1d',
    realBackground: '#f2005d',
    realForeground: '#ffffff',
  },
  '23': {
    name: 'Grape',
    background: '#cd74e6',
    foreground: '#1d1d1d',
    realBackground: '#a500a8',
    realForeground: '#ffffff',
  },
  '24': {
    name: 'Amethyst',
    background: '#a47ae2',
    foreground: '#1d1d1d',
    realBackground: '#ae47ae',
    realForeground: '#ffffff',
  },
};

export const GoogleEventColors: { [key: string]: GoogleColorEntry } = {
  '1': {
    name: 'Lavender',
    background: '#a4bdfc',
    foreground: '#1d1d1d',
    realBackground: '#7e74ca',
    realForeground: '#ffffff',
  },
  '2': {
    name: 'Sage',
    background: '#7ae7bf',
    foreground: '#1d1d1d',
    realBackground: '#00c77b',
    realForeground: '#ffffff',
  },
  '3': {
    name: 'Grape',
    background: '#dbadff',
    foreground: '#1d1d1d',
    realBackground: '#a500a8',
    realForeground: '#ffffff',
  },
  '4': {
    name: 'Flamingo',
    background: '#ff887c',
    foreground: '#1d1d1d',
    realBackground: '#fa6970',
    realForeground: '#ffffff',
  },
  '5': {
    name: 'Banana',
    background: '#fbd75b',
    foreground: '#1d1d1d',
    realBackground: '#fec62c',
    realForeground: '#ffffff',
  },
  '6': {
    name: 'Tangerine',
    background: '#ffb878',
    foreground: '#1d1d1d',
    realBackground: '#ff0713',
    realForeground: '#ffffff',
  },
  '7': {
    name: 'Peacock',
    background: '#46d6db',
    foreground: '#1d1d1d',
    realBackground: '#0090e4',
    realForeground: '#ffffff',
  },
  '8': {
    name: 'Graphite',
    background: '#e1e1e1',
    foreground: '#1d1d1d',
    realBackground: '#616161',
    realForeground: '#ffffff',
  },
  '9': {
    name: 'Blueberry',
    background: '#5484ed',
    foreground: '#1d1d1d',
    realBackground: '#4e1fb4',
    realForeground: '#ffffff',
  },
  '10': {
    name: 'Basil',
    background: '#51b749',
    foreground: '#1d1d1d',
    realBackground: '#008e47',
    realForeground: '#ffffff',
  },
  '11': {
    name: 'Tomato',
    background: '#dc2127',
    foreground: '#1d1d1d',
    realBackground: '#ee0000',
    realForeground: '#ffffff',
  },
};

export const EVENT_COLOR_IDS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

export const calculateCalendarColor = (
  calendarColorId: string | undefined,
  calendarBackground: string | undefined,
  calendarForeground: string | undefined
) => {
  /*
    si colors[colorId].background === backgroundColor significa que es un color de tabla, y tenemos que usar el color real
    si no es de tabla, usar foreground y background
  */
  if (!calendarColorId) return undefined;
  const tableColor = GoogleCalendarColors[calendarColorId];
  if (calendarBackground === tableColor.background) {
    return {
      background: tableColor.realBackground,
      foreground: tableColor.realForeground,
    };
  }

  return {
    background: calendarBackground,
    foreground: calendarForeground,
  };
};

// los eventos pueden tener uno de los 11 colores, o el color del calendario si colorId = null
export const calculateEventColor = (
  calendarColorId: string | undefined,
  calendarBackground: string | undefined,
  calendarForeground: string | undefined,
  eventColorId: string | undefined
) => {
  if (!eventColorId || eventColorId === 'DEFAULT') {
    return calculateCalendarColor(calendarColorId, calendarBackground, calendarForeground);
  }
  const tableColor = GoogleEventColors[eventColorId];
  return {
    foreground: tableColor.realForeground,
    background: tableColor.realBackground,
  };
};
