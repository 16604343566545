import { useEffect, useState } from 'react';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import moment from 'moment-timezone';
import { generateDates } from '@/utils/date';
import { cn } from 'ui/cn';
import { useGlobalSelectedDate } from '@/hooks/useGlobalSelectedDate';
import { useSettings } from '@/hooks/useSettings';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';

const DAYS_HEADER = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export default function InlineDatePicker() {
  const { defaultTimeZone: timeZone } = useSettings();

  const { globalSelectedDate, setGlobalSelectedDate } = useGlobalSelectedDate();
  const { globalDateRange, setGlobalDateRange } = useGlobalDateRange();

  const [selectedMonth, setSelectedMonth] = useState(moment.tz(timeZone).startOf('month').toDate());
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const handleAddMonth = () => {
    setSelectedMonth(moment.tz(selectedMonth, timeZone).add(1, 'months').toDate());
  };
  const handleSubtractMonth = () => {
    setSelectedMonth(moment.tz(selectedMonth, timeZone).subtract(1, 'months').toDate());
  };
  const handleDayClick = (date: Date) => {
    setSelectedDay(date);
    setGlobalSelectedDate(date);
  };

  useEffect(() => {
    setSelectedMonth(moment.tz(globalSelectedDate, timeZone).toDate());
    setSelectedDay(globalSelectedDate);
    if (!globalSelectedDate) return;

    if (globalSelectedDate.getTime() < globalDateRange.start.getTime()) {
      setGlobalDateRange({
        start: moment(globalSelectedDate).subtract(1, 'month').toDate(),
      });
    } else if (globalDateRange.end.getTime() < globalSelectedDate.getTime()) {
      setGlobalDateRange({
        end: moment.tz(globalSelectedDate, timeZone).add(1, 'month').toDate(),
      });
    }
    setGlobalSelectedDate(globalSelectedDate);
  }, [globalSelectedDate, timeZone, globalDateRange, selectedDay, setGlobalDateRange, setGlobalSelectedDate]);

  return (
    <div className="flex flex-col justify-center w-full gap-2">
      <div className="flex items-center justify-between">
        <h3 className="font-semibold select-none">{moment.tz(selectedMonth, timeZone).format('MMMM YYYY')}</h3>
        <div className="flex items-center gap-9 mr-[18px]">
          <button type="button" onClick={handleSubtractMonth}>
            <GrFormPrevious className="w-5 h-5 transition-all rounded-full cursor-pointer hover:bg-gray-100" />
          </button>
          <button type="button" onClick={handleAddMonth}>
            <GrFormNext className="w-5 h-5 transition-all rounded-full cursor-pointer hover:bg-gray-100" />
          </button>
        </div>
      </div>
      <div className="grid items-center grid-cols-7">
        {DAYS_HEADER.map((day, index) => {
          return (
            <h1 key={index} className="grid w-full text-[12px] text-gray-500 select-none h-9 place-content-center">
              {day}
            </h1>
          );
        })}
      </div>
      <div className="grid grid-cols-7">
        {generateDates({ month: selectedMonth, currentMonth: selectedMonth, timeZone }).map(
          ({ date, currentMonth, today }, index) => {
            return (
              <div key={index} className="grid text-[12px] text-center h-9 place-content-center">
                {currentMonth ? (
                  <button
                    type="button"
                    className={cn(
                      today ? 'text-calendar-whiteColor bg-primary-500 rounded-full p-2 h-[32px] w-[32px]' : '',
                      selectedDay && moment(date).isSame(selectedDay, 'day') && !today
                        ? 'bg-primary-200 rounded-full p-2 h-[32px] w-[32px]'
                        : ''
                    )}
                    value={date.toString()}
                    onClick={() => handleDayClick(date)}>
                    {moment.tz(date, timeZone).format('D')}
                  </button>
                ) : null}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}
