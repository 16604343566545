import { SuggestionType } from '../nodes/suggestions/types';

export function buildSuggestionId(type: SuggestionType, id: string) {
  return `${type}::${id}`;
}

export function extractSuggestionTypeAndValue(id: string): { type: string; value: string } {
  let getId = id;

  if (getId.includes('~~JOIN~~')) {
    const parts = getId.split(/~~JOIN~~/);
    getId = parts[0];
  }

  const [type, value] = getId.split(/::/);

  return { type, value };
}
