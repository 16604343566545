type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export default function TimeBlockingIcon({ className, size = 14, color = 'black' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={className}>
      <g clipPath="url(#clip0_111_2229)">
        <path
          d="M10.4733 3.02985L6.13992 1.02985C6.09604 1.00954 6.04827 0.999023 5.99992 0.999023C5.95157 0.999023 5.9038 1.00954 5.85992 1.02985L1.52659 3.02985C1.46873 3.05663 1.41976 3.09944 1.38549 3.1532C1.35122 3.20696 1.3331 3.26943 1.33325 3.33318V8.99985C1.3331 9.06361 1.35122 9.12607 1.38549 9.17983C1.41976 9.2336 1.46873 9.27641 1.52659 9.30318L5.85992 11.3032C5.9038 11.3235 5.95157 11.334 5.99992 11.334C6.04827 11.334 6.09604 11.3235 6.13992 11.3032L10.4733 9.30318C10.5311 9.27641 10.5801 9.2336 10.6143 9.17983C10.6486 9.12607 10.6667 9.06361 10.6666 8.99985V3.33318C10.6667 3.26943 10.6486 3.20696 10.6143 3.1532C10.5801 3.09944 10.5311 3.05663 10.4733 3.02985ZM5.99992 4.96652L2.46325 3.33318L5.99992 1.69985L9.53659 3.33318L5.99992 4.96652ZM9.99992 8.78652L6.33325 10.4798V5.54652L9.99992 3.85318V8.78652Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_111_2229">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
