import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment-timezone';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformIntoDate = (object: any, key: string) => {
  if (!object[key]) return object[key];
  return moment(object[key]).toDate();
};

const client = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_URL || process.env.PLASMO_PUBLIC_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const httpGet = async <R, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
  const { data } = await client.get<unknown, AxiosResponse<R>, D>(url, config);
  return data;
};

export const httpPost = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const { data: response } = await client.post<T, AxiosResponse<R>, D>(url, data, config);
  return response;
};

export const httpPostBinary = async <R = AxiosResponse<unknown>>(
  url: string,
  data?: FormData,
  config?: AxiosRequestConfig<FormData>
): Promise<R> => {
  const finalConfig: AxiosRequestConfig<FormData> = {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'json',
  };

  const response = await client.post<unknown, R, FormData>(url, data, finalConfig);
  return response;
};

export const httpDelete = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const { data: response } = await client.delete<T, AxiosResponse<R>, D>(url, config);
  return response;
};

export const httpPatch = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const { data: response } = await client.patch<T, AxiosResponse<R>, D>(url, data, config);
  return response;
};

export const httpPut = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const { data: response } = await client.put<T, AxiosResponse<R>, D>(url, data, config);
  return response;
};

export const httpHead = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>
): Promise<R> => {
  const { data } = await client.head<unknown, AxiosResponse<R>, D>(url, config);
  return data;
};
