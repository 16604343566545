import { Document as TTDocument } from '@tiptap/extension-document';

const Document = TTDocument.extend({
  addAttributes() {
    return {
      date: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-date'),
        renderHTML: (attrs) => ({ 'data-date': attrs.date }),
      },
      disableNewLine: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-disable-new-line'),
        renderHTML: (attrs) => ({ 'data-disable-new-line': attrs.disableNewLine }),
      },
      onlyCreate: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-only-create'),
        renderHTML: (attrs) => ({ 'data-only-create': attrs.onlyCreate }),
      },
    };
  },
});

export default Document;
