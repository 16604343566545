import { forwardRef } from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { cn } from 'ui/cn';

export const ScrollAreaComponent = forwardRef<HTMLDivElement, ScrollArea.ScrollAreaProps>(
  ({ children, className, ...rest }, ref) => (
    <ScrollArea.Root
      {...rest}
      className={cn('w-full rounded overflow-hidden bg-white', className)}
      ref={ref}>
      <ScrollArea.Viewport className="w-full h-full [&>div]:!block mb-4">{children}</ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="flex select-none touch-none p-1 bg-[hsla(0, 0%, 0%, 0.114)] hover:bg-[hsla(0, 0%, 0%, 0.220)] transition duration-150 ease-out"
        orientation="vertical">
        <ScrollArea.Thumb className="flex-1 bg-[#86848d] rounded relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:w-full before:min-w-[50px] before:min-h-[50px]" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="flex select-none touch-none p-1 bg-[#0000001C] hover:bg-[#00000038] transition duration-150 ease-out data-orientation=[horizontal]:flex-col data-orientation=[horizontal]:h-[10px]"
        orientation="horizontal">
        <ScrollArea.Thumb className="flex-1 bg-[#86848d] rounded relative before:content-[''] before:-translate-1/2 before:absolute before:top-1/2 before:left-1/2 before:w-full before:min-w-[50px] before:min-h-[50px]" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner className="bg-red-600" />
    </ScrollArea.Root>
  )
);

ScrollAreaComponent.displayName = 'ScrollAreaComponent';
