import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import moment, { unitOfTime } from 'moment-timezone';
import { SchedulingWhen } from '@prisma/client';
import { useSetAtom } from 'jotai';

import { DateObject, DatePicker } from '@/components/DatePicker';
import { findTimeZone, roundToNextBlock } from '@/utils/date';
import Switch from '@/components/Switch';
import { Select } from '@/components/Select';
import { SchedulingLink } from '@/models/SchedulingLinkModel';
import { ALL_ROLLING_INTERVALS, RollingInterval } from '@/models/shared';
import { FloatingInput } from '@/components/FloatingInput';
import { slotsAtom } from '@/components/calendarAtoms';
import { generateSlots } from '@/utils/scheduling';

import { useSettings } from '@/hooks/useSettings';
import useScheduleIntervals from './useScheduleIntervals';
import { useAvailability } from '@/hooks/useAvailability';

export enum TimePeriod {
  INDEFINITELY = 'Indefinitely',
  BETWEEN = 'Between two dates',
  ROLLING_DATE = 'Rolling Date Range',
}

const WHEN_OPTIONS: SchedulingWhen[] = ['THIS_WEEK', 'NEXT_WEEK', 'THIS_MONTH', 'CUSTOM_DATES'];
const TIME_PERIOD_OPTIONS = Object.values(TimePeriod);

function getWhenLabel(when: Omit<SchedulingWhen, 'RECURRING_SLOTS'>, timeZone: string) {
  switch (when) {
    case 'THIS_WEEK':
      return 'This Week';
    case 'NEXT_WEEK':
      return 'Next Week';
    case 'THIS_MONTH':
      return `This Month - ${moment.tz(timeZone).format('MMM')}`;
    case 'CUSTOM_DATES':
      return 'Custom Dates';
  }
}

type Props = {
  start?: Date | null;
  end?: Date | null;
  when?: SchedulingWhen;
  useCustomSchedule?: boolean;
  recurrence?: SchedulingLink['recurrence'];
  duration?: number;
  scheduleId?: string;
  calendarIds?: string[];
  contactEmails?: string[];
  onChange: (value: {
    start?: Date | null;
    end?: Date | null;
    when?: SchedulingWhen;
    useCustomSchedule?: boolean;
    recurrence?: SchedulingLink['recurrence'];
  }) => void;
};

export default function DateRangePicker({
  start = null,
  end = null,
  duration,
  scheduleId,
  calendarIds,
  contactEmails,
  when,
  recurrence,
  useCustomSchedule,
  onChange,
}: Props) {
  const { defaultTimeZone: timeZone, defaultDuration = 30 } = useSettings();
  const scheduleIntervals = useScheduleIntervals(scheduleId);
  const { defaultAvailability } = useAvailability();

  const [timePeriod, setTimePeriod] = useState<TimePeriod | null>(null);
  const setSlots = useSetAtom(slotsAtom);

  const timeZoneData = findTimeZone(timeZone);

  const handleChangeDate = useCallback(
    (key: 'start' | 'end') => (value: DateObject) => {
      onChange({ [key]: value.toDate(), when: 'CUSTOM_DATES' });
      Promise.all([
        generateSlots({
          start: key === 'start' ? value.toDate() : start || new Date(),
          end: key === 'end' ? value.toDate() : end || new Date(),
          duration: duration || defaultDuration || 30,
          scheduleIntervals: scheduleIntervals,
          timeZone,
          checkCalendarIds: calendarIds,
          contactEmails,
        }),
      ]).then(([slots]) => setSlots(slots));
    },
    [calendarIds, contactEmails, defaultDuration, duration, end, onChange, scheduleIntervals, setSlots, start, timeZone]
  );

  const toggleUseCustomSchedule = useCallback(
    async (value: boolean) => {
      const changes = { useCustomSchedule: value } as Partial<SchedulingLink>;
      if (!value) {
        changes.scheduleId = defaultAvailability?.id;
        setSlots([]);
      } else {
        const slots = await generateSlots({
          start: start || new Date(),
          end: end || new Date(),
          duration: duration || defaultDuration || 30,
          scheduleIntervals: scheduleIntervals,
          timeZone,
          checkCalendarIds: calendarIds,
          contactEmails,
        });
        setSlots(slots);
      }
      onChange(changes);
    },
    [
      calendarIds,
      contactEmails,
      defaultAvailability?.id,
      defaultDuration,
      duration,
      end,
      onChange,
      scheduleIntervals,
      setSlots,
      start,
      timeZone,
    ]
  );

  const handleChangeWhen = useCallback(
    async (when: SchedulingWhen) => {
      let start: Date;
      let end: Date;

      switch (when) {
        case 'CUSTOM_DATES':
          onChange({ when });
          return;
        case 'THIS_WEEK':
          start = roundToNextBlock(new Date(), 30);
          end = moment.tz(timeZone).add(1, 'week').startOf('week').toDate();
          break;
        case 'NEXT_WEEK':
          start = moment.tz(timeZone).add(1, 'week').startOf('week').toDate();
          end = moment.tz(timeZone).add(2, 'weeks').startOf('week').toDate();
          break;
        case 'THIS_MONTH':
          start = roundToNextBlock(new Date(), 30);
          end = moment.tz(timeZone).add(1, 'month').startOf('month').toDate();
          break;
        default:
          return;
      }

      onChange({ start, end, when });
      const slots = await generateSlots({
        start,
        end,
        duration: duration || defaultDuration || 30,
        scheduleIntervals: scheduleIntervals,
        timeZone,
        checkCalendarIds: calendarIds,
        contactEmails,
      });

      setSlots(slots);
    },
    [calendarIds, contactEmails, defaultDuration, duration, onChange, scheduleIntervals, setSlots, timeZone]
  );

  const handleChangeRecurrence = useCallback(
    (value: boolean) => {
      const changes = {
        when: value ? 'RECURRING_SLOTS' : 'CUSTOM_DATES',
        recurrence: undefined,
      } as Partial<SchedulingLink>;

      if (value) {
        changes.slots = undefined;
      } else {
        changes.recurringSlots = undefined;
      }

      onChange(changes);
    },
    [onChange]
  );

  const handleChangeTimePeriod = useCallback(
    (value: TimePeriod) => {
      setTimePeriod(value);
      if (value === TimePeriod.ROLLING_DATE) {
        onChange({
          recurrence: {
            rollingInterval: 'Days',
            rollingNumber: 1,
          },
        });
      }

      if (value === TimePeriod.INDEFINITELY) {
        onChange({ start: null, end: null, recurrence: undefined });
        return;
      }

      onChange({ recurrence: undefined });
    },
    [onChange]
  );

  function handleOnChangeRollingInterval(value: RollingInterval) {
    onChange({
      recurrence: {
        rollingInterval: value,
        rollingNumber: recurrence?.rollingNumber || 1,
      },
    });
  }

  function handleOnChangeRollingNumber(event: ChangeEvent<HTMLInputElement>) {
    onChange({
      recurrence: {
        rollingInterval: recurrence?.rollingInterval ?? 'Days',
        rollingNumber: Number(event.target.value),
      },
    });
  }

  useEffect(() => {
    if (!recurrence?.rollingInterval || !recurrence.rollingNumber) return;
    onChange({
      start: new Date(),
      end: moment
        .tz()
        .add(recurrence.rollingNumber, recurrence.rollingInterval?.toLowerCase() as unitOfTime.DurationConstructor)
        .toDate(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrence]);

  useEffect(() => {
    if (when !== 'RECURRING_SLOTS') {
      setTimePeriod(null);
      return;
    }

    if (recurrence) {
      setTimePeriod(TimePeriod.ROLLING_DATE);
      return;
    }

    if (start && end) {
      setTimePeriod(TimePeriod.BETWEEN);
      return;
    }

    setTimePeriod(TimePeriod.INDEFINITELY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  return (
    <>
      <div className="flex justify-between gap-3 text-sm text-secondary-300">
        {useCustomSchedule ? (
          <div className="flex-1">
            {when !== 'RECURRING_SLOTS' ? (
              <Select
                value={when || 'THIS_WEEK'}
                CustomTrigger={(props) => (
                  <div className="text-left cursor-pointer hover:text-primary-500 xs:text-[12px]" {...props} />
                )}
                extractLabel={(option) => getWhenLabel(option, timeZone)}
                extractValue={(option) => option}
                onChange={handleChangeWhen}
                options={WHEN_OPTIONS}
              />
            ) : (
              <Select
                options={TIME_PERIOD_OPTIONS}
                CustomTrigger={(props) => (
                  <div className="text-left cursor-pointer hover:text-primary-500 xs:text-[12px]" {...props} />
                )}
                value={timePeriod || TimePeriod.INDEFINITELY}
                extractLabel={(option) => option}
                extractValue={(option) => option}
                onChange={handleChangeTimePeriod}
              />
            )}
          </div>
        ) : null}
        <span className="flex-1 truncate">
          {timeZoneData?.abbr} ({timeZoneData?.value.replace(/_/g, ' ')})
        </span>
      </div>
      {useCustomSchedule ? (
        <>
          {(!timePeriod || timePeriod === TimePeriod.BETWEEN) && (
            <div className="flex w-full gap-3">
              <DatePicker label="Start" className="flex-1" value={start} onChange={handleChangeDate('start')} />
              <DatePicker
                label="End"
                className="flex-1"
                value={end}
                minDate={start ?? new Date()}
                onChange={handleChangeDate('end')}
              />
            </div>
          )}
          {timePeriod === TimePeriod.ROLLING_DATE && (
            <div className="flex gap-3">
              <FloatingInput
                className="flex-1"
                label="Number"
                rounded
                variant="primary"
                type="number"
                min={1}
                value={recurrence?.rollingNumber || 1}
                onChange={handleOnChangeRollingNumber}
              />
              <Select
                className="flex-1"
                options={ALL_ROLLING_INTERVALS}
                label="Interval"
                value={recurrence?.rollingInterval ?? 'Days'}
                extractLabel={(option) => option}
                extractValue={(option) => option}
                onChange={handleOnChangeRollingInterval}
              />
            </div>
          )}
        </>
      ) : null}
      <Switch
        name="useCustomSchedule"
        label="Use a custom schedule"
        value={!!useCustomSchedule}
        onChange={toggleUseCustomSchedule}
      />
      <Switch label="Recurring" value={when === 'RECURRING_SLOTS'} onChange={handleChangeRecurrence} />
    </>
  );
}
