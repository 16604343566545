import { useProjects } from '@/hooks/useProjects';
import { generateRandomColor } from '@/utils/generateRandomColor';
import * as Popover from '@radix-ui/react-popover';
import { useEffect, useMemo, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

type Props = {
  projectId: string;
};

export default function ProjectColorPicker({ projectId }: Props) {
  const { projects, updateProject } = useProjects({});
  const project = useMemo(() => projects.find(({ id }) => id === projectId), [projectId, projects]);
  const [color, setColor] = useState<string>(project?.color || generateRandomColor());

  useEffect(() => {
    setColor(project?.color || generateRandomColor());
  }, [project?.color]);

  const handleOnOpenChange = (open: boolean) => {
    if (open || !project) return;
    updateProject({
      projectId: project.id,
      project: {
        name: project.name,
        color,
        eventIds: project.eventIds,
        parentProjectId: project.parentProjectId ?? '',
        schedulingLinksIds: project.schedulingLinksIds,
        tasksIds: project.tasksIds,
      },
    });
  };

  if (!project) {
    return null;
  }

  return (
    <Popover.Root onOpenChange={handleOnOpenChange}>
      <Popover.Trigger onClick={(e) => e.stopPropagation()}>
        <div className="w-2.5 h-2.5 mr-2 rounded-full" style={{ background: color }} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content onClick={(e) => e.stopPropagation()}>
          <SketchPicker color={color} onChange={({ hex }: ColorResult) => setColor(hex)} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
