import type { Editor } from '@tiptap/core';
import useDndContext from '@/hooks/useDndContext';
import { useEffect } from 'react';

export default function useDndHandler(editor: Editor | null) {
  const { dndContext } = useDndContext();

  useEffect(() => {
    if (!dndContext.isDragging || !editor?.isFocused) return;
    editor.commands.blur();
  }, [dndContext.isDragging, editor]);
}
