type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const ContactsIcon = ({ className, size = 18, color = '#626262' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99719 9.80005C10.6118 9.80005 11.8969 8.49055 11.8969 6.87505C11.8969 5.25955 10.5879 3.95005 8.99719 3.95005C7.38253 3.95005 6.0975 5.25955 6.0975 6.87505C6.07219 8.48942 7.38281 9.80005 8.99719 9.80005ZM10.4034 10.7H7.59656C5.38875 10.7 3.6 12.3791 3.6 14.4491C3.6 14.8654 3.95719 15.2 4.39875 15.2H13.6012C14.0428 15.2 14.4 14.8654 14.4 14.4491C14.4 12.3791 12.6112 10.7 10.4034 10.7ZM14.4 5.30005C15.6426 5.30005 16.65 4.29261 16.65 3.05005C16.65 1.80749 15.6431 0.800049 14.4 0.800049C13.1574 0.800049 12.15 1.80749 12.15 3.05005C12.15 4.29261 13.1569 5.30005 14.4 5.30005ZM5.17219 6.87505C5.17219 6.7218 5.19981 6.57608 5.21744 6.42758C4.91062 6.25911 4.57312 6.20005 4.21594 6.20005H2.47725C1.10925 6.20005 0 7.37567 0 8.82411C0 9.1138 0.221822 9.35005 0.495563 9.35005H6.1065C5.53219 8.68067 5.17219 7.82286 5.17219 6.87505ZM3.6 5.30005C4.84256 5.30005 5.85 4.29261 5.85 3.05005C5.85 1.80749 4.84312 0.800049 3.6 0.800049C2.35744 0.800049 1.35 1.80749 1.35 3.05005C1.35 4.29261 2.35744 5.30005 3.6 5.30005ZM15.5222 6.20005H13.7829C13.4229 6.20005 13.0832 6.28546 12.7744 6.4318C12.7913 6.57974 12.8194 6.72317 12.8194 6.87505C12.8194 7.82314 12.4599 8.68095 11.8868 9.35005H17.5033C17.7778 9.35005 18 9.1138 18 8.82411C18 7.37567 16.8919 6.20005 15.5222 6.20005Z"
      fill={color}
    />
  </svg>
);
