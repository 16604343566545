import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { TaskIcon } from '@/icons/TaskIcon';
import { menuStateAtom } from './CommandBox/NewCommandBox';
import { useAtom } from 'jotai';
import { cn } from 'ui/cn';

type MenuModalProps = {
  onChange: (type: 'TASK' | 'EVENT' | 'SCHEDULING_LINK' | 'NOTE') => void;
  itemType: 'TASK' | 'EVENT' | 'SCHEDULING_LINK' | 'NOTE' | 'EMAIL' | 'WORKFLOW' | 'CONTACT' | 'SEARCH';
};

export const MenuModal = ({ onChange, itemType }: MenuModalProps) => {
  const [menuState] = useAtom(menuStateAtom);
  return (
    <div
      className={cn(
        'absolute top-5 -left-2 z-50 rounded-[4px] shadow-popover min-w-[210px] text-sm bg-white cursor-pointer',
        menuState ? 'block' : 'hidden'
      )}>
      <div
        className={cn(
          'flex flex-row h-10 items-center pl-2 gap-2 hover:bg-select-light-gray',
          itemType === 'TASK' ? 'bg-select-light-gray' : ''
        )}
        onClick={() => onChange('TASK')}>
        <TaskIcon color="black" />
        <span>Task</span>
      </div>
      <div
        className={cn(
          'flex flex-row h-10 items-center pl-2 gap-2 hover:bg-select-light-gray',
          itemType === 'EVENT' ? 'bg-select-light-gray' : ''
        )}
        onClick={() => onChange('EVENT')}>
        <EventIcon color="black" />
        <span>Event</span>
      </div>
      <div
        className={cn(
          'flex flex-row h-10 items-center pl-2 gap-2 hover:bg-select-light-gray',
          itemType === 'SCHEDULING_LINK' ? 'bg-select-light-gray' : ''
        )}
        onClick={() => onChange('SCHEDULING_LINK')}>
        <SchedulingLinkIcon color="black" />
        <span>Scheduling Link</span>
      </div>
      <div
        className={cn(
          'flex flex-row h-10 items-center pl-2 gap-2 hover:bg-select-light-gray',
          itemType === 'NOTE' ? 'bg-select-light-gray' : ''
        )}
        onClick={() => onChange('NOTE')}>
        <NoteIcon color="black" />
        <span>Note</span>
      </div>
    </div>
  );
};
