import { createDraggableId, DraggableType, DroppableType, getDraggableStyles } from '@/utils/dnd';
import { Draggable } from 'react-beautiful-dnd';
import { cn } from 'ui/cn';
import moment from 'moment-timezone';
import { CSSProperties, Fragment, useCallback, useContext, useMemo } from 'react';
import useLeftSidebar, { LeftSidebarType } from '@/components/LeftSidebar/useLeftSidebar';
import { ParagraphText } from '@/components/Typography';
import { Task as TaskType } from '@/models/TaskModel';
import { Event as EventType } from '@/models/EventModel';
import { EventIcon } from '@/icons/EventIcon';
import { ItemType, Slot as SlotType } from '../types';
import CalendarContext from '@/components/Calendar/context';
import { useAtomValue, useSetAtom } from 'jotai';
import { calendarTypeAtom, recurringSlotsAtom, slotsAtom } from '@/components/calendarAtoms';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { calcHeight, updateRecurringSlot } from '../utils';
import { ContactEvent as ContactEventType } from '@/hooks/useContactEvents';
import { blendWithWhite } from '@/utils/colors';
import { useTaskCompleted } from '@/hooks/useTasks';
import { ConferenceDataProps, ContactContextMenu } from '@/components/CommandBox/DragHandleMouseOver';
import { HoverCard } from '@/components/HoverCard';
import { useEasySession } from '@/hooks/useEasySession';
import { AttendeeResponseStatus } from '@/utils/contacts';
import { useEvents } from '@/hooks/useEvents';
import ProjectColorPicker from '@/components/ProjectColorPicker';
import { useProjects } from '@/hooks/useProjects';

type CommonProps = {
  item: { start?: Date | null; end?: Date | null; allDay?: boolean | null };
  className?: string;
};

export function Common({ item, className }: CommonProps) {
  const { timeZone, hourHeight } = useContext(CalendarContext);
  const _height = calcHeight(hourHeight, timeZone, item.start!, item.end!);

  return (
    <div className={cn('flex flex-col px-1', className)}>
      <ParagraphText
        className={`flex overflow-hidden ${
          _height < 20 ? '!text-[9px] leading-none' : 'text-xs leading-tight'
        } font-semibold text-clip`}>
        {item?.allDay ? (
          'All day'
        ) : (
          <>
            {moment.tz(item.start, timeZone).format('h:mma')}
            {' - '}
            {moment.tz(item.end, timeZone).format('h:mma')}
          </>
        )}
      </ParagraphText>
    </div>
  );
}

type TaskProps = {
  item: TaskType;
  showDuration: boolean;
};

function Task({ item, showDuration }: TaskProps) {
  const { timeZone } = useContext(CalendarContext);
  const { toggleTaskCompleted } = useTaskCompleted();
  const { projects } = useProjects({});
  const id = item.projectIds?.find((projectId) => projects.filter((p) => p.id === projectId));

  return (
    <>
      <div className="h-full w-[4px] mr-1 bg-primary-500" />
      <div className="flex items-center">
        {id ? <ProjectColorPicker projectId={id} /> : null}
        <input
          name="isCompleted"
          type="checkbox"
          className="mr-[4px] relative mt-1 border-primary-500 bg-primary-500 w-[15px] h-[15px] border-[2px] [&:not(:checked)]:!bg-transparent border-solid rounded appearance-none checked:after:absolute checked:after:top-0 checked:after:left-[2px] checked:after:h-2 checked:after:w-1.5 checked:after:rotate-45 checked:after:border-[2px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:content-[''] hover:cursor-pointer focus:shadow-none focus:transition-[border-color_0.2s]"
          defaultChecked={item.isCompleted}
          onClick={(e) => {
            e.stopPropagation();
            toggleTaskCompleted({ isCompleted: e.currentTarget.checked, taskId: item.id });
          }}
        />
      </div>

      <div className="flex flex-col max-w-[90%] gap-0.5 pt-1">
        <ParagraphText className="overflow-hidden text-xs font-semibold leading-tight text-clip">
          {item.summary}
        </ParagraphText>
        {showDuration ? (
          <ParagraphText className="text-xs leading-tight text-clip">
            {moment.tz(item.start, timeZone).format('h:mma')}
            {' - '}
            {moment.tz(item.end, timeZone).format('h:mma')}
          </ParagraphText>
        ) : null}
      </div>
    </>
  );
}

type EventProps = {
  item: EventType;
  showDuration: boolean;
  userEmail?: string | null;
};

function Event({ item, showDuration, userEmail }: EventProps) {
  const { timeZone, hourHeight } = useContext(CalendarContext);
  const { updateEvent } = useEvents({});
  const _height = calcHeight(hourHeight, timeZone, item.start!, item.end!);
  const backgroundColor = item.backgroundColor ?? '#279AF1';
  const isEventHoverCardDisabled =
    item?.conferenceData ?? item?.hangoutLink ?? item?.location ?? item?.attendees?.length;
  const status = item.attendees?.find((c) => c.email === userEmail)?.responseStatus;
  const { projects } = useProjects({});
  const idProject = item.projectIds?.find((projectId) => projects.filter((p) => p.id === projectId));

  const handleChangeStatus = (status: AttendeeResponseStatus) => {
    if (!item) return;
    item.attendees?.forEach((c) => {
      if (c.email === userEmail) {
        c.responseStatus = status;
      }
    });
    updateEvent({ eventId: item.id, event: item });
  };

  return (
    <>
      <div className="w-1 h-full mr-1" style={{ backgroundColor }} />
      <div className="flex flex-col max-w-[90%] gap-0.5 pt-1">
        <div className="flex items-center">
          {idProject ? <ProjectColorPicker projectId={idProject} /> : null}
          <div className="flex flex-col">
            {!isEventHoverCardDisabled ? (
              <ParagraphText
                className={`flex overflow-hidden ${
                  _height < 20 ? '!text-[10px] leading-none' : 'text-xs leading-tight'
                } font-semibold text-clip ${status === 'declined' ? 'line-through' : ''}`}>
                {item.summary}
              </ParagraphText>
            ) : (
              <HoverCard
                className="p-[8px]"
                trigger={
                  <ParagraphText
                    className={`flex overflow-hidden ${
                      _height < 20 ? '!text-[10px] leading-none' : 'text-xs leading-tight'
                    } font-semibold  text-clip ${status === 'declined' ? 'line-through' : ''}`}>
                    {item.summary}
                  </ParagraphText>
                }>
                <ContactContextMenu
                  conferenceData={item?.conferenceData as ConferenceDataProps}
                  hangoutLink={item?.hangoutLink}
                  location={item?.location}
                  contacts={item?.attendees?.map((attendee) => ({
                    email: attendee.email || '',
                    name: attendee.name || '',
                    responseStatus: attendee.responseStatus || '',
                  }))}
                  creatorEmail={item?.creator as { email: string }}
                  takeMeetingNotes={false}
                  status={status}
                  onChangeStatus={handleChangeStatus}
                />
              </HoverCard>
            )}
            {showDuration ? (
              <ParagraphText className="block text-xs leading-tight text-clip">
                {moment.tz(item.start, timeZone).format('h:mma')}
                {' - '}
                {moment.tz(item.end, timeZone).format('h:mma')}
              </ParagraphText>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

type contactEventProps = {
  item: ContactEventType;
  showDuration: boolean;
};

function ContactEvent({ item, showDuration }: contactEventProps) {
  const { timeZone } = useContext(CalendarContext);

  return (
    <>
      <EventIcon className="h-5 mr-1 shrink-0" color={item.foregroundColor} size={15} />
      <div className="flex flex-col max-w-[90%] gap-[2px]">
        <ParagraphText className="flex overflow-hidden text-xs font-semibold leading-tight text-clip">
          {item.summary || 'busy'}
        </ParagraphText>
        {showDuration ? (
          <ParagraphText className="block text-xs leading-tight text-clip">
            {moment.tz(item.start, timeZone).format('h:mma')}
            {' - '}
            {moment.tz(item.end, timeZone).format('h:mma')}
          </ParagraphText>
        ) : null}
      </div>
    </>
  );
}

type SlotProps = {
  item: SlotType;
};

function Slot({ item }: SlotProps) {
  const { timeZone } = useContext(CalendarContext);
  const setSlots = useSetAtom(slotsAtom);
  const setRecurringSlots = useSetAtom(recurringSlotsAtom);
  const calendarType = useAtomValue(calendarTypeAtom);

  const handleOnDelete = useCallback(() => {
    if (calendarType === 'SPECIFIC') {
      setSlots((prev) => prev.filter((_, index) => index !== Number(item.id)));
      return;
    }
    setRecurringSlots((prev) => updateRecurringSlot(prev, item, null, timeZone));
  }, [calendarType, item, setRecurringSlots, setSlots, timeZone]);

  return (
    <Fragment key={item.id}>
      <Common item={item} className="z-50" />
      <button type="button" onClick={handleOnDelete} className="absolute right-1 bottom-1">
        <DeleteIcon className="text-gray-400 cursor-pointer hover:fill-red-500 transition-all" />
      </button>
    </Fragment>
  );
}

type Props = {
  item: ItemType;
  index: number;
  className?: string;
  style?: CSSProperties;
  onChangeStart?: () => void;
  onChangeEnd?: () => void;
};

export default function Item({ item, index, className, style, onChangeStart, onChangeEnd }: Props) {
  const { hourHeight, timeZone } = useContext(CalendarContext);
  const { openLeftSidebar } = useLeftSidebar();
  const { user } = useEasySession();

  const handleOpenItemDetails = useCallback(
    ({ id, itemType, end, start, allDay }: ItemType) =>
      () => {
        let sidebarType: LeftSidebarType;
        switch (itemType) {
          case 'EVENT':
            sidebarType = 'EVENT_EDITOR';
            break;
          case 'TASK':
            sidebarType = 'TASK_EDITOR';
            break;
          default:
            return;
        }
        openLeftSidebar({
          type: sidebarType,
          context: {
            id,
            end,
            start,
            isAllDay: allDay,
            autoFocus: true,
          },
        });
      },
    [openLeftSidebar]
  );

  const contentStyle = useMemo(() => {
    if (item.itemType !== 'EVENT' && item.itemType !== 'CONTACT_EVENT') return {};

    return {
      backgroundColor: `${blendWithWhite(item.backgroundColor ?? '#279AF1', 80)}`,
      color: item.backgroundColor,
    };
  }, [item]);

  const taskStyle = useMemo(() => {
    if (item.itemType !== 'TASK') return {};

    return {
      backgroundColor: `${blendWithWhite('#279AF1', 80)}`,
      color: '#279AF1',
    };
  }, [item]);

  const showDuration = useMemo(() => {
    if ('allDay' in item && item.allDay) return false;
    if (!item.start || !item.end) return false;

    return moment(item.end).diff(item.start, 'minutes') >= 45;
  }, [item]);

  const renderedContent = useMemo(() => {
    switch (item.itemType) {
      case 'EVENT':
        return <Event item={item} userEmail={user?.email} showDuration={showDuration} />;
      case 'TASK':
        return <Task item={item} showDuration={showDuration} />;
      case 'SLOT':
        return <Slot item={item} />;
      case 'CONTACT_EVENT':
        return <ContactEvent item={item} showDuration={showDuration} />;
      default:
        return <Common item={item} />;
    }
  }, [item, showDuration, user?.email]);

  const eventPendingAnswerStyle = useMemo(() => {
    if (
      item.itemType === 'EVENT' &&
      (item.status === 'tentative' ||
        ['needsAction', 'tentative'].includes(item.attendees?.find((attendee) => attendee.self)?.responseStatus ?? ''))
    ) {
      return {
        backgroundColor: `${blendWithWhite(item.backgroundColor ?? '#279AF1', 95)}`,
        borderColor: '#C5E4FB',
        borderWidth: '2px',
        borderStyle: 'dashed',
      };
    }

    return {};
  }, [item]);

  const _height = calcHeight(hourHeight, timeZone, item.start!, item.end!);

  return (
    <Draggable
      draggableId={createDraggableId(DroppableType.CALENDAR, item.itemType as DraggableType, item.id)}
      key={item.id}
      index={index}
      shouldRespectForcePress>
      {(draggableProvided, snapshot) => (
        <div
          key={item.id}
          className={cn(
            'absolute inset-x-0 flex flex-row focus:z-10',
            (item.itemType === 'BOOKING' || item.itemType === 'CONTACT_EVENT') && 'pointer-events-none',
            'allDay' in item && item.allDay && !snapshot.isDragging && 'static'
          )}
          style={style}
          tabIndex={-1}
          data-targetable-item
          data-item-id={item.id}
          data-item-type={item.itemType}>
          {_height >= 20 && onChangeStart ? (
            <div className="absolute top-0 z-10 w-full h-1.5 cursor-ns-resize" onMouseDown={onChangeStart} />
          ) : null}
          <div
            className={cn(
              'w-full rounded-md text-left text-black whitespace-nowrap flex items-start overflow-hidden border border-white',
              snapshot.isDragging && 'opacity-50',
              className,
              item.itemType === 'SLOT' && 'bg-calendar-recommendedSlotBackgroundColor/40',
              item.itemType === 'TASK' && item.isCompleted && 'opacity-60',
              item.itemType === 'SLOT' && snapshot.isDragging && 'opacity-0',
              item.itemType === 'BOOKING' && 'bg-emerald-500 text-white'
            )}
            ref={draggableProvided?.innerRef}
            {...draggableProvided?.draggableProps}
            {...draggableProvided?.dragHandleProps}
            onClick={handleOpenItemDetails(item)}
            style={{
              ...getDraggableStyles(draggableProvided?.draggableProps.style, snapshot, 'allDay' in item && item.allDay),
              ...contentStyle,
              ...taskStyle,
              ...eventPendingAnswerStyle,
            }}>
            {renderedContent}
          </div>
          {onChangeEnd && <div className="absolute bottom-0 w-full h-1.5 cursor-ns-resize" onMouseDown={onChangeEnd} />}
        </div>
      )}
    </Draggable>
  );
}
