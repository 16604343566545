import { useNotes } from '@/hooks/useNotes';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Node } from '@tiptap/core';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Editor, EditorContent, NodeViewWrapper, ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import moment from 'moment-timezone';
import { ReactNode, useEffect, useState } from 'react';
import TypingText from './Common/TypingText';
import { httpGet } from 'utils/smarty-api';
import { Link } from '@tiptap/extension-link';
import {
  MdCode,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdLink,
  MdRedo,
  MdStrikethroughS,
  MdUndo,
} from 'react-icons/md';
import { FaLink, FaRegEdit } from 'react-icons/fa';

type TipTapProps = {
  placeholder: string;
  content: any;
  setContent: (content: string) => void;
  children?: ReactNode;
  isNotes?: boolean;
  isThread?: boolean;
  showUndoRedo?: boolean;
  showDateTimeVars?: boolean;
  showTimeVars?: boolean;
  showDateTimeShiftVars?: boolean;
  showAIButton?: boolean;
  classNameBox?: string;
  classNameEditor?: string;
};

type MenuButtonProps = {
  icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

type DropDownProps = {
  buttonName: string;
  children: ReactNode;
};

type LinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedText: string;
  editor: Editor;
};

export const tipTapVarList1 = [
  {
    label: 'Long full date',
    value: '{{MMMM Do YYYY}}',
    valueOriginal: 'MMMM Do YYYY',
    placeholder: moment().format('MMMM Do YYYY'),
  },
  {
    label: 'Short full date',
    value: '{{MM-DD-YY}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().format('MM-DD-YY'),
    borderBottom: true,
  },
  {
    label: 'Long year',
    value: '{{YYYY}}',
    valueOriginal: 'YYYY',
    placeholder: moment().format('YYYY'),
  },
  {
    label: 'Short year',
    value: '{{YY}}',
    valueOriginal: 'YY',
    placeholder: moment().format('YY'),
    borderBottom: true,
  },
  {
    label: 'Long month',
    value: '{{MMMM}}',
    valueOriginal: 'MMMM',
    placeholder: moment().format('MMMM'),
  },
  {
    label: 'Long day',
    value: '{{dddd}}',
    valueOriginal: 'dddd',
    placeholder: moment().format('dddd'),
    borderBottom: true,
  },
  {
    label: 'Short month',
    value: '{{MMM}}',
    valueOriginal: 'MMM',
    placeholder: moment().format('MMM'),
  },
  {
    label: 'Short day',
    value: '{{ddd}}',
    valueOriginal: 'ddd',
    placeholder: moment().format('ddd'),
    borderBottom: true,
  },
  {
    label: 'Month (01-12)',
    value: '{{MM}}',
    valueOriginal: 'MM',
    placeholder: moment().format('MM'),
  },
  {
    label: 'Day (01-31)',
    value: '{{DD}}',
    valueOriginal: 'DD',
    placeholder: moment().format('DD'),
    borderBottom: true,
  },
  {
    label: 'Month (1-12)',
    value: '{{M}}',
    valueOriginal: 'M',
    placeholder: moment().format('M'),
  },
  {
    label: 'Day (1-31)',
    value: '{{D}}',
    valueOriginal: 'D',
    placeholder: moment().format('D'),
  },
];
export const tipTapVarList2 = [
  {
    label: 'AM/PM',
    value: '{{A}}',
    valueOriginal: 'A',
    placeholder: moment().format('A'),
    borderBottom: true,
  },
  {
    label: 'Hours (00-23)',
    value: '{{HH}}',
    valueOriginal: 'HH',
    placeholder: moment().format('HH'),
  },
  {
    label: 'Hours (01-12)',
    value: '{{hh}}',
    valueOriginal: 'hh',
    placeholder: moment().format('hh'),
  },
  {
    label: 'Minutes (00-59)',
    value: '{{mm}}',
    valueOriginal: 'mm',
    placeholder: moment().format('mm'),
  },
  {
    label: 'Seconds (00-59)',
    value: '{{ss}}',
    valueOriginal: 'ss',
    placeholder: moment().format('ss'),
    borderBottom: true,
  },
  {
    label: 'Hours (0-23)',
    value: '{{H}}',
    valueOriginal: 'H',
    placeholder: moment().format('H'),
  },
  {
    label: 'Hours (1-12)',
    value: '{{h}}',
    valueOriginal: 'h',
    placeholder: moment().format('h'),
  },
  {
    label: 'Minutes (0-59)',
    value: '{{m}}',
    valueOriginal: 'm',
    placeholder: moment().format('m'),
    borderBottom: true,
  },
  {
    label: 'Seconds (0-59)',
    value: '{{s}}',
    valueOriginal: 's',
    placeholder: moment().format('s'),
  },
  {
    label: 'Full time',
    value: '{{h:mm:ss A}}',
    valueOriginal: 'h:mm:ss A',
    placeholder: moment().format('h:mm:ss A'),
  },
];
export const tipTapVarList3 = [
  {
    label: 'Date in 1 day',
    value: '{{MM-DD-YY; shift 1D}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(1, 'day').format('MM-DD-YY'),
  },
  {
    label: 'Date in 2 day',
    value: '{{MM-DD-YY; shift 2D}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(2, 'day').format('MM-DD-YY'),
  },
  {
    label: 'Date in 3 day',
    value: '{{MM-DD-YY; shift 3D}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(3, 'day').format('MM-DD-YY'),
    borderBottom: true,
  },
  {
    label: 'Date in 1 week',
    value: '{{MM-DD-YY; shift 1W}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(1, 'week').format('MM-DD-YY'),
  },
  {
    label: 'Date in 1 month',
    value: '{{MM-DD-YY; shift 1M}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(1, 'month').format('MM-DD-YY'),
  },
  {
    label: 'Date in 1 year',
    value: '{{MM-DD-YY ; shift 1Y}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().add(1, 'year').format('MM-DD-YY'),
    borderBottom: true,
  },
  {
    label: 'Next monday',
    value: '{{dddd MM-DD-YY; shift +1D > MON}}',
    valueOriginal: 'dddd MM-DD-YY',
    placeholder: moment().day(1).format('dddd MM-DD-YY'),
  },
  {
    label: 'Start of next month',
    value: '{{dddd MM-DD-YY; shift > M + 1D}}',
    valueOriginal: 'dddd MM-DD-YY',
    placeholder: moment().add(1, 'M').startOf('month').format('dddd MM-DD-YY'),
    borderBottom: true,
  },
  {
    label: 'Time in 6 hours',
    value: '{{h:mm:ss A; shift +6h}}',
    valueOriginal: 'h:mm:ss A',
    placeholder: moment().add(6, 'hours').format('h:mm:ss A'),
    borderBottom: true,
  },
  {
    label: 'Date 1 year ago',
    value: '{{MM-DD-YY; shift -1Y}}',
    valueOriginal: 'MM-DD-YY',
    placeholder: moment().subtract(1, 'year').format('MM-DD-YY'),
  },
];

const MenuButton = ({ icon, onClick, disabled, className }: MenuButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${disabled ? 'disabled' : ''} tipTabButton`}>
      {icon}
    </button>
  );
};

const ensureHttpPrefix = (inputUrl: string): string => {
  if (!inputUrl.startsWith('http://') && !inputUrl.startsWith('https://')) {
    return 'http://' + inputUrl;
  }
  return inputUrl;
};

const LinkModal = ({ isOpen, onClose, selectedText, editor }: LinkModalProps) => {
  const [text, setText] = useState(selectedText || '');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setText(selectedText || '');
  }, [selectedText]);

  useEffect(() => {
    if (!isOpen) {
      setText('');
      setUrl('');
    }
  }, [isOpen]);

  const handleSave = () => {
    const fullUrl = ensureHttpPrefix(url);
    if (editor) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: fullUrl }).insertContent(text).run();
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg min-w-[400px]">
        <h2 className="text-xl mb-4">Edit link</h2>

        <div className="mb-3 flex items-center gap-2">
          <span className="icon-text">
            <FaRegEdit />
          </span>
          <input
            type="text"
            value={text}
            placeholder="Text to display"
            onChange={(e) => setText(e.target.value)}
            className="border p-2 rounded w-full bg-gray-100"
          />
        </div>

        <div className="mb-3 flex items-center gap-2">
          <span className="icon-link">
            <FaLink />
          </span>
          <input
            type="url"
            value={url}
            placeholder="Link to URL address"
            onChange={(e) => setUrl(e.target.value)}
            className="border p-2 rounded w-full bg-gray-100"
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <button onClick={onClose} className="px-4 py-2">
            Cancel
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const DropDownButton = ({ buttonName, children }: DropDownProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="tipTabButton" aria-label="Customise options">
          {buttonName}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="min-w-[250px] max-h-[300px] overflow-y-auto bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}>
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const Tiptap = ({
  placeholder,
  content,
  setContent,
  isNotes,
  isThread,
  showUndoRedo = false,
  showDateTimeVars = false,
  showTimeVars = false,
  showDateTimeShiftVars = false,
  showAIButton = false,
  classNameBox,
  classNameEditor,
}: TipTapProps) => {
  const { notes } = useNotes();
  const [aiText, setAiText] = useState<string>('');
  const [, setAiType] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [aiModalOpen, setAiModalOpen] = useState<boolean>(false);
  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const [selectedTextForModal, setSelectedTextForModal] = useState('');

  const VariableNode = Node.create({
    name: 'variable',
    content: 'inline*',
    group: 'inline',
    inline: true,
    isolating: true,
    selectable: false,
    editable: false,
    atom: true,

    parseHTML() {
      return [
        {
          tag: 'div[data-variable]',
        },
      ];
    },

    renderHTML() {
      return ['div', { 'data-variable': true, class: 'custom_variable' }, 0];
    },
  });
  const VariableNodeView = ReactNodeViewRenderer(({ node }: any) => {
    return (
      <NodeViewWrapper className="custom_variable" contentEditable={false}>
        {node.textContent}
      </NodeViewWrapper>
    );
  });
  const editor = useEditor({
    extensions: [
      StarterKit as any,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      VariableNode.extend({
        addNodeView() {
          return VariableNodeView;
        },
      }),
      Link.configure({
        openOnClick: true,
      }),
    ],
    content: content,
  });

  /**
   * Handle AI Click event
   * @param value selected option
   */
  const handleAIClickEvent = async (value: string) => {
    setLoader(true);
    setAiModalOpen(true);
    setAiType(value);
    const { view, state } = editor as Editor;
    const { from, to } = view.state.selection;

    const text = state.doc.textBetween(from, to, '');
    const content = value === 'retry' ? `retry ${text} ${aiText}` : `Convert the following text into ${value}. ${text}`;
    const response = await httpGet('/openai', { params: { prompt: content } });
    setAiText(response as string);
    setLoader(false);
  };

  const handleVarClick = (value: string) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: 'variable',
        editable: false,
        content: [{ type: 'text', text: value }],
      })
      .run();
  };
  const handleNoteClick = (value: string) => {
    editor?.commands.clearContent();
    editor?.chain().focus().insertContent(value).run();
  };

  editor?.on('update', ({ editor }) => {
    setContent(editor?.getHTML() || '');
  });

  const getSelectedText = (): string => {
    if (editor && editor.view) {
      const { view } = editor;
      const { from, to } = view.state.selection;
      return editor.state.doc.textBetween(from, to, '\n');
    }
    return '';
  };

  if (!editor) {
    return null;
  }

  return (
    <div className={`text-sm ${classNameBox}`}>
      <EditorContent editor={editor} className={`tipTap_editor p-2 overflow-y-auto ${classNameEditor}`} />
      <div className="border-t">
        <div className="relative flex flex-wrap gap-1 py-3 px-2">
          <MenuButton
            icon={<MdFormatBold />}
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          />
          <MenuButton
            icon={<MdFormatItalic />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          />
          <MenuButton
            icon={<MdStrikethroughS />}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          />
          <MenuButton
            icon={<MdLink />}
            onClick={() => {
              const selectedText = getSelectedText();
              if (editor.isActive('link')) {
                editor.chain().focus().unsetLink().run();
              } else {
                setSelectedTextForModal(selectedText);
                setLinkModalOpen(true);
              }
            }}
            className={editor.isActive('link') ? 'is-active' : ''}
          />
          <MenuButton
            icon={<MdCode />}
            onClick={() => editor.chain().focus().toggleCode().run()}
            disabled={!editor.can().chain().focus().toggleCode().run()}
            className={editor.isActive('code') ? 'is-active' : ''}
          />
          {/* ... for other buttons, you may need to find appropriate icons ... */}
          <MenuButton
            icon={<MdFormatListNumbered />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive('orderedList') ? 'is-active' : ''}
          />
          <MenuButton
            icon={<MdFormatListBulleted />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          />
          {showUndoRedo ? (
            <>
              <MenuButton
                icon={<MdUndo />}
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().chain().focus().undo().run()}
                className={editor.isActive('undo') ? 'is-active' : ''}
              />
              <MenuButton
                icon={<MdRedo />}
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().chain().focus().redo().run()}
                className={editor.isActive('redo') ? 'is-active' : ''}
              />
            </>
          ) : null}
          {showDateTimeVars ? (
            <DropDownButton buttonName="Date vars">
              {tipTapVarList1.map((item) => (
                <DropdownMenu.Item key={item.value} className="dropMenuItem" onClick={() => handleVarClick(item.value)}>
                  {item.label}
                  <div className="ml-auto pl-[20px]">{item.placeholder}</div>
                </DropdownMenu.Item>
              ))}
            </DropDownButton>
          ) : null}
          {showTimeVars ? (
            <DropDownButton buttonName="Time vars">
              {tipTapVarList2.map((item) => (
                <DropdownMenu.Item key={item.value} className="dropMenuItem" onClick={() => handleVarClick(item.value)}>
                  {item.label}
                  <div className="ml-auto pl-[20px]">{item.placeholder}</div>
                </DropdownMenu.Item>
              ))}
            </DropDownButton>
          ) : null}
          {showDateTimeShiftVars ? (
            <DropDownButton buttonName="DT shift">
              {tipTapVarList3.map((item) => (
                <DropdownMenu.Item key={item.value} className="dropMenuItem" onClick={() => handleVarClick(item.value)}>
                  {item.label}
                  <div className="ml-auto pl-[20px]">{item.placeholder}</div>
                </DropdownMenu.Item>
              ))}
            </DropDownButton>
          ) : null}
          {isNotes ? (
            <DropDownButton buttonName="{ }">
              {notes?.results.map((item) => (
                <DropdownMenu.Item
                  key={item.title}
                  className="flex gap-2 cursor-pointer py-[8px] px-[10px] hover:bg-gray-300"
                  onClick={() => handleNoteClick(item.content as string)}>
                  <span className="text-[12px]">{item.title}</span>
                  <div
                    className="pl-[5px] text-gray-500 text-[12px]"
                    dangerouslySetInnerHTML={{
                      __html: (item.content as string).replace(/<\/?[^>]+(>|$)/g, '').substring(0, 50),
                    }}
                  />
                </DropdownMenu.Item>
              ))}
            </DropDownButton>
          ) : null}
          {showAIButton ? (
            <DropDownButton buttonName="AI">
              <DropdownMenu.Item
                className="flex gap-2 cursor-pointer py-[8px] px-[10px] hover:bg-gray-300"
                onClick={() => handleAIClickEvent(isThread ? 'instruct the ai' : 'draft')}>
                <span className="text-[12px]">Write a draft</span>
              </DropdownMenu.Item>
              {!isThread ? (
                <>
                  <DropdownMenu.Item
                    className="flex gap-2 cursor-pointer py-[8px] px-[10px] hover:bg-gray-300"
                    onClick={() => handleAIClickEvent('shorten text')}>
                    <span className="text-[12px]">Shorten</span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    className="flex gap-2 cursor-pointer py-[8px] px-[10px] hover:bg-gray-300"
                    onClick={() => handleAIClickEvent('lengthen text')}>
                    <span className="text-[12px]">Lengthen</span>
                  </DropdownMenu.Item>
                </>
              ) : null}

              {isThread ? (
                <DropdownMenu.Item
                  className="flex gap-2 cursor-pointer py-[8px] px-[10px] hover:bg-gray-300"
                  onClick={() => handleAIClickEvent('summarize conversation')}>
                  <span className="text-[12px]">Summarize Conversation</span>
                </DropdownMenu.Item>
              ) : null}
            </DropDownButton>
          ) : null}
          {aiModalOpen ? (
            <div className="absolute border bg-white bottom-[85px] shadow-lg rounded-sm w-full">
              <div className="h-auto p-[10px] max-h-80 overflow-y-auto">
                {loader ? 'Loading...' : <TypingText text={aiText} />}
              </div>
              {!loader ? (
                <div className="flex gap-[10px] justify-center px-4">
                  <button
                    className="font-lexend transition border hover:shadow p-[8px] rounded-[10px] gap-[10px] mx-auto w-[50%] mb-4"
                    onClick={() => {
                      void handleAIClickEvent('retry');
                    }}>
                    Retry
                  </button>
                  <button
                    className="font-lexend transition border hover:shadow p-[8px] rounded-[10px] gap-[10px] mx-auto w-[50%] mb-4"
                    onClick={() => {
                      setAiText('');
                      setAiModalOpen(false);
                      handleNoteClick(aiText as string);
                    }}>
                    Accept
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : null}
        </div>
      </div>
      <LinkModal
        isOpen={isLinkModalOpen}
        onClose={() => setLinkModalOpen(false)}
        selectedText={selectedTextForModal}
        editor={editor}
      />
    </div>
  );
};
