import { ChangeEventHandler, FormEvent, useEffect, useState } from 'react';
import cntl from 'cntl';
import { FloatingInput } from '@/components/FloatingInput';
import SubmitButton from '@/components/SubmitButton';
import { useSettings } from '@/hooks/useSettings';
import { AccountProvider, ConferencingType } from '@prisma/client';
import { toast, Toaster } from 'react-hot-toast';
import { GoogleLogo } from '@/icons/GoogleLogo';
import { useAccounts } from '@/hooks/useAccounts';
import { ZoomIcon } from '@/icons/SocialIcons';
import { useRouter } from 'next/router';
import { buildZoomAuthorizeLink } from '@/hooks/useZoom';
import { ToastGeneric } from '@/components/ToastGeneric';

const defaultButtonStyle = (value: boolean) => {
  return cntl`
    text-black rounded-md px-2 py-1 text-[14px] leading-[14px] font-medium
    hover:cursor-pointer
    ${value ? '' : 'underline'} 
  `;
};

export const Conferencing = () => {
  const router = useRouter();
  const { settings, saveSettings } = useSettings();
  const { accounts } = useAccounts();
  const googleAccounts = accounts?.filter((account) => account.provider === AccountProvider.GOOGLE) ?? [];
  const zoomAccounts = accounts?.filter((account) => account.provider === AccountProvider.ZOOM) ?? [];
  const [customLink, setCustomLink] = useState(settings?.customLink ?? '');
  const [phoneNumber, setPhoneNumber] = useState(settings?.phoneNumber ?? '');
  const [initialCustomLink, setInitialCustomLink] = useState(settings?.customLink ?? '');
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(settings?.phoneNumber ?? '');
  const [hasCustomLinkChanged, setHasCustomLinkChanged] = useState(false);
  const [hasPhoneNumberChanged, setHasPhoneNumberChanged] = useState(false);

  useEffect(() => {
    if (settings) {
      setCustomLink(settings.customLink ?? '');
      setPhoneNumber(settings.phoneNumber ?? '');
      setInitialCustomLink(settings.customLink ?? '');
      setInitialPhoneNumber(settings.phoneNumber ?? '');
    }
  }, [settings]);

  useEffect(() => {
    setHasCustomLinkChanged(initialCustomLink !== customLink);
  }, [initialCustomLink, customLink]);

  useEffect(() => {
    setHasPhoneNumberChanged(initialPhoneNumber !== phoneNumber);
  }, [initialPhoneNumber, phoneNumber]);

  useEffect(() => {
    if (settings) {
      setCustomLink(settings.customLink ?? '');
      setPhoneNumber(settings.phoneNumber ?? '');
    }
  }, [settings]);

  const handleMakeDefault = async (value: string) => {
    if (settings) {
      await saveSettings({ conferencingType: value as ConferencingType });
      toast(<ToastGeneric title="Updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
    }
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();

    if (settings) {
      await saveSettings({ customLink: customLink, phoneNumber: phoneNumber });
      toast(<ToastGeneric title="Updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
    }
  };

  const handleCustomLinkChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCustomLink(e.target.value);
  };

  const handlePhoneNumberChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPhoneNumber(e.target.value);
  };

  const connectZoomAccount = () => {
    void router.push(buildZoomAuthorizeLink());
  };

  return (
    <div className="flex flex-col w-full mt-6">
      <form onSubmit={handleSave} className="flex flex-col gap-4">
        <Toaster />
        <div className="flex flex-row items-center justify-between border-b pb-2">
          <div>No Conferencing</div>
          <button
            className={defaultButtonStyle(settings?.conferencingType === ConferencingType.NONE)}
            onClick={() => handleMakeDefault(ConferencingType.NONE)}
            type="button">
            {settings?.conferencingType === ConferencingType.NONE ? 'Default' : 'Make Default'}
          </button>
        </div>
        <div className="flex flex-row items-center justify-between border-b pb-2">
          <div>
            <GoogleLogo className="sm:inline" /> Google Meet
            {googleAccounts.map((account, index) => (
              <div key={index} className="ml-4 opacity-60 pt-2">
                {account.email}
              </div>
            ))}
          </div>
          <button
            className={defaultButtonStyle(settings?.conferencingType === ConferencingType.GOOGLE)}
            onClick={() => handleMakeDefault(ConferencingType.GOOGLE)}
            type="button">
            {settings?.conferencingType === ConferencingType.GOOGLE ? 'Default' : 'Make Default'}
          </button>
        </div>
        <div className="flex flex-row items-center justify-between border-b pb-2">
          <div>
            {zoomAccounts.length ? (
              <>
                <ZoomIcon className="sm:inline" /> Zoom
              </>
            ) : null}
            {zoomAccounts.length ? (
              zoomAccounts.map((account, index) => (
                <div key={index} className="ml-4 opacity-60 pt-2">
                  {account.email}
                </div>
              ))
            ) : (
              <button type="button" onClick={connectZoomAccount}>
                <ZoomIcon className="sm:inline" /> Set up Zoom
              </button>
            )}
          </div>
          {zoomAccounts.length ? (
            <button
              className={defaultButtonStyle(settings?.conferencingType === ConferencingType.ZOOM)}
              onClick={() => handleMakeDefault(ConferencingType.ZOOM)}
              type="button">
              {settings?.conferencingType === ConferencingType.ZOOM ? 'Default' : 'Make Default'}
            </button>
          ) : null}
        </div>
        <div className="flex flex-row items-center justify-between border-b pb-2">
          <FloatingInput
            className="w-1/2 h-[48px]"
            value={customLink}
            name="customLink"
            onChange={handleCustomLinkChange}
            label="Custom link"
            variant="primary"
            rounded
            isLabelFixed
            placeholder="Custom link"
          />
          <button
            className={defaultButtonStyle(settings?.conferencingType === ConferencingType.CUSTOM)}
            onClick={() => handleMakeDefault(ConferencingType.CUSTOM)}
            type="button">
            {settings?.conferencingType === ConferencingType.CUSTOM ? 'Default' : 'Make Default'}
          </button>
        </div>
        <div className="flex flex-row items-center justify-between border-b pb-2">
          <FloatingInput
            className="w-1/2 h-[48px]"
            value={phoneNumber}
            name="phoneNumber"
            onChange={handlePhoneNumberChange}
            label="Phone number"
            variant="primary"
            rounded
            isLabelFixed
            placeholder="Phone number"
          />
          <button
            className={defaultButtonStyle(settings?.conferencingType === ConferencingType.PHONE)}
            onClick={() => handleMakeDefault(ConferencingType.PHONE)}
            type="button">
            {settings?.conferencingType === ConferencingType.PHONE ? 'Default' : 'Make Default'}
          </button>
        </div>
        <SubmitButton className="w-[183px] mt-5" disabled={!hasCustomLinkChanged && !hasPhoneNumberChanged} />
      </form>
    </div>
  );
};
