import { useCallback, useMemo, useRef } from 'react';
import { atom } from 'jotai';

import { Command } from 'cmdk';
import Key from '../../Key';
import ItemList from './ItemList';
import Editor from '../../Editor';

import { useEvents } from '@/hooks/useEvents';
import { useTasks } from '@/hooks/useTasks';
import { useNotes } from '@/hooks/useNotes';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';

import useReference from '../hooks/useReference';
import useEditorToItemArg from '../../Editor/hooks/useEditorToItemArg';
import useSearchState from '../hooks/useSearchState';

import { iconByType, onItemCreated, onItemUpdated } from '../utils';
import { NEW_ITEM_PREFIX } from '@/components/Editor/utils/item';

import type { Editor as EditorType } from '@tiptap/core';
import type { NoteArg } from '@/models/NoteArg';
import type { EventArg } from '@/models/EventArg';
import type { TaskArg } from '@/models/TaskArg';
import type { SchedulingLinkArg } from '@/models/SchedulingLinkArg';

export const searchValueAtom = atom<string>('');

export const SearchView = () => {
  useReference();
  const {
    searchState: { value, selectedItem, reference, options },
    closeSearch,
    showOptions,
    setValue,
    setIsCreating,
  } = useSearchState();

  const createKeyboardEvent = useCallback(
    () =>
      new KeyboardEvent('keydown', {
        key: 'Enter',
        code: 'Enter',
        keyCode: 13,
        bubbles: true,
        cancelable: true,
      }),
    []
  );

  const commandRef = useRef<HTMLDivElement>(null);

  const editorToItemArg = useEditorToItemArg();

  const { createTask, updateTask } = useTasks({
    onCreated: onItemCreated,
    onUpdated: onItemUpdated,
  });
  const { createEvent, updateEvent } = useEvents({
    onCreated: onItemCreated,
    onUpdated: onItemUpdated,
  });
  const { createNote, updateNote } = useNotes({
    onCreated: onItemCreated,
    onUpdated: onItemUpdated,
  });
  const { createSchedulingLink, updateSchedulingLink } = useSchedulingLinks({
    onCreated: onItemCreated,
    onUpdated: onItemUpdated,
  });

  const onEditorUpdate = useCallback(
    (editor: EditorType) => {
      setValue(editor.getText());

      const itemType = editor.getJSON().content?.at(0)?.attrs?.type;
      const id = editor.getJSON().content?.at(0)?.attrs?.id;

      if (itemType && id.startsWith(NEW_ITEM_PREFIX)) {
        setIsCreating(true);
        return;
      }

      setIsCreating(false);
    },
    [setIsCreating, setValue]
  );

  const onEditorSubmit = useCallback(
    async (editor: EditorType) => {
      const itemType = editor.getJSON().content?.at(0)?.attrs?.type;
      const id = editor.getJSON().content?.at(0)?.attrs?.id;

      const selectedItem = commandRef.current?.querySelector('[aria-selected]') as HTMLDivElement | null;
      if (selectedItem && selectedItem.dataset.value !== 'save') {
        commandRef.current?.dispatchEvent(createKeyboardEvent());
        return;
      }

      if (!itemType || !id) {
        return;
      }

      const itemArg = await editorToItemArg(editor, reference);
      if (!itemArg) return;

      const isNew = id.startsWith(NEW_ITEM_PREFIX);

      switch (itemType) {
        case 'TASK':
          if (isNew) {
            createTask(itemArg as TaskArg);
            break;
          }
          updateTask({ taskId: id, task: itemArg as TaskArg });
          break;
        case 'EVENT':
          if (isNew) {
            createEvent(itemArg as EventArg);
            break;
          }
          updateEvent({ eventId: id, event: itemArg as EventArg });
          break;
        case 'NOTE':
          if (isNew) {
            createNote(itemArg as NoteArg);
            break;
          }
          updateNote({ noteId: id, note: itemArg as NoteArg });
          break;
        case 'SCHEDULING_LINK':
          if (isNew) {
            createSchedulingLink(itemArg as SchedulingLinkArg);
            break;
          }
          updateSchedulingLink({ schedulingLinkId: id, schedulingLink: itemArg as SchedulingLinkArg });
          break;
      }
      closeSearch();
    },
    [
      createKeyboardEvent,
      closeSearch,
      createEvent,
      createNote,
      createSchedulingLink,
      createTask,
      editorToItemArg,
      reference,
      updateEvent,
      updateNote,
      updateSchedulingLink,
      updateTask,
    ]
  );

  const renderedRefIcon = useMemo(() => {
    const Icon = reference?.type ? iconByType[reference.type!] : null;
    return Icon ? <Icon size={14} color="black" /> : null;
  }, [reference]);

  return (
    <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full">
      <div className="top-0 left-0 w-full h-full bg-gray-600 opacity-40" onClick={closeSearch} />
      <Command
        ref={commandRef}
        className="fixed xs:w-screen sm:w-screen md:w-[600px] lg:w-[600px] h-min bg-white flex flex-col gap-3 z-20 items-center justify-center rounded-lg pt-6 p-3">
        {reference && (
          <div className="flex items-center w-full gap-3">
            <span className="text-xs text-secondary-300">Referencing</span>
            <span className="shrink-0">{renderedRefIcon}</span>
            <span className="text-sm font-semibold truncate">{reference.name}</span>
          </div>
        )}
        <div className="flex w-full gap-2.5 py-2.5 items-center">
          <Editor
            items={selectedItem ? [selectedItem] : []}
            key={selectedItem?.id}
            defaultItemType={null}
            disableNewLine
            disableDnd
            onlyCreate
            hideButtons
            onUpdate={onEditorUpdate}
            onSubmit={onEditorSubmit}
            autofocus
            preventSave
          />
        </div>
        <ItemList />
        <div className="flex items-center justify-between w-full text-xs font-medium leading-5">
          <button onClick={closeSearch} className="flex items-center gap-1">
            <Key size={16} color="#79C0F6" className="text-white">
              ESC
            </Key>
            <span className="text-secondary-300">Cancel</span>
          </button>

          {!value && !options && (
            <button onClick={showOptions} className="flex items-center gap-1 select-none">
              <span className="text-secondary-300">See all options</span>
              <Key size={16} color="#79C0F6" className="text-white pt-0.5">
                ↵
              </Key>
            </button>
          )}
        </div>
      </Command>
    </div>
  );
};
