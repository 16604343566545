import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { useContacts } from '@/hooks/useContacts';
import { AddNewIcon } from '@/icons/AddNewIcon';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { SearchIcon } from '@/icons/SearchIcon';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';
import InputField from '@/components/InputField';
import { Cross1Icon } from '@radix-ui/react-icons';

interface IProps {
  onClose: () => void;
  handleInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputValue: string;
}

export const AllContactsHeader = ({ onClose, handleInput, inputValue }: IProps) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [showInput, setShowInput] = useState(false);
  const { openLeftSidebar } = useLeftSidebar();
  const { contacts } = useContacts({});

  const handleOpenLeftSidebarAddContacts = () => {
    openLeftSidebar({
      type: 'CONTACT_EDITOR',
      context: { editMode: false },
    });
  };

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  return (
    <div className="flex flex-col gap-8 pl-4 py-6 xs:w-[85%] sm:w-full md:w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[10px] mr-[14px] grow">
          <ToolTip tooltip="Add New Contact">
            <div
              onClick={handleOpenLeftSidebarAddContacts}
              className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-[#EFEFEF] rounded-[14px] shrink-0">
              <AddNewIcon />
            </div>
          </ToolTip>
          {!showInput && (
            <div
              className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-[#EFEFEF] rounded-[14px]"
              onClick={() => setShowInput(true)}>
              <SearchIcon size={16} />
            </div>
          )}
          {showInput && (
            <div className="flex  ring-gray-300 rounded-md overflow-hidden grow ring-1">
              <InputField
                ref={inputRef}
                name="contacts"
                type="text"
                onChange={handleInput}
                // onBlur={() => setShowInput(false)}
                value={inputValue}
                placeholder="search contacts"
                className="w-full"
                inputClassName="focus-visible:outline-none rounded-none ring-0 min-h-[40px]"
              />
              <div
                className="flex items-center justify-center ring-0 cursor-pointer w-[40px] bg-[#f4f4f4] hover:bg-[#eee]"
                onClick={() => setShowInput(false)}>
                <Cross1Icon />
              </div>
            </div>
          )}
        </div>
        <ToolTip tooltip="Close Sidebar">
          <button type="button" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center px-[15px]">
        <ContactsIcon className="mr-[8px]" size={18} />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter truncate">
          Contacts {(contacts ?? []).length > 0 && `(${contacts.length})`}
        </h1>
      </div>
    </div>
  );
};
