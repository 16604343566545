import { useId, useState } from 'react';
import { Select } from '@/components/Select';
import { useSettings } from '@/hooks/useSettings';
import { toast } from 'react-hot-toast';
import { ConferencingType } from '@prisma/client';
import { ConferencingSelectorModal } from './ConferencingSelectorModal';
import ZoomAuthenticationErrorModal from './ZoomAuthenticationErrorModal';
import { useAccounts } from '@/hooks/useAccounts';
import { useCreateZoomLink } from '@/hooks/useZoom';
import { ConferencingData } from '@/models/EventArg';
import cuid from 'cuid';
import { ToastGeneric } from './ToastGeneric';

type ConferenceTypeSelect = 'NONE' | 'GOOGLE' | 'ZOOM' | 'CUSTOM' | 'PHONE';

export const DEFAULT_CONFERENCING_OPTIONS: ConferenceTypeSelect[] = ['NONE', 'GOOGLE', 'ZOOM', 'CUSTOM', 'PHONE'];
const options = [...DEFAULT_CONFERENCING_OPTIONS, 'EDIT'];

const conferenceTypeLabelExtractor = (when: string) => {
  switch (when) {
    case 'NONE':
      return 'No conferencing';
    case 'GOOGLE':
      return 'Google Meet';
    case 'ZOOM':
      return 'Zoom';
    case 'CUSTOM':
      return 'Custom link';
    case 'PHONE':
      return 'Phone number';
    case 'EDIT':
      return 'Edit default conferencing settings';
  }
};

export const ConferencingSelector = ({
  id,
  className,
  name,
  isDefault = false,
  onConferenceDataChange,
  disabled,
}: {
  id?: string;
  className?: string;
  name?: string;
  isDefault?: boolean;
  value?: string;
  onConferenceDataChange?: (data: ConferencingData | null) => void;
  initialConferenceData?: ConferencingData | null;
  disabled?: boolean;
}) => {
  const localId = useId();
  const { accounts } = useAccounts();
  const { settings, saveSettings } = useSettings();
  const [openModalConferencing, setOpenModalConferencing] = useState(false);
  const { mutateAsync: createZoomLink } = useCreateZoomLink();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(settings?.conferencingType ?? 'NONE');

  const handleOptionChange = async (option: string) => {
    const hasZoomAccount = accounts?.some((account) => account.provider === 'ZOOM');
    const hasCustomInfo = settings?.customLink !== '';
    const hasPhoneNumber = settings?.phoneNumber !== '';

    switch (option) {
      case 'EDIT':
        window.open('/settings/conferencing', '_blank');
        break;
      case 'GOOGLE':
        setSelectedOption('GOOGLE');

        if (isDefault || !onConferenceDataChange) {
          await saveSettings({ conferencingType: option });
          toast(<ToastGeneric title="Updated" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }

        onConferenceDataChange({
          createRequest: {
            requestId: cuid(),
          },
        });
        break;
      case 'ZOOM': {
        setSelectedOption('ZOOM');
        if (!hasZoomAccount) {
          setOpenModalConferencing(true);
          break;
        }

        if (isDefault || !onConferenceDataChange) {
          await saveSettings({ conferencingType: option });
          toast(<ToastGeneric title="Updated" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }

        let zoomLink: string;
        try {
          zoomLink = await createZoomLink({});
        } catch (error) {
          setShowErrorModal(true);
          break;
        }

        onConferenceDataChange({
          conferenceSolution: {
            iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
            name: 'Zoom Meeting',
            key: { type: 'addOn' },
          },
          entryPoints: [
            {
              entryPointType: 'video',
              uri: zoomLink,
              label: zoomLink,
            },
          ],
        });
        break;
      }
      case 'CUSTOM':
        setSelectedOption('CUSTOM');

        if (!hasCustomInfo) {
          setOpenModalConferencing(true);
          break;
        }

        if (isDefault || !onConferenceDataChange || !settings?.customLink) {
          await saveSettings({ conferencingType: option });
          toast(<ToastGeneric title="Updated" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }

        onConferenceDataChange({
          conferenceSolution: {
            name: 'Custom',
            iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
            key: { type: 'addOn' },
          },
          entryPoints: [
            {
              entryPointType: 'video',
              uri: settings?.customLink,
              label: settings?.customLink,
            },
          ],
        });
        break;
      case 'PHONE':
        setSelectedOption('PHONE');
        if (!hasPhoneNumber) {
          setOpenModalConferencing(true);
          break;
        }

        if (isDefault || !onConferenceDataChange || !settings?.phoneNumber) {
          await saveSettings({ conferencingType: option });
          toast(<ToastGeneric title="Updated" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
          break;
        }

        onConferenceDataChange({
          conferenceSolution: {
            name: 'Phone call',
            iconUri: `${process.env.NEXT_PUBLIC_URL}/zoom-logo.png`,
            key: {
              type: 'addOn',
            },
          },
          entryPoints: [
            {
              entryPointType: 'phone',
              uri: `tel:${settings.phoneNumber}`,
              label: settings?.phoneNumber,
            },
          ],
          notes: 'Call at ' + settings?.phoneNumber,
        });
        break;

      case 'NONE':
        setSelectedOption('NONE');

        if (isDefault) {
          await saveSettings({ conferencingType: option });
          toast(<ToastGeneric title="Updated" />, {
            position: 'bottom-center',
            style: { background: '#000' },
          });
        } else if (onConferenceDataChange) {
          onConferenceDataChange(null);
        }
        break;
      default:
        await saveSettings({ conferencingType: option as ConferencingType });
        toast(<ToastGeneric title="Updated" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        break;
    }
  };

  return (
    <>
      {openModalConferencing ? <ConferencingSelectorModal onClose={() => setOpenModalConferencing(false)} /> : null}
      <Select
        id={id ?? localId}
        className={className}
        options={options}
        extractLabel={(option) => conferenceTypeLabelExtractor(option)}
        extractValue={(option) => option}
        disabled={disabled}
        label="Conferencing link"
        name={name}
        value={selectedOption}
        onChange={handleOptionChange}
      />
      {showErrorModal ? <ZoomAuthenticationErrorModal onClose={() => setShowErrorModal(false)} /> : null}
    </>
  );
};
