import { DatePicker } from '@/components/DatePicker';
import { Select } from '@/components/Select';
import ToolTip from '@/components/Tooltip';
import { InfoIcon } from '@/icons/InfoIcon';
import { Deadline, DeadlineRange, Priority, TimeBlockingType } from '@prisma/client';
import { DateObject } from 'react-multi-date-picker';

type Props = {
  start?: Date | null;
  dueDate?: Date | null;
  allDay?: boolean;
  deadlineType?: Deadline;
  deadlineRangeType?: DeadlineRange;
  timeBlocking: TimeBlockingType;
  priority: Priority;
  disabled?: boolean;
  onChange: (value: {
    deadlineType?: Deadline;
    deadlineRangeType?: DeadlineRange;
    timeBlocking?: TimeBlockingType;
    priority?: Priority;
    dueDate?: Date | null;
  }) => void;
};

const TIMEBLOCKING_OPTIONS: TimeBlockingType[] = Object.values(TimeBlockingType);
const PRIORITY_OPTIONS: Priority[] = Object.values(Priority);
const DEADLINE_OPTION: Deadline[] = Object.values(Deadline);
const DEADLINE_RANGE_OPTION: DeadlineRange[] = Object.values(DeadlineRange);

const deadlineLabelExtractor = (when: Deadline) => {
  switch (when) {
    case 'HARD_DEADLINE':
      return 'Hard Deadline';
    case 'SOFT_DEADLINE':
      return 'Soft Deadline';
    case 'NONE':
      return 'None';
  }
};

const timeBlockingLabelExtractor = (value: TimeBlockingType) => {
  switch (value) {
    case 'BUSY':
      return 'Busy';
    case 'FREE':
      return 'Free';
    default:
      return null;
  }
};

const priorityLabelExtractor = (when: Priority) => {
  switch (when) {
    case 'NO_PRIORITY':
      return 'None';
    case 'LOW':
      return 'Low';
    case 'NORMAL':
      return 'Normal';
    case 'HIGH':
      return 'High';
  }
};

const deadlineRangeLabelExtractor = (when: DeadlineRange) => {
  switch (when) {
    case 'ONE_WEEK':
      return '1 week';
    case 'TWO_WEEKS':
      return '2-3 weeks';
    case 'ONE_MONTH':
      return '1-2 months';
    case 'THREE_MONTHS':
      return '3-6 months';
  }
};

export default function TimeBlocking({
  start,
  deadlineType,
  priority,
  timeBlocking,
  disabled,
  dueDate,
  allDay,
  deadlineRangeType,
  onChange,
}: Props) {
  const handleDeadlineChange = (value: Deadline) => {
    onChange({ deadlineType: value });
  };

  const handleTimeBlockingChange = (value: TimeBlockingType) => {
    onChange({ timeBlocking: value });
  };

  const handlePriorityChange = (value: Priority) => {
    onChange({ priority: value });
  };

  const handleChangeDueDate = (value: DateObject | DateObject[] | null) => {
    if (!value) {
      onChange({ dueDate: null });
      return;
    }
    if (Array.isArray(value)) return;

    const date = value.toDate();

    onChange({ dueDate: date });
  };

  const handleDeadlineRangeChange = (value: DeadlineRange) => {
    onChange({ deadlineRangeType: value });
  };
  return (
    <section className="flex flex-col gap-3 py-5 border-t boreder-gray-border">
      {start ? (
        <span className="flex items-center text-sm mb-[8px]">
          <p className="text-gray-text font-inter text-[14px] leading-[21px] tracking-tighter">Time Blocking</p>
          <ToolTip
            className="w-[174px] h-full text-[14px] font-inter leading-5"
            size="lg"
            tooltip="Select how you want Smarty to time block this task, tasks that are marked as free will allow other events & auto-scheduling to happen during the scheduled start/end">
            <div className="px-2.5">
              <InfoIcon size={13} className="cursor-pointer text-slate-400" />
            </div>
          </ToolTip>
        </span>
      ) : null}
      <Select
        options={DEADLINE_OPTION}
        label="Deadline type"
        value={deadlineType}
        extractLabel={deadlineLabelExtractor}
        disabled={disabled}
        extractValue={(option) => option}
        onChange={handleDeadlineChange}
        placeholder="Select a deadline type"
      />
      {deadlineType === 'HARD_DEADLINE' ? (
        <DatePicker label="Deadline" value={dueDate} onChange={handleChangeDueDate} showTime={!allDay} />
      ) : deadlineType === 'SOFT_DEADLINE' ? (
        <Select
          options={DEADLINE_RANGE_OPTION}
          label="Deadline Range"
          className="mb-[10px]"
          value={deadlineRangeType}
          disabled={disabled}
          extractLabel={deadlineRangeLabelExtractor}
          extractValue={(option) => option}
          onChange={handleDeadlineRangeChange}
        />
      ) : null}
      <Select
        options={TIMEBLOCKING_OPTIONS}
        label="Time Blocking"
        value={timeBlocking}
        extractLabel={timeBlockingLabelExtractor}
        extractValue={(option) => option}
        disabled={disabled}
        onChange={handleTimeBlockingChange}
        placeholder="Select a time blocking type"
      />
      <Select
        options={PRIORITY_OPTIONS}
        label="Priority"
        value={priority}
        extractLabel={priorityLabelExtractor}
        extractValue={(option) => option}
        disabled={disabled}
        onChange={handlePriorityChange}
        placeholder="Select a priority"
      />
    </section>
  );
}
