import { ReactNode, useState } from 'react';
import moment from 'moment-timezone';
import { Select } from './Select';
import { findTimeZone } from '@/utils/date';
import { frequencyText, getDayOfWeek } from '@/utils/recurrenceUtils';
import { RecurrenceType } from '@prisma/client';

type RecurringSelectProps = {
  date: Date | null;
  recurrenceType: string;
  placeholder?: ReactNode;
  label?: string;
  timeZone: string;
  onChange: (value: string) => void;
};
export const RecurringSelect = ({
  date,
  recurrenceType,
  placeholder,
  label = 'Recurring',
  timeZone,
  onChange,
}: RecurringSelectProps) => {
  const dayOfWeek = moment(date).format('dddd');
  const dayOfMonth = moment(date).format('D');
  const month = moment(date).format('MMM');
  const [showRecurrence, setShowRecurrence] = useState(false);
  const ruleFrequency = () => {
    switch (recurrenceType) {
      case 'YEARLY':
        return `Annually on ${month} ${dayOfMonth}`;
      case 'MONTHLY':
        return `Monthly on the ${getOccurrencesOfMonth(date!)} ${dayOfWeek}`;
      case 'WEEKLY':
        return `Weekly on ${dayOfWeek}`;
      case 'DAILY':
        return 'Daily';
      case 'DOES_NOT_REPEAT':
        return 'Does not repeat';
      default:
        return 'Does not repeat';
    }
  };
  const [selectValue, setSelectValue] = useState(ruleFrequency());
  const options = [
    'Does not repeat',
    'Daily',
    `Weekly on ${dayOfWeek}`,
    `Monthly on the ${getOccurrencesOfMonth(date!)}${getOrdinalSuffix(getOccurrencesOfMonth(date!))} ${dayOfWeek}`,
    `Annually on ${month} ${dayOfMonth}`,
    'Custom',
  ];
  const onRecurrenceChange = (value: string) => {
    switch (value) {
      case 'Does not repeat':
        setSelectValue('Does not repeat');
        onChange('DOES_NOT_REPEAT');
        return 'DOES_NOT_REPEAT';
      case 'Custom':
        setSelectValue('Custom');
        onChange('CUSTOM');
        return 'CUSTOM';
      case 'Daily':
        setSelectValue('Daily');
        onChange('DAILY');
        return 'DAILY';
      case `Weekly on ${dayOfWeek}`:
        setSelectValue(`Weekly on ${dayOfWeek}`);
        onChange('WEEKLY');
        return 'WEEKLY';
      case `Monthly on the ${getOccurrencesOfMonth(date!)}${getOrdinalSuffix(
        getOccurrencesOfMonth(date!)
      )} ${dayOfWeek}`:
        setSelectValue(
          `Monthly on the ${getOccurrencesOfMonth(date!)}${getOrdinalSuffix(getOccurrencesOfMonth(date!))} ${dayOfWeek}`
        );
        onChange('MONTHLY');
        return 'MONTHLY';
      case `Annually on ${month} ${dayOfMonth}`:
        setSelectValue(`Annually on ${month} ${dayOfMonth}`);
        onChange('YEARLY');
        return 'YEARLY';
      default:
        setSelectValue('Does not repeat');
        onChange('DOES_NOT_REPEAT');
        return 'DOES_NOT_REPEAT';
    }
  };
  return (
    <>
      <div className="flex justify-between mb-2">
        <button
          type="button"
          className={`text-sm font-medium text-secondary-300 leading-5 -tracking-[0.154px] xs:text-[12px] lg:text-sm ${
            date !== null ? 'hover:text-secondary-500 hover:cursor-pointer' : ''
          }`}
          onClick={(e) => {
            e.preventDefault();
            setShowRecurrence(!showRecurrence);
          }}
          disabled={date === null}>
          {date !== null ? frequencyText(recurrenceType) : 'Please select a date'}
        </button>
        <div className="text-sm font-medium text-secondary-300 leading-5 -tracking-[0.154px] xs:text-[12px] lg:text-sm">
          {`${findTimeZone(timeZone)?.abbr} (${findTimeZone(timeZone)
            ?.value.replace(/_/g, ' ')
            .replace('America/Argentina', 'Argentina')}) `}
        </div>
      </div>
      {showRecurrence ? (
        <Select
          value={selectValue}
          label={label}
          options={options}
          placeholder={placeholder}
          extractLabel={(option) => option}
          extractValue={(option) => option}
          onChange={(value) => onRecurrenceChange(value)}
        />
      ) : null}
    </>
  );
};

// Function to get the suffix for the ordinal number (1st, 2nd, 3rd, 4th, 5th)
export function getOrdinalSuffix(number: number): string {
  let suffix = 'th';
  if (number === 1) {
    suffix = 'st';
  } else if (number === 2) {
    suffix = 'nd';
  } else if (number === 3) {
    suffix = 'rd';
  }
  return suffix;
}

// Function to obtain the number of occurrences of the day of the week in the month
export function getOccurrencesOfMonth(date: Date) {
  const dateMoment = moment(date);

  // Get the day of the month
  const dayOfMonth = dateMoment.date();

  // Get the total days in the month
  const daysInMonth = dateMoment.daysInMonth();

  // Calculates the position of the day in the month (first, second, third, fourth, fifth)
  let positionDayInMonth = Math.ceil(dayOfMonth / 7);

  // Adjusts the position if it is the last day of the week and not the last day of the month
  if (dateMoment.day() === 0 && dayOfMonth < daysInMonth) {
    positionDayInMonth++;
  }

  return positionDayInMonth;
}

export function getWeekdayByTypeAndDate(value: RecurrenceType, date: Date) {
  switch (value) {
    case 'DAILY':
      return [0, 1, 2, 3, 4, 5, 6];
    case 'WEEKLY':
      return getDayOfWeek(date).map((d) => d - 1);
    case 'MONTHLY':
      return getDayOfWeek(date).map((d) => d - 1);
    case 'YEARLY':
      return null;
    default:
      return [0, 1, 2, 3, 4, 5, 6];
  }
}
