type Props = {
  size?: number;
  color?: string;
};

export default function DescriptionIcon({ color = 'black', size = 14 }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M12.25 3.5V4.66667H1.75V3.5H12.25ZM1.75 10.5H7V9.33333H1.75V10.5ZM1.75 7.58333H12.25V6.41667H1.75V7.58333Z"
        fill={color}
      />
    </svg>
  );
}
