import { chatStateAtom } from '@/components/Overlay/utils';
import { rightSidebarStateAtom } from '@/components/RightSidebar';
import ToolTip from '@/components/Tooltip';
import { CalendarSettingsIcon } from '@/icons/CalendarSettingsIcon';
import { useSetAtom } from 'jotai';
import { MdChat } from 'react-icons/md';

export const CalendarDockButton = ({ onOpen }: { onOpen: () => void }) => {
  const setChatState = useSetAtom(chatStateAtom);
  const setRightSidebarState = useSetAtom(rightSidebarStateAtom);

  const onOpenChat = () => {
    setChatState({ show: true, value: '' });
    setRightSidebarState({ show: true, type: 'CHAT' });
  };

  return (
    <div className="flex space-x-4">
      <ToolTip tooltip="Open the calendar control panel">
        <button onClick={onOpen} className="flex items-center justify-center py-[13px] px-4 bg-[#F3F3F3] rounded-[14px]">
          <CalendarSettingsIcon className="w-[26px] h-[26px]" />
        </button>
      </ToolTip>
      <ToolTip tooltip="Chat with Smarty">
        <button onClick={onOpenChat} className="flex items-center justify-center py-[13px] px-4 bg-black text-white rounded-[14px] hover:bg-blue-600 shadow-md">
          <MdChat className="w-[26px] h-[26px]" />
        </button>
      </ToolTip>
    </div>
  );
};
