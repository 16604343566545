import { Attendee } from '@/utils/contacts';
import { ChangeEvent, useCallback } from 'react';
import { GuestsSelector } from '@/components/GuestsSelector';

type Props = {
  guests: Attendee[];
  guestsCanModify?: boolean;
  guestsCanInviteOthers?: boolean;
  guestsCanSeeOtherGuests?: boolean;
  disabled?: boolean;
  onChange: (data: {
    attendees?: Attendee[];
    guestsCanModify?: boolean;
    guestsCanInviteOthers?: boolean;
    guestsCanSeeOtherGuests?: boolean;
  }) => void;
};

export default function Guests({
  guests,
  guestsCanModify,
  guestsCanInviteOthers,
  guestsCanSeeOtherGuests,
  disabled,
  onChange,
}: Props) {

  const handleGuestsChange = useCallback(
    (attendees: Attendee[]) => {
      onChange({ attendees });
    },
    [onChange]
  );

  const handleGuestPermission = useCallback(
    (permission: 'guestsCanModify' | 'guestsCanInviteOthers' | 'guestsCanSeeOtherGuests') =>
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange({ [permission]: event.target.checked });
      },
    [onChange]
  );

  return (
    <section className="flex flex-col gap-2 py-5 border-t border-gray-border">
      <GuestsSelector
        selectedContacts={guests}
        disabled={disabled}
        onChange={handleGuestsChange}
        label="Guests"
        placeholder="Add guests"
      />
      <div className="flex gap-3 text-sm py-2.5">
        <div className=" text-secondary-300 w-[110px]">
          Guest
          <br />
          permissions
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2.5">
            <input
              type="checkbox"
              id="modifyEvents"
              checked={guestsCanModify}
              onChange={handleGuestPermission('guestsCanModify')}
              disabled={disabled}
            />
            <label htmlFor="modifyEvents" className="text-sm">
              Modify events
            </label>
          </div>
          <div className="flex items-center gap-2.5">
            <input
              type="checkbox"
              id="inviteOthers"
              checked={guestsCanInviteOthers}
              onChange={handleGuestPermission('guestsCanInviteOthers')}
              disabled={disabled}
            />
            <label htmlFor="inviteOthers" className="text-sm">
              Invite others
            </label>
          </div>
          <div className="flex items-center gap-2.5">
            <input
              type="checkbox"
              id="seeGuestList"
              checked={guestsCanSeeOtherGuests}
              onChange={handleGuestPermission('guestsCanSeeOtherGuests')}
              disabled={disabled}
            />
            <label htmlFor="seeGuestList" className="text-sm">
              See guest list
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}
