import { useProjects } from '@/hooks/useProjects';
import ProjectIcon from '@/icons/ProjectIcon';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useEasySession } from '@/hooks/useEasySession';

type Props = { projectId: string; createNew?: boolean, nameNew?: string};

export default function Project({ projectId, createNew, nameNew }: Props) {
  const { isAuthenticated } = useEasySession();
  const { projects: allProjects } = useProjects({});
  const router = useRouter();

  const handleOnClickProject = useCallback(
    (projectId: string) => async () => {
      if (!projectId) return;
      try {
        const filterProject = allProjects.find((project) => project.id === projectId);
        if (!filterProject) return;

        await router.push(`/projects/${filterProject.id}`);
      } catch (error) {
        console.error('Failed to navigate:', error);
      }
    },
    [router, allProjects]
  );

  const projectFiltered = useMemo(() => {
    return allProjects.find((project) => project.id === projectId);
  }, [allProjects, projectId]);

  if (createNew || !isAuthenticated)
    return (
      <div className="inline-flex items-center px-1 text-gray-500 align-top rounded bg-select-light-gray whitespace-nowrap hover:cursor-pointer bg-gray-scheduled">
        <ProjectIcon color="#A1A1A3" className="-mt-0.5 shrink-0" />
        <span className="text-[14px] ml-0 overflow-hidden transition-all max-w-0 group-hover:max-w-xs group-hover:ml-2">
          Create new Project: {nameNew}
        </span>
      </div>
    );

  if (!projectFiltered) return null;

  return (
    <div
      className="inline-flex items-center px-1 text-gray-500 align-top rounded bg-select-light-gray whitespace-nowrap hover:cursor-pointer"
      style={{ backgroundColor: projectFiltered.color + '4A' }}
      onClick={handleOnClickProject(projectId)}>
      <ProjectIcon color="#A1A1A3" className="-mt-0.5 shrink-0" />
      <span className="text-[14px] ml-0 overflow-hidden transition-all max-w-0 group-hover:max-w-xs group-hover:ml-2">
        {projectFiltered.name}
      </span>
    </div>
  );
}
