import Image from 'next/image';
import avatarPlaceholder from '@/icons/avatar-placeholder.png';
import { cn } from 'ui/cn';

type Props = {
  className?: string;
  name?: string | null;
  picture?: string | null;
  size?: number;
};

export const Avatar = ({ className, name, picture, size }: Props) => {
  return (
    <Image
      className={cn('aspect-square object-cover', className)}
      src={picture || avatarPlaceholder}
      alt={`${name}'s avatar`}
      aria-hidden={!picture || !name}
      referrerPolicy="no-referrer"
      fill={!size}
      width={size || undefined}
      height={size || undefined}
    />
  );
};
