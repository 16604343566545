import { ChangeEvent, FormEvent, useState } from 'react';
import { Contact } from '@/models/ContactModel';
import SubmitButton from '@/components/SubmitButton';
import { FloatingInput } from '@/components/FloatingInput';
import { toast } from 'react-hot-toast';
import { GroupArg } from '@/models/GroupModel';
import { NameEmail } from '@/utils/contacts';
import { Divider } from '@/components/Divider';
import { GuestsSelector } from '@/components/GuestsSelector';
import { ToastGeneric } from './ToastGeneric';

type GroupFormProps = {
  group: GroupArg;
  allContacts: Contact[];
  onSave: (group: GroupArg) => void;
  isSaving: boolean;
};

export const GroupForm = ({ group, allContacts, onSave, isSaving }: GroupFormProps) => {
  const [name, setName] = useState<string>(group.name);
  const [contacts, setContacts] = useState<NameEmail[]>(
    allContacts.filter((contact) => group.contactIds.includes(contact.id)).map(({ name, email }) => ({ name, email }))
  );

  const handleContactsChange = (contacts: NameEmail[]) => {
    setContacts(contacts);
  };

  const handleGroupName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      toast(<ToastGeneric title="Invalid name" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      return;
    }

    onSave({
      name,
      contactIds: allContacts
        .filter((contact) => contacts.find((selectedContact) => contact.email === selectedContact.email))
        .map(({ id }) => id),
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col flex-1 min-h-0 px-[16px] pb-[24px] gap-[16px]">
      <div className="flex-1 overflow-y-auto flex flex-col gap-[10px] px-[5px] pt-[5px] pb-[15px]">
        <div className="flex flex-col gap-5">
          <FloatingInput
            className="flex-auto"
            variant="primary"
            rounded
            placeholder="Group Name"
            label="Group name"
            name="Group Name"
            value={name}
            onChange={handleGroupName}
          />
          <Divider />
          <GuestsSelector selectedContacts={contacts} onChange={handleContactsChange} guestsCanSeeOtherGuests={true} />
        </div>
      </div>

      <div className="flex flex-col gap-[10px] p-[5px] pt-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t">
        <SubmitButton isLoading={isSaving} variant="primary">
          Save Group
        </SubmitButton>
      </div>
    </form>
  );
};
