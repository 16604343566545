import { Extension, JSONContent } from '@tiptap/react';

import { MutableRefObject, useMemo, useRef } from 'react';
import { NameEmail } from '@/utils/contacts';
import useItemMutations from './useItemMutations';
import { SmartyItemType } from '@/hooks/useSmartyItems';
import { Task } from '@/models/TaskModel';
import { ItemType, RichTextItem } from '../../types';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { editorFor } from '@/components/LeftSidebar';
import { getDiff, getItemTypeFromEditor, parseEditorItems } from '../../utils/editor';
import moment from 'moment-timezone';
import { useSettings } from '@/hooks/useSettings';
import { Action } from '../../hooks/useGuestsNotificationModal';
import useFilter from '@/hooks/useFilter';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customCommands: {
      save: () => ReturnType;
      openSidebar: (args: { id: string; itemType: ItemType }) => ReturnType;
      deleteItem: (args: { id: string; itemType: ItemType }) => ReturnType;
      updateContacts: (args: { id: string; contacts: NameEmail[] }) => ReturnType;
      updateProjects: (args: { id: string; projectIds: string[] }) => ReturnType;
    };
  }
}

export default function useCommands({
  items = [],
  showNotificationModal,
  prevStateRef,
  content,
  preventSave,
}: {
  items?: SmartyItemType[];
  showNotificationModal: () => Promise<Action>;
  prevStateRef: MutableRefObject<JSONContent | null>;
  content: JSONContent[];
  preventSave?: boolean;
}) {
  const { openLeftSidebar } = useLeftSidebar();
  const { createItem, updateItem, deleteItem, updateSmartyItem } = useItemMutations();
  const { defaultTimeZone: timeZone } = useSettings();

  const { filter } = useFilter();

  const refs = useRef({ showNotificationModal, content, items, filter });

  refs.current.showNotificationModal = showNotificationModal;
  refs.current.content = content;
  refs.current.items = items;
  refs.current.filter = filter;

  return useMemo(
    () =>
      Extension.create({
        name: 'customCommands',
        addCommands() {
          return {
            save: () => {
              return ({ editor, state, commands }) => {
                if (preventSave || !getItemTypeFromEditor(editor)) return true;
                (async () => {
                  if (!prevStateRef.current) return;
                  const { date } = state.doc.attrs;
                  const projectIds = refs.current.filter.projects.map((project) => project.id);

                  const currentState = editor.getJSON()!;
                  const { newItems, updatedItems, deletedItems, updatedTypes } = getDiff(
                    currentState,
                    prevStateRef.current
                  );
                  prevStateRef.current = currentState;

                  if (!newItems.length && !updatedItems?.length && !deletedItems.length && !updatedTypes.length) {
                    commands.setContent(refs.current.content);
                    prevStateRef.current = editor.getJSON() || null;
                    return;
                  }

                  let parsedDate = moment.tz(date, 'YYYY-MM-DD', timeZone).startOf('day').toISOString();
                  if (date === 'today') {
                    parsedDate = moment.tz(timeZone).startOf('day').toISOString();
                  }

                  const newItemsParsed = parseEditorItems(newItems, { date: parsedDate, projectIds });
                  const updatedItemsParsed = parseEditorItems(updatedItems, { date: parsedDate, projectIds });
                  const deletedItemsParsed = parseEditorItems(deletedItems);

                  let notifyGuests = false;
                  if (
                    [...newItemsParsed, ...updatedItemsParsed, ...deletedItemsParsed].some((item) => {
                      if (item.itemType !== 'EVENT') return false;
                      return !!item.contacts.length;
                    })
                  ) {
                    switch (await refs.current.showNotificationModal()) {
                      case Action.CANCEL:
                        commands.setContent(refs.current.content);
                        return;
                      case Action.SEND:
                        notifyGuests = true;
                        break;
                    }
                  }

                  const [newTasks, otherNewItems] = newItemsParsed.reduce<[RichTextItem[], RichTextItem[]]>(
                    (acc, item) => {
                      if (item.itemType === 'TASK') {
                        acc[0].push(item);
                      } else {
                        acc[1].push(item);
                      }
                      return acc;
                    },
                    [[], []]
                  );
                  otherNewItems.forEach((item) => {
                    createItem(item, notifyGuests);
                  });
                  updatedItemsParsed.forEach((item) => {
                    updateItem(item, notifyGuests);
                  });
                  deletedItemsParsed.forEach((item) => {
                    deleteItem({ id: item.id!, itemType: item.itemType });
                  });
                  updatedTypes.forEach((items) => {
                    const [parsedNew, parsedOld] = parseEditorItems(items, { date: parsedDate, projectIds });
                    deleteItem({ id: parsedOld.id!, itemType: parsedOld.itemType });
                    createItem(parsedNew);
                  });

                  for (const task of newTasks) {
                    try {
                      await createItem(task);
                    } catch {
                      /* empty */
                    }
                  }
                })();

                return true;
              };
            },
            openSidebar: ({ id, itemType }: { id: string; itemType: ItemType }) => {
              return () => {
                openLeftSidebar({
                  type: editorFor(itemType),
                  context: { id, autoFocus: true },
                });
                return true;
              };
            },
            deleteItem: (item: { id: string; itemType: ItemType }) => {
              return () => {
                (async () => {
                  const smartyItem = refs.current.items?.find(({ id }) => item.id === id);

                  if (smartyItem?.itemType === ItemType.EVENT && smartyItem.attendees?.length) {
                    if ((await refs.current.showNotificationModal()) === Action.CANCEL) {
                      return;
                    }
                  }

                  void deleteItem(item);
                  return;
                })();

                return true;
              };
            },
            updateContacts: ({ id, contacts }: { id: string; contacts: NameEmail[] }) => {
              return ({ editor }) => {
                if (preventSave || !getItemTypeFromEditor(editor)) return true;

                let item = refs.current.items.find((item) => item.id === id);
                if (!item) {
                  for (const task of refs.current.items) {
                    if (task.itemType !== 'TASK') continue;
                    item = task.subtasks?.find((subTask) => subTask.id === id) as unknown as Task;
                    if (item) break;
                  }
                }
                if (!item || item.itemType === 'NOTE') return true;
                if (item.itemType === 'EVENT') {
                  void updateSmartyItem({ ...item, attendees: contacts });
                  return true;
                }
                void updateSmartyItem({ ...item, contacts });
                return true;
              };
            },
            updateProjects: ({ id, projectIds }: { id: string; projectIds: string[] }) => {
              return ({ editor }) => {
                if (preventSave || !getItemTypeFromEditor(editor)) return true;

                let item = refs.current.items.find((item) => item.id === id);
                if (!item) {
                  for (const task of refs.current.items) {
                    if (task.itemType !== 'TASK') continue;
                    item = task.subtasks?.find((subTask) => subTask.id === id) as unknown as Task;
                    if (item) break;
                  }
                }
                if (!item || item.itemType === 'NOTE') return true;
                void updateSmartyItem({ ...item, projectIds });
                return true;
              };
            },
          };
        },
      }),
    [createItem, deleteItem, openLeftSidebar, prevStateRef, preventSave, timeZone, updateItem, updateSmartyItem]
  );
}
