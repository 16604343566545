import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import ToolTip from '@/components/Tooltip';
import { AddNewIcon } from '@/icons/AddNewIcon';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import useLeftSidebar from '../useLeftSidebar';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { useRouter } from 'next/router';

export const AllSchedulingLinkHeader = ({ onClose }: { onClose: () => void }) => {
  const { openLeftSidebar } = useLeftSidebar();
  const router = useRouter();

  const handleOpenLeftSidebar = () => {
    if (router.asPath.startsWith('/calendar')) {
      openLeftSidebar({
        type: 'SCHEDULING_LINK_EDITOR',
      });
      void router.push('/scheduling-link/new', undefined, { shallow: true });
    }
    openLeftSidebar({
      type: 'SCHEDULING_LINK_EDITOR',
    });
  };

  return (
    <div className="flex flex-col gap-6 px-4 py-6 h-[140px]">
      <div className="flex items-center justify-between">
        <div
          onClick={handleOpenLeftSidebar}
          className="flex items-center justify-center cursor-pointer w-[52px] h-[44px] bg-[#EFEFEF] rounded-[14px]">
          <AddNewIcon />
        </div>
        <ToolTip tooltip="Close Sidebar">
          <button type="button" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>
      <div className="flex items-center">
        <SchedulingLinkIcon className="mr-[8px]" size={14} />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter">All Scheduling Links</h1>
      </div>
    </div>
  );
};
