export default function deepEqual<T extends object>(obj1: T, obj2: T): boolean {
  const stack = [{ obj1, obj2 }];

  while (stack.length > 0) {
    const { obj1, obj2 } = stack.pop()!;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        return false;
      }
      continue;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key as keyof T];
      const val2 = obj2[key as keyof T];

      if (typeof val1 === 'object' && typeof val2 === 'object' && val1 && val2) {
        stack.push({ obj1: val1 as T, obj2: val2 as T });
      } else if (val1 !== val2) {
        return false;
      }
    }
  }

  return true;
}
