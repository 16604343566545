import { useCallback, useEffect, useState } from 'react';
import { EventVisibility, RecurrenceType } from '@prisma/client';
import { ItemCreated } from '@/components/ToastItemCreated';
import { ToastGeneric } from '@/components/ToastGeneric';
import { toast } from 'react-hot-toast';
import { useEasySession } from '@/hooks/useEasySession';
import Header from '../Header';
import Title from '../sections/Title';
import Schedule from '../sections/Schedule';
import useLeftSidebar from '../../useLeftSidebar';
import Button from '@/components/Button';
import { RecurringInstance } from '@/models/EventModel';
import { useCalendars } from '@/hooks/useCalendars';
import { useEvents } from '@/hooks/useEvents';
import { ConferencingData, convertEventToEventArg, EventArg } from '@/models/EventArg';
import Guests from '../sections/Guests';
import Description from '../sections/Description';
import Settings from './Settings';
import ReferenceList from '../sections/References';
import useModal from '@/hooks/useModal';
import { EditRecurrentEventModal } from '@/components/EditRecurrentEventModal';
import { SkeletonEvent } from './SkeletonEvent';
import { Calendar } from '@/models/CalendarModel';
import ToggleableSection from '@/components/LeftSidebar/editors/sections/ToggleableSection';
import { MdPeopleAlt, MdSettings } from 'react-icons/md';
import { FaClipboardList } from 'react-icons/fa';
import { Divider } from '@/components/Divider';
import Tags from '../sections/Tags';


type Props = {
  id?: string;
  start?: Date;
  end?: Date;
  isAllDay?: boolean;
  summary?: string;
};

const DEFAULT_DESCRIPTION = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Sent with ',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'link',
              attrs: {
                href: 'http://smarty.ai',
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
                class:
                  'novel-text-stone-400 novel-underline novel-underline-offset-[3px] hover:novel-text-stone-600 novel-transition-colors novel-cursor-pointer text-stone-400 underline underline-offset-[3px] hover:text-stone-600 transition-colors cursor-pointer',
              },
            },
            {
              type: 'textStyle',
              attrs: {
                color: '#2563EB',
              },
            },
          ],
          text: 'Smarty',
        },
      ],
    },
  ],
};

export default function EventEditor({ id, summary, start, end, isAllDay }: Props) {
  const { closeLeftSidebar } = useLeftSidebar();
  const { defaultCalendar, calendars } = useCalendars();
  const [event, setEvent] = useState<EventArg>();
  const isRecurring = event?.recurrence?.length || event?.recurringEventId;

  const { isUnauthenticated } = useEasySession();

  const { events, createEvent, isCreatingEvent, resetCreateEvent, updateEvent, isUpdatingEvent, resetUpdateEvent } =
    useEvents({
      disableQuery: true,
      onCreated: (event) => {
        closeLeftSidebar();
        toast(<ItemCreated item={event} />, {
          position: 'bottom-center',
          style: { background: 'black' },
        });
        resetCreateEvent();
      },
      onCreateError: () => {
        if (isUnauthenticated) return;
        toast(<ToastGeneric title="Something went wrong, Event not created" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetCreateEvent();
      },
      onUpdated: (event) => {
        toast(<ItemCreated item={event} isUpdate={true} />, {
          position: 'bottom-center',
          style: { background: 'black' },
        });
        closeLeftSidebar();
        resetUpdateEvent();
      },
      onUpdateError: () => {
        if (isUnauthenticated) return;
        toast(<ToastGeneric title="Something went wrong, Event not updated" />, {
          position: 'bottom-center',
          style: { background: '#000' },
        });
        resetUpdateEvent();
      },
    });

  useEffect(() => {
    if (!defaultCalendar || !events) return;

    const event = events?.find((t) => t.id === id);
    if (event) {
      setEvent({
        ...convertEventToEventArg(event),
        summary: summary ?? event.summary,
        start: start ?? event.start,
        end: end ?? event.end,
        allDay: isAllDay ?? event.allDay,
      });
    } else {
      setEvent({
        summary: summary ?? '',
        start: start ?? new Date(),
        end: end ?? new Date(),
        allDay: isAllDay ?? false,
        attendees: [],
        description: JSON.stringify(DEFAULT_DESCRIPTION),
        recurrenceType: RecurrenceType.DOES_NOT_REPEAT,
        backgroundColor: defaultCalendar.backgroundColor ?? undefined,
        foregroundColor: defaultCalendar.foregroundColor ?? undefined,
        startTimeZone: defaultCalendar.timeZone,
        endTimeZone: defaultCalendar.timeZone,
        reminders: [...(defaultCalendar?.defaultReminders ?? [])],
        calendarId: defaultCalendar.id,
        visibility: EventVisibility.default,
        emailIds: [],
        eventIds: [],
        noteIds: [],
        projectIds: [],
        taskIds: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars, defaultCalendar, events, id, DEFAULT_DESCRIPTION]);

  const handleOnSave = useCallback(
    (eventArg: EventArg, picked?: RecurringInstance) => {
      if (!id) {
        createEvent(eventArg);
        return;
      }

      if (isRecurring) {
        updateEvent({ eventId: id, event: eventArg, recurringChange: picked, originalStart: start });
        return;
      }

      updateEvent({ eventId: id, event: eventArg });
    },
    [id, isRecurring, updateEvent, createEvent, start]
  );

  if (!calendars || !defaultCalendar || !events || !event) {
    return <SkeletonEvent />;
  }

  return (
    <EventForm
      onSave={handleOnSave}
      calendars={calendars}
      id={id}
      defaultEvent={event}
      isDisabled={isUpdatingEvent || isCreatingEvent}
    />
  );
}

interface EventFormProps {
  onSave: (eventArg: EventArg, recurringInstance?: RecurringInstance) => void;
  calendars: Calendar[];
  isDisabled: boolean;
  id?: string;
  defaultEvent: EventArg;
}

const EventForm = ({ onSave, defaultEvent, isDisabled = false, id }: EventFormProps) => {
  const { isOpen, closeModal, openModal } = useModal();

  const [event, setEvent] = useState(defaultEvent);
  const isRecurring = Boolean(event.recurringEventId || event.recurrence?.length);

  const handleSave = () => {
    if (isRecurring && id) {
      openModal();
      return;
    }
    onSave(event);
  };

  const confirmEdit = useCallback(
    async (picked?: RecurringInstance) => {
      if (!picked) return;
      if (!id) return;
      onSave(event, picked);
      closeModal();
    },
    [closeModal, event, onSave, id]
  );

  return (
    <section className="flex flex-col h-screen xs:overflow-x-hidden">
      <Header id={id ?? 'temp-event'} itemType="EVENT" isRecurring={isRecurring} originalStartEvent={event.start} />
      <article className="lg:h-full lg:p-6 xs:p-3 overflow-auto" style={{ height: 'calc(100dvh - 125px)' }}>
        <Title
          type="EVENT"
          title={event.summary}
          placeholder="Untitled Event"
          onChange={(title) => {
            setEvent((prev) => ({ ...prev, summary: title }));
          }}
        />
        <Schedule
          allDay={event.allDay}
          start={event.start}
          end={event.end}
          recurrenceType={event.recurrenceType}
          onChange={(value) => {
            setEvent((prev) => ({
              ...prev,
              start: value.start ?? prev.start,
              end: value.end ?? prev.end,
              allDay: value.allDay ?? prev.allDay,
              recurrence: value.recurrence ?? prev.recurrence,
              recurrenceType: value.recurrenceType ?? prev.recurrenceType,
            }));
          }}
        />
        <Divider />
        <ToggleableSection 
          title="Guests"
          summary={`${event.attendees && event.attendees.length > 0 ? `${event.attendees.length} guests` : 'No guests'}`}
          icon={MdPeopleAlt}
        >
          <Guests
            guests={event.attendees || []}
            guestsCanSeeOtherGuests={!!event.guestsCanSeeOtherGuests}
            guestsCanInviteOthers={!!event.guestsCanInviteOthers}
            guestsCanModify={!!event.guestsCanModify}
            onChange={(value) => {
              setEvent((prev) => ({
                ...prev,
                attendees: value.attendees ?? prev.attendees,
                guestsCanInviteOthers: value.guestsCanInviteOthers ?? prev.guestsCanInviteOthers,
                guestsCanModify: value.guestsCanModify ?? prev.guestsCanModify,
                guestsCanSeeOtherGuests: value.guestsCanSeeOtherGuests ?? prev.guestsCanSeeOtherGuests,
              }));
            }}
          />
        </ToggleableSection>
        <Tags
          hideContacts={true}
          conferenceData={event.conferenceData as ConferencingData}
          location={event.location}
          projectIds={event.projectIds}
          onChange={(value) => setEvent({ ...event, ...value })}
        />
        <Description
          value={event.description}
          onChange={(description) => setEvent((prev) => ({ ...prev, description }))}
        />
        <Divider />
        <ToggleableSection title="Calendar Settings" icon={MdSettings}>
          <Settings
            calendarId={event.calendarId}
            colorId={event.colorId}
            visibility={event.visibility || EventVisibility.default}
            availability={event.transparency}
            disabled={false}
            onChange={(value) =>
              setEvent((prev) => ({
                ...prev,
                calendarId: value.calendarId ?? prev.calendarId,
                colorId: value.colorId ?? prev.colorId,
                transparency: value.transparency ?? prev.transparency,
                visibility: value.visibility ?? prev.visibility,
              }))
            }
          />
        </ToggleableSection>  
        <ToggleableSection title="References" icon={FaClipboardList}>
          <ReferenceList value={event.emailIds} />
        </ToggleableSection>
      </article>
      <div onClick={(e) => e.stopPropagation()}>
        {isOpen ? <EditRecurrentEventModal itemType='EVENT' onNo={closeModal} onYesRecurrent={confirmEdit} /> : null}
      </div>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="mt-auto flex-1 flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t xs:w-[75%] sm:w-[90%] md:w-full">
        <Button variant="primary" onClick={handleSave} disabled={isDisabled}>
          Save Form
        </Button>
      </footer>
    </section>
  );
};
