export type EditorTypes = 'EVENT' | 'NOTE' | 'TASK' | 'SCHEDULING_LINK';

export enum Section {
  SCHEDULE = 'SCHEDULE',
  DESCRIPTION = 'DESCRIPTION',
  AUTO_SCHEDULE = 'AUTO_SCHEDULE',
  TIME_BLOCKING = 'TIME_BLOCKING',
  TAGS = 'TAGS',
  GUESTS = 'GUESTS',
  SETTINGS = 'SETTINGS',
  INVITEE_QUESTIONS = 'INVITEE_QUESTIONS',
  SLOTS_SETTINGS = 'SLOTS_SETTINGS',
  REFERENCES = 'REFERENCES',
}
