import { MouseEvent, useEffect, useState } from 'react';
import { EventIcon } from '@/icons/EventIcon';
import { HeaderText } from '../Typography';
import InlineDatePicker from '../InlineDatePicker';
import { CollapsibleSection } from '../CollapsibleSection';
import { useCalendars } from '@/hooks/useCalendars';
import { Divider } from '../Divider';
import { GuestsSelector } from '../GuestsSelector';
import { NameEmail } from '@/utils/contacts';
import { useContactEvents } from '@/hooks/useContactEvents';
import { useGlobalDateRange } from '@/hooks/useGlobalDateRange';
import { useSettings } from '@/hooks/useSettings';
import MyCalendars from '@/components/MyCalendars';
import { useAtom } from 'jotai';
import { contactsEmailsAtom } from '@/utils/contactsEmailsAtom';

export const CalendarControlPanel = () => {
  const { calendars } = useCalendars();
  const { globalDateRange } = useGlobalDateRange();
  const { defaultTimeZone: timeZone } = useSettings();

  const [contacts, setContacts] = useState<NameEmail[]>([]);
  const [contactsToShow, setContactsToShow] = useState<NameEmail[]>([]);
  const [, setContactsEmails] = useAtom(contactsEmailsAtom);

  const { contactEvents } = useContactEvents({
    start: globalDateRange.start,
    end: globalDateRange.end,
    contactEmails: contactsToShow?.map((contact) => contact.email) ?? [],
    timeZone,
  });

  const contactsWithoutEvents = contactsToShow.filter(
    (contact) => !contactEvents?.some((event) => event.email === contact.email)
  );

  useEffect(() => {
    setContactsEmails(contactsToShow.map((contact) => contact.email));
  }, [contactsToShow, setContactsEmails]);

  const handleContactsChange = (contacts: NameEmail[]) => {
    if (!contacts.length) {
      setContacts([]);
      setContactsToShow([]);
    } else {
      setContacts(contacts);
      setContactsToShow(contacts);
    }
  };

  const selectAllContacts = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!contacts.length) return;
    setContactsToShow(contacts);
  };

  const deselectAllContacts = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setContactsToShow([]);
  };

  //Function to add or remove a contact from contactsToShow
  const handleContactEvents = (email: string) => {
    if (contactsToShow.some((contact) => contact.email === email)) {
      setContactsToShow(contactsToShow.filter((contact) => contact.email !== email));
    } else {
      const contact = contacts.find((contact) => contact.email === email);
      if (contact) {
        setContactsToShow([...contactsToShow, contact]);
      }
    }
  };

  return (
    <div className="h-full pb-[24px] overflow-x-hidden">
      <div className="flex w-full gap-2.5 mt-3 mx-4">
        <EventIcon size={20} />
        <HeaderText className="font-semibold tracking-tight font-inter">Calendar</HeaderText>
      </div>
      <div className="flex flex-col mx-6 mt-12">
        <InlineDatePicker />

        <div className="flex flex-col px-2.5 w-full">
          {!calendars?.length ? <p className="mb-5">No Calendar data available.</p> : <MyCalendars />}
        </div>
      </div>

      <div className="mx-6">
        <Divider />
      </div>
      <div className="flex flex-col mx-6 mt-[14px] gap-3">
        <GuestsSelector
          selectedContacts={contacts}
          onChange={handleContactsChange}
          placeholder="Search for external calendars"
          guestsCanSeeOtherGuests
          label="External calendars"
        />
        <CollapsibleSection
          className="pb-5"
          header={
            <div className="flex items-center justify-between w-full">
              <div className="text-xs">External Calendars</div>
              <div className="flex gap-[10px]">
                <button
                  className="text-sm font-medium tracking-tight cursor-pointer font-inter text-secondary-300"
                  type="button"
                  onClick={selectAllContacts}>
                  Select all
                </button>
                <button
                  className="text-sm font-medium tracking-tight cursor-pointer font-inter text-secondary-300"
                  onClick={deselectAllContacts}
                  type="button">
                  Select none
                </button>
              </div>
            </div>
          }
          headerClassName="w-full"
          content={
            !contacts.length ? (
              <p className="mt-3 text-sm text-center">No Calendars selected.</p>
            ) : (
              <>
                <div className={`${contacts.length > 5 ? ' max-h-[40px] overflow-x-hidden overflow-y-scroll' : ''}`}>
                  {contacts.map((contact) => {
                    const backgroundColor = contactEvents?.find(
                      (event) => event.email === contact.email
                    )?.backgroundColor;
                    const hasEvent = contactEvents?.find((event) => event.email === contact.email);

                    return (
                      <div key={contact.email} className="flex gap-[10px] items-center py-1 px-[6px]">
                        <input
                          className={`appearance-none text-center inline-block text-2xl before:content-['✓'] outline-none h-4 w-4 ${
                            contactsToShow.includes(contact) ? '' : 'border-2'
                          }`}
                          id={contact.email}
                          type="checkbox"
                          name={contact.email}
                          disabled={!hasEvent && contactsToShow.includes(contact)}
                          checked={contactsToShow.includes(contact)}
                          onChange={() => handleContactEvents(contact.email)}
                          style={{
                            backgroundColor: contactsToShow.includes(contact) ? backgroundColor : '#fff',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />

                        <label className="text-sm">{contact.email}</label>
                        {hasEvent ? null : contactsToShow.includes(contact) ? (
                          <span className="-ml-2 text-secondary-300">*</span>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {contactsWithoutEvents.length || !contactsToShow.length ? (
                  <p className="text-xs text-secondary-300">* No events to show</p>
                ) : null}
              </>
            )
          }
          defaultCollapsed
        />
      </div>
    </div>
  );
};
