import { ContactEvent } from '@/hooks/useContactEvents';
import { Event } from '@/models/EventModel';
import { Task } from '@/models/TaskModel';
import { DateInterval } from '@/utils/date';

export type Slot = DateInterval & {
  id: string;
  itemType: 'SLOT';
};

export type Booking = DateInterval & {
  id: string;
  itemType: 'BOOKING';
};

export type ItemType = Task | Event | Slot | Booking | ContactEvent;

export enum EditionMode {
  NONE = 'NONE',
  EVENTS = 'EVENTS',
  SLOTS = 'SLOTS',
  BOOKING = 'BOOKING',
}

export enum DragAction {
  CREATE = 'CREATE',
  CHANGE_START = 'CHANGE_START',
  CHANGE_END = 'CHANGE_END',
}

export type IndicatorContext = {
  selectedDate: Date | null;
  dates: DateInterval | null;
  action: DragAction | null;
  selectedItem: ItemType | null;
  activeColumn: HTMLDivElement | null;
  duration: number | null;
};
