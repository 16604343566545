import { useCallback, useMemo } from 'react';

import { Select } from '@/components/Select';
import { Availability } from '@/models/AvailabilityModel';
import ScheduleDialog from './ScheduleDialog';

import { useAvailability } from '@/hooks/useAvailability';
import useModal from '@/hooks/useModal';

const CREATE_CUSTOM_SCHEDULE_OPT = {
  id: 'CREATE_CUSTOM_SCHEDULE',
  title: 'Create a custom schedule',
};

const TIME_DURATION = [10, 15, 30, 45, 60, 90, 120];

type Props = {
  scheduleId: string;
  duration: number;
  useCustomSchedule?: boolean;
  onChange: (data: { scheduleId?: string; duration?: number }) => void;
};

export default function ScheduleDurationPicker({ scheduleId, duration, useCustomSchedule, onChange }: Props) {
  const { availability, defaultAvailability } = useAvailability();
  const { isOpen, openModal, closeModal } = useModal();

  const availabilityOptions = useMemo(() => {
    if (!availability) return [CREATE_CUSTOM_SCHEDULE_OPT];
    const options = availability.filter(({ id }) => Boolean(id)).map(({ id, title }) => ({ id, title }));
    return [...options, CREATE_CUSTOM_SCHEDULE_OPT];
  }, [availability]);

  const handleOnChangeScheduleId = useCallback(
    (scheduleId: string) => {
      if (scheduleId === CREATE_CUSTOM_SCHEDULE_OPT.id) {
        openModal();
        return;
      }
      onChange({ scheduleId });
    },
    [onChange, openModal]
  );

  const handleOnChangeDuration = useCallback(
    (duration: number) => {
      onChange({ duration });
    },
    [onChange]
  );

  const handleOnCreateSchedule = useCallback(
    (schedule: Availability) => {
      onChange({ scheduleId: schedule.id });
      closeModal();
    },
    [closeModal, onChange]
  );

  return (
    <div className="flex w-full gap-3">
      <ScheduleDialog isOpen={isOpen} close={closeModal} onCreate={handleOnCreateSchedule} />
      {!!useCustomSchedule && (
        <Select
          label="Schedule"
          name="scheduleId"
          className="flex-1"
          classNameValue='xs:text-[12px]'
          value={scheduleId || defaultAvailability?.id || ''}
          options={availabilityOptions.map((item) => item.id!)}
          extractLabel={(option) => availabilityOptions?.find((av) => av.id === option)?.title}
          extractValue={(option) => option}
          onChange={handleOnChangeScheduleId}
        />
      )}
      <Select
        options={TIME_DURATION}
        label="Duration"
        value={duration}
        name="duration"
        extractLabel={(option) => `${option} minutes`}
        extractValue={(option) => option.toString()}
        onChange={handleOnChangeDuration}
        className="flex-1 xs:text-[12px]"
      />
    </div>
  );
}
