import { AboutView } from '@/components/LeftSidebar/Contact/AboutView';
import { useContacts } from '@/hooks/useContacts';
import * as Tabs from '@radix-ui/react-tabs';
import { ReactNode, useMemo } from 'react';
import { MomentsView } from './MomentsView';
import { DetailsView } from './DetailsView';

type ContactViewerProps = {
  contactId: string;
};

export const ContactViewer = ({ contactId }: ContactViewerProps) => {
  const { contacts } = useContacts({});

  const contact = useMemo(() => {
    if (!contacts) return null;
    const c = Array.isArray(contacts) ? contacts.find((t) => t.id === contactId) : undefined;
    return c;
  }, [contacts, contactId]);

  if (!contact) return null;

  return (
    <div className="flex flex-col flex-1 min-h-0 px-[16px] pb-[24px] gap-[16px] overflow-y-auto">
      <div className="flex-1 p-[10px]">
        <Tabs.Root className="flex flex-col py-[10px]" defaultValue="tab0">
          <Tabs.List className="flex border-b shrink-0 border-mauve6 " aria-label="Contact Info">
            <TabTitle trigger="Moments" tab_num="tab0" />
            <TabTitle trigger="About" tab_num="tab1" />
            <TabTitle trigger="Details" tab_num="tab2" />
          </Tabs.List>
          <TabContent trigger={<MomentsView contact={contact} />} tab_num="tab0" />
          <TabContent trigger={<AboutView contact={contact} />} tab_num="tab1" />
          <TabContent trigger={<DetailsView contact={contact} />} tab_num="tab2" />
        </Tabs.Root>
      </div>
    </div>
  );
};

type TabTitleProps = {
  trigger: ReactNode;
  tab_num: string;
};

export const TabTitle = ({ trigger, tab_num }: TabTitleProps) => {
  return (
    <Tabs.Trigger
      className="select-none text-slate-400 px-[16px] h-[45px] flex-1 flex items-center justify-center text-sm data-[state=active]:text-slate-600 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] outline-none cursor-pointer"
      value={tab_num}>
      {trigger}
    </Tabs.Trigger>
  );
};

type TabContentProps = {
  trigger: ReactNode;
  tab_num: string;
};

export const TabContent = ({ trigger, tab_num }: TabContentProps) => {
  return (
    <Tabs.Content className="py-3 bg-white outline-none grow rounded-b-md overflow-y-auto" value={tab_num}>
      {trigger}
    </Tabs.Content>
  );
};
