type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const InfoIcon = ({ className, size = 16, color = '#BBBBBB' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_780_68433)">
      <path
        d="M6.50001 11.9193C9.49155 11.9193 11.9167 9.49415 11.9167 6.5026C11.9167 3.51106 9.49155 1.08594 6.50001 1.08594C3.50847 1.08594 1.08334 3.51106 1.08334 6.5026C1.08334 9.49415 3.50847 11.9193 6.50001 11.9193Z"
        stroke={color}
        strokeWidth="1.08333"
      />
      <path d="M6.5 3.79688H6.50542" stroke={color} strokeWidth="1.08333" strokeLinecap="round" />
      <path
        d="M5.41669 5.96094H6.50002V8.66927M5.41669 8.66927H7.58335"
        stroke={color}
        strokeWidth="1.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_780_68433">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
