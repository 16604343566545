import { CollapsibleSection } from '@/components/CollapsibleSection';
import { iconByType } from '@/components/Editor/nodes/suggestions/utils';
import { useEffect, useMemo, useState } from 'react';
import useLeftSidebar from '../../useLeftSidebar';
import { editorFor } from '../..';
import { PlusIcon } from '@radix-ui/react-icons';
import { MdEmail } from 'react-icons/md';
import { searchStateAtom } from '@/components/Overlay/utils';
import { useSetAtom } from 'jotai';
import { useGetEmailThread } from '@/hooks/useEmails';
import useFindRef from '@/hooks/useFindRef';

type ReferenceProps = {
  referenceIds: string[];
  emailIds: string[];
};

const loadingPlaceholder = (
  <span className="flex gap-2.5 items-center">
    <MdEmail size={14} className="shrink-0" />
    loding...
  </span>
);

export default function ReferenceList({ referenceIds, emailIds }: ReferenceProps) {
  const { openLeftSidebar } = useLeftSidebar();
  const setSearchState = useSetAtom(searchStateAtom);
  const [emails, setEmails] = useState<{ id: string; title: string; itemType: 'EMAIL' }[]>([]);
  const [loading, setLoading] = useState(false);
  const getEmailThread = useGetEmailThread();
  const findRef = useFindRef();

  const addReferenceButton = useMemo(() => {
    const handleOnOpenModal = () => {
      setSearchState({
        show: true,
        type: 'SEARCH',
      });
    };

    return (
      <>
        <button type="button" onClick={handleOnOpenModal} className="flex items-center gap-2">
          <PlusIcon />
          <span className="underline">Add reference</span>
        </button>
      </>
    );
  }, [setSearchState]);

  useEffect(() => {
    setLoading(true);
    Promise.allSettled(emailIds.map((id) => getEmailThread(id))).then((emails) => {
      setEmails(
        emails.reduce(
          (acc, emailThread) => {
            if (emailThread.status === 'fulfilled') {
              const email = emailThread.value.messages.at(-1)!;
              acc.push({ id: email.id || '', title: email.headers.subject, itemType: 'EMAIL' });
            }
            return acc;
          },
          [] as { id: string; title: string; itemType: 'EMAIL' }[]
        )
      );
      setLoading(false);
    });
  }, [emailIds, getEmailThread]);

  const refs = useMemo(() => {
    const references = referenceIds.map((ref) => findRef(ref)).filter(Boolean);
    return [...references, ...emails];
  }, [emails, findRef, referenceIds]);

  const content = useMemo(() => {
    const renderedList = refs.map((ref) => {
      const Icon = iconByType[ref.itemType];

      function onClick() {
        openLeftSidebar({ type: editorFor(ref.itemType), context: { id: ref.id, threadId: ref.id } });
      }

      return (
        <li key={ref.id} className="flex items-center gap-2.5">
          <Icon className="shrink-0" size={14} color="black" />
          <button type="button" className="truncate hover:underline text-primary-500" onClick={onClick}>
            {'summary' in ref ? ref.summary : ref.title}
          </button>
        </li>
      );
    });

    return (
      <ul className="mt-2.5 flex flex-col gap-2.5">
        {renderedList}
        {loading && emailIds.map((id) => <li key={id}>{loadingPlaceholder}</li>)}
        <li>{addReferenceButton}</li>
      </ul>
    );
  }, [addReferenceButton, emailIds, loading, openLeftSidebar, refs]);

  if (!refs.length && !loading) {
    return addReferenceButton;
  }

  return <CollapsibleSection defaultCollapsed header="References" content={content} />;
}
