import { atom } from 'jotai';
import { DateInterval, RecurringInterval } from '@/utils/date';

export const slotsAtom = atom<DateInterval[]>([]);

export const eventBeingCreatedAtom = atom<DateInterval | null>(null);

type CalendarType = 'SPECIFIC' | 'GENERIC';
export const calendarTypeAtom = atom<CalendarType>('SPECIFIC');

export const recurringSlotsAtom = atom<RecurringInterval[]>([]);

export const timeZoneAtom = atom<string>('');
