import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import { ToastGeneric } from '@/components/ToastGeneric';
import ToolTip from '@/components/Tooltip';
import { useAvailability } from '@/hooks/useAvailability';
import { useSettings } from '@/hooks/useSettings';
import { useSmartyItems } from '@/hooks/useSmartyItems';
import { useTasks } from '@/hooks/useTasks';
import { AutoscheduleBtn } from '@/icons/AutoscheduleBtn.';
import { UnscheduledIcon } from '@/icons/UnscheduledIcon';
import { Task } from '@/models/TaskModel';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';

export const UnscheduledHeader = ({ onClose }: { onClose: () => void }) => {
  const { items } = useSmartyItems();
  const { updateTask } = useTasks({});
  const { defaultDuration } = useSettings();
  const { defaultAvailability } = useAvailability();

  const unscheduledItems = useMemo(() => {
    if (!items) return [];

    return items.find((item) => item.date === 'unscheduled')?.items || [];
  }, [items]);

  const handleAutoscheduleClick = async () => {
    if (!unscheduledItems) return;

    for (const item of unscheduledItems) {
      if (item.itemType === 'TASK') {
        const { itemType, id, ...rest } = item as Task;
        updateTask({
          taskId: id,
          task: {
            ...rest,
            duration: defaultDuration ?? 30,
            scheduleId: defaultAvailability?.id ?? '',
            earliestStartDate: new Date(),
            taskScheduleType: 'AUTO_SCHEDULED',
          },
        });
      }
    }
    toast(<ToastGeneric title="All to-dos were auto-scheduled" />, {
      position: 'bottom-center',
      style: { background: '#000' },
    });
  };

  return (
    <div className="flex flex-col gap-4 px-[24px] py-[24px] h-[140px] border-b">
      <div className="flex items-center justify-center">
        <AutoscheduleBtn onAutoscheduleClick={handleAutoscheduleClick} />

        <ToolTip tooltip="Close Sidebar">
          <button type="button" className="ml-auto" onClick={onClose}>
            <MdKeyboardDoubleArrowLeft color="#A1A1A3" size={34} />
            <KeyboardShortcutIndicator shortcut="Esc" />
          </button>
        </ToolTip>
      </div>

      <div className="flex items-center">
        <UnscheduledIcon size={17} className="mr-[8px]" color="#000000" />
        <h1 className="text-[24px] leading-[100%] font-semibold font-inter tracking-tighter select-none">To-dos</h1>
      </div>
    </div>
  );
};
