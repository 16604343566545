import useDropActions from '@/hooks/useDndActions';
import { useEvents } from '@/hooks/useEvents';
import { useSettings } from '@/hooks/useSettings';
import { useTasks } from '@/hooks/useTasks';
import { convertEventToEventArg } from '@/models/EventArg';
import { TaskArg } from '@/models/TaskArg';
import CalendarContext from '@/components/Calendar/context';
import { DraggableType, DroppableType, parseDraggableId, parseDroppableId } from '@/utils/dnd';
import moment from 'moment-timezone';
import { useContext, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { calendarTypeAtom, recurringSlotsAtom, slotsAtom } from '@/components/calendarAtoms';
import { updateRecurringSlot } from '../utils';
import { EditionMode } from '../types';

export default function useDrop() {
  const { addAction, removeAction } = useDropActions();
  const { indicatorContext, timeZone, editionMode } = useContext(CalendarContext);
  const { defaultDuration } = useSettings();

  const { tasks, updateTask } = useTasks({});
  const { events, updateEvent } = useEvents({});
  const [slots, setSlots] = useAtom(slotsAtom);
  const setRecurringSlots = useSetAtom(recurringSlotsAtom);
  const calendarType = useAtomValue(calendarTypeAtom);

  useEffect(() => {
    async function handleDragTaskEnd(result: DropResult) {
      const { destination, draggableId: _draggableId } = result;
      if (!destination) return;
      if (editionMode === EditionMode.SLOTS) return;

      const { droppableType, id: droppableDate } = parseDroppableId(destination.droppableId);
      if (droppableType !== DroppableType.CALENDAR && droppableType !== DroppableType.CALENDAR_HEADER) return;

      const { draggableType, id: draggableId } = parseDraggableId(_draggableId);
      if (draggableType !== DraggableType.TASK) return;

      const draggedTask = tasks?.results?.find((t) => t.id === draggableId);
      if (!draggedTask) return;

      let start;
      let end;
      let allDay = false;

      if (droppableType === DroppableType.CALENDAR) {
        if (!indicatorContext.dates) return;
        start = moment.tz(indicatorContext.dates.start, timeZone).toDate();
        end = moment.tz(indicatorContext.dates.end, timeZone).toDate();
      } else {
        allDay = true;
        start = moment.tz(droppableDate, timeZone).startOf('day').toDate();
        end = moment.tz(droppableDate, timeZone).add(1, 'day').startOf('day').toDate();
      }

      updateTask?.({
        taskId: draggedTask.id,
        task: { start, end, allDay, taskScheduleType: 'SPECIFIC' } as TaskArg,
      });
    }

    addAction(handleDragTaskEnd);

    return () => {
      removeAction(handleDragTaskEnd);
    };
  }, [addAction, defaultDuration, removeAction, timeZone, tasks, indicatorContext, updateTask, editionMode]);

  useEffect(() => {
    async function handleDragEventEnd(result: DropResult) {
      const { destination, draggableId: _draggableId } = result;
      if (!destination) return;
      if (editionMode === EditionMode.SLOTS) return;

      const { droppableType, id: droppableDate } = parseDroppableId(destination.droppableId);
      if (droppableType !== DroppableType.CALENDAR && droppableType !== DroppableType.CALENDAR_HEADER) return;

      const { draggableType, id: draggableId } = parseDraggableId(_draggableId);
      if (draggableType !== DraggableType.EVENT) return;

      const draggedEvent = events?.find((t) => t.id === draggableId);

      if (!draggedEvent) return;

      let start;
      let end;
      let allDay = false;

      if (droppableType === DroppableType.CALENDAR) {
        if (!indicatorContext.dates) return;
        start = moment.tz(indicatorContext.dates.start, timeZone).toDate();
        end = moment.tz(indicatorContext.dates.end, timeZone).toDate();
      } else {
        allDay = true;
        start = moment.tz(droppableDate, timeZone).startOf('day').toDate();
        end = moment.tz(droppableDate, timeZone).add(1, 'day').startOf('day').toDate();
      }

      updateEvent?.({
        eventId: draggedEvent.id,
        event: convertEventToEventArg({ ...draggedEvent, start, end, allDay }),
      });
    }

    addAction(handleDragEventEnd);

    return () => {
      removeAction(handleDragEventEnd);
    };
  }, [addAction, defaultDuration, removeAction, timeZone, events, indicatorContext, updateEvent, editionMode]);

  useEffect(() => {
    async function handleDragSlotEnd(result: DropResult) {
      const { destination, draggableId: _draggableId } = result;
      if (!destination || !indicatorContext.dates) return;

      const { droppableType } = parseDroppableId(destination.droppableId);
      if (droppableType !== DroppableType.CALENDAR) return;

      const { draggableType, id: draggableId } = parseDraggableId(_draggableId);
      if (draggableType !== DraggableType.SLOT) return;

      const draggedSlot = slots?.find((_, index) => index === Number(draggableId));

      if (!draggedSlot) return;

      const start = moment.tz(indicatorContext.dates.start, timeZone).toDate();
      const end = moment.tz(indicatorContext.dates.end, timeZone).toDate();

      if (calendarType === 'SPECIFIC') {
        setSlots((prev) => {
          const newSlots = [...prev];
          newSlots[Number(draggableId)] = { ...draggedSlot, start, end };
          return newSlots;
        });
        return;
      }
      setRecurringSlots((prev) => updateRecurringSlot(prev, draggedSlot, { start, end }, timeZone));
    }

    addAction(handleDragSlotEnd);

    return () => {
      removeAction(handleDragSlotEnd);
    };
  }, [
    addAction,
    defaultDuration,
    removeAction,
    timeZone,
    events,
    indicatorContext,
    updateEvent,
    slots,
    setSlots,
    calendarType,
    setRecurringSlots,
  ]);
}
