import { Note } from './NoteModel';

export type NoteArg = Omit<
  Note,
  | 'itemType'
  | 'content'
  | 'id'
  | 'userId'
  | 'projects'
  | 'events'
  | 'tasks'
  | 'notes'
  | 'linked'
  | 'createdAt'
  | 'updatedAt'
> & {
  content?: any;
  projectIds?: string[];
  eventIds?: string[];
  taskIds?: string[];
  noteIds?: string[];
};

export const convertNoteToNoteArg = (note: Note): NoteArg => {
  const { itemType, id, ...rest } = note;
  return rest;
};

export const convertNoteArgToNote = (noteArg: NoteArg, noteId?: string): Note => ({
  id: noteId || 'temp',
  itemType: 'NOTE',
  ...noteArg,
  linked: undefined,
  content: noteArg.content || '',
  createdAt: new Date(),
  updatedAt: new Date(),
});
