import { useEffect, useRef, useState } from 'react';

export default function useIntersectionObserver({ root, rootMargin, threshold }: IntersectionObserverInit = {}) {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const [node, setNode] = useState<Element | null>(null);
  const observer = useRef<IntersectionObserver>();

  function disconnectObserver() {
    if (observer.current) observer.current.disconnect();
  }

  useEffect(
    function updateObserver() {
      disconnectObserver();
      if (!node) return;

      observer.current = new IntersectionObserver(
        (entries) => {
          setEntry(entries[0]);
        },
        { root, rootMargin, threshold }
      );

      observer.current.observe(node);
      return disconnectObserver;
    },
    [root, rootMargin, threshold, node]
  );
  return [entry && entry.isIntersecting, setNode, entry] as const;
}
