import { Extension } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';

import type { Node } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/core';

function shouldPreventEdition(view: EditorView, from: number, to: number) {
  try {
    view.state.doc.nodesBetween(from, to, (node) => {
      if (node.attrs.editable === false) {
        throw new Error('prevent');
      }
    });
  } catch (e) {
    return true;
  }
  return false;
}

function onBackspace({ editor }: { editor: Editor }) {
  const { from: selectionFrom, to: selectionEnd } = editor.state.selection;

  if (shouldPreventEdition(editor.view, selectionFrom, selectionEnd)) {
    return true;
  }

  const isAtStartOfNode = editor.state.selection.$from.start() === selectionFrom;

  const items: Node[] = [];
  editor.state.doc.nodesBetween(0, editor.state.selection.from, (node) => {
    if (node.type.name !== 'item') return;
    items.push(node);
  });
  const prevNode = items[items.length - 2];

  if (prevNode?.attrs.editable === false && isAtStartOfNode) {
    editor.chain().setNodeSelection(selectionFrom).deleteNode('item').run();
    return true;
  }

  return false;
}

function onDelete({ editor }: { editor: Editor }) {
  const { from: selectionFrom, to: selectionEnd } = editor.state.selection;

  if (shouldPreventEdition(editor.view, selectionFrom, selectionEnd)) {
    return true;
  }

  const isAtEndOfNode = editor.state.selection.$to.end() === editor.state.selection.to;

  const items: Node[] = [];
  editor.state?.doc?.nodesBetween(selectionFrom, editor.state.doc.resolve(0).end(), (node) => {
    if (node.type.name !== 'item') return;
    items.push(node);
  });
  const nextNode = items[1];

  if (nextNode?.attrs.editable === false && isAtEndOfNode) return true;

  return false;
}

export default Extension.create({
  name: 'readOnlyHandler',

  addKeyboardShortcuts() {
    return {
      Backspace: onBackspace,
      'Mod-Backspace': onBackspace,
      'Alt-Backspace': onBackspace,
      'Shift-Backspace': onBackspace,
      Delete: onDelete,
      'Mod-Delete': onDelete,
      'Alt-Delete': onDelete,
      'Shift-Delete': onDelete,
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('readOnlyHandler'),
        props: {
          handleTextInput(view, from, to) {
            return shouldPreventEdition(view, from, to);
          },
          handleDrop(_, event, slice) {
            if (((event.target as HTMLElement).parentNode as HTMLElement).contentEditable === 'false') {
              return true;
            }

            try {
              slice.content.descendants((node) => {
                if (node.attrs.editable === false) {
                  throw new Error('prevent');
                }
              });
            } catch {
              return true;
            }

            return false;
          },
          handlePaste(view) {
            return shouldPreventEdition(view, view.state.selection.from, view.state.selection.to);
          },
          handleDOMEvents: {
            cut(view) {
              return shouldPreventEdition(view, view.state.selection.from, view.state.selection.to);
            },
          },
        },
      }),
    ];
  },
});
