import { Fragment, useMemo } from 'react';

type Props = {
  path: {
    name: string;
    onClick?: () => void;
  }[];
};

export default function Breadcrumb({ path }: Props) {
  const [prevPath, currentPath] = useMemo(() => {
    const _path = [...path];
    const current = _path.pop()!;
    return [_path, current];
  }, [path]);

  return (
    <nav className="text-sm text-secondary-300">
      <ul className="flex items-center gap-2 xs:text-[12px]">
        {prevPath.map(({ name, onClick }) => {
          return (
            <Fragment key={name}>
              <li>
                <button onClick={onClick} className="hover:underline">
                  {name}
                </button>
              </li>
              <li>{'>'}</li>
            </Fragment>
          );
        })}
        <li>{currentPath.name}</li>
      </ul>
    </nav>
  );
}
