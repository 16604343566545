import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { TaskIcon } from '@/icons/TaskIcon';
import { ReactNode, useMemo } from 'react';
import createSuggestions from './create';
import { getItemTypeFromEditor, parseEditorItems, getOnboardingStateFromEditor } from '../../utils/editor';
import { ItemType } from '../../types';

import type { Suggestion } from './types';
import type { Editor } from '@tiptap/core';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { editorFor } from '@/components/LeftSidebar';
import cuid from 'cuid';

const suggestions = [
  {
    id: 'OPEN_DETAILS',
    label: 'Open details',
  },
  {
    id: ItemType.TASK,
    label: 'Task',
  },
  {
    id: ItemType.EVENT,
    label: 'Event',
  },
  {
    id: ItemType.SCHEDULING_LINK,
    label: 'Scheduling link',
  },
  {
    id: ItemType.NOTE,
    label: 'Note',
  },
] as Suggestion[];

const iconByItemType = {
  TASK: <TaskIcon color="black" />,
  EVENT: <EventIcon color="black" />,
  SCHEDULING_LINK: <SchedulingLinkIcon color="black" />,
  NOTE: <NoteIcon color="black" />,
} as Record<ItemType, ReactNode>;

function renderSuggestion({ suggestion, editor }: { suggestion: Suggestion; editor: Editor }) {
  let renderedContent: ReactNode | null = null;
  const itemType = getItemTypeFromEditor(editor);
  const isOnboarding = getOnboardingStateFromEditor(editor);

  switch (suggestion.id) {
    case 'OPEN_DETAILS':
      renderedContent = (
        <>
          {iconByItemType[itemType]}
          Open {itemType.toLowerCase().replace('_', ' ')} details
        </>
      );
      break;
    case ItemType.EVENT:
    case ItemType.TASK:
      renderedContent = (
        <div  className={`px-2 py-2.5 flex items-center gap-2 w-[258px]`}>
          {iconByItemType[suggestion.id]}
          {suggestion.label}
        </div>
      );
      break;
    case ItemType.SCHEDULING_LINK:
    case ItemType.NOTE:
      renderedContent = (
        <div className={`px-2 py-2.5 flex items-center gap-2 w-[258px] ${isOnboarding ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'}`}>
          {iconByItemType[suggestion.id]}
          {suggestion.label}
        </div>
      );
      break;
    default:
      return null;
  }

  return (
    <div key={suggestion.id}>
      {renderedContent}
    </div>
  );
}

function useSlashSuggestions(allowedTypes: ItemType[]) {
  const { openLeftSidebar } = useLeftSidebar();

  return useMemo(
    () =>
      createSuggestions({
        char: '/',
        items: ({ query, editor }) => {
          return suggestions.filter((suggestion) => {
            if (!allowedTypes.includes(suggestion.id as ItemType)) return false;
            const itemType = getItemTypeFromEditor(editor);
            if (suggestion.id === itemType) return false;
            return suggestion.label.toLowerCase().startsWith(query.toLowerCase());
          });
        },
        renderSuggestion,
        command: ({ editor, range, props }) => {
          const isOnboarding = getOnboardingStateFromEditor(editor);
          if (isOnboarding) {
            if (props.id === 'TASK' || props.id === 'EVENT') {
              editor.commands.deleteRange(range);
              editor.commands.updateAttributes('item', { type: props.id });
            }
          } else {
            editor.commands.deleteRange(range);

            if (props.id === 'OPEN_DETAILS') {
              const item = editor.state.selection.$head.node();
              const { type, id } = item.attrs;
              const { date } = editor.state.doc.attrs;

              if (!id) {
                const [parsedItem] = parseEditorItems([item.toJSON()], { date });

                openLeftSidebar({
                  type: editorFor(type),
                  context: { ...parsedItem, key: cuid() },
                });
                return;
              }
              openLeftSidebar({
                type: editorFor(type),
                context: { id: id },
              });
              return;
            }

            editor.commands.updateAttributes('item', { type: props.id });
          }
        },
      }),
    [allowedTypes, openLeftSidebar]
  ) as any;
}

export default useSlashSuggestions;
