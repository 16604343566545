import { atom } from 'jotai';
import { toast } from 'react-hot-toast';

import { EventIcon } from '@/icons/EventIcon';
import { NoteIcon } from '@/icons/NoteIcon';
import { SchedulingLinkIcon } from '@/icons/SchedulingLinkIcon';
import { TaskIcon } from '@/icons/TaskIcon';

import { MdEmail } from 'react-icons/md';
import { ItemCreated } from '../ToastItemCreated';

import type { OverlayType, Reference } from './types';
import type { SmartyItemType } from '@/hooks/useSmartyItems';

export const iconByType = {
  TASK: TaskIcon,
  EVENT: EventIcon,
  NOTE: NoteIcon,
  SCHEDULING_LINK: SchedulingLinkIcon,
  EMAIL: MdEmail,
};

export const searchStateAtom = atom<{
  show: boolean;
  value?: string;
  type?: OverlayType;
  selectedItem?: SmartyItemType;
  options?: boolean;
  reference?: Reference;
  isCreating?: boolean;
  context?: any;
}>({
  show: false,
  type: 'SEARCH',
});

export const chatStateAtom = atom<{
  show: boolean;
  value?: string;
  type?: OverlayType;
  selectedItem?: SmartyItemType;
  options?: boolean;
  reference?: Reference;
  isCreating?: boolean;
  context?: any;
}>({
  show: false,
});

export function onItemCreated(item: SmartyItemType) {
  toast(<ItemCreated item={item} isAllDay={item.itemType === 'NOTE'} />, {
    position: 'bottom-center',
    style: { background: '#000' },
  });
}

export function onItemUpdated(item: SmartyItemType) {
  toast(<ItemCreated item={item} isUpdate isAllDay={item.itemType === 'NOTE'} />, {
    position: 'bottom-center',
    style: { background: '#000' },
  });
}
