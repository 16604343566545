import Button from '@/components/Button';
import { EventIcon } from '@/icons/EventIcon';
import React from 'react';
import { MdKeyboardDoubleArrowLeft, MdHdrAuto } from 'react-icons/md';
import DescriptionIcon from '@/icons/DescriptionIcon';
import ScheduleIcon from '@/icons/ScheduleIcon';
import TimeBlockingIcon from '@/icons/TimeBlockingIcon';
import TagIcon from '@/icons/TagIcon';
import { ContactsIcon } from '@/icons/ContactsIcon';
import { SettingsIcon } from '@/icons/SettingsIcon';
import QuestionIcon from '@/icons/QuestionIcon';
import VisiblityIcon from '@/icons/VisibilityIcon';
import { ReferencesIcon } from '@/icons/ReferencesIcon';
import { Section } from '../types';
import KeyboardShortcutIndicator from '@/components/KeyboardShortcutsIndicator';
import useLeftSidebar from '../../useLeftSidebar';

const EVENT_SECTIONS = [Section.SCHEDULE, Section.DESCRIPTION, Section.GUESTS, Section.SETTINGS, Section.REFERENCES];

export const SkeletonEvent = () => {
  const { closeLeftSidebar } = useLeftSidebar();
  const handleClose = () => {
    closeLeftSidebar();
  };
  return (
    <section className="flex flex-col h-screen xs:overflow-x-hidden animate-pulse">
      <header className="flex items-center justify-between px-4 xs:px-2 py-2.5 bg-white border-b-2 border-solid shrink-0">
        <button className="ml-auto" type="button" onClick={handleClose}>
          <MdKeyboardDoubleArrowLeft color="#A1A1A3" className="-m-3" size={48} />
          <KeyboardShortcutIndicator shortcut="Esc" />
        </button>
      </header>
      <article className="lg:h-full lg:p-6 xs:p-3 overflow-auto" style={{ height: 'calc(100dvh - 125px)' }}>
        <div className="flex items-center gap-2.5">
          <div>
            <EventIcon size={18} color="black" />
          </div>
          <input className="text-2xl font-semibold leading-none xs:text-xl" disabled />
        </div>
        <ButtonsSkeleton sections={EVENT_SECTIONS} />
      </article>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="mt-auto flex-1 flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t">
        <Button variant="primary" disabled>
          Save Form
        </Button>
      </footer>
    </section>
  );
};

const iconBySection = {
  [Section.SCHEDULE]: ScheduleIcon,
  [Section.DESCRIPTION]: DescriptionIcon,
  [Section.AUTO_SCHEDULE]: MdHdrAuto,
  [Section.TIME_BLOCKING]: TimeBlockingIcon,
  [Section.TAGS]: TagIcon,
  [Section.GUESTS]: ContactsIcon,
  [Section.SETTINGS]: SettingsIcon,
  [Section.INVITEE_QUESTIONS]: QuestionIcon,
  [Section.SLOTS_SETTINGS]: VisiblityIcon,
  [Section.REFERENCES]: ReferencesIcon,
};

const LabelDisplayNames: Record<Section, string> = {
  SCHEDULE: 'Schedule',
  AUTO_SCHEDULE: 'Auto-Schedule',
  SETTINGS: 'Settings',
  DESCRIPTION: 'Description',
  GUESTS: 'Guests',
  INVITEE_QUESTIONS: 'Invitee Questions',
  SLOTS_SETTINGS: 'Slots',
  TAGS: 'Tags',
  TIME_BLOCKING: 'Time Blocking',
  REFERENCES: 'References',
};

type Props = {
  sections: Section[];
};

export const ButtonsSkeleton = ({ sections }: Props) => {
  return (
    <section className="flex items-center pt-5 gap-6 overflow-hidden">
      {sections.map((section, index) => {
        const Icon = iconBySection[section];

        return (
          <div key={index} className={`flex items-center w-min`}>
            <div>
              <Icon size={18} color={'black'} />
            </div>
            <span className="text-[14px] w-full ml-1 truncate select-none">{LabelDisplayNames[section]}</span>
          </div>
        );
      })}
    </section>
  );
};
