type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const SelectorCalendarIcon = ({ className, size = 26, color = '#A1A1A3' }: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7179_93117)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5V7H6V20H21V7H18V5H16V7H11V5H9ZM8 10H19V18H8V10Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_7179_93117">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
