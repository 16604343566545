import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetAtom } from 'jotai';
import { toast } from 'react-hot-toast';
import cuid from 'cuid';
import moment from 'moment-timezone';
import { ItemCreated } from '@/components/ToastItemCreated';
import { ToastGeneric } from '@/components/ToastGeneric';
import Button from '@/components/Button';
import Breadcrumb from '@/components/Breadcrumb';
import Header from '../Header';
import Title from '../sections/Title';
import Schedule from './Schedule';
import {
  convertSchedulingLinkToSchedulingLinkArg,
  SchedulingLinkArg,
  SchedulingLinkQuestion,
} from '@/models/SchedulingLinkArg';
import { GoWorkflow } from 'react-icons/go';
import { FaCalendar } from 'react-icons/fa';

import { useSettings } from '@/hooks/useSettings';
import useLeftSidebar from '../../useLeftSidebar';
import { useSchedulingLinks } from '@/hooks/useSchedulingLinks';
import { useAvailability } from '@/hooks/useAvailability';
import useSlots from './useSlots';
import { useCalendars } from '@/hooks/useCalendars';
import { recurringSlotsAtom, slotsAtom } from '@/components/calendarAtoms';
import SlotsSection from './SlotsSection';
import ToggleableSection from '../sections/ToggleableSection';
import InviteeQuestions from './InviteeQuestions';
import Tags from '../sections/Tags';
import { Calendar } from '@/models/CalendarModel';
import { MdEvent } from 'react-icons/md';
import { SchedulingLinkEditorSkeleton } from './Skeleton';
import { getColorFromCalendar } from '@/components/CalendarItem';
import HoldManager from './HoldManager';

type Props = {
  id?: string;
};

export default function SchedulingLinkEditor({ id }: Props) {
  const { defaultDuration } = useSettings();
  const { defaultCalendar, calendars } = useCalendars();
  const { closeLeftSidebar } = useLeftSidebar();
  const { defaultAvailability } = useAvailability();
  const setSlots = useSetAtom(slotsAtom);
  const setRecurringSlots = useSetAtom(recurringSlotsAtom);
  const [defaultSchedulingLink, setDefaultSchedulingLink] = useState<SchedulingLinkArg>();

  const {
    schedulingLinks,
    createSchedulingLink,
    isCreatingSchedulingLink,
    resetCreateSchedulingLink,
    updateSchedulingLink,
    isUpdatingSchedulingLink,
    resetUpdateSchedulingLink,
  } = useSchedulingLinks({
    onCreated: (item) => {
      closeLeftSidebar();
      toast(<ItemCreated item={item} />, {
        position: 'bottom-center',
        style: { background: 'black' },
      });
      resetCreateSchedulingLink();
    },
    onCreateError: () => {
      toast(<ToastGeneric title="Something went wrong, Scheduling Link not created" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetCreateSchedulingLink();
    },
    onUpdated: (item) => {
      toast(<ItemCreated item={item} isUpdate />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateSchedulingLink();
    },
    onUpdateError: () => {
      toast(<ToastGeneric title="Something went wrong, Scheduling Link not updated" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
      resetUpdateSchedulingLink();
    },
  });

  useEffect(() => {
    if (!defaultCalendar || !calendars || !schedulingLinks) return;

    const schedulingLink = schedulingLinks?.results.find((sl) => sl.id === id);
    if (schedulingLink) {
      const convertedEvent = convertSchedulingLinkToSchedulingLinkArg(schedulingLink);
      setDefaultSchedulingLink(convertedEvent);
      setSlots(schedulingLink.slots || []);
      setRecurringSlots(schedulingLink.recurringSlots || []);
    } else {
      setDefaultSchedulingLink({
        title: `New Scheduling Link ${(schedulingLinks?.count || 0) + 1}`,
        start: moment().startOf('day').toDate(),
        end: moment().endOf('week').toDate(),
        contacts: [],
        projectIds: [],
        emailIds: [],
        useCustomSchedule: true,
        scheduleId: defaultAvailability?.id,
        when: 'THIS_WEEK',
        slots: [],
        recurringSlots: [],
        location: '',
        duration: defaultDuration,
        code: cuid(),
        calendarId: defaultCalendar.id ?? calendars[0].id ?? '',
        checkCalendarIds: [],
        questions: [],
        conferenceData: {},
      });
      setSlots([]);
      setRecurringSlots([]);
    }
  }, [
    calendars,
    defaultAvailability?.id,
    defaultCalendar,
    defaultDuration,
    id,
    schedulingLinks,
    schedulingLinks?.results,
    setRecurringSlots,
    setSlots,
  ]);

  const handleOnSave = useCallback(
    (schedulingLink: SchedulingLinkArg) => {
      if (!id) {
        createSchedulingLink(schedulingLink);
        return;
      }

      updateSchedulingLink({ schedulingLinkId: id, schedulingLink: schedulingLink });
    },
    [createSchedulingLink, id, updateSchedulingLink]
  );

  if (!calendars || !defaultCalendar || !schedulingLinks || !defaultSchedulingLink) {
    return <SchedulingLinkEditorSkeleton />;
  }

  return (
    <SchedulingLinkForm
      onSave={handleOnSave}
      calendars={calendars}
      id={id}
      defaultLink={defaultSchedulingLink}
      isDisabled={isCreatingSchedulingLink || isUpdatingSchedulingLink}
    />
  );
}

interface SchedulingLinkFormProps {
  onSave: (schedulingLink: SchedulingLinkArg) => void;
  calendars: Calendar[];
  isDisabled: boolean;
  id?: string;
  defaultLink: SchedulingLinkArg;
}

const SchedulingLinkForm = ({ onSave, defaultLink, isDisabled = false, id, calendars }: SchedulingLinkFormProps) => {
  const { openLeftSidebar } = useLeftSidebar();
  const [schedulingLink, setSchedulingLink] = useState(defaultLink);

  const handleOpenLink = useCallback(
    (link: string) => {
      onSave(schedulingLink);
      window.open(link, '_blank');
    },
    [onSave, schedulingLink]
  );

  const path = useMemo(() => {
    return [
      {
        name: 'Scheduling Links',
        onClick: () => {
          openLeftSidebar({ type: 'ALL_SCHEDULING_LINKS' });
        },
      },
      { name: defaultLink?.title || '' },
    ];
  }, [openLeftSidebar, defaultLink?.title]);

  const handleHoldChange = useCallback((hasAHold: boolean, holdType: string, holdDuration: number, holdDurationUnit: string, holdExpiration: Date | undefined, holdEventIds: string[] | undefined) => {
    const tags = { hasAHold, holdType, holdDuration, holdDurationUnit, holdExpiration, holdEventIds: JSON.stringify(holdEventIds) };
    setSchedulingLink((prev) => ({ ...prev, ...tags }));
  }, []);

  const handleSave = () => {
    onSave(schedulingLink);
  };

  useSlots({
    scheduleId: schedulingLink.scheduleId,
    duration: schedulingLink.duration,
    when: schedulingLink.when,
    calendarIds: schedulingLink.checkCalendarIds || [],
    contacts: schedulingLink.contacts,
    end: schedulingLink.end,
    start: schedulingLink.start,
    onChange: (slots) =>
      setSchedulingLink({ ...schedulingLink, slots: slots.slots, recurringSlots: slots.recurringSlots }),
  });

  return (
    <section className="flex flex-col h-full">
      <Header id={id ?? ''} itemType="SCHEDULING_LINK" />
      <article
        className="lg:h-full lg:p-6 xs:p-3 overflow-y-auto overflow-x-hidden"
        style={{ height: 'calc(100dvh - 115px)' }}>
        <div className="flex flex-col gap-3">
          <Breadcrumb path={path} />
          <Title
            placeholder="Untitled Scheduling Link"
            type="SCHEDULING_LINK"
            title={schedulingLink.title}
            onChange={(title) => setSchedulingLink({ ...schedulingLink, title })}
          />
        </div>
        <SlotsSection
          code={schedulingLink.code}
          slots={schedulingLink.slots}
          calendarIds={schedulingLink.checkCalendarIds}
          contacts={schedulingLink.contacts}
          id={id ?? ''}
          duration={schedulingLink.duration}
          start={schedulingLink.start}
          end={schedulingLink.end}
          scheduleId={schedulingLink.scheduleId}
          openLink={handleOpenLink}
        />
        <Schedule
          start={schedulingLink.start}
          end={schedulingLink.end}
          duration={schedulingLink.duration}
          calendarIds={schedulingLink.checkCalendarIds}
          contacts={schedulingLink.contacts}
          scheduleId={schedulingLink.scheduleId}
          when={schedulingLink.when}
          useCustomSchedule={schedulingLink.useCustomSchedule}
          onChange={(schedule) => {
            setSchedulingLink((prev) => ({
              ...prev,
              ...schedule,
            }));
          }}
        />
        <Tags
          projectIds={schedulingLink.projectIds}
          contacts={schedulingLink.contacts}
          location={schedulingLink.location}
          conferenceData={schedulingLink.conferenceData}
          when={schedulingLink.when}
          onChange={(tags) => setSchedulingLink({ ...schedulingLink, ...tags })}
        />
        {schedulingLink.when !== 'RECURRING_SLOTS' ? (
          <ToggleableSection
            title="Calendar Holds"
            summary={
              schedulingLink.hasAHold
                ? `Hold exists ${schedulingLink.holdType}` +
                  (schedulingLink.holdType === 'for the time selected'
                    ? `: ${schedulingLink.holdDuration} ${schedulingLink.holdDurationUnit}`
                    : '')
                : 'No hold will be created'
            }
            icon={MdEvent}>
            <HoldManager
              initialHasAHold={schedulingLink.hasAHold}
              initialHoldType={schedulingLink.holdType}
              initialHoldDuration={schedulingLink.holdDuration}
              initialHoldDurationUnit={schedulingLink.holdDurationUnit}
              initialHoldEventIds={JSON.parse(schedulingLink.holdEventIds as string ?? '[]') as string[]}
              initialHoldExpiration={schedulingLink.holdExpiration ? new Date(schedulingLink.holdExpiration) : undefined}
              onHoldChange={handleHoldChange}
            />
          </ToggleableSection>
        ) : null}
        <InviteeQuestions
          questions={schedulingLink.questions as SchedulingLinkQuestion[]}
          onChange={(questions) => setSchedulingLink({ ...schedulingLink, questions })}
        />
        <ToggleableSection
          title="Calendar & Check for Conflicts"
          summary="Select the calendars you want to check conflicts for"
          icon={FaCalendar}>
          <div>
            {calendars.map((c) => {
              return (
                <div className="flex flex-col gap-[10px]" key={c.id}>
                  <div className="flex items-center ">
                    <input
                      type="checkbox"
                      className="relative w-[22px] h-[22px] border-[3px] [&:not(:checked)]:!bg-transparent border-solid rounded-md appearance-none checked:after:absolute checked:after:top-0.5 checked:after:left-[5px] checked:after:h-2.5 checked:after:w-1.5 checked:after:rotate-45 checked:after:border-[3px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:content-[''] hover:cursor-pointer focus:shadow-none focus:transition-[border-color_0.2s]"
                      style={{
                        borderColor: getColorFromCalendar(c),
                        backgroundColor: getColorFromCalendar(c),
                      }}
                      onChange={() =>
                        setSchedulingLink((prev) => ({
                          ...prev,
                          checkCalendarIds: prev.checkCalendarIds?.includes(c.id)
                            ? prev.checkCalendarIds.filter((id) => id !== c.id)
                            : [...(prev.checkCalendarIds ?? []), c.id],
                        }))
                      }
                    />
                    <label className="flex items-center gap-2.5 p-2.5 cursor-pointer truncate">{c.summary}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </ToggleableSection>
        <ToggleableSection title="Automations" summary="Coming soon..." icon={GoWorkflow} disabled>
          Coming soon
        </ToggleableSection>
      </article>
      {/* TODO(Mariano): replace save button with auto-save */}
      <footer className="shrink-0 mt-auto flex flex-col gap-[10px] px-6 xs:px-3 py-[15px] shadow-[0_-10px_15px_-8px_rgba(0,0,0,0.1)] border-t flex-1 xs:w-[75%] sm:w-[90%] md:w-full">
        <Button variant="primary" onClick={handleSave} disabled={isDisabled}>
          Save Form
        </Button>
      </footer>
    </section>
  );
};
