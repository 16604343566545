import { useSearchContacts } from '@/hooks/useContactSearch';
import { Avatar } from '@/components/Avatar';
import { CollapsibleSection } from '@/components/CollapsibleSection';
import { useDeleteGroup, useGroups } from '@/hooks/useGroups';
import { Contact } from '@/models/ContactModel';
import { AddNewIcon } from '@/icons/AddNewIcon';
import { Group } from '@/models/GroupModel';
import { SubHeaderText } from '@/components/Typography';
import ToolTip from '@/components/Tooltip';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { toast } from 'react-hot-toast';
import useLeftSidebar from '@/components/LeftSidebar/useLeftSidebar';
import { ToastGeneric } from '@/components/ToastGeneric';

export const AllGroups = () => {
  const { results: contacts } = useSearchContacts({});
  const { groups } = useGroups({});
  const { deleteGroupAsync } = useDeleteGroup();
  const { openLeftSidebar } = useLeftSidebar();

  if (!contacts) {
    return null;
  }

  const handleOpenRightSidebarEditGroup = (group: Group) => () => {
    openLeftSidebar({
      type: 'GROUP_EDITOR',
      context: {
        groupId: group.id,
      },
    });
  };

  const handleDeleteGroup = (group: Group) => async () => {
    try {
      await deleteGroupAsync({ groupId: group.id });
      toast(<ToastGeneric title="Group deleted successfully" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
    } catch (error) {
      console.error(error);
      toast(<ToastGeneric title="Error deleting group" />, {
        position: 'bottom-center',
        style: { background: '#000' },
      });
    }
  };

  const handleOpenRightSidebarContact = (contact: Contact) => () => {
    openLeftSidebar({
      type: 'CONTACT',
      context: {
        contact: contact,
      },
    });
  };

  return (
    <div className="flex-1 flex flex-col px-[16px] pb-[24px] gap-[16px] w-full overflow-y-auto">
      {groups?.map((group) => (
        <div key={group.id} className="px-5">
          <CollapsibleSection
            header={group.name}
            defaultCollapsed={true}
            content={
              <>
                <div className="flex justify-end gap-[10px]">
                  <ToolTip tooltip="Add contact in group">
                    <div
                      onClick={handleOpenRightSidebarEditGroup(group)}
                      className="flex items-center justify-center cursor-pointer w-[30px] h-[30px] bg-[#EFEFEF] rounded-[14px] mr-[8px] mb-[8px]">
                      <AddNewIcon />
                    </div>
                  </ToolTip>
                  <ToolTip tooltip="Delete Group">
                    <div
                      onClick={handleDeleteGroup(group)}
                      className="flex items-center justify-center cursor-pointer w-[30px] h-[30px] bg-[#EFEFEF] rounded-[14px] mr-[8px] mb-[8px]">
                      <DeleteIcon />
                    </div>
                  </ToolTip>
                </div>
                <>
                  {group.contacts?.map((contact: Contact) => (
                    <div
                      key={contact.email}
                      className="flex justify-between items-center hover:bg-gray-200 p-[15px] rounded mr-[20px]"
                      onClick={handleOpenRightSidebarContact(contact)}>
                      <div className="flex items-center">
                        <div className="mr-[10px]">
                          <Avatar picture={contact.picture} name={contact.name} className="rounded-full" size={32} />
                        </div>
                        <div className="flex flex-col">
                          <div className="font-[400] text-[16px]">
                            {contact.name ? (
                              <SubHeaderText className="font-bold capitalize">{contact.name}</SubHeaderText>
                            ) : (
                              <SubHeaderText className="font-bold">{contact.email}</SubHeaderText>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </>
            }
          />
        </div>
      ))}
    </div>
  );
};
